import UbsApiV2 from "../apis/UbsApiV2";
import {
  GET_PRICING,
  GET_PRICING_TRIGGERS,
  GET_PRICING_TRIGGER_TYPES,
  CALCULATE_PRICING_RESPONSE,
} from "./types";

export const getPricing = (params) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  setTimeout(async () => {
    const response = await UbsApiV2.get(`/ssc/pricing`, {
      params: params,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: GET_PRICING,
          payload: res,
        });
      })
      .catch((err) => {});
  }, 1000);
};

export const getPricingTriggers = (params) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.get(`/ssc/pricing/triggers`, {
    params: params,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_PRICING_TRIGGERS,
        payload: res,
      });
    })
    .catch((err) => {});
};

export const getPricingTriggerTypes = (params) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.get(`/ssc/pricing/trigger_types`, {
    params: params,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_PRICING_TRIGGER_TYPES,
        payload: res,
      });
    })
    .catch((err) => {});
};

export const calculatePriceV2 = (body) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const response = await UbsApiV2.post(`/ssc/calculate/price`, body, {
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: CALCULATE_PRICING_RESPONSE,
        payload: res,
      });
    })
    .catch((err) => {});
};
