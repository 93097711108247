import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { MDBRow, MDBCol, MDBContainer } from "mdbreact";

const PaymentProcessPage = () => {
  return (
    <MDBContainer className="mt-4">
      <MDBRow>
        <MDBCol md="12" className="d-flex justify-content-center">
          <h1>Processing payment page</h1>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
  };
};

export default withRouter(connect(mapStateToProps, {})(PaymentProcessPage));
