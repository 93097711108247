import {
  GET_ROLES,
  FAILED_HANDLER,
  ROLE_RESPONSE_HANDLER,
  GET_ROLE_ACCESS,
  GET_USER_ROLE_ACCESS,
  SWITCH_ROLE_ACCESS_RESPONSE_HANDLER,
} from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case GET_ROLES:
      response["data"] = data;
      break;
    case ROLE_RESPONSE_HANDLER:
      response["data"] = action.payload.data;
      response["action"] = "ACTION";
      break;
    case SWITCH_ROLE_ACCESS_RESPONSE_HANDLER:
      response["data"] = action.payload.data;
      response["action"] = "ACTION";
      break;
    case GET_ROLE_ACCESS:
      response["data"] = data;
      break;
    case GET_USER_ROLE_ACCESS:
      response["data"] = data;
      break;
    case FAILED_HANDLER:
      response["payload"] = action.payload;
      break;
    default:
      return state;
  }
  return response;
};
