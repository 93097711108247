import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBSideNavItem,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBContainer,
  MDBAlert,
  MDBBtn,
  MDBFormInline,
  MDBSideNavLink,
} from "mdbreact";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { USER_COOKIES_KEY, USER_TMP } from "../../../helper";
import {
  displayBreadcrumbs,
  revalidateComponent,
  showHideLoader,
} from "../../../actions";
import {
  getLocationMenu,
  searchLocations,
} from "../../../actions/locationAction";
import { logout } from "../../../actions/authAction";
import Spinner from "../shared/Loader";
import logo from "../assets/ublogo.png";
import GenericSearch from "../shared/GenericSearch";
import CustomerModal from "../shared/CustomerModal";
import VoucherModal from "../shared/VoucherModal";
import RoleModal from "../shared/RoleModal";
import RoleAccessModal from "../shared/RoleAccessModal";
import SubscriptionMoreDetailsModal from "../shared/SubscriptionMoreDetailsModal";
import { validateAccess } from "../../../helper/utils";

import _ from "lodash";

class Dashboard extends React.Component {
  state = {
    toggleStateA: false,
    breakWidth: 1300,
    windowWidth: 0,
    showSearchModal: false,
    publicVenueContainer: undefined,
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    this.toggleSearchModal = this.toggleSearchModal.bind(this);
  }

  removeCookies(key) {
    const { cookies } = this.props;
    cookies.remove(key, { path: "/" });
  }

  debouncedLoginRedirect = _.debounce(function () {
    window.location.replace("/login");
  }, 100);

  doLogout() {
    this.props.showHideLoader(true);
    this.removeCookies(USER_COOKIES_KEY);
    this.removeCookies(USER_TMP);
    this.props.logout(this.props.authUser.sessionToken);
  }

  componentDidMount() {
    const { storeUserAccess } = this.props;
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    if (
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userVenues")
    ) {
      this.props.searchLocations("");
    }
    let params = { is_active: 1, limit: 10000 };
    if (this.props.authUser.organisation != "cust") {
      this.props.getLocationMenu(params, this.props.authUser.sessionToken);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userLogout !== this.props.userLogout &&
      this.props.userLogout !== null
    ) {
      window.location.replace("/login");
    }

    this.getPublicLocations(prevProps, prevState);
  }

  getPublicLocations(prevProps, prevState) {
    const { storeUserAccess } = this.props;
    if (
      prevProps.locations !== this.props.locations &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails")
    ) {
      this.setState({ publicVenueContainer: this.props.locations });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.authResponse !== this.props.authResponse &&
      nextProps.authResponse.type == "HEART_BEAT_RESPONSE_HANDLER"
    ) {
      return false;
    }
    return true;
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  // updateBreadcrumbs() {
  //   this.props.displayBreadcrumbs("Dashboard");
  // }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    });

  handleToggleClickA = () => {
    const { toggleStateA } = this.state;
    this.setState({
      toggleStateA: !toggleStateA,
    });
  };

  redirectToPage(url) {
    window.location.replace(url);
  }

  buildCustomerLocationLink() {
    const { publicVenueContainer } = this.state;
    if (publicVenueContainer !== undefined) {
      return (
        <div>
          {publicVenueContainer.results.map(
            (loc, index) =>
              loc.is_active === 1 && (
                <Link
                  to={`/admin/customerbooking?uuid=${loc.uuid}`}
                  key={index}
                  className="nav-item-level-2"
                  onClick={() =>
                    this.redirectToPage(
                      `/admin/customerbooking?uuid=${loc.uuid}`
                    )
                  }
                >
                  {loc.name}
                </Link>
              )
          )}
        </div>
      );
    }
    return <span></span>;
  }

  buildLocationLink() {
    if (this.props.getLocationMenuSuccessResponse.data !== undefined) {
      return (
        <div>
          {this.props.getLocationMenuSuccessResponse.data.locations.results.map(
            (loc, index) =>
              loc.is_active === 1 && (
                <Link
                  to={`/admin/booking?uuid=${loc.uuid}`}
                  key={index}
                  className="nav-item-level-2"
                  onClick={() =>
                    this.redirectToPage(`/admin/booking?uuid=${loc.uuid}`)
                  }
                >
                  {loc.name}
                </Link>
              )
          )}
        </div>
      );
    }
    return <span></span>;
  }

  renderAdminLinks() {
    const { storeUserAccess } = this.props;

    return (
      <div>
        {validateAccess(storeUserAccess, "Organisations", "") && (
          <Link to="/admin/manageorg">Organisations</Link>
        )}
        {validateAccess(storeUserAccess, "Locations", "") && (
          <Link to="/admin/managelocation">Locations</Link>
        )}
        {validateAccess(storeUserAccess, "Customers", "") && (
          <Link to="/admin/managecustomer">Customers</Link>
        )}
        {validateAccess(storeUserAccess, "Memberships", "") && (
          <Link to="/admin/managemembership">Memberships</Link>
        )}
        {validateAccess(storeUserAccess, "Rules", "") && (
          <Link to="/admin/managerules">Rules</Link>
        )}
        {validateAccess(storeUserAccess, "Rules", "") && (
          <Link to="/admin/rulesengine">Rules Engine</Link>
        )}
        <Link to="/admin/pricing">Pricing</Link>
        {validateAccess(storeUserAccess, "Vouchers", "") && (
          <Link to="/admin/managevoucher">Vouchers</Link>
        )}
        {/* {validateAccess(storeUserAccess, "Rules", "") && (
          <Link to="/admin/managepages">Manage Checkoutpage</Link>
        )} */}
        <Link to="/admin/managecredit">Credits</Link>
        {validateAccess(storeUserAccess, "Roles", "") && (
          <Link to="/admin/managerole">Roles</Link>
        )}
        {validateAccess(storeUserAccess, "Users", "") && (
          <Link to="/admin/manageuser">Users</Link>
        )}
        {validateAccess(storeUserAccess, "Bookings", "") && (
          <Link to="/admin/report/booking">Booking Report</Link>
        )}

        {validateAccess(storeUserAccess, "Bookings", "") && (
          <>
            <Link to="/admin/report/member_usage">Member Usage Report</Link>
            <Link to="/admin/report/court_usage">Court Usage Report</Link>
            <Link to="/admin/report/payment"> Booking Payment Reports</Link>
          </>
        )}

        {validateAccess(storeUserAccess, "RecurringBookings", "") && (
          <Link to="/admin/report/recurring/booking">Recurring Bookings</Link>
        )}
        {validateAccess(storeUserAccess, "EmailLogs", "") && (
          <Link to="/admin/report/emaillogs">Email Logs</Link>
        )}
        {validateAccess(storeUserAccess, "AuditLogs", "") && (
          <Link to="/admin/report/auditlog">Audit Logs</Link>
        )}
        {validateAccess(storeUserAccess, "AuditLogs", "") && (
          <>
            <Link to="/admin/report/payment_pending">
              Booking Payment Pending
            </Link>
          </>
        )}
      </div>
    );
  }

  render() {
    const { breakWidth, toggleStateA, windowWidth } = this.state;

    const { browserSize, storeUserAccess } = this.props;

    const navStyle = {
      paddingLeft: windowWidth > breakWidth ? "210px" : "16px",
    };
    const mainStyle = {
      margin: "0 6%",
      paddingTop: "5.5rem",
      paddingLeft: windowWidth > breakWidth ? "240px" : "0",
    };
    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row",
    };
    return (
      <div className="fixed-sn grey-skin">
        {/* {this.validateLogout()} */}
        <MDBSideNav
          triggerOpening={toggleStateA}
          breakWidth={breakWidth}
          bg="https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg"
          mask="strong"
          fixed
        >
          <li>
            <ul className="social">
              <li>
                <Link to="/admin/dashboard">
                  <img src={logo} style={{ height: "35px" }} />
                </Link>
              </li>
            </ul>
          </li>
          <MDBSideNavNav>
            {/* For Default User */}
            <MDBSideNavLink
              to="/admin/mycalendar"
              style={{
                display:
                  validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
                  validateAccess(
                    storeUserAccess,
                    "DefaultUserMenu",
                    "myCalendar"
                  )
                    ? "block"
                    : "none",
              }}
            >
              <MDBIcon icon="calendar-alt" style={{ marginRight: "9px" }} />
              {"My Calendar"}
            </MDBSideNavLink>
            <MDBSideNavCat
              style={{
                display:
                  validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
                  validateAccess(
                    storeUserAccess,
                    "DefaultUserMenu",
                    "userVenues"
                  )
                    ? "block"
                    : "none",
              }}
              name="Make a Booking"
              id="venues-cat"
              icon="location-arrow"
            >
              {this.buildCustomerLocationLink()}
            </MDBSideNavCat>
            <MDBSideNavLink
              to="/admin/mydetails"
              style={{
                display:
                  validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
                  validateAccess(
                    storeUserAccess,
                    "DefaultUserMenu",
                    "userDetails"
                  )
                    ? "block"
                    : "none",
              }}
            >
              <MDBIcon icon="user" style={{ marginRight: "9px" }} />
              {"My Details"}
            </MDBSideNavLink>
            <MDBSideNavLink
              to="/admin/favouritelist"
              style={{
                display: validateAccess(storeUserAccess, "DefaultUserMenu", "")
                  ? "block"
                  : "none",
              }}
            >
              <MDBIcon icon="star" style={{ marginRight: "9px" }} />
              {"Favourite List"}
            </MDBSideNavLink>

            <MDBSideNavLink
              style={{
                display:
                  validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
                  validateAccess(
                    storeUserAccess,
                    "Bookings",
                    "viewBookingDetails"
                  )
                    ? "block"
                    : "none",
              }}
              to="/admin/report/booking"
            >
              <MDBIcon icon="book" style={{ marginRight: "9px" }} />
              {validateAccess(storeUserAccess, "DefaultUserMenu", "")
                ? "My Bookings"
                : "Bookings"}
            </MDBSideNavLink>

            <MDBSideNavLink
              style={{
                display:
                  validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
                  validateAccess(
                    storeUserAccess,
                    "Bookings",
                    "viewBookingDetails"
                  )
                    ? "block"
                    : "none",
              }}
              to="/admin/credits"
            >
              <MDBIcon icon="book" style={{ marginRight: "9px" }} />
              {validateAccess(storeUserAccess, "DefaultUserMenu", "")
                ? "My Credits"
                : "Credits"}
            </MDBSideNavLink>
            {/* For Default User */}

            <MDBSideNavCat
              style={{
                display: validateAccess(storeUserAccess, "Venues", "")
                  ? "block"
                  : "none",
              }}
              name="Venues"
              id="venues-cat"
              icon="location-arrow"
            >
              {validateAccess(storeUserAccess, "Venues", "") &&
                this.buildLocationLink()}
            </MDBSideNavCat>

            <MDBSideNavCat
              style={{
                display: validateAccess(storeUserAccess, "Administrator", "")
                  ? "block"
                  : "none",
              }}
              name="Administrator"
              id="admin-cat"
              icon="user-cog"
            >
              {validateAccess(storeUserAccess, "Administrator", "") &&
                this.renderAdminLinks()}
            </MDBSideNavCat>
          </MDBSideNavNav>
        </MDBSideNav>
        <MDBNavbar style={navStyle} double expand="md" fixed="top" scrolling>
          <MDBNavbarNav left>
            <MDBNavItem>
              <div
                onClick={this.handleToggleClickA}
                key="sideNavToggleA"
                style={{
                  lineHeight: "32px",
                  marginRight: "1em",
                  verticalAlign: "middle",
                }}
              >
                <MDBIcon icon="bars" color="white" size="2x" />
              </div>
            </MDBNavItem>
            <MDBNavItem
              className="d-none d-md-inline"
              style={{ paddingTop: 5 }}
            >
              {this.props.breadcrumbs}
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right style={specialCaseNavbarStyles}>
            {validateAccess(storeUserAccess, "GenericSearch", "") && (
              <MDBNavItem>{this.renderGenericSearch()}</MDBNavItem>
            )}
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <div className="d-none d-md-inline">
                    {this.props.authUser.username}
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu right>
                  {/* <MDBDropdownItem href="#!">Settings</MDBDropdownItem> */}
                  <MDBDropdownItem href="#!" onClick={() => this.doLogout()}>
                    Logout
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBNavbar>
        <main style={mainStyle}>
          <MDBContainer
            fluid
            style={{
              height: 800,
              padding: 0,
            }}
            className={
              "mt-5 " + (browserSize.lessThan.medium ? "mobile-view" : "")
            }
          >
            {this.props.children}

            <CustomerModal />
            {this.props.authUser.organisation != "cust" ? (
              <div>
                <VoucherModal />
                <GenericSearch
                  showSearchModal={this.state.showSearchModal}
                  callback={this.toggleSearchModal}
                />
              </div>
            ) : (
              ""
            )}
            <RoleModal />
            <RoleAccessModal />
            <SubscriptionMoreDetailsModal />
          </MDBContainer>
        </main>
      </div>
    );
  }

  toggleSearchModal = (showSearchModal) => {
    this.setState({ showSearchModal: showSearchModal });
  };

  renderGenericSearch() {
    return (
      <div className="generic-search">
        <MDBFormInline className="md-form">
          <MDBIcon icon="search" />
          <input
            className="form-control form-control-sm ml-3 w-75 generic-search-field"
            type="text"
            placeholder="Search"
            aria-label="Search"
            readOnly
            value="Search here"
            onClick={() => this.toggleSearchModal(true)}
          />
        </MDBFormInline>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    breadcrumbs: state.breadcrumbs,
    userLogout: state.userLogout,
    getLocationMenuSuccessResponse: state.getLocationMenuSuccessResponse,
    getLocationMenuFailedResponse: state.getLocationMenuSuccessResponse,
    browserSize: state.browserSize,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
    authResponse: state.authResponse,
    locations: state.locations,
  };
};

export default withCookies(
  withRouter(
    connect(mapStateToProps, {
      displayBreadcrumbs,
      getLocationMenu,
      revalidateComponent,
      logout,
      showHideLoader,
      searchLocations,
    })(Dashboard)
  )
);
