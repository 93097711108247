import React from "react";
import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
} from "mdbreact";
import { connect } from "react-redux";
import { showHideLoader } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs, showNotification } from "../../../actions";
import _ from "lodash";
import moment from "moment";
import { getLocationList } from "../../../actions/locationAction";
import Autocomplete from "react-autocomplete";

import {
  getMemberUsageReport,
  getMembersList,
} from "../../../apis/ReportingApi";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";

class MemberUsageReport extends React.Component {
  state = {
    startDateFilter: "",
    endDateFilter: "",
    invisibleLines: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    courtUsageLineChartData: [],
    courtUsageBarChartData: [],
    locationSelectOptions: [],
    customerListOptions: [],
    selectedLocation: 2, //Default Tennis
    selectedCustomerId: 0,
    searchMemberText: "",
    totalUsedCourtTimeSlot: 0,
  };

  constructor(props) {
    super(props);
    this.state.startDateFilter = new Date(
      moment().startOf("month").format("YYYY-MM-DD")
    );
    this.state.endDateFilter = new Date(
      moment().endOf("month").format("YYYY-MM-DD")
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  debouncedSearchCustomer = _.debounce(function () {
    this.asyncSearchCustomer();
  }, 500);

  renderCustomerAutoComplete() {
    return (
      <div className="autoComplete">
        <Autocomplete
          autoComplete="off"
          inputProps={{
            id: "states-autocomplete",
            placeholder: "Search member",
          }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          label={"Search Member"}
          value={this.state.searchMemberText}
          items={this.state.customerListOptions}
          getItemValue={(item) => {
            let label =
              item.first_name + " " + item.last_name + " - " + item.email;

            return label;
          }}
          onSelect={(value, item) => {
            let label =
              item.first_name + " " + item.last_name + " - " + item.email;
            this.setState({
              searchMemberText: label,
              selectedCustomerId: item.id,
            });
          }}
          onChange={(event, value) => {
            let stateToChange = {
              searchMemberText: value,
            };
            if (value == "") {
              stateToChange["selectedCustomerId"] = 0;
            }

            this.setState(stateToChange);
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.first_name + " " + item.last_name + ", " + item.email}
            </div>
          )}
        />
      </div>
    );
  }

  handleLineClick = (day) => {
    this.setState((prevState) => ({
      invisibleLines: {
        ...prevState.invisibleLines, // Spread the previous state
        [day]: !prevState.invisibleLines[day], // Toggle the specific day
      },
    }));
  };

  getValueOfSelectedLocation = (value) => {
    value = value.length > 0 ? value[0] : false;

    if (value) {
      this.debouncedDoFilter();
    }

    this.setState({ selectedLocation: value });
  };

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  componentDidMount() {
    this.props.showHideLoader(true);
    this.props.getLocationList({}, this.props.authUser.sessionToken);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Member Usage"
    );
  }

  // Async function to fetch data
  getMemberUsageReportFunc = async (queryParams = {}) => {
    this.props.showHideLoader(true);
    try {
      let response = await getMemberUsageReport(queryParams);
      this.setState({
        courtUsageBarChartData: response.data.records.weekly_data_chart,
        courtUsageLineChartData: response.data.records.daily_chart_data,
        totalUsedCourtTimeSlot:
          response.data.records.total_number_of_used_timeslots,
      });
      // Handle the fetched data
      this.props.showHideLoader(false);
    } catch (error) {
      this.props.showNotification(
        "error",
        "Internal server error encountered. Please contact administrator."
      );
      this.props.showHideLoader(false);
    }
  };

  // Async function to fetch data
  asyncSearchCustomer = async () => {
    try {
      let response = await getMembersList("rande");
      this.setState({
        customerListOptions: response.data.customers,
      });
    } catch (error) {
      this.props.showNotification(
        "error",
        "Internal server error encountered. Please contact administrator."
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
        this.props.getLocationListSuccessResponse &&
      this.props.getLocationListSuccessResponse.status === 200
    ) {
      const locations =
        this.props.getLocationListSuccessResponse.data.locations.results;

      const locationSelectOptions = locations.map((location) => {
        return {
          text: location.name,
          value: location.id,
        };
      });

      this.setState({ locationSelectOptions });
      this.props.showHideLoader(false);
    }

    if (prevState.searchMemberText !== this.state.searchMemberText) {
      this.debouncedSearchCustomer();
    }

    if (prevState.selectedCustomerId !== this.state.selectedCustomerId) {
      this.debouncedDoFilter();
    }
  }

  doFilter() {
    const {
      startDateFilter,
      endDateFilter,
      selectedLocation,
      selectedCustomerId,
    } = this.state;

    let params = {};

    if (startDateFilter) {
      params["start_date"] = moment(startDateFilter).format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["end_date"] = moment(endDateFilter).format("YYYY-MM-DD");
    }

    if (selectedCustomerId && selectedCustomerId > 0) {
      params["customer_id"] = parseInt(selectedCustomerId);
    }

    if (selectedLocation) {
      params["location_id"] = selectedLocation;
    }

    if ("location_id" in params && "customer_id" in params) {
      this.getMemberUsageReportFunc(params);
    }
  }

  render() {
    return (
      <MDBContainer>
        <div>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <h3 className="mt-3">Member Usage Report</h3>
            </MDBCol>
          </MDBRow>

          <MDBRow className="my-4">
            <MDBCol md="2">
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select Start Date"
                  selected={this.state.startDateFilter}
                  maxDate={this.state.endDateFilter}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.startDateFilterOnChange}
                />
              </span>
            </MDBCol>
            <MDBCol md="2">
              {" "}
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select End Date"
                  selected={this.state.endDateFilter}
                  minDate={this.state.startDateFilter}
                  onChange={this.endDateFilterOnChange}
                  dateFormat="dd/MM/yyyy"
                />
              </span>
            </MDBCol>

            <MDBCol md="3">
              <MDBSelect
                outline
                color="primary"
                value={this.props.selectedLocation}
                options={this.state.locationSelectOptions}
                label="Select Location"
                style={{ margin: 0 }}
                getValue={this.getValueOfSelectedLocation}
              />
            </MDBCol>

            <MDBCol md="4">{this.renderCustomerAutoComplete()}</MDBCol>
          </MDBRow>

          <MDBRow className="d-flex justify-content-center">
            <MDBCol md="auto">
              <BarChart
                width={1400}
                height={600}
                data={this.state.courtUsageBarChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis domain={[0, 1]} />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="peak"
                  name="Peak 4PM to 10:30PM"
                  fill="#332e94"
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
                <Bar
                  dataKey="off_peak"
                  name="Off Peak 7AM to 4PM"
                  fill="#f56207"
                  activeBar={<Rectangle fill="gold" stroke="purple" />}
                />
              </BarChart>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mx-4">
            <MDBTable responsive striped bordered>
              <MDBTableHead>
                <tr>
                  <th className="text-center" width="20%">
                    Court Used 7:00am - 4:00pm
                  </th>

                  <th className="text-center" width="20%">
                    Court Used 4:00pm - 10:30pm
                  </th>

                  <th className="text-center" width="20%">
                    Day
                  </th>

                  <th className="text-center" width="20%">
                    Occupancy Rate(%) 7:00am - 4:00pm
                  </th>

                  <th className="text-center" width="20%">
                    Occupancy Rate(%) 4:00pm - 10:30pm
                  </th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {this.state.courtUsageBarChartData.map((data) => {
                  return (
                    <>
                      <tr>
                        <td>{data.off_peak}</td>
                        <td>{data.peak}</td>
                        <td>{data.day}</td>
                        <td>
                          {this.state.totalUsedCourtTimeSlot > 0
                            ? (
                                (data.off_peak /
                                  this.state.totalUsedCourtTimeSlot) *
                                100
                              ).toFixed(2)
                            : 0}
                          %
                        </td>
                        <td>
                          {this.state.totalUsedCourtTimeSlot > 0
                            ? (
                                (data.peak /
                                  this.state.totalUsedCourtTimeSlot) *
                                100
                              ).toFixed(2)
                            : 0}{" "}
                          %
                        </td>
                      </tr>
                    </>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </MDBRow>

          <MDBRow>
            <MDBCol md="12">
              <h4 className="mt-3">
                Member Usage Report By Days and Time (30 Mins Interval)
              </h4>
            </MDBCol>
          </MDBRow>
          <MDBRow className="d-flex justify-content-center">
            <MDBCol md="auto">
              <LineChart
                width={1400}
                height={600}
                data={this.state.courtUsageLineChartData}
              >
                <CartesianGrid strokeDasharray="6 6" />
                <XAxis dataKey="timeslot" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <Tooltip />
                <Legend onClick={({ value }) => this.handleLineClick(value)} />

                <Line
                  hide={this.state.invisibleLines.Monday}
                  type="monotone"
                  dataKey="Monday"
                  stroke="#064e21"
                />
                <Line
                  hide={this.state.invisibleLines.Tuesday}
                  type="monotone"
                  dataKey="Tuesday"
                  stroke="#d0a407"
                />
                <Line
                  hide={this.state.invisibleLines.Wednesday}
                  type="monotone"
                  dataKey="Wednesday"
                  stroke="#ff0000"
                />
                <Line
                  hide={this.state.invisibleLines.Thursday}
                  type="monotone"
                  dataKey="Thursday"
                  stroke="#82ca9d"
                />
                <Line
                  hide={this.state.invisibleLines.Friday}
                  type="monotone"
                  dataKey="Friday"
                  stroke="#0a2815"
                />
                <Line
                  hide={this.state.invisibleLines.Saturday}
                  type="monotone"
                  dataKey="Saturday"
                  stroke="#ff0582"
                />
                <Line
                  hide={this.state.invisibleLines.Sunday}
                  type="monotone"
                  dataKey="Sunday"
                  stroke="#000000"
                />
              </LineChart>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getLocationListFailedResponse: state.getLocationListFailedResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    showNotification,
    getLocationList,
  })(MemberUsageReport)
);
