import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";
import { Multiselect } from "multiselect-react-dropdown";
import {
  getPricingTriggerTypes,
  getPricingTriggers,
} from "../../../../actions/pricingAction";
import { showHideLoader, showNotification } from "../../../../actions";
import {
  createTrigger,
  updatePricingTrigger,
} from "../../../../apis/PricingApi";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBModalHeader,
} from "mdbreact";
import _ from "lodash";

const DEFAULT_TRIGGER_DATA = {
  description: "",
  trigger_type: "",
  trigger_condition: "",
};

class modal extends React.Component {
  constructor(props) {
    super(props);

    this.multiselectRef = React.createRef();
  }

  state = {
    collapseID: "pricingDetailsCollapse",
    openedCollapseIds: ["pricingDetailsCollapse"],
    triggerData: DEFAULT_TRIGGER_DATA,
    selectedTriggerType: {},
    pricingTriggerTypes: {},
    multiSelectOptionList: [],
    selectedValues: [],
    range_value_from: "",
    range_value_to: "",
  };

  formDataHandler = (event) => {
    const { triggerData } = this.state;
    this.setState({
      triggerData: { ...triggerData, [event.target.name]: event.target.value },
    });
  };

  errorHandler = () => {
    this.props.showPricingTriggerModal(false, false, DEFAULT_TRIGGER_DATA);
    this.props.showHideLoader(false);
    this.props.showNotification(
      "error",
      "Error encountered. Please contact administrator."
    );
  };
  submitHandler = async (event) => {
    event.preventDefault();

    const { triggerData, selectedValues, range_value_from, range_value_to } =
      this.state;

    let trigger_values = [];
    if (["is_in", "not_in"].includes(triggerData["trigger_condition"])) {
      trigger_values = selectedValues.map((v) => {
        return { datatype: "integer", value: v["value"] };
      });
    } else if (
      ["in_between", "not_in_between"].includes(
        triggerData["trigger_condition"]
      )
    ) {
      trigger_values = [
        { datatype: "range_from", value: range_value_from },
        { datatype: "range_to", value: range_value_to },
      ];
    }

    let formData = {
      description: triggerData["description"],
      trigger_type: triggerData["trigger_type"],
      trigger_condition: triggerData["trigger_condition"],
      trigger_values: trigger_values,
    };

    this.props.showHideLoader(true);

    let response;
    if (this.props.isEdit) {
      response = await updatePricingTrigger(triggerData["uuid"], formData);
    } else {
      response = await createTrigger(formData);
    }

    if (response) {
      this.props.showPricingTriggerModal(false, false, DEFAULT_TRIGGER_DATA);

      switch (response.status) {
        case 200:
        case 201:
          this.props.getPricingTriggers();
          this.props.showNotification("success", response.data.message);
          break;
        case 204:
          this.props.showNotification("success", "No changes made.");
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
      this.props.showHideLoader(false);
    } else {
      this.errorHandler();
    }
  };

  toggleAccordion = (collapseID) => {
    let { openedCollapseIds } = this.state;

    if (openedCollapseIds.includes(collapseID)) {
      openedCollapseIds = openedCollapseIds.filter(
        (collapseId) => collapseId != collapseID
      );
    } else {
      openedCollapseIds.push(collapseID);
    }

    this.setState({ openedCollapseIds });
  };

  renderTriggerTypeCondition(condition) {
    let displayText = "";
    switch (condition) {
      case "is_in":
        displayText = "Is in";
        break;
      case "not_in":
        displayText = "Not In";
        break;
      case "in_between":
        displayText = "Between";
        break;
      case "not_in_between":
        displayText = "Not in between";
        break;

      default:
        break;
    }

    return displayText;
  }

  componentDidMount() {
    this.props.getPricingTriggerTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.triggerData !== this.props.triggerData) {
      if (this.props.triggerData) {
        this.setState({ triggerData: this.props.triggerData });
      }
    }

    // Set Trigger list
    if (prevProps.pricingResponse !== this.props.pricingResponse) {
      let response = this.props.pricingResponse;

      this.props.showHideLoader(false);
      if (
        response.action == "LIST" &&
        response.type == "GET_PRICING_TRIGGER_TYPES"
      ) {
        if (response.status >= 200 && response.status <= 299) {
          this.setState({
            pricingTriggerTypes: response.data.records,
          });
        }
      }
    }

    this.onChangedTriggerType(prevState);
    this.setDefaultValues(prevState);
  }

  setDefaultValues = (prevState) => {
    const { triggerData } = this.state;
    if (prevState.triggerData !== triggerData && triggerData.id) {
      switch (triggerData["trigger_condition"]) {
        case "is_in":
        case "not_in":
          const currentSelected = triggerData.values.map((value) => {
            let data = {};

            switch (value["type"]) {
              case "days_of_the_week":
                data = {
                  value: value.day["value"],
                  text: value.day["text"],
                };
                break;
              case "membership_type":
                if (value.membership) {
                  data = {
                    text:
                      value.membership["id"] +
                      "." +
                      value.membership["name"] +
                      " - " +
                      value.membership["membership_type_name"],
                    value: value.membership["id"],
                  };
                }
                break;
              case "organisation_location":
                data = {
                  text: value.location["name"],
                  value: value.location["id"],
                };
                break;

              case "location_resource":
                data = {
                  text: value.resource["name"],
                  value: value.resource["id"],
                };
                break;

              default:
                break;
            }

            return data;
          });

          this.setState({ selectedValues: currentSelected });

          break;

        case "in_between":
        case "not_in_between":
          const range_value_from =
            triggerData?.values_in_range?.from?.value !== undefined
              ? triggerData?.values_in_range?.from?.value
              : "";
          const range_value_to =
            triggerData?.values_in_range?.from?.value !== undefined
              ? triggerData?.values_in_range?.to?.value
              : "";
          this.setState({ range_value_from, range_value_to });

          break;

        default:
          break;
      }
    }
  };

  renderInBetweenFields() {
    let renderedHTML = "";

    const {
      range_value_from,
      range_value_to,
      pricingTriggerTypes,
      triggerData,
    } = this.state;

    const optionList = pricingTriggerTypes["days_of_the_week"]["option_list"];

    switch (triggerData.trigger_type) {
      case "days_of_the_week":
        renderedHTML = (
          <>
            <MDBCol md="6">
              <label htmlFor="range_value_from" className="grey-text">
                <span className="text-danger">*</span> From:
              </label>

              <select
                value={range_value_from}
                id="range_value_from"
                className="form-control"
                onChange={(event) => {
                  this.setState({ range_value_from: event.target.value });
                }}
                required
              >
                <option value="">--Select From --</option>
                {optionList.map((option) => {
                  return (
                    <option value={option["value"]}>{option["text"]}</option>
                  );
                })}
              </select>
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="range_value_to" className="grey-text">
                <span className="text-danger">*</span> To:
              </label>

              <select
                value={range_value_to}
                id="range_value_to"
                className="form-control"
                onChange={(event) => {
                  this.setState({ range_value_to: event.target.value });
                }}
                required
              >
                <option value="">--Select To --</option>
                {optionList.map((option) => {
                  let isDisabled = false;

                  if (option["value"] == range_value_from) {
                    isDisabled = true;
                  }

                  return (
                    <option disabled={isDisabled} value={option["value"]}>
                      {option["text"]}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
          </>
        );
        break;

      case "hours_in_military_time":
        renderedHTML = (
          <>
            <MDBCol md="6">
              <label htmlFor="rule_trigger_value_from" className="grey-text">
                <span className="text-danger">*</span> From:
              </label>

              <input
                value={this.state.range_value_from}
                className="form-control"
                onChange={(event) => {
                  this.setState({ range_value_from: event.target.value });
                }}
                required
                pattern="^([01]\d|2[0-3]):?([0-5]\d)$"
                type="text"
              />
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="rule_trigger_value_to" className="grey-text">
                <span className="text-danger">*</span> To:
              </label>
              <input
                value={this.state.range_value_to}
                className="form-control"
                onChange={(event) => {
                  this.setState({ range_value_to: event.target.value });
                }}
                pattern="^([01]\d|2[0-3]):?([0-5]\d)$"
                required
                type="text"
              />
            </MDBCol>
          </>
        );
        break;

      default:
        break;
    }

    return renderedHTML;
  }

  renderIsInFields() {
    let renderedHTML = (
      <MDBCol md="" className="mb-3">
        <label className="grey-text">Values</label>
        <Multiselect
          ref={this.multiselectRef}
          displayValue="text"
          customCloseIcon={
            <>
              <MDBIcon icon={"minus-circle"}> </MDBIcon>
            </>
          }
          onRemove={(selectedList) => {
            const selectedTriggerValues = selectedList.map((selectedOption) => {
              return {
                value: selectedOption["value"],
                text: selectedOption["text"],
              };
            });
            this.setState({
              selectedValues: selectedTriggerValues,
            });
          }}
          onSelect={(selectedList) => {
            const selectedTriggerValues = selectedList.map((selectedOption) => {
              return {
                value: selectedOption["value"],
                text: selectedOption["text"],
              };
            });
            this.setState({
              selectedValues: selectedTriggerValues,
            });
          }}
          options={this.state.multiSelectOptionList}
          selectedValues={this.state.selectedValues}
        />
      </MDBCol>
    );
    return renderedHTML;
  }

  onChangedTriggerType = (prevState) => {
    const { triggerData, pricingTriggerTypes } = this.state;
    // On changed Trigger Type
    if (
      prevState.triggerData !== triggerData &&
      triggerData.trigger_type !== ""
    ) {
      const selectedTriggerType = pricingTriggerTypes[triggerData.trigger_type];

      const multiSelectOptionList =
        this.setTriggerTypeOptionList(selectedTriggerType);

      this.setState({
        selectedTriggerType,
        multiSelectOptionList,
      });
    }
  };

  setTriggerTypeOptionList = (triggerType) => {
    let options = [];
    switch (triggerType["shortname"]) {
      case "days_of_the_week":
        options = triggerType["option_list"];
        break;
      case "membership_type":
        options = triggerType["option_list"].map((membership) => {
          return {
            text:
              membership["id"] +
              "." +
              membership["name"] +
              " - " +
              membership["type"],
            value: membership["id"],
          };
        });
        break;

      case "organisation_location":
      case "location_resource":
        options = triggerType["option_list"].map((data) => {
          return {
            text: data["name"],
            value: data["id"],
          };
        });
        break;

      default:
        break;
    }

    return options;
  };

  render() {
    const { triggerData, selectedTriggerType, pricingTriggerTypes } =
      this.state;

    return (
      <>
        <div className="child-popup">
          <MDBModal
            isOpen={this.props.isShow}
            size="md"
            toggle={() =>
              this.props.showPricingTriggerModal(
                false,
                false,
                DEFAULT_TRIGGER_DATA
              )
            }
          >
            <form
              className={`needs-validation ${this.state.wasValidatedRuleTriggers}`}
              onSubmit={this.submitHandler}
              noValidate
            >
              <MDBModalHeader>
                {this.props.isEdit ? "Edit" : "Add"} Rule Trigger
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="description" className="grey-text">
                      <span className="text-danger">*</span> Description
                    </label>

                    <textarea
                      value={triggerData.description}
                      name="description"
                      id="description"
                      onChange={this.formDataHandler}
                      type="textarea"
                      className="form-control"
                      rows="3"
                      style={{ resize: "none" }}
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide description
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="trigger_type" className="grey-text">
                      <span className="text-danger">*</span> Trigger type
                    </label>

                    <select
                      id="trigger_type"
                      name="trigger_type"
                      className="form-control"
                      value={triggerData.trigger_type}
                      onChange={this.formDataHandler}
                      disabled={this.props.isEdit}
                      required
                    >
                      <option value="">--Select Trigger Type--</option>

                      {Object.keys(pricingTriggerTypes).map((key) => {
                        const triggerType = pricingTriggerTypes[key];
                        return (
                          <option
                            value={triggerType["shortname"]}
                            disabled={triggerType["shortname"] == ""}
                          >
                            {triggerType["display_name"]}
                          </option>
                        );
                      })}
                    </select>
                    <div className="invalid-feedback">
                      Please select trigger type
                    </div>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="trigger_condition" className="grey-text">
                      <span className="text-danger">*</span>Condition
                    </label>
                    <select
                      id="trigger_condition"
                      name="trigger_condition"
                      className="form-control"
                      value={triggerData.trigger_condition}
                      onChange={this.formDataHandler}
                      required
                    >
                      <option value={""} disabled={true} selected>
                        --Select Condition--
                      </option>

                      {selectedTriggerType.allowed_opperands &&
                        selectedTriggerType.allowed_opperands.map(
                          (opperand) => {
                            return (
                              <option value={opperand}>
                                {this.renderTriggerTypeCondition(opperand)}
                              </option>
                            );
                          }
                        )}
                    </select>
                    <div className="invalid-feedback">
                      Please select trigger condition
                    </div>
                  </MDBCol>
                </MDBRow>

                {triggerData.trigger_condition != "" && (
                  <MDBRow>
                    {["in_between", "not_in_between"].includes(
                      triggerData.trigger_condition
                    ) && this.renderInBetweenFields()}

                    {["is_in", "not_in"].includes(
                      triggerData.trigger_condition
                    ) && this.renderIsInFields()}
                  </MDBRow>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() =>
                    this.props.showPricingTriggerModal(
                      false,
                      false,
                      DEFAULT_TRIGGER_DATA
                    )
                  }
                >
                  Close
                </MDBBtn>
                <MDBBtn color="primary" type="submit">
                  {this.props.isEdit ? "Save" : "Add"}
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    pricingResponse: state.pricingResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPricingTriggerTypes,
    showHideLoader,
    showNotification,
    getPricingTriggers,
  })(modal)
);
