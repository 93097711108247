import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

import {
  createPricing,
  updatePricing,
  updatePricingConditions,
  createPricingDiscount,
  deletePricingDiscount,
} from "../../../../apis/PricingApi";
import {
  getPricingTriggers,
  getPricing,
} from "../../../../actions/pricingAction";

import { showHideLoader, showNotification } from "../../../../actions";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
} from "mdbreact";

import { Multiselect } from "multiselect-react-dropdown";

import _ from "lodash";
const DEFAULT_PRICING_DATA = {
  display_name: "",
  description: "",
  display_name: "",
  customer_type: "",
  pricing_type: "",
  price_per_block: 0,
  booking_price_cap: 0,
  discount_price: 0,
  discount_duration_in_mins: 0,
  hierarchy: 1,
};

class PricingModal extends React.Component {
  constructor(props) {
    super(props);

    this.multiselectRef = React.createRef();
  }
  state = {
    collapseID: "pricingDetailsCollapse",
    openedCollapseIds: ["pricingDetailsCollapse"],
    pricingData: DEFAULT_PRICING_DATA,
    pricingTypesOptions: [
      {
        value: "with_additional_guest_fee",
        text: "With additional guest fee",
        customer_type: "primary",
      },
      {
        value: "no_additional_guest_fee",
        text: "No guest fee",
        customer_type: "primary",
      },
      {
        value: "with_discount_fee",
        text: "With discount",
        customer_type: "guest",
      },
      {
        value: "no_discount_fee",
        text: "No discount",
        customer_type: "guest",
      },
    ],
    customerTypesOptions: [
      {
        value: "primary",
        text: "Primary",
      },
      {
        value: "guest",
        text: "Guest",
      },
    ],

    multiSelectOptionList: [],
    selectedValues: [],
  };

  formDataHandler = (event) => {
    const { pricingData } = this.state;
    this.setState({
      pricingData: { ...pricingData, [event.target.name]: event.target.value },
    });
  };

  errorHandler = () => {
    this.props.showPricingModal(false, false, DEFAULT_PRICING_DATA);
    this.props.showHideLoader(false);
    this.props.showNotification(
      "error",
      "Error encountered. Please contact administrator."
    );
  };

  submitHandler = async (event) => {
    const { pricingData } = this.state;

    let formData = {
      display_name: pricingData["display_name"],
      description: pricingData["description"],
      customer_type: pricingData["customer_type"],
      pricing_type: pricingData["pricing_type"],
      price_per_block: pricingData["price_per_block"],
      booking_price_cap: pricingData["booking_price_cap"],
      hierarchy: pricingData["hierarchy"],
    };

    event.preventDefault();
    this.props.showHideLoader(true);

    let response;
    if (this.props.isEdit) {
      response = await updatePricing(pricingData["uuid"], formData);
    } else {
      response = await createPricing(formData);
    }

    if (response) {
      this.props.showPricingModal(false, false, DEFAULT_PRICING_DATA);

      switch (response.status) {
        case 200:
        case 201:
          this.props.getPricing();
          this.props.showNotification("success", response.data.message);
          break;
        case 204:
          this.props.showNotification("success", "No changes made.");
          this.props.showHideLoader(false);

          break;

        default:
          this.props.showNotification("error", response.data.message);
          this.props.showHideLoader(false);
          break;
      }
    } else {
      this.errorHandler();
    }
  };

  submitPricingConditionUpdate = async () => {
    const { selectedValues, pricingData } = this.state;

    this.props.showHideLoader(true);

    const pricing_conditions_list = selectedValues.map((condition) => {
      return { pricing_trigger_id: condition["value"] };
    });

    let response;
    const body = {
      pricing_conditions: pricing_conditions_list,
    };
    response = await updatePricingConditions(pricingData["uuid"], body);

    if (response) {
      this.props.showPricingModal(false, false, DEFAULT_PRICING_DATA);

      switch (response.status) {
        case 200:
        case 201:
          this.props.getPricing();
          this.props.showNotification("success", response.data.message);
          break;
        case 204:
          this.props.showNotification("success", "No changes made.");
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
      this.props.showHideLoader(false);
    } else {
      this.errorHandler();
    }
  };

  submitDeletePricingDiscount = (pricing_discount_uuid) => {
    var result = DevExtremeConfirm(`Are you sure you want to delete discount?`);

    result.then(async (dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);
        let response = await deletePricingDiscount(pricing_discount_uuid);

        if (response) {
          this.props.showPricingModal(false, false, DEFAULT_PRICING_DATA);

          switch (response.status) {
            case 200:
            case 201:
              this.props.getPricing();
              this.props.showNotification("success", response.data.message);
              break;
            case 204:
              this.props.showNotification("success", "No changes made.");
              break;

            default:
              this.props.showNotification("error", response.data.message);
              break;
          }
          this.props.showHideLoader(false);
        } else {
          this.errorHandler();
        }
      }
    });
  };

  submitCreatePricingDiscount = async () => {
    const { pricingData } = this.state;

    this.props.showHideLoader(true);

    const body = {
      pricing_id: pricingData.id,
      duration_in_mins: pricingData.discount_duration_in_mins,
      price: pricingData.discount_price,
    };

    let response = await createPricingDiscount(pricingData["uuid"], body);

    if (response) {
      this.props.showPricingModal(false, false, DEFAULT_PRICING_DATA);

      switch (response.status) {
        case 200:
        case 201:
          this.props.getPricing();
          this.props.showNotification("success", response.data.message);
          break;
        case 204:
          this.props.showNotification("success", "No changes made.");
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
      this.props.showHideLoader(false);
    } else {
      this.errorHandler();
    }
  };

  toggleAccordion = (collapseID) => {
    let { openedCollapseIds } = this.state;

    if (openedCollapseIds.includes(collapseID)) {
      openedCollapseIds = openedCollapseIds.filter(
        (collapseId) => collapseId != collapseID
      );
    } else {
      openedCollapseIds.push(collapseID);
    }

    this.setState({ openedCollapseIds });
  };

  componentDidMount() {
    this.props.getPricingTriggers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pricingData !== this.props.pricingData) {
      if (this.props.pricingData) {
        // Set the price type option on load
        this.setState({ pricingData: this.props.pricingData });
      }
    }

    // Set Trigger list
    if (prevProps.pricingResponse !== this.props.pricingResponse) {
      let response = this.props.pricingResponse;

      this.props.showHideLoader(false);
      if (
        response.action == "LIST" &&
        response.type == "GET_PRICING_TRIGGERS"
      ) {
        if (response.status >= 200 && response.status <= 299) {
          let optionList = response.data.records.map((record) => {
            return { text: record["description"], value: record.id };
          });
          this.setState({
            multiSelectOptionList: optionList,
          });
        }
      }
    }

    this.setTriggerConditionMultiSelect(prevState);
  }

  setTriggerConditionMultiSelect = (prevState) => {
    const { pricingData } = this.state;
    if (prevState.pricingData !== pricingData && pricingData.id) {
      const currentSelected = pricingData.pricing_conditions.map(
        (condition) => {
          return {
            text: condition.pricing_trigger["description"],
            value: condition.pricing_trigger["id"],
          };
        }
      );

      this.setState({ selectedValues: currentSelected });
    }
  };

  renderTriggerTypeCondition(condition) {
    let displayText = "";
    switch (condition) {
      case "is_in":
        displayText = "Is in";
        break;
      case "not_in":
        displayText = "Not In";
        break;
      case "in_between":
        displayText = "Between";
        break;
      case "not_in_between":
        displayText = "Not in between";
        break;

      default:
        break;
    }

    return displayText;
  }

  renderTriggerValues(trigger) {
    let html = "";
    if (["in_between", "not_in_between"].includes(trigger.trigger_condition)) {
      switch (trigger.trigger_type) {
        case "hours_in_military_time":
          html = (
            <>
              {trigger.values_in_range && (
                <>
                  <ul>
                    <li>From: {trigger.values_in_range.from.value}</li>
                    <li>To: {trigger.values_in_range.to.value}</li>
                  </ul>
                </>
              )}
            </>
          );
          break;

        default:
          break;
      }
    } else if (["is_in", "not_in"].includes(trigger.trigger_condition)) {
      switch (trigger.trigger_type) {
        case "organisation_location":
          html = trigger.values.map((value) => {
            return (
              <>
                <ul>
                  <li>{value.location && <>{value.location.name} </>}</li>
                </ul>
              </>
            );
          });
          break;
        case "membership_type":
          html = trigger.values.map((value) => {
            return (
              <>
                <ul>
                  <li>
                    {value.membership && (
                      <>
                        {value.membership.name} -{" "}
                        {value.membership.membership_type_name}
                      </>
                    )}
                  </li>
                </ul>
              </>
            );
          });
          break;

        case "days_of_the_week":
          html = trigger.values.map((value) => {
            return (
              <>
                <ul>
                  <li>{value.day && <>{value.day.text}</>}</li>
                </ul>
              </>
            );
          });
          break;

        default:
          break;
      }
    }

    return html;
  }

  render() {
    const {
      openedCollapseIds,
      pricingTypesOptions,
      customerTypesOptions,
      pricingData,
    } = this.state;

    return (
      <>
        <div className="child-popup">
          <MDBModal
            isOpen={this.props.isShow}
            size="lg"
            toggle={() =>
              this.props.showPricingModal(false, false, DEFAULT_PRICING_DATA)
            }
          >
            <MDBModalBody>
              <MDBContainer className="md-accordion">
                <MDBCard className="mt-3">
                  <MDBCollapseHeader
                    tagClassName="d-flex justify-content-between"
                    onClick={() =>
                      this.toggleAccordion("pricingDetailsCollapse")
                    }
                    className="text-primary font-weight-bold"
                  >
                    Pricing Details
                    <MDBIcon
                      icon={
                        openedCollapseIds.includes("pricingDetailsCollapse")
                          ? "angle-up"
                          : "angle-down"
                      }
                    />
                  </MDBCollapseHeader>

                  <MDBCollapse
                    id={"pricingDetailsCollapse"}
                    isOpen={openedCollapseIds.includes(
                      "pricingDetailsCollapse"
                    )}
                  >
                    <MDBCardBody>
                      <form
                        className={`needs-validation ${this.state.wasValidatedRuleDetails}`}
                        onSubmit={this.submitHandler}
                        noValidate
                      >
                        <MDBRow>
                          <MDBCol md="12" className="mb-3">
                            <label htmlFor="display_name" className="grey-text">
                              <span className="text-danger">*</span> Display
                              name
                            </label>
                            <input
                              value={pricingData.display_name}
                              onChange={this.formDataHandler}
                              name="display_name"
                              type="text"
                              id="display_name"
                              className="form-control"
                              placeholder="Enter display name..."
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide display name
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="6" className="mb-3">
                            <label
                              htmlFor="customer_type"
                              className="grey-text"
                            >
                              <span className="text-danger">*</span> Customer
                              Type{" "}
                            </label>

                            <select
                              id="customer_type"
                              name="customer_type"
                              className="form-control"
                              value={pricingData.customer_type}
                              onChange={(event) => {
                                this.setState({
                                  pricingData: {
                                    ...this.state.pricingData,
                                    customer_type: event.target.value,
                                    pricing_type: "",
                                  },
                                });
                              }}
                              required
                            >
                              <option value="">--Select Customer Type--</option>
                              {customerTypesOptions.map((customerType) => (
                                <option value={customerType["value"]}>
                                  {customerType["text"]}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              Please select Customer Type
                            </div>
                          </MDBCol>

                          <MDBCol md="6" className="mb-3">
                            <label htmlFor="pricing_type" className="grey-text">
                              <span className="text-danger">*</span> Pricing
                              Type
                            </label>

                            <select
                              id="pricing_type"
                              name="pricing_type"
                              className="form-control"
                              defaultValue={pricingData.pricing_type}
                              value={pricingData.pricing_type}
                              onChange={this.formDataHandler}
                              required
                            >
                              <option value="">--Select Pricing Type--</option>
                              {pricingTypesOptions.map((pricingType) => {
                                if (
                                  pricingType["customer_type"] ==
                                  pricingData.customer_type
                                ) {
                                  return (
                                    <option value={pricingType["value"]}>
                                      {pricingType["text"]}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                            <div className="invalid-feedback">
                              Please select Pricing Type
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="2" className="mb-3">
                            <label htmlFor="hierarchy" className="grey-text">
                              <span className="text-danger">*</span> Hierarchy
                            </label>

                            <input
                              value={pricingData.hierarchy}
                              onChange={this.formDataHandler}
                              name="hierarchy"
                              type="number"
                              id="hierarchy"
                              className="form-control"
                              placeholder="1"
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide hierarchy
                            </div>
                          </MDBCol>
                          <MDBCol md="5" className="mb-3">
                            <label
                              htmlFor="price_per_block"
                              className="grey-text"
                            >
                              <span className="text-danger">*</span> Price (per
                              block)
                            </label>
                            <input
                              value={pricingData.price_per_block}
                              onChange={this.formDataHandler}
                              name="price_per_block"
                              type="number"
                              id="price_per_block"
                              className="form-control"
                              placeholder="0.00"
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide price per block
                            </div>
                          </MDBCol>

                          <MDBCol md="5" className="mb-3">
                            <label
                              htmlFor="booking_price_cap"
                              className="grey-text"
                            >
                              <span className="text-danger">*</span> Price cap
                            </label>
                            <input
                              value={pricingData.booking_price_cap}
                              onChange={this.formDataHandler}
                              name="booking_price_cap"
                              type="number"
                              id="booking_price_cap"
                              className="form-control"
                              placeholder="0.00"
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide price cap value
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="12" className="mb-3">
                            <label htmlFor="description" className="grey-text">
                              <span className="text-danger">*</span> Description
                            </label>

                            <textarea
                              value={pricingData.description}
                              name="description"
                              onChange={this.formDataHandler}
                              type="textarea"
                              id="description"
                              className="form-control"
                              rows="3"
                              style={{ resize: "none" }}
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide description
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="mb-4">
                          <MDBCol md="6"></MDBCol>
                          <MDBCol md="6">
                            <MDBBtn
                              color="primary"
                              size="sm"
                              type="submit"
                              className="float-right"
                            >
                              {this.props.isEdit
                                ? "Update Details"
                                : "Create Pricing"}
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </form>
                    </MDBCardBody>
                  </MDBCollapse>
                </MDBCard>
              </MDBContainer>

              {this.props.isEdit && pricingData.id && (
                <>
                  <MDBContainer className="md-accordion">
                    <MDBCard className="mt-3">
                      <MDBCollapseHeader
                        tagClassName="d-flex justify-content-between"
                        onClick={() =>
                          this.toggleAccordion("pricingConditionsCollapse")
                        }
                        className="text-primary font-weight-bold"
                      >
                        Conditions
                        <MDBIcon
                          icon={
                            openedCollapseIds.includes(
                              "pricingConditionsCollapse"
                            )
                              ? "angle-up"
                              : "angle-down"
                          }
                        />
                      </MDBCollapseHeader>

                      <MDBCollapse
                        id={"pricingConditionsCollapse"}
                        isOpen={openedCollapseIds.includes(
                          "pricingConditionsCollapse"
                        )}
                      >
                        <MDBCardBody>
                          <MDBRow className="mb-4">
                            <MDBCol md="12">
                              <label className="grey-text">Triggers</label>
                              <Multiselect
                                ref={this.multiselectRef}
                                displayValue="text"
                                customCloseIcon={
                                  <>
                                    <MDBIcon icon={"minus-circle"}> </MDBIcon>
                                  </>
                                }
                                onRemove={(selectedList) => {
                                  const selectedTriggerValues =
                                    selectedList.map((selectedOption) => {
                                      return {
                                        value: selectedOption["value"],
                                        text: selectedOption["text"],
                                      };
                                    });
                                  this.setState({
                                    selectedValues: selectedTriggerValues,
                                  });
                                }}
                                onSelect={(selectedList) => {
                                  const selectedTriggerValues =
                                    selectedList.map((selectedOption) => {
                                      return {
                                        value: selectedOption["value"],
                                        text: selectedOption["text"],
                                      };
                                    });
                                  this.setState({
                                    selectedValues: selectedTriggerValues,
                                  });
                                }}
                                options={this.state.multiSelectOptionList}
                                selectedValues={this.state.selectedValues}
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mb-4">
                            <MDBCol md="6"></MDBCol>
                            <MDBCol md="6">
                              <MDBBtn
                                color="primary"
                                size="sm"
                                type="button"
                                className="float-right"
                                onClick={() => {
                                  this.submitPricingConditionUpdate();
                                }}
                              >
                                Update condition
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>
                          <MDBTable responsive striped bordered>
                            <MDBTableHead>
                              <tr>
                                <th className="text-center" width="20%">
                                  Description
                                </th>
                                <th className="text-center" width="15%">
                                  {" "}
                                  Trigger Type
                                </th>
                                <th className="text-center" width="15%">
                                  Condition
                                </th>

                                <th className="text-center">Values</th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {pricingData.pricing_conditions.map(
                                (condition) => {
                                  let trigger = condition.pricing_trigger;
                                  return (
                                    <tr>
                                      <td>{trigger.description}</td>
                                      <td>{trigger.type_display_name}</td>
                                      <td>
                                        {this.renderTriggerTypeCondition(
                                          trigger.trigger_condition
                                        )}
                                      </td>

                                      <td>
                                        {this.renderTriggerValues(trigger)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </MDBTableBody>
                          </MDBTable>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>
                  <MDBContainer className="md-accordion">
                    <MDBCard className="mt-3">
                      <MDBCollapseHeader
                        tagClassName="d-flex justify-content-between"
                        onClick={() =>
                          this.toggleAccordion("pricingDiscountsCollapse")
                        }
                        className="text-primary font-weight-bold"
                      >
                        Discounts
                        <MDBIcon
                          icon={
                            openedCollapseIds.includes(
                              "pricingDiscountsCollapse"
                            )
                              ? "angle-up"
                              : "angle-down"
                          }
                        />
                      </MDBCollapseHeader>

                      <MDBCollapse
                        id={"pricingDiscountsCollapse"}
                        isOpen={openedCollapseIds.includes(
                          "pricingDiscountsCollapse"
                        )}
                      >
                        <MDBCardBody>
                          <MDBRow>
                            <MDBCol md="6" className="mb-3">
                              <label
                                htmlFor="discount_duration_in_mins"
                                className="grey-text"
                              >
                                <span className="text-danger">*</span> Duration
                                in minutes
                              </label>
                              <input
                                value={pricingData.discount_duration_in_mins}
                                onChange={this.formDataHandler}
                                name="discount_duration_in_mins"
                                type="number"
                                id="discount_duration_in_mins"
                                className="form-control"
                                placeholder="0"
                                required
                              />
                              <div className="invalid-feedback">
                                Please provide duration
                              </div>
                            </MDBCol>

                            <MDBCol md="6" className="mb-3">
                              <label
                                htmlFor="discount_price"
                                className="grey-text"
                              >
                                <span className="text-danger">*</span> Price
                              </label>
                              <input
                                value={pricingData.discount_price}
                                onChange={this.formDataHandler}
                                name="discount_price"
                                type="number"
                                id="discount_price"
                                className="form-control"
                                placeholder="0.00"
                                required
                              />
                              <div className="invalid-feedback">
                                Please provide price
                              </div>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="mb-4">
                            <MDBCol md="6"></MDBCol>
                            <MDBCol md="6">
                              <MDBBtn
                                color="primary"
                                size="sm"
                                type="button"
                                className="float-right"
                                onClick={() => {
                                  this.submitCreatePricingDiscount();
                                }}
                              >
                                Add Discount
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>
                          <MDBTable responsive striped bordered>
                            <MDBTableHead>
                              <tr>
                                <th className="text-center" width="45%">
                                  Duration
                                </th>
                                <th className="text-center" width="45%">
                                  {" "}
                                  Discounted Price
                                </th>

                                <th className="text-center"></th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {pricingData.discounts.map((discount) => {
                                return (
                                  <tr>
                                    <td>
                                      {discount.duration_in_mins} minute(s)
                                    </td>
                                    <td>{discount.price.toFixed(2)}</td>
                                    <td className="text-center">
                                      <MDBIcon
                                        style={{ cursor: "pointer" }}
                                        icon="trash"
                                        className="danger-text"
                                        onClick={() => {
                                          this.submitDeletePricingDiscount(
                                            discount["uuid"]
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </MDBTableBody>
                          </MDBTable>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>
                </>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() =>
                  this.props.showPricingModal(
                    false,
                    false,
                    DEFAULT_PRICING_DATA
                  )
                }
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    pricingResponse: state.pricingResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPricingTriggers,
    getPricing,
    showHideLoader,
    showNotification,
  })(PricingModal)
);
