import React from "react";
import { MDBContainer } from "mdbreact";

class PageTitle extends React.Component {
  renderContent() {
    return (
      <div>
        <MDBContainer className="text-center">
          <h3>{this.props.title}</h3>
          <h5>{this.props.subTitle}</h5>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default PageTitle;
