import { SET_INTERVAL_ID, GET_INTERVAL_ID } from "../actions/types";
export default (state = {}, action) => {
  switch (action.type) {
    case GET_INTERVAL_ID:
      return action.payload;
    case SET_INTERVAL_ID:
      return action.payload;
    default:
      return state;
  }
};
