import React from "react";
import { MDBContainer, MDBInputGroup, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { validateSearch } from "../../../actions";
import { searchLocations } from "../../../actions/locationAction";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Sticky from "react-sticky-el";

class SearchField extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   this.props.getResources(this.props.search.value, "");
  // }

  updateInputValue = (evt) => {
    this.debouncedSearch(evt.target.value);
    const search = {
      value: evt.target.value,
    };
    this.props.validateSearch(search);
  };

  triggerSearch = () => {
    this.props.searchLocations(this.props.search.value);
  };

  //-- depricated
  goToSearchResult() {
    this.props.history.push("/searchresult");
    const search = {
      value: this.props.search.value,
      entered: this.props.search.value,
    };
    this.props.validateSearch(search);
    this.props.searchLocations(this.props.search.value);
  }

  debouncedSearch = _.debounce(function (query) {
    const search = {
      entered: this.props.search.value,
    };
    this.props.validateSearch(search);
    this.props.searchLocations(query);
    // this.props.history.push("/searchresult");
  }, 1000);

  renderSearchButton() {
    // disabled
    if (1 !== 1) {
      return (
        <MDBBtn
          color="yellow"
          className="m-0 px-3 py-2 z-depth-0"
          onClick={this.goToSearchResult.bind(this)}
        >
          Search
        </MDBBtn>
      );
    }
  }

  render() {
    return (
      <div className="mt-5" style={{ width: "100%" }}>
        <Sticky
          stickyStyle={{
            left: "0px",
            right: "0px",
            top: "0px",
            width: "100%",
            zIndex: "99",
          }}
        >
          <MDBContainer>
            <div className="search-field-container">
              <MDBInputGroup
                hint="Enter venue name, suburb or postcode"
                onChange={this.updateInputValue}
                value={this.props.search.value}
                append={this.renderSearchButton()}
              />
            </div>
          </MDBContainer>
        </Sticky>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    locations: state.locations,
    search: state.search,
  };
};

export default withRouter(
  connect(mapStateToProps, { searchLocations, validateSearch })(SearchField)
);
