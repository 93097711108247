import React from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Home from "./Home";
import SearchResult from "./SearchResult";
import Checkout from "./Checkout";
import Booking from "./Booking";
import Payment from "./Payment";
import Login from "./Login";
import Contact from "./Contact";
import ResetPassword from "./ResetPassword";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { validateLayout } from "../../../actions";
import FourZeroFour from "../shared/FourZeroFour";
import BookingSuccess from "./BookingSuccess";
import BookingCancel from "./BookingCancel";
import Fingerprint2 from "fingerprintjs2";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { hotjar } from "react-hotjar";
import config from "../../../config";
import Register from "./Register";
import RegisterSuccess from "./RegisterSuccess";
import ForgotPass from "./ForgotPass";
import ForgotPassSuccess from "./ForgotPassSuccess";
import CheckoutPage from "../admin/CheckoutPage";
import MaintenancePage from "../MaintenancePage";

class PublicLayout extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount() {
    const { cookies } = this.props;
    let hasFingerprint = cookies.get("fingerprint") != "";
    if (hasFingerprint) {
      if (window.requestIdleCallback) {
        requestIdleCallback(function () {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var fingerHashed = Fingerprint2.x64hash128(values.join(""), 31);
            cookies.set("fingerprint", fingerHashed);
          });
        });
      } else {
        setTimeout(function () {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var fingerHashed = Fingerprint2.x64hash128(values.join(""), 31);
            cookies.set("fingerprint", fingerHashed);
          });
        }, 500);
      }
    }
    hotjar.initialize(config.hotjar.hjid, config.hotjar.hjsv);
  }
  validateHeader() {
    if (this.props.layout.showHeader) {
      return <Header />;
    }
  }

  validateFooter() {
    if (this.props.layout.showFooter) {
      return <Footer />;
    }
  }

  renderContent() {
    let arrayToRedirect = [
      "/booking",
      "/checkout",
      "/payment",
      "/searchresult",
      "/checkout/redirect",
      "/users/ResetUser",
      "/booking/success",
      "/booking/payment",
      "/booking/cancel",
      "/register",
      "/registersuccess",
      "/forgotpass",
      "/checkoutpage",
      "/forgotpasssuccess",
      "/contact",
    ];
    // if (arrayToRedirect.includes(window.location.pathname)) {
    //   setTimeout(() => {
    //     window.location.href = "/maintenance";
    //   }, 500); // 1-second delay before redirect
    // }

    return (
      <div>
        {this.validateHeader()}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/searchresult" exact component={SearchResult} />
          <Route path="/booking" exact component={Booking} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/payment" exact component={Payment} />
          <Route path="/checkout/redirect" component={Payment} />
          <Route path="/login" exact component={Login} />
          <Route path="/maintenance" exact component={MaintenancePage} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/users/ResetUser" component={ResetPassword} />
          <Route path="/booking/success" component={BookingSuccess} />
          <Route path="/booking/payment/" component={BookingSuccess} />
          <Route path="/booking/cancel" component={BookingCancel} />
          <Route path="/register" exact component={Register} />
          <Route path="/registersuccess" exact component={RegisterSuccess} />
          <Route path="/forgotpass" exact component={ForgotPass} />

          <Route path="/checkoutpage" exact component={CheckoutPage} />
          <Route
            path="/forgotpasssuccess"
            exact
            component={ForgotPassSuccess}
          />
          <Route component={FourZeroFour} />
        </Switch>
        {this.validateFooter()}
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout };
};

export default withCookies(
  connect(mapStateToProps, { validateLayout })(PublicLayout)
);
