import React from "react";
import { Link } from "react-router-dom";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
} from "mdbreact";
class VenueTile extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isOpen: false,
  };

  componentDidMount() {}

  validateAbout() {
    let about =
      this.props.about !== undefined
        ? this.props.about
        : "No description available";

    let aboutIntro = about;
    if (aboutIntro.length > 100) {
      aboutIntro = aboutIntro.substring(0, 100) + "...";
    }
    if (this.state.isOpen === false) {
      return (
        <span>
          {aboutIntro}
          <Link to="#" onClick={this.toggleCollapse(true)}>
            Read more
          </Link>
        </span>
      );
    } else {
      return (
        <span>
          {about}
          <br />
          <br />
          <strong>Contact #:</strong> {this.props.contactNumber}
          <br />
          <strong>Email:</strong> {this.props.email}
          <br />
          <strong>Website:</strong>{" "}
          <a href={this.props.website} target="_blank">
            {this.props.website}
          </a>
          <br />
          <br />
          <Link to="#" onClick={this.toggleCollapse(false)}>
            Read less
          </Link>
        </span>
      );
    }
  }

  toggleCollapse = (isOpen) => () => {
    this.setState((prevState) => ({
      isOpen: prevState.isOpen !== isOpen ? isOpen : "",
    }));
  };

  renderContent() {
    return (
      <MDBCol>
        <MDBCard>
          <Link to={`/booking?uuid=${this.props.uuid}`}>
            <MDBCardImage
              className="img-fluid tile-image-size"
              src={this.props.imageUrl}
              waves
            />
          </Link>
          <MDBCardBody>
            <MDBCardTitle style={{ color: "#212529" }}>
              {this.props.title}
            </MDBCardTitle>
            <strong style={{ color: "#212529" }}>{this.props.location}</strong>
            <MDBCardText>{this.validateAbout()}</MDBCardText>

            <MDBRow>
              <MDBCol md="6">
                <Link to={`/register?uuid=${this.props.uuid}`}>
                  <MDBBtn className="float-right1" color="success" size="sm">
                    Subscribe
                  </MDBBtn>
                </Link>
              </MDBCol>
              <MDBCol md="6">
                <Link to={`/booking?uuid=${this.props.uuid}`}>
                  <MDBBtn className="float-right" size="sm">
                    Book Now
                  </MDBBtn>
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default VenueTile;
