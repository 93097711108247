import React from "react";
import { connect } from "react-redux";
import { toggleModal } from "../../../actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBAlert,
} from "mdbreact";

class Modal extends React.Component {
  validateCancelButtonView() {
    if (this.props.modal.negativeButtonText !== "") {
      return (
        <MDBBtn
          color="blue-grey"
          onClick={this.props.modal.negativeButtonAction}
        >
          {this.props.modal.negativeButtonText}
        </MDBBtn>
      );
    }
  }

  showAlert() {
    if (this.props.modalAttr.showAlert) {
      return (
        <MDBAlert
          color={this.props.modalAttr.alertType}
          className="text-center"
        >
          {this.props.modalAttr.alertMsg}
        </MDBAlert>
      );
    } else {
      return <span></span>;
    }
  }

  toggle() {
    if (
      this.props.modal.allowToggle ||
      this.props.modal.allowToggle === undefined
    ) {
      this.props.modal.isOpen = !this.props.modal.isOpen;
    }
    this.setState({
      update: true,
    });
  }

  renderContent() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.modal.isOpen}
          style={{ display: "block", zIndex: 10000 }}
          toggle={() => {
            this.toggle();
          }}
          size={
            this.props.modal.modalSize != undefined &&
            this.props.modal.modalSize
          }
        >
          <MDBModalHeader>{this.props.modal.title}</MDBModalHeader>
          <MDBModalBody>
            {this.showAlert()}
            {this.props.modal.content}
          </MDBModalBody>
          <MDBModalFooter>
            {this.validateCancelButtonView()}
            {this.props.modal.hasPositiveButtonAction ||
            typeof this.props.modal.hasPositiveButtonAction === "undefined" ? (
              <MDBBtn
                color={
                  this.props.modal.positiveButtonColor !== undefined
                    ? this.props.modal.positiveButtonColor
                    : `secondary`
                }
                onClick={this.props.modal.positiveButtonAction}
              >
                {this.props.modal.positiveButtonText}
              </MDBBtn>
            ) : (
              ""
            )}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { modal: state.modal, modalAttr: state.modalAttr };
};

export default connect(mapStateToProps, { toggleModal })(Modal);
