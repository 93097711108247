export default (
  search = {
    value: "",
    entered: "",
  },
  action
) => {
  if (action.type === "VALIDATE_SEARCH") {
    return action.payload;
  }
  return search;
};
