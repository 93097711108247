import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBBtn } from "mdbreact";

class Footer extends React.Component {
  renderContent() {
    return (
      <MDBFooter color="stylish-color" className="font-small pt-4 mt-4">
        <MDBContainer className="text-center text-md-left">
          <MDBRow>
            <MDBCol md="6">
              <h5 className="title">About Us</h5>
              <p className="text-justify">
                Unified Bookings is a multi-purpose, multi-platform booking
                system that takes the stress out of managing bookings for your
                service. We can create a custom booking platform specific for
                your business, or you can join one of the existing booking
                portfolios to give you the best chance of organic leads. With a
                large range of options, from presentation, to locations, to
                service types, Unified Bookings is highly configurable and is
                perfect for all sorts of bookings.
              </p>
            </MDBCol>
            <MDBCol md="6">
              <h5 className="title">Contact</h5>
              <ul style={{ paddingLeft: "0" }}>
                <li className="list-unstyled">
                  <a href="/contact">
                    <MDBBtn
                      color="default"
                      className="p-3"
                      style={{ marginLeft: "0px" }}
                      // onClick={this.validateLogin.bind(this)}
                    >
                      Send us Message
                    </MDBBtn>
                  </a>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            &copy; {2022} Copyright:
            <a href="https://unifiedbookings.com.au">
              {" "}
              unifiedbookings.com.au{" "}
            </a>
            <br />
            Powered By: <a href="#"> TINA TEC PTY LTD </a>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default Footer;
