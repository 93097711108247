import React from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { validateAccess } from "../../../helper/utils";
import { getUserSubscriptions } from "../../../actions/userAction";
import VenueSubscriptionTile from "../shared/VenueSubscriptionTile";
import moment, { utc } from "moment";
import _ from "lodash";
import { showNotification } from "../../../actions";

class VenueSubscriptions extends React.Component {
  state = {
    subscriptionContainer: [],
  };

  componentDidMount() {
    if (validateAccess(this.props.storeUserAccess, "DefaultUserMenu", "")) {
      this.props.getUserSubscriptions(this.props.authUser.sessionToken);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userResponse !== this.props.userResponse) {
      let response = this.props.userResponse;
      if (
        response.action == "ACTION" &&
        response.type === "SUBSCRIBE_RESPONSE_HANDLER"
      ) {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        this.props.getUserSubscriptions(this.props.authUser.sessionToken);
      } else if (response.type == "GET_SUBSCRIPTION_RESPONSE_HANDLER") {
        console.log("check response");
        console.log(response);
        this.setState({ subscriptionContainer: response.data });
        // console.log("check this user details");
        // // this.setState({
        // //   ...this.state,
        // //   resultContainer: response.data,
        // //   page: response.data.page,
        // //   name: response.data.results[0].name,
        // //   email: response.data.results[0].email,
        // // });
      }
    }

    console.log("locations>>>>>>>>");
    console.log(this.props.locations);
  }

  buildTiles() {
    const { subscriptionContainer } = this.state;
    console.log("subscriptionContainer>>>>>>>>>>>>");
    console.log(subscriptionContainer);
    let elem = [];
    // elem.push(
    //   <VenueSubscriptionTile
    //     key={"t-all"}
    //     subscription={{
    //       name: "List of all upcoming bookings",
    //       locationid: null,
    //     }}
    //   />
    // );
    if (this.props.locations.results !== undefined) {
      _.map(this.props.locations.results, (loc, index) => {
        let obj = {
          locationid: loc.id,
          name: loc.name,
          uuid: loc.uuid,
        };
        elem.push(
          <VenueSubscriptionTile key={"t-" + index} subscription={obj} />
        );
      });
    }

    return elem;
  }

  renderContent() {
    return <MDBRow>{this.buildTiles()}</MDBRow>;
  }

  render() {
    if (validateAccess(this.props.storeUserAccess, "DefaultUserMenu", "")) {
      return this.renderContent();
    }
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    storeUserAccess: state.storeUserAccess,
    userResponse: state.userResponse,
    authUser: state.authUser,
    locations: state.locations,
  };
};

export default connect(mapStateToProps, {
  getUserSubscriptions,
  showNotification,
})(VenueSubscriptions);
