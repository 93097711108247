import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_BUSINESS_RULES,
  GET_BUSINESS_RULE_RESPONSE_HANDLER,
  DELETE_BUSINESS_RULE_RESPONSE_HANDLER,
  GET_BUSINESS_RULE_EVALUATORS,
  GET_BUSINESS_RULE_TRIGGER_TYPES,
  GET_BUSINESS_RULE_EVALUATORS_RESPONSE_HANDLER,
  GET_BUSINESS_RULE_TRIGGER_TYPES_RESPONSE_HANDLER,
} from "./types";

export const getOrgBusinessRules =
  (params, sessionToken) => async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate
      .get(`/rules/businessrules`, {
        params: params,
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_RULES,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const getBusinessRuleEvaluatorsList =
  (params, sessionToken) => async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate
      .get(`/rules/businessrules/rule_evaluator_list`, {
        params: params,
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_RULE_EVALUATORS,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: GET_BUSINESS_RULE_EVALUATORS_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const getBusinessruleTriggerTypeList =
  (params, sessionToken) => async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate
      .get(`/rules/businessrules/rule_triggers_list`, {
        params: params,
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_RULE_TRIGGER_TYPES,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: GET_BUSINESS_RULE_TRIGGER_TYPES_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const insertBusinessRule = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/rules/businessrule`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const updateBusinessRule =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/rules/businessrule/${uuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const insertBusinessRuleEvaluator =
  (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/rules/businessrule/evaluator`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const updateBusinessRuleEvaluator =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/rules/businessrule/evaluator/${uuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const updateBusinessRuleTrigger =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/rules/businessrule/trigger/${uuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const deleteBusinessRuleTrigger =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .delete(`/rules/businessrule/trigger/${uuid}`, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const deleteBusinessRuleEvaluator =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .delete(`/rules/businessrule/evaluator/${uuid}`, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const insertBusinessRuleTrigger =
  (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/rules/businessrule/trigger/`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const updateBusinessRuleMessages =
  (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/rules/businessrule/messages`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const deleteBusinessRule =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .delete(`/rules/businessrule/${uuid}`, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: DELETE_BUSINESS_RULE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: DELETE_BUSINESS_RULE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };
