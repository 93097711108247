import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { showHideLoader, toggleModal } from "../../../actions";
import {
  insertBusinessRuleEvaluator,
  updateBusinessRuleEvaluator,
  getBusinessRuleEvaluatorsList,
} from "../../../actions/ruleEngineAction";
import _ from "lodash";

import { GEN_MODAL_RULE_CONDITION_EVALUATOR } from "../../../actions/types";
class RuleEvaluatorModal extends React.Component {
  state = {
    collapseID: "collapse1",
    operatorList: [
      {
        text: "--Select Operator--",
        value: "",
      },
      {
        text: "Is equal to",
        value: "is_equals",
      },
      {
        text: "Not equal to",
        value: "not_equal",
      },
      {
        text: "Less than or equal",
        value: "lte",
      },

      {
        text: "Greater than or equal",
        value: "gte",
      },
      {
        text: "Maximum",
        value: "maximum",
      },
      {
        text: "In Between",
        value: "in_between",
      },
    ],
    b_rule_id: 0,
    uuid: "",
    evaluator_selected_id: "",
    evaluator_operator: "",
    evaluator_value: "",
    evaluator_value_from: "",
    evaluator_value_to: "",
    evaluator_description: "",
    evaluator_value_type: "",
    evaluator_input_type: "",
    evaluator_option_list: [],

    allowedOperators: ["is_equals", "not_equal", "lte", "gte", "maximum"], // Default allowed operators
    ruleConditionEvaluators: [],
  };

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  setFormValues(evaluator, isEdit) {
    let formData = {
      uuid: "",
      b_rule_id: evaluator["business_rule_id"],
      evaluator_selected_id: "",
      evaluator_operator: "",
      evaluator_value: "",
      evaluator_value_from: "",
      evaluator_value_to: "",
      evaluator_description: "",
      evaluator_value_type: "",
      evaluator_input_type: "",
    };

    if (isEdit) {
      formData["uuid"] = evaluator["uuid"];
      formData["evaluator_selected_id"] = evaluator["evaluator_id"];
      formData["evaluator_operator"] = evaluator["operator"];
      formData["evaluator_description"] = evaluator["description"];
      formData["evaluator_value_type"] = evaluator["value_type"];
      formData["evaluator_value"] = evaluator["value"]["value"];

      if (evaluator["operator"] == "in_between") {
        let evalValue = evaluator["value"]["value"];
        formData["evaluator_value_from"] = evalValue["from"];
        formData["evaluator_value_to"] = evalValue["to"];
      } else {
        formData["evaluator_value"] = evaluator["value"]["value"];
      }

      if (evaluator && evaluator["evaluator_details"]) {
        formData["evaluator_input_type"] =
          evaluator["evaluator_details"]["input_field_type"];

        this.setState({
          evaluator_option_list: evaluator["evaluator_details"]["optionList"],
        });
      }
    }

    return formData;
  }

  componentDidMount() {
    this.props.getBusinessRuleEvaluatorsList(
      {},
      this.props.authUser.sessionToken
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_RULE_CONDITION_EVALUATOR) {
        let evaluator = response.data.payload;
        let isEdit = false;
        if (response.data.show && response.data.action === "edit") {
          isEdit = true;
        }

        let formData = this.setFormValues(evaluator, isEdit);
        this.setState({
          isEdit: isEdit,
          showModal: true,
          ...formData,
          wasValidated: "",
        });
      }
    }

    if (
      prevProps.ruleEngineEvaluatorsResponse !==
      this.props.ruleEngineEvaluatorsResponse
    ) {
      let response = this.props.ruleEngineEvaluatorsResponse;

      this.props.showHideLoader(false);
      if (
        this.props.ruleEngineEvaluatorsResponse.action === "LIST" &&
        this.props.ruleEngineEvaluatorsResponse.status === 200
      ) {
        this.setState({
          ruleConditionEvaluators: response.data,
        });
      }
    }
    if (prevState.evaluator_selected_id !== this.state.evaluator_selected_id) {
      const matchedEvaluator = this.state.ruleConditionEvaluators.find(
        (element) => element["id"] == this.state.evaluator_selected_id
      );

      if (typeof matchedEvaluator != "undefined") {
        this.setState({
          evaluator_description: matchedEvaluator["description"],
          allowedOperators: matchedEvaluator["allowedOperators"],
          evaluator_value_type: matchedEvaluator["value_type"],
        });
      }
    }
  }

  showModalFunc = (showModal, source) => {
    let isEdit = true;
    if (source !== {}) {
      isEdit = false;
    }

    let formData = this.setFormValues(source, isEdit);
    this.setState({
      isEdit: isEdit,
      showModal: showModal,
      ...formData,
    });
  };

  ruleConditionBuilder = () => {
    const {
      evaluator_value,
      evaluator_option_list,
      evaluator_input_type,
      evaluator_value_type,
      evaluator_operator,
      evaluator_value_from,
      evaluator_value_to,
    } = this.state;
    let inputForm = "";

    if (evaluator_operator === "in_between") {
      if (evaluator_input_type == "input") {
        inputForm = (
          <MDBRow>
            <MDBCol md="6">
              <label htmlFor="evaluator_value_from" className="grey-text">
                <span className="text-danger">*</span> From:
              </label>

              <input
                id="evaluator_value_from"
                name="evaluator_value_from"
                value={evaluator_value_from}
                className="form-control"
                onChange={this.formDataHandler}
                required
                pattern="^([01]\d|2[0-3]):?([0-5]\d)$"
                type={
                  evaluator_value_type === "integer" ||
                  evaluator_value_type === "decimal"
                    ? "number"
                    : "text"
                }
              />
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="evaluator_value_to" className="grey-text">
                <span className="text-danger">*</span> To:
              </label>
              <input
                id="evaluator_value_to"
                name="evaluator_value_to"
                value={evaluator_value_to}
                className="form-control"
                onChange={this.formDataHandler}
                pattern="^([01]\d|2[0-3]):?([0-5]\d)$"
                required
                type={
                  evaluator_value_type === "integer" ||
                  evaluator_value_type === "decimal"
                    ? "number"
                    : "text"
                }
              />
            </MDBCol>
          </MDBRow>
        );
      }
    } else {
      if (evaluator_input_type == "input") {
        inputForm = (
          <input
            id="evaluator_value"
            name="evaluator_value"
            value={evaluator_value}
            className="form-control"
            onChange={this.formDataHandler}
            required
            type={
              evaluator_value_type === "integer" ||
              evaluator_value_type === "decimal"
                ? "number"
                : "text"
            }
          />
        );
      } else if (evaluator_input_type == "dropdown") {
        inputForm = (
          <select
            id="evaluator_value"
            name="evaluator_value"
            className="form-control"
            defaultValue={evaluator_value}
            onChange={this.formDataHandler}
            required
          >
            <option value="">--Please Select--</option>
            {evaluator_option_list.map((evalOption) => (
              <option value={evalOption["value"]}>{evalOption["text"]}</option>
            ))}
          </select>
        );
      }
    }

    return inputForm;
  };

  submitHandler = (event) => {
    const {
      uuid,
      b_rule_id,
      evaluator_selected_id,
      evaluator_operator,
      evaluator_value,
      isEdit,
      evaluator_value_type,
      evaluator_value_from,
      evaluator_value_to,
    } = this.state;

    let evaluatorValue = { type: evaluator_value_type, value: evaluator_value };

    if (evaluator_operator == "in_between") {
      evaluatorValue["value"] = {
        from: evaluator_value_from,
        to: evaluator_value_to,
      };
    } else {
      evaluatorValue["value"] = evaluator_value;
    }

    let body = {
      evaluator_id: evaluator_selected_id,
      business_rule_id: b_rule_id,
      operator: evaluator_operator,
      value: evaluatorValue,
    };

    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateBusinessRuleEvaluator(
          body,
          uuid,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertBusinessRuleEvaluator(
          body,
          this.props.authUser.sessionToken
        );
      }
    }
  };

  render() {
    const {
      evaluator_selected_id,
      evaluator_operator,
      showModal,
      isEdit,
      operatorList,
      allowedOperators,
      evaluator_description,
    } = this.state;
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={showModal}
          size="md"
          toggle={() => this.showModalFunc(false, {})}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>{isEdit ? "Edit" : "Add"} Evaluator</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="shortname" className="grey-text">
                    <span className="text-danger">*</span> Evaluator{" "}
                  </label>

                  <select
                    id="evaluator_selected_id"
                    name="evaluator_selected_id"
                    className="form-control"
                    defaultValue={evaluator_selected_id}
                    disabled={isEdit ? true : false}
                    onChange={(event) => {
                      let evaluatorselectedId = event.target.value;

                      const selectedEvaluator =
                        this.state.ruleConditionEvaluators.find(
                          (element) => element["id"] == evaluatorselectedId
                        );

                      this.setState({
                        evaluator_selected_id: evaluatorselectedId,
                        evaluator_value_type: selectedEvaluator["value_type"],
                        evaluator_input_type:
                          selectedEvaluator["input_field_type"],
                        evaluator_option_list: selectedEvaluator["optionList"],
                      });
                    }}
                    required
                  >
                    <option value="">--Select Evaluator--</option>
                    {this.state.ruleConditionEvaluators.map((evaluator) => (
                      <option value={evaluator["id"]}>
                        {evaluator["display_name"]}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Please select evaluator
                  </div>
                </MDBCol>
              </MDBRow>
              {evaluator_description && (
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <span
                      className="font-italic"
                      dangerouslySetInnerHTML={{
                        __html: `(${evaluator_description})`,
                      }}
                    ></span>
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="operator" className="grey-text">
                    <span className="text-danger">*</span> Operator
                  </label>

                  <select
                    id="evaluator_operator"
                    name="evaluator_operator"
                    className="form-control"
                    value={evaluator_operator}
                    onChange={this.formDataHandler}
                    required
                  >
                    <option value="">--Select Operator--</option>
                    {operatorList.map((operator) => {
                      if (allowedOperators.includes(operator["value"])) {
                        return (
                          <option
                            value={operator["value"]}
                            disabled={operator["value"] == ""}
                          >
                            {operator["text"]}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <div className="invalid-feedback">Please select operator</div>
                </MDBCol>
              </MDBRow>

              {evaluator_operator != "" &&
                typeof evaluator_operator != "undefined" && (
                  <MDBRow>
                    <MDBCol md="12" className="mb-3">
                      <label className="grey-text">
                        <span className="text-danger">*</span> Value
                      </label>
                      {this.ruleConditionBuilder()}
                      <div className="invalid-feedback">
                        Please provide valid value
                      </div>
                    </MDBCol>
                  </MDBRow>
                )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModalFunc(false, {})}
              >
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {isEdit ? "Save" : "Add"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
    locations: state.locations,
    ruleEngineEvaluatorsResponse: state.ruleEngineEvaluatorsResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    toggleModal,
    insertBusinessRuleEvaluator,
    updateBusinessRuleEvaluator,
    getBusinessRuleEvaluatorsList,
  })(RuleEvaluatorModal)
);
