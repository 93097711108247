import React from "react";
import { validateLayout } from "../../../actions";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import SectionContainer from "../shared/sectionContainer";
import { Link } from "react-router-dom";

import _ from "lodash";

import { cancelPublicBooking } from "../../../actions/bookingAction";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { USER_COOKIES_KEY, USER_TMP } from "../../../helper";

import { showHideLoader } from "../../../actions";

class BookingCancel extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  state = {
    showGoToDashboard: false,
  };
  getUserCookies() {
    const { cookies } = this.props;
    let userCookies = cookies.get(USER_COOKIES_KEY);
    if (userCookies !== undefined) {
      this.setState({ showGoToDashboard: true });
    }
  }

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: false,
      showSearch: false,
      showHeader: false,
      showLogin: false,
    };
    this.props.validateLayout(layout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingResponse !== this.props.bookingResponse) {
      this.props.showHideLoader(false);
    }
  }

  componentDidMount() {
    this.setLayout();
    let uuid = _.split(this.props.location.pathname, "/")[3];
    this.props.showHideLoader(true);
    this.props.cancelPublicBooking(uuid);
    this.getUserCookies();
  }

  renderContent() {
    const { showGoToDashboard } = this.state;
    return (
      <div>
        <MDBContainer>
          <h1 className="text-center mt-5">Cancelled Booking</h1>
          <h6 className="text-center">
            Your booking was cancelled. To book please click the below link. You
            will be redirected to home page to select venue you wish to book.
            Thank you!
          </h6>
          <div className="text-center">
            {!showGoToDashboard && <Link to="/">Go back to Home page</Link>}
            {showGoToDashboard && (
              <Link to="/admin/dashboard">Go back to Dashboard</Link>
            )}
          </div>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout, bookingResponse: state.bookingResponse };
};

export default withCookies(
  connect(mapStateToProps, {
    validateLayout,
    cancelPublicBooking,
    showHideLoader,
  })(BookingCancel)
);
