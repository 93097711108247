import React, { Component } from "react";
import { connect } from "react-redux";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBadge,
} from "mdbreact";
import _ from "lodash";
import moment, { utc } from "moment";
import {
  roundNumber,
  extractTimezone,
  getBookingColors,
  getCss,
  validateAccess,
} from "../../../helper/utils";
import { disableParentCalendarTimer } from "../../../actions";

import {
  getResourceBookings,
  getResourceBookingsPublic,
} from "../../../actions/bookingAction";

import BookingRequestModal from "../shared/BookingRequestModal";
import BookingRequestCountDownTimerComponent from "../shared/BookingRequestCountDownTimerComponent";

class CalendarBookingComponent extends React.Component {
  // handleSwitchChange = () => {
  //   this.props.setSwitch(!this.props.setSwitchValResponse);
  //   this.props.onSwitch(!this.props.setSwitchValResponse);
  // };

  state = {
    tableElem: [],
    refreshInterval: 5000,
    resourceObj: {},
    isShowBookingRequestModal: false,
    bookingRequestData: {},
  };

  componentDidMount() {
    this.resourceObj = {};
    this.setResourceObj();
    this.startCalendarRefresh();
    this.refreshCalendar();
    if (this.props.isChangeSchedule) {
      this.props.disableParentCalendarTimer(true);
    } else {
      this.props.disableParentCalendarTimer(false);
    }
  }

  setResourceObj() {
    let resourceObj = this.props.resourceObj;
    let paramData = {
      rows: resourceObj.generatedTimeArr.length + 1,
      court: resourceObj.court,
      generatedTimeArr: resourceObj.generatedTimeArr,
      maxBookingParticipants: resourceObj.maxBookingParticipants,
      defaultStartTime: resourceObj.defaultStartTime,
      defaultEndTime: resourceObj.defaultEndTime,
      resourceUuid: resourceObj.resourceUuid,
      resourceId: resourceObj.resourceId,

      durationPerChunk: resourceObj.durationPerChunk,
      maxDuration: resourceObj.maxDuration,
      minDuration: resourceObj.minDuration,
      allowBookingSameTime: resourceObj.allowBookingSameTime,
      allowStartTime: resourceObj.allowStartTime,
      allowEndTime: resourceObj.allowEndTime,
    };
    this.resourceObj = paramData;
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.props.disableParentCalendarTimer(false);
  }

  startCalendarRefresh = () => {
    this.intervalId = setInterval(
      this.refreshCalendar.bind(this),
      this.state.refreshInterval
    );
  };

  refreshCalendar() {
    let disableParent = false;

    if (
      this.props.disableParentCalendarTimerResponse &&
      this.props.isChangeSchedule === undefined
    ) {
      disableParent = true;
    }

    if (
      !this.props.pauseBackgroundRequestsResponse &&
      !disableParent &&
      this.props.selectedDate != "" //Check first if the date is valid or not empty before doing an API call
    ) {
      if (this.props.isPrivate) {
        this.props.getResourceBookings(
          {
            locationId: this.props.resourceObj.location.id,
            locationUuid: this.props.resourceObj.location.uuid,
            date: moment(this.props.selectedDate).format("YYYY-MM-DD"),
            resourceUuid: this.props.resourceObj.resourceUuid,
            resourceId: this.props.resourceObj.resourceId,
          },
          this.props.authUser.sessionToken
        );
      } else {
        this.props.getResourceBookingsPublic({
          locationId: this.props.resourceObj.location.id,
          locationUuid: this.props.resourceObj.location.uuid,
          date: moment(this.props.selectedDate).format("YYYY-MM-DD"),
          resourceUuid: this.props.resourceObj.resourceUuid,
          resourceId: this.props.resourceObj.resourceId,
        });
      }
    }
  }

  buildTable(param) {
    let resourceObj = this.resourceObj;
    let elem = [];
    let obj = {};
    _.map(resourceObj.generatedTimeArr, (val, index) => {
      obj = {
        key: index,
        bookings: param.bookings,
        locked: param.locked,
        recurrings: param.recurrings,
        booking_requests: param.booking_requests,
        pending_recurring_schedules: param.pending_recurring_schedules,
        time: val,
        rows: resourceObj.rows,
        court: resourceObj.court,
        maxBookingParticipants: resourceObj.maxBookingParticipants,
        defaultStartTime: resourceObj.defaultStartTime,
        defaultEndTime: resourceObj.defaultEndTime,
        resourceUuid: resourceObj.resourceUuid,
        resourceId: resourceObj.resourceId,
        durationPerChunk: resourceObj.durationPerChunk,
        maxDuration: resourceObj.maxDuration,
        minDuration: resourceObj.minDuration,
        allowBookingSameTime: resourceObj.allowBookingSameTime,
        allowStartTime: resourceObj.allowStartTime,
        allowEndTime: resourceObj.allowEndTime,
      };
      elem.push(this.buildRow(obj));
    });

    // for (let i = 1; i < this.resourceObj.rows; i++) {}
    let tableElem = [];
    tableElem.push(
      <MDBTable
        className="table-no-side-border m-0"
        key={"tbl-" + Math.floor(100)}
      >
        <MDBTableHead></MDBTableHead>
        <MDBTableBody>{elem}</MDBTableBody>
      </MDBTable>
    );
    this.setState({ tableElem: tableElem });
  }

  getTzDetails(dt = new Date()) {
    return extractTimezone(this.props.locationTimezone, dt);
  }

  validateRenderingOfTD(paramData) {
    const { isPrivate } = this.props;
    let genStartTime = _.split(paramData.time, "-")[0];
    let genEndTime = _.split(paramData.time, "-")[1];

    if (
      moment(genStartTime, "HH:mm").isSameOrAfter(
        moment(paramData.defaultStartTime, "HH:mm")
      ) &&
      moment(genEndTime, "HH:mm").isSameOrBefore(
        moment(paramData.defaultEndTime, "HH:mm")
      )
    ) {
      let booked = _.chain(paramData.bookings)
        .map((book) => {
          // Convert date time to browser date
          var localStartTime = moment(book.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
          var localEndTime = moment(book.end_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
          // if (localStartTime === _.split(genStartTime, "-")[0]) {
          //   return book;
          // }

          if (
            moment(genStartTime, "HH:mm").isSameOrAfter(
              moment(localStartTime, "HH:mm")
            ) &&
            moment(genEndTime, "HH:mm").isSameOrBefore(
              moment(localEndTime, "HH:mm")
            )
          ) {
            return book;
          }
          return [];
        })
        .filter(_.size)
        .value();

      if (booked.length > 0) {
        var bookObj = booked[0];
        if (
          (!bookObj.is_available && !bookObj.is_booked) ||
          bookObj.recurring_schedule?.status.includes("updating", "cancelling")
        ) {
          return this.renderTD("", paramData.key);
        } else if (
          !bookObj.is_available &&
          (bookObj.is_booked || bookObj.is_booking_request)
        ) {
          return this.renderBookedTD(paramData, booked);
        }
      }

      let sDate = moment(this.props.selectedDate).format("YYYY-MM-DD");
      let currDate = moment(new Date()).format("YYYY-MM-DD");
      if (
        !isPrivate &&
        moment(sDate, "YYYY-MM-DD").isBefore(moment(currDate, "YYYY-MM-DD"))
      ) {
        return this.renderTD("", paramData.key);
      } else {
        return this.validateAvailableTD(paramData);
      }
    } else {
      return this.renderTD("", paramData.key);
    }
  }

  renderBookedTD(paramData, booked) {
    const { storeUserAccess, bookingColors, isPrivate, isDefaultUser } =
      this.props;
    const isAdmin = !isDefaultUser && isPrivate;

    let isStartTime = false;
    let rowSpan = 1;
    let bookObj = booked[0];
    let genStartTime = _.split(paramData.time, "-")[0];

    let localStartTime = moment(bookObj.start_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");

    let t1 = moment(bookObj.start_time);
    let t2 = moment(bookObj.end_time);

    let duration = t2.diff(t1, "minutes");
    rowSpan = duration / 30;

    if (genStartTime === localStartTime) {
      isStartTime = true;
    }

    // This should be improved
    let tdInlineStyle = { cursor: "pointer", width: "1px", maxWidth: "1px" };

    if (!isPrivate) {
      if (isStartTime) {
        return (
          <td
            className={getCss(bookingColors.bg_public_booked)}
            rowSpan={rowSpan}
            key={paramData.key}
            style={tdInlineStyle}
          >
            <strong className={getCss(bookingColors.tc_public_booked)}>
              Booked
            </strong>
          </td>
        );
      }
      return <></>;
    } else {
      // -- TODO Temporary validation
      let noCustomerDetails = false;
      if (bookObj.customer === undefined) {
        noCustomerDetails = true;
      }

      let isRecurring =
        bookObj.parent_booking !== undefined || bookObj.recurring !== undefined;

      let tdColor = "",
        tdTextColor = "",
        tdColorClickable = "",
        addToWaitList = "";

      if (!noCustomerDetails) {
        tdColor = isRecurring
          ? getCss(bookingColors.bg_recurring)
          : getCss(bookingColors.bg_oneOff);
        tdTextColor = isRecurring
          ? getCss(bookingColors.tc_recurring)
          : getCss(bookingColors.tc_oneOff);
        tdColorClickable = isRecurring
          ? getCss(bookingColors.bg_recurring)
          : getCss(bookingColors.bg_oneOff);
      } else {
        tdColor = getCss(bookingColors.bg_notAvailable);
        tdTextColor = getCss(bookingColors.tc_notAvailable);
        tdColorClickable = getCss(bookingColors.bg_notAvailable);
        addToWaitList = getCss(bookingColors.bg_addToWaitList);
      }

      // Change the color of the timeslot booking
      if (
        bookObj.booking_color != "" &&
        bookObj.booking_color != undefined &&
        isAdmin
      ) {
        tdInlineStyle = {
          cursor: "pointer",
          width: "1px",
          maxWidth: "1px",
          backgroundColor: bookObj.booking_color,
          ":hover": bookObj.booking_color,
        };

        tdColorClickable = "";
        tdColor = "";
      }

      if (this.props.isChangeSchedule && this.props.showCalendarSelect) {
        if (isStartTime) {
          if (noCustomerDetails) {
            return (
              <td
                rowSpan={rowSpan}
                className={tdColorClickable}
                key={paramData.key}
              >
                <strong className={tdTextColor}>Booked</strong>
              </td>
            );
          }

          return (
            <td
              rowSpan={rowSpan}
              className={`${tdColor} td-popup`}
              key={paramData.key}
              style={tdInlineStyle}
            >
              {this.displayIcon(bookObj)}
              <strong className={tdTextColor}>
                {this.validateDisplayName(
                  bookObj.customer.first_name + " " + bookObj.customer.last_name
                )}
                <br />
                {
                  // If admin show the number of checked in participants
                  !this.props.isDefaultUser &&
                    this.showCheckedInParticipants(bookObj.participants)
                }
              </strong>
              {!this.props.isDefaultUser && (
                // If admin show the pop up status
                <span className="td-popup-container">
                  {this.renderPopupContent(bookObj)}
                </span>
              )}
            </td>
          );
        }
        return <></>;
      }
      if (isStartTime) {
        if (noCustomerDetails) {
          return (
            <td
              rowSpan={rowSpan}
              className={`${tdColorClickable}`}
              key={paramData.key}
              style={tdInlineStyle}
              onClick={() => {
                const timeslotNotificationData = {
                  selectedTimeslot: bookObj.start_time,
                  location_id: bookObj.location.id,
                };

                this.props.openBookingTimeslotNotificationModal(
                  timeslotNotificationData
                );
              }}
              onMouseEnter={(event) => {
                event.target.firstChild.innerText = "Add to wait list";
                event.target.className = addToWaitList;
              }}
              onMouseLeave={(event) => {
                event.target.firstChild.innerText = "Booked";

                event.target.className = tdColorClickable;
              }}
            >
              <strong className={tdTextColor}>Booked</strong>
            </td>
          );
        }
        if (validateAccess(storeUserAccess, "AdminBooking", "viewBooking")) {
          return (
            <td
              rowSpan={rowSpan}
              className={`${tdColorClickable}  td-popup`}
              key={paramData.key}
              style={tdInlineStyle}
              onClick={() =>
                this.props.openBookingDetailsModal(paramData, booked)
              }
            >
              {/* {this.displayIcon(bookObj)} */}
              {this.displayIcon(bookObj)}

              <strong className={tdTextColor}>
                {this.validateDisplayName(
                  bookObj.customer.first_name + " " + bookObj.customer.last_name
                )}
                <br />
                {
                  // If admin show the number of checked in participants
                  !this.props.isDefaultUser &&
                    this.showCheckedInParticipants(bookObj.participants)
                }
              </strong>

              {!this.props.isDefaultUser && (
                // If admin show the pop up status
                <span className="td-popup-container">
                  {this.renderPopupContent(bookObj)}
                </span>
              )}
            </td>
          );
        }
        return (
          <td
            rowSpan={rowSpan}
            className={`${tdColorClickable}  td-popup`}
            key={paramData.key}
            style={tdInlineStyle}
          >
            {this.displayIcon(bookObj)}
            <strong className={tdTextColor}>
              {this.validateDisplayName(
                bookObj.customer.first_name + " " + bookObj.customer.last_name
              )}
            </strong>
            <span className="td-popup-container">
              {this.renderPopupContent(bookObj)}
            </span>
          </td>
        );
      } else {
        return <></>;
      }
    }
  }

  validateDisplayName(str) {
    if (this.props.popupStatusClass.length > 0) {
      return str.substring(0, 10) + "...";
    }
    return str;
  }

  showCheckedInParticipants(participants) {
    var totalParticipants = participants.length;
    var checkedInParticipants = 0;
    participants.forEach((participant) => {
      if (participant.checked_in) {
        checkedInParticipants++;
      }
    });

    var str = `${checkedInParticipants}/${totalParticipants}`;
    return str;
  }

  renderParticipantsDetails(participant) {
    var name =
      participant.customer.firstName + " " + participant.customer.lastName;
    var membership = "No membership - N/A";
    if (participant.customer.membership) {
      membership =
        participant.customer.membership.type +
        " - " +
        participant.customer.membership.number;
    }

    var checkedInStatus = "Not checked in";
    if (participant.checked_in) {
      checkedInStatus = "Checked in";
    }
    return `${name} - ${membership} - ${checkedInStatus} `;
  }

  renderPopupContent(obj) {
    if (this.props.popupStatusClass.length > 0) {
      return (
        <div>
          {obj.participants.map((participant) => {
            return (
              <MDBRow>
                <MDBCol>
                  <strong>{this.renderParticipantsDetails(participant)}</strong>
                  <br />
                  <br />
                </MDBCol>
              </MDBRow>
            );
          })}
        </div>
      );
    }
    return <></>;
  }

  displayIcon(bookObj) {
    let isPaid =
      bookObj.transaction !== undefined &&
      bookObj.transaction.ispaid !== undefined &&
      bookObj.transaction.ispaid;

    let lastPaymentElem = bookObj.transaction.payments[0];

    let partiallyPaid =
      bookObj.transaction !== undefined &&
      bookObj.transaction.ispaid !== undefined &&
      bookObj.transaction.ispaid === 0 &&
      lastPaymentElem.voucherCreditAmount > 0;

    let unpaid =
      bookObj.transaction !== undefined &&
      bookObj.transaction.ispaid !== undefined &&
      bookObj.transaction.ispaid === 0 &&
      // lastPaymentElem.totalAmount === lastPaymentElem.subTotalAmount &&
      lastPaymentElem.voucherCreditAmount === 0;

    return (
      <div className="slotIconContainer">
        <MDBBadge pill color="secondary">
          {bookObj.status}
        </MDBBadge>

        {isPaid ? (
          <MDBBadge pill color="success">
            Paid
          </MDBBadge>
        ) : (
          <></>
        )}

        {partiallyPaid ? (
          <MDBBadge pill color="warning-color-dark">
            Partially Paid
          </MDBBadge>
        ) : (
          <></>
        )}

        {unpaid ? (
          <MDBBadge pill color="warning-color-dark">
            Unpaid
          </MDBBadge>
        ) : (
          <></>
        )}

        {bookObj.is_recurring === 1 ? (
          <MDBBadge pill color="primary">
            {bookObj.recurring_type}
          </MDBBadge>
        ) : (
          <></>
        )}

        {bookObj.is_recurring === 0 &&
        bookObj.parent_booking !== undefined &&
        bookObj.parent_booking.recurring_type !== undefined ? (
          <MDBBadge pill color="primary">
            {bookObj.parent_booking.recurring_type}
          </MDBBadge>
        ) : (
          <></>
        )}
      </div>
    );
  }

  toggleBookingRequestModal = (isShow = false) => {
    this.setState({ isShowBookingRequestModal: isShow });
  };

  renderBookingRequestTD(bookingRequestObj, key, rowSpan, isStartTime) {
    const { bookingColors, isDefaultUser, isPrivate } = this.props;
    const isBookingOwner = bookingRequestObj["is_booking_request_owner"];

    const isAdmin = !isDefaultUser && isPrivate;

    const bgColor =
      isBookingOwner || isAdmin
        ? bookingColors.bg_request_booking
        : bookingColors.bg_notAvailable;

    if (isStartTime) {
      return (
        <td
          rowSpan={rowSpan}
          className={getCss(bgColor)}
          style={{ cursor: isBookingOwner ? "pointer" : "default" }}
          key={key}
          onClick={() => {
            if (isBookingOwner) {
              window.location.replace(
                "/admin/checkoutpage?booking_request_uuid=" +
                  bookingRequestObj["uuid"]
              );
            } else if (isAdmin) {
              this.setState({
                bookingRequestData: bookingRequestObj,
              });
              this.toggleBookingRequestModal(true);
            }
          }}
        >
          {(isBookingOwner || isAdmin) && (
            <div className="slotIconContainer">
              <MDBBadge pill color="secondary">
                Payment Pending
              </MDBBadge>
            </div>
          )}

          <strong className={getCss(bookingColors.tc_request_booking)}>
            <BookingRequestCountDownTimerComponent
              expirationDateTime={bookingRequestObj.expiration_date_time}
              bookingRequestObj={bookingRequestObj}
              isBookingOwner={isBookingOwner}
              isAdmin={isAdmin}
            />
          </strong>
        </td>
      );
    } else {
      return <></>;
    }
  }

  renderPendingRecurringBookings(
    pendingRecurringSchedule,
    key,
    rowSpan,
    isStartTime
  ) {
    const { bookingColors, isDefaultUser, isPrivate } = this.props;

    const isAdmin = !isDefaultUser && isPrivate;

    const bgColor = isAdmin
      ? bookingColors.bg_recurring
      : bookingColors.bg_notAvailable;

    let statusDisplay = "Processing Booking";

    switch (pendingRecurringSchedule["status"]) {
      case "updating":
        statusDisplay = "Updating Booking...";
        break;
      case "cancelling":
        statusDisplay = "Cancelling Booking...";
        break;
      default:
        break;
    }

    if (isStartTime) {
      return (
        <td
          rowSpan={rowSpan}
          className={getCss(bgColor)}
          style={{ cursor: "default" }}
          key={key}
        >
          {isAdmin && (
            <div className="slotIconContainer">
              <strong className={getCss(bookingColors.tc_recurring)}>
                {statusDisplay}
              </strong>
            </div>
          )}
        </td>
      );
    } else {
      return <></>;
    }
  }

  renderTD(text, key) {
    const { bookingColors, isPrivate } = this.props;
    if (!isPrivate) {
      return (
        <td
          key={key}
          className={
            text.length === 0
              ? getCss(bookingColors.bg_public_notAvailable)
              : ""
          }
        >
          <strong>{text}</strong>
        </td>
      );
    } else {
      if (text.length === 0) {
        return (
          <td key={key} className={getCss(bookingColors.bg_notAvailable)}></td>
        );
      }
      return (
        <td key={key}>
          <strong>{text}</strong>
        </td>
      );
    }
  }

  validateAvailableTD(paramData) {
    const { isDefaultUser, isPrivate } = this.props;

    let searchDateVal = moment(this.props.selectedDate).format("YYYY-MM-DD");

    let genStartTime = _.split(paramData.time, "-")[0];
    let addedTime = genStartTime;
    let durationCounter = 0;

    let minDuration = new Number(
      _.replace(paramData.minDuration, "m", "")
    ).valueOf();

    let bookedStartTimeArr = _.chain(paramData.bookings)
      .map((book) => {
        return moment(book.start_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm");
      })
      .sort()
      .value();

    let maxTime = "";

    for (let i = 0; i < bookedStartTimeArr.length; i++) {
      if (
        moment(genStartTime, "HH:mm").isBefore(
          moment(bookedStartTimeArr[i], "HH:mm")
        )
      ) {
        maxTime = bookedStartTimeArr[i];
        break;
      }
    }

    let resourceId = paramData.resourceId;

    let locked = paramData.locked;
    let recurrings = paramData.recurrings;

    let tz = this.getTzDetails(new Date(searchDateVal));

    let result = _.filter(locked, function (data) {
      return (
        data["resource_id"] == resourceId &&
        data["timeslot"] == genStartTime &&
        data["date"] == searchDateVal
      );
    });

    if (result.length > 0) {
      return this.renderTD("", paramData.key);
    }

    let timezone = tz.timezone;
    let currentDateTime = moment(searchDateVal + " " + genStartTime).format(
      "YYYY-MM-DD HH:mm"
    );
    if (!paramData.allowBookingSameTime && isDefaultUser) {
      let result = _.filter(paramData.resources, function (resource) {
        let booking = _.filter(resource.bookings, function (booking) {
          if (typeof booking.customer == "undefined") {
            return false;
          }
          let booking_from = moment(booking.start_time)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm");
          let booking_to = moment(booking.end_time)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm");
          return (
            currentDateTime >= booking_from && currentDateTime < booking_to
          );
        });
        return booking.length > 0;
      });

      if (result.length > 0) {
        return this.renderTD("", paramData.key);
      }
    }

    let recurring = _.filter(recurrings, function (data) {
      let booking_from = moment(data.booking_from)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm");
      let booking_to = moment(data.booking_to)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm");
      return (
        data["resource_id"] == resourceId &&
        currentDateTime >= booking_from &&
        currentDateTime < booking_to
      );
    });

    if (recurring.length > 0) {
      return this.renderTD("", paramData.key);
    }

    let pending_recurring_schedules = paramData.pending_recurring_schedules;

    let formattedPendingRecurringSchedules = _.filter(
      pending_recurring_schedules,
      function (data) {
        let booking_from = moment(data.booking_from)
          .tz(timezone)
          .format("YYYY-MM-DD HH:mm");
        let booking_to = moment(data.booking_to)
          .tz(timezone)
          .format("YYYY-MM-DD HH:mm");
        return currentDateTime >= booking_from && currentDateTime < booking_to;
      }
    );

    if (formattedPendingRecurringSchedules.length > 0) {
      let pendingRecurringSchedule = formattedPendingRecurringSchedules[0];
      let rowSpan = 1;
      let t1 = moment(pendingRecurringSchedule.booking_from);
      let t2 = moment(pendingRecurringSchedule.booking_to);
      let duration = t2.diff(t1, "minutes");
      rowSpan = duration / 30;

      let isStartTime = false;
      let genStartTime = _.split(paramData.time, "-")[0];
      let localStartTime = moment(pendingRecurringSchedule.booking_from)
        .tz(this.getTzDetails().timezone)
        .format("HH:mm");

      if (genStartTime === localStartTime) {
        isStartTime = true;
      }

      return this.renderPendingRecurringBookings(
        pendingRecurringSchedule,
        paramData.key,
        rowSpan,
        isStartTime
      );
    }

    let booking_requests = paramData.booking_requests;

    let formattedBookingRequest = _.filter(booking_requests, function (data) {
      let booking_from = moment(data.booking_from)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm");
      let booking_to = moment(data.booking_to)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm");
      return currentDateTime >= booking_from && currentDateTime < booking_to;
    });

    if (formattedBookingRequest.length > 0) {
      let formattedbookingRequestObj = formattedBookingRequest[0];
      let rowSpan = 1;
      let t1 = moment(formattedbookingRequestObj.booking_from);
      let t2 = moment(formattedbookingRequestObj.booking_to);
      let duration = t2.diff(t1, "minutes");
      rowSpan = duration / 30;

      let isStartTime = false;
      let genStartTime = _.split(paramData.time, "-")[0];
      let localStartTime = moment(formattedbookingRequestObj.booking_from)
        .tz(this.getTzDetails().timezone)
        .format("HH:mm");

      if (genStartTime === localStartTime) {
        isStartTime = true;
      }

      return this.renderBookingRequestTD(
        formattedbookingRequestObj,
        paramData.key,
        rowSpan,
        isStartTime
      );
    }

    let allowStartTime = paramData.allowStartTime;
    let allowEndTime = paramData.allowEndTime;
    let time = paramData.time.split("-");

    let startTime = parseInt(time[0].replace(":", ""));
    let endTime = parseInt(time[1].replace(":", ""));
    if (allowEndTime != "" && endTime > allowEndTime) {
      return this.renderTD("", paramData.key);
    }

    if (allowStartTime != "" && startTime < allowStartTime) {
      return this.renderTD("", paramData.key);
    }

    if (!isPrivate || isDefaultUser) {
      let currentDate = moment().format("YYYY-MM-DD");
      let currentTime = moment().format("HH:mm");
      if (currentTime > genStartTime && searchDateVal <= currentDate) {
        return this.renderTD("", paramData.key);
      }
    }

    if (maxTime.length > 0) {
      while (addedTime !== maxTime) {
        addedTime = moment(addedTime, "HH:mm")
          .add(30, "minutes")
          .format("HH:mm");
        durationCounter += 30;
      }

      if (durationCounter < minDuration) {
        return this.validateNotAvailableTD(paramData);
      } else {
        return this.renderAvailableTD(paramData);
      }
    } else {
      return this.renderAvailableTD(paramData);
    }
  }

  validateNotAvailableTD(paramData) {
    const { isPrivate } = this.props;
    let genStartTime = _.split(paramData.time, "-")[0];
    let defaultStartTime = paramData.defaultStartTime;
    let minDuration = new Number(
      _.replace(paramData.minDuration, "m", "")
    ).valueOf();
    let minusTime = moment(genStartTime, "HH:mm")
      .subtract(30, "minutes")
      .format("HH:mm");

    if (
      !isPrivate &&
      moment(minusTime, "HH:mm").isBefore(moment(defaultStartTime, "HH:mm"))
    ) {
      return this.renderTD("", paramData.key);
    } else {
      // return this.renderAvailableTD(paramData);
      let durationCounter = 0;
      let bookedStartTimeArr = _.chain(paramData.bookings)
        .map((book) => {
          return moment(book.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
        })
        .sort()
        .reverse()
        .value();

      let minTime = "";

      for (let i = 0; i < bookedStartTimeArr.length; i++) {
        if (
          moment(genStartTime, "HH:mm").isAfter(
            moment(bookedStartTimeArr[i], "HH:mm")
          )
        ) {
          minTime = bookedStartTimeArr[i];
          break;
        }
      }

      let minusTime = genStartTime;
      if (minTime.length > 0) {
        while (minusTime !== minTime) {
          minusTime = moment(minusTime, "HH:mm")
            .subtract(30, "minutes")
            .format("HH:mm");
          durationCounter += 30;
        }

        if (!isPrivate && durationCounter < minDuration) {
          return this.renderTD("", paramData.key);
        } else {
          return this.renderAvailableTD(paramData);
        }
      } else {
        return this.renderAvailableTD(paramData);
      }
    }
  }

  renderAvailableTD(paramData) {
    const {
      bookingColors,
      allowLocResourcePublicBooking,
      isDefaultUser,
      storeUserAccess,
      isPrivate,
    } = this.props;
    if (!isPrivate) {
      if (
        allowLocResourcePublicBooking === undefined ||
        (allowLocResourcePublicBooking !== undefined &&
          "no" === allowLocResourcePublicBooking.toLowerCase())
      ) {
        return (
          <td
            className={getCss(bookingColors.bg_public_available)}
            key={paramData.key}
          >
            <strong className={getCss(bookingColors.tc_public_available)}>
              Available
            </strong>
          </td>
        );
      }
      return (
        <td
          className={getCss(bookingColors.bg_public_available)}
          style={{ cursor: "pointer" }}
          onClick={() => this.props.openModal(paramData)}
          key={paramData.key}
        >
          <strong className={getCss(bookingColors.tc_public_available)}>
            Available
          </strong>
        </td>
      );
    } else {
      if (validateAccess(storeUserAccess, "AdminBooking", "addBooking")) {
        if (isDefaultUser) {
          if (
            validateAccess(
              storeUserAccess,
              "AdminBooking",
              "showBookingNotAvailable"
            )
          ) {
            return (
              <td
                className={getCss(bookingColors.bg_available)}
                style={{ cursor: "pointer" }}
                onClick={() => this.props.tempReadonlyModal()}
                key={paramData.key}
              ></td>
            );
          } else {
            return (
              <td
                className={getCss(bookingColors.bg_available)}
                style={{ cursor: "pointer" }}
                onClick={() => this.props.openBookingModal(paramData)}
                key={paramData.key}
              ></td>
            );
          }
        }

        return (
          <td
            className={getCss(bookingColors.bg_available)}
            style={{ cursor: "pointer" }}
            onClick={() => this.props.openBookingModal(paramData)}
            key={paramData.key}
          ></td>
        );
      }
      return (
        <td
          className={getCss(bookingColors.bg_available)}
          key={paramData.key}
        ></td>
      );
    }
  }

  buildRow(obj) {
    return (
      <tr
        key={obj.key}
        className={
          "tr-fix-height " +
          (obj.key % 2 === 0 ? "table-border-bottom-dotted" : "")
        }
      >
        {this.validateRenderingOfTD(obj)}
      </tr>
    );
  }

  render() {
    // return this.state.tableElem;
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          top: "-1.4px",
          position: "absolute",
        }}
      >
        {this.state.tableElem}
        {this.state.isShowBookingRequestModal && (
          <BookingRequestModal
            isShowBookingRequestModal={this.state.isShowBookingRequestModal}
            toggleBookingRequestModal={this.toggleBookingRequestModal}
            bookingRequestDataUUID={this.state.bookingRequestData.uuid}
          />
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.validateRenderingOfCalendar(prevProps, prevState);
    this.getSelectedDate(prevProps, prevState);
    this.validateTimer(prevProps, prevState);
  }

  validateTimer(prevProps, prevState) {
    if (
      prevProps.disableParentCalendarTimerResponse !==
      this.props.disableParentCalendarTimerResponse
    ) {
      if (
        this.props.disableParentCalendarTimerResponse &&
        this.props.isChangeSchedule === undefined
      ) {
        clearInterval(this.intervalId);
      }
      if (!this.props.disableParentCalendarTimerResponse) {
        this.startCalendarRefresh();
      }
    }
  }

  debouncedSearchCustomer = _.debounce(function () {
    // console.log("Execute api request.....");
    this.refreshCalendar();
    this.startCalendarRefresh();
  }, 500);

  getSelectedDate(prevProps, prevState) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      clearInterval(this.intervalId);
      this.debouncedSearchCustomer();
    }
  }

  validateRenderingOfCalendar(prevProps, prevState) {
    if (prevProps.bookingResponse !== this.props.bookingResponse) {
      let res = this.props.bookingResponse;
      if (res.action === "GET_RESOURCE_BOOKINGS") {
        if (typeof res.data !== "undefined") {
          if (res.data.identifier === this.props.resourceObj.resourceUuid) {
            let results = this.props.bookingResponse.data.results;

            if (results.bookings !== undefined) {
              let paramData = {
                bookings: results.bookings,
                locked: results.locked,
                recurrings: results.recurrings,
                booking_requests: results.booking_requests,
                pending_recurring_schedules:
                  results.pending_recurring_schedules,
              };

              this.buildTable(paramData);
            }
          }
        }
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    bookingResponse: state.bookingResponse,
    authUser: state.authUser,
    selectedDate: state.selectedDate,
    disableParentCalendarTimerResponse:
      state.disableParentCalendarTimerResponse,
    pauseBackgroundRequestsResponse: state.pauseBackgroundRequestsResponse,
  };
};

export default connect(mapStateToProps, {
  getResourceBookings,
  getResourceBookingsPublic,
  disableParentCalendarTimer,
})(CalendarBookingComponent);
