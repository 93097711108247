import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { connect } from "react-redux";
import { genModalRole } from "../../../actions";
import { GEN_MODAL_ROLE } from "../../../actions/types";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";
import { insertRole, updateRole } from "../../../actions/roleAction";
import { getCustomers } from "../../../actions/customerAction";
import { getLocationList } from "../../../actions/locationAction";
import { getResourceList } from "../../../actions/resourceAction";

import _ from "lodash";
import moment from "moment";
import Modal from "./Modal";

class RoleModal extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    notification: {},

    roleId: "",
    role: "",
    description: "",
    wasValidated: "",

    componentName: "RoleModal",
    parent: "",
  };

  componentDidMount() { }

  render() {
    return (
      <div>

        {this.renderForm()}
      </div>
    );
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const { roleId, role, description, isEdit } = this.state;
    let body = {
      role: role,
      description: description,
    };

    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    console.log(event.target.checkValidity());
    if (role.length === 0) {
      this.props.showNotification("error", "Please enter role");
    }

    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateRole(body, roleId, this.props.authUser.sessionToken);
      } else {
        this.props.insertRole(body, this.props.authUser.sessionToken);
      }
    }
  };

  renderForm() {
    const { role, description, showModal, isEdit } = this.state;
    return (
      <div>
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
            autoComplete="off"
          >
            <MDBModalHeader>{isEdit ? "Edit" : "Add"} Role</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="8">
                  <MDBInput
                    required
                    label="Role"
                    labelClass="required"
                    value={role}
                    name="role"
                    onChange={this.formInputChangeHandler}
                  />
                </MDBCol>

                <MDBCol md="12">
                  <MDBInput
                    label="Description"
                    name="description"
                    value={description}
                    onChange={this.formInputChangeHandler}
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  clearForm() {
    this.setState({
      isEdit: false,
      roleId: "",
      role: "",
      description: "",
      wasValidated: "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.roleResponse !== this.props.roleResponse) {
      let response = this.props.roleResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          let attr = {
            show: false,
            action: "callback",
            parent: this.state.parent,
            response: response,
          };
          this.props.genModalRole(attr);
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
        }
      }
    }

    this.validateModal(prevProps, prevState);
  }

  validateModal(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_ROLE) {
        if (response.data.show && response.data.action === "add") {
          this.setState({ showModal: true, parent: response.data.parent });
        } else if (response.data.show && response.data.action === "edit") {
          let source = response.data.payload;
          this.setState({
            showModal: true,
            isEdit: true,

            roleId: source.id,
            //-- todo
            role: source.role,
            description: source.description,
          });
        }
      }
    }
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,

        organisationId: source.organisation.id,
        //-- todo
        locationId:
          source.location !== undefined && source.location.id !== null
            ? source.location.id
            : "",
        locationValue:
          source.location !== undefined && source.location.name !== null
            ? source.location.name
            : "",
        value: source.customer.email
          ? source.customer.firstName +
          " " +
          source.customer.lastName +
          ", " +
          source.customer.email
          : "",
        customerId: source.customer.id !== undefined ? source.customer.id : "",

        amount: source.amount,
        expiryDate: new Date(source.expiry),
        description: source.description,
        voucherCode: source.voucher_code,
        voucherId: source.id,
        customerVoucher:
          source.voucher_code +
          (source.customer.customer_voucher_id !== undefined &&
            source.customer.customer_voucher_id !== null
            ? source.customer.customer_voucher_id
            : ""),
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit, value: "" });
    }

    if (!showModal) {
      this.clearForm();
    }
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  formInputChangeHandler = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    roleResponse: state.roleResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertRole,
    updateRole,
    showNotification,
    clearNotification,
    getCustomers,
    getLocationList,
    getResourceList,
    genModalRole,
  })(RoleModal)
);
