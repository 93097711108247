import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
} from "mdbreact";
import { connect } from "react-redux";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  addModalAttr,
  showNotification,
  genModalFavouriteCustomer,
} from "../../../actions";
import {
  getUserFavourites,
  deleteFavouriteCustomer,
} from "../../../actions/userFavouriteAction";
import FavouriteCustomerModal from "../shared/FavouriteCustomerModal";
import { getCustomers } from "../../../actions/customerAction";

class FavouriteList extends React.Component {
  state = {
    componentName: "FavouriteList",
    userFavourites: [],
    favouriteId: "",
  };

  componentDidMount() {
    this.props.getUserFavourites({}, this.props.authUser.sessionToken);
    this.props.displayBreadcrumbs("Dashboard / My Favourite List");
  }

  clearForm() {
    this.setState({
      isEdit: false,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      mobile: "",
      customer: {},
      favouriteId: "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userFavouriteResponse !== this.props.userFavouriteResponse) {
      let response = this.props.userFavouriteResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
        }
        this.props.getUserFavourites({}, this.props.authUser.sessionToken);
      } else {
        this.setState({
          ...this.state,
          userFavourites: this.props.userFavouriteResponse.data,
        });
      }
    }
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.clearDeleteCustomerAlert();
  }

  clearDeleteCustomerAlert() {
    let modalAttr = {
      showAlert: false,
      alertType: "",
      alertMsg: "",
    };
    this.props.addModalAttr(modalAttr);
  }

  confirmDelete = (favouriteCustomer) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to remove ${favouriteCustomer.first_name} ${favouriteCustomer.last_name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ favouriteId: favouriteCustomer.id });
  };

  doDelete() {
    const { favouriteId } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteFavouriteCustomer(
      favouriteId,
      this.props.authUser.sessionToken
    );
    this.closeModal();
  }

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  showAddCustomerAsFavouriteModal = (source) => {
    if (source !== null) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalFavouriteCustomer(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
        payload: {},
      };
      this.props.genModalFavouriteCustomer(attr);
    }
  };

  render() {
    const { userFavourites } = this.state;
    return (
      <>
        <FavouriteCustomerModal />
        <MDBContainer>
          {/* FILTER SECTION */}
          <div>
            <MDBRow className="mb-4">
              <MDBCol md="6">
                <h3 className="mt-3">My Favourite List</h3>
              </MDBCol>
              <MDBCol md="6">
                <MDBBtn
                  color="primary"
                  onClick={() => this.showAddCustomerAsFavouriteModal(null)}
                  className="float-right"
                >
                  Add Favourite
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>

          {/* END FILTER SECTION */}

          {/* START TABLE SECTION */}
          <MDBTable responsive striped bordered className="nested-table">
            <MDBTableHead>
              <tr>
                <th width="90%" className="text-center">
                  Favourite Customer Details
                </th>
                <th width="10%"></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {userFavourites.map((customer) => {
                return (
                  <tr>
                    <td>
                      <div>
                        <table>
                          <tbody className="table-th-100-px">
                            <tr>
                              <th>
                                <MDBIcon
                                  icon="user-alt"
                                  color="black"
                                  size="1x"
                                />{" "}
                                Name
                              </th>
                              <td>
                                {customer.first_name + " " + customer.last_name}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <MDBIcon icon="at" color="black" size="1x" />{" "}
                                Email
                              </th>
                              <td>{customer.email}</td>
                            </tr>
                            <tr>
                              <th>
                                <MDBIcon
                                  icon="mobile-alt"
                                  color="black"
                                  size="1x"
                                />{" "}
                                Mobile
                              </th>
                              <td>{customer.mobile_no}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                    <td className="text-center">
                      <MDBDropdown>
                        <MDBDropdownToggle caret color="primary" size="sm">
                          Actions
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                          <MDBDropdownItem
                            onClick={() =>
                              this.showAddCustomerAsFavouriteModal(customer)
                            }
                          >
                            <MDBIcon icon="edit" className="cyan-text" /> Edit
                          </MDBDropdownItem>

                          <MDBDropdownItem
                            onClick={() => this.confirmDelete(customer)}
                          >
                            <MDBIcon icon="trash" className="red-text" /> Remove
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
          {/* END TABLE SECTION */}
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    customerResponse: state.customerResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    userFavouriteResponse: state.userFavouriteResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getCustomers,
    showHideLoader,
    toggleModal,
    addModalAttr,
    showNotification,
    genModalFavouriteCustomer,
    getUserFavourites,
    deleteFavouriteCustomer,
  })(FavouriteList)
);
