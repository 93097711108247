import {
  BOOKING_CANCELLED,
  BOOKING_FAILED_HANDLER,
  BOOKING_CONFLICTS,
  GET_BOOKING_PARTICIPANTS,
  GET_USER_BOOKINGS,
  GET_USER_CALENDAR,
  BOOKING_PARTICIPANT_OPTOUT,
  GET_RESOURCE_BOOKINGS,
  GET_BOOKINGS_REPORT,
  BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION,
  BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER,
} from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case GET_BOOKING_PARTICIPANTS:
      response["data"] = action.payload.data;
      response["action"] = "GET_BOOKING_PARTICIPANTS";
      break;
    case BOOKING_CANCELLED:
      response["data"] = action.payload.data;
      response["action"] = "ACTION";
      break;
    case BOOKING_PARTICIPANT_OPTOUT:
      response["data"] = action.payload.data;
      response["action"] = BOOKING_PARTICIPANT_OPTOUT;
      break;
    case BOOKING_CONFLICTS:
      response["data"] = action.payload.data;
      response["page"] = action.page;
      response["action"] = "CONFLICTS";
      break;
    case BOOKING_FAILED_HANDLER:
      action.payload.iscreate = action.isCreate;
      response["payload"] = action.payload;
      break;
    case GET_USER_BOOKINGS:
      response["data"] = action.payload.data;
      response["action"] = "GET_USER_BOOKINGS";
      break;
    case GET_USER_CALENDAR:
      response["data"] = action.payload.data;
      response["action"] = "GET_USER_CALENDAR";
      break;
    case GET_RESOURCE_BOOKINGS:
      response["data"] = action.payload.data;
      response["action"] = "GET_RESOURCE_BOOKINGS";
      break;
    case GET_BOOKINGS_REPORT:
      response["data"] = action.payload;
      response["action"] = GET_BOOKINGS_REPORT;
      break;
    case BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION:
      response["data"] = action.payload;
      response["action"] = BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION;
      break;
    case BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER:
      response["data"] = action.payload;
      response["action"] =
        BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER;
      break;

    default:
      return state;
  }
  return response;
};
