import React from "react";
import { MDBContainer } from "mdbreact";
import { validateLayout } from "../../../actions";
import { connect } from "react-redux";

class FourZeroFour extends React.Component {
  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: false,
      showSearch: false,
      showHeader: false,
      showLogin: false,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();
  }

  renderContent() {
    return (
      <div>
        <MDBContainer>
          <h1 className="text-center mt-5 text-danger">404</h1>
          <h5 className="text-center">Opps! Page not found</h5>
          <h6 className="text-center">
            Sorry, but the page your are looking for is not found. Please, make
            sure you have typed the correct URL
          </h6>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout };
};

export default connect(mapStateToProps, { validateLayout })(FourZeroFour);
