import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBBtn, MDBIcon } from "mdbreact";
import moment, { utc } from "moment";
import DatePicker from "react-datepicker";

class CalendarNavigation extends React.Component {
  componentDidMount() {}

  state = {
    calendarPrevNavDisable: this.props.defaultState,
    calendarNextNavDisable: this.props.defaultState,
  };

  render() {
    if (this.props.isNext) {
      return (
        <MDBBtn
          color="grey darken-1"
          size="sm"
          style={{ margin: "0" }}
          onClick={() => this.props.calendarNav(this.props.isNext)}
          // onMouseEnter={() => this.clearRefreshCalendarTimer()}
          disabled={this.state.calendarNextNavDisable}
        >
          <MDBIcon icon={"angle-double-right"} />
        </MDBBtn>
      );
    } else {
      return (
        <MDBBtn
          color="grey darken-1"
          size="sm"
          style={{ margin: "0" }}
          onClick={() => this.props.calendarNav(this.props.isNext)}
          // onMouseEnter={() => this.clearRefreshCalendarTimer()}
          disabled={this.state.calendarPrevNavDisable}
        >
          <MDBIcon icon={"angle-double-left"} />
        </MDBBtn>
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.calendarNextNavResponse !== this.props.calendarNextNavResponse
    ) {
      this.setState({
        calendarNextNavDisable: this.props.calendarNextNavResponse,
      });
    }

    if (
      prevProps.calendarPrevNavResponse !== this.props.calendarPrevNavResponse
    ) {
      this.setState({
        calendarPrevNavDisable: this.props.calendarPrevNavResponse,
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    // selectedDate: state.selectedDate,
    calendarNextNavResponse: state.calendarNextNavResponse,
    calendarPrevNavResponse: state.calendarPrevNavResponse,
  };
};

export default connect(mapStateToProps, {})(CalendarNavigation);
