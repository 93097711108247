import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Autocomplete from "react-autocomplete";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSelect,
} from "mdbreact";
import { connect } from "react-redux";
import Spinner from "./Loader";
import DatePicker from "react-datepicker";
import { genModalCustomer, genModalVoucher } from "../../../actions";
import { GEN_MODAL_CUSTOMER, GEN_MODAL_VOUCHER } from "../../../actions/types";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";
import {
  getVouchers,
  insertVoucher,
  updateVoucher,
  deleteVoucher,
  clearVoucherResponse,
  generateCustomerVoucher,
  assignVoucher,
} from "../../../actions/voucherAction";
import { getCustomers } from "../../../actions/customerAction";
import { getLocationList } from "../../../actions/locationAction";
import { getResourceList } from "../../../actions/resourceAction";

import _ from "lodash";
import moment from "moment";
import Modal from "./Modal";

class ManageVoucher extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    notification: {},

    organisationId: "",
    locationId: "",
    locationUuid: "",
    resourceId: "",
    customerId: "",
    amount: "",
    expiryDate: new Date(),
    description: "",
    voucherCode: "",
    voucherId: "",
    wasValidated: "",
    customerVoucher: "",

    value: "",
    customerResultContainer: [],

    locationValue: "",
    locationResultContainer: [],

    resourceValue: "",
    resourceResultContainer: [],
    showAddCustomer: false,
    componentName: "ManageVoucher",
    parent: "",
    disableEditingPrimaryFields: false,
    locationsOptions: [],
  };

  debouncedSearchCustomer = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 20, query: val };
    this.props.getCustomers(params, this.props.authUser.sessionToken);
  }, 1000);

  debouncedSearchLocation = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 5, q: val, is_active: 1 };
    this.props.getLocationList(params, this.props.authUser.sessionToken);
  }, 1000);

  debouncedSearchResource = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 5, locationUuid: this.state.locationUuid, q: val };
    this.props.getResourceList(params, this.props.authUser.sessionToken);
  }, 1000);

  initCustomerSearchRecord() {
    let params = { limit: 10, query: "" };
    this.props.getCustomers(params, this.props.authUser.sessionToken);
  }

  initLocationSearchRecord() {
    let params = { limit: 10, q: "", is_active: 1 };
    this.props.getLocationList(params, this.props.authUser.sessionToken);
  }

  dateOnChange = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Expiry Date"
          onChange={this.dateOnChange}
          selected={this.state.expiryDate}
          dateFormat="dd/MM/yyyy"
          // showTimeSelect
          timeFormat="HH:mm"
        />
      </span>
    );
  }

  renderCustomerAutoComplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete">Search customer</label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.value}
          items={this.state.customerResultContainer}
          getItemValue={(item) => {
            let label =
              item.first_name + " " + item.last_name + ", " + item.email;
            if (item.membership) {
              label +=
                " - " +
                (item.membership.number ? item.membership.number : "N/A") +
                " - " +
                item.membership.name +
                " " +
                item.membership.type.name;
            }
            return label;
          }}
          onSelect={(value, item) => {
            this.setState({
              customerId: item.id,
              value,
              customerResultContainer: [item],
            });
          }}
          onChange={(event, value) => {
            clearTimeout(this.state.timer);
            this.debouncedSearchCustomer(value);
            this.setState({
              value,
              customerId: "",
            });
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.first_name + " " + item.last_name + ", " + item.email}
              {item.membership
                ? " - " +
                  (item.membership.number ? item.membership.number : "N/A") +
                  " - " +
                  item.membership.name +
                  " " +
                  item.membership.type.name
                : ""}
            </div>
          )}
        />
      </div>
    );
  }

  getValueOfSelectLocation = (value) => {
    if (value.length > 0) {
      let org = _.find(
        this.props.getLocationListSuccessResponse.data.locations.results,
        function (o) {
          return o.id + "" == value[0] + "";
        }
      );
      this.setState({
        organisationId: org.organisation.id,
        locationId: value.length > 0 ? value[0] : "",
      });
    }
  };

  renderLocationAutoComplete() {
    return (
      <div className="autoComplete">
        <MDBSelect
          outline
          name="limit"
          color="primary"
          getValue={(value) => {
            this.getValueOfSelectLocation(value);
          }}
          options={this.state.locationsOptions}
          label="Select Location"
        />
      </div>
    );
  }

  // renderLocationAutoComplete() {
  //   return (
  //     <div className="autoComplete">
  //       <label htmlFor="states-autocomplete2" className="required">
  //         Search location
  //       </label>
  //       <Autocomplete
  //         autoComplete="off"
  //         inputProps={{ id: "states-autocomplete2" }}
  //         wrapperStyle={{ width: "100%", display: "inline-block" }}
  //         menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
  //         value={this.state.locationValue}
  //         items={this.state.locationResultContainer}
  //         getItemValue={(item) => item.name}
  //         onSelect={(value, item) => {
  //           // this.props.getResourceList(
  //           //   { locationUuid: item.uuid },
  //           //   this.props.authUser.sessionToken
  //           // );

  //           this.setState({
  //             locationId: item.id,
  //             locationUuid: item.uuid,
  //             locationValue: value,
  //             locationResultContainer: [item],
  //             resourceValue: "",
  //             organisationId: item.organisation.id,
  //           });
  //         }}
  //         onChange={(event, value) => {
  //           this.setState({ locationValue: value, locationId: "" });
  //           this.debouncedSearchLocation(value);
  //         }}
  //         renderMenu={(children) => <div className="menu">{children}</div>}
  //         renderItem={(item, isHighlighted) => (
  //           <div
  //             className={`item ${isHighlighted ? "item-highlighted" : ""}`}
  //             key={item.id}
  //           >
  //             {item.name}
  //           </div>
  //         )}
  //       />
  //     </div>
  //   );
  // }

  renderResourceAutoComplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete2" className="required">
          Search resource
        </label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete2" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.resourceValue}
          items={this.state.resourceResultContainer}
          getItemValue={(item) => item.name}
          onSelect={(value, item) => {
            this.setState({
              resourceId: item.id,
              resourceValue: value,
              resourceResultContainer: [item],
            });
          }}
          onChange={(event, value) => {
            this.setState({ resourceValue: value, resourceId: "" });
            this.debouncedSearchResource(value);
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.name}
            </div>
          )}
        />
      </div>
    );
  }

  render() {
    const { showAddCustomer } = this.state;
    return <div>{this.renderForm()}</div>;
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const {
      organisationId,
      locationId,
      resourceId,
      customerId,
      amount,
      expiryDate,
      description,
      voucherCode,
      voucherId,
      isEdit,
      disableEditingPrimaryFields,
      customerVoucher,
      userVoucherId,
    } = this.state;
    let body = {
      //-- TODO
      isPublish: true,
      resourceData: {
        resourceId: resourceId,
        locationId: locationId,
        organisationId: organisationId,
      },
      couponData: {
        amount: amount,
        expiry: moment(expiryDate).format("YYYY-MM-DD 00:00:00"),
        type: "",
        description: description,
        issuedBy: this.props.authUser.username,
      },
    };
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      if (locationId.length === 0) {
        this.props.showNotification("error", "Please select location");
      } else {
        this.props.showHideLoader(true);
        if (isEdit) {
          console.log("check her2e");
          body.couponData["coupon"] = voucherCode;
          if (customerId !== null && customerId !== "") {
            body.couponData["updateDetails"] = disableEditingPrimaryFields;
            body.couponData["userVoucherId"] = userVoucherId;
            body.couponData["customerCoupon"] = customerVoucher;
            this.props.assignVoucher(
              body,
              voucherId,
              customerId,
              this.props.authUser.sessionToken
            );
          } else {
            body.couponData["updateDetails"] = disableEditingPrimaryFields;
            this.props.updateVoucher(
              body,
              voucherId,
              this.props.authUser.sessionToken
            );
          }
        } else {
          if (customerId === null || customerId.length === 0) {
            this.props.insertVoucher(body, this.props.authUser.sessionToken);
          } else {
            this.props.generateCustomerVoucher(
              body,
              customerId,
              this.props.authUser.sessionToken
            );
          }
        }
      }
    }
  };

  showAddCustomer(show) {
    let attr = {
      show: true,
      action: "add",
      parent: this.state.componentName,
    };
    this.props.genModalCustomer(attr);
  }
  renderForm() {
    const {
      amount,
      description,
      showModal,
      isEdit,
      voucherCode,
      customerVoucher,
      disableEditingPrimaryFields,
      customerId,
      balance,
    } = this.state;
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
            autoComplete="off"
          >
            <MDBModalHeader>{isEdit ? "Edit" : "Add"} Voucher</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol
                  size="2"
                  style={{
                    display: `${voucherCode.length === 0 ? "none" : "block"}`,
                  }}
                >
                  <MDBInput
                    label="Voucher Code"
                    disabled
                    value={customerVoucher}
                  />
                </MDBCol>
                <MDBCol md="12" className="mb-3">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "90%" }}>
                          {!disableEditingPrimaryFields &&
                            this.renderCustomerAutoComplete()}

                          {disableEditingPrimaryFields && (
                            <MDBInput
                              label={
                                customerId.length === 0 &&
                                disableEditingPrimaryFields
                                  ? "Issue to customer is not available because the voucher is already in used"
                                  : "Customer"
                              }
                              disabled
                              value={this.state.value}
                            />
                          )}
                        </td>
                        <td
                          style={{
                            paddingTop: disableEditingPrimaryFields
                              ? "7px"
                              : "28px",
                          }}
                        >
                          <MDBBtn
                            color="primary"
                            size="sm"
                            disabled={disableEditingPrimaryFields}
                            style={{ height: "38px" }}
                            onClick={() => this.showAddCustomer(true)}
                          >
                            <i class="fas fa-plus"></i>
                          </MDBBtn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </MDBCol>
                <MDBCol md="12" className="mb-3">
                  {this.renderLocationAutoComplete()}
                </MDBCol>
                {/* <MDBCol md="6" className="mb-3">
                  {this.renderResourceAutoComplete()}
                </MDBCol> */}
                <MDBCol md="5">
                  <label htmlFor="expiryDate" className="grey-text required">
                    Expiry Date
                  </label>
                  {this.datePicker()}
                </MDBCol>
                <MDBCol md="3" className="mb-3">
                  <label htmlFor="amount" className="grey-text required">
                    Amount
                  </label>
                  <input
                    disabled={disableEditingPrimaryFields}
                    value={amount}
                    onChange={this.formInputChangeHandler}
                    name="amount"
                    type="text"
                    id="amount"
                    className="form-control"
                    placeholder="Enter amount"
                    required
                  />
                </MDBCol>
                {isEdit && balance !== undefined && (
                  <MDBCol md="3" className="mb-3">
                    <label htmlFor="balance">Balance</label>
                    <input
                      disabled={true}
                      value={balance}
                      name="balance"
                      type="text"
                      id="balance"
                      className="form-control"
                      required
                    />
                  </MDBCol>
                )}
                <MDBCol md="9" className="mb-3">
                  <label htmlFor="description" className="grey-text required">
                    Description
                  </label>
                  <input
                    value={description}
                    onChange={this.formInputChangeHandler}
                    name="description"
                    type="text"
                    id="description"
                    className="form-control"
                    placeholder="Enter Description"
                    required
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  clearForm() {
    this.setState({
      isEdit: false,
      organisationId: "",
      locationId: "",
      locationUuid: "",
      resourceId: "",
      customerId: "",
      amount: "",
      expiryDate: new Date(),
      description: "",
      wasValidated: "",
      voucherCode: "",
      voucherId: "",
      customerVoucher: "",

      locationValue: "",
      locationResultContainer: [],

      resourceValue: "",
      resourceResultContainer: [],
      showAddCustomer: false,
      disableEditingPrimaryFields: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.voucherResponse !== this.props.voucherResponse) {
      let response = this.props.voucherResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          //-- TODO assign voucher to customer
          if (!this.state.isEdit && this.state.customerId.length > 0) {
          }
          let attr = {
            show: false,
            action: "callback",
            parent: this.state.parent,
            response: response,
          };
          this.props.genModalVoucher(attr);
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
        }
      }
    }

    this.getCustomerResult(prevProps, prevState);
    this.getLocationResult(prevProps, prevState);
    this.getResourceResult(prevProps, prevState);
    this.customerCallback(prevProps, prevState);
    this.validateVoucherModal(prevProps, prevState);
  }

  validateVoucherModal(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_VOUCHER) {
        if (response.data.show && response.data.action === "add") {
          this.setState({ showModal: true, parent: response.data.parent });
        } else if (response.data.show && response.data.action === "edit") {
          let source = response.data.payload;
          let customerId =
            source.customer.id !== undefined && source.customer.id !== null
              ? source.customer.id
              : "";
          let balance =
            customerId !== ""
              ? source.balance
              : source.unissued_voucher_balance;
          let disableEditingPrimaryFields = false;

          if (
            customerId !== "" ||
            (customerId === "" &&
              source.unissued_voucher_balance !== undefined &&
              source.unissued_voucher_balance !== null &&
              source.unissued_voucher_balance < source.amount)
          ) {
            disableEditingPrimaryFields = true;
          }

          console.log(source);
          this.setState({
            showModal: true,
            isEdit: true,

            organisationId: source.organisation.id,
            //-- todo
            locationId:
              source.location !== undefined && source.location.id !== null
                ? source.location.id
                : "",
            locationValue:
              source.location !== undefined && source.location.name !== null
                ? source.location.name
                : "",
            value: source.customer.email
              ? source.customer.firstName +
                " " +
                source.customer.lastName +
                ", " +
                source.customer.email
              : "",
            customerId: customerId,

            amount: source.amount,
            balance: balance,
            expiryDate: new Date(source.expiry),
            description: source.description,
            voucherCode: source.voucher_code,
            voucherId: source.id,
            customerVoucher: source.voucher_code,
            parent: response.data.parent,
            disableEditingPrimaryFields: disableEditingPrimaryFields,
            userVoucherId: source.customer.customer_voucher_id,
          });
        }

        let params = { limit: 100, q: "", is_active: 1 };
        this.props.getLocationList(params, this.props.authUser.sessionToken);
      }
    }
  }

  customerCallback(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_CUSTOMER) {
        if (
          response.data.parent === this.state.componentName &&
          response.data.action === "callback"
        ) {
          let customer = response.data.response.data.customer;
          this.setState({
            customerId: customer.id,
            value:
              customer.first_name +
              " " +
              customer.last_name +
              ", " +
              customer.email_address,
          });
        }
      }
    }
  }

  getCustomerResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(this.props.customerResponse.data.results)
        .orderBy(["first_name"], ["asc"])
        .value();

      this.setState({
        customerResultContainer: resultContainer,
      });
    }
  }

  getLocationResult(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
      this.props.getLocationListSuccessResponse
    ) {
      let resultContainer = _.chain(
        this.props.getLocationListSuccessResponse.data.locations.results
      )
        .orderBy(["name"], ["asc"])
        .value();

      let locationsOptions = [];
      console.log("LOCATION RESULT?????");
      console.log(this.state.locationId);
      _.map(resultContainer, (result, index) => {
        // if (index === 0) {
        //   locationsOptions.push({
        //     checked: false,
        //     disabled: false,
        //     text: "No Location Selected",
        //     value: "",
        //   });
        // }
        let checked = false;
        if (result.id + "" === this.state.locationId + "") {
          checked = true;
        }
        locationsOptions.push({
          checked: checked,
          disabled: false,
          text: result.name,
          value: result.id,
        });
      });

      this.setState({
        locationsOptions: locationsOptions,
      });
    }
  }

  getResourceResult(prevProps, prevState) {
    if (
      prevProps.getResourceListSuccessResponse !==
      this.props.getResourceListSuccessResponse
    ) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(
        this.props.getResourceListSuccessResponse.data.resources.results
      )
        .orderBy(["name"], ["asc"])
        .value();
      this.setState({
        resourceResultContainer: resultContainer,
      });
    }
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,

        organisationId: source.organisation.id,
        //-- todo
        locationId:
          source.location !== undefined && source.location.id !== null
            ? source.location.id
            : "",
        locationValue:
          source.location !== undefined && source.location.name !== null
            ? source.location.name
            : "",
        value: source.customer.email
          ? source.customer.firstName +
            " " +
            source.customer.lastName +
            ", " +
            source.customer.email
          : "",
        customerId: source.customer.id !== undefined ? source.customer.id : "",

        amount: source.amount,
        expiryDate: new Date(source.expiry),
        description: source.description,
        voucherCode: source.voucher_code,
        voucherId: source.id,
        customerVoucher:
          source.voucher_code +
          (source.customer.customer_voucher_id !== undefined &&
          source.customer.customer_voucher_id !== null
            ? source.customer.customer_voucher_id
            : ""),
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit, value: "" });
    }

    if (!showModal) {
      this.clearForm();
    }
  };

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  formInputChangeHandler = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    voucherResponse: state.voucherResponse,
    customerResponse: state.customerResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getResourceListSuccessResponse: state.getResourceListSuccessResponse,
    genCustomerVoucherSuccessResponse: state.genCustomerVoucherSuccessResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getVouchers,
    clearVoucherResponse,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertVoucher,
    updateVoucher,
    deleteVoucher,
    showNotification,
    clearNotification,
    getCustomers,
    getLocationList,
    getResourceList,
    generateCustomerVoucher,
    assignVoucher,
    genModalCustomer,
    genModalVoucher,
  })(ManageVoucher)
);
