import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBIcon,
} from "mdbreact";
import { showHideLoader, toggleModal } from "../../../actions";
import { getMemberships } from "../../../actions/membershipAction";
import { getResourceList } from "../../../actions/resourceAction";
import { getLocationList } from "../../../actions/locationAction";

import {
  updateBusinessRuleTrigger,
  insertBusinessRuleTrigger,
  getBusinessruleTriggerTypeList,
} from "../../../actions/ruleEngineAction";
import _ from "lodash";

import { GEN_MODAL_RULE_TRIGGER } from "../../../actions/types";

class RuleTriggerModal extends React.Component {
  constructor(props) {
    super(props);

    this.multiselectRef = React.createRef();
  }

  state = {
    conditionList: [
      {
        text: "Is in",
        value: "is_in",
      },
      {
        text: "Not in",
        value: "not_in",
      },
      {
        text: "Between",
        value: "in_between",
      },
      {
        text: "Not in between",
        value: "not_in_between",
      },
    ],
    b_rule_id: 0,
    uuid: "",
    rule_trigger_id: 0,
    rule_trigger_description: "",
    rule_trigger_type: "",
    rule_trigger_condition: "",
    rule_trigger_values: "",
    rule_trigger_value_from: "",
    rule_trigger_value_to: "",
    ruleTriggerValuesArrayFormatted: [],

    membershipTypeList: [],
    locationOptionList: [],
    resourceLocationOptionList: [],
    daysOfTheWeekOptionList: [],

    betweenOptionList: [],
    multiSelectOptionList: [],
    multiSelectDefaultSelected: [],
    multiSelectUpdatedValues: [],
    organisation_location: "",
    ruleTriggerTypes: [],
  };

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  setFormValues(ruleTrigger, isEdit) {
    let formData = {
      rule_trigger_uuid: "",
      b_rule_id: ruleTrigger["business_rule_id"],
      rule_trigger_id: ruleTrigger["id"],
      rule_trigger_description: "",
      rule_trigger_type: "",
      rule_trigger_condition: "",
      rule_trigger_values: [],
      ruleTriggerValuesArrayFormatted: [],
      rule_trigger_value_from: "",
      rule_trigger_value_to: "",
    };

    if (isEdit) {
      formData["rule_trigger_uuid"] = ruleTrigger["uuid"];
      formData["rule_trigger_description"] = ruleTrigger["description"];
      formData["rule_trigger_type"] = ruleTrigger["trigger_type"];
      formData["rule_trigger_condition"] = ruleTrigger["trigger_condition"];

      switch (ruleTrigger["trigger_condition"]) {
        case "in_between":
        case "not_in_between":
          formData["rule_trigger_value_from"] =
            ruleTrigger["valueFrom"]["value"];
          formData["rule_trigger_value_to"] = ruleTrigger["valueTo"]["value"];
          break;

        case "not_in":
        case "is_in":
          formData["rule_trigger_values"] = ruleTrigger["values"];
          formData["ruleTriggerValuesArrayFormatted"] =
            ruleTrigger["formattedValueInList"];
          break;

        default:
          break;
      }

      //This is only for the location resource type for now
      if (
        ruleTrigger["metadata"] &&
        ruleTrigger["trigger_type"] == "location_resource"
      ) {
        const ruleTriggerMetadata = JSON.parse(ruleTrigger["metadata"]);
        const organisation_location_uuid =
          ruleTriggerMetadata["organisation_location_uuid"];
        this.setState({
          organisation_location: organisation_location_uuid,
        });

        this.props.showHideLoader(true);
        this.props.getResourceList(
          { locationUuid: organisation_location_uuid },
          this.props.authUser.sessionToken
        );
      }
    }

    return formData;
  }
  componentDidMount() {
    this.props.getMemberships({ limit: 50 }, this.props.authUser.sessionToken);
    this.props.getLocationList({}, this.props.authUser.sessionToken);
    this.props.getBusinessruleTriggerTypeList(
      {},
      this.props.authUser.sessionToken
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_RULE_TRIGGER) {
        let ruleTrigger = response.data.payload;
        let isEdit = false;
        if (response.data.show && response.data.action === "edit") {
          isEdit = true;
        }

        let formData = this.setFormValues(ruleTrigger, isEdit);
        this.setState({
          isEdit: isEdit,
          showModal: true,
          ...formData,
          wasValidatedRuleTriggers: "",
        });
      }
    }

    if (prevState.rule_trigger_type !== this.state.rule_trigger_type) {
      let multiSelectOptionList = [];
      switch (this.state.rule_trigger_type) {
        case "membership_type":
          multiSelectOptionList = this.state.membershipTypeList;
          break;
        case "organisation_location":
          multiSelectOptionList = this.state.locationOptionList;
          break;
        case "days_of_the_week":
          multiSelectOptionList = this.state.daysOfTheWeekOptionList;
          break;
        default:
          multiSelectOptionList = [];
      }
      const multiSelectedDefaultSelected = multiSelectOptionList.filter(
        (option) =>
          this.state.ruleTriggerValuesArrayFormatted.includes(
            String(option["value"])
          )
      );

      this.setState({
        multiSelectOptionList,
        multiSelectedDefaultSelected,
      });
    }

    if (prevState.organisation_location !== this.state.organisation_location) {
      this.props.showHideLoader(true);
      this.props.getResourceList(
        { locationUuid: this.state.organisation_location },
        this.props.authUser.sessionToken
      );
    }

    if (
      prevProps.getLocationListSuccessResponse !==
      this.props.getLocationListSuccessResponse
    ) {
      this.props.showHideLoader(false);
      let response = this.props.getLocationListSuccessResponse;

      if (response.data.locations.results) {
        const responseLocations = response.data.locations.results;
        const locationList = responseLocations.map((location) => {
          return {
            text: location["name"],
            value: location["id"],
            uuid: location["uuid"],
          };
        });
        this.setState({
          locationOptionList: locationList,
        });
      }
    }

    if (
      prevProps.getResourceListSuccessResponse !==
        this.props.getResourceListSuccessResponse &&
      this.props.getResourceListSuccessResponse.status === 200
    ) {
      this.props.showHideLoader(false);
      let response = this.props.getResourceListSuccessResponse;

      const resourceLocationOptionList = response.data.resources.results.map(
        (resource) => {
          return { text: resource["name"], value: resource["id"] };
        }
      );

      const multiSelectedDefaultSelected = resourceLocationOptionList.filter(
        (option) =>
          this.state.ruleTriggerValuesArrayFormatted.includes(
            String(option["value"])
          )
      );

      this.setState({
        multiSelectOptionList: resourceLocationOptionList,
        resourceLocationOptionList,
        multiSelectedDefaultSelected,
      });

      this.setState({
        resourceLocationOptionList,
      });
    }

    if (prevProps.membershipResponse !== this.props.membershipResponse) {
      let response = this.props.membershipResponse;
      this.props.showHideLoader(false);
      if (response.action != "ACTION" && response.status < 400) {
        const data = response.data;

        const membershipTypeList = data.results.map((membership) => {
          return {
            text:
              membership["id"] +
              "." +
              membership["name"] +
              " - " +
              membership?.type?.name,
            value: membership["id"],
          };
        });

        this.setState({
          membershipTypeList,
        });
      }
    }

    if (
      prevProps.ruleEngineTriggerTypesResponse !==
      this.props.ruleEngineTriggerTypesResponse
    ) {
      let response = this.props.ruleEngineTriggerTypesResponse;

      this.props.showHideLoader(false);
      if (
        this.props.ruleEngineTriggerTypesResponse.action === "LIST" &&
        this.props.ruleEngineTriggerTypesResponse.status === 200
      ) {
        let ruleTriggerTypes = response.data;

        let daysOfTheWeekOptionList = [];
        ruleTriggerTypes.map((ruleTriggerType) => {
          if (ruleTriggerType.shortname === "days_of_the_week") {
            daysOfTheWeekOptionList = ruleTriggerType.option_list;
          }
        });

        this.setState({
          ruleTriggerTypes: ruleTriggerTypes,
          daysOfTheWeekOptionList: daysOfTheWeekOptionList,
        });
      }
    }
  }

  showModalFunc = (showModal, source) => {
    let isEdit = true;
    if (source !== {}) {
      isEdit = false;
    }

    let formData = this.setFormValues(source, isEdit);
    this.setState({
      isEdit: isEdit,
      showModal: showModal,
      ...formData,
    });
  };

  getTriggerValues() {
    const { ruleTriggerValuesArrayFormatted, rule_trigger_values } = this.state;
    let selectedTriggerValues = this.multiselectRef.current.getSelectedItems();

    const arrayOfSelectedValues = selectedTriggerValues.map(
      (selectedValue) => String(selectedValue["value"]) // Convert to String
    );

    let ruleTriggersValues = [];
    const addedTriggersValues = _.differenceWith(
      arrayOfSelectedValues,
      ruleTriggerValuesArrayFormatted,
      _.isEqual
    );

    const removedTriggersValues = _.differenceWith(
      ruleTriggerValuesArrayFormatted,
      arrayOfSelectedValues,
      _.isEqual
    );

    removedTriggersValues.map((removedValue) => {
      const dataValue = rule_trigger_values.find(
        (ruleTriggerValue) => String(ruleTriggerValue["value"]) == removedValue
      );

      if (typeof dataValue !== "undefined") {
        ruleTriggersValues.push({
          value: removedValue,
          uuid: dataValue["uuid"],
          action: "removed",
        });
      }
    });

    addedTriggersValues.map((value) => {
      ruleTriggersValues.push({
        value: value,
        action: "added",
      });
    });

    return ruleTriggersValues;
  }

  submitHandler = (event) => {
    event.preventDefault();
    const {
      isEdit,
      rule_trigger_uuid,
      b_rule_id,
      rule_trigger_id,
      rule_trigger_description,
      rule_trigger_type,
      rule_trigger_condition,
      organisation_location,
      rule_trigger_value_from,
      rule_trigger_value_to,
    } = this.state;

    let updatedTriggerValues = [];

    if (["is_in", "not_in"].includes(rule_trigger_condition)) {
      updatedTriggerValues = this.getTriggerValues();
    }

    let body = {
      business_rule_id: b_rule_id,
      rule_trigger_id: rule_trigger_id,
      description: rule_trigger_description,
      trigger_type: rule_trigger_type,
      trigger_condition: rule_trigger_condition,
      trigger_values: updatedTriggerValues,
      trigger_value_from: rule_trigger_value_from,
      trigger_value_to: rule_trigger_value_to,
    };

    if (rule_trigger_type == "location_resource") {
      body["metadata"] = { organisation_location_uuid: organisation_location };
    }
    this.setState({ wasValidatedRuleTriggers: "was-validated" });

    if (event.target.checkValidity()) {
      var result = DevExtremeConfirm(
        "Are you sure you want to save changes for business rule triggers?"
      );

      result.then((dialogResult) => {
        if (dialogResult) {
          if (isEdit) {
            this.props.updateBusinessRuleTrigger(
              body,
              rule_trigger_uuid,
              this.props.authUser.sessionToken
            );
          } else {
            this.props.insertBusinessRuleTrigger(
              body,
              this.props.authUser.sessionToken
            );
          }
        } else {
          this.setState({ wasValidatedRuleTriggers: "" });
        }
      });
    }
  };

  renderIsInFields() {
    let renderedHTML = (
      <MDBCol md="" className="mb-3">
        <label htmlFor="ruleType" className="grey-text">
          Values
        </label>
        <Multiselect
          ref={this.multiselectRef}
          displayValue="text"
          customCloseIcon={
            <>
              <MDBIcon icon={"minus-circle"}> </MDBIcon>
            </>
          }
          onRemove={(selectedList) => {
            const selectedTriggerValues = selectedList.map(
              (selectedOption) => selectedOption["value"]
            );

            this.setState({
              multiSelectUpdatedValues: selectedTriggerValues,
            });
          }}
          onSelect={(selectedList) => {
            const selectedTriggerValues = selectedList.map(
              (selectedOption) => selectedOption["value"]
            );
            this.setState({
              multiSelectUpdatedValues: selectedTriggerValues,
            });
          }}
          options={this.state.multiSelectOptionList}
          selectedValues={this.state.multiSelectedDefaultSelected}
        />
      </MDBCol>
    );
    return renderedHTML;
  }

  renderInBetweenFields() {
    let renderedHTML = "";

    const {
      rule_trigger_type,
      rule_trigger_value_from,
      rule_trigger_value_to,
      daysOfTheWeekOptionList,
    } = this.state;

    switch (rule_trigger_type) {
      case "days_of_the_week":
        renderedHTML = (
          <>
            <MDBCol md="6">
              <label htmlFor="rule_trigger_value_from" className="grey-text">
                <span className="text-danger">*</span> From:
              </label>

              <select
                id="rule_trigger_value_from"
                name="rule_trigger_value_from"
                className="form-control"
                value={rule_trigger_value_from}
                onChange={(event) => {
                  this.setState({
                    rule_trigger_value_from: event.target.value,
                    rule_trigger_value_to: "",
                  });
                }}
                required
              >
                <option value="">--Select From --</option>
                {daysOfTheWeekOptionList.map((option) => {
                  return (
                    <option value={option["value"]}>{option["text"]}</option>
                  );
                })}
              </select>
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="rule_trigger_value_to" className="grey-text">
                <span className="text-danger">*</span> To:
              </label>

              <select
                id="rule_trigger_value_to"
                name="rule_trigger_value_to"
                className="form-control"
                value={rule_trigger_value_to}
                onChange={this.formDataHandler}
                required
              >
                <option value="">--Select To --</option>
                {daysOfTheWeekOptionList.map((option) => {
                  let isDisabled = false;

                  if (option["value"] == rule_trigger_value_from) {
                    isDisabled = true;
                  }

                  return (
                    <option disabled={isDisabled} value={option["value"]}>
                      {option["text"]}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
          </>
        );
        break;

      case "hours_in_military_time":
        renderedHTML = (
          <>
            <MDBCol md="6">
              <label htmlFor="rule_trigger_value_from" className="grey-text">
                <span className="text-danger">*</span> From:
              </label>

              <input
                id="rule_trigger_value_from"
                name="rule_trigger_value_from"
                value={rule_trigger_value_from}
                className="form-control"
                onChange={this.formDataHandler}
                required
                pattern="^([01]\d|2[0-3]):?([0-5]\d)$"
                type="text"
              />
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="rule_trigger_value_to" className="grey-text">
                <span className="text-danger">*</span> To:
              </label>
              <input
                id="rule_trigger_value_to"
                name="rule_trigger_value_to"
                value={rule_trigger_value_to}
                className="form-control"
                onChange={this.formDataHandler}
                pattern="^([01]\d|2[0-3]):?([0-5]\d)$"
                required
                type="text"
              />
            </MDBCol>
          </>
        );
        break;

      default:
        break;
    }

    return renderedHTML;
  }

  render() {
    const {
      rule_trigger_description,
      rule_trigger_type,
      rule_trigger_condition,
      showModal,
      isEdit,
      conditionList,
      organisation_location,
    } = this.state;
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={showModal}
          size="md"
          toggle={() => this.showModalFunc(false, {})}
        >
          <form
            className={`needs-validation ${this.state.wasValidatedRuleTriggers}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>
              {isEdit ? "Edit" : "Add"} Rule Trigger
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="displayName" className="grey-text">
                    <span className="text-danger">*</span> Description
                  </label>

                  <textarea
                    value={rule_trigger_description}
                    name="rule_trigger_description"
                    onChange={this.formDataHandler}
                    type="textarea"
                    id="rule_trigger_description"
                    className="form-control"
                    rows="3"
                    style={{ resize: "none" }}
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide description
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="rule_trigger_type" className="grey-text">
                    <span className="text-danger">*</span> Trigger type
                  </label>

                  <select
                    id="rule_trigger_type"
                    name="rule_trigger_type"
                    className="form-control"
                    value={rule_trigger_type}
                    onChange={(event) => {
                      this.setState({
                        rule_trigger_condition: "",
                        rule_trigger_type: event.target.value,
                      });
                    }}
                    disabled={isEdit}
                    required
                  >
                    <option value="">--Select Trigger Type--</option>
                    {this.state.ruleTriggerTypes.map((triggerType) => {
                      return (
                        <option
                          value={triggerType["shortname"]}
                          disabled={triggerType["shortname"] == ""}
                        >
                          {triggerType["display_name"]}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    Please select trigger type
                  </div>
                </MDBCol>
              </MDBRow>

              {this.state.rule_trigger_type == "location_resource" && (
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <label
                      htmlFor="organisation_location"
                      className="grey-text"
                    >
                      Organisation Location
                    </label>

                    <select
                      id="organisation_location"
                      name="organisation_location"
                      className="form-control"
                      value={organisation_location}
                      onChange={this.formDataHandler}
                      disabled={isEdit}
                      required
                    >
                      <option value="" disabled selected>
                        --Select a Location--
                      </option>
                      {this.state.locationOptionList.map((location) => {
                        return (
                          <option value={location["uuid"]}>
                            {location["text"]}
                          </option>
                        );
                      })}
                    </select>
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="rule_trigger_condition" className="grey-text">
                    <span className="text-danger">*</span>Condition
                  </label>

                  <select
                    id="rule_trigger_condition"
                    name="rule_trigger_condition"
                    className="form-control"
                    value={rule_trigger_condition}
                    onChange={this.formDataHandler}
                    required
                  >
                    <option value={""} disabled={true}>
                      --Select Condition--
                    </option>
                    {this.state.ruleTriggerTypes.map((triggerType) => {
                      if (rule_trigger_type === triggerType["shortname"]) {
                        let allowedOpperands = triggerType["allowed_opperands"];

                        return conditionList.map((condition) => {
                          if (allowedOpperands.includes(condition["value"]))
                            return (
                              <option
                                value={condition["value"]}
                                disabled={condition["value"] == ""}
                              >
                                {condition["text"]}
                              </option>
                            );
                        });
                      }
                    })}
                  </select>
                  <div className="invalid-feedback">
                    Please select trigger condition
                  </div>
                </MDBCol>
              </MDBRow>

              {rule_trigger_condition != "" && (
                <MDBRow>
                  {["in_between", "not_in_between"].includes(
                    rule_trigger_condition
                  ) && this.renderInBetweenFields()}

                  {["is_in", "not_in"].includes(rule_trigger_condition) &&
                    this.renderIsInFields()}
                </MDBRow>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModalFunc(false, {})}
              >
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {isEdit ? "Save" : "Add"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getResourceListSuccessResponse: state.getResourceListSuccessResponse,
    ruleEngineTriggerTypesResponse: state.ruleEngineTriggerTypesResponse,
    membershipResponse: state.membershipResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    toggleModal,
    insertBusinessRuleTrigger,
    updateBusinessRuleTrigger,
    getBusinessruleTriggerTypeList,
    getMemberships,
    getLocationList,
    getResourceList,
  })(RuleTriggerModal)
);
