import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { connect } from "react-redux";
import { validateLayout } from "../../../actions";
import { searchLocations } from "../../../actions/locationAction";
import VenueTile from "../shared/VenueTile";
import PageTitle from "../shared/PageTitle";

class Home extends React.Component {
  setLayout() {
    const layout = {
      showBanner: true,
      showFooter: true,
      showSearch: true,
      showHeader: true,
      showLogin: true,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();
    this.props.searchLocations("");
  }

  generateTile() {
    if (this.props.locations.results !== undefined) {
      return this.props.locations.results.map((location) => {
        if (location.is_active === 1) {
          return (
            <MDBCol className="mt-5 mb-4 custom-col" key={location.id}>
              <VenueTile
                title={location.name}
                about={location.attributes.about}
                location={location.attributes.full_address}
                contactNumber={location.attributes.contact_number}
                email={location.attributes.contact_email}
                website={location.attributes.website}
                uuid={location.uuid}
                imageUrl={location.attributes.image_url}
              />
            </MDBCol>
          );
        }
      });
    }
  }

  renderContent() {
    return (
      <div>
        <MDBContainer className="content-min-height mt-5">
          <PageTitle
            title="Our Venues"
            subTitle="Select available venues below and book with us!"
          />
          <div className="mt-3 mb-5">
            <MDBRow>{this.generateTile()}</MDBRow>
          </div>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout, locations: state.locations };
};

export default connect(mapStateToProps, { validateLayout, searchLocations })(
  Home
);
