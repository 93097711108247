import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { setAuthUser } from "../../../actions/authAction";

import PrivateLayout from "./PrivateLayout";
import PublicLayout from "./PublicLayout";

import { USER_COOKIES_KEY } from "../../../helper";
import Notification from "../shared/Notification";
import Spinner from "../shared/Loader";
import Modal from "../shared/Modal";

class Main extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.getUserCookies();
  }

  getUserCookies() {
    const { cookies } = this.props;
    let userCookies = cookies.get(USER_COOKIES_KEY);
    if (userCookies !== undefined) {
      this.props.setAuthUser(userCookies);
    }
  }

  renderAdmin() {
    if (this.props.authUser.isLoggedIn) {
      return <Route path="/admin" component={PrivateLayout} />;
    }
  }

  renderContent() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            {this.renderAdmin()}
            <Route path="/" component={PublicLayout} />
          </Switch>
        </BrowserRouter>
        <Notification />
        <Spinner />
        <Modal />
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { authUser: state.authUser };
};

export default withCookies(connect(mapStateToProps, { setAuthUser })(Main));
