import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
    GET_MEMBERSHIPS,
    MEMBERSHIP_RESPONSE_HANDLER,
} from "./types";

export const getMemberships = (params, sessionToken) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken
    };

    const response = await bookingAppPrivate
        .get(`/memberships`, {
            params: params,
            headers: headers,
        })
        .then((res) => {
            dispatch({
                type: GET_MEMBERSHIPS,
                payload: res,
            });
        })
        .catch((err) => {
            let errRes = {};
            errRes = "Network error";
            if (err.response !== undefined) {
                errRes = err.response;
            }
            dispatch({
                type: MEMBERSHIP_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};

export const insertMembership = (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .post(`/memberships`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: MEMBERSHIP_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: MEMBERSHIP_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};

export const updateMembership = (body, uuid, sessionToken) => async (
    dispatch
) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .put(`/memberships/${uuid}`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: MEMBERSHIP_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: MEMBERSHIP_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};

export const clearUpdateMembershipSuccessResponse = () => {
    return {
        type: MEMBERSHIP_RESPONSE_HANDLER,
        payload: {},
    };
};

export const clearUpdateMembershipFailedResponse = () => {
    return {
        type: MEMBERSHIP_RESPONSE_HANDLER,
        payload: {},
    };
};

export const deleteMembership = (uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .delete(`/memberships/${uuid}`, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: MEMBERSHIP_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: MEMBERSHIP_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};
