import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import Modal from "../shared/Modal";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import {
  getOrganisations,
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
} from "../../../actions/organisationAction";
import {
  showHideLoader,
  showNotification,
  toggleModal,
} from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs } from "../../../actions";
import _ from "lodash";
import moment from "moment";
import {
  getAttributes,
  updateAttribute,
} from "../../../actions/attributeAction";
import { validateAccess } from "../../../helper/utils";

class ManageOrganisation extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    loader: true,
    filterLoaded: false,
    type: "organisation",
    attrPage: 1,
    attrLimit: 10,
    attrQuery: "",
    organisationUuid: "",
    organisationId: "",
    showAttributeModal: false,
    resultContainer: {},
    locationAttributeKeysContainer: {},
    showConfigs: false,
  };

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Start Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  endDatePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select End Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 200);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  componentDidMount() {
    this.props.showHideLoader(true);

    const { query } = this.state;

    this.props.displayBreadcrumbs("Dashboard / Administrator / Organisation");

    let params = {};

    this.initLimitOptions();

    this.props.getOrganisations(params, this.props.authUser.sessionToken);
  }

  render() {
    let authUser = this.props.authUser;
    let canEdit =
      authUser !== undefined &&
      typeof authUser.organisationId !== "undefined" &&
      authUser.organisationId == 1;

    return (
      <div>

        {canEdit ? this.renderForm() : <span></span>}
        {this.renderTable()}
      </div>
    );
  }

  createTypeOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Log Type",
      value: "",
    });

    let response = this.props.organisationResponse;
    if (
      response.search_aggs !== undefined &&
      response.search_aggs.type !== undefined
    ) {
      let buckets = response.search_aggs.type.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  createUserOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select User",
      value: "",
    });
    let response = this.props.organisationResponse;
    if (
      response.search_aggs !== undefined &&
      response.search_aggs.user !== undefined
    ) {
      let buckets = response.search_aggs.user.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  clearForm() {
    this.setState({
      name: "",
      isActive: false,
      domain: "",
      wasValidated: "",
      isEdit: false,
      organisationUuid: "",
      organisationId: "",
    });
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        name: source.name,
        domain: source.domain,
        isActive: source.is_active === 1 ? true : false,
        organisationUuid: source.uuid,
        organisationId: source.id,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }

    if (!showModal) {
      this.clearForm();
    }
  };

  renderForm() {
    const { showModal, name, isActive, domain, isEdit } = this.state;
    let authUser = this.props.authUser;
    let canEdit =
      authUser !== undefined &&
      typeof authUser.organisationId !== "undefined" &&
      authUser.organisationId == 1;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Organisations</h3>
          </MDBCol>
          {canEdit ? (
            <MDBCol md="6">
              <MDBBtn
                color="primary"
                onClick={() => this.showModal(true, false, null)}
                className="float-right"
              >
                Add Organisation
              </MDBBtn>
            </MDBCol>
          ) : (
              <span></span>
            )}
        </MDBRow>
        {canEdit ? this.renderFilters() : <span></span>}
        {canEdit ? (
          <MDBModal isOpen={showModal} size="lg" toggle={() => this.showModal(false, false, null)}>
            <form
              className={`needs-validation ${this.state.wasValidated}`}
              onSubmit={this.submitHandler}
              noValidate
            >
              <MDBModalHeader>
                {isEdit ? "Edit" : "Add"} Organisation
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="name" className="grey-text">
                      Name
                    </label>
                    <input
                      value={name}
                      name="name"
                      onChange={this.formDataHandler}
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide the name
                    </div>
                  </MDBCol>
                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="domain" className="grey-text">
                      Domain
                    </label>
                    <input
                      value={domain}
                      name="domain"
                      onChange={this.formDataHandler}
                      type="text"
                      id="domain"
                      className="form-control"
                      placeholder="Domain"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide the domain
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        name="isActive"
                        type="checkbox"
                        className="custom-control-input"
                        id="checkActive"
                        defaultChecked={isActive}
                        onChange={this.onIsCheckChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkActive"
                        style={{
                          fontWeight: "bold",
                          marginTop: "35px !important",
                        }}
                      >
                        Active
                      </label>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.showModal(false, false, null)}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn color="primary" type="submit">
                  Save
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        ) : (
            <span></span>
          )}
      </div>
    );
  }

  confirmDelete = (organisation) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${organisation.name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({
      organisationId: organisation.id,
      organisationUuid: organisation.uuid,
    });
  };

  submitHandler = (event) => {
    const { name, domain, isActive, organisationUuid, isEdit } = this.state;
    event.preventDefault();
    this.setState({ wasValidated: "was-validated", formCleared: false });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      let body = {
        name: name,
        domain: domain,
        isActive: isActive,
      };

      let session = this.props.authUser.sessionToken;
      if (isEdit) {
        this.props.updateOrganisation(organisationUuid, body, session);
      } else {
        this.props.createOrganisation(body, session);
      }
    }
  };

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  doDelete() {
    const { organisationUuid } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteOrganisation(
      organisationUuid,
      this.props.authUser.sessionToken
    );
    this.closeModal();
  }

  renderFilters() {
    const { limitOptions, query } = this.state;
    return (
      <div>
        <MDBRow>
          <MDBCol md="3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="isActiveFilter"
                onChange={this.onIsActiveCheckChange}
              />
              <label
                className="custom-control-label"
                htmlFor="isActiveFilter"
                style={{
                  fontWeight: "bold",
                  marginTop: "35px !important",
                }}
              >
                Active
              </label>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search organisation"
                name="query"
                value={query}
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.organisationResponseFull !== this.props.organisationResponseFull
    ) {
      this.props.showHideLoader(false);
      let response = this.props.organisationResponse;
      let fullResponse = this.props.organisationResponseFull;
      if (fullResponse.action == "ACTION") {
        this.props.showNotification(
          fullResponse.notificationType,
          response.message
        );
        if (fullResponse.status >= 200 && fullResponse.status <= 300) {
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.doFilter();
        }
      } else {
        this.setState({
          ...this.state,
          resultContainer: response,
          page: response.page,
        });
      }

      if (!this.state.filterLoaded) {
        this.setState({
          filterLoaded: true,
          typeOptions: this.createTypeOptions(),
          userOptions: this.createUserOptions(),
        });
      }
    }

    if (
      prevProps.getAttrKeysSuccessResponse !==
      this.props.getAttrKeysSuccessResponse &&
      this.props.getAttrKeysSuccessResponse.status === 200 &&
      this.state.showAttributeModal
    ) {
      this.setState({
        locationAttributeKeysContainer: this.props.getAttrKeysSuccessResponse,
      });
    }
  }

  renderTable() {
    const { resultContainer, page } = this.state;
    let authUser = this.props.authUser;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        // {
        //     label: "ID",
        //     field: "heading0",
        //     sort: "asc",
        // },
        {
          label: "Name",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Domain",
          field: "heading2",
          sort: "asc",
        },
        {
          label: "Is Active",
          field: "heading3",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading4",
          sort: "asc",
        },
        {
          label: "",
          field: "heading5",
          sort: "asc",
        },
      ],
    };

    let canEdit =
      authUser !== undefined &&
      typeof authUser.organisationId !== "undefined" &&
      authUser.organisationId == 1;
    let rows = [];
    if (typeof resultContainer.results !== "undefined") {
      rows = _.chain(resultContainer.results)
        .map((result, index) => {
          let url = "/admin/manageorgsettings?sourceUuid=" + result.uuid;
          return {
            //heading0: result.id,
            heading1: result.name,
            heading2: result.domain,
            heading3: result.is_active,
            heading4: moment(result.created)
              .local()
              .format("DD/MM/YYYY HH:mm:ss"),
            header5: (
              <div>
                {canEdit ? (
                  <MDBBtn
                    onClick={() => this.confirmDelete(result)}
                    color="danger"
                    size="sm"
                    className="float-right"
                  >
                    Delete
                  </MDBBtn>
                ) : (
                    <span></span>
                  )}
                {validateAccess(
                  storeUserAccess,
                  "Organisations",
                  "viewSettings"
                ) && (
                    <MDBBtn
                      style={{
                        marginTop: ".375rem",
                        marginBottom: ".375rem",
                        marginRight: ".375rem",
                        marginLeft: ".375rem",
                      }}
                      href={url}
                      color="primary"
                      size="sm"
                      className="float-right"
                    >
                      View Settings
                    </MDBBtn>
                  )}
                {canEdit ? (
                  <MDBBtn
                    color="primary"
                    size="sm"
                    className="float-right"
                    onClick={() => this.showModal(true, true, result)}
                  >
                    Edit
                  </MDBBtn>
                ) : (
                    <span></span>
                  )}
              </div>
            ),

            //heading5: moment(result.updated).local().format("DD/MM/YYYY"),
          };
        })
        .value();
      data["rows"] = rows;
      let hasPage = typeof resultContainer.page == "undefined";

      return (
        <MDBContainer>
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
          {hasPage ? (
            <span></span>
          ) : (
              <MDBRow>
                <MDBCol md="12">
                  {this.renderTotalResult()}
                  <div className="float-right">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={resultContainer.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={4}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            )}
        </MDBContainer>
      );
    }
    return <div></div>;
  }

  showAttributeModal = (showAttributeModal, source) => {
    this.props.showHideLoader(true);
    const { type } = this.state;
    if (!showAttributeModal) {
      this.setState({
        showAttributeModal: showAttributeModal,
      });
      this.refreshAttr();
    } else {
      this.setState({
        showAttributeModal: showAttributeModal,
        organisationUuid: source.uuid,
        organisationName: source.name,
      });

      this.debouncedDoAttrFilter();
      let params = { limit: 10 };
      this.props.getAttributeKeys(
        type,
        params,
        this.props.authUser.sessionToken
      );
    }
  };

  debouncedDoAttrFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doAttrFilter();
  });

  doAttrFilter() {
    const {
      attrPage,
      attrLimit,
      attrQuery,
      organisationUuid,
      type,
    } = this.state;
    let params = {};

    if ((attrPage + "").length > 0) {
      params["page"] = attrPage;
    }
    if (attrLimit.length > 0) {
      params["limit"] = attrLimit;
    }
    if (attrQuery !== undefined) {
      params["q"] = attrQuery;
    }

    params["sourceUuid"] = organisationUuid;

    this.props.getAttributes(type, params, this.props.authUser.sessionToken);
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (typeof resultContainer.page !== "undefined") {
      return (
        <span>
          Showing {resultContainer.page} out {resultContainer.totalPage} pages
        </span>
      );
    }
    return <span></span>;
  }

  doFilter() {
    const {
      startDateFilter,
      endDateFilter,
      status,
      page,
      limit,
      query,
      type,
      user,
    } = this.state;

    this.props.showHideLoader(true);

    let params = {};
    if (startDateFilter) {
      params["startDate"] = moment(startDateFilter).utc().format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["endDate"] = moment(endDateFilter).utc().format("YYYY-MM-DD");
    }
    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (query !== undefined) {
      params["query"] = query;
    }

    this.props.getOrganisations(params, this.props.authUser.sessionToken);
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  onIsCheckChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    organisationResponse: state.organisationResponse.data,
    organisationResponseFull: state.organisationResponse,
    getAttrSuccessResponse: state.getAttrSuccessResponse,
    getAttrKeysSuccessResponse: state.getAttrKeysSuccessResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getOrganisations,
    showHideLoader,
    getAttributes,
    createOrganisation,
    updateOrganisation,
    deleteOrganisation,
    showNotification,
    toggleModal,
    updateAttribute,
  })(ManageOrganisation)
);
