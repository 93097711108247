import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Cookies } from "react-cookie";
import config from "../config";
import { connect } from "react-redux";

var cookies = new Cookies();

var client = reconnect();

function reconnect() {
  client = new W3CWebSocket(
    config.api.webSocket.baseUrl +
      "?x-client-fingerprint=" +
      cookies.get("fingerprint")
  );

  client.onopen = () => {
    client.isOpen = true;
    console.log("WebSocket Client Connected");
  };

  client.onclose = () => {
    client.isOpen = false;
    setTimeout(reconnect, 1000);
  };

  return client;
}

export default client;
