import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBDataTable,
} from "mdbreact";
import { connect } from "react-redux";
import SectionContainer from "../shared/sectionContainer";
import {
  displayBreadcrumbs,
  clearSuccessHandler,
  clearFailedHandler,
  alertMessage,
  showHideLoader,
} from "../../../actions";
import { createUser, getUsers } from "../../../actions/userAction";
import _ from "lodash";
import Loader from "../shared/Loader";
import moment from "moment";
import WeeklyCalendarV2 from "../shared/WeeklyCalendarV2";

class BookingPrivate extends React.Component {
  state = {
    name: "",
    email: "",
    loginId: "",
    domain: "",
    formCleared: false,
    wasValidated: "",
    showModal: false,
    isModalTrigger: false,
  };

  componentDidMount() {
    this.props.displayBreadcrumbs("Dashboard / Venues");
    this.props.getUsers({}, this.props.authUser.sessionToken);
  }

  renderContent() {
    return (
      <div>
        <MDBRow className="no-margin-left no-margin-right">
          <MDBCol size="12">
            <WeeklyCalendarV2 private={true} />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    authUser: state.authUser,
    successHandlerResponse: state.successHandlerResponse,
    failedHandlerResponse: state.failedHandlerResponse,
    authUser: state.authUser,
    alert: state.alert,
    users: state.users,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    createUser,
    clearSuccessHandler,
    clearFailedHandler,
    alertMessage,
    showHideLoader,
    getUsers,
  })(BookingPrivate)
);
