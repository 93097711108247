import React, { useState, useEffect } from "react";

const BookingRequestCountDownTimerComponent = ({
  expirationDateTime,
  isBookingOwner,
  isAdmin,
  bookingRequestObj,
}) => {
  //Count down timer
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [isExpired, setIsExpired] = useState(false);
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    setRemainingTimer(expirationDateTime);

    if (bookingRequestObj) {
      const bookingDataObj = bookingRequestObj.bookingDataObj;
      if (bookingDataObj) {
        const customerData = bookingDataObj.customerData;
        setCustomerName(customerData.first_name + " " + customerData.last_name);
      }
    }
  }, [0]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      } else if (seconds === 0 && minutes > 0) {
        setSeconds(59);
        setMinutes((minutes) => minutes - 1);
      } else if (minutes === 0 && hours > 0) {
        setSeconds(59);
        setMinutes(59);
        setHours((hours) => hours - 1);
      } else if (hours === 0 && days > 0) {
        setSeconds(59);
        setMinutes(59);
        setHours(23);
        setDays((days) => days - 1);
      } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        // Expired booking request
        clearInterval(interval);
        setIsExpired(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes, days, hours]);

  const setRemainingTimer = (expirationDateTime) => {
    const secs = expirationDateTime.seconds ? expirationDateTime.seconds : 0;
    const remainingDays = expirationDateTime.days ? expirationDateTime.days : 0;
    //
    let remainingHours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let remainingMinutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let remainingSeconds = Math.ceil(divisor_for_seconds);

    setHours(remainingHours);
    setMinutes(remainingMinutes);
    setSeconds(remainingSeconds);
    setDays(remainingDays);
  };

  const trimName = (customerName) => {
    let name = customerName;
    if (name.length > 10) {
      name = customerName.substring(0, 10) + "...";
    }

    return name;
  };

  return (
    <>
      {!isBookingOwner && !isAdmin ? (
        ""
      ) : isExpired ? (
        <>Cancelling...</>
      ) : (
        <>
          {trimName(customerName)}
          <br />
          {String(days).padStart(2, "0")}:{String(hours).padStart(2, "0")}:
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </>
      )}
      {}
    </>
  );
};

export default BookingRequestCountDownTimerComponent;
