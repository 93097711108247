// import _ from "lodash";

import axios from "axios";

import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  SEARCH_LOCATIONS,
  LOCATION_RESOURCES,
  INSERT_LOCATION_SUCCESS_HANDLER,
  INSERT_LOCATION_FAILED_HANDLER,
  UPDATE_LOCATION_SUCCESS_HANDLER,
  UPDATE_LOCATION_FAILED_HANDLER,
  GET_LOCATION_LIST_SUCCESS_HANDLER,
  GET_LOCATION_LIST_FAILED_HANDLER,
  GET_LOCATION_MENU_SUCCESS_HANDLER,
  GET_LOCATION_MENU_FAILED_HANDLER,
  DELETE_LOCATION_SUCCESS_HANDLER,
  DELETE_LOCATION_FAILED_HANDLER,
} from "./types";

const CancelToken = axios.CancelToken;
let cancel;

export const searchLocations = (query) => async (dispatch) => {
  const response = await bookingAppPrivate.get(`/search/locations`, {
    params: {
      q: query,
    },
  });

  dispatch({ type: SEARCH_LOCATIONS, payload: response.data });
};

export const getLocationResources = (uuid, date, version = "1") => async (
  dispatch
) => {
  // const response = await bookingAppPrivate.get(`/search/locations/${uuid}/resources`, {
  //   params: {
  //     date: date,
  //   },
  //   cancelToken: new CancelToken(function executor(c) {
  //     cancel = c;
  //     console.log("cancelling?");
  //   }),
  // });

  // dispatch({ type: LOCATION_RESOURCES, payload: response.data });
  cancel && cancel();
  const response = await bookingAppPrivate
    .get(`/search/locations/${uuid}/resources`, {
      params: {
        date: date,
        version: version,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
    .then((res) => {
      dispatch({ type: LOCATION_RESOURCES, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const searchLocationsPrivate = (query, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };

  const response = await bookingAppPrivate.get(`/locations`, {
    params: {
      q: query,
    },
    headers: headers,
  });

  dispatch({ type: SEARCH_LOCATIONS, payload: response.data });
};

export const getLocationResourcesPrivate = (
  uuid,
  params,
  sessionToken
) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  // const response = await bookingAppPrivate.get(`/locations/${uuid}/resources`, {
  //   params: {
  //     date: date,
  //   },
  //   headers: headers,
  // });

  // dispatch({ type: LOCATION_RESOURCES, payload: response.data });

  cancel && cancel();
  const response = await bookingAppPrivate
    .get(`/locations/${uuid}/resources`, {
      params: params,
      headers: headers,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
    .then((res) => {
      dispatch({ type: LOCATION_RESOURCES, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertLocation = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/locations`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: INSERT_LOCATION_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: INSERT_LOCATION_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearInsertLocationSuccessResponse = () => {
  return {
    type: INSERT_LOCATION_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearInsertLocationFailedResponse = () => {
  return {
    type: INSERT_LOCATION_FAILED_HANDLER,
    payload: {},
  };
};

export const updateLocation = (body, uuid, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/locations/${uuid}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: UPDATE_LOCATION_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: UPDATE_LOCATION_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearUpdateLocationSuccessResponse = () => {
  return {
    type: UPDATE_LOCATION_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearUpdateLocationFailedResponse = () => {
  return {
    type: UPDATE_LOCATION_FAILED_HANDLER,
    payload: {},
  };
};

export const getLocationMenu = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/locations`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_LOCATION_MENU_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_LOCATION_MENU_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getLocationList = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/locations`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_LOCATION_LIST_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_LOCATION_LIST_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearGetLocationListSuccessResponse = () => {
  return {
    type: GET_LOCATION_LIST_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearGetLocationListFailedResponse = () => {
  return {
    type: GET_LOCATION_LIST_FAILED_HANDLER,
    payload: {},
  };
};

export const deleteLocation = (uuid, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .delete(`/locations/${uuid}`, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: DELETE_LOCATION_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: DELETE_LOCATION_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearDeleteLocationSuccessResponse = () => {
  return {
    type: DELETE_LOCATION_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearDeleteLocationFailedResponse = () => {
  return {
    type: DELETE_LOCATION_FAILED_HANDLER,
    payload: {},
  };
};
