const config = {
  api: {
    private: {
      baseUrl: process.env.REACT_APP_API_V1_URL,
      headers: { apikey: process.env.REACT_APP_API_V1_KEY },
    },
    webSocket: {
      baseUrl: process.env.REACT_APP_WEBSOCKET_URL,
    },
    v2: {
      baseUrl: process.env.REACT_APP_API_V2_URL,
      headers: { apikey: process.env.REACT_APP_API_V2_KEY },
    },
  },
  hotjar: {
    hjid: process.env.REACT_APP_HOT_JAR_HJID,
    hjsv: process.env.REACT_APP_HOT_JAR_HJSV,
  },
};

export default {
  ...config,
};
