import React from "react";
import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getBookingReports } from "../../../actions/bookingAction";
import { showHideLoader } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs } from "../../../actions";
import _ from "lodash";
import moment from "moment";
import ReactExport from "react-data-export";
import { extractTimezone } from "../../../helper/utils";

class BookingReport extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: "",
    endDateFilter: "",
    limitOptions: [],
    limit: "10",
    status: "",
    page: "1",
    tableBookingReports: [],
    excelBookingReports: [],
    isExportAllData: 0, //False will export curent page, True will export all data
    exportDataSelectOptions: [],
  };

  constructor(props) {
    super(props);
    this.state.startDateFilter = new Date(
      moment().startOf("month").format("YYYY-MM-DD")
    );
    this.state.endDateFilter = new Date(
      moment().endOf("month").format("YYYY-MM-DD")
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  initExportTypeOption() {
    let options = [
      {
        checked: true,
        disabled: false,
        text: "Current page",
        value: 0,
      },
      {
        checked: false,
        disabled: false,
        text: "All records",
        value: 1,
      },
    ];

    this.setState({ exportDataSelectOptions: options });
  }

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  getTzDetails(dt = new Date()) {
    return extractTimezone("Australia/Sydney", dt);
  }

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  componentDidMount() {
    this.props.showHideLoader(true);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Bookings"
    );

    this.initLimitOptions();
  }

  renderExcelDataExport() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const { excelBookingReports } = this.state;
    const excelDataSet = this.prepareExcelDataSet(excelBookingReports);
    return (
      <ExcelFile
        element={
          <MDBBtn color="primary" type="button" size="md">
            Export data
          </MDBBtn>
        }
        filename="Booking reports"
      >
        <ExcelSheet dataSet={excelDataSet} name="Booking reports" />
      </ExcelFile>
    );
  }

  prepareExcelDataSet = (excelBookingReports) => {
    let excelDataSet = [
      {
        columns: [
          {
            title: "Customer Name",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 150 },
          },

          {
            title: "Customer Email",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 170 },
          },
          {
            title: "Mobile",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 170 },
          },
          {
            title: "Location",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 250 },
          },
          {
            title: "Court/Field",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 170 },
          },
          {
            title: "Reference Number",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },
          {
            title: "Status",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },

          {
            title: "Booking Date",
            style: {
              font: { sz: "12", bold: true },
              width: { wpx: 220 },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 150 },
          },
          {
            title: "Duration",
            style: {
              font: { sz: "12", bold: true },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 120 },
          },

          {
            title: "Is Paid",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },
          {
            title: "Payment Method",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },

          {
            title: "Payment Date",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },
          {
            title: "Amount",
            style: {
              font: { sz: "12", bold: true },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 100 },
          },
        ],
        data: excelBookingReports.map((booking) => {
          return [
            {
              value: booking.customer_full_name,
              style: { font: { sz: "12" } },
            },
            { value: booking.customer_email, style: { font: { sz: "12" } } },
            { value: booking.location_name, style: { font: { sz: "12" } } },
            { value: booking.court_field, style: { font: { sz: "12" } } },
            { value: booking.booking_reference, style: { font: { sz: "12" } } },
            { value: booking.status, style: { font: { sz: "12" } } },
            { value: booking.booking_date, style: { font: { sz: "12" } } },
            { value: booking.duration, style: { font: { sz: "12" } } },
            { value: booking.is_paid, style: { font: { sz: "12" } } },
            { value: booking.payment_method, style: { font: { sz: "12" } } },
            { value: booking.payment_date, style: { font: { sz: "12" } } },
            {
              value: "$" + booking.amount.toFixed(2),
              style: { font: { sz: "12" } },
            },
          ];
        }),
      },
    ];

    return excelDataSet;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getBookingReportResponse !==
        this.props.getBookingReportResponse &&
      this.props.getBookingReportResponse.action === "GET_BOOKINGS_REPORT"
    ) {
      const responseData = this.props.getBookingReportResponse.data;

      if (responseData.data) {
        const bookingReports = responseData.data.bookings.results;

        const formattedBookings = bookingReports.map((booking) => {
          let is_paid = "No";
          let payment_method = "";
          let payment_date = "";

          if (booking.transaction) {
            let payments = booking.transaction.payments;

            if (payments) {
              // #Get the payment method if its multi payment method or one payment method only
              if (payments.length > 1) {
                payment_method = "Multi";
              } else if (payments.length == 1) {
                payment_method = payments[0].method
                  ? payments[0].method.vendor
                  : "";
              }

              if (booking.transaction.ispaid == 1) {
                is_paid = "Yes";

                // Get the last payment date
                payments.forEach((value, key) => {
                  if (value.paymentDate != "") {
                    payment_date = moment(value.paymentDate)
                      .tz(this.getTzDetails().timezone)
                      .format("DD/MM/YY HH:mm:ss");
                  }
                });
              }
            }
          }

          let booking_date = moment(booking.start_time)
            .tz(this.getTzDetails().timezone)
            .format("DD/MM/YY");

          let duration =
            moment(booking.start_time)
              .tz(this.getTzDetails().timezone)
              .format("HH:mm") +
            "-" +
            moment(booking.end_time)
              .tz(this.getTzDetails().timezone)
              .format("HH:mm");

          let customer_email = "";
          let customer_full_name = "";
          let customer_mobile = "";

          // This is to avoid null error on excel for customer details
          if (booking.customer) {
            if (booking.customer.fullname) {
              customer_full_name = booking.customer.fullname;
            }

            if (booking.customer.email) {
              customer_email = booking.customer.email;
            }

            if (booking.customer.mobile) {
              customer_mobile = booking.customer.mobile;
            }
          }

          return {
            customer_full_name: customer_full_name,
            customer_email: customer_email,
            customer_mobile: customer_mobile,
            booking_reference: booking.booking_reference,
            booking_date: booking_date,
            duration: duration,
            amount: booking.transaction ? booking.transaction.totalAmount : 0,

            location_name: booking.location ? booking.location.name : "",
            court_field: booking.resource ? booking.resource.name : "",

            status: booking.status ? booking.status : "",

            is_paid: is_paid,
            payment_method: payment_method,
            payment_date: payment_date,
          };
        });

        let statesToUpdate = {
          excelBookingReports: formattedBookings,
        };

        if (!responseData.data.isExportExcelRequest) {
          // Update only  if not excel export request type
          statesToUpdate = {
            ...statesToUpdate,
            tableBookingReports: formattedBookings,
            resultContainer: {
              totalPage: responseData.data.bookings.totalPage,
              returnedRangedFrom: responseData.data.bookings.returnedRangedFrom,
              returnedRangedTo: responseData.data.bookings.returnedRangedTo,
              returnedTotal: responseData.data.bookings.returnedTotal,
              total: responseData.data.bookings.total,
            },
            page: responseData.data.bookings.page,
          };
        }

        this.setState(statesToUpdate);
      }

      this.props.showHideLoader(false);
    }
  }

  renderBookingReports(tableBookingReports) {
    let rows = _.chain(tableBookingReports)
      .map((result, index) => {
        let data = {};

        data = {
          ...data,
          heading_customer_name: result.customer_full_name,
          heading_customer_email: result.customer_email,
          heading_mobile: result.customer_mobile,
          heading_location: result.location_name,
          heading_court: result.court_field,
          heading_booking_reference: result.booking_reference,
          heading_status: result.status,
          heading_booking_date: result.booking_date,
          heading_duration: result.duration,
          heading_is_paid: result.is_paid,

          heading_payment_method: result.payment_method,
          heading_payment_date: result.payment_date,
          heading_amount: "$" + result.amount.toFixed(2),
        };

        return data;
      })
      .value();
    return rows;
  }

  renderBookingReportsColumns() {
    let columns = [];

    columns = [
      ...columns,
      {
        label: "Customer Name",
        field: "heading_customer_name",
        sort: "asc",
      },
      {
        label: "Customer Email",
        field: "heading_customer_email",
        sort: "asc",
      },
      {
        label: "Mobile",
        field: "heading_mobile",
        sort: "asc",
      },
      {
        label: "Location",
        field: "heading_location",
        sort: "asc",
      },
      {
        label: "Court/Field",
        field: "heading_court",
        sort: "asc",
      },
      {
        label: "Reference Number",
        field: "heading_booking_reference",
        sort: "asc",
      },
      {
        label: "Status",
        field: "heading_status",
        sort: "asc",
      },
      {
        label: "Booking Date",
        field: "heading_booking_date",
        sort: "asc",
      },
      {
        label: "Duration",
        field: "heading_duration",
        sort: "asc",
      },
      {
        label: "Is Paid",
        field: "heading_is_paid",
        sort: "asc",
      },
      {
        label: "Payment Method",
        field: "heading_payment_method",
        sort: "asc",
      },

      {
        label: "Payment Date",
        field: "heading_payment_date",
        sort: "asc",
      },

      {
        label: "Amount",
        field: "heading_amount",
        sort: "asc",
      },
    ];

    return columns;
  }

  getValueOfSelectLimit = (value) => {
    value = value.length > 0 ? value[0] : "";
    this.setState({ limit: value, page: 1, isExportAllData: 0 });
    this.initExportTypeOption();
    if (value != "") {
      this.debouncedDoFilter();
    }
  };

  getValueOfSelectExportType = (value) => {
    value = value.length > 0 ? value[0] : false;

    if (value === 1) {
      this.debouncedDoFilter();
    }

    this.setState({ isExportAllData: value });
  };

  doFilter() {
    const { startDateFilter, endDateFilter, page, limit, isExportAllData } =
      this.state;
    const { customerId } = this.props;

    this.props.showHideLoader(true);

    let params = {};

    if (startDateFilter) {
      params["start_date"] = moment(startDateFilter).format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["end_date"] = moment(endDateFilter).format("YYYY-MM-DD");
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (customerId && customerId > 0) {
      params["customerId"] = parseInt(customerId);
    }

    params["isExportAllData"] = isExportAllData;

    return this.props.getBookingReports(
      params,
      this.props.authUser.sessionToken,
      false
    );
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  render() {
    const { tableBookingReports, limitOptions, resultContainer } = this.state;

    const data = {
      columns: this.renderBookingReportsColumns(),
    };

    data.rows = this.renderBookingReports(tableBookingReports);

    return (
      <MDBContainer>
        <div>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <h3 className="mt-3">Booking Report</h3>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="3">
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select Start Date"
                  selected={this.state.startDateFilter}
                  maxDate={this.state.endDateFilter}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.startDateFilterOnChange}
                />
              </span>
            </MDBCol>
            <MDBCol md="3">
              {" "}
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select End Date"
                  selected={this.state.endDateFilter}
                  minDate={this.state.startDateFilter}
                  onChange={this.endDateFilterOnChange}
                  dateFormat="dd/MM/yyyy"
                />
              </span>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="2">
              <MDBSelect
                outline
                color="primary"
                getValue={this.getValueOfSelectLimit}
                value={this.state.limit}
                options={limitOptions}
                label="Show entries"
              />
            </MDBCol>

            <>
              <MDBCol
                md="8"
                className="d-flex align-items-center  justify-content-end"
              >
                {this.renderExcelDataExport()}
              </MDBCol>
              <MDBCol md="2">
                <MDBSelect
                  outline
                  color="primary"
                  getValue={this.getValueOfSelectExportType}
                  value={this.state.isExportAllData}
                  options={this.state.exportDataSelectOptions}
                  label="Export type"
                />
              </MDBCol>
            </>
          </MDBRow>
        </div>
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            <br></br>
            {resultContainer && (
              <>
                <span>
                  Showing {resultContainer.returnedRangedFrom} -{" "}
                  {resultContainer.returnedRangedTo} of {resultContainer.total}{" "}
                  item(s)
                </span>
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={resultContainer.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getBookingReportResponse: state.bookingResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getBookingReports,
    showHideLoader,
  })(BookingReport)
);
