import React from "react";
import ReactDOM from "react-dom";

class Main extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  renderContent() {
    return (
      <div>
        <h1>Admin Testing!</h1>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default Main;
