import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_RESOURCE_LIST_SUCCESS_HANDLER,
  GET_RESOURCE_LIST_FAILED_HANDLER,
  INSERT_RESOURCE_SUCCESS_HANDLER,
  INSERT_RESOURCE_FAILED_HANDLER,
  UPDATE_RESOURCE_SUCCESS_HANDLER,
  UPDATE_RESOURCE_FAILED_HANDLER,
  DELETE_RESOURCE_SUCCESS_HANDLER,
  DELETE_RESOURCE_FAILED_HANDLER,
  GET_RESOURCE,
  LOCK_RESOURCE_BOOKING_TIME_SLOT,
} from "./types";

export const getResourceList = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/resources`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_RESOURCE_LIST_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_RESOURCE_LIST_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getResource =
  (resourceUuid, sessionToken, isDispatch = true) =>
  async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    var response = bookingAppPrivate
      .get(`/resources/${resourceUuid}`, {
        headers: headers,
      })
      .then((res) => {
        if (isDispatch) {
          dispatch({ type: GET_RESOURCE, payload: res });
        }
        return res;
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        if (isDispatch) {
          dispatch({
            type: GET_RESOURCE_LIST_FAILED_HANDLER,
            payload: errRes,
          });
        }
        return errRes;
      });
    return response;
  };

export const clearGetResourceListSuccessResponse = () => {
  return {
    type: GET_RESOURCE_LIST_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearGetResourceListFailedResponse = () => {
  return {
    type: GET_RESOURCE_LIST_FAILED_HANDLER,
    payload: {},
  };
};

export const insertResource = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/resources`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: INSERT_RESOURCE_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: INSERT_RESOURCE_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearInsertResourceSuccessResponse = () => {
  return {
    type: INSERT_RESOURCE_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearInsertResourceFailedResponse = () => {
  return {
    type: INSERT_RESOURCE_FAILED_HANDLER,
    payload: {},
  };
};

export const updateResource = (body, id, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/resources/${id}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: UPDATE_RESOURCE_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: UPDATE_RESOURCE_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearUpdateResourceSuccessResponse = () => {
  return {
    type: UPDATE_RESOURCE_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearUpdateResourceFailedResponse = () => {
  return {
    type: UPDATE_RESOURCE_FAILED_HANDLER,
    payload: {},
  };
};

export const deleteResource = (id, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .delete(`/resources/${id}`, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: DELETE_RESOURCE_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: DELETE_RESOURCE_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const lockBookingTimeslot = (body, session) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };

  if (session != undefined && session != "") {
    headers["x-session-token"] = session;
  }
  const response = bookingAppPrivate
    .post(`/resources/locktimeslot`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: LOCK_RESOURCE_BOOKING_TIME_SLOT, payload: res });
      return res;
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: LOCK_RESOURCE_BOOKING_TIME_SLOT,
        payload: errRes,
      });
    });
  return await response;
};

export const clearDeleteResourceSuccessResponse = () => {
  return {
    type: DELETE_RESOURCE_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearDeleteResourceFailedResponse = () => {
  return {
    type: DELETE_RESOURCE_FAILED_HANDLER,
    payload: {},
  };
};
