import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
    SUBMIT_CONTACT_MESSAGE_HANDLER,
    SUBMIT_CONTACT_MESSAGE_FAILED_HANDLER
} from "./types";

export const submitMessage = (name, email, subject, message) => async (dispatch) => {
    var data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
    };

    const headers = {
        "Content-Type": "application/json",
    };
    bookingAppPrivate
        .post(`/contact-us`, data, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: SUBMIT_CONTACT_MESSAGE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: SUBMIT_CONTACT_MESSAGE_FAILED_HANDLER,
                payload: {
                    'data': { 'message': errRes },
                    'status': 400
                },
            });
        });
};