import React from "react";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBSwitch,
  MDBInput,
} from "mdbreact";
import _ from "lodash";

import moment, { utc } from "moment";

import ReactPaginate from "react-paginate";
import { extractTimezone } from "../../../helper/utils";

import { GET_ROLE_ACCESS } from "../../../actions/types";
import { getRoleAccess, switchAccess } from "../../../actions/roleAction";
import {
  showHideLoader,
  showNotification,
  clearNotification,
} from "../../../actions";

class RoleAccess extends React.Component {
  state = {
    collapseID: "",
    switchContainer: [],
    resultContainer: {},
    switch1: false,
    accessDetailsSwtichContainer: [],
  };

  componentDidMount() {
    this.props.getRoleAccess(
      { roleId: this.props.roleId },
      this.props.authUser.sessionToken
    );
  }

  componentWillUnmount() { }

  handleSwitchChange = (nr) => () => {
    let switchNumber = `switch${nr}`;
    this.setState({
      [switchNumber]: !this.state[switchNumber],
    });
  };

  mobileToggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  onACheckChange = (id) => () => {
    let { accessDetailsSwtichContainer } = { ...this.state };

    let index = _.findIndex(accessDetailsSwtichContainer, function (o) {
      return o.rcdId === id;
    });

    let enable =
      accessDetailsSwtichContainer[index].enable === true ? false : true;

    accessDetailsSwtichContainer[index].enable = enable;
    this.setState({
      accessDetailsSwtichContainer: accessDetailsSwtichContainer,
    });
    this.doSwitchAccess("roleAccessDetails", id, enable);
  };

  buildAccessList(roleAccessDetails) {
    let elements = [];
    _.map(roleAccessDetails, (elem, index) => {
      elements.push(
        <MDBCol md="4" key={index}>
          <MDBInput
            label={elem.name}
            // valueDefault={elem.enable === 1 ? true : false}
            // checked={elem.enable === 1 ? true : false}
            onChange={this.onACheckChange(elem.rcdId)}
            type="checkbox"
            id={"cId" + elem.rcdId}
            defaultChecked={elem.enable === 1 ? true : false}
          />
        </MDBCol>
      );
    });

    return (
      <div>
        <MDBRow>{elements}</MDBRow>
      </div>
    );
  }

  handleSwitch = (index, id) => {
    let { switchContainer } = { ...this.state };
    switchContainer[index]["enable"] = !switchContainer[index]["enable"];
    this.setState({ switchContainer });
    this.doSwitchAccess("roleAccess", id, switchContainer[index]["enable"]);
  };

  doSwitchAccess(type, id, enable) {
    let body = {
      id: id,
      type: type,
      enable: enable,
    };
    this.props.switchAccess(body, this.props.authUser.sessionToken);
  }

  mobileResourceAcordtion() {
    const { collapseID, resultContainer, switchContainer } = this.state;
    let elements = [];
    _.map(resultContainer.roleAccess, (elem, index) => {
      console.log("ELLMMMMMMMMMMMMM");
      console.log(elem);
      let elemId = index + 1;
      elements.push(
        <MDBCard className="my-1" key={"cid-" + elemId}>
          <MDBCollapseHeader className="grey lighten-3">
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <td style={{ width: "100%" }}>
                    <strong>{elem.description}</strong>
                  </td>
                  <td style={{ display: "inline-flex" }}>
                    <MDBSwitch
                      checked={switchContainer[index].enable}
                      checked={switchContainer[index].enable}
                      onChange={() => this.handleSwitch(index, elem.raId)}
                      labelLeft={""}
                      labelRight=""
                    />
                    {elem.roleAccessDetails.length > 0 && (
                      <MDBIcon
                        icon={
                          collapseID === "collapse" + elemId
                            ? "angle-up"
                            : "angle-down"
                        }
                        className=""
                        style={{ float: "right", padding: "5px 5px 5px 5px" }}
                        onClick={this.mobileToggleCollapse("collapse" + elemId)}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </MDBCollapseHeader>
          <MDBCollapse id={"collapse" + elemId} isOpen={collapseID}>
            <div className="p-3">
              {this.buildAccessList(elem.roleAccessDetails)}
            </div>
          </MDBCollapse>
        </MDBCard>
      );
    });

    return <div>{elements}</div>;
  }

  renderContent() {
    const { results, showLoading } = this.state;

    return <div className="">{this.mobileResourceAcordtion()}</div>;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.roleResponse !== this.props.roleResponse) {
      let response = this.props.roleResponse;
      this.props.showHideLoader(false);
      if (
        response.action === "ACTION" &&
        response.type === "SWITCH_ROLE_ACCESS_RESPONSE_HANDLER"
      ) {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        // if (response.status >= 200 && response.status <= 300) {
        //   this.setState({
        //     showModal: false,
        //     isModalTrigger: false,
        //   });
        //   this.debouncedDoFilterWithDelay();
        // }
      } else if (response.type == "GET_ROLE_ACCESS") {
        let switchContainer = _.chain(
          this.props.roleResponse.data.result.roleAccess
        )
          .map((roleAccess) => {
            return {
              enable: roleAccess.enable === 1 ? true : false,
              raId: roleAccess.raId,
            };
          })
          .value();

        let accessDetailsSwtichContainer = [];

        _.map(this.props.roleResponse.data.result.roleAccess, (roleAccess) => {
          _.map(roleAccess.roleAccessDetails, (details) => {
            accessDetailsSwtichContainer.push({
              rcdId: details.rcdId,
              enable: details.enable === 1 ? true : false,
            });
          });
        });

        this.setState({
          resultContainer: this.props.roleResponse.data.result,
          switchContainer: switchContainer,
          accessDetailsSwtichContainer: accessDetailsSwtichContainer,
        });
      }
    }
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    roleResponse: state.roleResponse,
    authUser: state.authUser,
  };
};

export default connect(mapStateToProps, {
  getRoleAccess,
  switchAccess,
  showHideLoader,
  showNotification,
  clearNotification,
})(RoleAccess);
