import React from "react";

import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import {
  displayBreadcrumbs,
  showHideLoader,
  showNotification,
} from "../../../../actions";

import { getPricingTriggers } from "../../../../actions/pricingAction";

import PricingTriggerModal from "./PricingTriggerModal";

class PricingTrigger extends React.Component {
  state = {
    pricingTriggers: [],
    selectedPricingTrigger: null,
    isShowPricingTriggerModal: false,
    isEditPricingTriggerModal: false,
  };

  componentDidMount() {
    this.props.displayBreadcrumbs("Dashboard / Admin / Pricing Triggers");
    this.props.showHideLoader(true);
    this.props.getPricingTriggers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pricingResponse !== this.props.pricingResponse) {
      let response = this.props.pricingResponse;

      this.props.showHideLoader(false);
      if (
        response.action == "LIST" &&
        response.type == "GET_PRICING_TRIGGERS"
      ) {
        if (response.status >= 200 && response.status <= 299) {
          this.setState({
            pricingTriggers: response.data.records,
          });
        }
      }
    }
  }

  showPricingTriggerModal = (
    isShow = false,
    isEdit = false,
    selectedPricingTrigger = null
  ) => {
    this.setState({
      isShowPricingTriggerModal: isShow,
      isEditPricingTriggerModal: isEdit,
      selectedPricingTrigger: selectedPricingTrigger,
    });
  };

  renderTriggerValues(trigger) {
    let html = "";
    if (["in_between", "not_in_between"].includes(trigger.trigger_condition)) {
      switch (trigger.trigger_type) {
        case "hours_in_military_time":
          html = (
            <>
              {trigger.values_in_range && (
                <>
                  <ul>
                    <li>From: {trigger.values_in_range.from.value}</li>
                    <li>To: {trigger.values_in_range.to.value}</li>
                  </ul>
                </>
              )}
            </>
          );
          break;
        case "days_of_the_week":
          html = (
            <>
              {trigger.values_in_range && (
                <>
                  <ul>
                    <li>From: {trigger.values_in_range.from.day.text}</li>
                    <li>To: {trigger.values_in_range.to.day.text}</li>
                  </ul>
                </>
              )}
            </>
          );
          break;

        default:
          break;
      }
    } else if (["is_in", "not_in"].includes(trigger.trigger_condition)) {
      switch (trigger.trigger_type) {
        case "organisation_location":
          html = trigger.values.map((value) => {
            return (
              <>
                <ul>
                  <li>{value.location && <>{value.location.name} </>}</li>
                </ul>
              </>
            );
          });
          break;
        case "membership_type":
          html = trigger.values.map((value) => {
            return (
              <>
                <ul>
                  <li>
                    {value.membership && (
                      <>
                        {value.membership.name} -{" "}
                        {value.membership.membership_type_name}
                      </>
                    )}
                  </li>
                </ul>
              </>
            );
          });
          break;

        case "days_of_the_week":
          html = trigger.values.map((value) => {
            return (
              <>
                <ul>
                  <li>{value.day && <>{value.day.text}</>}</li>
                </ul>
              </>
            );
          });
          break;

        default:
          break;
      }
    }

    return html;
  }

  renderTriggerTypeCondition(condition) {
    let displayText = "";
    switch (condition) {
      case "is_in":
        displayText = "Is in";
        break;
      case "not_in":
        displayText = "Not In";
        break;
      case "in_between":
        displayText = "Between";
        break;
      case "not_in_between":
        displayText = "Not in between";
        break;

      default:
        break;
    }

    return displayText;
  }

  renderConditions(pricingConditions) {
    return (
      <>
        {pricingConditions.map((condition) => {
          return (
            <ul>
              <li>{condition.pricing_trigger.description}</li>
            </ul>
          );
        })}
      </>
    );
  }

  render() {
    const { pricingTriggers } = this.state;

    return (
      <>
        <PricingTriggerModal
          showPricingTriggerModal={this.showPricingTriggerModal}
          isShow={this.state.isShowPricingTriggerModal}
          isEdit={this.state.isEditPricingTriggerModal}
          triggerData={this.state.selectedPricingTrigger}
        />
        <MDBContainer>
          {/* FILTER SECTION */}
          <div>
            <MDBRow className="mb-4">
              <MDBCol md="6">
                <h3 className="mt-3">Manage Pricing Triggers</h3>
              </MDBCol>
              <MDBCol md="6">
                <MDBBtn
                  color="primary"
                  onClick={() => this.showPricingTriggerModal(true, false)}
                  className="float-right"
                >
                  Add Pricing Trigger
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>

          {/* END FILTER SECTION */}

          {/* START TABLE SECTION */}
          <MDBTable responsive striped bordered>
            <MDBTableHead>
              <tr>
                <th className="text-center" width="20%">
                  Description
                </th>
                <th className="text-center" width="15%">
                  {" "}
                  Trigger Type
                </th>
                <th className="text-center" width="15%">
                  Condition
                </th>

                <th className="text-center">Values</th>

                <th className="text-center" width="10%"></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {pricingTriggers.map((trigger) => {
                return (
                  <tr>
                    <td>{trigger.description}</td>
                    <td>{trigger.type_display_name}</td>
                    <td>
                      {this.renderTriggerTypeCondition(
                        trigger.trigger_condition
                      )}
                    </td>

                    <td>{this.renderTriggerValues(trigger)}</td>

                    <td className="text-center">
                      <>
                        <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="edit"
                          className="cyan-text mr-2"
                          onClick={() => {
                            this.showPricingTriggerModal(true, true, trigger);
                          }}
                        />
                      </>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>

          {/* END TABLE SECTION */}
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    pricingResponse: state.pricingResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    showNotification,
    getPricingTriggers,
  })(PricingTrigger)
);
