import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Autocomplete from "react-autocomplete";

import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { connect } from "react-redux";

import {
  displayBreadcrumbs,
  clearSuccessHandler,
  clearFailedHandler,
  alertMessage,
  showHideLoader,
  showNotification,
  toggleModal,
} from "../../../actions";
import {
  createUser,
  getUsers,
  resetPassword,
  updateUser,
  deleteUser,
  getUserDetails,
} from "../../../actions/userAction";

import { getOrganisations } from "../../../actions/organisationAction";
import { getRoles } from "../../../actions/roleAction";

import _ from "lodash";
import Modal from "./Modal";
import { withCookies, Cookies } from "react-cookie";
import { USER_COOKIES_KEY } from "../../../helper";
import { instanceOf } from "prop-types";
import moment from "moment";
import { setAuthUser } from "../../../actions/authAction";

class UserDetails extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  state = {
    name: "",
    email: "",
    domain: "",
    formCleared: false,
    wasValidated: "",
    showModal: false,
    isModalTrigger: false,
    query: "",
    userId: "",
    allowLogin: false,
  };

  clearForm() {
    let { roleOptions } = { ...this.state };
    _.map(roleOptions, (option, index) => {
      roleOptions[index].checked = false;
    });

    this.setState({
      isEdit: false,
      name: "",
      allowLogin: false,
      email: "",
      isActive: false,
      userId: "",
      roleId: "",
      roleOptions: roleOptions,
      wasValidated: "",
    });
  }

  setCookies(key, val, expires) {
    const { cookies } = this.props;
    cookies.set(key, val, { path: "/", expires });
  }

  updateUserCookies() {
    const { cookies } = this.props;
    const { email } = this.state;
    let userCookies = cookies.get(USER_COOKIES_KEY);
    if (userCookies !== undefined) {
      this.props.setAuthUser(userCookies);
      userCookies["username"] = email;

      let userCookieExp = new Date(
        moment(new Date()).add(480, "minutes").utc().format()
      );
      this.setCookies(
        USER_COOKIES_KEY,
        JSON.stringify(userCookies),
        userCookieExp
      );
    }
  }

  submitHandler = (event) => {
    const {
      name,
      email,
      isActive,
      allowLogin,
      loginId,
      domain,
      userId,
      isEdit,
      roleId,
    } = this.state;
    event.preventDefault();
    this.setState({ wasValidated: "was-validated", formCleared: false });

    if (name.length === 0) {
      this.props.showNotification("error", "Please enter name");
    } else if (email.length === 0) {
      this.props.showNotification("error", "Please enter valid email");
    }

    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      let body = {
        name: name,
        email: email,
        isActive: isActive,
        allowLogin: allowLogin,
      };
      let session = this.props.authUser.sessionToken;
      if (isEdit) {
        this.props.updateUser(userId, body, session);
      } else {
        this.props.createUser(body, session);
      }
    }
  };

  componentDidMount() {
    this.props.showHideLoader(true);

    this.props.getUserDetails(this.props.authUser.sessionToken);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userResponse !== this.props.userResponse) {
      let response = this.props.userResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          // this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.doRefresh();
          this.updateUserCookies();
        }
      } else if (response.type == "USER_DETAILS_RESPONSE_HANDLER") {
        this.setState({
          ...this.state,
          resultContainer: response.data,
          page: response.data.page,
          name: response.data.results[0].name,
          email: response.data.results[0].email,
          userId: response.data.results[0].id,
        });
      }
    }
  }

  doReset() {
    const { email, name, userId } = this.state;
    let body = {
      email: email,
      name: name,
      userId: userId,
    };
    this.props.showHideLoader(true);
    this.props.resetPassword(body, this.props.authUser.sessionToken);
  }

  doRefresh = _.debounce(function () {
    this.props.showHideLoader(true);
    this.props.getUserDetails(this.props.authUser.sessionToken);
  }, 1000);

  resetForm = _.debounce(function () {
    if (!this.state.formCleared) {
      this.setState({
        formCleared: true,
        wasValidated: "",
        showModal: false,
      });
    }
  }, 1000);

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderUserDetails()}
      </div>
    );
  }

  showModal = (showModal, isEdit, source) => {
    let { roleOptions } = { ...this.state };
    if (source !== null) {
      let roleId = source.role === undefined ? "" : source.role.id;
      if (roleId !== "" && roleId !== undefined) {
        console.log("roleOptions");
        console.log(roleOptions);
        _.map(roleOptions, (option, index) => {
          roleOptions[index].checked = false;
        });

        let index = _.findIndex(roleOptions, function (o) {
          return o.value === roleId;
        });
        if (index > -1) {
          roleOptions[index].checked = true;
        }
      }

      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        name: source.name,
        email: source.email,
        email: source.email,
        isActive: source.is_active,
        allowLogin: source.allow_login,
        userId: source.id,
        roleId: source.role === undefined ? "" : source.role.id,
        roleOptions: roleOptions,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }

    if (!showModal) {
      this.resetForm();
    }
  };

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  renderForm() {
    const {
      showModal,
      name,
      email,
      isActive,
      allowLogin,
      domain,
      isEdit,
      roleOptions,
    } = this.state;
    return (
      <div>
        <MDBModal isOpen={showModal} size="lg">
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>Update Details</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <MDBInput
                    required
                    label="Name"
                    labelClass="required"
                    value={name}
                    name="name"
                    onChange={this.formDataHandler}
                    id="idName"
                  />
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <MDBInput
                    required
                    label="Email address"
                    labelClass="required"
                    disabled={true}
                    value={email}
                    name="email"
                    onChange={this.formDataHandler}
                    type="email"
                    id="idEmail"
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  renderUserDetails() {
    const { limitOptions, statusOptions, email, name, resultContainer } =
      this.state;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">User Details</h3>
            <MDBCard>
              <MDBCardBody>
                <MDBCol md="12" className="mb-3">
                  <MDBInput
                    disabled={true}
                    label="Name"
                    value={name}
                    name="name"
                    id="idName"
                  />
                </MDBCol>
                <MDBCol md="12" className="mb-3">
                  <MDBInput
                    disabled={true}
                    label="Email address"
                    value={email}
                    name="email"
                    type="email"
                    id="idEmail"
                  />
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="primary"
                    size="sm"
                    className="float-right"
                    onClick={() => this.doReset()}
                  >
                    Reset Password
                  </MDBBtn>

                  <MDBBtn
                    color="primary"
                    size="sm"
                    className="float-right"
                    onClick={() =>
                      this.showModal(true, true, resultContainer.results[0])
                    }
                  >
                    Edit
                  </MDBBtn>
                </MDBCol>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    authUser: state.authUser,
    alert: state.alert,
    userResponse: state.userResponse,
    organisationResponse: state.organisationResponse,
    roleResponse: state.roleResponse,
  };
};

export default withCookies(
  withRouter(
    connect(mapStateToProps, {
      displayBreadcrumbs,
      createUser,
      clearSuccessHandler,
      clearFailedHandler,
      alertMessage,
      showHideLoader,
      getUsers,
      showNotification,
      resetPassword,
      updateUser,
      deleteUser,
      toggleModal,
      getOrganisations,
      getRoles,
      setAuthUser,
      getUserDetails,
    })(UserDetails)
  )
);
