import React from "react";
import { connect } from "react-redux";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import { showHideLoader, showNotification } from "../../../actions";
import {
  cancelBookingRequest,
  getBookingRequest,
  checkBookingRequestRecordLock,
} from "../../../actions/bookingAction";

class BookingRequestModal extends React.Component {
  state = {
    canCancel: 0,
    createByAdmin: 0,
    mainCustomer: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
    },
    participantsData: [],
    locationName: "",
    resourceName: "",
    timeDuration: "",
    reservationDate: "",
    checkBookingRequestLockIntervalId: "",
  };

  componentDidMount() {
    const { bookingRequestDataUUID } = this.props;

    this.props.getBookingRequest(
      bookingRequestDataUUID,
      this.props.authUser.sessionToken
    );

    // Check the record lock on load
    this.props.checkBookingRequestRecordLock(
      bookingRequestDataUUID,
      this.props.authUser.sessionToken
    );

    // Then Check the record lock after every 5 secs

    let intervalId = setInterval(() => {
      this.props.checkBookingRequestRecordLock(
        bookingRequestDataUUID,
        this.props.authUser.sessionToken
      );
    }, 5000);

    this.setState({ checkBookingRequestLockIntervalId: intervalId });
  }

  componentDidUpdate(prevProps, prevState) {
    this.onBookingRequestResponse(prevProps, prevState);
  }
  componentWillUnmount() {
    clearInterval(this.state.checkBookingRequestLockIntervalId);
  }

  onBookingRequestResponse(prevProps, prevState) {
    //GET BOOKING REQUEST DATA RESPONSE
    if (
      prevProps.bookingRequestResponse !== this.props.bookingRequestResponse &&
      this.props.bookingRequestResponse.action === "GET_BOOKING_REQUEST" &&
      this.props.bookingRequestResponse.status === 200
    ) {
      const dataResponse = this.props.bookingRequestResponse.data.response;
      if (dataResponse) {
        this.setState({
          mainCustomer: dataResponse.customerData,
          participantsData: dataResponse.participantsData,
          locationName: dataResponse.location_name,
          resourceName: dataResponse.resource_name,
          timeDuration: dataResponse.time_from + " - " + dataResponse.time_to,
          reservationDate: dataResponse.date,
          createByAdmin: dataResponse.is_created_by_admin,
        });
      }
    }

    //CHECK BOOKING REQUEST RECORD LOCK RESPONSE
    if (
      prevProps.bookingRequestResponse !== this.props.bookingRequestResponse &&
      this.props.bookingRequestResponse.action ===
        "GET_BOOKING_REQUEST_RECORD_LOCK" &&
      this.props.bookingRequestResponse.status === 200
    ) {
      this.setState({
        canCancel:
          this.props.bookingRequestResponse.data.canCancelBookingRequest,
      });
    }
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    event.preventDefault();

    var result = DevExtremeConfirm(
      `Are you sure you want to cancel booking request?`
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);
        this.props.cancelBookingRequest(
          {},
          this.props.authUser.sessionToken,
          this.props.bookingRequestDataUUID
        );
        setTimeout(() => {
          this.props.showNotification(
            "success",
            "Booking Request has been cancelled"
          );
          this.props.toggleBookingRequestModal(false);
          this.props.showHideLoader(false);
        }, 3000);
      }
    });
  };

  render() {
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={this.props.isShowBookingRequestModal}
          size="lg"
          toggle={() => this.props.toggleBookingRequestModal(false)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>Booking Request</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    label="Location"
                    disabled
                    value={this.state.locationName}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    label="Resource"
                    disabled
                    value={this.state.resourceName}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    label="Reservation Date"
                    disabled
                    value={this.state.reservationDate}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    label="Time"
                    disabled
                    value={this.state.timeDuration}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-4">
                <MDBCol md="12" className="text-left">
                  <h4>Customer Details</h4>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    label="First Name"
                    disabled
                    value={this.state.mainCustomer.first_name}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    label="Last Name"
                    disabled
                    value={this.state.mainCustomer.last_name}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    label="Email"
                    disabled
                    value={this.state.mainCustomer.email}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    label="Contact Number"
                    disabled
                    value={this.state.mainCustomer.mobile}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mt-4">
                <MDBCol md="12" className="text-left">
                  <h4>Participants</h4>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12">
                  <MDBTable striped bordered>
                    <MDBTableHead>
                      <tr>
                        <th className="text-center" width="20%">
                          Name
                        </th>
                        <th className="text-center" width="20%">
                          {" "}
                          Email
                        </th>
                        <th className="text-center" width="20%">
                          Phone
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {this.state.participantsData.map((participant) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {participant["first_name"] +
                                  " " +
                                  participant["last_name"]}
                              </td>
                              <td>{participant["email"]}</td>
                              <td>{participant["mobile"]}</td>
                            </tr>
                          </>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              {this.state.createByAdmin === 1 && (
                <>
                  <span
                    data-mdb-toggle="tooltip"
                    data-html="true"
                    title={
                      this.state.canCancel !== 1
                        ? "Cannot cancel booking request, currently being processed by user."
                        : ""
                    }
                  >
                    <MDBBtn
                      color="primary"
                      type="submit"
                      disabled={this.state.canCancel !== 1}
                    >
                      Cancel Booking Request{" "}
                      {this.state.canCancel !== 1 && (
                        <MDBIcon icon="question-circle" />
                      )}
                    </MDBBtn>
                  </span>
                </>
              )}

              <MDBBtn
                color="secondary"
                onClick={() => this.props.toggleBookingRequestModal(false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    bookingRequestResponse: state.bookingRequestResponse,
  };
};

export default connect(mapStateToProps, {
  showHideLoader,
  showNotification,
  cancelBookingRequest,
  getBookingRequest,
  checkBookingRequestRecordLock,
})(BookingRequestModal);
