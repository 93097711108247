import axios from "axios";
import { Cookies } from "react-cookie";
import config from "../config";
var cookies = new Cookies();

export default axios.create({
  baseURL: config.api.v2.baseUrl,
  headers: {
    "x-api-key": config.api.v2.headers.apikey,
    "x-client-fingerprint": cookies.get("fingerprint"),
  },
});
