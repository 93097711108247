import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
    GET_MEMBERSHIP_TYPES,
    MEMBERSHIP_TYPE_RESPONSE_HANDLER,
} from "./types";

export const getMembershipTypes = (params, sessionToken) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken
    };

    const response = await bookingAppPrivate
        .get(`/memberships/types`, {
            params: params,
            headers: headers,
        })
        .then((res) => {
            dispatch({
                type: GET_MEMBERSHIP_TYPES,
                payload: res,
            });
        })
        .catch((err) => {
            let errRes = {};
            errRes = "Network error";
            if (err.response !== undefined) {
                errRes = err.response;
            }
            dispatch({
                type: MEMBERSHIP_TYPE_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};

export const insertMembershipType = (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .post(`/memberships/types`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: MEMBERSHIP_TYPE_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: MEMBERSHIP_TYPE_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};

export const updateMembershipType = (body, uuid, sessionToken) => async (
    dispatch
) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .put(`/memberships/types/${uuid}`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: MEMBERSHIP_TYPE_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: MEMBERSHIP_TYPE_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};

export const clearUpdateMembershipTypeSuccessResponse = () => {
    return {
        type: MEMBERSHIP_TYPE_RESPONSE_HANDLER,
        payload: {},
    };
};

export const clearUpdateMembershipTypeFailedResponse = () => {
    return {
        type: MEMBERSHIP_TYPE_RESPONSE_HANDLER,
        payload: {},
    };
};

export const deleteMembershipType = (uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .delete(`/memberships/types/${uuid}`, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: MEMBERSHIP_TYPE_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: MEMBERSHIP_TYPE_RESPONSE_HANDLER,
                payload: errRes,
            });
        });
};
