import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Autocomplete from "react-autocomplete";

import _ from "lodash";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { generateCredit, updateCredit } from "../../../actions/voucherAction";

import { showHideLoader, showNotification } from "../../../actions";

import { getCustomers } from "../../../actions/customerAction";

import moment from "moment";

class ManageCreditModal extends React.Component {
  state = {
    expiryDate: new Date(new Date().setMonth(new Date().getMonth() + 3)), //add 3 months to current date
    creditAmount: 0,
    customerResultContainer: [],
    searchCustomerValue: "",
    limit: "10",
    query: "",
    customerId: 0,
  };

  componentDidMount() {
    if (this.props.isEdit) {
      this.setState({
        expiryDate: new Date(moment(this.props.expiryDate)),
        creditAmount: this.props.creditAmount,
        customerId: this.props.customerId,
        customerFullName: this.props.customerFullName,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.getCustomerResult(prevProps, prevState);
  }

  getCustomerResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(this.props.customerResponse.data.results)
        .orderBy(["first_name"], ["asc"])
        .value();

      this.setState({
        customerResultContainer: resultContainer,
      });
    }
  }
  componentWillUnmount() {}

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const { creditAmount, expiryDate, customerId } = this.state;
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);

      if (this.props.isEdit) {
        let body = {
          expiry: moment(expiryDate).format("YYYY-MM-DD"),
        };

        this.props.updateCredit(
          body,
          this.props.creditUuid,
          this.props.authUser.sessionToken
        );
      } else {
        let body = {
          credit_amount: creditAmount,
          expiry: moment(expiryDate).format("YYYY-MM-DD"),
          customer_id: customerId,
        };
        this.props.generateCredit(body, this.props.authUser.sessionToken);
      }
    }
  };

  debouncedSearchCustomer = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 20, query: val };
    this.props.getCustomers(params, this.props.authUser.sessionToken);
  }, 1000);

  render() {
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={this.props.isShowManageCreditModal}
          size="md"
          toggle={() => this.props.toggleManageCreditModal(false)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>
              {this.props.isEdit ? "Edit" : "Add"} Credit{" "}
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="mb-2">
                <>
                  <MDBCol size="12">
                    <div className="autoComplete">
                      <label
                        htmlFor="states-autocomplete"
                        className="grey-text required"
                      >
                        Customer
                      </label>
                      {this.props.isEdit ? (
                        <input
                          value={this.props.customerFullName}
                          type="text"
                          className="form-control"
                          disabled
                        />
                      ) : (
                        <Autocomplete
                          autoComplete="off"
                          inputProps={{ id: "states-autocomplete" }}
                          wrapperStyle={{
                            width: "100%",
                            display: "inline-block",
                          }}
                          menuStyle={{
                            position: "absolute",
                            background: "red",
                            zIndex: "3",
                          }}
                          value={this.state.searchCustomerValue}
                          items={this.state.customerResultContainer}
                          getItemValue={(item) => {
                            let label =
                              item.first_name +
                              " " +
                              item.last_name +
                              ", " +
                              item.email;
                            if (item.membership) {
                              label +=
                                " - " +
                                (item.membership.number
                                  ? item.membership.number
                                  : "N/A") +
                                " - " +
                                item.membership.name +
                                " " +
                                item.membership.type.name;
                            }
                            return label;
                          }}
                          onSelect={(value, item) => {
                            this.setState({
                              customerId: item.id,
                              searchCustomerValue: value,
                              customerResultContainer: [item],
                            });
                          }}
                          onChange={(event, value) => {
                            this.debouncedSearchCustomer(value);
                            this.setState({
                              searchCustomerValue: value,
                              customerId: "",
                            });
                          }}
                          renderMenu={(children) => (
                            <div className="menu">{children}</div>
                          )}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item ${
                                isHighlighted ? "item-highlighted" : ""
                              }`}
                              key={item.id}
                            >
                              {item.first_name +
                                " " +
                                item.last_name +
                                ", " +
                                item.email}
                              {item.membership
                                ? " - " +
                                  (item.membership.number
                                    ? item.membership.number
                                    : "N/A") +
                                  " - " +
                                  item.membership.name +
                                  " " +
                                  item.membership.type.name
                                : ""}
                            </div>
                          )}
                        />
                      )}
                    </div>
                  </MDBCol>
                </>
              </MDBRow>

              <MDBRow>
                <MDBCol md="6">
                  <>
                    <label htmlFor="expiryDate" className="grey-text required">
                      Expiry Date
                    </label>
                    <span>
                      <DatePicker
                        minDate={new Date()}
                        className="custom-input"
                        placeholderText="Select Expiry Date"
                        onChange={(date) => {
                          this.setState({ expiryDate: date });
                        }}
                        selected={this.state.expiryDate}
                        dateFormat="dd/MM/yyyy"
                        timeFormat="HH:mm"
                      />
                    </span>
                  </>
                </MDBCol>
                <MDBCol md="6">
                  <label htmlFor="amount" className="grey-text required">
                    Amount
                  </label>
                  <input
                    // disabled={disableEditingPrimaryFields}
                    value={this.state.creditAmount}
                    onChange={this.formDataHandler}
                    name="creditAmount"
                    type="number"
                    step="any"
                    id="creditAmount"
                    className="form-control"
                    placeholder="Enter amount"
                    disabled={this.props.isEdit}
                    required
                    min={1}
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.props.toggleManageCreditModal(false)}
              >
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    customerResponse: state.customerResponse,
  };
};

export default connect(mapStateToProps, {
  showHideLoader,
  showNotification,
  getCustomers,
  generateCredit,
  updateCredit,
})(ManageCreditModal);
