import UbsApiV2 from "./UbsApiV2";

export const createPricing = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.post(`/ssc/pricing`, body, {
    headers: headers,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {});

  return response;
};

export const updatePricing = async (pricing_uuid, body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await UbsApiV2.put(`/ssc/pricing/${pricing_uuid}`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const updatePricingConditions = async (pricing_uuid, body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await UbsApiV2.put(
      `/ssc/pricing/${pricing_uuid}/conditions`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const createPricingDiscount = async (pricing_uuid, body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await UbsApiV2.post(
      `/ssc/pricing/${pricing_uuid}/discount`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const deletePricingDiscount = async (pricing_discount_id) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await UbsApiV2.delete(
      `/ssc/pricing/discount/${pricing_discount_id}`,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const createTrigger = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.post(`/ssc/pricing/triggers`, body, {
    headers: headers,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {});

  return response;
};

export const updatePricingTrigger = async (pricing_uuid, body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await UbsApiV2.put(
      `/ssc/pricing/triggers/${pricing_uuid}`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
