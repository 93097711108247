import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GENERATE_CUSTOMER_VOUCHER_SUCCESS_HANDLER,
  GENERATE_CUSTOMER_VOUCHER_FAILED_HANDLER,
  GET_VOUCHERS,
  GET_CREDITS,
  FAILED_HANDLER,
  VOUCHER_RESPONSE_HANDLER,
} from "./types";

export const generateCustomerVoucher =
  (body, customerId, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/vouchers/generate/user/${customerId}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GENERATE_CUSTOMER_VOUCHER_SUCCESS_HANDLER,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GENERATE_CUSTOMER_VOUCHER_FAILED_HANDLER,
          payload: errRes,
        });
      });
  };
export const generateCredit = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/credits`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: VOUCHER_RESPONSE_HANDLER,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: VOUCHER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const updateCredit = (body, uuid, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/credits/${uuid}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: VOUCHER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: VOUCHER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const clearGenCustomerVoucherSuccessResponse = () => {
  return {
    type: GENERATE_CUSTOMER_VOUCHER_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearGenCustomerVoucherFailedResponse = () => {
  return {
    type: GENERATE_CUSTOMER_VOUCHER_FAILED_HANDLER,
    payload: {},
  };
};

export const getVouchers = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  const response = await bookingAppPrivate
    .get(`/vouchers`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_VOUCHERS,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getCredits = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  const response = await bookingAppPrivate
    .get(`/credits`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_CREDITS,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const insertVoucher = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/vouchers`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: VOUCHER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: VOUCHER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const updateVoucher = (body, id, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/vouchers/${id}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: VOUCHER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: VOUCHER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const deleteVoucher = (id, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .delete(`/vouchers/${id}`, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: VOUCHER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: VOUCHER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const assignVoucher =
  (body, voucherId, userId, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/vouchers/${voucherId}/assignuser/${userId}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: VOUCHER_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: VOUCHER_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const clearVoucherResponse = () => {
  return {
    type: VOUCHER_RESPONSE_HANDLER,
    payload: {},
  };
};
