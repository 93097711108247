import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_ORGANISATION,
  GET_ORGANISATION_CHECKOUT_PAGE,
  GET_ORGANISATIONS,
  ORGANISATION_RESPONSE_HANDLER,
} from "./types";

export const createOrganisation = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/organisations`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: ORGANISATION_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response.data.message;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: ORGANISATION_RESPONSE_HANDLER,
        payload: {
          status: 400,
          data: {
            message: errRes,
          },
        },
      });
    });
};

export const updateOrganisation =
  (organisationUuid, body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/organisations/${organisationUuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: ORGANISATION_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response.data.message;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: ORGANISATION_RESPONSE_HANDLER,
          payload: {
            status: 400,
            data: {
              message: errRes,
            },
          },
        });
      });
  };

export const deleteOrganisation =
  (organisationUuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .delete(`/organisations/${organisationUuid}`, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: ORGANISATION_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response.data.message;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: ORGANISATION_RESPONSE_HANDLER,
          payload: {
            status: 400,
            data: {
              message: errRes,
            },
          },
        });
      });
  };

export const getOrganisations = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  const response = await bookingAppPrivate.get(`/organisations`, {
    headers: headers,
    params: params,
  });

  dispatch({ type: GET_ORGANISATIONS, payload: response });
};

export const getOrganisationCheckoutPage =
  (params, sessionToken) => async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };
    const response = await bookingAppPrivate.get(
      `/organisations/custompage/a4366f65-e7af-4c10-92ab-4b16a8eb79e9`,
      {
        headers: headers,
        params: params,
      }
    );

    dispatch({ type: GET_ORGANISATION_CHECKOUT_PAGE, payload: response });
  };
