import { SET_CUSTOMER } from "../actions/types";

export default (
  customer = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
  },
  action
) => {
  if (action.type === SET_CUSTOMER) {
    return action.payload;
  }
  return customer;
};
