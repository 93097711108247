import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import {
  showHideLoader,
  toggleModal,
  genModalFavouriteCustomer,
} from "../../../actions";

import { getCustomers } from "../../../actions/customerAction";
import _ from "lodash";

import { GEN_MODAL_FAVOURITE_CUSTOMER } from "../../../actions/types";

import {
  insertFavouriteCustomer,
  updateFavouriteCustomer,
} from "../../../actions/userFavouriteAction";

class FavouriteCustomerModal extends React.Component {
  state = {
    resultContainer: {},
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemInnerPills: "1",
    customerUuid: "",
    notification: {},
    showSearchLoading: false,
    customerResultContainer: [],
    value: "",
  };

  componentDidMount() {
    if (this.props.showModal) {
      let customer = this.props.customer;
      this.setState({
        isEdit: true,
        firstName: customer.first_name,
        lastName: customer.last_name,
        email: customer.email,
        address: customer.address,
        mobile: customer.mobile,
        showModal: this.props.showModal,
      });
    }
  }
  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  submitHandler = (event) => {
    const { firstName, lastName, email, mobile, isEdit, favouriteId } =
      this.state;
    let body = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile_no: mobile,
    };
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });

    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateFavouriteCustomer(
          body,
          favouriteId,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertFavouriteCustomer(
          body,
          this.props.authUser.sessionToken
        );
      }
    }
  };

  debouncedSearchCustomer = _.debounce(function (val) {
    let params = { limit: 10, query: val };
    if (val != "") {
      this.props.getCustomers(params, this.props.authUser.sessionToken);
    }
  }, 100);

  clearForm() {
    this.setState({
      isEdit: false,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
    });
  }

  showSearchLoading(showSearchLoading) {
    this.setState({ showSearchLoading: showSearchLoading });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_FAVOURITE_CUSTOMER) {
        let favouriteCustomer = response.data.payload;

        if (response.data.show && response.data.action === "edit") {
          this.setState({
            isEdit: true,
            showModal: true,
            firstName: favouriteCustomer.first_name,
            lastName: favouriteCustomer.last_name,
            email: favouriteCustomer.email,
            mobile: favouriteCustomer.mobile_no,
            favouriteId: favouriteCustomer.id,
          });
        } else {
          this.setState({
            isEdit: false,
            showModal: true,
            firstName: favouriteCustomer.first_name,
            lastName: favouriteCustomer.last_name,
            email: favouriteCustomer.email,
            mobile: favouriteCustomer.mobile_no,
          });
        }
      }
    }

    if (prevProps.userFavouriteResponse !== this.props.userFavouriteResponse) {
      let response = this.props.userFavouriteResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.clearForm();
        this.setState({
          showModal: false,
          isModalTrigger: false,
        });
      }
    }

    //Customer search
    if (prevProps.customerResponse !== this.props.customerResponse) {
      let response = this.props.customerResponse;
      this.setState({ customerResultContainer: response.data.results });
    }
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        firstName: source.first_name,
        lastName: source.last_name,
        email: source.email,
        mobile: source.mobile,
        favouriteId: source.id,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }
    if (!showModal) {
      this.clearForm();
    }
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      mobile,
      isEdit,
      isDefaultUser,
      showModal,
    } = this.state;
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>
              {isEdit ? "Edit" : "Add"} as Favourite Contact
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.showSearchLoading && (
                <MDBRow>
                  <MDBCol md="12">
                    <div>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden"> Searching...</span>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="firstName" className="grey-text">
                    <span className="text-danger">*</span> First Name
                  </label>
                  <input
                    value={firstName}
                    onChange={this.formDataHandler}
                    name="firstName"
                    type="text"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter Customer First Name..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please prove first name
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="lastName" className="grey-text">
                    <span className="text-danger">*</span> Last Name
                  </label>
                  <input
                    value={lastName}
                    onChange={this.formDataHandler}
                    name="lastName"
                    type="text"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter Customer Last Name..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide last name
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="email" className="grey-text">
                    <span className="text-danger">*</span> Email Address
                  </label>
                  <input
                    readOnly={isDefaultUser}
                    disabled={isDefaultUser}
                    value={email}
                    onChange={this.formDataHandler}
                    name="email"
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter Email Address..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide proper email address
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="mobile" className="grey-text">
                    <span className="text-danger">*</span> Mobile
                  </label>
                  <input
                    value={mobile}
                    onChange={this.formDataHandler}
                    name="mobile"
                    type="number"
                    id="mobile"
                    className="form-control"
                    placeholder="Enter Customer Mobile..."
                    required
                  />
                  <div className="invalid-feedback">Please provide mobile</div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
    locations: state.locations,
    userFavouriteResponse: state.userFavouriteResponse,
    customerResponse: state.customerResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    toggleModal,
    genModalFavouriteCustomer,
    getCustomers,
    insertFavouriteCustomer,
    updateFavouriteCustomer,
  })(FavouriteCustomerModal)
);
