import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { genModalRole, genModalRoleAccess } from "../../../actions";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";
import { getRoles, deleteRole } from "../../../actions/roleAction";
import { validateAccess } from "../../../helper/utils";

import _ from "lodash";
import moment from "moment";
import Modal from "../shared/Modal";

class ManageRole extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    notification: {},
    roleId: "",

    componentName: "ManageRole",
  };

  componentDidMount() {
    this.props.showHideLoader(true);
    this.props.displayBreadcrumbs("Dashboard / Administrator / Roles");
    this.initLimitOptions();
  }

  render() {
    return (
      <div>

        {/* <CustomerModal
          showModal={showAddCustomer}
          customerCallback={this.customerCallback}
        /> */}
        {this.renderTable()}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.roleResponse !== this.props.roleResponse) {
      let response = this.props.roleResponse;
      this.props.showHideLoader(false);
      if (
        response.action == "ACTION" &&
        response.type !== "SWITCH_ROLE_ACCESS_RESPONSE_HANDLER"
      ) {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.debouncedDoFilterWithDelay();
        }
      } else if (response.type == "GET_ROLES") {
        console.log("check res");
        console.log(this.props.roleResponse);
        this.setState({
          resultContainer: this.props.roleResponse.data,
          page: this.props.roleResponse.data.page,
        });
      }
    }
  }

  debouncedDoFilterWithDelay = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  }, 1000);

  debouncedDoFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  });

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  renderFilters() {
    const { limitOptions } = this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Roles</h3>
          </MDBCol>
          {validateAccess(storeUserAccess, "Roles", "add") && (
            <MDBCol md="6">
              <MDBBtn
                color="primary"
                onClick={() => this.showModal(true, false, null)}
                className="float-right"
              >
                Add Role
              </MDBBtn>
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search"
                name="query"
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.clearDeleteCustomerAlert();
  }

  clearDeleteCustomerAlert() {
    let modalAttr = {
      showAlert: false,
      alertType: "",
      alertMsg: "",
    };
    this.props.addModalAttr(modalAttr);
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
    };
    this.props.toggleModal(modal);
  };

  doDelete() {
    const { roleId } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteRole(roleId, this.props.authUser.sessionToken);
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  confirmDelete = (source) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${source.role}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeDeleteModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ roleId: source.id });
  };

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalRole(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
      };
      this.props.genModalRole(attr);
    }

    if (!showModal) {
      this.clearForm();
      this.clearDeleteCustomerAlert();
    }
  };

  showAccess = (source) => {
    let attr = {
      show: true,
      action: "add",
      parent: this.state.componentName,
      payload: source,
    };
    console.log("atrrrr");
    console.log(attr);
    this.props.genModalRoleAccess(attr);
  };

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        {
          label: "Role",
          field: "heading0",
          sort: "asc",
        },
        {
          label: "Description",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading8",
          sort: "asc",
        },
        {
          label: "Updated",
          field: "heading9",
          sort: "asc",
        },
        {
          label: "",
          field: "heading10",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    rows = _.chain(resultContainer.results)
      .map((result, index) => {
        return {
          heading0: result.role,
          heading1: result.description,
          heading8: moment(result.created).local().format("DD/MM/YYYY"),
          heading9:
            result.updated === null
              ? ""
              : moment(result.updated).local().format("DD/MM/YYYY"),
          heading10: (
            <div>
              {validateAccess(storeUserAccess, "Roles", "edit") && (
                <MDBBtn
                  color="primary"
                  size="sm"
                  className="float-right"
                  onClick={() => this.showModal(true, true, result)}
                >
                  Edit
                </MDBBtn>
              )}
              {validateAccess(storeUserAccess, "Roles", "delete") && (
                <MDBBtn
                  color="danger"
                  size="sm"
                  className="float-right"
                  onClick={() => this.confirmDelete(result)}
                >
                  Delete
                </MDBBtn>
              )}
              {validateAccess(storeUserAccess, "Roles", "manageAccess") && (
                <MDBBtn
                  color="primary"
                  size="sm"
                  className="float-right"
                  onClick={() => this.showAccess(result)}
                >
                  Manage Access
                </MDBBtn>
              )}
            </div>
          ),
        };
      })
      .value();
    data["rows"] = rows;
    return (
      <MDBContainer>
        {this.renderFilters()}
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            {this.renderTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={resultContainer.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  doFilter() {
    this.props.showHideLoader(true);
    const { status, page, limit, query } = this.state;

    let params = {};

    if ((page + "").length > 0) {
      params["page"] = page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }
    if (query !== undefined) {
      params["q"] = query;
    }
    this.props.getRoles(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    roleResponse: state.roleResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getRoles,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    showNotification,
    clearNotification,
    genModalRole,
    genModalRoleAccess,
    deleteRole,
  })(ManageRole)
);
