import {
  GET_CUSTOMERS,
  CUSTOMER_RESPONSE_HANDLER,
  GET_CUSTOMER,
  FAILED_HANDLER,
  INSERT_CUSTOMER_SUCCESS_HANDLER,
  VALIDATE_CUSTOMER_DETAILS,
} from "../actions/types";
import { act } from "react-dom/test-utils";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    data: data,
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case GET_CUSTOMERS:
      response["data"] = data.customers;
      break;
    case CUSTOMER_RESPONSE_HANDLER:
      response["action"] = "ACTION";
      break;
    case GET_CUSTOMER:
    case FAILED_HANDLER:
      break;
    case VALIDATE_CUSTOMER_DETAILS:
      response["data"] = data;
      break;

    default:
      return state;
  }
  return response;
};
