import React from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { validateAccess } from "../../../helper/utils";
import { getUserSubscriptions, subscribe } from "../../../actions/userAction";
import { getUserBookings } from "../../../actions/bookingAction";
import moment, { utc } from "moment";
import _ from "lodash";
import { extractTimezone } from "../../../helper/utils";
import BookingEditor from "./BookingEditor";
import { getStatuses } from "../../../actions/bookingAction";
import { getResource } from "../../../actions/resourceAction";
import { closeBooking, setActiveComponent } from "../../../actions";

import { GEN_MODAL_SUBSCRIBE_MORE_DETAILS } from "../../../actions/types";
import {
  genModalSubscriptionMoreDetails,
  showHideLoader,
} from "../../../actions";

class VenueSubscriptionTile extends React.Component {
  state = {
    upcomingContainer: [],
    showBookingForm: false,
    statuses: {},
    locationResource: {},
    locationObjs: {},
    componentName: "VenueSubscriptionTile",
  };

  componentDidMount() {
    if (validateAccess(this.props.storeUserAccess, "DefaultUserMenu", "")) {
      // this.props.getUserSubscriptions(this.props.authUser.sessionToken);
      this.props.setActiveComponent(this.state.componentName);
    }
    this.doGetUserBookings();

    this.props.getStatuses(this.props.authUser.sessionToken);

    this.selectedObj = {};
  }

  doGetUserBookings() {
    let params = {
      limit: 5,
      page: 1,
      showFutureBookings: 1,
      status: "Booked",
      locationId: this.props.subscription.locationid,
      componentName: this.state.componentName,
    };
    this.props.getUserBookings(params, this.props.authUser.sessionToken);
  }

  debouncedGetUserBookings = _.debounce(function () {
    this.doGetUserBookings();
  }, 1000);

  getTzDetails(dt = new Date()) {
    const { locationTimezone } = this.state;
    return extractTimezone(locationTimezone, dt);
  }

  unSubscribe() {
    let body = {
      locationId: this.props.subscription.locationid,
      subscribe: false,
    };
    this.props.subscribe(body, this.props.authUser.sessionToken);
  }

  buildUpcoming(results) {
    let elem = [];
    _.map(results, (result, index) => {
      let date = moment(result.start_time)
        .tz(this.getTzDetails().timezone)
        .format("DD/MM/YYYY");
      let duration =
        moment(result.start_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm") +
        "-" +
        moment(result.end_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm");

      elem.push(
        <tr key={"tr-" + index}>
          <td>
            <strong>{result.resource.name}</strong>
            <br />
            Reference: {result.booking_reference} Date: {date} Time: {duration}
          </td>
          <td>
            <MDBBtn
              color="primary"
              size="sm"
              onClick={() => this.viewDetails(result)}
            >
              Details
            </MDBBtn>
          </td>
        </tr>
      );
    });

    return elem;
  }

  viewDetails(selectedObj) {
    this.props.showHideLoader(true);
    this.selectedObj = selectedObj;
    this.props.getResource(
      selectedObj.resource.uuid,
      this.props.authUser.sessionToken
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingResponse !== this.props.bookingResponse) {
      const { bookingResponse } = this.props;
      if (this.props.bookingResponse.type == "GET_USER_BOOKINGS") {
        if (
          (this.props.subscription.locationid == null ||
            bookingResponse.data.bookings.locationId ===
              this.props.subscription.locationid) &&
          bookingResponse.data.bookings.componentName ===
            this.state.componentName
        ) {
          this.setState({
            upcomingElem: this.buildUpcoming(
              this.props.bookingResponse.data.bookings.results
            ),
          });
        }
      }
    }
    this.getStatuses(prevProps, prevState);
    this.getResourceResponse(prevProps, prevState);
    this.closeBooking(prevProps, prevState);
  }

  closeBooking(prevProps, prevState) {
    if (
      prevProps.isCloseBooking !== this.props.isCloseBooking &&
      this.props.isCloseBooking
    ) {
      this.props.closeBooking(false);
      this.selectedObj = {};

      this.setState({
        showBookingForm: false,
      });
      this.debouncedGetUserBookings();
    }
  }

  getStatuses(prevProps, prevState) {
    if (
      prevProps.getStatusesSuccessResponse !==
        this.props.getStatusesSuccessResponse &&
      this.props.getStatusesSuccessResponse.status === 200
    ) {
      this.setState({
        statuses: this.props.getStatusesSuccessResponse.data.statuses,
      });
    }
  }

  getResourceResponse(prevProps, prevState) {
    if (
      prevProps.getResourceResponse !== this.props.getResourceResponse &&
      this.props.componentReducer === this.state.componentName
    ) {
      this.props.showHideLoader(false);
      if (this.props.getResourceResponse.status === 200) {
        if (
          this.selectedObj.location !== undefined &&
          "" + this.selectedObj.location.id ===
            "" + this.props.subscription.locationid
        ) {
          this.setState({
            locationResource: this.props.getResourceResponse.data,
            showBookingForm: true,
          });
        }
      }
    }
  }

  renderBookingDetailsForm() {
    const { showBookingForm, locationResource, statuses } = this.state;
    if (showBookingForm) {
      let booking = this.selectedObj;
      let locationObj = _.filter(this.props.locations.results, function (data) {
        return data.uuid == booking.location.uuid;
      })[0];
      return (
        <div>
          <BookingEditor
            doCalculate={true}
            statuses={statuses}
            selectedBooking={this.selectedObj}
            locationResource={locationResource}
            isView={false}
            locationObj={locationObj}
          />
        </div>
      );
    }
    return <span></span>;
  }

  showMoreDetails() {
    let attr = {
      show: true,
      action: "open",
      parent: this.state.componentName,
      payload: this.props.subscription,
    };

    this.props.genModalSubscriptionMoreDetails(attr);
  }

  renderContent() {
    const { subscription } = this.props;
    let hasElem =
      this.state.upcomingElem !== undefined &&
      this.state.upcomingElem.length > 0;
    let thStyle = {
      width: "80%",
      textAlign: hasElem ? "left" : "center",
    };

    if (!hasElem) {
      thStyle["borderBottom"] = "none";
    }

    return (
      <MDBCol md="6" className="mt-4">
        <MDBCard cascade>
          <MDBCardBody cascade>
            <h5 style={{ textAlign: "center" }}>{subscription.name}</h5>
            <hr />
            <MDBCardText>
              <MDBTable responsive hover>
                <MDBTableHead>
                  <tr>
                    <th style={thStyle}>
                      <h6>
                        <strong>
                          {this.state.upcomingElem !== undefined &&
                          this.state.upcomingElem.length > 0
                            ? "Upcoming bookings"
                            : "No upcoming bookings"}
                        </strong>
                      </h6>
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>{this.state.upcomingElem}</MDBTableBody>
              </MDBTable>
            </MDBCardText>
          </MDBCardBody>
          <div className="grey lighten-3 p-2" style={{ textAlign: "right" }}>
            {/* {this.props.subscription.locationid != null && (
              <MDBBtn
                size="sm"
                color="danger"
                onClick={() => this.unSubscribe()}
              >
                Unsubscribe
              </MDBBtn>
            )} */}
            <MDBBtn
              size="sm"
              color="primary"
              onClick={() => this.showMoreDetails()}
            >
              More Details
            </MDBBtn>
            {/* <Link to={"/admin/customerbooking?uuid=" + subscription.uuid}>
              <MDBBtn size="sm" color="primary">
                New Booking
              </MDBBtn>
            </Link> */}
          </div>
        </MDBCard>
        {this.renderBookingDetailsForm()}
      </MDBCol>
    );
  }

  render() {
    if (validateAccess(this.props.storeUserAccess, "DefaultUserMenu", "")) {
      return this.renderContent();
    }
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    storeUserAccess: state.storeUserAccess,
    userResponse: state.userResponse,
    authUser: state.authUser,
    bookingResponse: state.bookingResponse,
    getResourceResponse: state.resourceResponse,
    getStatusesSuccessResponse: state.getStatusesSuccessResponse,
    isCloseBooking: state.closeBooking,
    componentReducer: state.componentReducer,
    locations: state.locations,
  };
};

export default connect(mapStateToProps, {
  getUserSubscriptions,
  getUserBookings,
  subscribe,
  getStatuses,
  getResource,
  closeBooking,
  genModalSubscriptionMoreDetails,
  setActiveComponent,
  showHideLoader,
})(VenueSubscriptionTile);
