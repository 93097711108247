import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBSwitch } from "mdbreact";
import { setSwitch } from "../../../actions";

class Switch extends React.Component {
  handleSwitchChange = () => {
    this.props.setSwitch(!this.props.setSwitchValResponse);
    this.props.onSwitch(!this.props.setSwitchValResponse);
  };

  componentDidMount() {
    // this.props.onSwitch(true);
    // console.log;
    // console.log("check !this.props.setSwitchValResponse");
    // console.log(this.props.setSwitchValResponse);
  }

  render() {
    return (
      <MDBSwitch
        checked={this.props.setSwitchValResponse}
        onChange={() => this.handleSwitchChange()}
        labelLeft="Weekly"
        labelRight="Day"
      />
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("this.state.switch" + this.state.switch);
    // console.log("check !this.props.setSwitchValResponse");
    // console.log(this.props.setSwitchValResponse);
  }
}

const mapStateToProps = (state) => {
  return { setSwitchValResponse: state.setSwitchValResponse };
};

export default connect(mapStateToProps, { setSwitch })(Switch);
