import React from "react";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import _ from "lodash";
import {
  genSearchVoucher,
  genSearchBooking,
  genSearchCustomer,
} from "../../../actions/genericSearchAction";
import moment, { utc } from "moment";

import { getStatuses } from "../../../actions/bookingAction";
import { getResource } from "../../../actions/resourceAction";

import ReactPaginate from "react-paginate";
import BookingEditor from "./BookingEditor";
// import CustomerModal from "./CustomerModal";
import { extractTimezone } from "../../../helper/utils";

import {
  GEN_SEARCH_VOUCHER,
  GEN_SEARCH_BOOKING,
  GEN_SEARCH_CUSTOMER,
} from "../../../actions/types";
import { genModalCustomer, genModalVoucher } from "../../../actions";
import { GEN_MODAL_CUSTOMER, GEN_MODAL_VOUCHER } from "../../../actions/types";

import { closeBooking } from "../../../actions";
import notiSound from "../assets/noti.mp3";

class GenericSearchResult extends React.Component {
  state = {
    results: {},
    limit: "5",
    query: "",
    page: "1",
    showLoading: false,

    locationResource: {},
    showChildModal: false,
    statuses: {},
    componentName: "GenericSearchResult",
    play: false,
    locationTimezone: undefined,
  };

  constructor(props) {
    super(props);
    this.audio = new Audio(notiSound);
  }

  componentDidMount() {
    this.page = 1;
    this.selectedObj = {};

    if (this.props.type === GEN_SEARCH_BOOKING) {
      this.props.getStatuses(this.props.authUser.sessionToken);
    }
    this.audio.addEventListener("ended", () => this.setState({ play: false }));
  }
  debounceDoSearch = _.debounce(function () {
    this.doSearch();
  }, 1000);

  componentWillUnmount() {
    this.audio.removeEventListener("ended", () =>
      this.setState({ play: false })
    );
  }

  // togglePlay = () => {
  //   this.setState({ play: !this.state.play }, () => {
  //     this.state.play ? this.audio.play() : this.audio.pause();
  //   });
  // }

  getTzDetails(dt = new Date()) {
    const { locationTimezone } = this.state;
    return extractTimezone(locationTimezone, dt);
  }

  doSearch() {
    const { limit, query } = this.state;
    let params = {};

    if ((this.page + "").length > 0) {
      params["page"] = this.page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }

    switch (this.props.type) {
      case GEN_SEARCH_VOUCHER:
        if (query !== undefined) {
          params["q"] = this.props.query;
        }
        this.props.genSearchVoucher(params, this.props.authUser.sessionToken);
        break;
      case GEN_SEARCH_BOOKING:
        if (query !== undefined) {
          params["query"] = this.props.query;
          params["exactMatch"] = true;
        }
        this.props.genSearchBooking(params, this.props.authUser.sessionToken);
        break;
      case GEN_SEARCH_CUSTOMER:
        if (query !== undefined) {
          params["query"] = this.props.query;
        }
        this.props.genSearchCustomer(params, this.props.authUser.sessionToken);
    }

    this.setState({ query: this.props.query, showLoading: true });
  }

  renderTotalResult() {
    const { results } = this.state;
    return (
      <span>
        Showing {results.data.results.length} of total {results.data.total}{" "}
        records
      </span>
    );
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.page = selectedPage;
    this.doSearch();
  };

  viewMore = (obj) => {
    let attr = {};
    switch (this.props.type) {
      case GEN_SEARCH_BOOKING:
        this.selectedObj = obj;
        this.props.getResource(
          obj.resource.uuid,
          this.props.authUser.sessionToken
        );
        break;
    }
    switch (this.props.type) {
      case GEN_SEARCH_CUSTOMER:
        attr = {
          show: true,
          action: "edit",
          parent: this.state.componentName,
          payload: obj,
        };
        this.props.genModalCustomer(attr);
        break;
      case GEN_SEARCH_VOUCHER:
        attr = {
          show: true,
          action: "edit",
          parent: this.state.componentName,
          payload: obj,
        };
        this.props.genModalVoucher(attr);
        break;
    }
  };

  buildRecord() {
    const { results } = this.state;
    let elem = [];

    if (results.type === GEN_SEARCH_VOUCHER) {
      let customerVoucherId = "";
      let customerIssued = false;
      let customer = {};
      _.map(results.data.results, (result, index) => {
        customerVoucherId = result.customer.customer_voucher_id;
        customerIssued =
          customerVoucherId !== null && customerVoucherId !== undefined;
        customer = result.customer;
        elem.push(
          <tr key={index}>
            <td>
              {result.voucher_code}
              {customerIssued ? customerVoucherId : ""}
              {customerIssued
                ? " | " + customer.firstName + " " + customer.lastName
                : ""}
              {customerIssued ? " | " + customer.email : ""}
            </td>
            <td>
              <MDBBtn
                size="sm"
                color="primary"
                onClick={() => this.viewMore(result)}
              >
                View more
              </MDBBtn>
            </td>
          </tr>
        );
      });
    }

    if (results.type === GEN_SEARCH_BOOKING) {
      let customer = {};
      _.map(results.data.results, (result, index) => {
        customer = result.customer;
        let date = moment(result.start_time)
          .tz(this.getTzDetails().timezone)
          .format("DD/MM/YYYY");
        let duration =
          moment(result.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm") +
          "-" +
          moment(result.end_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
        elem.push(
          <tr key={index}>
            <td>
              {result.booking_reference}
              {" | " + customer.fullname}
              {" | Date: " + date}
              {" | Time: " + duration}
              {" | " + result.location.name}
              {" | " + result.resource.name}
              {" | " + result.status}
            </td>
            <td>
              <MDBBtn
                size="sm"
                color="primary"
                onClick={() => this.viewMore(result)}
              >
                View more
              </MDBBtn>
            </td>
          </tr>
        );
      });
    }

    if (results.type === GEN_SEARCH_CUSTOMER) {
      let memberNo = "";
      _.map(results.data.results, (result, index) => {
        memberNo = "";
        if (result.membership) {
          memberNo =
            " | Membership: " +
            result.membership.name +
            " " +
            result.membership.type.name;
          if (result.membership.number !== undefined) {
            memberNo += " | Member #: " + result.membership.number;
          }
        }
        elem.push(
          <tr key={index}>
            <td>
              {result.fullname}
              {" | " + result.email}
              {memberNo}
            </td>
            <td>
              <MDBBtn
                size="sm"
                color="primary"
                onClick={() => this.viewMore(result)}
              >
                View more
              </MDBBtn>
            </td>
          </tr>
        );
      });
    }

    return elem;
  }

  renderContent() {
    const { results, showLoading } = this.state;
    if (results.data !== undefined && results.data.results.length > 0) {
      return (
        <div className="generic-search-result mb-5">
          <MDBTable responsive hover>
            <MDBTableHead>
              <tr>
                <th colSpan="2">
                  {this.props.category}
                  {showLoading && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>{this.buildRecord()}</MDBTableBody>
          </MDBTable>
          <MDBRow>
            <MDBCol md="12">
              {this.renderTotalResult()}
              <div className="float-right mt-2">
                <ReactPaginate
                  forcePage={this.page - 1}
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={results.data.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      );
    }

    return (
      <div className="generic-search-result mb-5">
        <MDBTable responsive hover>
          <MDBTableHead>
            <tr>
              <th colSpan="2">
                {this.props.category}
                {showLoading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr style={{ textAlign: "center" }}>
              <td>No record to display</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </div>
    );
  }

  renderBookingDetailsForm() {
    const { showChildModal, locationResource, statuses } = this.state;
    if (this.props.type === GEN_SEARCH_BOOKING && showChildModal) {
      return (
        <div>
          <BookingEditor
            doCalculate={true}
            statuses={statuses}
            selectedBooking={this.selectedObj}
            locationResource={locationResource}
            isView={false}
          />
        </div>
      );
    }
    return <span></span>;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.genericSearchResponse !== this.props.genericSearchResponse) {
      if (this.props.genericSearchResponse.type === this.props.type) {
        this.setState({
          results: this.props.genericSearchResponse,
          showLoading: false,
        });
        if (
          this.props.genericSearchResponse.data !== undefined &&
          this.props.genericSearchResponse.data.results !== undefined &&
          this.props.genericSearchResponse.data.results.length > 0
        ) {
          this.audio.play();
        }
      }
    }

    if (prevProps.query !== this.props.query && this.props.query.length > 0) {
      this.page = 1;
      this.doSearch();
    } else if (
      prevProps.query !== this.props.query &&
      this.props.query.length === 0
    ) {
      this.setState({ results: {}, showLoading: false });
    }
    this.getStatuses(prevProps, prevState);
    this.getResourceResponse(prevProps, prevState);
    this.closeBooking(prevProps, prevState);
    this.customerCallback(prevProps, prevState);
    this.voucherCallback(prevProps, prevState);
  }

  customerCallback(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_CUSTOMER) {
        if (
          response.data.parent === this.state.componentName &&
          response.data.action === "callback"
        ) {
          this.debounceDoSearch();
        }
      }
    }
  }

  voucherCallback(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_VOUCHER) {
        if (
          response.data.parent === this.state.componentName &&
          response.data.action === "callback"
        ) {
          this.debounceDoSearch();
        }
      }
    }
  }

  closeBooking(prevProps, prevState) {
    if (
      prevProps.isCloseBooking !== this.props.isCloseBooking &&
      this.props.isCloseBooking
    ) {
      this.props.closeBooking(false);
      this.selectedObj = {};

      this.setState({
        showChildModal: false,
      });
      this.debounceDoSearch();
    }
  }

  getStatuses(prevProps, prevState) {
    if (
      prevProps.getStatusesSuccessResponse !==
        this.props.getStatusesSuccessResponse &&
      this.props.getStatusesSuccessResponse.status === 200 &&
      this.props.type === GEN_SEARCH_BOOKING
    ) {
      this.setState({
        statuses: this.props.getStatusesSuccessResponse.data.statuses,
      });
    }
  }

  getResourceResponse(prevProps, prevState) {
    if (
      prevProps.getResourceResponse !== this.props.getResourceResponse &&
      this.props.getResourceResponse.status === 200 &&
      this.props.type === GEN_SEARCH_BOOKING
    ) {
      this.setState({
        locationResource: this.props.getResourceResponse.data,
        showChildModal: true,
      });
    }
  }

  debounceDoFocus = _.debounce(function () {
    this.doFocus();
  }, 400);

  render() {
    return (
      <div>
        {this.renderBookingDetailsForm()}
        {/* {this.renderCustomerModal()} */}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    genericSearchResponse: state.genericSearchResponse,
    authUser: state.authUser,
    getResourceResponse: state.resourceResponse,
    getStatusesSuccessResponse: state.getStatusesSuccessResponse,
    isCloseBooking: state.closeBooking,
    genericModalResponse: state.genericModalResponse,
  };
};

export default connect(mapStateToProps, {
  genSearchVoucher,
  genSearchBooking,
  genSearchCustomer,
  getStatuses,
  getResource,
  closeBooking,
  genModalCustomer,
  genModalVoucher,
})(GenericSearchResult);
