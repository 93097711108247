import bookingAppPrivate from "../apis/bookingAppPrivate";
import { GET_AUDITLOGS } from "./types";


export const getAuditLogs = (params, sessionToken) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate.get(`/auditlogs`, {
        headers: headers,
        params: params
    });

    dispatch({ type: GET_AUDITLOGS, payload: response.data });
};
