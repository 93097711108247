import { DISABLE_NEXT_NAV, DISABLE_PREV_NAV } from "./types";

export const disableNextNav = (disable) => {
  return {
    type: DISABLE_NEXT_NAV,
    payload: disable,
  };
};

export const disablePrevNav = (disable) => {
  return {
    type: DISABLE_PREV_NAV,
    payload: disable,
  };
};
