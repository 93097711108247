import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_ROLES,
  FAILED_HANDLER,
  ROLE_RESPONSE_HANDLER,
  GET_ROLE_ACCESS,
  GET_USER_ROLE_ACCESS,
  SWITCH_ROLE_ACCESS_RESPONSE_HANDLER,
} from "./types";

export const getRoles = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  bookingAppPrivate
    .get(`/role`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_ROLES,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const insertRole = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/role`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: ROLE_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: ROLE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const updateRole = (body, id, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/role/${id}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: ROLE_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: ROLE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const deleteRole = (id, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .delete(`/role/${id}`, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: ROLE_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: ROLE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const assignVoucher = (body, voucherId, userId, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/vouchers/${voucherId}/assignuser/${userId}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: ROLE_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: ROLE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const getRoleAccess = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  bookingAppPrivate
    .get(`/role/roleaccess`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_ROLE_ACCESS,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getUserRoleAccess = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  bookingAppPrivate
    .get(`/role/roleaccess`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_USER_ROLE_ACCESS,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const switchAccess = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/role/switchaccess`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: SWITCH_ROLE_ACCESS_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: SWITCH_ROLE_ACCESS_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const clearRoleResponse = () => {
  return {
    type: ROLE_RESPONSE_HANDLER,
    payload: {},
  };
};
