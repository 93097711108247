import {
    GET_MEMBERSHIP_TYPES,
    MEMBERSHIP_TYPE_RESPONSE_HANDLER,
} from "../actions/types";

export default (state = {}, action) => {
    let status = 200;
    let data = {}
    let notificationType = 'info'
    if (action.payload != undefined && typeof action.payload.status != 'undefined') {
        status = action.payload.status;
        notificationType = 'success'
        if (status >= 400) {
            notificationType = 'error'
        }
        data = action.payload.data;
    }

    let response = { notificationType: notificationType, type: action.type, status: status, action: 'LIST' };
    switch (action.type) {
        case GET_MEMBERSHIP_TYPES:
            response['data'] = data.membership_types;
            break;
        case MEMBERSHIP_TYPE_RESPONSE_HANDLER:
            response['data'] = action.payload.data;
            response['action'] = 'ACTION'
            break;
        default:
            return state;
    }
    return response
};
