import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  SET_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_BANNER_MESSAGE,
  HIDE_CUSTOMER_BANNER_MESSAGE,
  INSERT_CUSTOMER_SUCCESS_HANDLER,
  INSERT_CUSTOMER_FAILED_HANDLER,
  CUSTOMER_RESPONSE_HANDLER,
  FAILED_HANDLER,
  VALIDATE_CUSTOMER_DETAILS,
} from "./types";

export const setCustomer = (customer) => {
  return {
    type: "SET_CUSTOMER",
    payload: customer,
  };
};

export const getCustomers = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };

  const response = await bookingAppPrivate
    .get(`/customers`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getFavouriteCustomers =
  (params, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate
      .get(`users/favouritesDetails`, {
        params: params,
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_CUSTOMERS,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: FAILED_HANDLER,
          payload: errRes,
        });
      });
  };

export const insertCustomer = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/customers`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: CUSTOMER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: CUSTOMER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const clearInsertCustomerSuccessResponse = () => {
  return {
    type: INSERT_CUSTOMER_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearInsertCustomerFailedResponse = () => {
  return {
    type: INSERT_CUSTOMER_FAILED_HANDLER,
    payload: {},
  };
};

export const updateCustomer =
  (body, uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/customers/${uuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: CUSTOMER_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: CUSTOMER_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const clearUpdateCustomerSuccessResponse = () => {
  return {
    type: CUSTOMER_RESPONSE_HANDLER,
    payload: {},
  };
};

export const clearUpdateCustomerFailedResponse = () => {
  return {
    type: CUSTOMER_RESPONSE_HANDLER,
    payload: {},
  };
};

export const deleteCustomer = (uuid, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .delete(`/customers/${uuid}`, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: CUSTOMER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: CUSTOMER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const validateCustomerDetails =
  (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/customers/verify`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: VALIDATE_CUSTOMER_DETAILS, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: VALIDATE_CUSTOMER_DETAILS,
          payload: errRes,
        });
      });
  };

export const clearDeleteCustomerSuccessResponse = () => {
  return {
    type: CUSTOMER_RESPONSE_HANDLER,
    payload: {},
  };
};

export const clearDeleteCustomerFailedResponse = () => {
  return {
    type: CUSTOMER_RESPONSE_HANDLER,
    payload: {},
  };
};

export const getCustomerBannerMessage =
  (params, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };

    bookingAppPrivate
      .get(`/customers/getBannerMessage`, {
        headers: headers,
        params: params,
      })
      .then((res) => {
        dispatch({ type: GET_CUSTOMER_BANNER_MESSAGE, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: CUSTOMER_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const hideCustomerBannerMessage =
  (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };

    bookingAppPrivate
      .post(`/customers/hideCustomerBannerMessage`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: CUSTOMER_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: CUSTOMER_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };
