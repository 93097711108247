import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getLocationList } from "../../../actions/locationAction";
import ManageCustomer from "./ManageCustomer";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";
import {
  getMemberships,
  insertMembership,
  updateMembership,
  deleteMembership,
} from "../../../actions/membershipAction";

import { getMembershipTypes } from "../../../actions/membershipTypeAction";

import _ from "lodash";
import moment from "moment";
import Modal from "../shared/Modal";
import { validateAccess } from "../../../helper/utils";
import { SketchPicker } from "react-color";

const defaultRecurringBookingColor = "#4CAF50B3";
const defaultRegularBookingColor = "#1890ff";
class ManageMembership extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    customerUuid: "",
    notification: {},
    query: "",
    showMembershipCustomers: false,
    membership_id: "",
    membership_name: "",
    membership_price: "",
    membership_type: "",
    membership_type_id: "",
    locationId: "",
    location: "",
    isEdit: false,
    locationValue: "",
    locationResultContainer: [],
    membershipTypeContainer: [],
    recurringBookingColor: defaultRecurringBookingColor,
    regularBookingColor: defaultRegularBookingColor,
  };

  componentDidMount() {
    this.props.showHideLoader(true);
    this.props.displayBreadcrumbs("Dashboard / Administrator / Memberships");
    this.initLimitOptions();
    this.props.getMembershipTypes({}, this.props.authUser.sessionToken);
  }

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderMembershipCustomers()}
        {this.renderTable()}
      </div>
    );
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const {
      membership_id,
      locationId,
      membership_name,
      membership_price,
      membership_prefix_name,
      membership_type_id,
      isEdit,
      recurringBookingColor,
      regularBookingColor,
    } = this.state;
    let body = {
      location_id: locationId,
      membership_name: membership_name,
      membership_price: membership_price,
      membership_prefix_name: membership_prefix_name,
      type_id: membership_type_id,
      regular_booking_bg_color: regularBookingColor,
      recurring_booking_bg_color: recurringBookingColor,
    };
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateMembership(
          body,
          membership_id,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertMembership(body, this.props.authUser.sessionToken);
      }
    }
  };

  getLocationResult(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
      this.props.getLocationListSuccessResponse
    ) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(
        this.props.getLocationListSuccessResponse.data.locations.results
      )
        .orderBy(["name"], ["asc"])
        .value();
      this.setState({
        locationResultContainer: resultContainer,
      });
    }
  }

  renderLocationAutoComplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete2" className="required">
          Search location
        </label>
        <Autocomplete
          required
          autoComplete="off"
          inputProps={{ id: "states-autocomplete2" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.locationValue}
          items={this.state.locationResultContainer}
          getItemValue={(item) => item.name}
          onSelect={(value, item) => {
            this.setState({
              locationId: item.id,
              locationUuid: item.uuid,
              locationValue: value,
              locationResultContainer: [item],
              resourceValue: "",
              organisationId: item.organisation.id,
            });
          }}
          onChange={(event, value) => {
            this.setState({ locationValue: value, locationId: "" });
            this.debouncedSearchLocation(value);
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.name}
            </div>
          )}
        />
      </div>
    );
  }

  debouncedSearchLocation = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 5, q: val, is_active: 1 };
    this.props.getLocationList(params, this.props.authUser.sessionToken);
  }, 1000);

  handleChangeComplete = (color, name) => {
    this.setState({ [name]: color.hex });
  };

  renderForm() {
    const {
      showModal,
      membership_name,
      membership_price,
      membership_prefix_name,
      membership_type_id,
      location,
      membershipTypeContainer,
      isEdit,
    } = this.state;
    return (
      <div>
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>
              {isEdit ? "Edit" : "Add"} Membership
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  {this.renderLocationAutoComplete()}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="firstName" className="grey-text">
                    <span className="text-danger">*</span> Membership Name
                  </label>
                  <input
                    value={membership_name}
                    onChange={this.formDataHandler}
                    name="membership_name"
                    type="text"
                    id="membership_name"
                    className="form-control"
                    placeholder="Enter Membership Name..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the membership name
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="firstName" className="grey-text">
                    <span className="text-danger">*</span> Membership Prefix
                    Name
                  </label>
                  <input
                    value={membership_prefix_name}
                    onChange={this.formDataHandler}
                    name="membership_prefix_name"
                    type="text"
                    id="membership_prefix_name"
                    className="form-control"
                    placeholder="Enter Membership Prefix Name. Eg. ORG"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the membership prefix name
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="lastName" className="grey-text">
                    <span className="text-danger">*</span> Membership Type
                  </label>
                  <select
                    className="custom-select bMDBRowser-default"
                    name="membership_type_id"
                    required
                    defaultValue={membership_type_id}
                    onChange={this.formDataHandler}
                  >
                    <option value="">Select Membership Type</option>
                    {membershipTypeContainer != undefined &&
                      membershipTypeContainer.map((type) => (
                        <option key={type.name} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="email" className="grey-text">
                    <span className="text-danger">*</span> Membership Price
                  </label>
                  <input
                    value={membership_price}
                    onChange={this.formDataHandler}
                    name="membership_price"
                    type="number"
                    id="membership_price"
                    className="form-control"
                    placeholder="Enter Membership Price..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the membership price
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="lastName" className="grey-text">
                    <span className="text-danger"></span> Regular Booking Color
                  </label>
                  <SketchPicker
                    color={this.state.recurringBookingColor}
                    onChange={(color) =>
                      this.handleChangeComplete(color, "recurringBookingColor")
                    }
                  />
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="email" className="grey-text">
                    <span className="text-danger">*</span> Recurring Booking
                    Color
                  </label>

                  <SketchPicker
                    color={this.state.regularBookingColor}
                    onChange={(color) =>
                      this.handleChangeComplete(color, "regularBookingColor")
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  clearForm() {
    this.setState({
      isEdit: false,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      mobile: "",
      customerId: "",
      customerUuid: "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.getLocationResult(prevProps, prevState);
    this.getMembershipTypeResult(prevProps, prevState);

    if (prevProps.membershipResponse !== this.props.membershipResponse) {
      let response = this.props.membershipResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.doFilter();
        }
      } else if (response.status < 400) {
        this.setState({
          resultContainer: this.props.membershipResponse.data,
          page: this.props.membershipResponse.data.page,
        });
      }
    }
  }

  getMembershipTypeResult(prevProps, prevState) {
    if (
      prevProps.membershipTypeResponse !== this.props.membershipTypeResponse
    ) {
      let response = this.props.membershipTypeResponse;
      if (response.status < 400) {
        this.setState({
          membershipTypeContainer: response.data,
        });
      }
    }
  }

  debouncedDoFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  });

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  renderFilters() {
    const { limitOptions, statusOptions } = this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Membership Plans</h3>
          </MDBCol>
          {validateAccess(
            storeUserAccess,
            "Memberships",
            "AddMembershipPlan"
          ) && (
            <MDBCol md="6">
              <MDBBtn
                color="primary"
                onClick={() => this.showModal(true, false, null)}
                className="float-right"
              >
                Add Membership Plans
              </MDBBtn>
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search membership plan."
                name="query"
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.clearDeleteMembershipAlert();
  }

  clearDeleteMembershipAlert() {
    let modalAttr = {
      showAlert: false,
      alertType: "",
      alertMsg: "",
    };
    this.props.addModalAttr(modalAttr);
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
    };
    this.props.toggleModal(modal);
  };

  doDelete() {
    const { membership_id } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteMembership(
      membership_id,
      this.props.authUser.sessionToken
    );
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  confirmDelete = (membership) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${membership.name} - ${membership.type.name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeDeleteModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ membership_id: membership.id });
  };

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        membership_name: source.name,
        membership_prefix_name: source.prefix_name,
        membership_price: source.price,
        membership_type_id: source.type.id,
        locationId: source.location.id,
        locationValue: source.location.name,
        membership_id: source.id,
        recurringBookingColor: source.recurring_booking_bg_color
          ? source.recurring_booking_bg_color
          : defaultRecurringBookingColor,
        regularBookingColor: source.regular_booking_bg_color
          ? source.regular_booking_bg_color
          : defaultRegularBookingColor,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }

    if (!showModal) {
      this.clearForm();
      this.clearDeleteMembershipAlert();
    }
  };

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        {
          label: "Membership Prefix Name",
          field: "membership_prefix_name",
          sort: "asc",
        },
        {
          label: "Membership Name",
          field: "membership_name",
          sort: "asc",
        },
        {
          label: "Membership Type",
          field: "membership_type",
          sort: "asc",
        },
        {
          label: "Membership Location",
          field: "membership_location",
          sort: "asc",
        },
        {
          label: "Membership Price",
          field: "membership_price",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading6",
          sort: "asc",
        },
        {
          label: "Updated",
          field: "heading7",
          sort: "asc",
        },
        {
          label: "",
          field: "heading8",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    rows = _.chain(resultContainer.results)
      .map((result, index) => {
        return {
          membership_prefix_name: result.prefix_name,
          membership_name: result.name,
          membership_type: result.type.name,
          membership_location: result.location.name,
          membership_price: `$${result.price}`,
          heading6: moment(result.created).local().format("DD/MM/YYYY"),
          heading7: moment(result.updated).local().format("DD/MM/YYYY"),
          heading8: (
            <div>
              {validateAccess(storeUserAccess, "Memberships", "delete") && (
                <MDBBtn
                  color="danger"
                  size="sm"
                  className="float-right"
                  onClick={() => this.confirmDelete(result)}
                >
                  Delete
                </MDBBtn>
              )}
              {validateAccess(storeUserAccess, "Memberships", "edit") && (
                <MDBBtn
                  color="primary"
                  size="sm"
                  className="float-right"
                  onClick={() => this.showModal(true, true, result)}
                >
                  Edit
                </MDBBtn>
              )}
              {validateAccess(
                storeUserAccess,
                "Memberships",
                "viewCustomers"
              ) && (
                <MDBBtn
                  style={{
                    marginTop: ".375rem",
                    marginBottom: ".375rem",
                    marginRight: ".375rem",
                    marginLeft: ".375rem",
                  }}
                  //href={bookingUrl}
                  onClick={() => this.showMembershipCustomers(result)}
                  color="primary"
                  size="sm"
                  className="float-right"
                >
                  View Customers
                </MDBBtn>
              )}
            </div>
          ),
        };
      })
      .value();
    data["rows"] = rows;
    return (
      <MDBContainer>
        {this.renderFilters()}
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            {this.renderTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={resultContainer.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  showMembershipCustomers = (customer) => {
    if (customer !== null) {
      this.setState({
        showMembershipCustomers: true,
        membershipId: customer.id,
      });
    } else {
      this.setState({ showMembershipCustomers: false, membershipId: 0 });
    }
  };

  renderMembershipCustomers() {
    const { showMembershipCustomers, membershipId } = this.state;
    if (showMembershipCustomers) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return (
      <div>
        {membershipId > 0 ? (
          <MDBModal
            isOpen={showMembershipCustomers}
            size="xl"
            wrapClassName="scrollable"
            toggle={() => this.showMembershipCustomers(null)}
          >
            <MDBModalHeader>Membership - Customers</MDBModalHeader>
            <MDBModalBody>
              <ManageCustomer membershipId={membershipId} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showMembershipCustomers(null)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : (
          <span></span>
        )}
      </div>
    );
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return (
      <span>
        Showing {resultContainer.page} page out of {resultContainer.total} pages
      </span>
    );
  }

  doFilter() {
    this.props.showHideLoader(true);
    const { status, page, limit, query } = this.state;

    let params = {};

    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }
    if (query !== undefined) {
      params["query"] = query;
    }
    this.props.getMemberships(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    membershipResponse: state.membershipResponse,
    membershipTypeResponse: state.membershipTypeResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    modal: state.modal,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getMemberships,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertMembership,
    updateMembership,
    deleteMembership,
    showNotification,
    clearNotification,
    getLocationList,
    getMembershipTypes,
  })(ManageMembership)
);
