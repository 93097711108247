import React, { Component } from "react";

import Autocomplete from "react-autocomplete";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { nanoid } from "nanoid";

import {
  getRules,
  insertRule,
  updateRule,
  deleteRule,
} from "../../../actions/ruleAction";

import Notification from "../shared/Notification";

import { getLocationList } from "../../../actions/locationAction";
import { validateAccess } from "../../../helper/utils";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBDataTable,
  MDBAutoV5,
  MDBSelectV5,
  MDBSelect,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtnGroup,
} from "mdbreact";

import {
  displayBreadcrumbs,
  showHideLoader,
  showNotification,
} from "../../../actions";

import _ from "lodash";

import { Button, Checkbox, Input, Radio, Select } from "antd";

import QueryBuilder from "react-querybuilder";
import { CommonSeriesSettingsHoverStyle } from "devextreme-react/chart";

const { Option } = Select;

class ManageRules extends React.Component {
  state = {
    requestCount: 0,
    typeId: 1,
    ruleId: 0,
    isEdit: false,
    showManageRuleModal: false,
    name: "",
    description: "",
    public_message: "",
    isActive: false,
    locationResultContainer: [],
    locationIds: "",
    resultContainer: [],
    ruleTypes: [],
    ruleType: "booking_time_restriction",
  };

  memberTypes = [
    { name: "Non Member", label: "Non Member" },
    { name: "Day", label: "Day" },
    { name: "Full", label: "Full" },
    { name: "Meriden", label: "Meriden" },
    { name: "WWTA", label: "WWTA" },
    { name: "Max", label: "Max" },
    { name: "Homebush", label: "Homebush" },
    { name: "IGSSA", label: "IGSSA" },
  ];

  constructor(props) {
    super(props);
    this.changeHandler = this.changeHandler.bind(this);
    this.logQuery = this.logQuery.bind(this);
  }

  controlElements = {
    addGroupAction: (event) => {
      return event.level == 0 && this.AntDActionElement(event);
    },
    addRuleAction: (event) => {
      return this.AntDActionElement(event);
    },
    combinatorSelector: this.AntDValueSelector,
    fieldSelector: this.AntDValueSelector,
    notToggle: this.AntDNotToggle,
    operatorSelector: this.AntDValueSelector,
    removeGroupAction: this.AntDActionElement,
    removeRuleAction: this.AntDActionElement,
    valueEditor: this.AntDValueEditor,
  };

  preparedFields = {
    booking: [
      {
        name: "max_active_booking",
        label: "Max Active Booking",
        inputType: "number",
        operators: [{ name: ">=", label: "is" }],
      },
      {
        name: "is_member",
        label: "Is Customer a Member?",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
      },
      {
        name: "override_public_message",
        label: "Override Public Mesage",
        operators: [{ name: "override", label: "override" }],
      },
      {
        name: "admin_action",
        label: "Admin Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "admin_message",
        label: "Admin Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "user_action",
        label: "User Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "user_message",
        label: "User Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "public_action",
        label: "Public Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "public_message",
        label: "Public Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
    ],
    booking_cancellation: [
      {
        name: "membership_type",
        label: "Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "primary_participant_membership_type",
        label: "Primary Participant Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "prevent_booking_cancellation",
        label: "Prevent Booking Cancellation Before",
        operators: [{ name: "<=", label: "Set Hours" }],
        inputType: "number",
      },
      {
        name: "admin_action",
        label: "Admin Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "admin_message",
        label: "Admin Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "user_action",
        label: "User Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "user_message",
        label: "User Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "public_action",
        label: "Public Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "public_message",
        label: "Public Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
    ],
    booking_time_restriction: [
      {
        name: "membership_type",
        label: "Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "primary_participant_membership_type",
        label: "Primary Participant Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
        mode: "multiple",
      },
      {
        name: "days_in_advance",
        label: "Days in Advance Limit",
        valueEditorType: "between",
        custom: {
          label_1: {
            name: "days",
            label: "Days in Advance",
            type: "number",
          },
          label_2: {
            name: "reset_hours",
            label: "Set Reset Hour",
            type: "time",
          },
        },
        operators: [{ name: "=", label: "skip" }],
        defaultValue: false,
      },
      {
        name: "booking_start_day",
        label: "Booking Available Day FROM",
        valueEditorType: "select",
        values: [
          { name: "Monday", label: "Monday" },
          { name: "Tuesday", label: "Tuesday" },
          { name: "Wednesday", label: "Wednesday" },
          { name: "Thursday", label: "Thursday" },
          { name: "Friday", label: "Friday" },
          { name: "Saturday", label: "Saturday" },
          { name: "Sunday", label: "Sunday" },
        ],
        operators: [{ name: "=", label: "From Day" }],
      },
      {
        name: "booking_end_day",
        label: "Booking Available Day TO",
        valueEditorType: "select",
        values: [
          { name: "Monday", label: "Monday" },
          { name: "Tuesday", label: "Tuesday" },
          { name: "Wednesday", label: "Wednesday" },
          { name: "Thursday", label: "Thursday" },
          { name: "Friday", label: "Friday" },
          { name: "Saturday", label: "Saturday" },
          { name: "Sunday", label: "Sunday" },
        ],
        operators: [{ name: "=", label: "To Day" }],
      },
      {
        name: "booking_start_time",
        label: "Booking Available Start Time",
        inputType: "time",
        operators: [{ name: "=", label: "Start At" }],
      },
      {
        name: "booking_end_time",
        label: "Booking Available End Time",
        inputType: "time",
        operators: [{ name: "=", label: "End At" }],
      },

      {
        name: "pricing_type",
        label: "Pricing Period Type",
        valueEditorType: "select",
        values: [
          { name: "Peak", label: "Peak" },
          { name: "Off-Peak", label: "Off-Peak" },
        ],
        operators: [{ name: "=", label: "Set Pricing Period Type as" }],
      },
      {
        name: "pricing",
        label: "Pricing Type",
        valueEditorType: "select",
        values: [
          { name: "No Pricing Fee", label: "No Pricing Fee" },
          { name: "Casual Court Fee", label: "Casual Court Fee" },
          { name: "Visitor Fee", label: "Visitor Fee" },
        ],
        operators: [{ name: "=", label: "Set Pricing Type as" }],
      },
      {
        name: "max_fee_per_hour",
        label: "Max Fee Per Hour",
        inputType: "number",
        operators: [{ name: "=", label: "Set Max Fee Per Hour" }],
      },
      {
        name: "pricing_per",
        label: "Set Custom Pricing",
        valueEditorType: "between",
        custom: {
          label_1: {
            name: "hours_greater_than",
            label: "Hours(s) >",
            type: "number",
          },
          label_2: {
            name: "set_price_to",
            label: "Set Price To",
            type: "number",
          },
          label_3: {
            name: "period_type",
            label: "Type",
            default: "All",
            values: [
              { name: "All", label: "All" },
              { name: "Peak", label: "Peak" },
              { name: "Off Peak", label: "Off Peak" },
            ],
          },
        },
        operators: [{ name: "=", label: "skip" }],
        defaultValue: false,
      },
      {
        name: "calculate_pricing",
        label: "Calculate Pricing?",
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
        operators: [{ name: "=", label: "calculate price when rule is true?" }],
      },
      {
        name: "allow_booking_at_same_time",
        label: "Allow Member to book overlapping timeslot on same location.",
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
        operators: [{ name: "Is Allowed?", label: "Is Allowed?" }],
      },
      {
        name: "allow_booking_when_accompanied",
        label: "Allow Member to book when accompanied by member type.",
        valueEditorType: "select",
        values: [
          { name: "Day", label: "Day" },
          { name: "Full", label: "Full" },
        ],
        operators: [{ name: "Accompanied By", label: "Accompanied By" }],
      },
      {
        name: "override_public_message",
        label: "Override Public Mesage",
        operators: [{ name: "override", label: "override" }],
      },
      {
        name: "show_alert",
        label: "Show Alert?",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
      },
      {
        name: "prevent_booking_cancellation",
        label: "Prevent Booking Cancellation Before",
        operators: [{ name: "<=", label: "Set Hours" }],
        inputType: "number",
      },
      {
        name: "admin_action",
        label: "Admin Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "admin_message",
        label: "Admin Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "user_action",
        label: "User Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "user_message",
        label: "User Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "public_action",
        label: "Public Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "public_message",
        label: "Public Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
    ],
    membership_booking_pricing_computation: [
      {
        name: "membership_type",
        label: "Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "primary_participant_membership_type",
        label: "Primary Participant Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
        mode: "multiple",
      },
      {
        name: "days_in_advance",
        label: "Days in Advance Limit",
        valueEditorType: "between",
        custom: {
          label_1: {
            name: "days",
            label: "Days in Advance",
            type: "number",
          },
          label_2: {
            name: "reset_hours",
            label: "Set Reset Hour",
            type: "time",
          },
        },
        operators: [{ name: "=", label: "skip" }],
        defaultValue: false,
      },
      {
        name: "booking_start_day",
        label: "Booking Available Day FROM",
        valueEditorType: "select",
        values: [
          { name: "Monday", label: "Monday" },
          { name: "Tuesday", label: "Tuesday" },
          { name: "Wednesday", label: "Wednesday" },
          { name: "Thursday", label: "Thursday" },
          { name: "Friday", label: "Friday" },
          { name: "Saturday", label: "Saturday" },
          { name: "Sunday", label: "Sunday" },
        ],
        operators: [{ name: "=", label: "From Day" }],
      },
      {
        name: "booking_end_day",
        label: "Booking Available Day TO",
        valueEditorType: "select",
        values: [
          { name: "Monday", label: "Monday" },
          { name: "Tuesday", label: "Tuesday" },
          { name: "Wednesday", label: "Wednesday" },
          { name: "Thursday", label: "Thursday" },
          { name: "Friday", label: "Friday" },
          { name: "Saturday", label: "Saturday" },
          { name: "Sunday", label: "Sunday" },
        ],
        operators: [{ name: "=", label: "To Day" }],
      },
      {
        name: "booking_start_time",
        label: "Booking Available Start Time",
        inputType: "time",
        operators: [{ name: "=", label: "Start At" }],
      },
      {
        name: "booking_end_time",
        label: "Booking Available End Time",
        inputType: "time",
        operators: [{ name: "=", label: "End At" }],
      },

      {
        name: "pricing_type",
        label: "Pricing Period Type",
        valueEditorType: "select",
        values: [
          { name: "Peak", label: "Peak" },
          { name: "Off-Peak", label: "Off-Peak" },
        ],
        operators: [{ name: "=", label: "Set Pricing Period Type as" }],
      },
      {
        name: "pricing",
        label: "Pricing Type",
        valueEditorType: "select",
        values: [
          { name: "No Pricing Fee", label: "No Pricing Fee" },
          { name: "Casual Court Fee", label: "Casual Court Fee" },
          { name: "Visitor Fee", label: "Visitor Fee" },
        ],
        operators: [{ name: "=", label: "Set Pricing Type as" }],
      },
      {
        name: "max_fee_per_hour",
        label: "Max Fee Per Hour",
        inputType: "number",
        operators: [{ name: "=", label: "Set Max Fee Per Hour" }],
      },
      {
        name: "pricing_per",
        label: "Set Custom Pricing",
        valueEditorType: "between",
        custom: {
          label_1: {
            name: "hours_greater_than",
            label: "Hours(s) >",
            type: "number",
          },
          label_2: {
            name: "set_price_to",
            label: "Set Price To",
            type: "number",
          },
          label_3: {
            name: "period_type",
            label: "Type",
            default: "All",
            values: [
              { name: "All", label: "All" },
              { name: "Peak", label: "Peak" },
              { name: "Off Peak", label: "Off Peak" },
            ],
          },
        },
        operators: [{ name: "=", label: "skip" }],
        defaultValue: false,
      },
      {
        name: "calculate_pricing",
        label: "Calculate Pricing?",
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
        operators: [{ name: "=", label: "calculate price when rule is true?" }],
      },
      {
        name: "allow_booking_at_same_time",
        label: "Allow Member to book overlapping timeslot on same location.",
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
        operators: [{ name: "Is Allowed?", label: "Is Allowed?" }],
      },
      {
        name: "allow_booking_when_accompanied",
        label: "Allow Member to book when accompanied by member type.",
        valueEditorType: "select",
        values: [
          { name: "Day", label: "Day" },
          { name: "Full", label: "Full" },
        ],
        operators: [{ name: "Accompanied By", label: "Accompanied By" }],
      },
      {
        name: "override_public_message",
        label: "Override Public Mesage",
        operators: [{ name: "override", label: "override" }],
      },
      {
        name: "show_alert",
        label: "Show Alert?",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
      },
      {
        name: "prevent_booking_cancellation",
        label: "Prevent Booking Cancellation Before",
        operators: [{ name: "<=", label: "Set Hours" }],
        inputType: "number",
      },
    ],
    alerts: [
      {
        name: "membership_type",
        label: "Membership Type",
        valueEditorType: "select",
        values: this.memberTypes,
        operators: [{ name: "=", label: "=" }],
      },
      // {
      //   name: "primary_participant_membership_type",
      //   label: "Primary Participant Membership Type",
      //   valueEditorType: "select",
      //   values: this.memberTypes,
      //   operators: [{ name: "=", label: "=" }],
      //   mode: "multiple",
      // },
      // {
      //   name: "days_in_advance",
      //   label: "Days in Advance Limit",
      //   valueEditorType: "between",
      //   custom: {
      //     label_1: {
      //       name: "days",
      //       label: "Days in Advance",
      //       type: "number",
      //     },
      //     label_2: {
      //       name: "reset_hours",
      //       label: "Set Reset Hour",
      //       type: "time",
      //     },
      //   },
      //   operators: [{ name: "=", label: "skip" }],
      //   defaultValue: false,
      // },
      {
        name: "booking_start_day",
        label: "Booking Restricted Day FROM",
        valueEditorType: "select",
        values: [
          { name: "Monday", label: "Monday" },
          { name: "Tuesday", label: "Tuesday" },
          { name: "Wednesday", label: "Wednesday" },
          { name: "Thursday", label: "Thursday" },
          { name: "Friday", label: "Friday" },
          { name: "Saturday", label: "Saturday" },
          { name: "Sunday", label: "Sunday" },
        ],
        operators: [{ name: "=", label: "From Day" }],
      },
      {
        name: "booking_end_day",
        label: "Booking Restricted Day TO",
        valueEditorType: "select",
        values: [
          { name: "Monday", label: "Monday" },
          { name: "Tuesday", label: "Tuesday" },
          { name: "Wednesday", label: "Wednesday" },
          { name: "Thursday", label: "Thursday" },
          { name: "Friday", label: "Friday" },
          { name: "Saturday", label: "Saturday" },
          { name: "Sunday", label: "Sunday" },
        ],
        operators: [{ name: "=", label: "To Day" }],
      },
      {
        name: "booking_start_time",
        label: "Booking Restricted Start Time",
        inputType: "time",
        operators: [{ name: "=", label: "Start At" }],
      },
      {
        name: "booking_end_time",
        label: "Booking Restricted End Time",
        inputType: "time",
        operators: [{ name: "=", label: "End At" }],
      },
      {
        name: "allow_other_participant_in_booking",
        label: "Allow other participant in booking?",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ],
      },

      // {
      //   name: "pricing_type",
      //   label: "Pricing Period Type",
      //   valueEditorType: "select",
      //   values: [
      //     { name: "Peak", label: "Peak" },
      //     { name: "Off-Peak", label: "Off-Peak" },
      //   ],
      //   operators: [{ name: "=", label: "Set Pricing Period Type as" }],
      // },
      // {
      //   name: "pricing",
      //   label: "Pricing Type",
      //   valueEditorType: "select",
      //   values: [
      //     { name: "No Pricing Fee", label: "No Pricing Fee" },
      //     { name: "Casual Court Fee", label: "Casual Court Fee" },
      //     { name: "Visitor Fee", label: "Visitor Fee" },
      //   ],
      //   operators: [{ name: "=", label: "Set Pricing Type as" }],
      // },
      // {
      //   name: "max_fee_per_hour",
      //   label: "Max Fee Per Hour",
      //   inputType: "number",
      //   operators: [{ name: "=", label: "Set Max Fee Per Hour" }],
      // },
      // {
      //   name: "pricing_per",
      //   label: "Set Custom Pricing",
      //   valueEditorType: "between",
      //   custom: {
      //     label_1: {
      //       name: "hours_greater_than",
      //       label: "Hours(s) >",
      //       type: "number",
      //     },
      //     label_2: {
      //       name: "set_price_to",
      //       label: "Set Price To",
      //       type: "number",
      //     },
      //     label_3: {
      //       name: "period_type",
      //       label: "Type",
      //       default: "All",
      //       values: [
      //         { name: "All", label: "All" },
      //         { name: "Peak", label: "Peak" },
      //         { name: "Off Peak", label: "Off Peak" },
      //       ],
      //     },
      //   },
      //   operators: [{ name: "=", label: "skip" }],
      //   defaultValue: false,
      // },
      // {
      //   name: "calculate_pricing",
      //   label: "Calculate Pricing?",
      //   valueEditorType: "select",
      //   values: [
      //     { name: "Yes", label: "Yes" },
      //     { name: "No", label: "No" },
      //   ],
      //   operators: [{ name: "=", label: "calculate price when rule is true?" }],
      // },
      // {
      //   name: "allow_booking_at_same_time",
      //   label: "Allow Member to book overlapping timeslot on same location.",
      //   valueEditorType: "select",
      //   values: [
      //     { name: "Yes", label: "Yes" },
      //     { name: "No", label: "No" },
      //   ],
      //   operators: [{ name: "Is Allowed?", label: "Is Allowed?" }],
      // },
      // {
      //   name: "allow_booking_when_accompanied",
      //   label: "Allow Member to book when accompanied by member type.",
      //   valueEditorType: "select",
      //   values: [
      //     { name: "Day", label: "Day" },
      //     { name: "Full", label: "Full" },
      //   ],
      //   operators: [{ name: "Accompanied By", label: "Accompanied By" }],
      // },
      {
        name: "override_public_message",
        label: "Override Public Mesage",
        operators: [{ name: "override", label: "override" }],
      },
      // {
      //   name: "show_alert",
      //   label: "Show Alert?",
      //   operators: [{ name: "=", label: "=" }],
      //   valueEditorType: "select",
      //   values: [
      //     { name: "Yes", label: "Yes" },
      //     { name: "No", label: "No" },
      //   ],
      // },
      // {
      //   name: "prevent_booking_cancellation",
      //   label: "Prevent Booking Cancellation Before",
      //   operators: [{ name: "<=", label: "Set Hours" }],
      //   inputType: "number",
      // },
      {
        name: "admin_action",
        label: "Admin Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "admin_message",
        label: "Admin Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "user_action",
        label: "User Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "user_message",
        label: "User Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
      {
        name: "public_action",
        label: "Public Action",
        valueEditorType: "checkbox",
        operators: [{ name: "=", label: "=" }],
        valueEditorType: "select",
        values: [
          { name: "BLOCK", label: "BLOCK" },
          { name: "WARN", label: "WARN" },
        ],
      },
      {
        name: "public_message",
        label: "Public Message",
        inputType: "text",
        operators: [{ name: "=", label: "=" }],
      },
    ],
  };

  componentDidMount() {
    this.props.showHideLoader(true);
    let params = { limit: 100, is_active: 1 };

    this.props.getRules({}, this.props.authUser.sessionToken);

    this.props.getLocationList(params, this.props.authUser.sessionToken);

    this.setState({
      requestCount: 2,
    });

    this.props.displayBreadcrumbs("Dashboard / Administrator / Rules");

    this.initRuleTypes();
  }

  initRuleTypes() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "Booking Cancellation",
        value: "Booking Cancellation",
      },
      {
        checked: false,
        disabled: false,
        text: "Booking Time Restriction",
        value: "Booking Time Restriction",
      },
      {
        checked: false,
        disabled: false,
        text: "Booking",
        value: "Booking",
      },
      {
        checked: false,
        disabled: false,
        text: "Membership Booking Pricing Computation",
        value: "Membership Booking Pricing Computation",
      },
      {
        checked: false,
        disabled: false,
        text: "Alerts",
        value: "Alerts",
      },
    ];

    this.setState({ ruleTypes: options });
  }

  componentDidUpdate(prevProps, prevState) {
    this.getLocationResult(prevProps, prevState);
    this.getRuleResult(prevProps, prevState);
    if (this.state.requestCount == 0) {
      this.props.showHideLoader(false);
    }
  }

  getLocationResult(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
      this.props.getLocationListSuccessResponse
    ) {
      let resultContainer = [];
      this.props.getLocationListSuccessResponse.data.locations.results.forEach(
        (element) => {
          resultContainer.push({
            text: element.name,
            value: element.id,
          });
        }
      );
      this.setState({
        locationResultContainer: resultContainer,
        requestCount: this.state.requestCount - 1,
      });
    }
  }

  getRuleResult(prevProps, prevState) {
    let response = this.props.ruleResponse;
    if (prevProps.ruleResponse !== response) {
      if (response.status >= 400) {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
      } else if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );

        this.props.getRules({}, this.props.authUser.sessionToken);
        this.toggle();
      } else {
        this.setState({
          resultContainer: response.data,
        });
      }
      this.setState({
        requestCount: this.state.requestCount - 1,
      });
    }
  }

  AntDActionElement(event) {
    return (
      <Button
        type="primary"
        className={event.className}
        title={event.title}
        onClick={(e) => {
          event.handleOnClick(e);
        }}
      >
        {event.label}
      </Button>
    );
  }

  AntDValueSelector(event) {
    let opt = event.options[0];
    if (opt && typeof opt.label != "undefined" && opt.label == "skip") {
      return "";
    }
    return (
      <Select
        className={event.className}
        value={event.value}
        onChange={(v) => event.handleOnChange(v)}
      >
        {event.options.map((option) => {
          const key = option.id ? `key-${option.id}` : `key-${option.name}`;
          return (
            <Option key={key} value={option.name}>
              {option.label}
            </Option>
          );
        })}
      </Select>
    );
  }

  AntDNotToggle(event) {
    return (
      <Checkbox
        className={event.className}
        onChange={(e) => event.handleOnChange(e.target.checked)}
        checked={!!event.checked}
      >
        Not
      </Checkbox>
    );
  }

  AntDValueEditor(event) {
    let uniqueUUid = new Date().getTime();
    let {
      operator,
      className,
      handleOnChange,
      value,
      values,
      title,
      type,
      inputType,
      fieldData,
    } = event;
    if (operator === "null" || operator === "notNull") {
      return null;
    }
    switch (type) {
      case "between":
        let val1 = "";
        let val2 = "";
        let val3 = "";
        if (value.constructor.name == "Object") {
          val1 = value[fieldData.custom.label_1.name];
          val2 = value[fieldData.custom.label_2.name];
          if (typeof fieldData.custom.label_3 != "undefined") {
            val3 = value[fieldData.custom.label_3.name];
            if (val3 == "" || val3 == undefined) {
              val3 = fieldData.custom.label_3.default;
            }
          }
        }
        let callback = (val, fieldName, className) => {
          let data = {};
          let inputs = document.querySelectorAll("." + className);
          for (var i = 0; i < inputs.length; ++i) {
            var input = inputs[i];
            if (typeof input.dataset.json != "undefined") {
              let dt = JSON.parse(input.dataset.json);
              data = {
                ...data,
                ...dt,
              };
            }
          }

          data[fieldName] = val;
          for (var i = 0; i < inputs.length; ++i) {
            var input = inputs[i];
            input.dataset.json = JSON.stringify(data);
          }
          handleOnChange(data);
        };

        let uniqueClassName = fieldData.name + uniqueUUid;
        return (
          <span>
            <Input
              name={fieldData.custom.label_1.name}
              className={uniqueClassName + " " + className + " between"}
              addonBefore={fieldData.custom.label_1.label}
              type={fieldData.custom.label_1.type}
              onChange={(e) => {
                callback(
                  e.target.value,
                  fieldData.custom.label_1.name,
                  uniqueClassName
                );
              }}
              value={val1}
            />
            <Input
              name={fieldData.custom.label_2.name}
              addonBefore={fieldData.custom.label_2.label}
              className={fieldData.name + " " + className + " between"}
              type={fieldData.custom.label_2.type}
              onChange={(e) => {
                callback(
                  e.target.value,
                  fieldData.custom.label_2.name,
                  uniqueClassName
                );
              }}
              value={val2}
            />
            {fieldData.name == "pricing_per" && (
              <Select
                name={fieldData.custom.label_3.name}
                className={uniqueClassName + " " + className + " between"}
                onChange={(e) => {
                  callback(e, fieldData.custom.label_3.name, uniqueClassName);
                }}
                defaultValue={
                  val3 != "" ? val3 : fieldData.custom.label_3.default
                }
                multiple
              >
                {fieldData.custom.label_3.values.map((v) => (
                  <Option key={v.name} value={v.name}>
                    {v.label}
                  </Option>
                ))}
              </Select>
            )}
          </span>
        );
      case "select":
        return (
          <Select
            className={className}
            mode={fieldData.mode}
            onChange={(v) => handleOnChange(v)}
            value={value}
            multiple
          >
            {values.map((v) => (
              <Option key={v.name} value={v.name}>
                {v.label}
              </Option>
            ))}
          </Select>
        );

      case "checkbox":
        return (
          <Checkbox
            type="checkbox"
            className={className}
            onChange={(e) => handleOnChange(e.target.checked)}
            checked={!!value}
          />
        );

      case "radio":
        return (
          <span className={className} title={title}>
            {values.map((v) => (
              <Radio
                key={v.name}
                value={v.name}
                checked={value === v.name}
                onChange={(e) => handleOnChange(e.target.value)}
              >
                {v.label}
              </Radio>
            ))}
          </span>
        );

      default:
        return (
          <Input
            type={inputType || "text"}
            value={value}
            title={title}
            className={className}
            onChange={(e) => handleOnChange(e.target.value)}
          />
        );
    }
  }

  render() {
    return (
      <div className="with-antd">
        {this.renderRulesTable()}
        {this.renderRuleForm()}
      </div>
    );
  }

  submitHandler = (event) => {
    const {
      ruleId,
      name,
      description,
      isActive,
      public_message,
      locationIds,
      ruleQuery,
      isEdit,
      typeId,
      ruleTypeOrig,
    } = this.state;
    event.preventDefault();
    this.setState({ wasValidated: "was-validated", formCleared: false });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      this.setState({
        requestCount: 1,
      });
      let body = {
        name: name,
        description: description,
        isActive: isActive,
        publicMessage: public_message,
        locations: locationIds,
        ruleJson: ruleQuery,
        typeId: typeId,
        ruleType: ruleTypeOrig,
      };
      if (isEdit) {
        return this.props.updateRule(
          body,
          ruleId,
          this.props.authUser.sessionToken
        );
      }
      return this.props.insertRule(body, this.props.authUser.sessionToken);
    }
  };

  changeHandler(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  onIsActiveLocChange = (evt) => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  showManageRuleModal = (showModal, source) => {
    let { locationResultContainer } = this.state;

    if (source !== null) {
      let ruleType = source.type.name.toLowerCase().replace(/\s/g, "_");
      let locationIds = [];
      _.map(source.locations, function (locationId) {
        locationIds.push(locationId.toString());
      });
      this.setState({
        showManageRuleModal: showModal,
        isEdit: true,
        name: source.name,
        description: source.description,
        public_message: source.public_message,
        isActive: parseInt(source.is_active) == 1,
        ruleId: source.id,
        ruleQuery: source.rule_json,
        locationIds: locationIds,
        ruleType: ruleType,
        ruleTypeOrig: source.type.name,
      });
    } else {
      if (!showModal) {
        this.resetForm();
      }
      this.setState({ showManageRuleModal: showModal, isEdit: false });
    }
  };

  renderRulesTable() {
    const { storeUserAccess } = this.props;
    const { resultContainer } = this.state;
    let rows = [];

    if (typeof resultContainer !== "undefined") {
      rows = _.chain(resultContainer)
        .map((result, index) => {
          return {
            heading0: result.name,
            heading5: result.type.name.toUpperCase(),
            heading1: result.description,
            heading2: result.public_message,
            heading3: parseInt(result.is_active) == 1 ? "Yes" : "No",
            heading4: validateAccess(
              storeUserAccess,
              "LocationResources",
              "add"
            ) && (
              <div>
                <MDBBtn
                  color="primary"
                  size="sm"
                  className="float-right"
                  onClick={() => {
                    this.showManageRuleModal(true, result);
                  }}
                >
                  Manage Rule
                </MDBBtn>
              </div>
            ),
          };
        })
        .value();
    }
    let data = {
      columns: [
        {
          label: "Name",
          field: "heading0",
          sort: "asc",
        },
        {
          label: "Rule Type",
          field: "heading5",
          sort: "asc",
        },
        {
          label: "Description",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Customer - Public Message",
          field: "heading2",
          sort: "asc",
        },
        {
          label: "Is Active",
          field: "heading3",
          sort: "asc",
        },
        {
          label: "",
          field: "heading4",
          sort: "asc",
        },
      ],
      rows: [],
    };

    data.rows = rows;

    return (
      <div>
        <MDBContainer>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <h3 className="mt-3">Rule Management</h3>
            </MDBCol>
            {validateAccess(storeUserAccess, "LocationResources", "add") && (
              <MDBCol md="6">
                <MDBBtn
                  color="primary"
                  onClick={() => this.showManageRuleModal(true, null)}
                  className="float-right"
                >
                  Add Rule
                </MDBBtn>
              </MDBCol>
            )}
          </MDBRow>
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
        </MDBContainer>
      </div>
    );
  }

  toggle = () => {
    this.setState({
      showManageRuleModal: !this.state.showManageRuleModal,
    });
    this.resetForm();
  };

  resetForm() {
    this.setState({
      isEdit: false,
      isActive: false,
      name: "",
      description: "",
      public_message: "",
      locationIds: [],
      ruleQuery: "",
      ruleType: "booking_time_restriction",
      ruleTypeOrig: "Booking Time Restriction",
    });
  }

  ruleTypeChangeHandler = (evt) => {
    this.setState({
      ruleTypeOrig: evt.target.value,
      [evt.target.name]: evt.target.value.toLowerCase().replace(/\s/g, "_"),
      ruleQuery: "",
    });
  };

  renderRuleForm() {
    const {
      name,
      description,
      isEdit,
      showManageRuleModal,
      isActive,
      public_message,
      locationIds,
      ruleQuery,
      ruleType,
      ruleTypes,
      ruleTypeOrig,
    } = this.state;
    let { locationResultContainer } = this.state;
    let fields = this.preparedFields[ruleType];
    locationResultContainer.forEach((option, index) => {
      locationResultContainer[index].checked = locationIds.includes(
        option.value
      );
    });
    return (
      <div>
        <MDBModal isOpen={showManageRuleModal} size="lg" toggle={this.toggle}>
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>
              Manage Rule - <strong>{name}</strong>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12">
                  <span className="text-danger">*</span> &nbsp;
                  <label htmlFor="idName" className="grey-text">
                    Select Location(s)
                  </label>
                  <MDBSelect
                    id="test"
                    required
                    multiple
                    options={locationResultContainer}
                    placeholder="Select location(s)"
                    getValue={(values) => {
                      this.setState({
                        locationIds: values,
                      });
                    }}
                    style={{ marginTop: "unset" }}
                  />
                  <input
                    value={locationIds}
                    style={{ display: "none" }}
                    type="text"
                    id={"input-test"}
                    className="CatalogPhotoGridProductModal hide-input-form form-control"
                    required
                  />
                  <div className="invalid-feedback">
                    Please select one or more location(s)
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <span className="text-danger">*</span> &nbsp;
                  <label htmlFor="idName" className="grey-text">
                    Rule Type
                  </label>
                  <select
                    name="ruleType"
                    className="form-control"
                    required
                    defaultValue={ruleTypeOrig}
                    onChange={this.ruleTypeChangeHandler}
                  >
                    {ruleTypes.map((ruleType) => (
                      <option value={ruleType.value}>{ruleType.value}</option>
                    ))}
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <span className="text-danger">*</span> &nbsp;
                  <label htmlFor="idName" className="grey-text">
                    Name
                  </label>
                  <input
                    value={name}
                    name="name"
                    onChange={this.changeHandler}
                    type="text"
                    id="idName"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <span className="text-danger">*</span> &nbsp;
                  <label htmlFor="idName" className="grey-text">
                    Description
                  </label>
                  <input
                    value={description}
                    name="description"
                    onChange={this.changeHandler}
                    type="text"
                    id="idName"
                    className="form-control"
                    placeholder="Description"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the description
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <span className="text-danger">*</span> &nbsp;
                  <label htmlFor="idName" className="grey-text">
                    Customer - Public Message
                  </label>
                  <textarea
                    value={public_message}
                    name="public_message"
                    onChange={this.changeHandler}
                    type="text"
                    id="idVal"
                    rows={10}
                    className="form-control"
                    placeholder="Customer - Public Message"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the Customer - Public Message
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkActive"
                      defaultChecked={isActive}
                      onChange={this.onIsActiveLocChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkActive"
                      style={{
                        fontWeight: "bold",
                        marginTop: "35px !important",
                      }}
                    >
                      Active
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <QueryBuilder
                    query={ruleQuery}
                    controlElements={this.controlElements}
                    fields={fields}
                    onQueryChange={this.logQuery}
                    showNotToggle={false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showManageRuleModal(false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  logQuery(query) {
    this.setState({
      ruleQuery: query,
    });
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    modal: state.modal,
    ruleResponse: state.ruleResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    getLocationList,
    getRules,
    insertRule,
    updateRule,
    deleteRule,
    showNotification,
  })(ManageRules)
);
