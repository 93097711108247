import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
  MDBBtnGroup
} from "mdbreact";
import ReactPaginate from 'react-paginate';
import { connect } from "react-redux";
import {
  getAuditLogs,
} from "../../../actions/auditAction";
import { showHideLoader } from "../../../actions";
import Spinner from "../shared/Loader";
import SectionContainer from "../shared/sectionContainer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs } from "../../../actions";
import _ from "lodash";
import moment from "moment";

class ReportAuditLog extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    loader: true,
    filterLoaded: false,
    user: "",
    type: ""
  };

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Start Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  endDatePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select End Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  componentDidMount() {
    const { query } = this.state;

    this.props.showHideLoader(true);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Audit Logs"
    );

    let params = {};

    this.initLimitOptions();

    this.props.getAuditLogs(params, this.props.authUser.sessionToken);
  }

  render() {
    return <div>
      {this.renderTable()}
    </div>;
  }

  createTypeOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Log Type",
      value: "",
    });
    let response = this.props.getAuditLogsSuccessResponse.auditLogs;

    if (response.search_aggs !== undefined && response.search_aggs.type !== undefined) {
      let buckets = response.search_aggs.type
        .buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  createUserOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select User",
      value: "",
    });
    let response = this.props.getAuditLogsSuccessResponse.auditLogs;
    if (response.search_aggs !== undefined && response.search_aggs.user !== undefined) {
      let buckets = response.search_aggs.user
        .buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  renderFilters() {
    const { limitOptions, typeOptions, userOptions } = this.state;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Audit Logs Report</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectLimit}
              value={this.state.limit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueType}
              value={this.state.type}
              options={typeOptions}
              label="Filter By Type"
            />
          </MDBCol>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueUser}
              value={this.state.user}
              options={userOptions}
              label="Filter By User"
            />
          </MDBCol>
          <MDBCol md="6">
            <div className="float-right">
              <MDBInput
                label="Search audit log by keyword(s)"
                name="query"
                onChange={this.changeHandler}
                style={{ width: "430px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow >
          <MDBCol md="3">
            <MDBInput
              outline
              type="date"
              color="primary"
              label="Date Start"
            />
          </MDBCol>
          <MDBCol md="3">
            <MDBInput
              outline
              type="date"
              color="primary"
              label="Date End"
            />
          </MDBCol>
        </MDBRow> */}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.getAuditLogsSuccessResponse !== this.props.getAuditLogsSuccessResponse) {
      this.setState({
        resultContainer: this.props.getAuditLogsSuccessResponse,
        page: this.props.getAuditLogsSuccessResponse.auditLogs.page
      });

      this.props.showHideLoader(false);
      if (!this.state.filterLoaded) {
        this.setState({
          filterLoaded: true,
          typeOptions: this.createTypeOptions(),
          userOptions: this.createUserOptions()
        })
      }
    }
  }

  renderTable() {
    const { resultContainer, page } = this.state;

    const data = {
      columns: [
        {
          label: "User",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Type",
          field: "heading2",
          sort: "asc",
        },
        {
          label: "Message",
          field: "heading3",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading4",
          sort: "asc",
        }
      ],
    };

    let rows = [];
    if (typeof resultContainer.auditLogs !== 'undefined') {
      rows = _.chain(resultContainer.auditLogs.results)
        .map((result, index) => {
          return {
            heading1: result.user.name,
            heading2: result.type,
            heading3: result.message,
            heading4: moment(result.created).local().format("DD/MM/YYYY HH:mm:ss"),
            //heading5: moment(result.updated).local().format("DD/MM/YYYY"),
          };
        })
        .value();
      data["rows"] = rows;
      return (
        <MDBContainer>
          {this.renderFilters()}
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
          <MDBRow>
            <MDBCol md="12">
              {this.renderTotalResult()}
              <div className="float-right">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={resultContainer.auditLogs.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }
    return <div></div>;
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  getValueUser = (value) => {
    this.setState({ user: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  getValueType = (value) => {
    this.setState({ type: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (typeof resultContainer.auditLogs !== 'undefined') {
      return (
        <span>
          Showing {resultContainer.auditLogs.limit * resultContainer.auditLogs.page} of {resultContainer.auditLogs.total} result
        </span>
      );
    }
    return <span></span>;
  }

  doFilter() {
    const {
      startDateFilter,
      endDateFilter,
      status,
      page,
      limit,
      query,
      type,
      user
    } = this.state;

    this.props.showHideLoader(true);

    let params = {};
    if (startDateFilter) {
      params["startDate"] = moment(startDateFilter).utc().format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["endDate"] = moment(endDateFilter).utc().format("YYYY-MM-DD");
    }
    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (type.length > 0) {
      params["type"] = type;
    }

    if (user.length > 0) {
      params["user"] = user;
    }

    if (query !== undefined) {
      params["query"] = query;
    }

    this.props.getAuditLogs(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    this.debouncedDoFilter();
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage
    });
    this.debouncedDoFilter();
  };

}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getAuditLogsSuccessResponse: state.getAuditLogsSuccessResponse
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getAuditLogs,
    showHideLoader
  })(ReportAuditLog)
);
