import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
  MDBBtnGroup,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getBookingReports } from "../../../actions/bookingAction";
import { showHideLoader } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs } from "../../../actions";
import _ from "lodash";
import moment from "moment";
import ReactExport from "react-data-export";
import { bulkPayCustomerPaymentPending } from "../../../actions/paymentAction";

class PaymentPendingReport extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: "",
    endDateFilter: "",
    limitOptions: [],
    paymentOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    loader: true,
    filterLoaded: false,
    user: "",
    type: "",
    paymentPendingBookings: [],
    excelPaymentPendingReports: [],
    selectedBulkPaymentPendingBookingRefNo: [], // List of pending that will be paid
    isExportAllData: 0, //False will export curent page, True will export all data
    exportDataSelectOptions: [],
  };

  constructor(props) {
    super(props);
    this.state.startDateFilter = new Date(
      moment().startOf("month").format("YYYY-MM-DD")
    );
    this.state.endDateFilter = new Date(
      moment().endOf("month").format("YYYY-MM-DD")
    );

    if (typeof props.isFromCustomerPageModal !== "undefined") {
      //Default is 50 if its payment pending report
      this.state.limit = "50";
    }
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  initExportTypeOption() {
    let options = [
      {
        checked: true,
        disabled: false,
        text: "Current page",
        value: 0,
      },
      {
        checked: false,
        disabled: false,
        text: "All records",
        value: 1,
      },
    ];

    this.setState({ exportDataSelectOptions: options });
  }

  exportDataSelectOptions;

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  componentDidMount() {
    this.props.showHideLoader(true);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Payments"
    );

    //  //Default for payment Pending report
    //  if (isFromCustomerPageModal) {
    //   params["limit"] = "50";
    //   params["page"] = "1";
    // }

    this.initLimitOptions();
  }

  componentWillUnmount() {
    this.props.bulkPayCustomerPaymentPending([]);
  }

  handlePaymentSwitchChange = (bookingReferenceNumber) => () => {
    let { selectedBulkPaymentPendingBookingRefNo } = this.state;

    if (
      selectedBulkPaymentPendingBookingRefNo.includes(bookingReferenceNumber)
    ) {
      // If the booking reference is in list remove it

      selectedBulkPaymentPendingBookingRefNo =
        selectedBulkPaymentPendingBookingRefNo.filter(function (item) {
          return item !== bookingReferenceNumber;
        });
    } else {
      // otherwise add it

      selectedBulkPaymentPendingBookingRefNo.push(bookingReferenceNumber);
    }

    this.setState({ selectedBulkPaymentPendingBookingRefNo });

    this.props.bulkPayCustomerPaymentPending(
      selectedBulkPaymentPendingBookingRefNo
    );
  };

  renderExcelDataExport() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const { excelPaymentPendingReports } = this.state;
    const excelDataSet = this.prepareExcelDataSet(excelPaymentPendingReports);
    return (
      <ExcelFile
        element={
          <MDBBtn color="primary" type="button" size="md">
            Export data
          </MDBBtn>
        }
        filename="Payment Pending Bookings"
      >
        <ExcelSheet dataSet={excelDataSet} name="Payment Pending" />
      </ExcelFile>
    );
  }

  prepareExcelDataSet = (excelPaymentPendingReports) => {
    let excelDataSet = [
      {
        columns: [
          {
            title: "Customer Name",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 150 },
          },

          {
            title: "Customer Email",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 170 },
          },
          {
            title: "Reference Number",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },
          {
            title: "Booking Date Time",
            style: {
              font: { sz: "12", bold: true },
              width: { wpx: 220 },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 150 },
          },
          {
            title: "Duration",
            style: {
              font: { sz: "12", bold: true },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 120 },
          },

          {
            title: "Amount",
            style: {
              font: { sz: "12", bold: true },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 100 },
          },
        ],
        data: excelPaymentPendingReports.map((booking) => {
          const fullName = booking.customer_full_name;
          const email = booking.customer_email;

          const bookingReference = booking.booking_reference;

          const reservationDate = booking.booking_date;

          const reservationTime = booking.duration;

          const totalAmount = booking.amount;

          return [
            { value: fullName, style: { font: { sz: "12" } } },
            { value: email, style: { font: { sz: "12" } } },
            { value: bookingReference, style: { font: { sz: "12" } } },
            { value: reservationDate, style: { font: { sz: "12" } } },
            { value: reservationTime, style: { font: { sz: "12" } } },
            { value: totalAmount, style: { font: { sz: "12" } } },
          ];
        }),
      },
    ];

    return excelDataSet;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getBookingReportResponse !==
        this.props.getBookingReportResponse &&
      this.props.getBookingReportResponse.action === "GET_BOOKINGS_REPORT"
    ) {
      const responseData = this.props.getBookingReportResponse.data;

      if (responseData.data) {
        const paymentPendingBookings = responseData.data.bookings.results;

        const formattedPaymentPendingBookings = paymentPendingBookings.map(
          (booking) => {
            return {
              customer_full_name: booking.customer
                ? booking.customer.fullname
                : "",
              customer_email: booking.customer ? booking.customer.email : "",
              booking_reference: booking.booking_reference,
              booking_date: moment(booking.start_time)
                .local("Australia/Sydney")
                .format("YYYY-MM-DD"),
              duration:
                moment(booking.start_time)
                  .local("Australia/Sydney")
                  .format("HH:mm") +
                " - " +
                moment(booking.end_time)
                  .local("Australia/Sydney")
                  .format("HH:mm"),
              amount: booking.transaction ? booking.transaction.totalAmount : 0,

              location_name: booking.location ? booking.location.name : "",
              court_field: booking.resource ? booking.resource.name : "",
            };
          }
        );

        let statesToUpdate = {
          excelPaymentPendingReports: formattedPaymentPendingBookings,
        };

        if (!responseData.data.isExportExcelRequest) {
          // Update only  if not excel export request type
          statesToUpdate = {
            ...statesToUpdate,
            paymentPendingBookings: formattedPaymentPendingBookings,
            resultContainer: {
              totalPage: responseData.data.bookings.totalPage,
              returnedRangedFrom: responseData.data.bookings.returnedRangedFrom,
              returnedRangedTo: responseData.data.bookings.returnedRangedTo,
              returnedTotal: responseData.data.bookings.returnedTotal,
              total: responseData.data.bookings.total,
            },
            page: responseData.data.bookings.page,
          };
        }

        this.setState(statesToUpdate);
      }

      this.props.showHideLoader(false);
    }
  }

  renderPaymentReports(paymentPendingBookings) {
    let rows = _.chain(paymentPendingBookings)
      .map((result, index) => {
        let data = {};
        if (this.props.isFromCustomerPageModal) {
          data["heading0"] = (
            <div className="custom-control custom-checkbox text-center">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`paymentPendingSwitch_${result.booking_reference}`}
                // checked={isSelectedPaymentSwitch}
                onChange={this.handlePaymentSwitchChange(
                  result.booking_reference
                )}
              />

              <label
                className="custom-control-label"
                htmlFor={`paymentPendingSwitch_${result.booking_reference}`}
              >
                {" "}
              </label>
            </div>
          );
        }

        data = {
          ...data,
          heading1: this.props.isFromCustomerPageModal
            ? result.location_name
            : result.customer_full_name,
          heading2: this.props.isFromCustomerPageModal
            ? result.court_field
            : result.customer_email,
          heading3: result.booking_reference,
          heading4: result.booking_date,
          heading5: result.duration,
          heading6: "$" + result.amount.toFixed(2),
        };

        return data;
      })
      .value();
    return rows;
  }

  renderPaymentReportColumns() {
    let columns = [];

    if (this.props.isFromCustomerPageModal) {
      columns.push({
        label: "",
        field: "heading0",
        sort: "asc",
      });
    }

    columns = [
      ...columns,
      {
        label: this.props.isFromCustomerPageModal
          ? "Location"
          : "Customer Name",
        field: "heading1",
        sort: "asc",
      },
      {
        label: this.props.isFromCustomerPageModal
          ? "Court/Field"
          : "Customer Email",
        field: "heading2",
        sort: "asc",
      },
      {
        label: "Reference Number",
        field: "heading3",
        sort: "asc",
      },
      {
        label: "Booking Date",
        field: "heading4",
        sort: "asc",
      },
      {
        label: "Duration",
        field: "heading5",
        sort: "asc",
      },
      {
        label: "Amount",
        field: "heading6",
        sort: "asc",
      },
    ];

    return columns;
  }

  getValueOfSelectLimit = (value) => {
    value = value.length > 0 ? value[0] : "";
    this.setState({ limit: value, page: 1, isExportAllData: 0 });
    this.initExportTypeOption();
    if (value != "") {
      this.debouncedDoFilter();
    }
  };

  getValueOfSelectExportType = (value) => {
    value = value.length > 0 ? value[0] : false;

    if (value === 1) {
      this.debouncedDoFilter();
    }

    this.setState({ isExportAllData: value });
  };

  doFilter() {
    const { startDateFilter, endDateFilter, page, limit, isExportAllData } =
      this.state;
    const { customerId } = this.props;

    this.props.showHideLoader(true);

    let params = {};

    if (startDateFilter) {
      params["start_date"] = moment(startDateFilter).format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["end_date"] = moment(endDateFilter).format("YYYY-MM-DD");
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (customerId && customerId > 0) {
      params["customerId"] = parseInt(customerId);
    }

    params["is_paid"] = 0;
    params["is_booked"] = 1;

    params["isExportAllData"] = isExportAllData;

    return this.props.getBookingReports(
      params,
      this.props.authUser.sessionToken,
      false
    );
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  render() {
    const { paymentPendingBookings, limitOptions, resultContainer } =
      this.state;

    const data = {
      columns: this.renderPaymentReportColumns(),
    };

    data.rows = this.renderPaymentReports(paymentPendingBookings);

    return (
      <MDBContainer>
        <div>
          {!this.props.isFromCustomerPageModal && (
            <MDBRow className="mb-4">
              <MDBCol md="6">
                <h3 className="mt-3">Payment Pending Report</h3>
              </MDBCol>
            </MDBRow>
          )}

          <MDBRow>
            <MDBCol md="3">
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select Start Date"
                  selected={this.state.startDateFilter}
                  maxDate={this.state.endDateFilter}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.startDateFilterOnChange}
                />
              </span>
            </MDBCol>
            <MDBCol md="3">
              {" "}
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select End Date"
                  selected={this.state.endDateFilter}
                  minDate={this.state.startDateFilter}
                  onChange={this.endDateFilterOnChange}
                  dateFormat="dd/MM/yyyy"
                />
              </span>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="2">
              <MDBSelect
                outline
                color="primary"
                getValue={this.getValueOfSelectLimit}
                value={this.state.limit}
                options={limitOptions}
                label="Show entries"
              />
            </MDBCol>

            {!this.props.isFromCustomerPageModal && (
              <>
                <MDBCol
                  md="8"
                  className="d-flex align-items-center  justify-content-end"
                >
                  {this.renderExcelDataExport()}
                </MDBCol>
                <MDBCol md="2">
                  <MDBSelect
                    outline
                    color="primary"
                    getValue={this.getValueOfSelectExportType}
                    value={this.state.isExportAllData}
                    options={this.state.exportDataSelectOptions}
                    label="Export type"
                  />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </div>
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            <br></br>
            {resultContainer && (
              <>
                <span>
                  Showing {resultContainer.returnedRangedFrom} -{" "}
                  {resultContainer.returnedRangedTo} of {resultContainer.total}{" "}
                  item(s)
                </span>
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={resultContainer.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getBookingReportResponse: state.bookingResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getBookingReports,
    showHideLoader,
    bulkPayCustomerPaymentPending,
  })(PaymentPendingReport)
);
