import { STORE_USER_ACCESS } from "../actions/types";

export default (state = {}, action) => {
  let data = {
    type: action.type,
  };
  switch (action.type) {
    case STORE_USER_ACCESS:
      data["data"] = action.payload;
      break;
    default:
      return state;
  }
  return data;
};
