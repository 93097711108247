import React from "react";
import { connect } from "react-redux";
import { CirclePicker, CompactPicker } from "react-color";
import { withRouter } from "react-router-dom";
import defaultLogo from "../assets/ubicon.png";
import CheckoutForm from "../shared/CheckoutForm";

import { MDBRow, MDBCol, MDBContainer, MDBInput } from "mdbreact";
import { displayBreadcrumbs, showHideLoader } from "../../../actions";
import { getOrganisationCheckoutPage } from "../../../actions/organisationAction";
import { findLastIndex } from "lodash";

class ManageOrganisationPages extends React.Component {
  state = {
    selectedItemRadio: "headerText",
    selectedPresets: "teal",

    componentName: "ManageOrganisationPages",
    totalAmount: "0.00",

    //Header
    headerTextColor: "#fff",
    headerText: "Organisation Name",
    headerBackgroundColor: "#16A5A5",

    //Sub Header
    subHeaderTextColor: "#000",
    subHeaderText: "Sub Header",

    colorToChange: "headerTextColor", // Default color to change

    //Forms
    formLabelsColor: "#000",
    formBodyBackgroundColor: "#fff",

    //Pay Button
    payButtonBackgroundColor: "#16A5A5",
    payButtonTextColor: "#fff",

    //Upload Image
    imagePreview: defaultLogo,
    isErrorSelectedImage: false,
  };

  onChangeRadioButton =
    (selectedRadio, colorToChange = "") =>
    () => {
      this.setState({
        selectedItemRadio: selectedRadio,
        colorToChange: colorToChange,
      });
    };

  onChangeRadioButton =
    (selectedRadio, colorToChange = "") =>
    () => {
      this.setState({
        selectedItemRadio: selectedRadio,
        colorToChange: colorToChange,
      });
    };

  onChangeRadioPresets = (selectedPresets) => () => {
    let {
      headerTextColor,
      headerBackgroundColor,
      subHeaderTextColor,
      formLabelsColor,
      formBodyBackgroundColor,
      payButtonBackgroundColor,
      payButtonTextColor,
    } = this.state;

    if (selectedPresets) {
      switch (selectedPresets) {
        case "dark":
          headerTextColor = "#ffffff";
          headerBackgroundColor = "#4D4D4D";
          subHeaderTextColor = "#ffffff";
          formLabelsColor = "#ffffff";
          formBodyBackgroundColor = "#999999";
          payButtonBackgroundColor = "#ffffff";
          payButtonTextColor = "#000000";

          break;

        case "light":
          headerTextColor = "#ffffff";
          headerBackgroundColor = "#009CE0";
          subHeaderTextColor = "#000000";
          formLabelsColor = "#000000";
          formBodyBackgroundColor = "#ffffff";
          payButtonBackgroundColor = "#009CE0";
          payButtonTextColor = "#ffffff";
          break;

        case "teal":
          headerTextColor = "#ffffff";
          headerBackgroundColor = "#16A5A5";
          subHeaderTextColor = "#000000";
          formLabelsColor = "#000000";
          formBodyBackgroundColor = "#ffffff";
          payButtonBackgroundColor = "#16A5A5";
          payButtonTextColor = "#ffffff";

          break;
        default:
      }

      this.setState({
        selectedPresets,
        headerTextColor,
        headerBackgroundColor,
        subHeaderTextColor,
        formLabelsColor,
        formBodyBackgroundColor,
        payButtonBackgroundColor,
        payButtonTextColor,
      });
    }
  };

  handleChangeComplete = (color) => {
    const { colorToChange } = this.state;
    this.setState({ [colorToChange]: color.hex });
  };

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  componentDidMount() {
    this.props.displayBreadcrumbs(
      "Dashboard / Admin / Organisation / Manage Pages / Checkout Page"
    );
    let params = {};

    this.props.showHideLoader(true);
    this.props.getOrganisationCheckoutPage(
      params,
      this.props.authUser.sessionToken
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.organisationResponse !== this.props.organisationResponse) {
      let response = this.props.organisationResponse;
      if (response.status >= 200 && response.status <= 300) {
        let data = response.data.body;

        let customAttributes = data.custom_attributes;

        let selectedPresets = "";
        let headerTextColor = "";
        let headerBackgroundColor = "";
        let subHeaderTextColor = "";
        let formLabelsColor = "";
        let formBodyBackgroundColor = "";
        let payButtonBackgroundColor = "";
        let payButtonTextColor = "";

        customAttributes.map((attribute) => {
          let value = attribute.default_value;

          if (attribute.org_value) {
            value = attribute.org_value;
          }

          switch (attribute.name) {
            case "checkout-header-text-color":
              headerTextColor = value;
              break;

            case "checkout-header-background-color":
              headerBackgroundColor = value;
              break;

            case "checkout-sub-header-text-color":
              subHeaderTextColor = value;
              break;

            case "checkout-form-labels-color":
              headerTextColor = value;
              break;

            case "checkout-body-backgound-color":
              formLabelsColor = value;
              break;

            case "checkout-pay-button-background-color":
              payButtonBackgroundColor = value;
              break;

            case "checkout-pay-button-text-color":
              payButtonTextColor = value;
              break;

            default:
          }
        });

        this.setState({
          selectedPresets,
          headerTextColor,
          headerBackgroundColor,
          subHeaderTextColor,
          formLabelsColor,
          formBodyBackgroundColor,
          payButtonBackgroundColor,
          payButtonTextColor,
        });
        this.props.showHideLoader(false);
      }
    }
  }

  handleImageChange = (event) => {
    const selected = event.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      // Check if the selected file is allowed
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          isErrorSelectedImage: false,
          imagePreview: reader.result,
        });
      };
      reader.readAsDataURL(selected);
    } else {
      console.log("not allowed");
      this.setState({ isErrorSelectedImage: true, imagePreview: defaultLogo });
    }
  };

  setCreateCheckoutPageAttributes = () => {
    let checkoutPageOrgAttribValues = [
      {
        name: "checkout-header-text-color",
        value: this.state.headerTextColor,
      },
      {
        name: "checkout-header-background-color",
        value: this.state.headerBackgroundColor,
      },
      {
        name: "checkout-sub-header-text-color",
        value: this.state.subHeaderTextColor,
      },
      {
        name: "checkout-form-labels-color",
        value: this.state.formLabelsColor,
      },
      {
        name: "checkout-body-backgound-color",
        value: this.state.formBodyBackgroundColor,
      },
      {
        name: "checkout-pay-button-background-color",
        value: this.state.payButtonBackgroundColor,
      },
      {
        name: "checkout-pay-button-text-color",
        value: this.state.payButtonTextColor,
      },
      {
        name: "checkout-sub-header-text-display",
        value: this.state.subHeaderTextColor,
      },
      {
        name: "checkout-header-text-display",
        value: this.state.subHeaderText,
      },
    ];

    return checkoutPageOrgAttribValues;
  };

  submitHandler = (event) => {
    event.preventDefault();
    console.log(this.setCreateCheckoutPageAttributes());
  };

  render() {
    const {
      totalAmount,
      selectedItemRadio,
      selectedPresets,

      headerTextColor,
      headerBackgroundColor,
      headerText,
      subHeaderTextColor,
      subHeaderText,
      formLabelsColor,
      formBodyBackgroundColor,
      payButtonBackgroundColor,
      payButtonTextColor,
      isErrorSelectedImage,
      imagePreview,
    } = this.state;
    return (
      <>
        <MDBContainer className="mt-4">
          <MDBRow>
            <MDBCol md="4">
              <MDBRow>
                <h4> Edit Checkout page</h4>
                <MDBContainer>
                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "headerText",
                      "headerTextColor"
                    )}
                    checked={selectedItemRadio === "headerText" ? true : false}
                    label="Header Text Display"
                    type="radio"
                    id="radioTitleHeader"
                  />
                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "headerBackground",
                      "headerBackgroundColor"
                    )}
                    checked={
                      selectedItemRadio === "headerBackground" ? true : false
                    }
                    label="Header Background"
                    type="radio"
                    id="radioHeaderBackground"
                  />

                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "subHeaderText",
                      "subHeaderTextColor"
                    )}
                    checked={
                      selectedItemRadio === "subHeaderText" ? true : false
                    }
                    label="Subheader Text Display"
                    type="radio"
                    id="radioSubHeader"
                  />

                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "formBodyBackground",
                      "formBodyBackgroundColor"
                    )}
                    checked={
                      selectedItemRadio === "formBodyBackground" ? true : false
                    }
                    label="Body Background"
                    type="radio"
                    id="radioFormBodyBackground"
                  />

                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "formLabels",
                      "formLabelsColor"
                    )}
                    checked={selectedItemRadio === "formLabels" ? true : false}
                    label="Form Labels"
                    type="radio"
                    id="radioFormLabels"
                  />
                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "payButtonBackground",
                      "payButtonBackgroundColor"
                    )}
                    checked={
                      selectedItemRadio === "payButtonBackground" ? true : false
                    }
                    label="Pay Button Background"
                    type="radio"
                    id="radioPayButton"
                  />

                  <MDBInput
                    gap
                    onClick={this.onChangeRadioButton(
                      "payButtonText",
                      "payButtonTextColor"
                    )}
                    checked={
                      selectedItemRadio === "payButtonText" ? true : false
                    }
                    label="Pay Button Text Display"
                    type="radio"
                    id="radioPayText"
                  />
                </MDBContainer>
              </MDBRow>

              <MDBRow className="mt-4">
                <MDBCol md="12">
                  <CompactPicker
                    color={this.state.headerTextColor}
                    onChange={this.handleChangeComplete}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mt-4">
                <MDBCol md="12">
                  <label for="orgLogoFileUpload" className="font-weight-bold">
                    Upload logo
                  </label>
                  <span className="font-italic">
                    {" "}
                    (Default UB logo will be set)
                  </span>
                  <input
                    type="file"
                    class="form-control"
                    id="orgLogoFileUpload"
                    onChange={this.handleImageChange}
                  />
                  {isErrorSelectedImage && (
                    <div className="text-danger">
                      Selected file format not supported
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
              {(selectedItemRadio === "headerText" ||
                selectedItemRadio === "subHeaderText") && (
                <MDBRow className="mt-4">
                  <MDBCol md="12">
                    <label
                      htmlFor="defaultFormCardNameEx"
                      className="font-weight-bold"
                    >
                      Text Display
                    </label>
                    <input
                      type="text"
                      id={selectedItemRadio}
                      name={selectedItemRadio}
                      className="form-control"
                      value={this.state[selectedItemRadio]}
                      onChange={this.formDataHandler}
                    />
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow className="mt-4">
                <MDBCol md="12">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.submitHandler}
                  >
                    Save
                  </button>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <CheckoutForm
              isPaymentPage={false}
              isDisabledButton={true}
              headerTextColor={headerTextColor}
              headerBackgroundColor={headerBackgroundColor}
              subHeaderTextColor={subHeaderTextColor}
              formLabelsColor={formLabelsColor}
              formBodyBackgroundColor={formBodyBackgroundColor}
              payButtonBackgroundColor={payButtonBackgroundColor}
              payButtonTextColor={payButtonTextColor}
              totalAmount={totalAmount}
              subHeaderText={subHeaderText}
              headerText={headerText}
              imagePreview={imagePreview}
            />

            <MDBCol md="3">
              <MDBRow>
                <h4> Presets Theme</h4>
                <MDBContainer>
                  <MDBInput
                    gap
                    onClick={this.onChangeRadioPresets("teal")}
                    checked={selectedPresets === "teal" ? true : false}
                    label="Teal"
                    type="radio"
                    id="radioPresetsTeal"
                  />
                  <MDBInput
                    gap
                    onClick={this.onChangeRadioPresets("dark")}
                    checked={selectedPresets === "dark" ? true : false}
                    label="Dark"
                    type="radio"
                    id="radioPresetsDark"
                  />

                  <MDBInput
                    gap
                    onClick={this.onChangeRadioPresets("light")}
                    checked={selectedPresets === "light" ? true : false}
                    label="Light"
                    type="radio"
                    id="radioPresetsLight"
                  />
                </MDBContainer>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    rulesEngineResponse: state.rulesEngineResponse,
    organisationResponse: state.organisationResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getOrganisationCheckoutPage,
    showHideLoader,
  })(ManageOrganisationPages)
);
