import {
  GET_BUSINESS_RULE_TRIGGER_TYPES_RESPONSE_HANDLER,
  GET_BUSINESS_RULE_TRIGGER_TYPES,
} from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case GET_BUSINESS_RULE_TRIGGER_TYPES:
      response["data"] = data.dataList;
      break;
    case GET_BUSINESS_RULE_TRIGGER_TYPES_RESPONSE_HANDLER:
      response["data"] = action.payload.data;
      response["action"] = "ACTION";
      break;
    default:
      return state;
  }
  return response;
};
