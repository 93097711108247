import { combineReducers } from "redux";
import validateLayoutReducer from "./validateLayoutReducer";
import toggleModalReducer from "./toggleModalReducer";
import searchLocationsReducer from "./searchLocationsReducer";
import validateSearchReducer from "./validateSearchReducer";
import authLoginReducer from "./authLoginReducer";
import authLoginFailedReducer from "./authLoginFailedReducer";
import inputUsernameReducer from "./inputUsernameReducer";
import inputPasswordReducer from "./inputPasswordReducer";
import inputDomainReducer from "./inputDomainReducer";
import getLocationResourcesReducer from "./getLocationResourcesReducer";
import selectDateReducer from "./selectDateReducer";
import alertMessageReducer from "./alertMessageReducer";
import setBookingDetailsReducer from "./setBookingDetailsReducer";
import setCustomerReducer from "./setCustomerReducer";
import setAuthUserReducer from "./setAuthUserReducer";
import showHideLoaderReducer from "./showHideLoaderReducer";
import notificationReducer from "./notificationReducer";
import displayBreadcrumbsReducer from "./displayBreadcrumbsReducer";
import requestPaymentReducer from "./requestPaymentReducer";
import paymentRequestSuccessReducer from "./paymentRequestSuccessReducer";
import paymentRequestFailedReducer from "./paymentRequestFailedReducer";
import redirectedReducer from "./redirectedReducer";
import getBookingDetailsReducer from "./getBookingDetailsReducer";
import bookingRequestReducer from "./bookingRequestReducer";
import successHandlerReducer from "./successHandlerReducer";
import failedHandlerReducer from "./failedHandlerReducer";
import usersReducer from "./usersReducer";
import revalidateComponentReducer from "./revalidateComponentReducer";
import userLogoutReducer from "./userLogoutReducer";
import validateResetTokenReducer from "./validateResetTokenReducer";
import changePasswordReducer from "./changePasswordReducer";
import bookingSuccessReducer from "./bookingSuccessReducer";
import bookingFailedReducer from "./bookingFailedReducer";
import getStatusesSuccessReducer from "./getStatusesSuccessReducer";
import getStatusesFailedReducer from "./getStatusesFailedReducer";
import getBookingStatusSuccessReducer from "./getBookingStatusSuccessReducer";
import getBookingStatusFailedReducer from "./getBookingStatusFailedReducer";
import genCustomerVoucherSuccessReducer from "./genCustomerVoucherSuccessReducer";
import genCustomerVoucherFailedReducer from "./genCustomerVoucherFailedReducer";
import getBookingsSuccessReducer from "./getBookingsSuccessReducer";
import getBookingsFailedReducer from "./getBookingsFailedReducer";
import insertLocationSuccessReducer from "./insertLocationSuccessReducer";
import insertLocationFailedReducer from "./insertLocationFailedReducer";
import updateLocationSuccessReducer from "./updateLocationSuccessReducer";
import updateLocationFailedReducer from "./updateLocationFailedReducer";
import getAttrSuccessReducer from "./getAttrSuccessReducer";
import getAttrFailedReducer from "./getAttrFailedReducer";
import getLocationListSuccessReducer from "./getLocationListSuccessReducer";
import getLocationListFailedReducer from "./getLocationListFailedReducer";
import getAttrKeysSuccessReducer from "./getAttrKeysSuccessReducer";
import getAttrKeysFailedReducer from "./getAttrKeysFailedReducer";
import setValReducer from "./setValReducer";
import getLocationMenuSuccessReducer from "./getLocationMenuSuccessReducer";
import getLocationMenuFailedReducer from "./getLocationMenuFailedReducer";
import deleteLocationSuccessReducer from "./deleteLocationSuccessReducer";
import deleteLocationFailedReducer from "./deleteLocationFailedReducer";
import addModalAttrReducer from "./addModalAttrReducer";
import insertAttrSuccessReducer from "./insertAttrSuccessReducer";
import insertAttrFailedReducer from "./insertAttrFailedReducer";
import updateAttrSuccessReducer from "./updateAttrSuccessReducer";
import updateAttrFailedReducer from "./updateAttrFailedReducer";
import insertAttrKeySuccessReducer from "./insertAttrKeySuccessReducer";
import insertAttrKeyFailedReducer from "./insertAttrKeyFailedReducer";
import getAttrTypesSuccessReducer from "./getAttrTypesSuccessReducer";
import getAttrTypesFailedReducer from "./getAttrTypesFailedReducer";
import getAuditLogsReducer from "./getAuditLogsReducer";
import getResourceListSuccessReducer from "./getResourceListSuccessReducer";
import getResourceListFailedReducer from "./getResourceListFailedReducer";
import insertResourceSuccessReducer from "./insertResourceSuccessReducer";
import insertResourceFailedReducer from "./insertResourceFailedReducer";
import updateResourceSuccessReducer from "./updateResourceSuccessReducer";
import updateResourceFailedReducer from "./updateResourceFailedReducer";
import deleteResourceSuccessReducer from "./deleteResourceSuccessReducer";
import deleteResourceFailedReducer from "./deleteResourceFailedReducer";
import getCustomersReducer from "./customersReducer";
import getCustomerBannerMessage from "./customerBannerReducer";
import contactUsReducer from "./contactUsReducer";
import authReducer from "./authReducer";
import organisationReducer from "./organisationReducer";
import attributeReducer from "./attributeReducer";
import voucherReducer from "./voucherReducer";
import recurringBookingReducer from "./recurringBookingReducer";
import paymentReducer from "./paymentReducer";
import resourceReducer from "./resourceReducer";
import closeBookingReducer from "./closeBookingReducer";
import bookingChangeScheduleReducer from "./bookingChangeScheduleReducer";
import bookingReducer from "./bookingReducer";
import genericSearchReducer from "./genericSearchReducer";
import { responsiveStateReducer } from "redux-responsive";
import membershipReducer from "./membershipReducer";
import membershipTypeReducer from "./membershipTypeReducer";
import ruleReducer from "./ruleReducer";
import emailLogReducer from "./emailLogReducer";
import genericModalReducer from "./genericModalReducer";
import roleReducer from "./roleReducer";
import storeUserAccessReducer from "./storeUserAccessReducer";
import componentReducer from "./componentReducer";
import setSwitchValReducer from "./setSwitchValReducer";
import disableParentCalendarTimerReducer from "./disableCalendarParentTimerReducer";
import pauseBackgroundRequestsReducer from "./pauseBackgroundRequestsReducer";
import calendarNextNavReducer from "./calendarNextNavReducer";
import calendarPrevNavReducer from "./calendarPrevNavReducer";
import userFavouriteReducer from "./userFavouriteReducer";
import rulesEngineReducer from "./rulesEngineReducer";
import pricingReducer from "./pricingReducer";
import ruleEngineEvaluatorReducer from "./ruleEngineEvaluatorReducer";
import ruleEngineTriggerTypesReduer from "./ruleEngineTriggerTypesReduer";
import addedCustomerParticipantReducer from "./addedCustomerParticipantReducer";
import wizardReducer from "./wizardReducer";
import intervalsReducer from "./intervalsReducer";
import stripePaymentReducer from "./stripePaymentReducer";

export default combineReducers({
  layout: validateLayoutReducer,
  modal: toggleModalReducer,
  locations: searchLocationsReducer,
  search: validateSearchReducer,
  authLogin: authLoginReducer,
  authLoginFailed: authLoginFailedReducer,
  inputUsername: inputUsernameReducer,
  inputPassword: inputPasswordReducer,
  inputDomain: inputDomainReducer,
  locationResources: getLocationResourcesReducer,
  selectedDate: selectDateReducer,
  alert: alertMessageReducer,
  bookingDetails: setBookingDetailsReducer,
  customer: setCustomerReducer,
  authUser: setAuthUserReducer,
  loader: showHideLoaderReducer,
  breadcrumbs: displayBreadcrumbsReducer,
  paymentRequest: requestPaymentReducer,
  paymentRequestSuccess: paymentRequestSuccessReducer,
  paymentRequestFailed: paymentRequestFailedReducer,
  redirected: redirectedReducer,
  bookingDetailsResponse: getBookingDetailsReducer,
  bookingRequestResponse: bookingRequestReducer,
  successHandlerResponse: successHandlerReducer,
  failedHandlerResponse: failedHandlerReducer,
  userResponse: usersReducer,
  revalidateComp: revalidateComponentReducer,
  userLogout: userLogoutReducer,
  validateResetTokenResponse: validateResetTokenReducer,
  changePasswordResponse: changePasswordReducer,
  bookingSuccessResponse: bookingSuccessReducer,
  bookingFailedResponse: bookingFailedReducer,
  getStatusesSuccessResponse: getStatusesSuccessReducer,
  getStatusesFailedResponse: getStatusesFailedReducer,
  getBookingStatusSuccessResponse: getBookingStatusSuccessReducer,
  getBookingStatusFailedResponse: getBookingStatusFailedReducer,
  genCustomerVoucherSuccessResponse: genCustomerVoucherSuccessReducer,
  genCustomerVoucherFailedResponse: genCustomerVoucherFailedReducer,
  getBookingsSuccessResponse: getBookingsSuccessReducer,
  getBookingsFailedResponse: getBookingsFailedReducer,
  insertLocationSuccessResponse: insertLocationSuccessReducer,
  insertLocationFailedResponse: insertLocationFailedReducer,
  updateLocationSuccessResponse: updateLocationSuccessReducer,
  updateLocationFailedResponse: updateLocationFailedReducer,
  getAttrSuccessResponse: getAttrSuccessReducer,
  getAttrFailedResponse: getAttrFailedReducer,
  getLocationListSuccessResponse: getLocationListSuccessReducer,
  getLocationListFailedResponse: getLocationListFailedReducer,
  getAttrKeysSuccessResponse: getAttrKeysSuccessReducer,
  getAttrKeysFailedResponse: getAttrKeysFailedReducer,
  getVal: setValReducer,
  getLocationMenuSuccessResponse: getLocationMenuSuccessReducer,
  getLocationMenuFailedResponse: getLocationMenuFailedReducer,
  deleteLocationSuccessResponse: deleteLocationSuccessReducer,
  deleteLocationFailedResponse: deleteLocationFailedReducer,
  modalAttr: addModalAttrReducer,
  insertAttrSuccessResponse: insertAttrSuccessReducer,
  insertAttrFailedResponse: insertAttrFailedReducer,
  updateAttrSuccessResponse: updateAttrSuccessReducer,
  updateAttrFailedResponse: updateAttrFailedReducer,
  insertAttrKeySuccessResponse: insertAttrKeySuccessReducer,
  insertAttrKeyFailedResponse: insertAttrKeyFailedReducer,
  getAttrTypesSuccessResponse: getAttrTypesSuccessReducer,
  getAttrTypesFailedResponse: getAttrTypesFailedReducer,
  getAuditLogsSuccessResponse: getAuditLogsReducer,
  getResourceListSuccessResponse: getResourceListSuccessReducer,
  getResourceListFailedResponse: getResourceListFailedReducer,
  insertResourceSuccessResponse: insertResourceSuccessReducer,
  insertResourceFailedResponse: insertResourceFailedReducer,
  updateResourceSuccessResponse: updateResourceSuccessReducer,
  updateResourceFailedResponse: updateResourceFailedReducer,
  deleteResourceSuccessResponse: deleteResourceSuccessReducer,
  deleteResourceFailedResponse: deleteResourceFailedReducer,
  customerResponse: getCustomersReducer,
  customerBannerMessageResponse: getCustomerBannerMessage,
  notification: notificationReducer,
  contactUsResponse: contactUsReducer,
  authResponse: authReducer,
  organisationResponse: organisationReducer,
  attributeChildResponse: attributeReducer,
  voucherResponse: voucherReducer,
  recurringBookingResponse: recurringBookingReducer,
  paymentResponse: paymentReducer,
  resourceResponse: resourceReducer,
  closeBooking: closeBookingReducer,
  bookingChangeSchedule: bookingChangeScheduleReducer,
  bookingResponse: bookingReducer,
  genericSearchResponse: genericSearchReducer,
  browserSize: responsiveStateReducer,
  membershipResponse: membershipReducer,
  membershipTypeResponse: membershipTypeReducer,
  ruleResponse: ruleReducer,
  emailLogsResponse: emailLogReducer,
  genericModalResponse: genericModalReducer,
  roleResponse: roleReducer,
  storeUserAccess: storeUserAccessReducer,
  componentReducer: componentReducer,
  setSwitchValResponse: setSwitchValReducer,
  disableParentCalendarTimerResponse: disableParentCalendarTimerReducer,
  pauseBackgroundRequestsResponse: pauseBackgroundRequestsReducer,
  calendarNextNavResponse: calendarNextNavReducer,
  calendarPrevNavResponse: calendarPrevNavReducer,
  userFavouriteResponse: userFavouriteReducer,
  rulesEngineResponse: rulesEngineReducer,
  pricingResponse: pricingReducer,
  ruleEngineEvaluatorsResponse: ruleEngineEvaluatorReducer,
  ruleEngineTriggerTypesResponse: ruleEngineTriggerTypesReduer,
  addedCustomerParticipantResponse: addedCustomerParticipantReducer,
  activeWizardStep: wizardReducer,
  intervalId: intervalsReducer,
  stripePaymentResponse: stripePaymentReducer,
});
