import React from "react";
import { displayBreadcrumbs } from "../../../actions";
import { connect } from "react-redux";
import VenueSubscriptions from "../shared/VenueSubscriptions";

class DashboardContent extends React.Component {
  componentDidMount() {
    this.props.displayBreadcrumbs("Dashboard");
  }
  renderContent() {
    return (
      <div>
        <h4>Welcome to dashboard!</h4>

        <VenueSubscriptions />
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { breadcrumbs: state.breadcrumbs };
};

export default connect(mapStateToProps, { displayBreadcrumbs })(
  DashboardContent
);
