import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { connect } from "react-redux";
import { GEN_MODAL_ROLE_ACCESS } from "../../../actions/types";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
  genModalRoleAccess,
} from "../../../actions";
import { insertRole, updateRole } from "../../../actions/roleAction";
import { getCustomers } from "../../../actions/customerAction";
import { getLocationList } from "../../../actions/locationAction";
import { getResourceList } from "../../../actions/resourceAction";

import _ from "lodash";
import moment from "moment";
import Modal from "./Modal";
import RoleAccess from "./RoleAccess";

class RoleAccessModal extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    notification: {},

    roleId: "",
    role: "",
    description: "",
    wasValidated: "",

    componentName: "RoleAccessModal",
    parent: "",
  };

  componentDidMount() { }

  render() {
    return (
      <div>

        {this.renderForm()}
      </div>
    );
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const { roleId, role, description, isEdit } = this.state;
    let body = {
      role: role,
      description: description,
    };

    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    console.log(event.target.checkValidity());
    if (role.length === 0) {
      this.props.showNotification("error", "Please enter role");
    }

    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateRole(body, roleId, this.props.authUser.sessionToken);
      } else {
        this.props.insertRole(body, this.props.authUser.sessionToken);
      }
    }
  };

  renderForm() {
    const { roleId, role, showModal } = this.state;
    return (
      <div>
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
            autoComplete="off"
          >
            <MDBModalHeader>Manage Role Access for {role}</MDBModalHeader>
            <MDBModalBody>
              <RoleAccess roleId={roleId} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  clearForm() {
    this.setState({
      isEdit: false,
      roleId: "",
      role: "",
      description: "",
      wasValidated: "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.roleResponse !== this.props.roleResponse) {
    //   let response = this.props.roleResponse;
    //   this.props.showHideLoader(false);
    //   if (response.action == "ACTION") {
    //     this.props.showNotification(
    //       response.notificationType,
    //       response.data.message
    //     );
    //     if (response.status >= 200 && response.status <= 300) {
    //       let attr = {
    //         show: false,
    //         action: "callback",
    //         parent: this.state.parent,
    //         response: response,
    //       };
    //       this.props.genModalRoleAccess(attr);
    //       this.clearForm();
    //       this.setState({
    //         showModal: false,
    //         isModalTrigger: false,
    //       });
    //     }
    //   }
    // }

    this.validateModal(prevProps, prevState);
  }

  validateModal(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_ROLE_ACCESS) {
        if (response.data.show && response.data.action === "add") {
          console.log("response.data.payload");
          console.log(response.data.payload);
          this.setState({
            showModal: true,
            parent: response.data.parent,
            roleId: response.data.payload.id,
            role: response.data.payload.role,
          });
        }
      }
    }
  }

  showModal = (showModal) => {
    this.setState({ showModal: showModal });
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  formInputChangeHandler = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    roleResponse: state.roleResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertRole,
    updateRole,
    showNotification,
    clearNotification,
    getCustomers,
    getLocationList,
    getResourceList,
    genModalRoleAccess,
  })(RoleAccessModal)
);
