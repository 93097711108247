import React from "react";
import { connect } from "react-redux";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { Radio } from "antd";
import moment, { utc } from "moment";
import { showHideLoader, showNotification } from "../../../actions";
import _ from "lodash";
import { notifyBookingTimeslotAvailablity } from "../../../actions/bookingAction";
import { extractTimezone } from "../../../helper/utils";

class BookingTimeslotNotificationModal extends React.Component {
  state = {
    durationInMinutes: 30,
    is_exact_duration: true,
    isShowModalForOtherAvailableResources: false,
    otherAvailableResources: [],
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    this.notifyBookingTimeslotResponse(prevProps, prevState);
  }
  componentWillUnmount() {}

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  notifyBookingTimeslotResponse(prevProps, prevState) {
    if (prevProps.bookingResponse !== this.props.bookingResponse) {
      let res = this.props.bookingResponse;
      if (
        res.action ===
        "BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER"
      ) {
        this.props.showHideLoader(false);
        if (typeof res.data !== "undefined") {
          const responseData = res.data.data;
          this.props.showNotification("error", responseData.message);
        }
      } else if (res.action === "BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION") {
        if (typeof res.data !== "undefined") {
          const responseData = res.data.data;
          this.props.showHideLoader(false);
          this.props.toggleNotificationModal(false);

          if (responseData.is_other_resources_available) {
            this.setState({
              isShowModalForOtherAvailableResources: true,
              otherAvailableResources: responseData.other_available_resources,
            });
          } else {
            this.props.showNotification("success", responseData.message);
            this.setState({ otherAvailableResources: [] });
          }
        }
      }
    }
  }
  getTzDetails(dt = new Date()) {
    return extractTimezone(this.props.locationTimezone, dt);
  }

  formatSelectedDurationDisplay = () => {
    let selectedTimeslot = this.props.selectedTimeslot;

    var localStartTime = moment(selectedTimeslot)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");
    var localEndTime = moment(selectedTimeslot)
      .add(this.state.durationInMinutes, "minutes")
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");

    return `${localStartTime} - ${localEndTime}`;
  };

  render() {
    return (
      <>
        <div className="child-popup">
          <MDBModal isOpen={this.props.isShowModal} size="md">
            <MDBModalHeader className="font-weight-bold">
              Timeslot Notification
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="text-left mb-3 ">
                  Your selected slot is already taken, Do you want to be
                  notified when timeslot is available?
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol md="12" className="text-left">
                  <label className="font-weight-bold">
                    {" "}
                    Select Duration: {this.formatSelectedDurationDisplay()}
                  </label>

                  <select
                    className="browser-default custom-select"
                    name="status"
                    onChange={(event) => {
                      this.setState({ durationInMinutes: event.target.value });
                    }}
                    defaultValue={this.state.status}
                    value={this.state.durationInMinutes}
                  >
                    <option value={30}>30 minutes or 0.5 hour</option>
                    <option value={60}>60 minutes or 1 hour</option>
                    <option value={90}>90 minutes or 1.5 hours</option>
                    <option value={120}>120 minutes or 2 hours</option>
                  </select>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="12" className=" text-left">
                  <label className=" font-weight-bold ">Notify me when:</label>
                </MDBCol>
                <MDBCol md="12" className=" text-left">
                  <Radio
                    value={this.state.is_exact_duration}
                    checked={this.state.is_exact_duration === true}
                    onChange={(e) => {
                      this.setState({
                        is_exact_duration: true,
                      });
                    }}
                  >
                    Exact selected duration is available
                  </Radio>
                </MDBCol>
                <MDBCol md="12" className=" text-left">
                  <Radio
                    value={this.state.is_exact_duration}
                    checked={this.state.is_exact_duration === false}
                    onChange={(e) => {
                      this.setState({
                        is_exact_duration: false,
                      });
                    }}
                  >
                    There is any available slot on the selected duration
                  </Radio>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() =>
                  this.props.toggleNotificationModal(!this.props.isShowModal)
                }
              >
                Close
              </MDBBtn>
              <MDBBtn
                color="primary"
                onClick={() => {
                  const selectedTimeslotFrom = this.props.selectedTimeslot;
                  let selectedTimeslotTo = moment(selectedTimeslotFrom)
                    .add(this.state.durationInMinutes, "minutes")
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss");

                  const bodyRequest = {
                    timeslotFrom: selectedTimeslotFrom,
                    timeslotTo: selectedTimeslotTo + "Z",
                    locationId: this.props.locationId,
                    isExactDurationOnly: this.state.is_exact_duration,
                  };

                  this.props.showHideLoader(true);
                  this.props.notifyBookingTimeslotAvailablity(
                    bodyRequest,
                    this.props.authUser.sessionToken
                  );
                }}
              >
                Submit
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </div>

        {this.state.isShowModalForOtherAvailableResources && (
          <MDBModal
            isOpen={this.state.isShowModalForOtherAvailableResources}
            size="md"
          >
            <MDBModalHeader className="font-weight-bold">
              Timeslot Notification
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="text-left mb-3 ">
                  <p>
                    Success! You will be notified when there is available
                    courts/fields .{" "}
                  </p>
                  <p>
                    But currently other timeslots are available with your
                    selected duration that you might want to check.
                  </p>
                  <p className="font-weight-bold"> Available Courts/Fields:</p>
                  <ul>
                    {this.state.otherAvailableResources.map(function (item) {
                      return <li key={item.id}>{item.name}</li>;
                    })}
                  </ul>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="primary"
                onClick={() =>
                  this.setState({
                    isShowModalForOtherAvailableResources: false,
                  })
                }
              >
                OK
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    bookingResponse: state.bookingResponse,
  };
};

export default connect(mapStateToProps, {
  notifyBookingTimeslotAvailablity,
  showHideLoader,
  showNotification,
})(BookingTimeslotNotificationModal);
