import React from "react";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { showNotification, clearNotification } from "../../../actions";
import "react-notifications/lib/notifications.css";

class Notification extends React.Component {
  displayNotification = (type, message, timeout) => {
    switch (type) {
      case "info":
        NotificationManager.info(message, "", timeout);
        break;
      case "success":
        NotificationManager.success(message, "", timeout);
        break;
      case "warning":
        NotificationManager.warning(message, "", timeout);
        break;
      case "error":
        NotificationManager.error(message, "", timeout);
        break;
      default:
        break;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type != this.props.type && this.props.type != "") {
      this.displayNotification(
        this.props.type,
        this.props.message,
        this.props.timeout
      );
      this.props.clearNotification();
    }
  }

  render() {
    return (
      <div>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    type: state.notification.type,
    message: state.notification.message,
    timeout: state.notification.timeout,
  };
};

export default connect(mapStateToProps, {
  showNotification,
  clearNotification,
})(Notification);
