import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
  MDBAlert,
} from "mdbreact";
import { connect } from "react-redux";
import { submitMessage } from "../../../actions/contactAction.js";
import { withRouter } from "react-router-dom";
import PageTitle from "../shared/PageTitle";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Spinner from "../shared/Loader";
import {
  showHideLoader,
  showNotification,
  validateLayout,
} from "../../../actions";

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  constructor(props) {
    super(props);
  }

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: true,
      showSearch: false,
      showHeader: true,
      showLogin: false,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contactUsResponse !== this.props.contactUsResponse) {
      let response = this.props.contactUsResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.clearForm();
        }
      }
    }
  }

  clearForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    this.props.showHideLoader(true);
    const { name, email, subject, message } = this.state;

    event.preventDefault();
    this.props.submitMessage(name, email, subject, message);
  };

  renderContactForm() {
    const { name, email, subject, message } = this.state;
    return (
      <div>
        <form onSubmit={this.submitHandler}>
          <MDBCard className="card-body">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    name="name"
                    label="Name"
                    type="text"
                    vlaue={name}
                    onChange={this.formDataHandler}
                  />
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    name="email"
                    label="Email"
                    type="email"
                    vlaue={email}
                    onChange={this.formDataHandler}
                  />
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    name="subject"
                    label="Subject"
                    type="text"
                    vlaue={subject}
                    onChange={this.formDataHandler}
                  />
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    name="message"
                    label="Message Inquiry"
                    type="textarea"
                    rows="3"
                    vlaue={message}
                    onChange={this.formDataHandler}
                  />
                </MDBCol>
                <MDBCol md="12" className="mt-5 padding-left-3">
                  <MDBBtn color="default" className="w-100 p-3" type="submit">
                    Submit Message
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCard>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div>
        <MDBContainer className="content-min-height mt-4 mb-4">
          <div className="mb-3">
            <PageTitle title="Contact Us" subTitle="" />
          </div>
          <MDBRow className="mt-6 mb-6" className="flex-center">
            <MDBCol md="8">{this.renderContactForm()}</MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    loader: state.loader,
    contactUsResponse: state.contactUsResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    submitMessage,
    showHideLoader,
    Spinner,
    showNotification,
    validateLayout,
  })(Contact)
);
