import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
  MDBAlert,
  MDBLink,
} from "mdbreact";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  validateLayout,
  alertMessage,
  showNotification,
} from "../../../actions";
import {
  authLogin,
  inputUsername,
  inputPassword,
  inputDomain,
  clearAuthLoginFailedAlert,
  setAuthUser,
  clearGetHeartBeatResponse,
  register,
} from "../../../actions/authAction";
import { withRouter } from "react-router-dom";
import PageTitle from "../shared/PageTitle";

import { withCookies, Cookies } from "react-cookie";

import { showHideLoader } from "../../../actions";
import queryString from "query-string";

class Register extends React.Component {
  // static propTypes = {
  //   cookies: instanceOf(Cookies).isRequired,
  // };

  state = {
    name: "",
    email: "",
    wasValidated: "",
    urlParams: {},
  };

  constructor(props) {
    super(props);
  }

  // setCookies(key, val, expires) {
  //   const { cookies } = this.props;
  //   cookies.set(key, val, { path: "/", expires });
  // }

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: true,
      showSearch: false,
      showHeader: true,
      showLogin: true,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();
    let params = queryString.parse(this.props.location.search);
    this.setState({ urlParams: params });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authResponse !== this.props.authResponse &&
      this.props.authResponse.type === "REGISTER_RESPONSE_HANDLER"
    ) {
      this.props.showHideLoader(false);
      if (this.props.authResponse.status === 200) {
        this.props.history.push("/registersuccess");
      } else {
        this.props.showNotification(
          this.props.authResponse.notificationType,
          this.props.authResponse.data.message
        );
      }
    }
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const { name, email, urlParams } = this.state;
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      let body = {
        name: name,
        email: email,
      };
      if (urlParams.uuid !== undefined) {
        body["subscription"] = urlParams.uuid;
      }
      this.props.register(body);
    } else {
      this.props.showNotification(
        "error",
        "Please enter valid values on the given fields"
      );
    }
  };

  renderLoginForm() {
    const { name, email, urlParams } = this.state;
    return (
      <div>
        <form
          className={`${this.state.wasValidated}`}
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBCard className="card-body">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    label="Your Name"
                    name="name"
                    value={name}
                    required
                    onChange={this.formDataHandler}
                  />
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    label="Your Email"
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={this.formDataHandler}
                  />
                </MDBCol>
                <MDBCol md="12">
                  <div
                    style={{
                      background: "#adadad1f",
                      textAlign: "center",
                      padding: "7px",
                      color: "gray",
                    }}
                  >
                    We'll send you an email with a link to set your password
                  </div>
                </MDBCol>
                <MDBCol md="12" className="mt-3 padding-left-3">
                  <MDBBtn
                    color="default"
                    className="w-100 p-3"
                    type="submit"
                    // onClick={this.validateLogin.bind(this)}
                  >
                    Register
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="12" style={{ textAlign: "center" }}>
                  {"Already have an account? "}{" "}
                  <Link
                    to={
                      "/login" +
                      (urlParams.uuid !== undefined
                        ? "?uuid=" + urlParams.uuid
                        : "")
                    }
                  >
                    Login
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCard>
        </form>
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        <MDBContainer className="content-min-height mt-5 mb-5">
          <div className="mb-5">
            <PageTitle title="Registration" subTitle="" />
          </div>
          <MDBRow className="mt-5 mb-5" className="flex-center">
            <MDBCol md="6">{this.renderLoginForm()}</MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    login: state.authLogin,
    iUsername: state.inputUsername,
    iPassword: state.inputPassword,
    iDomain: state.inputDomain,
    loginFailed: state.authLoginFailed,
    alert: state.alert,
    authUser: state.authUser,
    loader: state.loader,
    authResponse: state.authResponse,
  };
};

export default withCookies(
  withRouter(
    connect(mapStateToProps, {
      validateLayout,
      alertMessage,
      authLogin,
      inputUsername,
      inputPassword,
      inputDomain,
      clearAuthLoginFailedAlert,
      setAuthUser,
      showHideLoader,
      showNotification,
      clearGetHeartBeatResponse,
      register,
    })(Register)
  )
);
