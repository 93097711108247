import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { connect } from "react-redux";
import {
  GEN_MODAL_CUSTOMER,
  GEN_MODAL_SUBSCRIBE_MORE_DETAILS,
} from "../../../actions/types";
import { getUserBookings } from "../../../actions/bookingAction";
import { extractTimezone } from "../../../helper/utils";
import { closeBooking } from "../../../actions";
import {
  showHideLoader,
  showNotification,
  clearNotification,
  setActiveComponent,
} from "../../../actions";
import BookingEditor from "./BookingEditor";
import { getStatuses } from "../../../actions/bookingAction";
import { getResource } from "../../../actions/resourceAction";

import _ from "lodash";
import moment from "moment";
import Modal from "./Modal";

class SubscriptionMoreDetailsModal extends React.Component {
  state = {
    componentName: "SubscriptionMoreDetailsModal",
    parent: "",
    disableEditingPrimaryFields: false,
    statuses: {},
    locationResource: {},
    showBookingForm: false,
    locationid: "",
    selectedStatus: "",
    bookedCount: "",
    paidCount: "",
    unpaidcount: "",
    checkedInCount: "",
    cancelledCount: "",
    upcomingElem: [],
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderBookingDetailsForm()}
      </div>
    );
  }

  viewDetails(selectedObj) {
    this.props.showHideLoader(true);
    this.selectedObj = selectedObj;
    this.props.getResource(
      selectedObj.resource.uuid,
      this.props.authUser.sessionToken
    );
  }

  getTzDetails(dt = new Date()) {
    const { locationTimezone } = this.state;
    return extractTimezone(locationTimezone, dt);
  }

  buildUpcoming(results) {
    let elem = [];
    _.map(results, (result, index) => {
      let date = moment(result.start_time)
        .tz(this.getTzDetails().timezone)
        .format("DD/MM/YYYY");
      let duration =
        moment(result.start_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm") +
        "-" +
        moment(result.end_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm");

      elem.push(
        <tr key={"tr-" + index}>
          <td style={{ width: "90%" }}>
            <strong>{result.resource.name}</strong>
            <br />
            Reference: {result.booking_reference} Date: {date} Time: {duration}
          </td>
          <td>
            <MDBBtn
              color="primary"
              size="sm"
              onClick={() => this.viewDetails(result)}
            >
              Details
            </MDBBtn>
          </td>
        </tr>
      );
    });

    return elem;
  }

  getBookingByStatus(status, locationId = null) {
    const { locationid, componentName } = this.state;
    let params = {
      limit: 1000,
      page: 1,
      showFutureBookings: 0,
      componentName: componentName,
    };
    this.props.showHideLoader(true);
    if (locationid != "") {
      params["locationId"] = locationid;
    } else if (locationId) {
      params["locationId"] = locationId;
    }

    if ("Booked" === status) {
      params["status"] = "Booked";
      params["is_cancelled"] = 0;
    }

    if ("Paid" === status) {
      params["isPaid"] = 1;
      params["is_cancelled"] = 0;
    }

    if ("Unpaid" === status) {
      params["isPaid"] = 0;
      params["is_cancelled"] = 0;
    }

    if ("Checked In" === status) {
      params["status"] = "Checked In";
      params["is_cancelled"] = 0;
    }

    if ("Cancelled" === status) {
      params["is_cancelled"] = 1;
    }

    this.setState({ selectedStatus: status });
    this.props.getUserBookings(params, this.props.authUser.sessionToken);
  }

  renderForm() {
    const {
      showModal,
      bookedCount,
      paidCount,
      unpaidcount,
      checkedInCount,
      cancelledCount,
    } = this.state;
    return (
      <div>
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
            autoComplete="off"
          >
            <MDBModalHeader>Bookings</MDBModalHeader>
            <MDBModalBody>
              <div md="6">
                <MDBBtn
                  color="info"
                  size="sm"
                  onClick={() => this.getBookingByStatus("Booked")}
                >
                  {bookedCount !== "" ? `(${bookedCount})` : ""} Booked
                </MDBBtn>
                <MDBBtn
                  color="success"
                  size="sm"
                  onClick={() => this.getBookingByStatus("Paid")}
                >
                  {paidCount !== "" ? `(${paidCount})` : ""} Paid
                </MDBBtn>
                <MDBBtn
                  color="warning"
                  size="sm"
                  onClick={() => this.getBookingByStatus("Unpaid")}
                >
                  {unpaidcount !== "" ? `(${unpaidcount})` : ""} Unpaid
                </MDBBtn>
                <MDBBtn
                  color="info"
                  size="sm"
                  onClick={() => this.getBookingByStatus("Checked In")}
                >
                  {checkedInCount !== "" ? `(${checkedInCount})` : ""} Checked
                  In
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  size="sm"
                  onClick={() => this.getBookingByStatus("Cancelled")}
                >
                  {cancelledCount !== "" ? `(${cancelledCount})` : ""} Cancelled
                </MDBBtn>
              </div>

              <MDBTable responsive hover>
                <MDBTableHead>
                  <tr>
                    <th>
                      <h6>
                        <strong>
                          {
                            "Click any of the above booking statuses to see details"
                          }
                        </strong>
                      </h6>
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>{this.state.upcomingElem}</MDBTableBody>
              </MDBTable>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      bookedCount,
      paidCount,
      unpaidcount,
      checkedInCount,
      cancelledCount,
    } = this.state;

    if (prevProps.bookingResponse !== this.props.bookingResponse) {
      const { bookingResponse } = this.props;
      if (this.props.bookingResponse.type == "GET_USER_BOOKINGS") {
        if (
          (this.state.locationid == null ||
            bookingResponse.data.bookings.locationId ===
              this.state.locationid) &&
          bookingResponse.data.bookings.componentName ===
            this.state.componentName
        ) {
          let bookedCnt = bookedCount;
          let paidCnt = paidCount;
          let unpaidcnt = unpaidcount;
          let checkedInCnt = checkedInCount;
          let cancelledCnt = cancelledCount;

          let statuses = this.props.bookingResponse.data.bookings.statuses;
          console.log("check statuses: ");
          console.log(statuses);
          bookedCnt = statuses["Booked"];
          paidCnt = statuses["Paid"];
          unpaidcnt = statuses["Unpaid"];
          checkedInCnt = statuses["Checked In"];
          cancelledCnt = statuses["Cancelled"];

          this.setState({
            upcomingElem: this.buildUpcoming(
              this.props.bookingResponse.data.bookings.results
            ),
            bookedCount: bookedCnt,
            paidCount: paidCnt,
            unpaidcount: unpaidcnt,
            checkedInCount: checkedInCnt,
            cancelledCount: cancelledCnt,
          });
          this.props.showHideLoader(false);
        }
      }
    }
    this.getStatuses(prevProps, prevState);
    this.getResourceResponse(prevProps, prevState);
    this.closeBooking(prevProps, prevState);

    this.validateVoucherModal(prevProps, prevState);
  }

  closeBooking(prevProps, prevState) {
    if (
      prevProps.isCloseBooking !== this.props.isCloseBooking &&
      this.props.isCloseBooking
    ) {
      this.props.closeBooking(false);
      this.selectedObj = {};

      this.setState({
        showBookingForm: false,
      });
    }
  }

  getStatuses(prevProps, prevState) {
    if (
      prevProps.getStatusesSuccessResponse !==
        this.props.getStatusesSuccessResponse &&
      this.props.getStatusesSuccessResponse.status === 200
    ) {
      this.setState({
        statuses: this.props.getStatusesSuccessResponse.data.statuses,
      });
    }
  }

  getResourceResponse(prevProps, prevState) {
    if (
      prevProps.getResourceResponse !== this.props.getResourceResponse &&
      this.props.getResourceResponse.status === 200 &&
      this.props.componentReducer === this.state.componentName
    ) {
      if (
        this.state.locationid == null ||
        (this.selectedObj.location !== undefined &&
          "" + this.selectedObj.location.id === "" + this.state.locationid)
      ) {
        this.setState({
          locationResource: this.props.getResourceResponse.data,
          showBookingForm: true,
        });
        this.props.showHideLoader(false);
      }
    }
  }

  renderBookingDetailsForm() {
    const { showBookingForm, locationResource, statuses } = this.state;

    if (showBookingForm) {
      let booking = this.selectedObj;
      let locationObj = _.filter(this.props.locations.results, function (data) {
        return data.uuid == booking.location.uuid;
      })[0];
      return (
        <div>
          <BookingEditor
            doCalculate={true}
            statuses={statuses}
            selectedBooking={this.selectedObj}
            locationResource={locationResource}
            isView={true}
            locationObj={locationObj}
          />
        </div>
      );
    }
    return <span></span>;
  }

  validateVoucherModal(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_SUBSCRIBE_MORE_DETAILS) {
        if (response.data.show && response.data.action === "open") {
          this.setState({
            showModal: true,
            parent: response.data.parent,
            locationid: response.data.payload.locationid,
          });
          this.props.showHideLoader(true);
          this.getBookingByStatus("Booked", response.data.payload.locationid);
          this.props.setActiveComponent(this.state.componentName);
        }
      }
    }
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit, value: "" });
    }

    if (!showModal) {
      this.resetVars();
      this.props.setActiveComponent("VenueSubscriptionTile");
    }
  };

  resetVars() {
    this.selectedObj = {};
    this.setState({
      parent: "",
      disableEditingPrimaryFields: false,
      statuses: {},
      locationResource: {},
      showBookingForm: false,
      locationid: "",
      selectedStatus: "",
      bookedCount: "",
      paidCount: "",
      unpaidcount: "",
      checkedInCount: "",
      cancelledCount: "",
      upcomingElem: [],
    });
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    genericModalResponse: state.genericModalResponse,

    bookingResponse: state.bookingResponse,
    getResourceResponse: state.resourceResponse,
    getStatusesSuccessResponse: state.getStatusesSuccessResponse,
    isCloseBooking: state.closeBooking,
    componentReducer: state.componentReducer,
    locations: state.locations,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    showNotification,
    clearNotification,
    getUserBookings,
    getStatuses,
    getResource,
    closeBooking,
    setActiveComponent,
  })(SubscriptionMoreDetailsModal)
);
