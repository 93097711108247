import React from "react";
import ReactDOM from "react-dom";
import GuestMain from "./modules/guest/Main";
import AdminMain from "./modules/admin/Main";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLogin: false };
  }

  componentDidMount() {
  }

  validateView() {
    if (!this.state.isLogin) {
      return <GuestMain />;
    } else {
      return <AdminMain />;
    }
  }

  render() {
    return <div>{this.validateView()}</div>;
  }
}

export default App;
