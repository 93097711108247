import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Autocomplete from "react-autocomplete";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
// import CustomerModal from "../shared/CustomerModal";
import DatePicker from "react-datepicker";
import { genModalCustomer, genModalVoucher } from "../../../actions";
import { GEN_MODAL_CUSTOMER } from "../../../actions/types";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";
import {
  getVouchers,
  insertVoucher,
  updateVoucher,
  deleteVoucher,
  clearVoucherResponse,
  generateCustomerVoucher,
  assignVoucher,
} from "../../../actions/voucherAction";
import { getCustomers } from "../../../actions/customerAction";
import { getLocationList } from "../../../actions/locationAction";
import { getResourceList } from "../../../actions/resourceAction";

import _ from "lodash";
import moment from "moment";
import Modal from "../shared/Modal";
import { validateAccess } from "../../../helper/utils";

class ManageVoucher extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    // page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    notification: {},

    organisationId: "",
    locationId: "",
    locationUuid: "",
    resourceId: "",
    customerId: "",
    amount: "",
    expiryDate: new Date(),
    description: "",
    voucherCode: "",
    voucherId: "",
    wasValidated: "",
    customerVoucher: "",

    value: "",
    customerResultContainer: [],

    locationValue: "",
    locationResultContainer: [],

    resourceValue: "",
    resourceResultContainer: [],
    showAddCustomer: false,
    componentName: "ManageVoucher",
  };

  debouncedSearchCustomer = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 20, query: val };
    this.props.getCustomers(params, this.props.authUser.sessionToken);
  }, 1000);

  debouncedSearchLocation = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 5, q: val, is_active: 1 };
    this.props.getLocationList(params, this.props.authUser.sessionToken);
  }, 1000);

  debouncedSearchResource = _.debounce(function (val) {
    this.props.showHideLoader(true);
    let params = { limit: 5, locationUuid: this.state.locationUuid, q: val };
    this.props.getResourceList(params, this.props.authUser.sessionToken);
  }, 1000);

  componentDidMount() {
    this.props.showHideLoader(true);
    this.props.displayBreadcrumbs("Dashboard / Administrator / Vouchers");
    this.initLimitOptions();
    this.initCustomerSearchRecord();
    this.initLocationSearchRecord();
    this.page = "1";
  }

  initCustomerSearchRecord() {
    let params = { limit: 10, query: "" };
    this.props.getCustomers(params, this.props.authUser.sessionToken);
  }

  initLocationSearchRecord() {
    let params = { limit: 10, q: "", is_active: 1 };
    this.props.getLocationList(params, this.props.authUser.sessionToken);
  }

  dateOnChange = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Expiry Date"
          onChange={this.dateOnChange}
          selected={this.state.expiryDate}
          dateFormat="dd/MM/yyyy"
          // showTimeSelect
          timeFormat="HH:mm"
        />
      </span>
    );
  }

  renderCustomerAutoComplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete">Search customer</label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.value}
          items={this.state.customerResultContainer}
          getItemValue={(item) => {
            let label =
              item.first_name + " " + item.last_name + ", " + item.email;
            if (item.membership) {
              label +=
                " - " +
                (item.membership.number ? item.membership.number : "N/A") +
                " - " +
                item.membership.name +
                " " +
                item.membership.type.name;
            }
            return label;
          }}
          onSelect={(value, item) => {
            this.setState({
              customerId: item.id,
              value,
              customerResultContainer: [item],
            });
          }}
          onChange={(event, value) => {
            clearTimeout(this.state.timer);
            this.debouncedSearchCustomer(value);
            this.setState({
              value,
              customerId: "",
            });
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.first_name + " " + item.last_name + ", " + item.email}
              {item.membership
                ? " - " +
                  (item.membership.number ? item.membership.number : "N/A") +
                  " - " +
                  item.membership.name +
                  " " +
                  item.membership.type.name
                : ""}
            </div>
          )}
        />
      </div>
    );
  }

  renderLocationAutoComplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete2" className="required">
          Search location
        </label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete2" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.locationValue}
          items={this.state.locationResultContainer}
          getItemValue={(item) => item.name}
          onSelect={(value, item) => {
            // this.props.getResourceList(
            //   { locationUuid: item.uuid },
            //   this.props.authUser.sessionToken
            // );

            this.setState({
              locationId: item.id,
              locationUuid: item.uuid,
              locationValue: value,
              locationResultContainer: [item],
              resourceValue: "",
              organisationId: item.organisation.id,
            });
          }}
          onChange={(event, value) => {
            this.setState({ locationValue: value, locationId: "" });
            this.debouncedSearchLocation(value);
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.name}
            </div>
          )}
        />
      </div>
    );
  }

  renderResourceAutoComplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete2" className="required">
          Search resource
        </label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete2" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.resourceValue}
          items={this.state.resourceResultContainer}
          getItemValue={(item) => item.name}
          onSelect={(value, item) => {
            this.setState({
              resourceId: item.id,
              resourceValue: value,
              resourceResultContainer: [item],
            });
          }}
          onChange={(event, value) => {
            this.setState({ resourceValue: value, resourceId: "" });
            this.debouncedSearchResource(value);
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.name}
            </div>
          )}
        />
      </div>
    );
  }

  render() {
    const { showAddCustomer } = this.state;
    return (
      <div>
        {/* <CustomerModal
          showModal={showAddCustomer}
          customerCallback={this.customerCallback}
        /> */}
        {this.renderForm()}
        {this.renderTable()}
      </div>
    );
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const {
      organisationId,
      locationId,
      resourceId,
      customerId,
      amount,
      expiryDate,
      description,
      voucherCode,
      voucherId,
      isEdit,
    } = this.state;
    let body = {
      //-- TODO
      isPublish: true,
      resourceData: {
        resourceId: resourceId,
        locationId: locationId,
        organisationId: organisationId,
      },
      couponData: {
        amount: amount,
        expiry: moment(expiryDate).format("YYYY-MM-DD 00:00:00"),
        type: "",
        description: description,
        issuedBy: this.props.authUser.username,
      },
    };
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      if (locationId.length === 0) {
        this.props.showNotification("error", "Please select location");
      } else {
        this.props.showHideLoader(true);
        if (isEdit) {
          body.couponData["coupon"] = voucherCode;
          if (customerId !== null && customerId.length > 0) {
            this.props.assignVoucher(
              body,
              voucherId,
              customerId,
              this.props.authUser.sessionToken
            );
          } else {
            this.props.updateVoucher(
              body,
              voucherId,
              this.props.authUser.sessionToken
            );
          }
        } else {
          if (customerId === null || customerId.length === 0) {
            this.props.insertVoucher(body, this.props.authUser.sessionToken);
          } else {
            this.props.generateCustomerVoucher(
              body,
              customerId,
              this.props.authUser.sessionToken
            );
          }
        }
      }
    }
  };

  showAddCustomer(show) {
    let attr = {
      show: true,
      action: "add",
      parent: this.state.componentName,
    };
    this.props.genModalCustomer(attr);
  }
  renderForm() {
    const {
      organisationId,
      locationId,
      resourceId,
      customerId,
      amount,
      expiryDate,
      description,
      showModal,
      isEdit,
      voucherCode,
      showAddCustomer,
      customerVoucher,
    } = this.state;
    return (
      <div>
        <MDBModal isOpen={showModal} size="lg">
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
            autoComplete="off"
          >
            <MDBModalHeader>{isEdit ? "Edit" : "Add"} Voucher</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol
                  size="2"
                  style={{
                    display: `${voucherCode.length === 0 ? "none" : "block"}`,
                  }}
                >
                  <MDBInput
                    label="Voucher Code"
                    disabled
                    value={customerVoucher}
                  />
                </MDBCol>
                <MDBCol md="12" className="mb-3">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "90%" }}>
                          {this.renderCustomerAutoComplete()}
                        </td>
                        <td style={{ paddingTop: "28px" }}>
                          <MDBBtn
                            color="primary"
                            size="sm"
                            style={{ height: "38px" }}
                            onClick={() => this.showAddCustomer(true)}
                          >
                            <i class="fas fa-plus"></i>
                          </MDBBtn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </MDBCol>
                <MDBCol md="12" className="mb-3">
                  {this.renderLocationAutoComplete()}
                </MDBCol>
                {/* <MDBCol md="6" className="mb-3">
                  {this.renderResourceAutoComplete()}
                </MDBCol> */}
                <MDBCol md="5">
                  <label htmlFor="expiryDate" className="grey-text required">
                    Expiry Date
                  </label>
                  {this.datePicker()}
                </MDBCol>
                <MDBCol md="3" className="mb-3">
                  <label htmlFor="amount" className="grey-text required">
                    Amount
                  </label>
                  <input
                    value={amount}
                    onChange={this.formInputChangeHandler}
                    name="amount"
                    type="text"
                    id="amount"
                    className="form-control"
                    placeholder="Enter amount"
                    required
                  />
                </MDBCol>
                <MDBCol md="9" className="mb-3">
                  <label htmlFor="description" className="grey-text required">
                    Description
                  </label>
                  <input
                    value={description}
                    onChange={this.formInputChangeHandler}
                    name="description"
                    type="text"
                    id="description"
                    className="form-control"
                    placeholder="Enter Description"
                    required
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  clearForm() {
    this.setState({
      isEdit: false,
      organisationId: "",
      locationId: "",
      locationUuid: "",
      resourceId: "",
      customerId: "",
      amount: "",
      expiryDate: new Date(),
      description: "",
      wasValidated: "",
      voucherCode: "",
      voucherId: "",
      customerVoucher: "",

      locationValue: "",
      locationResultContainer: [],

      resourceValue: "",
      resourceResultContainer: [],
      showAddCustomer: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.voucherResponse !== this.props.voucherResponse) {
      let response = this.props.voucherResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          //-- TODO assign voucher to customer
          if (!this.state.isEdit && this.state.customerId.length > 0) {
          }

          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.debouncedDoFilterWithDelay();
        }
      } else {
        this.setState({
          resultContainer: this.props.voucherResponse.data,
        });
        this.page = this.props.voucherResponse.data.page;
      }
    }

    this.getCustomerResult(prevProps, prevState);
    this.getLocationResult(prevProps, prevState);
    this.getResourceResult(prevProps, prevState);
    this.genCussmerVoucherResponse(prevProps, prevState);
    this.customerCallback(prevProps, prevState);
  }

  customerCallback(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_CUSTOMER) {
        if (
          response.data.parent === this.state.componentName &&
          response.data.action === "callback"
        ) {
          let customer = response.data.response.data.customer;
          this.setState({
            customerId: customer.id,
            value:
              customer.first_name +
              " " +
              customer.last_name +
              ", " +
              customer.email_address,
          });
        }
      }
    }
  }

  genCussmerVoucherResponse(prevProps, prevState) {
    if (
      prevProps.genCustomerVoucherSuccessResponse !==
      this.props.genCustomerVoucherSuccessResponse
    ) {
      let response = this.props.genCustomerVoucherSuccessResponse;
      this.props.showNotification("success", response.data.message);
      this.props.showHideLoader(false);
      this.clearForm();
      this.setState({
        showModal: false,
        isModalTrigger: false,
      });
      this.doFilter();
      // if (response.action == "ACTION") {
      //   this.props.showNotification(
      //     response.notificationType,
      //     response.data.message
      //   );
      //   if (response.status >= 200 && response.status <= 300) {
      //     //-- TODO assign voucher to customer
      //     if (!this.state.isEdit && this.state.customerId.length > 0) {
      //     }

      //     this.clearForm();
      //     this.setState({
      //       showModal: false,
      //       isModalTrigger: false,
      //     });
      //     this.doFilter();
      //   }
      // } else {
      //   this.setState({
      //     resultContainer: this.props.voucherResponse.data,
      //     page: this.props.voucherResponse.data.page,
      //   });
      // }
    }
  }

  getCustomerResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(this.props.customerResponse.data.results)
        .orderBy(["first_name"], ["asc"])
        .value();

      this.setState({
        customerResultContainer: resultContainer,
      });
    }
  }

  getLocationResult(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
      this.props.getLocationListSuccessResponse
    ) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(
        this.props.getLocationListSuccessResponse.data.locations.results
      )
        .orderBy(["name"], ["asc"])
        .value();
      this.setState({
        locationResultContainer: resultContainer,
      });
    }
  }

  getResourceResult(prevProps, prevState) {
    if (
      prevProps.getResourceListSuccessResponse !==
      this.props.getResourceListSuccessResponse
    ) {
      this.props.showHideLoader(false);
      let resultContainer = _.chain(
        this.props.getResourceListSuccessResponse.data.resources.results
      )
        .orderBy(["name"], ["asc"])
        .value();
      this.setState({
        resourceResultContainer: resultContainer,
      });
    }
  }

  debouncedDoFilterWithDelay = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  }, 1100);

  debouncedDoFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  });

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  renderFilters() {
    const { limitOptions, statusOptions } = this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search"
                name="query"
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.clearDeleteCustomerAlert();
  }

  clearDeleteCustomerAlert() {
    let modalAttr = {
      showAlert: false,
      alertType: "",
      alertMsg: "",
    };
    this.props.addModalAttr(modalAttr);
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
    };
    this.props.toggleModal(modal);
  };

  doDelete() {
    const { voucherId } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteVoucher(voucherId, this.props.authUser.sessionToken);
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  confirmDelete = (source) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${source.voucher_code}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeDeleteModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ voucherId: source.id });
  };

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalVoucher(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
      };
      this.props.genModalVoucher(attr);
    }

    if (!showModal) {
      this.clearForm();
      this.clearDeleteCustomerAlert();
    }
  };

  renderTable() {
    const { resultContainer } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        {
          label: "Location",
          field: "heading0",
          sort: "asc",
        },
        {
          label: "Voucher Code",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Amount",
          field: "heading2",
          sort: "asc",
        },
        {
          label: "Balance",
          field: "heading3",
          sort: "asc",
        },
        {
          label: "expiry",
          field: "heading4",
          sort: "asc",
        },
        {
          label: "Description",
          field: "heading5",
          sort: "asc",
        },
        {
          label: "Issued To",
          field: "heading6",
          sort: "asc",
        },
        {
          label: "Issued By",
          field: "heading7",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading8",
          sort: "asc",
        },
        {
          label: "Updated",
          field: "heading9",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    rows = _.chain(resultContainer.results)
      .map((result, index) => {
        return {
          heading0:
            result.location !== undefined && result.location.name !== undefined
              ? result.location.name
              : "",
          heading1: result.voucher_code,
          heading2: result.amount,
          heading3:
            result.customer !== undefined &&
            result.customer.customer_voucher_id !== null
              ? result.balance
              : result.unissued_voucher_balance,
          heading4: result.expiry
            ? moment(result.expiry).local().format("DD/MM/YYYY")
            : "",
          heading5: result.description,
          heading6:
            result.customer !== undefined && result.customer.firstName !== null
              ? result.customer.firstName + " " + result.customer.lastName
              : "",
          heading7: result.issued_by,
          heading8: moment(result.created).local().format("DD/MM/YYYY"),
          heading9: moment(result.updated).local().format("DD/MM/YYYY"),
        };
      })
      .value();
    data["rows"] = rows;
    return (
      <MDBContainer>
        {this.renderFilters()}
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            {this.renderTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={resultContainer.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  doFilter() {
    this.props.showHideLoader(true);
    const { status, limit, query } = this.state;

    let params = {};

    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((this.page + "").length > 0) {
      params["page"] = this.page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }
    if (query !== undefined) {
      params["q"] = query;
    }
    this.props.getVouchers(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.page = "1";
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  formInputChangeHandler = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    // this.setState({
    //   page: selectedPage,
    // });
    this.page = selectedPage;
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    voucherResponse: state.voucherResponse,
    customerResponse: state.customerResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getResourceListSuccessResponse: state.getResourceListSuccessResponse,
    genCustomerVoucherSuccessResponse: state.genCustomerVoucherSuccessResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getVouchers,
    clearVoucherResponse,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertVoucher,
    updateVoucher,
    deleteVoucher,
    showNotification,
    clearNotification,
    getCustomers,
    getLocationList,
    getResourceList,
    generateCustomerVoucher,
    assignVoucher,
    genModalCustomer,
    genModalVoucher,
  })(ManageVoucher)
);
