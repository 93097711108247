import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
    GET_RULES,
    RULE_RESPONSE_HANDLER,
} from "./types";

export const getRules = (params, sessionToken) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken
    };

    const response = await bookingAppPrivate
        .get(`/rules`, {
            params: params,
            headers: headers,
        })
        .then((res) => {
            dispatch({
                type: GET_RULES,
                payload: res,
            });
        })
        .catch((err) => {
            let errRes = {
                message: "Network error"
            };
            if (err.response !== undefined) {
                errRes = err.response.data;
            }
            dispatch({
                type: RULE_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: errRes
                },
            });
        });
};

export const insertRule = (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .post(`/rules`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: RULE_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {
                message: "Network error",
            };
            if (err.response !== undefined) {
                errRes = err.response.data;
            }
            dispatch({
                type: RULE_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: errRes
                },
            });
        });
};

export const updateRule = (body, uuid, sessionToken) => async (
    dispatch
) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .put(`/rules/${uuid}`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: RULE_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {
                message: "Network error",
            };
            if (err.response !== undefined) {
                errRes = err.response.data;
            }
            dispatch({
                type: RULE_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: errRes
                },
            });
        });
};

export const deleteRule = (uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .delete(`/rules/${uuid}`, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: RULE_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {
                message: "Network error",
            };
            if (err.response !== undefined) {
                errRes = err.response.data;
            }
            dispatch({
                type: RULE_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: errRes
                },
            });
        });
};
