import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
  MDBAlert,
} from "mdbreact";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { validateLayout } from "../../../actions";
import {
  validateResetToken,
  changePassword,
} from "../../../actions/authAction";
import PageTitle from "../shared/PageTitle";
import { strongRegex, mediumRegex } from "../shared/passwordstrength";
import _ from "lodash";
import Loader from "../shared/Loader";
import { showHideLoader } from "../../../actions";

class ResetPassword extends React.Component {
  state = {
    passwordstrength: "",
    password: "",
    confirmPassword: "",
    color: "#DB4437",
    isWeek: true,
    showAlert: false,
    alertType: "",
    alertMsg: "",
    sessionToken: _.split(this.props.location.pathname, "/")[3],
  };

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: true,
      showSearch: false,
      showHeader: true,
      showLogin: true,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();
    this.props.validateResetToken(this.state.sessionToken);
  }

  analyze(event) {
    if (strongRegex.test(event.target.value)) {
      this.setState({
        passwordstrength: "STRONG",
        color: "#0F9D58",
        isWeek: false,
      });
    } else if (mediumRegex.test(event.target.value)) {
      this.setState({
        passwordstrength: "MEDIUM",
        color: "#F4B400",
        isWeek: false,
      });
    } else {
      this.setState({
        passwordstrength: "WEAK",
        color: "#DB4437",
        isWeek: true,
      });
    }
  }

  onPasswordChange = (evt) => {
    this.setState({ password: evt.target.value });
    this.analyze(evt);
    this.resetAlert();
  };

  onConfirmPasswordChange = (evt) => {
    this.setState({
      confirmPassword: evt.target.value,
    });
    this.resetAlert();
  };

  validatePasswordstrengthStatus() {
    if (this.state.passwordstrength.length > 0) {
      return (
        <div className="mt-2 mb-2">
          <h6 style={{ marginLeft: "13px" }}>
            Password strength:{" "}
            <strong style={{ color: `${this.state.color}` }}>
              {this.state.passwordstrength}
            </strong>
          </h6>
        </div>
      );
    } else {
      return <span></span>;
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.validateChangePassword();
  };

  resetAlert() {
    this.setState({ showAlert: false, alertType: "", alertMsg: "" });
  }

  validateChangePassword() {
    const { password, confirmPassword, isWeek, sessionToken } = this.state;
    this.resetAlert();
    if (password.length == 0 || confirmPassword.length == 0) {
      this.setState({
        showAlert: true,
        alertType: "danger",
        alertMsg: "Please enter password and confirm password",
      });
    } else if (isWeek) {
      this.setState({
        showAlert: true,
        alertType: "danger",
        alertMsg: "Your password is week",
      });
    } else if (password !== confirmPassword) {
      this.setState({
        showAlert: true,
        alertType: "danger",
        alertMsg: "Your password mismatch",
      });
    } else {
      this.props.showHideLoader(true);
      this.props.changePassword(sessionToken, password);
    }
  }

  validateAlert() {
    const { showAlert, alertType, alertMsg } = this.state;
    if (showAlert) {
      return (
        <MDBAlert color={alertType} className="text-center">
          {alertMsg}
        </MDBAlert>
      );
    } else {
      return <span></span>;
    }
  }

  renderPaymentForm() {
    return (
      <div>
        <Loader />
        <form onSubmit={this.submitHandler}>
          <MDBCard className="card-body">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <h1>{this.validatePasswordstrengthStatus()}</h1>
                  {this.validateAlert()}
                  {this.checkChangePasswordResponse()}
                </MDBCol>

                <MDBCol md="12">
                  <MDBCol md="12">
                    <MDBInput
                      label="Password"
                      type="password"
                      onChange={this.onPasswordChange}
                    />
                  </MDBCol>
                  <MDBCol md="12">
                    <MDBInput
                      label="Confirm password"
                      type="password"
                      onChange={this.onConfirmPasswordChange}
                    />
                  </MDBCol>
                </MDBCol>
                <MDBCol md="12" className="mt-5 padding-left-3">
                  <MDBBtn color="default" className="w-100 p-3" type="submit">
                    Change password
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCard>
        </form>
      </div>
    );
  }

  debounceRedirectLogin = _.debounce(function () {
    window.location.replace("/login");
  }, 2500);

  checkChangePasswordResponse() {
    if (
      this.props.changePasswordResponse !== null &&
      this.props.changePasswordResponse.status === 200
    ) {
      this.debounceRedirectLogin();
      this.props.showHideLoader(false);
      return (
        <MDBAlert color="success" className="text-center">
          Password has been reset! You will be redirected to login page
        </MDBAlert>
      );
    } else if (this.props.changePasswordResponse === "ERROR") {
      return (
        <MDBAlert color="danger" className="text-center">
          Unable to reset password. Please try again later or contact system
          administrator.
        </MDBAlert>
      );
    }
    return <span></span>;
  }

  checkValidateResetTokenResponse() {
    if (this.props.validateResetTokenResponse !== undefined) {
      if (this.props.validateResetTokenResponse === "INVALID_TOKEN") {
        return (
          <MDBContainer style={{ minHeight: "400px" }}>
            <h3 className="text-center mt-5 text-danger">
              Invalid token or Token is already expired
            </h3>
            <h6 className="text-center">
              Sorry, but your reset password link is invalid or already expired
            </h6>
          </MDBContainer>
        );
      } else if (
        this.props.validateResetTokenResponse !== null &&
        this.props.validateResetTokenResponse.status === 200
      ) {
        return this.renderContent();
      }
    }
    return <div className="text-center mt-5 mb-5">Loading...</div>;
  }

  renderContent() {
    return (
      <div>
        <MDBContainer className="content-min-height mt-5 mb-5">
          <div className="mb-5">
            <PageTitle
              title="Change Your Password"
              subTitle="Password must be 8 characters or long, must contain at least 1 lowercase, 1 uppercase, 1 numeric and at least one special character. Available special characters are [!@#$%^&]"
            />
          </div>
          <MDBRow className="mt-5 mb-5" className="flex-center">
            <MDBCol md="6">{this.renderPaymentForm()}</MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.checkValidateResetTokenResponse();
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    validateResetTokenResponse: state.validateResetTokenResponse,
    changePasswordResponse: state.changePasswordResponse,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, {
  validateLayout,
  validateResetToken,
  changePassword,
  showHideLoader,
})(ResetPassword);
