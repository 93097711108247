import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSwitch,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import ReportBooking from "./ReportBooking";
import ReportEmailLog from "./ReportEmailLog";
import PaymentReferenceNumberModal from "../shared/PaymentReferenceNumberModal";
import PaymentPendingReport from "../admin/PaymentPendingReport";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";

import { getMemberships } from "../../../actions/membershipAction";

import {
  getCustomers,
  insertCustomer,
  clearInsertCustomerSuccessResponse,
  clearInsertCustomerFailedResponse,
  updateCustomer,
  clearUpdateCustomerSuccessResponse,
  clearUpdateCustomerFailedResponse,
  deleteCustomer,
  clearDeleteCustomerSuccessResponse,
  clearDeleteCustomerFailedResponse,
} from "../../../actions/customerAction";
import _ from "lodash";
import moment from "moment";
import Modal from "../shared/Modal";
import { genModalCustomer } from "../../../actions";
import { validateAccess } from "../../../helper/utils";
import UserDetails from "../shared/UserDetails";

class ManageCustomer extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    customerUuid: "",
    notification: {},
    query: "",
    showBookingHistoryModal: false,
    showOutboundNotification: false,
    customer: {},
    componentName: "ManageCustomer",
    canBook: true,
    isDefaultUser: false,
    isPaymentPendingreport: false,
    bulkPaymentPendingBookingRefNo: [],
    isShowPaymentReferenceModal: false,
  };

  componentDidMount() {
    this.props.getMemberships({ limit: 20 }, this.props.authUser.sessionToken);

    const { storeUserAccess } = this.props;
    let isDefaultUser =
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails") &&
      !validateAccess(storeUserAccess, "Customers", "");

    if (isDefaultUser) {
      this.props.displayBreadcrumbs("Dashboard / My Details");
    } else {
      this.props.displayBreadcrumbs("Dashboard / Administrator / Customers");
    }
    this.setState({ isDefaultUser: isDefaultUser });
    this.initLimitOptions();
    this.initIsApprovedOptions();
  }

  render() {
    const { showModal, customer, isDefaultUser } = this.state;
    return (
      <div>
        {/* {this.renderForm()} */}
        {this.renderCustomerBookingHistory()}
        {this.renderCustomerOutboundNotification()}
        {this.renderTable()}
      </div>
    );
  }

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const {
      firstName,
      lastName,
      email,
      address,
      mobile,
      isEdit,
      customerUuid,
      canBook,
      isPublic,
    } = this.state;
    let body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      address: address,
      phone: mobile,
      customerUuid: customerUuid,
      canBook: canBook,
      isPublic: isPublic,
    };
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateCustomer(
          body,
          customerUuid,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertCustomer(body, this.props.authUser.sessionToken);
      }
    }
  };

  renderForm() {
    const {
      showModal,
      firstName,
      lastName,
      email,
      mobile,
      address,
      isEdit,
      canBook,
    } = this.state;
    return (
      <div>
        <MDBModal isOpen={showModal} size="lg">
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>{isEdit ? "Edit" : "Add"} Customer</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="firstName" className="grey-text">
                    <span className="text-danger">*</span> First Name
                  </label>
                  <input
                    value={firstName}
                    onChange={this.formDataHandler}
                    name="firstName"
                    type="text"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter Customer First Name..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="lastName" className="grey-text">
                    <span className="text-danger">*</span> Last Name
                  </label>
                  <input
                    value={lastName}
                    onChange={this.formDataHandler}
                    name="lastName"
                    type="text"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter Customer Last Name..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="email" className="grey-text">
                    <span className="text-danger">*</span> Email Address
                  </label>
                  <input
                    value={email}
                    onChange={this.formDataHandler}
                    name="email"
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter Email Address..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="mobile" className="grey-text">
                    <span className="text-danger">*</span> Mobile
                  </label>
                  <input
                    value={mobile}
                    onChange={this.formDataHandler}
                    name="mobile"
                    type="number"
                    id="mobile"
                    className="form-control"
                    placeholder="Enter Customer Mobile..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="2" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkActive"
                      defaultChecked={canBook}
                      onChange={this.onIsActiveLocChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkActive"
                      style={{
                        fontWeight: "bold",
                        marginTop: "35px !important",
                      }}
                    >
                      Is Booking Allow
                    </label>
                  </div>
                </MDBCol>
                <MDBCol md="10" className="mb-3">
                  <label htmlFor="address" className="grey-text">
                    Address
                  </label>
                  <input
                    value={address}
                    onChange={this.formDataHandler}
                    name="address"
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Enter address..."
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="address" className="grey-text">
                    Select Membership
                  </label>
                  <select
                    className="custom-select bMDBRowser-default"
                    name="domain"
                    required
                    onChange={this.formDataHandler}
                  >
                    <option>Select Membership</option>
                  </select>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  onIsActiveLocChange = (evt) => {
    this.setState({
      canBook: !this.state.canBook,
    });
  };

  clearForm() {
    this.setState({
      isEdit: false,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      mobile: "",
      customerId: "",
      customerUuid: "",
      customer: {},
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      let response = this.props.customerResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.debouncedDoFilterWithDelay();
        }
      } else if (response.status < 400) {
        this.setState({
          resultContainer: this.props.customerResponse.data,
          page: this.props.customerResponse.data.page,
        });
      } else {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
      }
    }

    if (prevProps.paymentResponse !== this.props.paymentResponse) {
      let response = this.props.paymentResponse;
      if (response.action == "SET_BULK_PAY_BOOKING_REFERENCE_NUMBER") {
        this.setState({ bulkPaymentPendingBookingRefNo: response.data });
      }
    }
  }

  debouncedDoFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  });

  debouncedDoFilterWithDelay = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  }, 1000);

  initIsApprovedOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "Pending",
        value: "0",
      },
      {
        checked: false,
        disabled: false,
        text: "Approved",
        value: "1",
      },
      {
        checked: false,
        disabled: false,
        text: "Declined",
        value: "-1",
      },
    ];

    this.setState({ isApprovedOptions: options });
  }

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  renderFilters() {
    const { limitOptions, statusOptions, isApprovedOptions, isDefaultUser } =
      this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        {isDefaultUser && <UserDetails />}
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">
              {!isDefaultUser ? "Customers" : "My Booking Contact Details"}
            </h3>
            {isDefaultUser && (
              <h6 className="mt-1">
                Booking contact details will be available if you already have
                existing booking transactions
              </h6>
            )}
          </MDBCol>
          {!isDefaultUser &&
            validateAccess(storeUserAccess, "Customers", "add") && (
              <MDBCol md="6">
                <MDBBtn
                  color="primary"
                  onClick={() => this.showModal(true, false, null)}
                  className="float-right"
                >
                  Add Customer
                </MDBBtn>
              </MDBCol>
            )}
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={(value) => {
                this.getValueOfSelectLimit(value);
              }}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={(value) => {
                this.getValueOfIsApproved(value);
              }}
              options={isApprovedOptions}
              label="Filter Membership Status"
            />
          </MDBCol>
          <MDBCol md="8">
            <div className="float-right">
              <MDBInput
                label="Search"
                name="query"
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.clearDeleteCustomerAlert();
  }

  clearDeleteCustomerAlert() {
    let modalAttr = {
      showAlert: false,
      alertType: "",
      alertMsg: "",
    };
    this.props.addModalAttr(modalAttr);
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
    };
    this.props.toggleModal(modal);
  };

  doDelete() {
    const { customerUuid } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteCustomer(customerUuid, this.props.authUser.sessionToken);
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  confirmDelete = (customer) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${customer.first_name} ${customer.last_name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeDeleteModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ customerUuid: customer.uuid });
  };

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalCustomer(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
      };
      this.props.genModalCustomer(attr);
    }

    if (!showModal) {
      this.clearForm();
      this.clearDeleteCustomerAlert();
    }
  };

  renderTable() {
    const { resultContainer, page, isDefaultUser } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [],
    };

    let canEdit =
      isDefaultUser || validateAccess(storeUserAccess, "Customers", "update");

    let rows = [];
    rows = _.chain(resultContainer.results)
      .map((result, index) => {
        let mPlan = "N/A";
        let mNo = "N/A";
        let MCardNo = "N/A";
        let isApproved = "N/A";
        let isRenewal = "N/A";
        if (result.membership !== null && result.membership !== undefined) {
          mPlan = result.membership.name + " " + result.membership.type.name;
          mNo = result.membership.number;
          MCardNo = result.membership.card_number;
          isRenewal = result.membership.is_renewal ? "Yes" : "No";
          switch (result.membership.is_approved) {
            case 0:
              isApproved = "Pending Approval";
              break;
            case 1:
              isApproved = "Approved";
              break;
            case -1:
              isApproved = "Declined";
          }
        }
        let rowData = {
          heading1: (
            <div>
              <table>
                <tbody className="table-th-100-px">
                  <tr>
                    <th>
                      <MDBIcon icon="user-alt" color="black" size="1x" /> Name
                    </th>
                    <td>
                      {result.first_name} {result.last_name}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <MDBIcon icon="at" color="black" size="1x" /> Email
                    </th>
                    <td>{result.email}</td>
                  </tr>
                  <tr>
                    <th>
                      <MDBIcon icon="mobile-alt" color="black" size="1x" />{" "}
                      Mobile
                    </th>
                    <td>{result.mobile}</td>
                  </tr>
                  <tr>
                    <th>
                      <MDBIcon icon="address-book" color="black" size="1x" />{" "}
                      Address
                    </th>
                    <td>{result.address}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
          heading2: mPlan && (
            <div>
              <table>
                <tbody className="table-th-100-px">
                  <tr>
                    <th>Plan:</th>
                    <td>{mPlan}</td>
                  </tr>
                  <tr>
                    <th>Member #:</th>
                    <td>{mNo ? mNo : "N/A"}</td>
                  </tr>
                  {!isDefaultUser ? (
                    <tr>
                      <th>Card #:</th>
                      <td>{MCardNo ? MCardNo : "N/A"}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <th>Status:</th>
                    <td>{isApproved}</td>
                  </tr>
                  <tr>
                    <th>Is Renewal:</th>
                    <td>{isRenewal}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
          heading3: result.is_public ? "Yes" : "No",
          heading4: result.canbook ? "Yes" : "No",
        };

        if (!isDefaultUser) {
          rowData["heading5"] = moment(result.created)
            .local()
            .format("DD/MM/YYYY");
          rowData["heading6"] = moment(result.created)
            .local()
            .format("DD/MM/YYYY");
        }

        rowData["heading7"] = (
          <MDBDropdown>
            <MDBDropdownToggle caret color="primary" size="sm">
              Actions
            </MDBDropdownToggle>
            <MDBDropdownMenu basic>
              {canEdit && (
                <MDBDropdownItem
                  onClick={() => this.showModal(true, true, result)}
                >
                  <MDBIcon icon="edit" className="cyan-text" /> Edit
                </MDBDropdownItem>
              )}

              {!isDefaultUser &&
              validateAccess(storeUserAccess, "Customers", "delete") ? (
                <MDBDropdownItem onClick={() => this.confirmDelete(result)}>
                  <MDBIcon icon="trash" className="red-text" /> Delete
                </MDBDropdownItem>
              ) : (
                ""
              )}
              <MDBDropdownItem divider />
              {validateAccess(storeUserAccess, "Customers", "viewBookings") && (
                <MDBDropdownItem
                  onClick={() => this.showBookingHistory(result)}
                >
                  <MDBIcon icon="book-open" className="cyan-text" /> Bookings
                </MDBDropdownItem>
              )}

              {validateAccess(storeUserAccess, "Customers", "viewBookings") && (
                <MDBDropdownItem
                  onClick={() => this.showBookingHistory(result, true)}
                >
                  <MDBIcon icon="book-open" className="cyan-text" /> Payment
                  Pending
                </MDBDropdownItem>
              )}
              {validateAccess(
                storeUserAccess,
                "Customers",
                "notificationLogs"
              ) && (
                <MDBDropdownItem
                  onClick={() => this.showOutboundNotification(result)}
                >
                  <MDBIcon icon="envelope-open" className="cyan-text" />{" "}
                  Notification Logs
                </MDBDropdownItem>
              )}
            </MDBDropdownMenu>
          </MDBDropdown>
        );
        return rowData;
      })
      .value();
    data["rows"] = rows;
    return (
      <MDBContainer>
        {this.renderFilters()}
        <MDBTable responsive striped bordered className="nested-table">
          <MDBTableHead>
            <tr>
              <th width="40%">Customer Details</th>
              <th width="40%">Membership Details</th>
              <th width="4%">
                Allow other members to see my details for bookings
              </th>
              <th width="4%">Is Allow Booking</th>
              {!isDefaultUser ? (
                <>
                  <th width="4%">Created</th>
                  <th width="4%">Updated</th>
                </>
              ) : (
                ""
              )}

              <th width="10%"></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            {this.renderTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={resultContainer.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  showBookingHistory = (customer, isPaymentPendingreport = false) => {
    if (customer !== null) {
      this.setState({
        showBookingHistoryModal: true,
        customerId: customer.id,
        isPaymentPendingreport,
      });
    } else {
      this.setState({
        showBookingHistoryModal: false,
        customerId: 0,
        isPaymentPendingreport,
      });
    }
  };

  showOutboundNotification = (customer) => {
    if (customer !== null) {
      this.setState({
        showOutboundNotification: true,
        customerId: customer.id,
      });
    } else {
      this.setState({ showOutboundNotification: false, customerId: 0 });
    }
  };

  renderCustomerOutboundNotification() {
    const { showOutboundNotification, customerId } = this.state;
    if (showOutboundNotification) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return (
      <div>
        {customerId > 0 ? (
          <MDBModal
            isOpen={showOutboundNotification}
            size="xl"
            wrapClassName="scrollable"
            toggle={() => this.showOutboundNotification(null)}
          >
            <MDBModalBody>
              <ReportEmailLog customerId={customerId} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showOutboundNotification(null)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : (
          <span></span>
        )}
      </div>
    );
  }

  togglePaymentReferenceModal = (isShow = true) => {
    this.setState({ isShowPaymentReferenceModal: isShow });
  };

  renderCustomerBookingHistory() {
    const {
      showBookingHistoryModal,
      customerId,
      isPaymentPendingreport,
      bulkPaymentPendingBookingRefNo,
      isShowPaymentReferenceModal,
    } = this.state;
    if (showBookingHistoryModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return (
      <div>
        {customerId > 0 ? (
          <MDBModal
            isOpen={showBookingHistoryModal}
            size="xl"
            wrapClassName="scrollable"
            toggle={() => this.showBookingHistory(null)}
          >
            <MDBModalHeader>
              {isPaymentPendingreport
                ? "Payment Pending Bookings"
                : "Customer Bookings"}
            </MDBModalHeader>
            <MDBModalBody>
              {isPaymentPendingreport ? (
                <>
                  <PaymentPendingReport
                    isFromCustomerPageModal={true}
                    customerId={customerId}
                  />
                  <PaymentReferenceNumberModal
                    isShowPaymentReferenceModal={isShowPaymentReferenceModal}
                    togglePaymentReferenceModal={
                      this.togglePaymentReferenceModal
                    } // Callback function for closing modal
                    bulkPaymentPendingBookingRefNo={
                      bulkPaymentPendingBookingRefNo
                    }
                    closeModal={this.showBookingHistory}
                  />
                </>
              ) : (
                <ReportBooking customerId={customerId} />
              )}
            </MDBModalBody>

            <MDBModalFooter>
              {bulkPaymentPendingBookingRefNo.length > 0 && (
                <MDBBtn
                  onClick={() => this.togglePaymentReferenceModal(true)}
                  color="primary"
                >
                  Mark as Paid
                </MDBBtn>
              )}

              <MDBBtn
                color="secondary"
                onClick={() => this.showBookingHistory(null)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : (
          <span></span>
        )}
      </div>
    );
  }

  getValueOfSelectLimit = (value) => {
    let limit = value.length > 0 ? value[0] : "";
    this.setState({ limit: limit });
    this.debouncedDoFilter();
  };

  getValueOfIsApproved = (value) => {
    let val = value.length > 0 ? value[0] : "";
    if (val != "") {
      this.setState({ membership_status: val });
      this.debouncedDoFilter();
    }
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  doFilter() {
    this.props.showHideLoader(true);
    const { status, page, limit, query, membership_status } = this.state;
    let params = {};

    if (
      typeof this.props.membershipId !== "undefined" &&
      this.props.membershipId > 0
    ) {
      params["membershipId"] = this.props.membershipId;
    }

    if (membership_status !== undefined) {
      params["membershipStatus"] = membership_status;
    }

    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }
    if (query !== undefined) {
      params["query"] = query;
    }
    this.props.getCustomers(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    customerResponse: state.customerResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
    paymentResponse: state.paymentResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getCustomers,
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertCustomer,
    clearInsertCustomerSuccessResponse,
    clearInsertCustomerFailedResponse,
    updateCustomer,
    clearUpdateCustomerSuccessResponse,
    clearUpdateCustomerFailedResponse,
    deleteCustomer,
    clearDeleteCustomerSuccessResponse,
    clearDeleteCustomerFailedResponse,
    showNotification,
    clearNotification,
    genModalCustomer,
    getMemberships,
  })(ManageCustomer)
);
