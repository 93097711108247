import {
  GEN_SEARCH_VOUCHER,
  GEN_SEARCH_BOOKING,
  GEN_SEARCH_CUSTOMER,
  GEN_SEARCH_FAILED_HANDLER,
} from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case GEN_SEARCH_VOUCHER:
      response["data"] = data.vouchers;
      break;
    case GEN_SEARCH_BOOKING:
      response["data"] = data.bookings;
      break;
    case GEN_SEARCH_CUSTOMER:
      response["data"] = data.customers;
      break;
    case GEN_SEARCH_FAILED_HANDLER:
      response["payload"] = action.payload;
      break;
    default:
      return state;
  }
  return response;
};
