import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBTabContent,
  MDBTabPane,
  MDBNavItem,
  MDBNavLink,
  MDBNav,
} from "mdbreact";
import { Radio } from "antd";
import {
  showHideLoader,
  toggleModal,
  genModalRuleConditionEvaluator,
  genModalRuleTrigger,
} from "../../../actions";

import _ from "lodash";

import { GEN_MODAL_RULES_ENGINE } from "../../../actions/types";

import {
  insertBusinessRule,
  updateBusinessRule,
  updateBusinessRuleMessages,
  deleteBusinessRuleTrigger,
  deleteBusinessRuleEvaluator,
} from "../../../actions/ruleEngineAction";

import RuleEvaluatorModal from "./RuleEvaluatorModal";
import RuleTriggerModal from "./RuleTriggerModal";

class RulesEngine extends React.Component {
  state = {
    collapseID: "collapseId1",
    businessRuleMessageActiveNav: "admin_nav",

    openedCollapseIds: ["collapseId1"],
    b_rule_id: 0,
    b_rule_uuid: "",
    b_rule_displayname: "",
    b_rule_shortname: "",
    b_rule_description: "",
    b_rule_match_type: "match_all",
    b_rule_type: "",
    b_customer_type: "both",

    b_rule_trigger_type: "booking",

    b_rule_evaluators: [],
    operatorList: [
      {
        text: "Is equal to",
        value: "is_equals",
      },
      {
        text: "Not equal to",
        value: "not_equal",
      },
      {
        text: "Less than or equal",
        value: "lte",
      },

      {
        text: "Greater than or equal",
        value: "gte",
      },
      {
        text: "Between",
        value: "in_between",
      },
      {
        text: "Should match",
        value: "is_in",
      },
      {
        text: "Should not match",
        value: "not_in",
      },
      {
        checked: false,
        disabled: false,
        text: "Maximum",
        value: "maximum",
      },
    ],
    allowedOperators: [],

    b_rule_messages: {
      admin: {
        user_type: "admin",
        message: "",
        action: "warn",
        displayName: "Admin",
      },

      private_user: {
        user_type: "private_user",
        message: "",
        action: "block",
        displayName: "Private User",
      },

      public_user: {
        user_type: "public_user",
        message: "",
        action: "block",
        displayName: "Public User",
      },
    },
  };

  toggleBusinessRuleAccordion = (collapseID) => {
    let { openedCollapseIds } = this.state;

    if (openedCollapseIds.includes(collapseID)) {
      openedCollapseIds = openedCollapseIds.filter(
        (collapseId) => collapseId != collapseID
      );
    } else {
      openedCollapseIds.push(collapseID);
    }

    this.setState({ openedCollapseIds });
  };

  toggleBusinessRuleMessageActiveNav = (tab) => (e) => {
    if (this.state.businessRuleMessageActiveNav !== tab) {
      this.setState({
        businessRuleMessageActiveNav: tab,
      });
    }
  };

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  setFormValues(businessRuleData, isEdit) {
    let formData = {
      b_rule_id: 0,

      b_rule_uuid: "",

      b_rule_displayname: "",
      b_rule_trigger_type: "booking",
      b_rule_shortname: "",
      b_rule_description: "",
      b_rule_isActive: false,
      b_rule_match_type: "match_all",
      b_rule_type: "",
      b_customer_type: "both",
      b_rule_evaluators: [],
      b_rule_triggers: [],

      b_rule_messages: {
        admin: {
          user_type: "admin",
          message: "",
          action: "warn",
          displayName: "Admin",
        },

        private_user: {
          user_type: "private_user",
          message: "",
          action: "block",
          displayName: "Private User",
        },

        public_user: {
          user_type: "public_user",
          message: "",
          action: "block",
          displayName: "Public User",
        },
      },
    };

    if (isEdit) {
      formData["b_rule_id"] = businessRuleData["id"];
      formData["b_rule_uuid"] = businessRuleData["uuid"];

      formData["b_rule_organisation_id"] = businessRuleData["organisation_id"];

      formData["b_rule_trigger_type"] =
        businessRuleData["rule_triggers"]["rule_type"];

      formData["b_rule_match_type"] = businessRuleData["match_type"];
      formData["b_rule_type"] = businessRuleData["rule_type"];
      formData["b_customer_type"] = businessRuleData["customer_type"];

      formData["b_rule_displayname"] = businessRuleData["display_name"];
      formData["b_rule_shortname"] = businessRuleData["shortname"];
      formData["b_rule_description"] = businessRuleData["description"];
      formData["b_rule_isActive"] = businessRuleData["is_active"];

      formData["b_rule_evaluators"] = businessRuleData["evaluators"];
      formData["b_rule_triggers"] = businessRuleData["rule_triggers"];

      if ("business_rule_messages" in businessRuleData) {
        const businessRuleMessage = businessRuleData["business_rule_messages"];
        const keys = Object.keys(businessRuleMessage);
        if (keys.length > 0) {
          formData["b_rule_messages"] =
            businessRuleData["business_rule_messages"];
        }
      }
    }

    return formData;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      if (response.type === GEN_MODAL_RULES_ENGINE) {
        let businessRule = response.data.payload;
        let isEdit = false;
        if (response.data.show && response.data.action === "edit") {
          isEdit = true;
        }
        let formData = this.setFormValues(businessRule, isEdit);
        this.setState({
          isEdit: isEdit,
          showModal: true,
          ...formData,
          wasValidatedRuleDetails: "",
          wasValidatedRuleTriggers: "",
          wasValidatedRuleMessage: "",
        });
      }
    }

    if (prevProps.rulesEngineResponse !== this.props.rulesEngineResponse) {
      let response = this.props.rulesEngineResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        let data = response.data;
        let formData = this.setFormValues(data.businessRule, true);
        this.setState({
          isEdit: true,
          wasValidatedRuleDetails: "",
          wasValidatedRuleTriggers: "",
          wasValidatedRuleMessage: "",
          ...formData,
        });
      }
    }
  }

  showModalFunc = (showModal, source) => {
    let isEdit = true;
    let dataSource = source;

    if (source === null) {
      isEdit = false;
      dataSource = {};
    }

    let formData = this.setFormValues(dataSource, isEdit);

    this.setState({
      isEdit: isEdit,
      showModal: showModal,
      ...formData,
    });
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  };

  ruleEvaluatorValueBuilder = (evaluator) => {
    let evaluatorDisplay = "";
    let evaluatorValue = evaluator["value"]["value"];
    let evaluatorType = evaluator["value"]["type"];

    switch (evaluator["operator"]) {
      case "is_equals":
      case "not_equal":
      case "lte":
      case "gte":
      case "maximum":
        evaluatorDisplay = evaluatorValue;

        if (evaluatorType === "boolean") {
          evaluatorDisplay = evaluatorValue === "1" ? "Yes" : "No";
        }

        break;
      case "in_between":
        evaluatorDisplay = `<b>From:</b> ${evaluatorValue["from"]} <br> <b>To:</b> ${evaluatorValue["to"]}`;

        break;
      default:
    }
    return evaluatorDisplay;
  };

  showRuleEvaluatorModal = (source, isEdit) => {
    if (isEdit) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalRuleConditionEvaluator(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalRuleConditionEvaluator(attr);
    }
  };

  showRuleTriggerModal = (source, isEdit) => {
    if (isEdit) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalRuleTrigger(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalRuleTrigger(attr);
    }
  };

  businessRuleDetailsSubmitHandler = (event) => {
    const {
      b_rule_id,
      b_rule_uuid,
      b_rule_trigger_type,
      b_rule_displayname,
      b_rule_shortname,
      b_rule_description,
      b_rule_match_type,
      b_rule_type,
      b_customer_type,
      isEdit,
    } = this.state;
    let body = {
      id: b_rule_id,
      shortname: b_rule_shortname,
      display_name: b_rule_displayname,
      rule_type: b_rule_type,
      customer_type: b_customer_type,
      match_type: b_rule_match_type,
      description: b_rule_description,
      rule_trigger_type: b_rule_trigger_type,
    };
    event.preventDefault();
    this.setState({ wasValidatedRuleDetails: "was-validated" });
    if (event.target.checkValidity()) {
      if (isEdit) {
        var result = DevExtremeConfirm(
          "Are you sure you want to save changes for business rule details?"
        );

        result.then((dialogResult) => {
          if (dialogResult) {
            this.props.showHideLoader(true);

            this.props.updateBusinessRule(
              body,
              b_rule_uuid,
              this.props.authUser.sessionToken
            );
          } else {
            this.setState({ wasValidatedRuleDetails: "" });
          }
        });
      } else {
        this.props.showHideLoader(true);

        this.props.insertBusinessRule(body, this.props.authUser.sessionToken);
      }
    }
  };

  businessRuleMessageSubmitHandler = (event) => {
    const { b_rule_id, b_rule_type, b_rule_messages } = this.state;

    let body = {
      b_rule_id: b_rule_id,
      b_rule_type,
      b_rule_messages,
    };
    event.preventDefault();
    this.setState({ wasValidatedRuleMessage: "was-validated" });
    if (event.target.checkValidity()) {
      if (b_rule_type == "BANNER_MESSAGE") {
        var result = DevExtremeConfirm(
          "Do you want to reset hidden banner message for all customers?"
        );

        result.then((dialogResult) => {
          if (dialogResult) {
            this.props.showHideLoader(true);

            this.props.updateBusinessRuleMessages(
              { ...body, resetHiddenBannerMessage: true },
              this.props.authUser.sessionToken
            );
          } else {
            this.props.updateBusinessRuleMessages(
              { ...body, resetHiddenBannerMessage: false },
              this.props.authUser.sessionToken
            );
            this.setState({ wasValidatedRuleMessage: "" });
          }
        });
      } else {
        var result = DevExtremeConfirm(
          "Are you sure you want to save changes for business rule messages?"
        );

        result.then((dialogResult) => {
          if (dialogResult) {
            this.props.showHideLoader(true);

            this.props.updateBusinessRuleMessages(
              body,
              this.props.authUser.sessionToken
            );
          } else {
            this.setState({ wasValidatedRuleMessage: "" });
          }
        });
      }
    }
  };

  deleteRuleTrigger = (uuid) => {
    var result = DevExtremeConfirm(
      "Are you sure you want to delete rule trigger?"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);

        this.props.deleteBusinessRuleTrigger(
          {},
          uuid,
          this.props.authUser.sessionToken
        );
      }
    });
  };

  deleteRuleEvaluator = (uuid) => {
    var result = DevExtremeConfirm(
      "Are you sure you want to delete business rule evaluator?"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);

        this.props.deleteBusinessRuleEvaluator(
          {},
          uuid,
          this.props.authUser.sessionToken
        );
      }
    });
  };
  renderRuleTriggerValues(ruleTrigger, trigger_condition) {
    let renderedHtml = "";
    switch (trigger_condition) {
      case "is_in":
      case "not_in":
        const ruleTriggerValues = ruleTrigger["values"];
        renderedHtml = (
          <ul>
            {ruleTriggerValues.map((triggerValue) => {
              return <li>{triggerValue["value_display"]}</li>;
            })}
          </ul>
        );
        break;

      case "in_between":
      case "not_in_between":
        let valueFromDisplay = ruleTrigger["valueFrom"]["value"];
        let valueToDisplay = ruleTrigger["valueTo"]["value"];

        if (ruleTrigger["trigger_type"] === "days_of_the_week") {
          valueFromDisplay = ruleTrigger["valueFrom"]["text"];
          valueToDisplay = ruleTrigger["valueTo"]["text"];
        }

        renderedHtml = (
          <>
            <ul>
              <li>From: {valueFromDisplay} </li>
              <li>To: {valueToDisplay} </li>
            </ul>
          </>
        );

      default:
        break;
    }

    return renderedHtml;
  }

  renderTriggerTypeCondition(condition) {
    let displayText = "";
    switch (condition) {
      case "is_in":
        displayText = "Is in";
        break;
      case "not_in":
        displayText = "Not In";
        break;
      case "in_between":
        displayText = "Between";
        break;
      case "not_in_between":
        displayText = "Not in between";
        break;

      default:
        break;
    }

    return displayText;
  }

  render() {
    const {
      b_rule_uuid,
      b_rule_id,
      b_rule_displayname,
      b_rule_shortname,
      b_rule_description,
      b_rule_messages,
      b_rule_evaluators,
      b_rule_triggers,
      b_rule_isActive,
      b_rule_type,
      b_customer_type,

      operatorList,
      isEdit,
      showModal,
      openedCollapseIds,
    } = this.state;

    const rule_type_options = [
      { value: "PRE_BOOKING_VALIDATION", text: "Pre-booking validation" },
      { value: "GENERAL_BOOKING_WARNING", text: "Booking popup warning" },
      { value: "BANNER_MESSAGE", text: "Banner message" },
      {
        value: "BOOKING_CANCELLATION",
        text: "Booking cancellation validation",
      },
    ];

    const customer_type_options = [
      { value: "both", text: "Both" },
      { value: "primary", text: "Main Customer" },
      { value: "guest", text: "Guest" },
    ];
    return (
      <>
        <div className="child-popup">
          <MDBModal
            isOpen={showModal}
            size="lg"
            toggle={() => this.showModalFunc(false, null)}
          >
            <MDBModalBody>
              <MDBContainer className="md-accordion">
                <MDBCard className="mt-3">
                  <MDBCollapseHeader
                    tagClassName="d-flex justify-content-between"
                    onClick={() =>
                      this.toggleBusinessRuleAccordion("collapseId1")
                    }
                    className="text-primary font-weight-bold"
                  >
                    Business Rule Details
                    <MDBIcon
                      icon={
                        openedCollapseIds.includes("collapseId1")
                          ? "angle-up"
                          : "angle-down"
                      }
                    />
                  </MDBCollapseHeader>
                  <MDBCollapse
                    id={"collapseId1"}
                    isOpen={openedCollapseIds.includes("collapseId1")}
                  >
                    <MDBCardBody>
                      <form
                        className={`needs-validation ${this.state.wasValidatedRuleDetails}`}
                        onSubmit={this.businessRuleDetailsSubmitHandler}
                        noValidate
                      >
                        <MDBRow>
                          <MDBCol md="6" className="mb-3">
                            <label htmlFor="displayName" className="grey-text">
                              <span className="text-danger">*</span> Display
                              name
                            </label>
                            <input
                              value={b_rule_displayname}
                              onChange={this.formDataHandler}
                              name="b_rule_displayname"
                              type="text"
                              id="b_rule_displayname"
                              className="form-control"
                              placeholder="Enter display name..."
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide display name
                            </div>
                          </MDBCol>

                          <MDBCol md="6" className="mb-3">
                            <label htmlFor="shortName" className="grey-text">
                              <span className="text-danger">*</span> Shortname
                            </label>
                            <input
                              value={b_rule_shortname}
                              onChange={this.formDataHandler}
                              name="b_rule_shortname"
                              type="text"
                              id="b_rule_shortname"
                              className="form-control"
                              placeholder="Enter short name..."
                              required
                              disabled={isEdit}
                            />
                            <div className="invalid-feedback">
                              Please provide shortname
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="6" className="mb-3">
                            <label htmlFor="b_rule_type" className="grey-text">
                              <span className="text-danger">*</span> Rule type{" "}
                            </label>

                            <select
                              id="b_rule_type"
                              name="b_rule_type"
                              className="form-control"
                              defaultValue={b_rule_type}
                              onChange={this.formDataHandler}
                              required
                            >
                              <option value="">--Select Rule Type--</option>
                              {rule_type_options.map((rule_type) => (
                                <option value={rule_type["value"]}>
                                  {rule_type["text"]}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              Please select evaluator
                            </div>
                          </MDBCol>

                          <MDBCol md="6" className="mb-3">
                            <label
                              htmlFor="b_customer_type"
                              className="grey-text"
                            >
                              <span className="text-danger">*</span> Customer
                              Type{" "}
                            </label>

                            <select
                              id="b_customer_type"
                              name="b_customer_type"
                              className="form-control"
                              defaultValue={b_customer_type}
                              onChange={this.formDataHandler}
                              required
                            >
                              <option value="">--Select Customer Type--</option>
                              {customer_type_options.map((customer_type) => (
                                <option value={customer_type["value"]}>
                                  {customer_type["text"]}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              Please select customer type
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="12" className="mb-3">
                            <label htmlFor="displayName" className="grey-text">
                              <span className="text-danger">*</span> Description
                            </label>

                            <textarea
                              value={b_rule_description}
                              name="b_rule_description"
                              onChange={this.formDataHandler}
                              type="textarea"
                              id="b_rule_description"
                              className="form-control"
                              rows="3"
                              style={{ resize: "none" }}
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide description
                            </div>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="mb-4">
                          <MDBCol md="6"></MDBCol>
                          <MDBCol md="6">
                            <MDBBtn
                              color="primary"
                              size="sm"
                              type="submit"
                              className="float-right"
                            >
                              {isEdit ? "Update Details" : "Create Rule"}
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </form>
                    </MDBCardBody>
                  </MDBCollapse>
                </MDBCard>
              </MDBContainer>

              {isEdit && (
                <>
                  {/* Start rule triggers */}
                  <MDBContainer className="md-accordion">
                    <MDBCard className="mt-1">
                      <MDBCollapseHeader
                        tagClassName="d-flex justify-content-between"
                        onClick={() =>
                          this.toggleBusinessRuleAccordion("collapseId2")
                        }
                        className="text-primary font-weight-bold"
                      >
                        Rule Triggers
                        <MDBIcon
                          icon={
                            openedCollapseIds.includes("collapseId2")
                              ? "angle-up"
                              : "angle-down"
                          }
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        id={"collapseId2"}
                        isOpen={openedCollapseIds.includes("collapseId2")}
                      >
                        <MDBCardBody>
                          <div>
                            <MDBRow className="mb-4">
                              <MDBCol md="6"></MDBCol>
                              <MDBCol md="6">
                                <MDBBtn
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.showRuleTriggerModal(
                                      { business_rule_id: b_rule_id },
                                      false
                                    );
                                  }}
                                  className="float-right"
                                >
                                  Add Triggers
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </div>

                          <MDBTable responsive bordered>
                            <MDBTableHead>
                              <tr>
                                <th width="30%" className="text-center">
                                  {" "}
                                  Description
                                </th>
                                <th width="20%" className="text-center">
                                  Trigger Type
                                </th>

                                <th width="10%" className="text-center">
                                  {" "}
                                  Condition
                                </th>
                                <th width="30%" className="text-center">
                                  Values
                                </th>
                                <th width="10%"></th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {b_rule_triggers.map((ruleTrigger) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{ruleTrigger["description"]}</td>
                                      <td>{ruleTrigger["display_name"]}</td>
                                      <td>
                                        {this.renderTriggerTypeCondition(
                                          ruleTrigger["trigger_condition"]
                                        )}
                                      </td>
                                      <td>
                                        {this.renderRuleTriggerValues(
                                          ruleTrigger,
                                          ruleTrigger["trigger_condition"]
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <MDBIcon
                                          style={{ cursor: "pointer" }}
                                          icon="edit"
                                          className="cyan-text"
                                          onClick={() => {
                                            this.showRuleTriggerModal(
                                              ruleTrigger,
                                              true
                                            );
                                          }}
                                        />

                                        <MDBIcon
                                          style={{ cursor: "pointer" }}
                                          icon="trash"
                                          className="danger-text"
                                          onClick={() => {
                                            this.deleteRuleTrigger(
                                              ruleTrigger["uuid"]
                                            );
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </MDBTableBody>
                          </MDBTable>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>

                  {/* End rule triggers */}

                  {/* Start Business Rule Message */}
                  <MDBContainer className="md-accordion">
                    <MDBCard className="mt-1">
                      <MDBCollapseHeader
                        tagClassName="d-flex justify-content-between"
                        onClick={() =>
                          this.toggleBusinessRuleAccordion("collapseId3")
                        }
                        className="text-primary font-weight-bold"
                      >
                        Business Rule Message{" "}
                        <MDBIcon
                          icon={
                            openedCollapseIds.includes("collapseId3")
                              ? "angle-up"
                              : "angle-down"
                          }
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        id={"collapseId3"}
                        isOpen={openedCollapseIds.includes("collapseId3")}
                      >
                        <MDBContainer>
                          <form
                            className={`needs-validation ${this.state.wasValidatedRuleMessage}`}
                            onSubmit={this.businessRuleMessageSubmitHandler}
                            noValidate
                          >
                            <MDBNav className="nav-tabs">
                              {Object.values(b_rule_messages).map(
                                (businessRuleMessage) => {
                                  return (
                                    <>
                                      <MDBNavItem>
                                        <MDBNavLink
                                          link
                                          to="#"
                                          active={
                                            this.state
                                              .businessRuleMessageActiveNav ===
                                            `${businessRuleMessage["user_type"]}_nav`
                                          }
                                          onClick={this.toggleBusinessRuleMessageActiveNav(
                                            `${businessRuleMessage["user_type"]}_nav`
                                          )}
                                          role="tab"
                                        >
                                          {businessRuleMessage["displayName"]}
                                        </MDBNavLink>
                                      </MDBNavItem>
                                    </>
                                  );
                                }
                              )}
                            </MDBNav>
                            <MDBTabContent
                              activeItem={
                                this.state.businessRuleMessageActiveNav
                              }
                            >
                              {Object.values(b_rule_messages).map(
                                (businessRuleMessage) => {
                                  return (
                                    <MDBTabPane
                                      tabId={`${businessRuleMessage["user_type"]}_nav`}
                                      role="tabpanel"
                                    >
                                      {this.state
                                        .businessRuleMessageActiveNav ===
                                        `${businessRuleMessage["user_type"]}_nav` && (
                                        <>
                                          <MDBRow>
                                            <MDBCol md="12" className="mb-3">
                                              <label
                                                htmlFor={`${businessRuleMessage["user_type"]}_action`}
                                                className="grey-text  mr-3"
                                              >
                                                Action
                                              </label>

                                              <Radio
                                                value="warn"
                                                checked={
                                                  businessRuleMessage[
                                                    "action"
                                                  ] === "warn"
                                                }
                                                onChange={(e) => {
                                                  let { b_rule_messages } =
                                                    this.state;

                                                  b_rule_messages[
                                                    businessRuleMessage[
                                                      "user_type"
                                                    ]
                                                  ]["action"] = e.target.value;

                                                  this.setState({
                                                    b_rule_messages,
                                                  });
                                                }}
                                              >
                                                Warn
                                              </Radio>

                                              <Radio
                                                value="block"
                                                checked={
                                                  businessRuleMessage[
                                                    "action"
                                                  ] === "block"
                                                }
                                                onChange={(e) => {
                                                  let { b_rule_messages } =
                                                    this.state;

                                                  b_rule_messages[
                                                    businessRuleMessage[
                                                      "user_type"
                                                    ]
                                                  ]["action"] = e.target.value;

                                                  this.setState({
                                                    b_rule_messages,
                                                  });
                                                }}
                                              >
                                                Block
                                              </Radio>
                                            </MDBCol>
                                            <MDBCol md="12" className="mb-3">
                                              <label
                                                htmlFor={`${businessRuleMessage["user_type"]}_message`}
                                                className="grey-text"
                                              >
                                                {`Display message for ${businessRuleMessage["displayName"]}`}{" "}
                                                <span
                                                  data-mdb-toggle="tooltip"
                                                  data-html="true"
                                                  title="<b>Custom Variables</b><br>{{customerName}} - can be used to substitute the name of the selected customer"
                                                >
                                                  <MDBIcon icon="question-circle" />
                                                </span>
                                              </label>

                                              <textarea
                                                value={
                                                  businessRuleMessage["message"]
                                                }
                                                onChange={(e) => {
                                                  let { b_rule_messages } =
                                                    this.state;

                                                  b_rule_messages[
                                                    businessRuleMessage[
                                                      "user_type"
                                                    ]
                                                  ]["message"] = e.target.value;

                                                  this.setState({
                                                    b_rule_messages,
                                                  });
                                                }}
                                                type="textarea"
                                                htmlFor={`${businessRuleMessage["user_type"]}_message`}
                                                className="form-control"
                                                rows="3"
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </>
                                      )}
                                    </MDBTabPane>
                                  );
                                }
                              )}

                              <MDBRow className="mb-4">
                                <MDBCol md="6"></MDBCol>
                                <MDBCol md="6">
                                  <MDBBtn
                                    color="primary"
                                    size="sm"
                                    type="submit"
                                    className="float-right"
                                  >
                                    Update Messages
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>
                            </MDBTabContent>
                          </form>
                        </MDBContainer>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>
                  {/* End Business Rule Message */}

                  {/* Start Evaluator */}
                  <MDBContainer className="md-accordion">
                    <MDBCard className="mt-1">
                      <MDBCollapseHeader
                        tagClassName="d-flex justify-content-between"
                        onClick={() =>
                          this.toggleBusinessRuleAccordion("collapseId4")
                        }
                        className="text-primary font-weight-bold"
                      >
                        Evaluators{" "}
                        <MDBIcon
                          icon={
                            openedCollapseIds.includes("collapseId4")
                              ? "angle-up"
                              : "angle-down"
                          }
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        id={"collapseId4"}
                        isOpen={openedCollapseIds.includes("collapseId4")}
                      >
                        <MDBCardBody>
                          <div>
                            <MDBRow className="mb-4">
                              <MDBCol md="6"></MDBCol>
                              <MDBCol md="6">
                                <MDBBtn
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.showRuleEvaluatorModal(
                                      { business_rule_id: b_rule_id },
                                      false
                                    );
                                  }}
                                  className="float-right"
                                >
                                  Add evaluator
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </div>

                          <MDBTable responsive bordered>
                            {b_rule_evaluators.length === 0 ? (
                              <>
                                <MDBTableHead>
                                  <tr>
                                    <th
                                      width="100%"
                                      className="text-center text-danger"
                                    >
                                      <span className="font-weight-bold">
                                        Note:
                                      </span>{" "}
                                      This business rule will be evaluated as
                                      failed and will display the alert messages
                                      if there is no evaluators.{" "}
                                    </th>
                                  </tr>
                                </MDBTableHead>
                              </>
                            ) : (
                              <>
                                <MDBTableHead>
                                  <tr>
                                    <th width="30%">Name</th>
                                    <th width="30%"> Operator</th>
                                    <th width="30%">Value</th>
                                    <th width="10%"></th>
                                  </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                  {b_rule_evaluators.map((evaluator) => {
                                    const evaluator_details =
                                      evaluator["evaluator_details"];

                                    const matchedOperator = operatorList.find(
                                      (operator) =>
                                        operator["value"] ==
                                        evaluator["operator"]
                                    );
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {evaluator_details["display_name"]}
                                          </td>
                                          <td>{matchedOperator["text"]}</td>
                                          <td>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  this.ruleEvaluatorValueBuilder(
                                                    evaluator
                                                  ),
                                              }}
                                            ></span>
                                          </td>

                                          <td className="text-center">
                                            <MDBIcon
                                              style={{ cursor: "pointer" }}
                                              icon="edit"
                                              className="cyan-text"
                                              onClick={() => {
                                                this.showRuleEvaluatorModal(
                                                  evaluator,
                                                  true
                                                );
                                              }}
                                            />
                                            <MDBIcon
                                              style={{ cursor: "pointer" }}
                                              icon="trash"
                                              className="danger-text"
                                              onClick={() => {
                                                this.deleteRuleEvaluator(
                                                  evaluator["uuid"]
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </MDBTableBody>
                              </>
                            )}
                          </MDBTable>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>

                  {/* End Evaluator */}
                </>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModalFunc(false, null)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <RuleEvaluatorModal />
          <RuleTriggerModal />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    storeUserAccess: state.storeUserAccess,
    locations: state.locations,
    rulesEngineResponse: state.rulesEngineResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    toggleModal,
    genModalRuleConditionEvaluator,
    genModalRuleTrigger,
    insertBusinessRule,
    updateBusinessRule,
    updateBusinessRuleMessages,
    deleteBusinessRuleTrigger,
    deleteBusinessRuleEvaluator,
  })(RulesEngine)
);
