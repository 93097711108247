import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  USER_RESPONSE_HANDLER,
  GET_USERS,
  USER_DETAILS_RESPONSE_HANDLER,
  GET_SUBSCRIPTION_RESPONSE_HANDLER,
  SUBSCRIBE_RESPONSE_HANDLER,
} from "./types";

export const createUser = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/users`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: USER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response.data.message;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: USER_RESPONSE_HANDLER,
        payload: {
          status: 400,
          data: {
            message: errRes,
          },
        },
      });
    });
};

export const updateUser = (userId, body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/users/${userId}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: USER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response.data.message;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: USER_RESPONSE_HANDLER,
        payload: {
          status: 400,
          data: {
            message: errRes,
          },
        },
      });
    });
};

export const deleteUser = (userId, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .delete(`/users/${userId}`, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: USER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response.data.message;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: USER_RESPONSE_HANDLER,
        payload: {
          status: 400,
          data: {
            message: errRes,
          },
        },
      });
    });
};

export const resetPassword = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/users/reset/password`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: USER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response.data.message;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: USER_RESPONSE_HANDLER,
        payload: {
          status: 400,
          data: {
            message: errRes,
          },
        },
      });
    });
};

export const getUsers = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  const response = await bookingAppPrivate.get(`/users`, {
    headers: headers,
    params: params,
  });

  dispatch({ type: GET_USERS, payload: response });
};

export const getUserDetails = (sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/users/details`, {
      headers: headers,
      params: {},
    })
    .then((res) => {
      dispatch({ type: USER_DETAILS_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: USER_DETAILS_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const getUserSubscriptions = (sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/users/subscriptions`, {
      headers: headers,
      params: {},
    })
    .then((res) => {
      dispatch({ type: GET_SUBSCRIPTION_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_SUBSCRIPTION_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const subscribe = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/users/subscription`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: SUBSCRIBE_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: SUBSCRIBE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};
