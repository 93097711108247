import React from "react";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBAlert,
} from "mdbreact";
import _ from "lodash";

import {
  getCustomerBannerMessage,
  hideCustomerBannerMessage,
} from "../../../actions/customerAction";

import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

class BannerMessage extends React.Component {
  state = {
    bannerMessage: {},
  };
  componentDidMount() {
    this.props.getCustomerBannerMessage(
      {
        organisationId: this.props.organisationId,
      },
      this.props.authUser.sessionToken
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customerBannerMessageResponse !==
      this.props.customerBannerMessageResponse
    ) {
      let response = this.props.customerBannerMessageResponse;
      if (response.action == "GET_CUSTOMER_BANNER_MESSAGE") {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.bannerMessages.length > 0) {
            this.setState({
              bannerMessage: response.data.bannerMessages[0],
            });
          }
        }
      }
    }
  }
  render() {
    const { bannerMessage } = this.state;
    return (
      <>
        {bannerMessage.message && (
          <MDBAlert
            color={"info"}
            dismiss
            onClose={() => {
              var result = DevExtremeConfirm(
                "Do you want to permanently hide this alert message?"
              );

              result.then((dialogResult) => {
                if (dialogResult) {
                  this.props.hideCustomerBannerMessage(
                    { businessRuleId: bannerMessage.business_rule_id },
                    this.props.authUser.sessionToken
                  );
                }
              });
            }}
          >
            <span style={{ fontSize: "16px" }}>{bannerMessage.message}</span>
          </MDBAlert>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    customerBannerMessageResponse: state.customerBannerMessageResponse,
  };
};

export default connect(mapStateToProps, {
  getCustomerBannerMessage,
  hideCustomerBannerMessage,
})(BannerMessage);
