import {
  FAILED_HANDLER,
  GET_PAYMENT_REQUEST_DETAILS,
  GET_PAYMENT_REQUEST_TRANSACTIONS,
  GET_PAYMENT_TRANSACTIONS,
  SET_BULK_PAY_BOOKING_REFERENCE_NUMBER,
} from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case GET_PAYMENT_REQUEST_DETAILS:
      response["data"] = data.stripeData;
      break;
    case GET_PAYMENT_REQUEST_TRANSACTIONS:
      response["data"] = data.transactions;
      response["action"] = GET_PAYMENT_REQUEST_TRANSACTIONS;
      break;
    case SET_BULK_PAY_BOOKING_REFERENCE_NUMBER:
      response["data"] = action.payload;
      response["action"] = SET_BULK_PAY_BOOKING_REFERENCE_NUMBER;
      break;
    case GET_PAYMENT_TRANSACTIONS:
      response["data"] = action.payload;
      response["action"] = GET_PAYMENT_TRANSACTIONS;
      break;
    case FAILED_HANDLER:
      response["action"] = FAILED_HANDLER;
      response["payload"] = action.payload;
      response["data"] = action.payload.data;
      break;

    default:
      return state;
  }
  return response;
};
