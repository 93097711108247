import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import _ from "lodash";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  displayBreadcrumbs,
  showHideLoader,
  showNotification,
} from "../../../actions";
import {
  getAttributeChilds,
  getAttributeKeys,
  getAttributes,
  updateChildAttribute,
} from "../../../actions/attributeAction";
import { getOrganisations } from "../../../actions/organisationAction";
import CustomDataGrid from "../shared/CustomDataGrid";
import { validateAccess } from "../../../helper/utils";

class ManageOrganisationSetting extends React.Component {
  state = {
    resultContainer: {},
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    loader: true,
    filterLoaded: false,
    type: "organisation",
    attrPage: 1,
    attrLimit: 10,
    attrQuery: "",
    organisationUuid: "",
    parentAttributeId: "",
    locationAttributeKeysContainer: {},
    organisationAttributeChildContainer: {},
    showConfigs: false,
    configName: "",

    childAttrLimit: "10",
    childAttrQuery: "",
  };

  triggerEvent = (e) => {
    const { type } = this.state;
    console.log(e)
    let uuid = e.oldData.organisation_uuid;
    this.props.showHideLoader(true);
    this.props.updateChildAttribute(
      type,
      e.oldData.id,
      {
        attributeValue: e.newData.value,
        attributeKeyId: e.oldData.attribute_key_id,
        sourceUuid: uuid,
      },
      this.props.authUser.sessionToken
    );
    return false;
  };

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Start Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  endDatePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select End Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  });

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  componentDidMount() {
    let queryParams = queryString.parse(this.props.location.search);
    let organisationUuid = queryParams["sourceUuid"];

    this.props.showHideLoader(true);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Organisation Configuration Settings"
    );

    this.setState(
      {
        organisationUuid: organisationUuid,
      },
      this.doFilter
    );

    this.childPage = "1";
  }

  doAttrChildFilter = () => {
    const {
      childAttrLimit,
      childAttrQuery,
      parentAttributeId,
      organisationUuid,
      type,
    } = this.state;
    let params = {};
    if ((this.childPage + "").length > 0) {
      params["page"] = this.childPage;
    }

    if (childAttrLimit.length > 0) {
      params["limit"] = childAttrLimit;
    }

    if (childAttrQuery !== undefined) {
      params["q"] = childAttrQuery;
    }

    params["parentAttributeId"] = parentAttributeId;

    params["sourceUuid"] = organisationUuid;

    this.props.getAttributeChilds(
      type,
      params,
      this.props.authUser.sessionToken
    );
  };

  renderConfigs() {
    const { showConfigs, attrValue, type, configName } = this.state;
    if (showConfigs) {
      return (
        <div className="fix-fluid-modal">
          <MDBModal
            isOpen={showConfigs}
            size="fluid"
            toggle={() => {
              this.showConfigModals(false, null);
            }}
          >
            <form
              className={`needs-validation ${this.state.attrWasValidated}`}
              onSubmit={this.attrSubmitHandler}
              noValidate
            >
              <MDBModalHeader>{configName} Settings</MDBModalHeader>
              <MDBModalBody className="organisation-settings">
                <MDBRow>
                  <MDBCol className="organisation-attributes">
                    {this.renderAttributeTable()}
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    this.showConfigModals(false, null);
                  }}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      );
    }
    return <span></span>;
  }

  setRowChanges = (rowChanges) => {
    this.setState({
      rowChanges: rowChanges,
    });
  };

  getEditingRowIds = (editingRowIds) => {
    this.setState({
      editingRowIds: editingRowIds,
    });
  };

  getRowId = (row) => row.id;

  renderChildTotalResult() {
    const { organisationAttributeChildContainer } = this.state;
    if (typeof organisationAttributeChildContainer.data.page !== "undefined") {
      return (
        <span>
          Showing {organisationAttributeChildContainer.data.results.length} of{" "}
          {organisationAttributeChildContainer.data.total} result
        </span>
      );
    }
    return <span></span>;
  }

  handleChildPageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      childPage: selectedPage,
    });
    this.childPage = selectedPage;
    this.doAttrChildFilter();
  };

  renderAttributeTable() {
    const { organisationAttributeChildContainer } = this.state;
    const { storeUserAccess } = this.props;
    if (organisationAttributeChildContainer.status !== 200) {
      return;
    }
    let rows = organisationAttributeChildContainer.data.results;
    let columns = [
      {
        fieldName: "name",
        displayName: "Name",
        isEditable: false,
      },
      {
        fieldName: "pretty_name",
        displayName: "Pretty Name",
        isEditable: false,
      },
      {
        fieldName: "value",
        displayName: "Value",
        isEditable: true,
      },
    ];

    let actions = ["delete"];
    if (
      validateAccess(
        storeUserAccess,
        "OrganisationConfigurationSettings",
        "updateConfig"
      )
    ) {
      actions.push("edit");
    }
    return (
      <div>
        <CustomDataGrid
          source={rows}
          columns={columns}
          key="name"
          allowDeleting={() => {
            return false;
          }}
          allowUpdating={() => {
            return true;
          }}
          actions={actions}
          triggerUpdatingEvent={this.triggerEvent}
        />
        <MDBRow>
          <MDBCol md="12" className="pr-4 pl-4 pt-4">
            {this.renderChildTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={organisationAttributeChildContainer.data.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handleChildPageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  renderAttrTotalResult() {
    if (this.props.getAttrSuccessResponse.status === 200) {
      return (
        <span>
          Showing{" "}
          {this.props.getAttrSuccessResponse.data.attributes.results.length}{" "}
          result
        </span>
      );
    }
    return <span></span>;
  }

  render() {
    return (
      <div>
        {this.renderTable()}
        {this.renderConfigs()}
      </div>
    );
  }

  createTypeOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Log Type",
      value: "",
    });
    let response = this.props.getAttrSuccessResponse;

    if (
      response.search_aggs !== undefined &&
      response.search_aggs.type !== undefined
    ) {
      let buckets = response.search_aggs.type.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  createUserOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select User",
      value: "",
    });
    let response = this.props.getAttrSuccessResponse;
    if (
      response.search_aggs !== undefined &&
      response.search_aggs.user !== undefined
    ) {
      let buckets = response.search_aggs.user.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getAttrSuccessResponse !== this.props.getAttrSuccessResponse
    ) {
      this.setState({
        resultContainer: this.props.getAttrSuccessResponse.data.attributes,
        attributeFullResponse: this.props.getAttrSuccessResponse.data,
        page: this.props.getAttrSuccessResponse.page,
      });

      if (!this.state.filterLoaded) {
        this.setState({
          filterLoaded: true,
          typeOptions: this.createTypeOptions(),
          userOptions: this.createUserOptions(),
        });
      }
      this.props.showHideLoader(false);
    }

    if (
      prevProps.attributeChildResponse !== this.props.attributeChildResponse
    ) {
      this.props.showHideLoader(false);
      let response = this.props.attributeChildResponse;
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.fetchChildWithDelayToGetLatestUpdate();
        }
      } else {
        this.setState({
          ...this.state,
          organisationAttributeChildContainer: response,
          page: response.page,
        });
      }
    }
  }

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        // {
        //     label: "ID",
        //     field: "heading0",
        //     sort: "asc",
        // },
        {
          label: "Short Name",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Display Name",
          field: "heading2",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading3",
          sort: "asc",
        },
        {
          label: "",
          field: "heading4",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    if (typeof resultContainer.results !== "undefined") {
      rows = _.chain(resultContainer.results)
        .map((result, index) => {
          return {
            //heading0: result.id,
            heading1: result.name,
            heading2: result.pretty_name,
            heading4: moment(result.created)
              .local()
              .format("DD/MM/YYYY HH:mm:ss"),
            header5: validateAccess(
              storeUserAccess,
              "OrganisationConfigurationSettings",
              "viewConfigs"
            ) && (
                <MDBBtn
                  onClick={() => this.showConfigModals(true, result)}
                  color="primary"
                  size="sm"
                  className="float-right"
                >
                  View Configs
                </MDBBtn>
              ),

            //heading5: moment(result.updated).local().format("DD/MM/YYYY"),
          };
        })
        .value();
      data["rows"] = rows;
      let hasPage = this.props.getAttrSuccessResponse != undefined;
      return (
        <MDBContainer>
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
          {!hasPage ? (
            <span></span>
          ) : (
              <MDBRow>
                <MDBCol md="12">
                  {this.renderTotalResult()}
                  <div className="float-right">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={resultContainer.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={4}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            )}
        </MDBContainer>
      );
    }
    return <div></div>;
  }

  showConfigModals = (showConfigs, source) => {
    this.props.showHideLoader(true);
    const { type } = this.state;
    if (!showConfigs) {
      this.setState({
        showConfigs: showConfigs,
      });
      this.props.showHideLoader(false);
    } else {
      this.setState({
        showConfigs: showConfigs,
        organisationUuid: source.organisation_uuid,
        organisationName: source.name,
        configName: source.pretty_name,
        parentAttributeId: source.id,
      });
      this.childPage = "1";
      this.debouncedDoAttrFilter();
    }
  };

  debouncedDoAttrFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doAttrChildFilter();
  });

  fetchChildWithDelayToGetLatestUpdate = _.debounce(function () {
    this.doAttrChildFilter();
  }, 1000);

  doAttrFilter() {
    const {
      attrPage,
      attrLimit,
      attrQuery,
      organisationUuid,
      type,
    } = this.state;
    let params = {};

    if ((attrPage + "").length > 0) {
      params["page"] = attrPage;
    }
    if (attrLimit.length > 0) {
      params["limit"] = attrLimit;
    }

    if (attrQuery !== undefined) {
      params["q"] = attrQuery;
    }

    params["sourceUuid"] = organisationUuid;

    this.props.getAttributes(type, params, this.props.authUser.sessionToken);
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (typeof resultContainer.page !== "undefined") {
      return (
        <span>
          Showing {resultContainer.results.length} of {resultContainer.total}{" "}
          result
        </span>
      );
    }
    return <span></span>;
  }

  doFilter() {
    const { page, limit, query, type, user, organisationUuid } = this.state;

    this.props.showHideLoader(true);

    let params = {};
    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (type.length > 0) {
      params["type"] = type;
    }

    if (query !== undefined) {
      params["query"] = query;
    }

    if (organisationUuid !== undefined) {
      params["sourceUuid"] = organisationUuid;
    }

    this.props.getAttributes(type, params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    this.debouncedDoFilter();
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getAttrSuccessResponse: state.getAttrSuccessResponse,
    getAttrKeysSuccessResponse: state.getAttrKeysSuccessResponse,
    attributeChildResponse: state.attributeChildResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getOrganisations,
    showHideLoader,
    getAttributeKeys,
    getAttributes,
    getAttributeChilds,
    updateChildAttribute,
    showNotification,
  })(ManageOrganisationSetting)
);
