import { ALERT_MESSAGE } from "../actions/types";

export default (
  alert = {
    title: "",
    text: "",
    type: "",
  },
  action
) => {
  if (action.type === ALERT_MESSAGE) {
    return action.payload;
  }
  return alert;
};
