import React from "react";
import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getPaymentReports } from "../../../actions/paymentAction";
import { showHideLoader } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs } from "../../../actions";
import _ from "lodash";
import moment from "moment";
import ReactExport from "react-data-export";
import { extractTimezone } from "../../../helper/utils";
import { getLocationList } from "../../../actions/locationAction";

class PaymentReports extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: "",
    endDateFilter: "",
    limitOptions: [],
    limit: "10",
    status: "",
    page: "1",
    tableBookingReports: [],
    excelPaymentReports: [],
    isExportAllData: 0, //False will export curent page, True will export all data
    exportDataSelectOptions: [],
    selectedLocation: 2, //Default Tennis
    locationSelectOptions: [],

    reportSearchTypeOptions: [
      {
        text: "By Payment Date",
        value: "paymentDate",
      },
      {
        text: "By Booking Date",
        value: "bookingDate",
      },
    ],
    reportSearchType: "paymentDate",
  };

  constructor(props) {
    super(props);
    this.state.startDateFilter = new Date(
      moment().startOf("month").format("YYYY-MM-DD")
    );
    this.state.endDateFilter = new Date(
      moment().endOf("month").format("YYYY-MM-DD")
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  getValueOfSelectedLocation = (value) => {
    value = value.length > 0 ? value[0] : false;
    if (value) {
      this.debouncedDoFilter();
    }
    this.setState({ selectedLocation: value });
  };

  getValueOfSelectedSearchType = (value) => {
    value = value.length > 0 ? value[0] : false;
    if (value) {
      this.debouncedDoFilter();
    }
    this.setState({ reportSearchType: value });
  };

  initExportTypeOption() {
    let options = [
      {
        checked: true,
        disabled: false,
        text: "Current page",
        value: 0,
      },
      {
        checked: false,
        disabled: false,
        text: "All records",
        value: 1,
      },
    ];

    this.setState({ exportDataSelectOptions: options });
  }

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  getTzDetails(dt = new Date()) {
    return extractTimezone("Australia/Sydney", dt);
  }

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  componentDidMount() {
    this.props.showHideLoader(true);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Bookings"
    );
    this.props.getLocationList({}, this.props.authUser.sessionToken);

    this.initLimitOptions();
  }

  renderExcelDataExport() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const { excelPaymentReports } = this.state;
    const excelDataSet = this.prepareExcelDataSet(excelPaymentReports);
    return (
      <ExcelFile
        element={
          <MDBBtn color="primary" type="button" size="md">
            Export data
          </MDBBtn>
        }
        filename="Payments Report"
      >
        <ExcelSheet dataSet={excelDataSet} name="Payments Report" />
      </ExcelFile>
    );
  }

  prepareExcelDataSet = (excelPaymentReports) => {
    let excelDataSet = [
      {
        columns: [
          {
            title: "Payment Method",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 150 },
          },

          {
            title: "Payment Reference #",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 200 },
          },
          {
            title: "Is Paid",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 80 },
          },
          {
            title: "Payment Date",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },
          {
            title: "Booking Reference Number",
            style: {
              font: { sz: "12", bold: true },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            },
            width: { wpx: 170 },
          },
          {
            title: "Booking Date",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },
          {
            title: "Duration",
            style: {
              font: { sz: "12", bold: true },
              alignment: { wrapText: true },
            },
            width: { wpx: 120 },
          },

          {
            title: "Amount",
            style: {
              font: { sz: "12", bold: true },
              width: { wpx: 220 },
              alignment: { horizontal: "center" },
            },
            width: { wpx: 150 },
          },
        ],

        data: excelPaymentReports.map((payment) => {
          return [
            {
              value: payment.payment_method,
              style: { font: { sz: "12" } },
            },
            {
              value: payment.payment_reference,
              style: { font: { sz: "12" } },
            },
            {
              value: payment.is_paid,
              style: { font: { sz: "12" } },
            },
            {
              value: payment.payment_date,
              style: { font: { sz: "12" } },
            },
            {
              value: payment.booking_reference,
              style: { font: { sz: "12" } },
            },
            {
              value: payment.booking_date,
              style: { font: { sz: "12" } },
            },
            {
              value: payment.duration,
              style: { font: { sz: "12" } },
            },

            {
              value: payment.amount.toFixed(2),
              style: { font: { sz: "12" } },
            },
          ];
        }),
      },
    ];

    return excelDataSet;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getPaymentReportsResponse !==
        this.props.getPaymentReportsResponse &&
      this.props.getPaymentReportsResponse.action === "GET_PAYMENT_TRANSACTIONS"
    ) {
      const responseData = this.props.getPaymentReportsResponse.data;

      if (responseData.data) {
        const paymentTransactions = responseData.data.paymentReports.results;
        const paymentMethods = responseData.data.paymentMethods;

        const formattedPaymentTransactions = paymentTransactions.map(
          (paymentTransaction) => {
            let is_paid = "No";
            let payment_method = "";
            let payment_date = "";
            let booking_reference = "";
            let booking_date = "";
            let duration = "";

            let payment_reference = "";
            if (paymentTransaction.remarks) {
              payment_reference = paymentTransaction.remarks;
            }

            let payment_amount = 0;
            if (paymentTransaction.amount) {
              payment_amount = paymentTransaction.amount;
            }

            if (paymentTransaction.payment_request) {
              const paymentRequest = paymentTransaction.payment_request;

              if (paymentRequest.is_paid == 1) {
                is_paid = "Yes";
              }

              const paymentMethod =
                paymentMethods[paymentRequest["payment_method_id"]];

              if (paymentMethod) {
                payment_method = paymentMethod.payment_vendor;
              }

              if (paymentTransaction.submitted_date) {
                payment_date = moment(paymentTransaction.submitted_date)
                  .tz(this.getTzDetails().timezone)
                  .format("DD/MM/YY HH:mm:ss");
              }
            }

            if (paymentTransaction.booking) {
              booking_reference = paymentTransaction.booking.reference;
              booking_date = moment(paymentTransaction.booking.start_time)
                .tz(this.getTzDetails().timezone)
                .format("DD/MM/YY");

              duration =
                moment(paymentTransaction.booking.start_time)
                  .tz(this.getTzDetails().timezone)
                  .format("HH:mm") +
                "-" +
                moment(paymentTransaction.booking.end_time)
                  .tz(this.getTzDetails().timezone)
                  .format("HH:mm");
            }

            return {
              booking_reference: booking_reference,
              is_paid: is_paid,
              payment_method: payment_method,
              payment_date: payment_date,
              amount: payment_amount,
              payment_reference: payment_reference,
              booking_date: booking_date,
              duration: duration,
            };
          }
        );

        let statesToUpdate = {
          excelPaymentReports: formattedPaymentTransactions,
        };

        if (!responseData.data.isExportExcelRequest) {
          // Update only  if not excel export request type
          statesToUpdate = {
            ...statesToUpdate,
            tableBookingReports: formattedPaymentTransactions,
            resultContainer: {
              totalPage: responseData.data.paymentReports.totalPage,
              returnedRangedFrom:
                responseData.data.paymentReports.returnedRangedFrom,
              returnedRangedTo:
                responseData.data.paymentReports.returnedRangedTo,
              returnedTotal: responseData.data.paymentReports.returnedTotal,
              total: responseData.data.paymentReports.total,
            },
            page: responseData.data.paymentReports.page,
          };
        }

        this.setState(statesToUpdate);
      }

      this.props.showHideLoader(false);
    }

    if (
      prevProps.getLocationListSuccessResponse !==
        this.props.getLocationListSuccessResponse &&
      this.props.getLocationListSuccessResponse.status === 200
    ) {
      const locations =
        this.props.getLocationListSuccessResponse.data.locations.results;

      const locationSelectOptions = locations.map((location) => {
        return {
          text: location.name,
          value: location.id,
        };
      });

      this.setState({ locationSelectOptions });
      this.props.showHideLoader(false);
    }
  }

  renderBookingReports(tableBookingReports) {
    let rows = _.chain(tableBookingReports)
      .map((result, index) => {
        let data = {};

        data = {
          ...data,
          heading_payment_method: result.payment_method,
          heading_payment_reference: result.payment_reference,
          heading_is_paid: result.is_paid,
          heading_payment_date: result.payment_date,
          heading_booking_reference: result.booking_reference,
          heading_booking_date: result.booking_date,
          heading_duration: result.duration,
          heading_amount: "$" + result.amount.toFixed(2),
        };

        return data;
      })
      .value();
    return rows;
  }

  renderBookingReportsColumns() {
    let columns = [];

    columns = [
      ...columns,

      {
        label: "Payment Method",
        field: "heading_payment_method",
        sort: "asc",
      },
      {
        label: "Payment Reference #",
        field: "heading_payment_reference",
        sort: "asc",
      },
      {
        label: "Is Paid",
        field: "heading_is_paid",
        sort: "asc",
      },
      {
        label: "Payment Date",
        field: "heading_payment_date",
        sort: "asc",
      },

      {
        label: "Booking Reference Number",
        field: "heading_booking_reference",
        sort: "asc",
      },
      {
        label: "Booking Date",
        field: "heading_booking_date",
        sort: "asc",
      },
      {
        label: "Duration",
        field: "heading_duration",
        sort: "asc",
      },

      {
        label: "Amount",
        field: "heading_amount",
        sort: "asc",
      },
    ];

    return columns;
  }

  getValueOfSelectLimit = (value) => {
    value = value.length > 0 ? value[0] : "";
    this.setState({ limit: value, page: 1, isExportAllData: 0 });
    this.initExportTypeOption();
    if (value != "") {
      this.debouncedDoFilter();
    }
  };

  getValueOfSelectExportType = (value) => {
    value = value.length > 0 ? value[0] : false;

    if (value === 1) {
      this.debouncedDoFilter();
    }

    this.setState({ isExportAllData: value });
  };

  doFilter() {
    const {
      startDateFilter,
      endDateFilter,
      page,
      limit,
      isExportAllData,
      selectedLocation,
      reportSearchType,
    } = this.state;

    this.props.showHideLoader(true);

    let params = {};

    if (startDateFilter) {
      params["start_date"] = moment(startDateFilter).format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["end_date"] = moment(endDateFilter).format("YYYY-MM-DD");
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    params["isExportAllData"] = isExportAllData;

    params["reportSearchType"] = reportSearchType;

    if (selectedLocation) {
      params["location_id"] = selectedLocation;
    }

    return this.props.getPaymentReports(
      params,
      this.props.authUser.sessionToken,
      false
    );
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
      isExportAllData: 0,
    });
    this.initExportTypeOption();
    this.debouncedDoFilter();
  };

  render() {
    const { tableBookingReports, limitOptions, resultContainer } = this.state;

    const data = {
      columns: this.renderBookingReportsColumns(),
    };

    data.rows = this.renderBookingReports(tableBookingReports);

    return (
      <MDBContainer>
        <div>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <h3 className="mt-3"> Booking Payment Report</h3>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="3">
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select Start Date"
                  selected={this.state.startDateFilter}
                  maxDate={this.state.endDateFilter}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.startDateFilterOnChange}
                />
              </span>
            </MDBCol>
            <MDBCol md="3">
              {" "}
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select End Date"
                  selected={this.state.endDateFilter}
                  minDate={this.state.startDateFilter}
                  onChange={this.endDateFilterOnChange}
                  dateFormat="dd/MM/yyyy"
                />
              </span>
            </MDBCol>

            <MDBCol md="3">
              <MDBSelect
                outline
                color="primary"
                value={this.state.reportSearchType}
                options={this.state.reportSearchTypeOptions}
                label="Search Type"
                style={{ margin: 0 }}
                getValue={this.getValueOfSelectedSearchType}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="2">
              <MDBSelect
                outline
                color="primary"
                getValue={this.getValueOfSelectLimit}
                value={this.state.limit}
                options={limitOptions}
                label="Show entries"
              />
            </MDBCol>

            <>
              <MDBCol
                md="8"
                className="d-flex align-items-center  justify-content-end"
              >
                {this.renderExcelDataExport()}
              </MDBCol>
              <MDBCol md="2">
                <MDBSelect
                  outline
                  color="primary"
                  getValue={this.getValueOfSelectExportType}
                  value={this.state.isExportAllData}
                  options={this.state.exportDataSelectOptions}
                  label="Export type"
                />
              </MDBCol>
            </>
          </MDBRow>
        </div>
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            <br></br>
            {resultContainer && (
              <>
                <span>
                  Showing {resultContainer.returnedRangedFrom} -{" "}
                  {resultContainer.returnedRangedTo} of {resultContainer.total}{" "}
                  item(s)
                </span>
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={resultContainer.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getPaymentReportsResponse: state.paymentResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getLocationListFailedResponse: state.getLocationListFailedResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getPaymentReports,
    showHideLoader,
    getLocationList,
  })(PaymentReports)
);
