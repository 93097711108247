import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Autocomplete from "react-autocomplete";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBIcon,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import CreditHistoryModal from "../shared/CreditHistoryModal";
import ManageCreditModal from "../shared/ManageCreditModal";

import {
  displayBreadcrumbs,
  showHideLoader,
  showNotification,
} from "../../../actions";
import { getCredits } from "../../../actions/voucherAction";

import _ from "lodash";
import moment from "moment";
import { validateAccess } from "../../../helper/utils";

class ManageCredit extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    notification: {},
    isDefaultUser: false,
    isShowCreditHistoryModal: false,
    ManageCreditModal: false,
    selectedCreditHistory: [],
    isEdit: false,
    expiryDate: null,
    customerId: 0,
    creditAmount: 0,
    customerFullName: "",
    creditUuid: "",
  };
  componentDidMount() {
    const { storeUserAccess } = this.props;
    this.props.showHideLoader(true);
    this.props.displayBreadcrumbs("Dashboard / Administrator / Credits");
    this.initLimitOptions();
    this.page = "1";

    let isDefaultUser =
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails");
    this.setState({ isDefaultUser });
  }

  render() {
    return (
      <div>
        {this.renderTable()}

        {this.state.isShowCreditHistoryModal && (
          <CreditHistoryModal
            isShowCreditHistoryModal={this.state.isShowCreditHistoryModal}
            toggleCreditHistoryModal={this.toggleCreditHistoryModal}
            creditHistory={this.state.selectedCreditHistory}
          />
        )}
        {this.state.isShowManageCreditModal && (
          <ManageCreditModal
            isShowManageCreditModal={this.state.isShowManageCreditModal}
            toggleManageCreditModal={this.toggleManageCreditModal}
            isEdit={this.state.isEdit}
            expiryDate={this.state.expiryDate}
            customerId={this.state.customerId}
            creditAmount={this.state.creditAmount}
            customerFullName={this.state.customerFullName}
            creditUuid={this.state.creditUuid}
          />
        )}
      </div>
    );
  }

  toggleCreditHistoryModal = (isShow = false) => {
    this.setState({ isShowCreditHistoryModal: isShow });
  };

  toggleManageCreditModal = (isShow = false, isEdit = false) => {
    this.setState({ isShowManageCreditModal: isShow, isEdit: isEdit });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.voucherResponse !== this.props.voucherResponse) {
      let response = this.props.voucherResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.debouncedDoFilterWithDelay();
        }

        const isShowCredit = false;

        this.toggleManageCreditModal(isShowCredit);
      } else {
        this.setState({
          resultContainer: this.props.voucherResponse.data,
        });
      }
    }
  }

  debouncedDoFilterWithDelay = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  }, 1100);

  debouncedDoFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  });

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  renderFilters() {
    const { limitOptions, statusOptions } = this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Credits</h3>
          </MDBCol>
          {validateAccess(storeUserAccess, "Vouchers", "add") && (
            <MDBCol md="6">
              <MDBBtn
                color="primary"
                onClick={() => {
                  const isEditCredit = false;
                  const isShowCredit = true;

                  this.setState({
                    expiryDate: null,
                    customerId: 0,
                    creditAmount: 0,
                    customerFullName: "",
                    creditUuid: "",
                  });
                  this.toggleManageCreditModal(isShowCredit, isEditCredit);
                }}
                className="float-right"
              >
                Add Credit
              </MDBBtn>
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  renderTable() {
    const { resultContainer, isDefaultUser } = this.state;
    const { storeUserAccess } = this.props;
    let data = {};

    let columns = [];

    if (isDefaultUser) {
      columns = [
        {
          label: "Credit Code",
          field: "headingCode",
          sort: "asc",
        },
        {
          label: "Amount",
          field: "headingAmount",
          sort: "asc",
        },
        {
          label: "Balance",
          field: "headingBalance",
          sort: "asc",
        },
        {
          label: "Expiry",
          field: "headingExpiry",
          sort: "asc",
        },
        {
          label: "Description",
          field: "headingDescription",
          sort: "asc",
        },
        {
          label: "Created",
          field: "headingCreated",
          sort: "asc",
        },
        {
          label: "Updated",
          field: "headingUpdated",
          sort: "asc",
        },
        {
          label: "",
          field: "headingAction",
          sort: "asc",
        },
      ];
    } else {
      columns = [
        {
          label: "Credit Code",
          field: "headingCode",
          sort: "asc",
        },
        {
          label: "Amount",
          field: "headingAmount",
          sort: "asc",
        },
        {
          label: "Balance",
          field: "headingBalance",
          sort: "asc",
        },
        {
          label: "Expiry",
          field: "headingExpiry",
          sort: "asc",
        },
        {
          label: "Description",
          field: "headingDescription",
          sort: "asc",
        },
        {
          label: "Issued To",
          field: "headingIssuedTo",
          sort: "asc",
        },
        {
          label: "Generated By",
          field: "headingGeneratedBy",
          sort: "asc",
        },
        {
          label: "Created",
          field: "headingCreated",
          sort: "asc",
        },
        {
          label: "Updated",
          field: "headingUpdated",
          sort: "asc",
        },
        {
          label: " ",
          field: "headingAction",
          sort: "asc",
        },
      ];
    }

    data = { columns: columns };

    let rows = [];
    rows = _.chain(resultContainer.results)
      .map((result, index) => {
        let creditSource = "Generated by booking cancellation";

        if (result.source == "booking.duration_changed") {
          creditSource = "Generated by updating booking duration";
        } else if (result.source == "credit.admin_generate") {
          creditSource = "Generated by admin";
        } else if (result.source == "vouchers.migrated_to_credits") {
          creditSource = "Migrated from old vouchers";
        } else {
          creditSource = "Generated by booking cancellation";
        }

        let dataRows = {
          headingCode: result.credit_code,
          headingAmount: "$" + result.initial_amount.toFixed(2),
          headingBalance: "$" + result.remaining_balance.toFixed(2),
          headingExpiry: result.expiry_date,
          headingDescription: creditSource,
          headingIssuedTo:
            result.customer !== undefined && result.customer.fullname !== null
              ? result.customer.fullname
              : "",
          headingGeneratedBy:
            result.generated_by !== undefined &&
            result.generated_by.user_name !== null
              ? result.generated_by.user_name
              : "",
          headingCreated: moment(result.created).local().format("DD/MM/YYYY"),
          headingUpdated: moment(result.updated).local().format("DD/MM/YYYY"),
          headingAction: (
            <div className="text-center">
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary" size="sm">
                  Actions
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  {!this.state.isDefaultUser && (
                    <MDBDropdownItem
                      onClick={() => {
                        const isEditCredit = true;
                        const isShowCredit = true;

                        this.setState({
                          expiryDate: result.expiry_date,
                          customerId: result.customer.id,
                          creditAmount: result.initial_amount,
                          customerFullName: result.customer.fullname,
                          creditUuid: result.credit_uuid,
                        });

                        this.toggleManageCreditModal(
                          isShowCredit,
                          isEditCredit
                        );
                      }}
                    >
                      <MDBIcon icon="edit" className="cyan-text" /> Edit
                    </MDBDropdownItem>
                  )}

                  <MDBDropdownItem
                    onClick={() => {
                      this.setState({
                        selectedCreditHistory: result.credit_history,
                      });
                      this.toggleCreditHistoryModal(true);
                    }}
                  >
                    <MDBIcon icon="book-open" className="green-text" />{" "}
                    Transaction History
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          ),
        };

        if (isDefaultUser) {
          delete dataRows["headingIssuedTo"];
          delete dataRows["headingGeneratedBy"];
        }
        return dataRows;
      })
      .value();
    data["rows"] = rows;
    return (
      <MDBContainer>
        {this.renderFilters()}
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            {this.renderTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={resultContainer.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return <span>Showing {resultContainer.total} result</span>;
  }

  doFilter() {
    this.props.showHideLoader(true);
    const { limit, isDefaultUser } = this.state;

    let params = {};

    if ((this.page + "").length > 0) {
      params["page"] = this.page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (isDefaultUser) {
      params["isMemberUser"] = 1;
    }

    this.props.getCredits(params, this.props.authUser.sessionToken);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    // this.setState({
    //   page: selectedPage,
    // });
    this.page = selectedPage;
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    voucherResponse: state.voucherResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getCredits,
    showHideLoader,
    showNotification,
  })(ManageCredit)
);
