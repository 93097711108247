import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBAlert,
} from "mdbreact";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { validateLayout, alertMessage, doRedirected } from "../../../actions";
import { setCustomer } from "../../../actions/customerAction";
import {
  checkPublicBookingConflicts,
  doBooking,
} from "../../../actions/bookingAction";
import { requestPayment, calculatePrice } from "../../../actions/paymentAction";
import PageTitle from "../shared/PageTitle";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Modal from "../shared/Modal";
import {
  toggleModal,
  showNotification,
  clearNotification,
} from "../../../actions";
import moment from "moment";
import { showHideLoader } from "../../../actions";
import Loader from "../shared/Loader";
import RichTextRenderer from "../shared/RichTextRenderer";
import websocketApi from "../../../apis/websocketApi";
import { extractTimezone } from "../../../helper/utils";
import CustomDataGrid from "../shared/CustomDataGrid";
import { lockBookingTimeslot } from "../../../actions/resourceAction";

class Checkout extends React.Component {
  maxBookingParticipants = 0;

  state = {
    //-- todo should have reducer
    time: this.props.bookingDetails.time,
    duration: "",
    first_name: this.props.customer.first_name,
    last_name: this.props.customer.last_name,
    email: this.props.customer.email,
    mobile: this.props.customer.mobile,
    address: this.props.customer.address,
    iAggree: false,
    coupon: "",
    alert: {
      type: "",
      title: "",
      text: "",
    },
    totalPrice: 0,
    couponErrorMsg: "",
    invalidCoupon: false,
    couponDiscount: 0,
    locationTimezone: undefined,
    participants: [],

    lockingTimeSlotIntervalId: "",
  };

  constructor(props) {
    super(props);
    this.maxBookingParticipants =
      this.props.bookingDetails.maxBookingParticipants;
    this.participantUniqueValidation =
      this.participantUniqueValidation.bind(this);
    this.triggerEvent = this.triggerEvent.bind(this);
    this.triggerDeletedEvent = this.triggerDeletedEvent.bind(this);
    this.triggerInsertEvent = this.triggerInsertEvent.bind(this);
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.allowDeletingParticipant = this.allowDeletingParticipant.bind(this);
    this.allowUpdatingParticipant = this.allowUpdatingParticipant.bind(this);
  }

  participantUniqueValidation(data) {
    let result = _.find(this.state.participants, function (o) {
      return o.email == data.value && data.value != "" && o.id != data.data.id;
    });
    return result == undefined || result.length == 0;
  }

  computeInitalPrice() {
    let dayRange = this.props.bookingDetails.dayRange;
    let nightRange = this.props.bookingDetails.nightRange;
    let dayPrice = this.props.bookingDetails.dayPrice;
    let nightPrice = this.props.bookingDetails.nightPrice;
    let dayRangeFrom = _.split(dayRange, "-")[0];
    let dayRangeTo = _.split(dayRange, "-")[1];

    let origTime = this.props.bookingDetails.time;
    let from = _.split(origTime, "-")[0];
    let to = _.split(origTime, "-")[1];
    let totalPrice = 0;
    if (
      moment(to, "HH:mm").isSameOrAfter(moment(dayRangeFrom, "HH:mm")) &&
      moment(to, "HH:mm").isSameOrBefore(moment(dayRangeTo, "HH:mm"))
    ) {
      totalPrice = dayPrice * 2;
    } else {
      totalPrice = nightPrice * 2;
    }
    this.setState({ totalPrice: totalPrice });
  }

  computeTotalPrice(startTime, endTime) {
    let dayRange = this.props.bookingDetails.dayRange;
    let nightRange = this.props.bookingDetails.nightRange;
    let dayPrice = this.props.bookingDetails.dayPrice;
    let nightPrice = this.props.bookingDetails.nightPrice;

    let addedTime = startTime;
    let totalPrice = 0;

    // endTime = moment(endTime, "HH:mm").add(30, "minutes").format("HH:mm");

    while (addedTime !== endTime) {
      let dayRangeFrom = _.split(dayRange, "-")[0];
      let dayRangeTo = _.split(dayRange, "-")[1];

      addedTime = moment(addedTime, "HH:mm").add(30, "minutes").format("HH:mm");
      if (
        moment(addedTime, "HH:mm").isSameOrAfter(
          moment(dayRangeFrom, "HH:mm")
        ) &&
        moment(addedTime, "HH:mm").isSameOrBefore(moment(dayRangeTo, "HH:mm"))
      ) {
        totalPrice += dayPrice;
      } else {
        totalPrice += nightPrice;
      }
    }

    this.setState({ totalPrice: totalPrice });
  }

  updateTimeValue(duration) {
    let origTime = this.props.bookingDetails.time;
    let startTime = _.split(origTime, "-")[0];
    let endTime = moment(startTime, "HH:mm")
      .add(duration, "minutes")
      .format("HH:mm");

    this.setState({
      duration: duration,
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,
      time: startTime + "-" + endTime,
    });
  }

  onDurationChange = (evt) => {
    this.props.showHideLoader(true);
    let duration = evt.target.value;
    let origTime = this.props.bookingDetails.time;
    let startTime = _.split(origTime, "-")[0];
    let endTime = moment(startTime, "HH:mm")
      .add(duration, "minutes")
      .format("HH:mm");

    let selectedTime = startTime + "-" + endTime;

    let requestObj = {};
    requestObj["organisationId"] = this.props.bookingDetails.organisationId;
    requestObj["locationId"] = this.props.bookingDetails.locationId;
    requestObj["resourceId"] = this.props.bookingDetails.resourceId;
    requestObj["from"] = this.validateSelectedDuration().from;
    requestObj["to"] = this.validateSelectedDuration().to;
    const chunks = this.generateChunk(selectedTime);
    requestObj["chunks"] = chunks;

    // Send initial Request to lock the timeslot to check if there is conflict
    this.props.lockBookingTimeslot(requestObj, "").then((response) => {
      if (response.status === 200 && response.data) {
        if (response.data.isConflictTimeslot) {
          const conflictedTimeSlotFrom = moment(
            response.data.conflictedTimeSlot
          ).format("HH:mm");

          const conflictedTimeSlotTo = moment(response.data.conflictedTimeSlot)
            .add(30, "minutes")

            .format("HH:mm");

          this.props.showHideLoader(false);
          this.props.showNotification(
            "error",
            "Cannot extend duration, timeslot " +
              conflictedTimeSlotFrom +
              " - " +
              conflictedTimeSlotTo +
              "  already taken by other user.",
            60000
          );
        } else {
          this.updateTimeValue(duration);
          this.intervalLockBookingtimeslot(requestObj);
        }
      }
    });
  };

  setPrimaryParticipant(key, val) {
    let { participants } = this.state;

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == -1;
    });
    let participant = {};
    if (participantIndex < 0) {
      participant = {
        id: -1,
        is_primary: true,
        first_name: "",
        last_name: "",
        email: "",
      };
    } else {
      participant = participants[participantIndex];
    }

    if (key == "email" && val != "") {
      let result = _.find(participants, function (o) {
        return o.email == val && val != "";
      });
      this.resetAlertMessage();
      if (result != undefined) {
        alert = {
          title: "Please review the following error: ",
          text: [
            "Participant's email already added. Please enter an unique email",
          ],
          type: "danger",
        };
        this.setState({ alert });
        return;
      }
    }

    participant[key] = val;
    if (participantIndex < 0) {
      participants.unshift(participant);
    } else {
      participants[participantIndex] = participant;
    }
    this.setState({
      participants: participants,
    });
  }

  onFirstnameChange = (evt) => {
    this.setPrimaryParticipant("first_name", evt.target.value);
    this.setState({ first_name: evt.target.value });
  };

  onLastnameChange = (evt) => {
    this.setPrimaryParticipant("last_name", evt.target.value);
    this.setState({ last_name: evt.target.value });
  };

  onEmailChange = (evt) => {
    clearTimeout(this.state.timer);
    var timer = setTimeout(() => {
      if (this.state.coupon != "") {
        this.calculatePrice();
      }
    }, 1000);
    this.setPrimaryParticipant("email", evt.target.value);
    this.setState({ email: evt.target.value, timer: timer });
  };

  onContactNumberChange = (evt) => {
    this.setPrimaryParticipant("mobile", evt.target.value);
    this.setState({ mobile: evt.target.value });
  };

  onAddressChange = (evt) => {
    this.setPrimaryParticipant("address", evt.target.value);
    this.setState({ address: evt.target.value });
  };

  onACheckChange = (evt) => {
    this.setState({ iAggree: !this.state.iAggree });
  };

  onACouponChange = (evt) => {
    this.setState({
      coupon: evt.target.value,
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,
    });
  };

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: true,
      showSearch: false,
      showHeader: true,
      showLogin: true,
    };
    this.props.validateLayout(layout);
  }

  resetAlertMessage() {
    alert = {
      title: "",
      text: "",
      type: "",
    };
    this.setState({ alert });
  }

  showAlert() {
    if (this.state.alert.text !== "") {
      return (
        <MDBAlert color={`${this.state.alert.type}`}>
          <strong>{this.state.alert.title}</strong>
          <br />
          <ul className="text-left">
            {this.state.alert.text.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </MDBAlert>
      );
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateCheckout() {
    this.props.showHideLoader(true);
    this.resetAlertMessage();
    let errMgsArr = [];

    if (this.props.bookingDetails.location === "") {
      errMgsArr.push("No valid booking selected");
    }
    if (this.state.invalidCoupon) {
      errMgsArr.push("Invalid Discount code provided");
    }
    if (this.state.first_name.length <= 0) {
      errMgsArr.push("Firstname is required");
    }

    if (this.state.last_name.length <= 0) {
      errMgsArr.push("Lastname is required");
    }

    if (this.state.email.length <= 0) {
      errMgsArr.push("Email is required");
    }

    if (this.state.email.length > 0 && !this.validateEmail(this.state.email)) {
      errMgsArr.push("Invalid Email format");
    }

    let email = this.state.email;
    let result = _.find(this.state.participants, function (o) {
      return o.email == email && o.id != -1 && email != "";
    });

    if (result != undefined) {
      errMgsArr.push(
        "Participant's email already added. Please enter an unique email"
      );
    }

    if (this.state.mobile.length <= 0) {
      errMgsArr.push("Contact # is required");
    } else if (!/^[0-9]{10,15}$/.test(this.state.mobile)) {
      errMgsArr.push("Please enter a valid contact number");
    }

    if (!this.state.iAggree) {
      errMgsArr.push("Check the Terms and Condition if you agree");
    }

    if (errMgsArr.length > 0) {
      alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.setState({ alert });
      this.props.showHideLoader(false);
    } else {
      this.props.showHideLoader(true);
      let customer = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        mobile: this.state.mobile,
        address: this.state.address,
      };

      this.props.setCustomer(customer);
      this.props.doRedirected(false);
      this.submitHandler();
      // this.props.history.push("/payment");
    }
  }

  submitHandler = (event) => {
    this.checkPublicBookingConflicts();
  };

  checkPublicBookingConflicts() {
    let priceData = {
      amount: this.state.totalPrice,
      currency: "AUD",
      couponDiscount: this.state.couponDiscount,
    };
    if (this.state.coupon.length > 0) {
      priceData["coupon"] = this.state.coupon;
    }

    let participantsData = [];

    _.map(this.state.participants, (participant) => {
      participantsData.push(participant);
    });

    let details = {
      resourceData: {
        organisationId: this.props.bookingDetails.organisationId,
        locationId: this.props.bookingDetails.locationId,
        resourceId: this.props.bookingDetails.resourceId,
        resourceName: this.props.bookingDetails.resource,
        bookingData: {
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,
          chunks: this.generateChunk(this.state.time),
        },
      },
      priceData: priceData,
      customerData: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        mobile: this.state.mobile,
      },
      participantsData: participantsData,
    };
    this.props.checkPublicBookingConflicts(details, "");
  }

  debouncedPaymentRedirect = _.debounce(function () {
    this.props.doRedirected(true);
    this.props.showHideLoader(false);
    this.props.history.push("/payment");
  }, 100);

  debouncedConfirmBookingRedirect = _.debounce(function (uuid) {
    this.props.doRedirected(true);
    this.props.showHideLoader(false);
    this.props.history.push("/booking/success/" + uuid);
  }, 100);

  returnToHome() {
    window.location.replace("/");
  }

  getTzDetails(date = new Date()) {
    return extractTimezone(this.props.bookingDetails.timezone, date);
  }

  validateSelectedDuration() {
    let selectedTime = this.state.time;
    let reservationDate = moment(
      this.props.bookingDetails.reservationDate,
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");

    let tz = this.getTzDetails(new Date(reservationDate));
    let from =
      moment(reservationDate + "T" + _.split(selectedTime, "-")[0] + tz.offset)
        .utc()
        .format()
        .substring(0, 19) + "Z";

    let to =
      moment(reservationDate + "T" + _.split(selectedTime, "-")[1] + tz.offset)
        .utc()
        .format()
        .substring(0, 19) + "Z";

    return { from: from, to: to };
  }

  generateChunk(selectedTime) {
    let day = moment(
      this.props.bookingDetails.reservationDate,
      "DD/MM/YYYY"
    ).format("dddd");
    let from = _.split(selectedTime, "-")[0];
    let to = _.split(selectedTime, "-")[1];

    let addTime = from;
    let chunks = [];
    chunks.push({
      day: day,
      time: from,
    });
    to = moment(to, "HH:mm").subtract(30, "minutes").format("HH:mm");

    while (addTime !== to) {
      addTime = moment(addTime, "HH:mm").add(30, "minutes").format("HH:mm");

      chunks.push({
        day: day,
        time: addTime,
      });
    }

    return chunks;
  }

  calculatePrice() {
    const { email } = this.state;

    if (this.state.time === "" || this.state.time == undefined) {
      return "";
    }

    this.props.showHideLoader(true);

    let couponObj = {};
    if (this.state.coupon.length > 0) {
      couponObj["coupon"] = this.state.coupon;
    }

    let participantsData = [];

    _.map(this.state.participants, (participant) => {
      participantsData.push(participant);
    });

    let resourceData = {
      organisationId: this.props.bookingDetails.organisationId,
      locationId: this.props.bookingDetails.locationId,
      resourceId: this.props.bookingDetails.resourceId,
      resourceName: this.props.bookingDetails.resource,
      bookingData: {
        updateSchedule: false,
        from: this.validateSelectedDuration().from,
        to: this.validateSelectedDuration().to,
        chunks: this.generateChunk(this.state.time),
      },
    };
    let details = {
      resourceData: resourceData,
      priceData: couponObj,
      customerData: {
        email: email,
      },
      participantsData: participantsData,
    };

    let requestObj = {};
    requestObj["organisationId"] = resourceData.organisationId;
    requestObj["locationId"] = resourceData.locationId;
    requestObj["resourceId"] = resourceData.resourceId;
    requestObj["from"] = resourceData.bookingData.from;
    requestObj["to"] = resourceData.bookingData.to;
    const chunks = this.generateChunk(this.state.time);
    requestObj["chunks"] = chunks;
    // Send initial Request to lock the timeslot to check if there is conflict
    this.props.lockBookingTimeslot(requestObj, "").then((response) => {
      if (response.status === 200 && response.data) {
        if (response.data.isConflictTimeslot) {
          // Do not continue on calculating price if there is conflict
          this.props.showNotification(
            "error",
            "Selected slot already taken by other user. Please select another slot",
            60000
          );
          window.history.back();
          return;
        } else {
          this.intervalLockBookingtimeslot(requestObj);
        }
      }
    });

    this.setState({
      locked: details,
    });

    // if (websocketApi.isOpen) {
    //   websocketApi.send(JSON.stringify({ ...details, action: "lock" }));
    // }

    this.props.calculatePrice(details);
  }

  intervalLockBookingtimeslot(requestObj) {
    // clear the old interval then send another new time slot locking
    if (this.state.lockingTimeSlotIntervalId !== "") {
      clearInterval(this.state.lockingTimeSlotIntervalId);
    }

    // This is for continuous locking of selected timeslot while on the booking modal every 5 seconds
    let lockingTimeSlotIntervalId = setInterval(() => {
      this.props.lockBookingTimeslot(requestObj, "");
    }, 5000);

    this.setState({ lockingTimeSlotIntervalId: lockingTimeSlotIntervalId });
    return;
  }

  debouncedCalculatePrice = _.debounce(function () {
    this.calculatePrice();
  }, 1000);

  componentDidUpdate(prevProps, prevState) {
    this.validateBookingConflicts(prevProps, prevState);
    this.validateBookingResponse(prevProps, prevState);
    this.validateResourceResponse(prevProps, prevState);

    if (prevState.time !== this.state.time) {
      this.calculatePrice();
    }

    if (prevState.coupon !== this.state.coupon) {
      this.debouncedCalculatePrice();
    }

    if (
      prevProps.successHandlerResponse !== this.props.successHandlerResponse
    ) {
      if (
        this.props.successHandlerResponse !== null &&
        this.props.successHandlerResponse.status === 200
      ) {
        this.props.showHideLoader(false);
        this.debouncedSetPrice(
          this.props.successHandlerResponse.data.totalPrice
        );
        this.setState({
          couponDiscount: this.props.successHandlerResponse.data.couponDiscount,
        });
      }
    }

    if (prevProps.failedHandlerResponse !== this.props.failedHandlerResponse) {
      if (
        this.props.failedHandlerResponse !== null &&
        this.props.failedHandlerResponse.status === 400
      ) {
        if (this.props.failedHandlerResponse.data.errorMessages.length > 0) {
          this.setState({
            couponErrorMsg:
              this.props.failedHandlerResponse.data.errorMessages[0],
            invalidCoupon: true,
          });
        }

        this.props.showHideLoader(false);
        this.debouncedSetPrice(
          this.props.failedHandlerResponse.data.totalPrice
        );
        this.setState({
          couponDiscount: this.props.failedHandlerResponse.data.couponDiscount,
        });
        this.props.showHideLoader(false);
      }
    }

    this.validatePaymentResponse(prevProps, prevState);
  }

  validateResourceResponse(prevProps, prevState) {
    if (
      prevProps.resourceResponse !== this.props.resourceResponse &&
      this.props.resourceResponse.action ===
        "LOCK_RESOURCE_BOOKING_TIME_SLOT" &&
      this.props.resourceResponse.status === 400
    ) {
      if (this.state.lockingTimeSlotIntervalId !== "") {
        clearInterval(this.state.lockingTimeSlotIntervalId);
      }
    }
  }
  validateBookingConflicts = (prevProps, prevState) => {
    let bookingResponse = this.props.bookingResponse;
    if (prevProps.bookingResponse !== bookingResponse) {
      if (bookingResponse.action == "CONFLICTS") {
        let warnings = bookingResponse.data.conflicts;
        let validationMessages = bookingResponse.data.validationMessages;
        this.submitBookingHandler(validationMessages);
      }
    }
  };

  validateBookingResponse(prevProps, prevState) {
    //-- Success Response
    if (
      prevProps.bookingSuccessResponse !== this.props.bookingSuccessResponse &&
      this.props.bookingSuccessResponse.status === 200 &&
      this.props.bookingSuccessResponse.iscreate
    ) {
      let bookingRequestResponse = this.props.bookingSuccessResponse.data.data; //Booking Request

      let bookingDataResponse = this.props.bookingSuccessResponse.data; // Actual Booking Data

      if (bookingRequestResponse) {
        const bookingRequestUUID = bookingRequestResponse.booking_request_uuid;
        window.location.replace(
          "/checkoutpage?booking_request_uuid=" + bookingRequestUUID
        );
      } else if (bookingDataResponse) {
        this.props.showHideLoader(false);
        const bookingUUID = bookingDataResponse.bookingData.bookingUuid;
        this.props.history.push("/booking/success/" + bookingUUID);
      }
    }
  }

  confirmBooking = (conflicts, canBook) => {
    let selectedTime = this.state.time;
    let day = moment(this.props.selectedDate).format("YYYY-MM-DD");
    let from = _.split(selectedTime, "-")[0];
    let to = _.split(selectedTime, "-")[1];

    const modal = {
      isOpen: true,
      content: (
        <div>
          {conflicts.length > 0 ? (
            <span style={{ color: "red" }}>
              Booking cannot be created. Please review the validation messages
              below
            </span>
          ) : (
            ""
          )}
          <ul>
            {conflicts.map((warning, index) => (
              <li
                key={index}
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: warning.message }}
              ></li>
            ))}
          </ul>
        </div>
      ),
      title: <span>Booking Warnings</span>,
      negativeButtonText: "Cancel",
      positiveButtonText: "Yes, please proceed.",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.requestPayment.bind(this),
    };

    if (!canBook) {
      modal["hasPositiveButtonAction"] = false;
      delete modal["positiveButtonAction"];
      delete modal["positiveButtonColor"];
      delete modal["positiveButtonText"];
    }
    this.props.toggleModal(modal);
  };

  submitBookingHandler = (validationMessages) => {
    let canBook = true;

    if (validationMessages.length > 0) {
      let conflicts = [];
      _.map(validationMessages, function (value) {
        // If the validation messages has atleast 1 blocker, block the booking
        if (value.action === "BLOCK" && canBook === true) {
          canBook = false;
        }

        conflicts.push(value);
      });
      this.props.showHideLoader(false);
      this.confirmBooking(conflicts, canBook);
    } else {
      this.requestPayment();
    }
  };

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  toolbarItemRender() {
    return (
      <div className="informer">
        {this.maxBookingParticipants > 0 ? (
          <i>
            {" "}
            <small>
              (Max booking Participants is {this.maxBookingParticipants})
            </small>
          </i>
        ) : (
          ""
        )}
      </div>
    );
  }

  allowUpdatingParticipant(e) {
    return (
      (typeof e.row.data != "undefined" &&
        typeof e.row.data.is_primary == "undefined") ||
      !e.row.data.is_primary
    );
  }

  allowDeletingParticipant(e) {
    return (
      typeof e.row.data.is_primary == "undefined" || !e.row.data.is_primary
    );
  }

  triggerEvent = (e) => {
    let { participants } = this.state;

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });

    let data = { ...e.key, ...e.newData };
    let key = data.__KEY__;
    if (typeof data.__KEY__ == "undefined") {
      key = data.id;
    }

    if (participantIndex < 0) {
      participants.push(data);
    } else {
      participants[participantIndex] = data;
    }

    this.state.participants = participants;

    this.calculatePrice();

    return true;
  };

  triggerInsertEvent = (e) => {
    let { participants } = this.state;

    let data = e.data;
    let key = data.__KEY__;
    data["id"] = key;
    delete data.__KEY__;

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });

    if (participantIndex < 0) {
      participants.push(data);
    } else {
      participants[participantIndex] = data;
    }

    this.state.participants = participants;

    this.calculatePrice();

    return false;
  };

  triggerDeletedEvent = (e) => {
    let { participants } = this.state;

    let data = e.key;
    let key = data.__KEY__;
    if (typeof data.__KEY__ == "undefined") {
      key = data.id;
    }

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });

    participants.splice(participantIndex, 1);

    this.state.participants = participants;

    this.calculatePrice();

    return false;
  };

  validatePaymentResponse(prevProps, prevState) {
    if (prevProps.paymentRequestFailed !== this.props.paymentRequestFailed) {
      if (this.props.paymentRequestFailed === "Network error") {
        const modal = {
          isOpen: true,
          content: `We are unable to process your request. You will be redirected to Home page. Please try again later or you can contact System Administrator`,
          title: "Checkout failed",
          negativeButtonText: "",
          positiveButtonText: "Ok",
          positiveButtonAction: this.returnToHome.bind(this),
        };
        this.props.toggleModal(modal);
        this.props.showHideLoader(false);
      } else if (this.props.paymentRequestFailed.status === 400) {
        this.props.showNotification(
          "error",
          this.props.paymentRequestFailed.data.message
        );
        this.props.showHideLoader(false);
      }
    }

    if (prevProps.paymentRequestSuccess !== this.props.paymentRequestSuccess) {
      if (
        this.props.paymentRequestSuccess.status === 200 &&
        this.props.redirected === false
      ) {
        console.log("tsetrset", this.props.paymentRequestSuccess);
        console.log("tsetrset", this.props.paymentRequestSuccess.data);
        if (
          this.props.paymentRequestSuccess.data.ispaid !== undefined &&
          this.props.paymentRequestSuccess.data.ispaid
        ) {
          this.props.doRedirected(true);
          this.props.showHideLoader(false);
          this.props.history.push(
            "/booking/success/" +
              this.props.paymentRequestSuccess.data.bookingData.bookingUuid
          );
        } else {
          this.props.doRedirected(true);
          this.props.showHideLoader(false);
          const bookingRequestUUID =
            this.props.paymentRequestSuccess.data.response.booking_request_uuid;
          window.location.replace(
            "/checkoutpage?booking_request_uuid=" + bookingRequestUUID
          );
        }
      }
    }
  }

  requestPayment() {
    let priceData = {
      amount: this.state.totalPrice,
      currency: "AUD",
      couponDiscount: this.state.couponDiscount,
    };
    if (this.state.coupon.length > 0) {
      priceData["coupon"] = this.state.coupon;
    }

    let participantsData = [];

    _.map(this.state.participants, (participant) => {
      participantsData.push(participant);
    });

    let details = {
      resourceData: {
        organisationId: this.props.bookingDetails.organisationId,
        locationId: this.props.bookingDetails.locationId,
        resourceId: this.props.bookingDetails.resourceId,
        resourceName: this.props.bookingDetails.resource,
        bookingData: {
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,
          chunks: this.generateChunk(this.state.time),
        },
      },
      priceData: priceData,
      customerData: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        mobile: this.state.mobile,
      },
      participantsData: participantsData,
    };
    this.props.doBooking(details);
  }

  debouncedSetPrice = _.debounce(function (price) {
    this.setState({ totalPrice: price });
  }, 100);

  validateCalculatePriceResponse() {
    if (
      this.props.successHandlerResponse !== null &&
      this.props.successHandlerResponse.status === 200
    ) {
      this.debouncedSetPrice(this.props.successHandlerResponse.data.totalPrice);
    }
    return <span></span>;
  }

  componentDidMount() {
    this.setLayout();
    this.resetAlertMessage();
    this.modalConfig();
    // this.computeInitalPrice();
    if (this.props.bookingDetails.location !== "") {
      this.calculatePrice();
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.state.lockingTimeSlotIntervalId !== "") {
      clearInterval(this.state.lockingTimeSlotIntervalId);
    }
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Error",
      negativeButtonText: "",
      positiveButtonText: "Ok",
    };
    this.props.toggleModal(modal);
  };

  renderAvailableSlot(bookingDetails) {
    return (
      <MDBCol md="6">
        <span className="custom-label required">Select Duration</span>
        <select
          className="browser-default custom-select"
          onChange={this.onDurationChange}
          value={this.state.duration}
        >
          {bookingDetails.availableSlot.map((time) => (
            <option key={time} value={time}>
              {time + " Minutes or " + time / 60 + " Hour/s"}
            </option>
          ))}
        </select>
      </MDBCol>
    );
  }

  showBookingDetailsErrorAlert() {
    if (this.props.bookingDetails.location === "") {
      return (
        <MDBAlert color="danger" className="text-center">
          <strong>No valid booking selected</strong>
        </MDBAlert>
      );
    }
  }

  isInvalidCoupon() {
    if (this.state.couponErrorMsg.length > 0) {
      return (
        <span style={{ color: "red", fontWeight: "bold" }}>
          {this.state.couponErrorMsg}
        </span>
      );
    }
    return <span></span>;
  }

  renderBookingDetails() {
    let bookingDetails = this.props.bookingDetails;
    return (
      <div>
        {this.showBookingDetailsErrorAlert()}
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <MDBInput
                label="Location"
                disabled
                value={bookingDetails.location}
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                label="Resource"
                disabled
                value={bookingDetails.resource}
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                label="Reservation Date"
                disabled
                value={bookingDetails.reservationDate}
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                label="Time"
                disabled
                valueDefault={bookingDetails.time}
                value={this.state.time}
                style={{ fontWeight: "bold" }}
              />
            </MDBCol>
            {this.renderAvailableSlot(bookingDetails)}

            <MDBCol md="6" className="input-label-bold coupon-section">
              <MDBInput
                label="ENTER CREDIT VOUCHER / DISCOUNT CODE"
                onChange={this.onACouponChange}
                style={{ fontWeight: "bold" }}
              />
              {this.isInvalidCoupon()}
            </MDBCol>
            <MDBCol md="6">
              <h5 className="mt-4">
                <strong className="float-right">
                  Total price: ${this.state.totalPrice}
                </strong>
              </h5>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  renderCustomerForm() {
    return (
      <div>
        <MDBContainer>
          {this.showAlert()}
          <MDBRow>
            <MDBCol
              className="col-sm-12 col-md-6"
              style={{ flexBasis: "auto" }}
            >
              <MDBInput
                label="First name"
                labelClass="required"
                onChange={this.onFirstnameChange}
                valueDefault={this.props.customer.first_name}
              />
            </MDBCol>
            <MDBCol
              className="col-sm-12 col-md-6"
              style={{ flexBasis: "auto" }}
            >
              <MDBInput
                label="Last name"
                labelClass="required"
                onChange={this.onLastnameChange}
                valueDefault={this.props.customer.last_name}
              />
            </MDBCol>
            <MDBCol md="12">
              <MDBInput
                label="Email"
                labelClass="required"
                onChange={this.onEmailChange}
                valueDefault={this.props.customer.email}
              />
            </MDBCol>
            <MDBCol md="12">
              <MDBInput
                label="Contact #"
                labelClass="required"
                onChange={this.onContactNumberChange}
                valueDefault={this.props.customer.mobile}
                pattern="^[0-9]{10,15}$"
              />
            </MDBCol>
            <MDBCol md="12">
              <MDBInput
                label="Address"
                onChange={this.onAddressChange}
                valueDefault={this.props.customer.address}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  redirect(redirectUrl) {
    const { locked } = this.state;
    if (websocketApi.isOpen) {
      websocketApi.send(JSON.stringify({ ...locked, action: "release_lock" }));
    }
    document.location.href = redirectUrl;
  }

  renderContent() {
    // this.isPaymentRequestSuccess();
    // this.isPaymentRequestFailed();
    return (
      <div>
        <Loader />

        <MDBContainer className="content-min-height mt-5">
          <PageTitle title="Booking confirmation" subTitle="" />

          <MDBRow className="mt-5 mb-5">
            <MDBCol className="col-sm-12 col-md-6 mb-5">
              <h5>Booking Details</h5>
              <hr></hr>
              {this.renderBookingDetails()}
            </MDBCol>
            <MDBCol size="col-sm-12 col-md-6 mb-5">
              <h5>Customer Details</h5>
              <hr></hr>
              {this.renderCustomerForm()}
            </MDBCol>
            {this.maxBookingParticipants == -1 ||
            this.maxBookingParticipants > 0 ? (
              <MDBCol className="col-sm-12 col-md-12">
                <h5>Participants</h5>
                <hr></hr>
                {this.renderBookingParticipants()}
              </MDBCol>
            ) : (
              ""
            )}

            <MDBCol size="12" style={{ textAlign: "center" }}>
              <br></br>
              <h5>
                Please fill up all required fields (
                <span className="text-danger">*</span>)
              </h5>
            </MDBCol>
            <MDBCol
              className="col-sm-0 col-md-6"
              style={{ flexBasis: "auto" }}
            ></MDBCol>
            <MDBCol
              className="col-sm-12 col-md-6"
              style={{ flexBasis: "auto" }}
            >
              {this.renderFormFooter()}
            </MDBCol>
            <MDBCol size="12">
              <div className="mt-5">{this.renderTermsAndConditions()}</div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  renderFormFooter() {
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12" className="mt-5">
            <div className="custom-control custom-checkbox mb-3 text-center">
              <input
                type="checkbox"
                className="custom-control-input"
                id="defaultUnchecked"
                onChange={this.onACheckChange}
              />
              <label
                className="custom-control-label"
                htmlFor="defaultUnchecked"
              >
                I agree to the Terms and Conditions Below
              </label>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol
            className="col-sm-12 col-md-6"
            style={{ paddingLeft: "10px" }}
          >
            <MDBBtn
              className="col-sm-12"
              color="success"
              disabled={this.state.invalidCoupon}
              onClick={this.validateCheckout.bind(this)}
            >
              Checkout
            </MDBBtn>
          </MDBCol>
          <MDBCol
            className="col-sm-12 col-md-6"
            style={{ paddingLeft: "10px" }}
          >
            <MDBBtn
              className="col-sm-12"
              color="blue-grey"
              onClick={() => {
                this.redirect(
                  `/booking?uuid=${this.props.bookingDetails.locationUuid}`
                );
              }}
            >
              Cancel
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  renderBookingParticipants() {
    let columns = [
      {
        fieldName: "first_name",
        displayName: "First Name",
        isEditable: true,
        dataType: "",
        isRequired: true,
      },
      {
        fieldName: "last_name",
        displayName: "Last Name",
        isEditable: true,
        dataType: "",
        isRequired: true,
      },
      {
        fieldName: "mobile",
        displayName: "Mobile",
        isEditable: true,
        dataType: "text",
        isRequired: true,
        pattern: "^[0-9]{10,15}$",
        patternErrorMessage: "Please enter a valid contact number",
      },
      {
        fieldName: "email",
        displayName: "Email",
        isEditable: true,
        dataType: "email",
        isRequired: true,
        validationCallback: this.participantUniqueValidation,
        validationCallbackMessage:
          "Participant's email already added. Please enter an unique email.",
      },
    ];

    let rows = _.uniqWith(Object.values(this.state.participants), _.isEqual);
    let canAdd =
      rows.length < this.maxBookingParticipants ||
      this.maxBookingParticipants == -1;

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <CustomDataGrid
              source={rows}
              columns={columns}
              allowDeleting={this.allowDeletingParticipant}
              allowUpdating={this.allowUpdatingParticipant}
              canAdd={canAdd}
              key="id"
              actions={["edit", "delete"]}
              editMode={"form"}
              confirmDeleteMessage={""}
              triggerUpdatedEvent={this.triggerEvent}
              triggerInsertedEvent={this.triggerInsertEvent}
              triggerDeletedEvent={this.triggerDeletedEvent}
              toolbarItemRender={this.toolbarItemRender}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  renderTermsAndConditions() {
    if (this.props.bookingDetails.termsConditions !== undefined) {
      return (
        <RichTextRenderer
          doScrollY={true}
          htmlString={this.props.bookingDetails.termsConditions}
        />
      );
    } else {
      return <div className="text-center">Loading...</div>;
    }
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    bookingDetails: state.bookingDetails,
    alert: state.alert,
    customer: state.customer,
    paymentRequest: state.paymentRequest,
    paymentRequestSuccess: state.paymentRequestSuccess,
    paymentRequestFailed: state.paymentRequestFailed,
    redirected: state.redirected,
    successHandlerResponse: state.successHandlerResponse,
    failedHandlerResponse: state.failedHandlerResponse,
    bookingResponse: state.bookingResponse,
    bookingSuccessResponse: state.bookingSuccessResponse,
    resourceResponse: state.resourceResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    doBooking,
    validateLayout,
    alertMessage,
    setCustomer,
    requestPayment,
    toggleModal,
    doRedirected,
    showHideLoader,
    calculatePrice,
    showNotification,
    clearNotification,
    checkPublicBookingConflicts,
    lockBookingTimeslot,
  })(Checkout)
);
