import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
} from "mdbreact";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  displayBreadcrumbs,
  showHideLoader,
  closeBooking,
} from "../../../actions";
import {
  getBookings,
  clearBookingStatusSuccessResponse,
  clearBookingStatusFailedResponse,
  getStatuses,
  clearStatusSuccessResponse,
  clearStatusFailedResponse,
  getBookingStatus,
} from "../../../actions/bookingAction";
import _ from "lodash";
import moment from "moment";
import { roundNumber } from "../../../helper/utils";
import BookingEditor from "../shared/BookingEditor";
import { validateAccess } from "../../../helper/utils";
import { getResource } from "../../../actions/resourceAction";

class ReportBooking extends React.Component {
  state = {
    resultContainer: {},
    statusOptions: [],
    startDateFilter: new Date(),
    endDateFilter: new Date(Date.now() + 12096e5),
    limitOptions: [],
    isRecurring: false,
    limit: "10",
    status: "Booked",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    selectedBookingDetails: {},
    paymentHistoryElem: [],
    bookingStatusHistoryElem: [],
    parentBookingId: 0,
    customerId: "",
  };

  constructor(props) {
    super(props);
    if (typeof props.parentBookingId !== "undefined") {
      this.state.parentBookingId = props.parentBookingId;
      this.state.startDateFilter = "";
      this.state.endDateFilter = "";
    }

    if (typeof props.customerId !== "undefined") {
      this.state.customerId = props.customerId;
      this.state.startDateFilter = "";
      this.state.endDateFilter = "";
    }

    const { storeUserAccess } = this.props;

    let isDefaultUser =
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails");

    this.isReadOnly = false;
    if (isDefaultUser) {
      this.isReadOnly = true;
    }
  }

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);

    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  datePicker() {
    return (
      <span>
        <label
          className="text-primary"
          style={{
            left: "16px",
            paddingRight: "5px",
            paddingLeft: "5px",
            fontWeight: "500",
            transform: "translateY(-10px) scale(0.8)",
            position: "absolute",
            zIndex: 4,
            background: "#fff",
          }}
        >
          <b>Date From</b>
        </label>
        <DatePicker
          className="custom-input"
          placeholderText="Select Start Date"
          selected={this.state.startDateFilter}
          onChange={this.startDateFilterOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  endDatePicker() {
    return (
      <span>
        <label
          className="text-primary"
          style={{
            left: "16x",
            paddingRight: "5px",
            paddingLeft: "5px",
            fontWeight: "500",
            transform: "translateY(-10px) scale(0.8)",
            position: "absolute",
            zIndex: 4,
            background: "#fff",
          }}
        >
          <b>Date To</b>
        </label>
        <DatePicker
          className="custom-input"
          placeholderText="Select End Date"
          selected={this.state.endDateFilter}
          onChange={this.endDateFilterOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  componentDidMount() {
    this.props.showHideLoader(true);

    const {
      parentBookingId,
      query,
      customerId,
      startDateFilter,
      endDateFilter,
    } = this.state;

    const { storeUserAccess } = this.props;

    this.setState({
      typeOptions: this.createTypeOptions(),
    });

    let isDefaultUser =
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails");

    if (isDefaultUser) {
      this.props.displayBreadcrumbs("Dashboard / Report / Booking");
    } else {
      this.props.displayBreadcrumbs(
        "Dashboard / Administrator / Report / Booking"
      );
    }

    let params = {};
    if (query !== undefined) {
      params["query"] = query;
    }
    if (customerId != "") {
      params["customerId"] = customerId;
    } else if (parentBookingId > 0) {
      params["parentBookingId"] = parentBookingId;
    } else {
      params["startDate"] = moment(startDateFilter).utc().format("YYYY-MM-DD");
      params["endDate"] = moment(endDateFilter).utc().format("YYYY-MM-DD");
    }
    params["status"] = "Booked";

    this.props.getBookings(params, this.props.authUser.sessionToken);

    this.initLimitOptions();

    this.props.getStatuses(this.props.authUser.sessionToken);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getBookingsSuccessResponse !==
      this.props.getBookingsSuccessResponse
    ) {
      this.props.showHideLoader(false);

      this.setState({
        resultContainer: this.props.getBookingsSuccessResponse,
        page: this.props.getBookingsSuccessResponse.data.bookings.page,
      });
    }
    this.generateStatusesOptions(prevProps, prevState);

    if (
      prevProps.getBookingStatusSuccessResponse !==
        this.props.getBookingStatusSuccessResponse &&
      this.props.getBookingStatusSuccessResponse.status === 200
    ) {
      let bookingStatusHistoryElem = this.buildStatusHistory(
        this.props.getBookingStatusSuccessResponse
      );
      this.setState({ bookingStatusHistoryElem: bookingStatusHistoryElem });
    }

    if (
      prevProps.isCloseBooking !== this.props.isCloseBooking &&
      this.props.isCloseBooking
    ) {
      this.props.closeBooking(false);
      this.setState({
        selectedBookingDetails: null,
        showBookingDetailsModal: false,
      });
      this.debouncedDoFilter();
    }

    this.getResourceResponse(prevProps, prevState);
  }

  getResourceResponse(prevProps, prevState) {
    if (
      prevProps.getResourceResponse !== this.props.getResourceResponse &&
      this.props.getResourceResponse.status === 200
    ) {
      this.setState({
        locationResource: this.props.getResourceResponse.data,
        showBookingDetailsModal: true,
      });
    }
  }

  generateStatusesOptions(prevProps, prevState) {
    if (
      prevProps.getStatusesSuccessResponse !==
      this.props.getStatusesSuccessResponse
    ) {
      let options = [];
      options.push({
        checked: false,
        disabled: false,
        text: "Select All",
        value: "",
      });
      let orderedStat = _.chain(
        this.props.getStatusesSuccessResponse.data.statuses
      )
        .orderBy(["name"], ["asc"])
        .value();
      _.map(orderedStat, (status) => {
        var isSelected = false;
        if (status["name"] == "Booked") {
          isSelected = true;
        }
        options.push({
          checked: isSelected,
          disabled: false,
          text: status.name,
          value: status.name,
        });
      });

      // let options = _.chain(this.props.getStatusesSuccessResponse.data.statuses)
      //   .orderBy(["name"], ["asc"])
      //   .map((status) => {
      //     return {
      //       checked: false,
      //       disabled: false,
      //       text: status.name,
      //       value: status.name,
      //     };
      //   })
      //   .value();

      this.setState({ statusOptions: options });
    }
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  doFilter() {
    const {
      startDateFilter,
      endDateFilter,
      status,
      isRecurring,
      page,
      limit,
      query,
      parentBookingId,
      customerId,
    } = this.state;
    this.props.showHideLoader(true);
    let params = {};
    if (startDateFilter) {
      params["startDate"] = moment(startDateFilter).utc().format("YYYY-MM-DD");
    }
    if (endDateFilter) {
      params["endDate"] = moment(endDateFilter).utc().format("YYYY-MM-DD");
    }
    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }
    if (isRecurring) {
      params["isRecurring"] = 1;
    }
    if ((page + "").length > 0) {
      params["page"] = page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (query !== undefined) {
      params["query"] = query;
    }

    if (customerId !== undefined && customerId != "") {
      params["customerId"] = customerId;
    }

    if (parentBookingId > 0) {
      params["parentBookingId"] = parentBookingId;
    }

    this.props.getBookings(params, this.props.authUser.sessionToken);
  }

  render() {
    return (
      <div>
        {this.renderBookingDetailsForm()}
        {this.renderTable()}
      </div>
    );
  }

  createTypeOptions = () => [
    {
      checked: false,
      disabled: false,
      text: "Administrator",
      value: "administrator",
    },
    {
      checked: false,
      disabled: false,
      text: "Auditor",
      value: "Auditor",
    },
  ];

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    let data = {
      columns: [
        {
          label: "Booking Ref",
          field: "heading0",
          sort: "asc",
        },
        {
          label: "Location",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Resource",
          field: "heading2",
          sort: "asc",
        },
      ],
    };

    const isReadOnly = this.isReadOnly;

    if (!isReadOnly) {
      data.columns.push({
        label: "Name",
        field: "heading3",
        sort: "asc",
      });
      data.columns.push({
        label: "Email",
        field: "heading4",
        sort: "asc",
      });
    }

    data.columns.push({
      label: "Status",
      field: "heading5",
      sort: "asc",
    });

    data.columns.push({
      label: "Reservation Date",
      field: "heading6",
      sort: "asc",
    });

    data.columns.push({
      label: "Reservation Time",
      field: "heading7",
      sort: "asc",
    });
    data.columns.push({
      label: "",
      field: "heading8",
      sort: "asc",
    });

    let rows = [];
    if (resultContainer.status === 200) {
      rows = _.chain(resultContainer.data.bookings.results)
        .map((result, index) => {
          let rt = {
            heading0: result.booking_reference,
            heading1: result.location.name,
            heading2: result.resource.name,
          };
          if (!isReadOnly) {
            rt["heading3"] = result.customer.fullname;
            rt["heading4"] = result.customer.email;
          }

          rt["heading5"] = result.status;
          rt["heading6"] = moment(result.start_time)
            .local()
            .format("DD/MM/YYYY");
          rt["heading7"] =
            moment(result.start_time).local().format("HH:mm") +
            "-" +
            moment(result.end_time).local().format("HH:mm");
          rt["heading8"] = validateAccess(
            storeUserAccess,
            "Bookings",
            "viewBookingDetails"
          ) && (
            <MDBBtn
              color="primary"
              size="sm"
              key={index}
              onClick={() => this.openBookingDetailsModal(result)}
            >
              More details
            </MDBBtn>
          );
          return rt;
        })
        .value();

      data["rows"] = rows;
      return (
        <MDBContainer>
          {this.renderFilters()}
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
          <MDBRow>
            <MDBCol md="12">
              {this.renderTotalResult()}
              <div className="float-right">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={resultContainer.data.bookings.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }
    return <div></div>;
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (resultContainer.status === 200) {
      return (
        <span>
          Showing {resultContainer.data.bookings.results.length} result
        </span>
      );
    }
    return <span></span>;
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  getValueOfSelectStatus = (value) => {
    this.setState({ status: value[0] });
    this.debouncedDoFilter();
  };

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  onRecurringCheckChange = (evt) => {
    this.setState({
      isRecurring: !this.state.isRecurring,
    });
    this.debouncedDoFilter();
  };

  renderFilters() {
    const { limitOptions, statusOptions } = this.state;
    return (
      <div>
        <MDBRow>
          <MDBCol md="3">
            <MDBRow>
              <MDBCol md="12">{this.datePicker()}</MDBCol>
              <MDBCol md="12" className="mt-2">
                {this.endDatePicker()}
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md="3">
            <MDBRow>
              <MDBCol md="12" className="remove-margin-tb">
                <MDBSelect
                  outline
                  color="primary"
                  getValue={this.getValueOfSelectStatus}
                  options={statusOptions}
                  label="Status"
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search customer or booking ref."
                name="query"
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  toggleOuterTabs = (tab) => (e) => {
    const { activeItemOuterTabs2 } = this.state;
    if (activeItemOuterTabs2 !== tab) {
      this.setState({
        activeItemOuterTabs: tab,
      });
    }
  };

  toggleInnerPills = (tab) => (e) => {
    const { activeItemInnerPills } = this.state;
    if (activeItemInnerPills !== tab) {
      this.setState({
        activeItemInnerPills: tab,
      });
    }
  };

  openBookingDetailsModal(obj) {
    this.props.getResource(obj.resource.uuid, this.props.authUser.sessionToken);

    this.setState({
      selectedBookingDetails: obj,
      // showBookingDetailsModal: true,
      //paymentHistoryElem: this.buildPaymentHistory(obj.transaction),
    });

    //this.props.getBookingStatus(obj.uuid, this.props.authUser.sessionToken);
  }

  closeBookingDetailsModal() {
    this.setState({
      selectedBookingDetails: {},
      showBookingDetailsModal: false,
      activeItemOuterTabs: "1",
      activeItemInnerPills: "1",
      paymentHistoryElem: [],
      bookingStatusHistoryElem: [],
    });
  }

  buildPaymentHistory(paymentTransaction) {
    let paymentHistoryElem = [];
    paymentHistoryElem.push(
      <MDBTable responsive key="tablekey1">
        <MDBTableHead>
          <tr>
            <th>Transaction Type</th>
            <th>Payment Method</th>
            <th className="text-align-right">Amount</th>
            <th>Discount Code</th>
            <th className="text-align-right">Discount Amount</th>
            <th className="text-align-right">Total Amount</th>
            <th>Transaction Date</th>
            <th>Status</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {paymentTransaction.payments.map((payment, index) => (
            <tr key={index}>
              <td key={index + "1"}>{payment.method.vendor}</td>
              <td key={index + "2"}>{payment.method.method}</td>
              <td key={index + "3"} className="text-align-right">
                ${roundNumber(payment.subTotalAmount, 2)}
              </td>
              <td key={index + "4"}>{payment.voucherCode}</td>
              <td key={index + "5"} className="text-align-right">
                ${roundNumber(payment.voucherCreditAmount, 2)}
              </td>
              <td key={index + "6"} className="text-align-right">
                ${roundNumber(payment.totalAmount, 2)}
              </td>
              <td key={index + "7"}>
                {moment(
                  _.split(payment.paymentDate, " ")[0] +
                    "T" +
                    _.split(payment.paymentDate, " ")[1] +
                    "Z",
                  "YYYY-MM-DDTHH:mm:ssZ"
                )
                  .local()
                  .format("DD/MM/YYYY HH:mm:ss")}
              </td>
              <td key={index + "8"}>{payment.status}</td>
              <td key={index + "9"}>{payment.comments}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    );
    return paymentHistoryElem;
  }

  renderPaymentHistory() {
    const { paymentHistoryElem } = this.state;
    if (paymentHistoryElem.length > 0) {
      return <div>{paymentHistoryElem}</div>;
    }
    return <span></span>;
  }

  buildStatusHistory(getBookingStatusSuccessResponse) {
    let bookingStatusHistoryElem = [];
    bookingStatusHistoryElem.push(
      <MDBTable responsive scrollY key="sttable1">
        <MDBTableHead>
          <tr>
            <th>Status</th>
            <th>Date</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {getBookingStatusSuccessResponse.data.history.map(
            (history, index) => (
              <tr key={index}>
                <td key={index + "1"}>{history.status}</td>
                <td key={index + "7"}>
                  {moment(
                    _.split(history.submittedDate, " ")[0] +
                      "T" +
                      _.split(history.submittedDate, " ")[1] +
                      "Z",
                    "YYYY-MM-DDTHH:mm:ssZ"
                  )
                    .local()
                    .format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td key={index + "9"}>{history.comments}</td>
              </tr>
            )
          )}
        </MDBTableBody>
      </MDBTable>
    );

    return bookingStatusHistoryElem;
  }

  renderStatusHistory() {
    const { bookingStatusHistoryElem } = this.state;
    if (bookingStatusHistoryElem.length > 0) {
      return <div>{bookingStatusHistoryElem}</div>;
    }
    return <span></span>;
  }

  renderBookingDetailsForm() {
    const { showBookingDetailsModal, selectedBookingDetails } = this.state;
    if (
      selectedBookingDetails != null &&
      typeof selectedBookingDetails.is_booked !== "undefined" &&
      showBookingDetailsModal
    ) {
      let locationList = _.isEmpty(this.props.getLocationMenuSuccessResponse)
        ? this.props.locations.results
        : this.props.getLocationMenuSuccessResponse.data.locations.results;

      let locationObj = _.filter(locationList, function (data) {
        return data.uuid == selectedBookingDetails.location.uuid;
      })[0];

      // let params = queryString.parse(this.props.location.search);
      // return (
      //   <div className="booking-modal">
      //     <BookingEditor
      //       selectedBooking={selectedBookingDetails}
      //       isView={true}
      //       locationResource={locationObj}
      //     />
      //   </div>
      // );

      console.log("Check missing>>>>>>>>");
      console.log(this.props.getStatusesSuccessResponse.data.statuses);
      console.log(selectedBookingDetails);
      console.log(
        !(
          validateAccess(
            this.props.storeUserAccess,
            "AdminBooking",
            "updateBooking"
          ) && selectedBookingDetails.is_cancelled !== 1
        )
      );
      console.log(this.state.locationResource);
      console.log(locationObj);

      return (
        <div className="booking-modal">
          <BookingEditor
            doCalculate={true}
            statuses={this.props.getStatusesSuccessResponse.data.statuses}
            selectedBooking={selectedBookingDetails}
            isView={
              !(
                validateAccess(
                  this.props.storeUserAccess,
                  "AdminBooking",
                  "updateBooking"
                ) && selectedBookingDetails.is_cancelled !== 1
              )
            }
            locationResource={this.state.locationResource}
            locationObj={locationObj}
          />
        </div>
      );
    }
    return <span></span>;
  }
}

const mapStateToProps = (state) => {
  return {
    getBookingsSuccessResponse: state.getBookingsSuccessResponse,
    getBookingsFailedResponse: state.getBookingsFailedResponse,
    authUser: state.authUser,
    getStatusesSuccessResponse: state.getStatusesSuccessResponse,
    getStatusesFailedResponse: state.getStatusesFailedResponse,
    getBookingStatusSuccessResponse: state.getBookingStatusSuccessResponse,
    getBookingStatusFailedResponse: state.getBookingStatusFailedResponse,
    isCloseBooking: state.closeBooking,
    storeUserAccess: state.storeUserAccess,
    locations: state.locations,
    getLocationMenuSuccessResponse: state.getLocationMenuSuccessResponse,
    getResourceResponse: state.resourceResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getBookings,
    clearBookingStatusSuccessResponse,
    clearBookingStatusFailedResponse,
    getStatuses,
    clearStatusSuccessResponse,
    clearStatusFailedResponse,
    getBookingStatus,
    showHideLoader,
    closeBooking,
    getResource,
  })(ReportBooking)
);
