import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { validateLayout } from "../../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class RegisterSuccess extends React.Component {
  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: false,
      showSearch: false,
      showHeader: false,
      showLogin: false,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();

    if (
      this.props.authResponse === undefined ||
      (this.props.authResponse !== undefined &&
        this.props.authResponse.type !== "REGISTER_RESPONSE_HANDLER")
    ) {
      this.props.history.push("/invalidurl");
    }
  }

  renderContent() {
    return (
      <div>
        <MDBContainer>
          <h1 className="text-center mt-5 text-success">
            Registration Successful
          </h1>
          <h5 className="text-center">
            Congratulations! Your registration is successful
          </h5>
          <h6 className="text-center">
            We sent you an email with a link to set your password
          </h6>
          <MDBRow className="mt-3">
            <MDBCol md="12" style={{ textAlign: "center" }}>
              {"Already have an account? "}
              <Link to={"/login"}>Login</Link>
            </MDBCol>
            <MDBCol md="12" style={{ textAlign: "center" }}>
              <Link to={"/"}>Go back to home page</Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.authResponse !== this.props.authResponse &&
  //     this.props.authResponse.type === "REGISTER_RESPONSE_HANDLER"
  //   ) {
  //   } else {
  //     this.props.history.push("/invalidurl");
  //   }
  // }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout, authResponse: state.authResponse };
};

export default connect(mapStateToProps, { validateLayout })(RegisterSuccess);
