import React from "react";
import ReactDOM from "react-dom";

import { init as initApm } from "@elastic/apm-rum";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./custom.css";
import "./antd.css";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { CookiesProvider } from "react-cookie";

import App from "./components/App";
import reducers from "./reducers";
import { responsiveStoreEnhancer } from "redux-responsive";

const store = createStore(
  reducers,
  compose(responsiveStoreEnhancer, applyMiddleware(thunk))
);

//START ELASTIC APM RUM INTEGRATION
const {
  REACT_APP_RUM_ENV_NAME,
  REACT_APP_RUM_ENDPOINT,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  REACT_APP_GOOGLE_ANALYTICS_URL,
} = process.env;

if (REACT_APP_RUM_ENV_NAME != "" && REACT_APP_RUM_ENDPOINT != "") {
  initApm({
    serviceName: "unified-bookings",
    serverUrl: REACT_APP_RUM_ENDPOINT.trim(),
    environment: REACT_APP_RUM_ENV_NAME.trim(),
  });
}
//END ELASTIC APM RUM INTEGRATION

// START GOOGLE ANALYTICS INTEGRATION
const injectGoogleAnalytics = () => {
  if (typeof window == "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID.trim());
};

// END GOOGLE ANALYTICS INTEGRATION
ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <scripts async src={REACT_APP_GOOGLE_ANALYTICS_URL.trim()} />
      <script>{injectGoogleAnalytics()}</script>
      <App />
    </Provider>
  </CookiesProvider>,
  document.querySelector("#root")
);
