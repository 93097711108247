import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  ColumnFixing,
  RequiredRule,
  EmailRule,
  CustomRule,
  PatternRule,
  Button,
} from "devextreme-react/data-grid";

import { MDBIcon } from "mdbreact";
import { confirm } from "devextreme/ui/dialog";
import { Template } from "devextreme-react/core/template";
import React from "react";
import { connect } from "react-redux";

class CustomDataGrid extends React.Component {
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbar",
    });
  }
  state = { isComponentUpdate: true };

  renderContent() {
    let rows = this.props.source;
    let columns = this.props.columns;
    let key = this.props.key;
    let actions = this.props.actions;
    let allowUpdating = this.props.allowUpdating;
    let allowDeleting = this.props.allowDeleting;
    let allowCheckin = this.props.allowCheckin;
    let canAdd = this.props.canAdd;
    let editMode = this.props.editMode;
    let toolbarItemRender = this.props.toolbarItemRender;
    let texts = {
      confirmDeleteMessage: this.props.confirmDeleteMessage,
    };

    const setIsComponentUpdate = (value = true) => {
      this.setState({ isComponentUpdate: value });
    };

    const setOnCancelButtonClick = (evt) => {
      setTimeout(function () {
        let form = evt.element;
        let cancelBtn = form.parentElement.parentElement.querySelector(
          "[aria-label='Cancel'] .dx-button-content"
        );

        if (cancelBtn) {
          cancelBtn.onclick = function () {
            setIsComponentUpdate(true);
          };
        }
      }, 100);
    };

    return (
      <div>
        <DataGrid
          id="gridContainer"
          keyExpr={key}
          dataSource={rows}
          allowColumnResizing={true}
          allowColumnReordering={true}
          showBorders={true}
          columnAutoWidth={true}
          onRowInserting={this.props.triggerRowValidating}
          onRowInserted={(e) => {
            this.props.triggerInsertedEvent(e);
            setIsComponentUpdate(true);
          }}
          onRowRemoving={this.props.triggerOnDeleteWaring}
          onRowRemoved={(e) => {
            this.props.triggerDeletedEvent(e);
            setIsComponentUpdate(true);
          }}
          onRowUpdated={(e) => {
            this.props.triggerUpdatedEvent(e);
            setIsComponentUpdate(true);
          }}
          onRowUpdating={this.props.triggerUpdatingEvent}
          onInitNewRow={(e) => {
            this.onInitNewRow(e);
            setIsComponentUpdate(false);
            setOnCancelButtonClick(e); // This is to set the behavior of cancel button
          }}
          onToolbarPreparing={
            toolbarItemRender !== undefined ? this.onToolbarPreparing : ""
          }
          scrolling={true}
          onEditingStart={(e) => {
            this.props.rowValidating(e);
            setIsComponentUpdate(false);

            setOnCancelButtonClick(e); // This is to set the behavior of cancel button
          }}
          focusedColumnIndex={5}
        >
          <ColumnFixing enabled={true} />
          <Paging enabled={false} />

          <Editing
            mode={editMode}
            allowUpdating={allowUpdating}
            allowDeleting={allowDeleting}
            allowAdding={canAdd}
            useIcons={true}
            texts={texts}
            refreshMode={true}
          />

          {columns.map((column) => (
            <Column
              formItem={{}}
              isEditable={column.isEditable}
              showEditorAlways={column.showEditorAlways}
              defaultVisible={column.visible}
              visible={column.visible}
              dataField={column.fieldName}
              title={column.displayName}
              allowEditing={column.isEditable}
              dataType={column.dataType}
              cellRender={column.renderGridCell}
            >
              {column.validationCallback && (
                <CustomRule
                  validationCallback={column.validationCallback}
                  message={column.validationCallbackMessage}
                />
              )}
              //
              {column.pattern ? (
                <PatternRule
                  pattern={column.pattern}
                  message={column.patternErrorMessage}
                />
              ) : (
                ""
              )}
              {column.isRequired ? <RequiredRule /> : ""}
              {column.dataType == "email" ? <EmailRule /> : ""}
              {typeof column.sources !== "undefined" &&
              column.sources.length > 0 ? (
                <Lookup
                  dataSource={column.sources}
                  displayExpr={column.sourceName}
                  valueExpr={column.sourceId}
                />
              ) : (
                ""
              )}
              //
              {column.pattern ? (
                <PatternRule
                  pattern={column.pattern}
                  message={column.patternErrorMessage}
                />
              ) : (
                ""
              )}
              {column.isRequired ? <RequiredRule /> : ""}
              {column.dataType == "email" ? <EmailRule /> : ""}
              {typeof column.sources !== "undefined" &&
              column.sources.length > 0 ? (
                <Lookup
                  dataSource={column.sources}
                  displayExpr={column.sourceName}
                  valueExpr={column.sourceId}
                />
              ) : (
                ""
              )}
            </Column>
          ))}

          {/* START ACTION COLUMN */}
          <Column type="buttons">
            {actions.map((action) => {
              if (action == "checkin" && allowCheckin) {
                return (
                  <Button
                    hint="Check in"
                    icon="map"
                    visible={true}
                    onClick={this.props.triggerCheckIn}
                  />
                );
              } else if (action == "addfavourite") {
                return (
                  <Button
                    hint="Add as favourite"
                    icon="far fa-star"
                    visible={this.props.allowAddingFavourite}
                    onClick={this.props.triggerAddFavourite}
                  />
                );
              } else if (action == "removefavourite") {
                return (
                  <Button
                    hint="Remove as favourite"
                    icon="fas fa-star"
                    visible={this.props.allowRemovingFavourite}
                    onClick={this.props.triggerRemoveFavourite}
                  />
                );
              } else {
                // These are default buttons (edit,delete)
                return <Button name={action} />;
              }
            })}
          </Column>

          {/* END ACTION COLUMN */}

          {toolbarItemRender !== undefined ? (
            <Template name="toolbar" render={toolbarItemRender} />
          ) : (
            ""
          )}
        </DataGrid>
      </div>
    );
  }

  onInitNewRow(e) {
    setTimeout(function () {
      let form = e.element;
      let btnSave = form.parentElement.parentElement.querySelector(
        "[aria-label='Save'] .dx-button-content"
      );
      let btnSaveText = form.parentElement.parentElement.querySelector(
        "[aria-label='Save'] .dx-button-text"
      );
      btnSaveText.innerHTML = "Add";
      btnSave.classList.add("btn-success");
    }, 100);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.resourceResponse !== this.props.resourceResponse &&
      this.props.resourceResponse !== undefined
    ) {
      let res = this.props.resourceResponse;
      if (res.action === "LOCK_RESOURCE_BOOKING_TIME_SLOT") {
        return false;
      }
    }

    if (
      nextProps.bookingResponse !== this.props.bookingResponse &&
      this.props.bookingResponse !== undefined
    ) {
      let res = this.props.bookingResponse;
      if (res.action === "GET_RESOURCE_BOOKINGS") {
        return false;
      }
    }

    if (
      nextProps.authResponse !== this.props.authResponse &&
      nextProps.authResponse.type == "HEART_BEAT_RESPONSE_HANDLER"
    ) {
      return false;
    }

    return this.state.isComponentUpdate;
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    authResponse: state.authResponse,
    bookingResponse: state.bookingResponse,
    resourceResponse: state.resourceResponse,
  };
};

export default connect(mapStateToProps, {})(CustomDataGrid);
