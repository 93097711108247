import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SectionContainer from "../shared/sectionContainer";
import { authLogin, setAuthUser } from "../../../actions/authAction";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { USER_COOKIES_KEY, USER_TMP } from "../../../helper";
import moment from "moment";
import _ from "lodash";
import Loader from "../shared/Loader";
import { showHideLoader } from "../../../actions";

class SelectOrganisation extends React.Component {
  state = {
    org: "",
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  renderContent() {
    if (this.props.authUser.organisations !== undefined) {
      return (
        <MDBContainer>
          <Loader />
          <MDBRow>
            <MDBCol>
              <h2 className="text-center mt-5 mb-5">
                Hi {this.props.authUser.username}, Please Select your
                Organisation
              </h2>
              <SectionContainer className="text-center" header="">
                {this.props.authUser.organisations.map((org, index) => (
                  <MDBBtn
                    color="primary"
                    size="lg"
                    key={index}
                    onClick={() => this.validateUser(org.domain)}
                  >
                    {org.name}
                  </MDBBtn>
                ))}
              </SectionContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    } else {
      this.debouncedDashboardRedirect();
      return <span></span>;
    }
  }

  debouncedDashboardRedirect = _.debounce(function () {
    this.props.history.push("/admin/dashboard");
  }, 100);

  dateOnChange = (date) => {
    this.debouncedSelectedDate(date);
  };

  validateUser(domain) {
    this.props.showHideLoader(true);
    const { cookies } = this.props;
    let tempCookies = cookies.get(USER_TMP);
    if (tempCookies !== undefined) {
      this.setState({ org: domain });
      let data = {
        username: this.props.authUser.username,
        password: tempCookies,
        domain: domain,
      };
      this.props.authLogin(data);
    } else {
      window.location.replace("/login");
    }
  }

  setCookies(key, val, expires) {
    const { cookies } = this.props;
    cookies.set(key, val, { path: "/", expires });
  }

  authenticated() {
    if (this.props.login.status !== undefined) {
      const timer = setTimeout(() => {
        let auth = {
          username: this.props.authUser.username,
          sessionToken: this.props.login.data.sessionToken,
          isLoggedIn: true,
          organisation: this.state.org,
          organisations: this.props.authUser.organisations,
          role: this.props.login.data.role,
        };
        if (typeof this.props.login.data.organisationId !== "undefined") {
          auth.organisationId = this.props.login.data.organisationId;
        }

        this.props.setAuthUser(auth);

        let userCookieExp = new Date(
          moment(new Date()).add(480, "minutes").utc().format()
        );
        this.setCookies(USER_COOKIES_KEY, JSON.stringify(auth), userCookieExp);
        this.props.showHideLoader(false);
        this.props.history.push("/admin/dashboard");
      }, 2000);
    }
  }

  render() {
    this.authenticated();
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { authUser: state.authUser, login: state.authLogin };
};

export default withCookies(
  withRouter(
    connect(mapStateToProps, { authLogin, setAuthUser, showHideLoader })(
      SelectOrganisation
    )
  )
);
