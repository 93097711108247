import {
  GEN_MODAL_CUSTOMER,
  GEN_MODAL_FAVOURITE_CUSTOMER,
  GEN_MODAL_VOUCHER,
  GEN_MODAL_ROLE,
  GEN_MODAL_ROLE_ACCESS,
  GEN_MODAL_SUBSCRIBE_MORE_DETAILS,
  GEN_MODAL_RULES_ENGINE,
  GEN_MODAL_RULE_CONDITION_EVALUATOR,
  GEN_MODAL_RULE_TRIGGER,
} from "../actions/types";

export default (state = {}, action) => {
  let data = {
    type: action.type,
  };
  switch (action.type) {
    case GEN_MODAL_CUSTOMER:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_FAVOURITE_CUSTOMER:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_VOUCHER:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_ROLE:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_ROLE_ACCESS:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_SUBSCRIBE_MORE_DETAILS:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_RULES_ENGINE:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_RULE_CONDITION_EVALUATOR:
      data["data"] = action.payload;
      break;
    case GEN_MODAL_RULE_TRIGGER:
      data["data"] = action.payload;
      break;
    default:
      return state;
  }
  return data;
};
