import { addDays } from "date-fns";
import _ from "lodash";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";
import {
  MDBAlert,
  MDBBadge,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtnFixedItem,
  MDBCollapseHeader,
  MDBCollapse,
  MDBBtnFixed,
} from "mdbreact";
import moment, { utc } from "moment";
import queryString from "query-string";
import React from "react";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomDataGrid from "./CustomDataGrid";
import IdleTimer from "react-idle-timer";
import RichTextRenderer from "../shared/RichTextRenderer";
import FavouriteCustomerModal from "../shared/FavouriteCustomerModal";
import BannerMessage from "./BannerMessage";
import BookingTimeslotNotificationModal from "./BookingTimeslotNotificationModal";

import {
  clearNotification,
  selectDate,
  showHideLoader,
  showNotification,
  toggleModal,
  closeBooking,
  bookingChangeSchedule,
  pauseBackgroundRequests,
} from "../../../actions";
import {
  disableNextNav,
  disablePrevNav,
} from "../../../actions/calendarNavAction";
import {
  clearBookingFailedResponse,
  clearBookingStatusFailedResponse,
  clearBookingStatusSuccessResponse,
  clearBookingSuccessResponse,
  clearStatusFailedResponse,
  clearStatusSuccessResponse,
  doBooking,
  getBookingStatus,
  getStatuses,
  setBookingDetails,
  updateBooking,
  checkBookingConflicts,
  validateAddedCustomer,
  getMemberAdvanceBookingDay,
} from "../../../actions/bookingAction";

import { createRecurring } from "../../../apis/RecurringApi";

import { genModalFavouriteCustomer } from "../../../actions";
import { lockBookingTimeslot } from "../../../actions/resourceAction";

import {
  getCustomers,
  validateCustomerDetails,
} from "../../../actions/customerAction";
import {
  getLocationList,
  getLocationResources,
  getLocationResourcesPrivate,
  searchLocations,
} from "../../../actions/locationAction";
import { calculatePrice, requestPayment } from "../../../actions/paymentAction";
import {
  clearGenCustomerVoucherFailedResponse,
  clearGenCustomerVoucherSuccessResponse,
  generateCustomerVoucher,
} from "../../../actions/voucherAction";
import websocketApi from "../../../apis/websocketApi";
import {
  roundNumber,
  extractTimezone,
  getBookingColors,
  getCss,
  validateAccess,
} from "../../../helper/utils";
import SectionContainer from "./sectionContainer";
import BookingEditor from "./BookingEditor";
import { subscribe } from "../../../actions/userAction";
import CalendarBookingComponent from "./CalendarBookingComponent";
import CalendarDateName from "./CalendarDateName";
import CalendarDatePicker from "./CalendarDatePicker";
import CalendarNavigation from "./CalendarNavigation";
import {
  getUserFavourites,
  deleteFavouriteCustomer,
} from "../../../actions/userFavouriteAction";
class WeeklyCalendar extends React.Component {
  maxBookingParticipants = 0;
  isCanRefresh = false;

  constructor(props) {
    super(props);
    this.participantUniqueValidation =
      this.participantUniqueValidation.bind(this);
    this.modal = React.createRef();
    this.searchParticipantsRef = React.createRef(null);
    this.addressRef = React.createRef(null);
    this.customerDiscountCodeRef = React.createRef(null);

    this.rowValidating = this.rowValidating.bind(this);
    this.triggerEvent = this.triggerEvent.bind(this);
    this.triggerDeletedEvent = this.triggerDeletedEvent.bind(this);
    this.triggerInsertEvent = this.triggerInsertEvent.bind(this);
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.allowDeletingParticipant = this.allowDeletingParticipant.bind(this);
    this.allowUpdatingParticipant = this.allowUpdatingParticipant.bind(this);
    if (!this.props.private) {
      this.handleOnAction = this.handleOnAction.bind(this);
      this.handleOnActive = this.handleOnActive.bind(this);
      this.handleOnIdle = this.handleOnIdle.bind(this);
    }
    this.openBookingDetailsModal = this.openBookingDetailsModal.bind(this);
    this.tempReadonlyModal = this.tempReadonlyModal.bind(this);
    this.openBookingModal = this.openBookingModal.bind(this);
    this.dateOnChange = this.dateOnChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.allowAddingFavouriteContact =
      this.allowAddingFavouriteContact.bind(this);
    this.allowRemovingFavouriteContact =
      this.allowRemovingFavouriteContact.bind(this);
    this.onAddFavouriteContact = this.onAddFavouriteContact.bind(this);
    this.onRemoveFavouriteContact = this.onRemoveFavouriteContact.bind(this);
    this.calendarNav = this.calendarNav.bind(this);

    if (
      typeof props.isChangeSchedule !== "undefined" &&
      props.isChangeSchedule
    ) {
      this.props.location.search = `uuid=${props.locationUuid}`;
      this.state.isChangeSchedule = props.isChangeSchedule;
    }

    if (this.props.changeScheduleDate) {
      this.selectedDateVar = new Date(this.props.changeScheduleDate);
      this.props.selectDate(new Date(this.props.changeScheduleDate));
    } else {
      this.selectedDateVar = new Date();
      this.props.selectDate(new Date());
    }

    if (
      typeof props.showCalendarSelect == "undefined" ||
      props.showCalendarSelect
    ) {
      // this.startCalendarRefresh();
    }
    //websocketApi.onmessage = (eventData) => {
    //  const dataFromServer = JSON.parse(eventData.data);
    //  console.log(dataFromServer)
    //}
  }

  onSendNotificationChange = (evt) => {
    this.setState({ sendNotification: !this.state.sendNotification });
  };

  // -- For public calendar only
  handleOnAction(event) {}

  handleOnActive(event) {}

  handleOnIdle(event) {
    this.props.pauseBackgroundRequests(true);
    this.showPublicModal();
  }

  doExtendSession() {
    this.props.pauseBackgroundRequests(false);
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  doCloseModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  showPublicModal() {
    let idleTimeInMinute = this.state.idleTime / 60 / 1000;
    const modal = {
      isOpen: true,
      content: (
        <div className="text-center">
          {`You have been Idle for ${idleTimeInMinute} minute${
            idleTimeInMinute > 1 ? "s" : ""
          }. Click continue if you want to continue your session`}
        </div>
      ),
      title: "Warning!",
      negativeButtonText: "Cancel",
      positiveButtonText: "Continue",
      positiveButtonColor: "success",
      negativeButtonAction: this.doCloseModal.bind(this),
      positiveButtonAction: this.doExtendSession.bind(this),
      allowToggle: false,
    };
    this.props.toggleModal(modal);
  }

  // -- For public calendar only

  state = {
    sendNotification: true,
    alert: {
      type: "",
      title: "",
      text: "",
      clearAfter: false,
    },
    isLocationPerMembership: false,
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    private: this.props.private,
    courtTypeOptions: [],
    surfaceTypeOptions: [],
    searchDateVal: this.props.changeScheduleDate
      ? moment(new Date(this.props.changeScheduleDate)).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD"),
    // searchDateVal: "2020-07-25",
    isClientSideFilter: false,
    ClientSidefilterCompleted: false,
    locationResourcesContainer: {},
    origLocationResourcesContainer: {},
    doDropdownFilter: false,
    selectedResource: "Select Resource Type",
    selectedSurface: "Select Surface Type",
    dropdownCounter: 0,
    showBookingDetailsModal: false,
    selectedBookingDetails: {},
    showBookingModal: false,
    showTermsAndConditionalModal: false,

    customerId: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
    invoice: "",
    paid: false,
    notes: "",
    selectedBooking: {},
    duration: "",
    origTime: "",
    time: "",
    paymentMethod: "Credit Card",
    isRecurring: false,
    frequencyInWeeks: "Weekly",
    totalPrice: 0,
    origPrice: 0,
    doneGettingOriginalPrice: false,
    showPriceChangeWarning: false,
    chargeAmount: 0,
    lessAmount: 0,
    totalAmount: 0,

    wasValidated: "",
    isInitialPriceComputed: false,

    coupon: "",
    couponErrorMsg: "",
    invalidCoupon: false,
    couponDiscount: 0,

    durationDefaultVal: 30,

    discountAmount: 0,
    receiptUrl: "",
    vendor: "",

    bookingRef: "",
    bookingUuid: "",
    bookingId: "",

    locationId: "",
    locationUuid: "",
    locationName: "",

    resourceId: "",
    resourceUuid: "",
    resourceName: "",

    customerId: "",
    customerUuid: "",

    origStatus: "",
    status: "",
    statusDesc: "",
    statusChangeConfirmation: "",

    statusNotes: "",
    isUpdate: false,

    isChargable: 0,
    issueVoucher: 0,
    isOverrideble: 0,
    getAvailableSlot: [],

    generatedTimeContainer: [],
    tableElem: [],
    tableElemForMobile: false,

    paymentTransaction: {},

    paymentHistoryElem: [],
    bookingStatusHistoryElem: [],

    disableStatusButton: false,

    generateVoucher: false,

    disableSaveButton: false,

    showRecurringWarning: false,
    origSearchDateVal: "",

    showCalendarSelect: false,
    updateSchedule: false,

    locationObj: {},
    locationTimezone: undefined,
    bookingColors: getBookingColors(undefined),

    showExceedMaxDurationAcknowledge: false,
    maxDuration: 0,
    maxDurationInText: "",
    checkMoreThanMaxDuration: false,
    customerResultContainer: [],

    isDateFilter: false,

    recurringFrequencies: {
      Weekly: 7,
      Fortnightly: 14,
      Monthly: 28,
    },
    endDate: "",

    calendarPrevNavDisable: false,
    calendarNextNavDisable: false,
    isConflictLoaded: false,
    participants: [],
    collapseID: "",
    modalCollapseID: "collapse1",

    buttonStyle: {
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0",
    },
    isScrolling: false,
    isDefaultUser: false,
    readOnlyEmail: false,
    defaultCustomer: {},
    allowLocResourcePublicBooking: "No",
    overwriteFee: false,
    overwriteFeeAmount: "",
    refreshInterval: 300,

    newSelectedBookingsContainer: undefined,
    idleTime: 60000,
    iAggree: false,
    showHideTC: false,
    userFavouritesEmail: [],
    userFavourites: {},
    participantValue: "",
    isFavouriteListSearch: true,
    isMainCustomer: true, // This will be used to check if the added customer is main or just participant
    selectedCustomerOrParticipant: {},
    isValidateCustomerOrParticipant: false,
    lockingTimeSlotIntervalId: "",
    overrideRuleDaysInAdvanceValue: null,
    overrideRuleDaysInAdvanceMessage: "",
    isCorrectSelectedDate: false,
    isShowBookingTimeslotNotificationModal: false,
    selectedTimeslotforBookingNotification: "",
    selectedTimeslotLocation: 0,
  };

  participantUniqueValidation(data) {
    // console.log(data);
    let result = _.find(this.state.participants, function (o) {
      return o.email == data.value && data.value != "" && o.id != data.data.id;
    });
    return result == undefined || result.length == 0;
  }

  rowValidating(e) {
    let editableFields = ["first_name", "last_name", "email", "mobile"];
    editableFields.forEach((column, index) => {
      e.component.focus();
      if (e.data.email == this.state.email && !this.props.isView) {
        e.component.columnOption(column).formItem.cssClass =
          "dx-cell-focus-disabled dx-state-readonly disabled";
      } else {
        e.component.columnOption(column).formItem.cssClass = "";
      }
    });
    //e.component.focus();
    return e;
  }

  triggerEvent = (e) => {
    let { participants, isLocationPerMembership } = this.state;
    let data = { ...e.key, ...e.newData };
    let key = data.__KEY__;
    if (typeof data.__KEY__ == "undefined") {
      key = data.id;
    }

    if (data["checked_in"]) {
      e.newData["checked_in_time"] = data["checked_in_time"] = moment()
        .local(this.getTzDetails().timezone)
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (data["checked_in"] == 0) {
      data["checked_in_time"] = "";
    }

    if (typeof data.is_primary != "undefined" && data.is_primary) {
      this.setState({
        ...this.state,
        ...data,
      });
    }

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });
    if (participantIndex < 0) {
      participants.push(data);
    } else {
      participants[participantIndex] = data;
    }
    if (!this.state.isDefaultUser) {
      let custObj = {
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phone: data.mobile,
      };
      this.props.validateCustomerDetails(
        custObj,
        this.props.authUser.sessionToken
      );
    }

    this.state.participants = participants;

    if (isLocationPerMembership) {
      this.calculatePrice();
    }
    return false;
  };

  triggerDeletedEvent = (e) => {
    let { participants, isLocationPerMembership } = this.state;
    let data = e.key;
    let key = data.__KEY__;
    if (typeof data.__KEY__ == "undefined") {
      key = data.id;
    }

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });

    // console.log(participants[participantIndex]);
    participants.splice(participantIndex, 1);
    // console.log(participants);

    this.state.participants = participants;

    if (isLocationPerMembership) {
      this.calculatePrice();
    }

    return false;
  };

  triggerInsertEvent = (e) => {
    let { participants, isLocationPerMembership } = this.state;
    let data = e.data;
    let key = data.__KEY__;
    data["id"] = key;
    delete data.__KEY__;

    if (data["checked_in"]) {
      data["checked_in_time"] = moment()
        .local(this.getTzDetails().timezone)
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (data["checked_in"] == 0) {
      data["checked_in_time"] = "";
    }
    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });
    if (participantIndex < 0) {
      participants.push(data);
      // do validate customer details
      if (!this.state.isDefaultUser) {
        let custObj = {
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phone: data.mobile,
        };
        this.props.validateCustomerDetails(
          custObj,
          this.props.authUser.sessionToken
        );
      }
    } else {
      participants[participantIndex] = data;
    }

    this.state.participants = participants;

    if (isLocationPerMembership) {
      this.calculatePrice();
    }
    return false;
  };

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  debouncedSelectedDate = _.debounce(function (date) {
    let formattedSelectedDate = moment(date).format("YYYY-MM-DD");
    let calendarNextNavDisable = this.state.calendarNextNavDisable;
    this.setAdvanceDaysBooking();

    let allowedBookingDate = moment(new Date())
      .add(this.days_in_advance, "days")
      .format("YYYY-MM-DD");

    let isAdmin = !this.state.isDefaultUser && this.state.private;

    if (allowedBookingDate < formattedSelectedDate && !isAdmin) {
      let errorMessage =
        "Oh no! You've reached the max calendar date " +
        moment(allowedBookingDate).format("YYYY-MM-DD") +
        " that you can book.";

      if (this.state.overrideRuleDaysInAdvanceMessage !== "") {
        errorMessage = this.state.overrideRuleDaysInAdvanceMessage.replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );
      }
      this.props.showNotification("error", errorMessage);
      return;
    }

    // calendarNextNavDisable = formattedSelectedDate > allowedBookingDate;

    this.props.disableNextNav(calendarNextNavDisable);

    this.selectedDateVar = date;

    this.props.selectDate(date);

    this.overrideCalendarNextNavDisable = calendarNextNavDisable;

    this.setState({
      searchDateVal: formattedSelectedDate,
      isClientSideFilter: false,
      ClientSidefilterCompleted: false,
      isDateFilter: true,
      calendarNextNavDisable: calendarNextNavDisable,
    });

    // if (this.state.private) {
    //   this.props.getLocationResourcesPrivate(
    //     params.uuid,
    //     { is_active: 1, display_online: 1, date: this.state.searchDateVal },
    //     this.props.authUser.sessionToken
    //   );
    // } else {
    //   this.props.getLocationResources(params.uuid, formattedDate);
    // }
    // this.intervalId = setInterval(this.refreshCalendar.bind(this), 5000);
    // this.refreshCalendar();
  }, 0);

  dateOnChange = (date) => {
    this.setState({
      isClientSideFilter: false,
      ClientSidefilterCompleted: false,
      locationResourcesLoaded: false,
    });
    this.debouncedSelectedDate(date);
  };

  datePicker() {
    if (this.state.private) {
      return (
        <CalendarDatePicker
          isPrivate={this.state.private}
          key={this.days_in_advance}
          maxDate={
            this.days_in_advance && addDays(new Date(), this.days_in_advance) // add only a max date for private user
          }
          dateOnChange={this.dateOnChange}
        />
      );
    } else {
      return (
        <CalendarDatePicker
          isPrivate={this.state.private}
          key={this.days_in_advance}
          minDate={new Date()}
          maxDate={addDays(new Date(), 30)}
          dateOnChange={this.dateOnChange}
        />
      );
    }
  }

  getValueOfSelectCourtType = (value) => {
    if (this.state.doDropdownFilter) {
      this.setState({
        isClientSideFilter: true,
        selectedResource: value,
        ClientSidefilterCompleted: false,
      });
    }
  };

  getValueOfSelectSurfaceType = (value) => {
    if (this.state.doDropdownFilter) {
      this.setState({
        isClientSideFilter: true,
        selectedSurface: value,
        ClientSidefilterCompleted: false,
      });
    }
  };

  filterEnable = () => {
    this.setState({ doDropdownFilter: true });
  };

  headerSection() {
    const { courtTypeOptions, surfaceTypeOptions, isDefaultUser } = this.state;
    return (
      <div className={`calendarHeader`}>
        {this.props.locationResources && isDefaultUser && (
          <BannerMessage
            organisationId={
              this.props.locationResources.results[0].organisation.id
            }
          />
        )}

        <MDBRow className={`${this.props.private ? "" : ""}`}>
          <MDBCol md="9">
            <MDBRow className="">
              <MDBCol md="3">
                <span className="custom-label">Select Date</span>
                {this.datePicker()}
              </MDBCol>
              {courtTypeOptions.length > 2 && (
                <MDBCol md="3" style={{ display: "none" }}>
                  <MDBSelect
                    outline
                    color="primary"
                    onClick={this.filterEnable}
                    getValue={this.getValueOfSelectCourtType}
                    getTextContent={this.getValueOfSelectCourtType}
                    options={courtTypeOptions}
                    label="Resource Type"
                  />
                </MDBCol>
              )}
              {surfaceTypeOptions.length > 2 && (
                <MDBCol md="3" style={{ display: "none" }}>
                  <MDBSelect
                    outline
                    color="primary"
                    onClick={this.filterEnable}
                    getValue={this.getValueOfSelectSurfaceType}
                    getTextContent={this.getValueOfSelectSurfaceType}
                    options={surfaceTypeOptions}
                    label="Surface Type"
                  />
                </MDBCol>
              )}
            </MDBRow>
          </MDBCol>
          <MDBCol>
            <div className="float-right">{this.statusIndicator()}</div>
          </MDBCol>
        </MDBRow>
        <CalendarDateName />
        <div className="float-right goToTodayCont">
          <MDBBtn
            size="sm"
            onClick={() =>
              this.setState({ showTermsAndConditionalModal: true })
            }
          >
            Terms and Conditions
          </MDBBtn>
          {this.renderSubscribeButton()}
          <MDBBtn
            className="goToToday"
            size="sm"
            onClick={() => this.doGoToToday()}
            onMouseEnter={() => this.clearRefreshCalendarTimer()}
          >
            Go to Today
          </MDBBtn>
        </div>
      </div>
    );
  }

  statusIndicator() {
    const { bookingColors } = this.state;
    if (!this.state.private) {
      return (
        <div>
          <div>
            <MDBBadge className={getCss(bookingColors.bg_public_available)}>
              <span className={getCss(bookingColors.tc_public_available)}>
                Available
              </span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_public_booked)}>
              <span className={getCss(bookingColors.tc_public_booked)}>
                Booked
              </span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_public_notAvailable)}>
              <span className={getCss(bookingColors.tc_public_notAvailable)}>
                Not available
              </span>
            </MDBBadge>
          </div>
          <span className="info-label">Click available block to book</span>
        </div>
      );
    } else {
      // if (bookingColors !== undefined) {
      return (
        <div>
          <div>
            <MDBBadge className={getCss(bookingColors.bg_available)}>
              <span className={getCss(bookingColors.tc_available)}>
                Available
              </span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_oneOff)}>
              <span className={getCss(bookingColors.tc_oneOff)}>One Off</span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_recurring)}>
              <span className={getCss(bookingColors.tc_recurring)}>
                Recurring
              </span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_maintenance)}>
              <span className={getCss(bookingColors.tc_maintenance)}>
                Maintenance
              </span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_coachingSession)}>
              <span className={getCss(bookingColors.tc_coachingSession)}>
                Coaching Session
              </span>
            </MDBBadge>
            <MDBBadge className={getCss(bookingColors.bg_notAvailable)}>
              <span className={getCss(bookingColors.tc_notAvailable)}>
                Not available
              </span>
            </MDBBadge>
          </div>
          <span className="info-label">Click available block to book</span>
        </div>
      );
      // }
      return <></>;
    }
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Checkout",
    };
    this.props.toggleModal(modal);
  };

  checkout() {
    this.props.history.push("/checkout");
  }

  getAvailableSlotOld(resourceId, time) {
    let resource = _.chain(this.props.locationResources.results)
      .map((resource) => {
        if (resource.uuid === resourceId) {
          return resource;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    let minDuration = parseInt(
      resource.attributes.min_duration.replace("m", "")
    );
    let maxDuration = parseInt(
      resource.attributes.max_duration.replace("m", "")
    );

    let remainingDuration = maxDuration - minDuration;

    let minTime = moment(_.split(time, "-"[1]), "HH:mm")
      .add(30, "minutes")
      .format("HH:mm");

    let maxTime = moment(_.split(time, "-"[1]), "HH:mm")
      .add(maxDuration, "minutes")
      .format("HH:mm");

    let addedTime = minTime;

    let formattedTimeArr = [];
    let toFormatObj = {};

    let counter = 0;
    while (addedTime !== maxTime) {
      counter++;
      if (counter !== 1) {
        addedTime = moment(addedTime, "HH:mm")
          .add(30, "minutes")
          .format("HH:mm");
      }
      if (toFormatObj.start === undefined) {
        toFormatObj["start"] = addedTime;
      } else if (
        toFormatObj.start !== undefined &&
        toFormatObj.end === undefined
      ) {
        toFormatObj["end"] = addedTime;
      }

      if (toFormatObj.start !== undefined && toFormatObj.end !== undefined) {
        formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
        addedTime = toFormatObj.start;
        toFormatObj = {};
      }
    }

    let filteredFormattedTimeArr = [];

    for (let i = 0; i < formattedTimeArr.length; i++) {
      let isFound = false;
      _.map(resource.booking, (book) => {
        if (book.start_time === _.split(formattedTimeArr[i], "-")[0]) {
          isFound = true;
        }
      });

      if (!isFound) {
        filteredFormattedTimeArr.push(formattedTimeArr[i]);
      } else {
        break;
      }
    }

    return filteredFormattedTimeArr;
  }

  getAvailableSlot(resourceId, time, paramData) {
    let tz = this.getTzDetails(new Date(this.state.searchDateVal));
    let resource = _.chain(this.state.locationResourcesContainer.results)
      .map((resource) => {
        if (resource.uuid === resourceId) {
          return resource;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    let minDuration = parseInt(
      resource.attributes.min_duration.replace("m", "")
    );
    let maxDuration = parseInt(
      resource.attributes.max_duration.replace("m", "")
    );

    let durationPerChunk = parseInt(
      resource.attributes.duration_per_chunk.replace("m", "")
    );

    let minTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(minDuration, "minutes")
      .format("HH:mm");

    let maxTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(maxDuration, "minutes")
      .format("HH:mm");
    if (maxTime > resource.attributes.default_end_time) {
      maxTime = resource.attributes.default_end_time;
    }
    let addedTime = minTime;

    let formattedTimeArr = [];
    let toFormatObj = {};

    let counter = 0;
    while (addedTime !== maxTime) {
      counter++;
      if (counter !== 1) {
        addedTime = moment(addedTime, "HH:mm")
          .add(durationPerChunk, "minutes")
          .format("HH:mm");
      }
      if (toFormatObj.start === undefined) {
        toFormatObj["start"] = addedTime;
      } else if (
        toFormatObj.start !== undefined &&
        toFormatObj.end === undefined
      ) {
        toFormatObj["end"] = addedTime;
      }

      if (toFormatObj.start !== undefined && toFormatObj.end !== undefined) {
        formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
        addedTime = toFormatObj.start;
        toFormatObj = {};
      }
    }
    let durationArr = [];

    // --set initial value
    durationArr.push(minDuration);

    let bookedStartTimeArr = _.chain(paramData.bookings)
      .map((book) => {
        return {
          startTime: moment(book.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          endTime: moment(book.end_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          bookingRef: book.booking_reference,
        };
      })
      .orderBy(["startTime"], ["asc"])
      .value();

    let recurrings = paramData.recurrings;
    for (let i = 0; i < formattedTimeArr.length; i++) {
      let isFound = false;
      let formattedStartTime = _.split(formattedTimeArr[i], "-")[0];
      _.map(bookedStartTimeArr, (bookTimeObj) => {
        if (
          this.state.bookingRef !== bookTimeObj.bookingRef &&
          bookTimeObj.startTime === formattedStartTime
        ) {
          isFound = true;
        }
      });

      if (!isFound) {
        _.map(recurrings, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(tz.timezone)
            .format("HH:mm");
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }

      let booking_requests = paramData.booking_requests;

      if (!isFound) {
        _.map(booking_requests, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(tz.timezone)
            .format("HH:mm");
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }

      let lockedTimeslots = paramData.locked;
      if (!isFound) {
        _.map(lockedTimeslots, function (data) {
          let booking_from = data.timeslot;
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }

      if (!isFound) {
        durationArr.push(
          durationPerChunk + durationArr[durationArr.length - 1]
        );
      } else {
        break;
      }
    }

    return durationArr;
  }

  updateTimeValue(duration) {
    const { showBookingDetailsModal } = this.state;
    let origTime = this.state.origTime;
    let startTime = _.split(origTime, "-")[0];
    let endTime = moment(startTime, "HH:mm")
      .add(duration, "minutes")
      .format("HH:mm");

    this.setState({ time: startTime + "-" + endTime });
    this.validateShowExceedMaxDuration(
      duration,
      this.state.resourceUuid,
      showBookingDetailsModal
    );

    this.setState({
      duration: duration,
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,
      isChangedDuration: false,
    });
  }

  onDurationChange = (evt) => {
    this.props.showHideLoader(true);
    let duration = evt.target.value;
    let origTime = this.state.origTime;
    let startTime = _.split(origTime, "-")[0];
    let endTime = moment(startTime, "HH:mm")
      .add(duration, "minutes")
      .format("HH:mm");

    let selectedTime = startTime + "-" + endTime;

    const resourceData = this.buildResourceDataObj();
    let requestObj = {};
    requestObj["organisationId"] = resourceData.organisationId;
    requestObj["locationId"] = resourceData.locationId;
    requestObj["resourceId"] = resourceData.resourceId;
    requestObj["from"] = resourceData.bookingData.from;
    requestObj["to"] = resourceData.bookingData.to;
    const chunks = this.generateChunk(selectedTime);
    requestObj["chunks"] = chunks;
    // Send initial Request to lock the timeslot to check if there is conflict
    this.props
      .lockBookingTimeslot(requestObj, this.props.authUser.sessionToken)
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data.isConflictTimeslot) {
            const conflictedTimeSlotFrom = moment(
              response.data.conflictedTimeSlot
            ).format("HH:mm");

            const conflictedTimeSlotTo = moment(
              response.data.conflictedTimeSlot
            )
              .add(30, "minutes")

              .format("HH:mm");

            this.props.showHideLoader(false);
            this.props.showNotification(
              "error",
              "Cannot extend duration, timeslot " +
                conflictedTimeSlotFrom +
                " - " +
                conflictedTimeSlotTo +
                "  already taken by other user.",
              60000
            );
          } else {
            this.updateTimeValue(duration);
            this.intervalLockBookingtimeslot();
          }
        }
      });
  };

  getAvailableSlotPrivate(resourceId, time, paramData) {
    let tz = this.getTzDetails(new Date(this.state.searchDateVal));

    let resource = _.chain(this.state.locationResourcesContainer.results)
      .map((resource) => {
        if (resource.uuid === resourceId) {
          return resource;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];
    let minDuration = parseInt(
      resource.attributes.min_duration.replace("m", "")
    );
    let maxDuration = parseInt(
      resource.attributes.max_duration.replace("m", "")
    );

    let durationPerChunk = parseInt(
      resource.attributes.duration_per_chunk.replace("m", "")
    );

    let minTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(30, "minutes")
      .format("HH:mm");

    let maxTime = resource.attributes.default_end_time;

    let addedTime = minTime;

    let formattedTimeArr = [];
    let toFormatObj = {};

    let counter = 0;
    while (addedTime !== maxTime) {
      counter++;
      if (counter !== 1) {
        addedTime = moment(addedTime, "HH:mm")
          .add(durationPerChunk, "minutes")
          .format("HH:mm");
      }
      if (toFormatObj.start === undefined) {
        toFormatObj["start"] = addedTime;
      } else if (
        toFormatObj.start !== undefined &&
        toFormatObj.end === undefined
      ) {
        toFormatObj["end"] = addedTime;
      }

      if (toFormatObj.start !== undefined && toFormatObj.end !== undefined) {
        formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
        addedTime = toFormatObj.start;
        toFormatObj = {};
      }
    }

    let durationArr = [];

    // --set initial value
    durationArr.push(30);

    let bookedStartTimeArr = _.chain(paramData.bookings)
      .map((book) => {
        return {
          startTime: moment(book.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          endTime: moment(book.end_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          bookingRef: book.booking_reference,
        };
      })
      .orderBy(["startTime"], ["asc"])
      .value();
    let recurrings = paramData.recurrings;
    for (let i = 0; i < formattedTimeArr.length; i++) {
      let isFound = false;
      let formattedStartTime = _.split(formattedTimeArr[i], "-")[0];
      _.map(bookedStartTimeArr, (bookTimeObj) => {
        if (
          this.state.bookingRef !== bookTimeObj.bookingRef &&
          bookTimeObj.startTime === formattedStartTime
        ) {
          isFound = true;
        }
      });

      if (!isFound) {
        _.map(recurrings, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(tz.timezone)
            .format("HH:mm");
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }
      let booking_requests = paramData.booking_requests;

      if (!isFound) {
        _.map(booking_requests, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(tz.timezone)
            .format("HH:mm");
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }

      let lockedTimeslots = paramData.locked;
      if (!isFound) {
        _.map(lockedTimeslots, function (data) {
          let booking_from = data.timeslot;
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }

      if (!isFound) {
        durationArr.push(
          durationPerChunk + durationArr[durationArr.length - 1]
        );
      } else {
        break;
      }
    }

    return durationArr;
  }

  validateModal(paramData) {
    let genStartTime = _.split(paramData.time, "-")[0];

    let minDuration = new Number(
      _.replace(paramData.minDuration, "m", "")
    ).valueOf();

    let minimumEndTime = moment(genStartTime, "HH:mm")
      .add(minDuration, "minutes")
      .format("HH:mm");

    if (minimumEndTime > paramData.defaultEndTime) {
      return false;
    } else {
      let maxTime = "";
      // Checked booked time slot
      let bookedStartTimeArr = _.chain(paramData.bookings)
        .map((data) => {
          return moment(data.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
        })
        .sort()
        .value();

      for (let i = 0; i < bookedStartTimeArr.length; i++) {
        if (
          moment(genStartTime, "HH:mm").isBefore(
            moment(bookedStartTimeArr[i], "HH:mm")
          )
        ) {
          maxTime = bookedStartTimeArr[i];
          break;
        }
      }

      if (maxTime.length > 0) {
        if (
          (genStartTime < maxTime && minimumEndTime > maxTime) ||
          (genStartTime == maxTime && minimumEndTime == maxTime)
        ) {
          return false;
        }
      }

      maxTime = "";
      // Checked booking request time slot
      let bookingRequestTimeArr = _.chain(paramData.booking_requests)
        .map((book) => {
          return moment(book.booking_from)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
        })
        .sort()
        .value();

      for (let i = 0; i < bookingRequestTimeArr.length; i++) {
        if (
          moment(genStartTime, "HH:mm").isBefore(
            moment(bookingRequestTimeArr[i], "HH:mm")
          )
        ) {
          maxTime = bookingRequestTimeArr[i];
          break;
        }
      }

      if (maxTime.length > 0) {
        if (
          (genStartTime < maxTime && minimumEndTime > maxTime) ||
          (genStartTime == maxTime && minimumEndTime == maxTime)
        ) {
          return false;
        }
      }

      maxTime = "";
      // Checked recurring bookings time slot
      let recurringBookingsTimeArr = _.chain(paramData.recurrings)
        .map((data) => {
          return moment(data.booking_from)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm");
        })
        .sort()
        .value();

      for (let i = 0; i < recurringBookingsTimeArr.length; i++) {
        if (
          moment(genStartTime, "HH:mm").isBefore(
            moment(recurringBookingsTimeArr[i], "HH:mm")
          )
        ) {
          maxTime = recurringBookingsTimeArr[i];
          break;
        }
      }

      if (maxTime.length > 0) {
        if (
          (genStartTime < maxTime && minimumEndTime > maxTime) ||
          (genStartTime == maxTime && minimumEndTime == maxTime)
        ) {
          return false;
        }
      }

      // maxTime = "";
      // console.log(paramData);
      // let lockedTimeslots = _.chain(paramData.locked)
      //   .map((data) => {
      //     return data.timeslot;
      //   })
      //   .sort()
      //   .value();

      // console.log(lockedTimeslots, "lockedTimeslots");
      // console.log(moment(genStartTime, "HH:mm"), "genStartTime");
      // let isFound = false;

      // _.map(lockedTimeslots, function (data) {
      //   let booking_from = data.timeslot;
      //   console.log(moment(genStartTime, "HH:mm").isBefore(moment(booking_from, "HH:mm")));

      //   moment(booking_from, "HH:mm")

      //   if (!isFound && moment(genStartTime, "HH:mm").isBefore(booking_from)) {
      //     maxTime = booking_from;
      //     isFound = true;
      //   }
      // });

      // console.log(maxTime, "maxTime");

      // if (maxTime.length > 0) {
      //   if (
      //     (genStartTime < maxTime && minimumEndTime > maxTime) ||
      //     (genStartTime == maxTime && minimumEndTime == maxTime)
      //   ) {
      //     return false;
      //   }
      // }
    }

    return true;
  }

  openModal = (paramData) => {
    // Used to open an availablle booking modal for public users

    clearInterval(this.intervalId);
    this.intervalId = null;

    let minDuration = new Number(
      _.replace(paramData.minDuration, "m", "")
    ).valueOf();
    if (!this.validateModal(paramData)) {
      minDuration = minDuration / 60;
      const modal = {
        isOpen: true,
        content: `Sorry but the selected time slot ${paramData.time} is not available. Minimum duration is ${minDuration} Hour.`,
        title: "Sorry",
        negativeButtonText: "",
        positiveButtonText: "Ok",
        positiveButtonAction: this.closeModal.bind(this),
      };
      this.props.toggleModal(modal);
    } else {
      let location = this.props.locations.results[0];
      // const modal = {
      //   isOpen: true,
      //   content: `Do you want to book ${location.name}, ${paramData.court} at ${paramData.time}?`,
      //   title: "Confirmation",
      //   negativeButtonText: "Cancel",
      //   positiveButtonText: "Book",
      //   negativeButtonAction: this.closeModal.bind(this),
      //   positiveButtonAction: this.checkout.bind(this),
      // };
      // this.props.toggleModal(modal);

      let timeFrom = _.split(paramData.time, "-")[0];
      let timeTo = moment(_.split(paramData.time, "-")[0], "HH:mm")
        .add(minDuration, "minutes")
        .format("HH:mm");

      if (
        typeof paramData.maxBookingParticipants !== "undefined" &&
        paramData.maxBookingParticipants != ""
      ) {
        this.maxBookingParticipants = paramData.maxBookingParticipants;
      }

      let details = {
        timezone: location.attributes.timezone,
        maxBookingParticipants: this.maxBookingParticipants,
        termsConditions: location.attributes.terms_and_conditions,
        organisationId: location.organisation.id,
        location: location.name,
        locationUuid: location.uuid,
        locationId: location.id,
        resource: paramData.court,
        resourceUuid: paramData.resourceUuid,
        resourceId: paramData.resourceId,
        reservationDate: moment(this.props.selectedDate).format("DD/MM/YYYY"),
        reservationDateInUTC: moment
          .utc(this.props.selectedDate)
          .format("DD/MM/YYYY"),
        time: timeFrom + "-" + timeTo,
        availableSlot: this.getAvailableSlot(
          paramData.resourceUuid,
          paramData.time,
          paramData
        ),
      };

      this.props.setBookingDetails(details);

      let customerDetails = {
        email: "",
      };
      this.setState({
        selectedCustomerOrParticipant: customerDetails,
        isValidateCustomerOrParticipant: true,
        time: timeFrom + "-" + timeTo,
      }); // Component did update will be triggered when this is changed
    }
  };

  toggleOuterTabs = (tab) => (e) => {
    const { activeItemOuterTabs2 } = this.state;
    if (activeItemOuterTabs2 !== tab) {
      this.setState({
        activeItemOuterTabs: tab,
      });
    }
  };

  toggleInnerPills = (tab) => (e) => {
    const { activeItemInnerPills } = this.state;
    if (activeItemInnerPills !== tab) {
      this.setState({
        activeItemInnerPills: tab,
      });
    }
  };

  buildPaymentHistory(paymentTransaction) {
    let paymentHistoryElem = [];
    paymentHistoryElem.push(
      <MDBTable responsive key="tablekey1">
        <MDBTableHead>
          <tr>
            <th>Transaction Type</th>
            <th>Payment Method</th>
            <th className="text-align-right">Amount</th>
            <th>Discount Code</th>
            <th className="text-align-right">Discount Amount</th>
            <th className="text-align-right">Total Amount</th>
            <th>Transaction Date</th>
            <th>Status</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {paymentTransaction.payments.map((payment, index) => (
            <tr key={index}>
              <td key={index + "1"}>{payment.method.vendor}</td>
              <td key={index + "2"}>{payment.method.method}</td>
              <td key={index + "3"} className="text-align-right">
                ${roundNumber(payment.subTotalAmount, 2)}
              </td>
              <td key={index + "4"}>{payment.voucherCode}</td>
              <td key={index + "5"} className="text-align-right">
                ${roundNumber(payment.voucherCreditAmount, 2)}
              </td>
              <td key={index + "6"} className="text-align-right">
                ${roundNumber(payment.totalAmount, 2)}
              </td>
              <td key={index + "7"}>
                {moment(
                  _.split(payment.paymentDate, " ")[0] +
                    "T" +
                    _.split(payment.paymentDate, " ")[1] +
                    "Z",
                  "YYYY-MM-DDTHH:mm:ssZ"
                )
                  .tz(this.getTzDetails().timezone)
                  .format("DD/MM/YYYY HH:mm:ss")}
              </td>
              <td key={index + "8"}>{payment.status}</td>
              <td key={index + "9"}>{payment.comments}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    );
    return paymentHistoryElem;
  }

  renderPaymentHistory() {
    const { paymentHistoryElem } = this.state;
    if (paymentHistoryElem.length > 0) {
      return <div>{paymentHistoryElem}</div>;
    }
    return <span></span>;
  }

  buildStatusHistory(getBookingStatusSuccessResponse) {
    let bookingStatusHistoryElem = [];
    bookingStatusHistoryElem.push(
      <MDBTable responsive scrollY key="sttable1">
        <MDBTableHead>
          <tr>
            <th>Status</th>
            <th>Date</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {getBookingStatusSuccessResponse.data.history.map(
            (history, index) => (
              <tr key={index}>
                <td key={index + "1"}>{history.status}</td>
                <td key={index + "7"}>
                  {moment(
                    _.split(history.submittedDate, " ")[0] +
                      "T" +
                      _.split(history.submittedDate, " ")[1] +
                      "Z",
                    "YYYY-MM-DDTHH:mm:ssZ"
                  )
                    .tz(this.getTzDetails().timezone)
                    .format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td key={index + "9"}>{history.comments}</td>
              </tr>
            )
          )}
        </MDBTableBody>
      </MDBTable>
    );

    return bookingStatusHistoryElem;
  }

  renderStatusHistory() {
    const { bookingStatusHistoryElem } = this.state;
    if (bookingStatusHistoryElem.length > 0) {
      return <div>{bookingStatusHistoryElem}</div>;
    }
    return <span></span>;
  }

  validateStatusChangeButton() {
    const { isChargable, isOverrideble, issueVoucher, disableStatusButton } =
      this.state;
    return (
      <div>
        {isOverrideble === 1 && (
          <MDBBtn
            color="info"
            className="float-right"
            disabled={disableStatusButton}
            type="submit"
            onClick={this.doStatusChange("issueVoucher")}
          >
            Issue Voucher
          </MDBBtn>
        )}
        {isChargable === 1 && (
          <MDBBtn
            color="info"
            className="float-right"
            disabled={disableStatusButton}
            type="submit"
            onClick={this.doStatusChange("charge")}
          >
            Charge
          </MDBBtn>
        )}
        {issueVoucher === 1 && (
          <MDBBtn
            color="info"
            className="float-right"
            disabled={disableStatusButton}
            type="submit"
            onClick={this.doStatusChange("issueVoucher")}
          >
            Issue Voucher
          </MDBBtn>
        )}
      </div>
    );
  }

  isBookingStatusChangeFailed() {
    if (
      (this.props.genCustomerVoucherFailedResponse !== null &&
        this.props.genCustomerVoucherFailedResponse.status === 400) ||
      this.props.genCustomerVoucherFailedResponse === "Network error"
    ) {
      this.props.showHideLoader(false);
      return (
        <MDBAlert color="danger" className="text-center">
          Unable to issue voucher. Please try again later or contact system
          administrator.
        </MDBAlert>
      );
    }
    return <span></span>;
  }

  isBookingStatusChangeSuccess() {
    if (this.props.genCustomerVoucherSuccessResponse.status === 200) {
      return (
        <MDBAlert color="success" className="text-center">
          Voucher has been generated and issued to customer and the booking is
          cancelled.
        </MDBAlert>
      );
    }

    return <span></span>;
  }

  renderStatusChangeConfirmation() {
    if (this.state.statusChangeConfirmation.length > 0) {
      return (
        <MDBCard border="info" className="m-3">
          <MDBCardBody className="text-info">
            <MDBCardTitle tag="h5">Confirm change status</MDBCardTitle>
            <MDBCardText>
              Status change to <strong>{this.state.status}</strong>.
              <br />
              <br />
              {this.state.statusChangeConfirmation}
            </MDBCardText>
            {this.isBookingStatusChangeSuccess()}
            {this.isBookingStatusChangeFailed()}
            {this.validateStatusChangeButton()}
          </MDBCardBody>
        </MDBCard>
      );
    }
    return <span></span>;
  }

  renderDiscountSection(coupon) {
    return (
      <>
        <MDBCol size="6">
          <label
            ref={this.customerDiscountCodeRef}
            htmlFor="idCoupon"
            className="grey-text"
          >
            Discount Code
          </label>
          <input
            value={coupon}
            name="coupon"
            onChange={this.onCouponChange}
            type="text"
            id="idCoupon"
            className="form-control"
            onFocus={(event) => {
              if (this.customerDiscountCodeRef.current) {
                setTimeout(() => {
                  this.customerDiscountCodeRef.current.scrollIntoView();
                }, 300);
              }
            }}
          />
          {this.isInvalidCoupon()}
        </MDBCol>
        <MDBCol size="6"></MDBCol>
      </>
    );
  }

  renderBookingStatuses() {
    if (this.props.getStatusesSuccessResponse.status === 200) {
      let statuses = _.chain(
        `this.props.getStatusesSuccessResponse.data.statuses`
      )
        .orderBy(["name"], ["asc"])
        .value();

      return (
        <MDBCol md="6" className="mb-3">
          <label className="grey-text">Status</label>

          <select
            className="browser-default custom-select"
            name="status"
            onChange={this.statusChangeHandler}
            defaultValue={this.state.status}
          >
            {statuses.map((status) => (
              <option key={status.id} value={status.name}>
                {status.name}
              </option>
            ))}
          </select>

          <div className="mt-3">
            <label className="grey-text">Description</label>
            <h5>{this.state.statusDesc}</h5>
          </div>
        </MDBCol>
      );
    }
    return <span></span>;
  }

  showCalendarSelect = (show) => {
    this.setState({ showCalendarSelect: show });
  };

  doCharge(doCharge) {
    let bookingParams = {
      isUpdate: true,
      generateVoucher: false,
      doCharge: doCharge,
    };
    this.doBooking(bookingParams);
  }

  validatePriceChangeWarning() {
    const {
      origPrice,
      totalPrice,
      showPriceChangeWarning,
      couponDiscount,
      paid,
      isDefaultUser,
    } = this.state;

    if (
      couponDiscount === 0 &&
      showPriceChangeWarning &&
      origPrice < totalPrice &&
      !isDefaultUser
    ) {
      return (
        <MDBRow>
          <MDBCol size="12" className="mt-2 text-info price-change-msg">
            *** Price changed. Do you wish to send a{" "}
            <strong>payment request</strong> to the customer's email with the
            amount of{" "}
            <strong style={{ fontSize: "large" }}>
              ${Math.abs(origPrice - totalPrice)}?
            </strong>
            <MDBBtn
              color="primary"
              type="button"
              size="sm"
              onClick={() => this.doCharge(true)}
            >
              Send payment request
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    } else if (showPriceChangeWarning && origPrice > totalPrice && paid) {
      return (
        <MDBRow>
          <MDBCol size="12" className="mt-2 text-info price-change-msg">
            *** Price changed. Upon saving, a <strong>credit voucher</strong>{" "}
            will be issued to the customer with the amout of{" "}
            <strong style={{ fontSize: "large" }}>
              ${Math.abs(origPrice - totalPrice)}
            </strong>
            . An email will be sent to the customer containing the voucher code
            ***
          </MDBCol>
        </MDBRow>
      );
    }

    return <span></span>;
  }

  renderMaxDurationExceedAcknowledge() {
    const { showExceedMaxDurationAcknowledge, maxDuration } = this.state;

    if (showExceedMaxDurationAcknowledge) {
      return (
        <MDBRow>
          <MDBCol>
            <div
              className="custom-control custom-checkbox mt-2"
              style={{ textAlign: "center" }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="checkMoreThanMaxDuration"
                defaultChecked={true}
                // onChange={this.checkMoreThanMaxDurationOnChange}
                required
              />
              <label
                className="custom-control-label"
                htmlFor="checkMoreThanMaxDuration"
              >
                I acknowledge that the customer will book more than the maximum
                duration{" "}
                <strong>
                  {maxDuration + " Minutes or " + maxDuration / 60 + " Hour/s"}
                </strong>
              </label>
            </div>
          </MDBCol>
        </MDBRow>
      );
    }
    return <span></span>;
  }

  toggleDetailsModal = () => {
    this.setState({
      showBookingDetailsModal: !this.state.showBookingDetailsModal,
    });
    this.resetBookingStates();
  };

  showHideTC = () => {
    this.setState({ showHideTC: !this.state.showHideTC });
  };

  renderBookingDetailsForm() {
    const { showBookingDetailsModal, selectedBookingDetails, resourceUuid } =
      this.state;
    if (showBookingDetailsModal) {
      let resource = _.chain(this.props.locationResources.results)
        .map((resource) => {
          if (resource.uuid === resourceUuid) {
            return resource;
          }
          return [];
        })
        .filter(_.size)
        .value()[0];

      return (
        <div className="booking-modal">
          <BookingEditor
            doCalculate={true}
            tableElem={this.state.tableElem}
            statuses={this.props.getStatusesSuccessResponse.data.statuses}
            selectedBooking={selectedBookingDetails}
            isView={
              !validateAccess(
                this.props.storeUserAccess,
                "AdminBooking",
                "updateBooking"
              )
            }
            locationResource={resource}
            locationObj={this.state.locationObj}
            newSelectedBookingsContainer={
              this.state.newSelectedBookingsContainer
            }
          />
        </div>
      );
    }
    return <span></span>;
  }

  renderAvailableSlot(availableSlot, durationDefaultVal) {
    return (
      <MDBCol md="6">
        <label className="grey-text required">Select duration</label>
        <select
          className="browser-default custom-select"
          onChange={this.onDurationChange}
          defaultValue={durationDefaultVal}
          value={this.state.duration}
        >
          {availableSlot.map((time) => (
            <option key={time} value={time}>
              {time + " Minutes or " + time / 60 + " Hour/s"}
            </option>
          ))}
        </select>
      </MDBCol>
    );
  }

  toolbarItemRender() {
    return (
      <div className="informer">
        {this.maxBookingParticipants > 0 ? (
          <i>
            {" "}
            <small>
              (Max booking Participants is {this.maxBookingParticipants})
            </small>
          </i>
        ) : (
          ""
        )}
      </div>
    );
  }

  getBookingWarnings(chunks) {
    const { searchDateVal, recurrings } = this.state;

    var resourceId = 0;
    if (this.state.showBookingDetailsModal) {
      resourceId = this.state.selectedBookingDetails.resource.id;
    } else {
      resourceId = this.state.selectedBooking.resourceId;
    }
    var warnings = [];
    if (this.state.private) {
      chunks.forEach((value, index) => {
        var genStartTime = value.time;

        let currentDateTime = moment(searchDateVal + " " + genStartTime).format(
          "YYYY-MM-DD HH:mm"
        );

        let timezone = this.getTzDetails().timezone;
        let recurring = _.filter(recurrings, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm");
          let booking_to = moment(data.booking_to)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm");
          return (
            data["resource_id"] == resourceId &&
            currentDateTime >= booking_from &&
            currentDateTime < booking_to
          );
        });

        if (recurring.length > 0) {
          warnings.push(
            "There is a recurring booking for the timeslot " + genStartTime
          );
        }
      });
    }

    return warnings;
  }

  confirmBooking = (warnings, conflicts, bookingWarnMessages, canBook) => {
    let selectedTime = this.state.time;
    let day = moment(this.props.selectedDate).format("YYYY-MM-DD");
    let from = _.split(selectedTime, "-")[0];
    let to = _.split(selectedTime, "-")[1];

    let isBookingWarningOnly =
      warnings.length == 0 &&
      conflicts.length == 0 &&
      bookingWarnMessages.length > 0;

    const modal = {
      isOpen: true,
      content: (
        <div>
          {!isBookingWarningOnly && (
            <p>
              Are you sure you want to book date {day} with timeslot from {from}{" "}
              to {to}?
            </p>
          )}

          {warnings.length > 0 ? (
            <span style={{ color: "red" }}>
              Please review the warnings below:
            </span>
          ) : (
            ""
          )}

          <ul>
            {warnings.map((warning, index) => (
              <li key={index} style={{ color: "red" }}>
                {warning}
              </li>
            ))}
          </ul>
          {conflicts.length > 0 ? (
            <span style={{ color: "red" }}>
              Booking cannot be created.
              {canBook && conflicts.length > 0
                ? " Please review the validation messages below:"
                : " Please review the conflicts below:"}
            </span>
          ) : (
            ""
          )}
          <ul>
            {conflicts.map((warning, index) => (
              <li
                key={index}
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: warning }}
              ></li>
            ))}
          </ul>

          {bookingWarnMessages.length > 0 ? (
            <span style={{ color: "#3265b9", fontWeight: "bold" }}>
              Please Note:
            </span>
          ) : (
            ""
          )}
          <ul>
            {bookingWarnMessages.map((message, index) => (
              <li
                key={index}
                style={{ color: "#3265b9" }}
                dangerouslySetInnerHTML={{ __html: message }}
              ></li>
            ))}
          </ul>
        </div>
      ),
      title: (
        <span style={{ color: isBookingWarningOnly ? "#3265b9" : "red" }}>
          Booking Warnings
        </span>
      ),
      negativeButtonText: "Cancel",
      positiveButtonText:
        canBook && conflicts.length > 0
          ? "Yes, override validation errors."
          : "Yes, please proceed.",
      positiveButtonColor: isBookingWarningOnly ? "info" : "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.submitBooking.bind(this),
    };

    if (!canBook) {
      modal["hasPositiveButtonAction"] = false;
      delete modal["positiveButtonAction"];
      delete modal["positiveButtonColor"];
      delete modal["positiveButtonText"];
    }
    this.props.toggleModal(modal);
  };

  proceedButtonValidateCustomerOrParticipant(modal) {
    const { isMainCustomer, isDefaultUser } = this.state;

    if (isMainCustomer == true) {
      // This is for proceeding the default seleted logged in private customer
      this.props.pauseBackgroundRequests(true);

      if (!isDefaultUser) {
        this.addValidatedMainCustomer();
      }

      this.setState({
        showBookingModal: true,
      });
    } else if (isMainCustomer == false) {
      // This is for proceeding adding the guest participant
      this.addValidatedParticipant();
      this.props.pauseBackgroundRequests(true);
    }

    modal["isOpen"] = false;
    this.props.toggleModal(modal);
  }

  closeButtonValidateParticipant(modal) {
    if (!this.state.showBookingModal) {
      this.props.pauseBackgroundRequests(false);
    }

    modal["isOpen"] = false;
    this.props.toggleModal(modal);
  }

  showValidateCustomerOrParticipantModal = (validationData) => {
    const { isMainCustomer } = this.state;
    let warnings = validationData["blockingMessages"];

    let userType = this.getUserType();

    const modal = {
      isOpen: true,
      content: (
        <div>
          <ul>
            {warnings.map((warning, index) => (
              <li
                key={index}
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: warning }}
              ></li>
            ))}
          </ul>
        </div>
      ),
      title: <span style={{ color: "red" }}>Booking Warning</span>,
      negativeButtonText: "Close",
      positiveButtonText: "Yes, please proceed.",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeButtonValidateParticipant.bind(this),
      positiveButtonAction:
        userType === "public_user"
          ? this.debouncedCheckout.bind(this)
          : this.proceedButtonValidateCustomerOrParticipant.bind(this), // private booking add customer or participant
    };

    if (validationData["hasBlocker"] && validationData["action"] == "block") {
      modal["hasPositiveButtonAction"] = false;
      delete modal["positiveButtonAction"];
      delete modal["positiveButtonColor"];
      delete modal["positiveButtonText"];
    } else if (!validationData["hasBlocker"]) {
      if (userType !== "public_user") {
        if (isMainCustomer == true) {
          //TO DO UPDATE THE SHOWING OF MODAL HERE
          // Show the modal for the first time if its a private user
          if (userType == "member_user") {
            this.setState({
              showBookingModal: true,
            });
            this.calculatePrice();
          } else {
            this.addValidatedMainCustomer();
          }
        } else {
          this.addValidatedParticipant();
        }
      } else {
        this.debouncedCheckout();
      }
    }

    if (validationData["blockingMessages"].length > 0) {
      this.props.toggleModal(modal);
    }
    this.props.showHideLoader(false);
  };

  checkBookingConflicts = (bookingParams = {}) => {
    const {
      first_name,
      last_name,
      email,
      mobile,
      address,
      invoice,
      paid,
      notes,
      selectedBooking,
      duration,
      origTime,
      time,
      isRecurring,
      frequencyInWeeks,
      paymentMethod,

      totalPrice,
      chargeAmount,
      lessAmount,
      unPaidAmount,

      couponDiscount,
      coupon,
      bookingUuid,
      bookingId,

      statusNotes,
      status,

      locationId,
      locationUuid,
      locationName,

      resourceId,
      resourceUuid,
      resourceName,
      participants,
      sendNotification,
    } = this.state;
    this.props.showHideLoader(true);

    let updateSchedule = this.state.updateSchedule;
    let endDate = this.state.endDate;
    let location = this.state.locationObj;
    let overwriteUpdateSchedule = false;
    let priceData = {
      amount: totalPrice,
      currency: "AUD",
    };

    if (this.state.coupon.length > 0) {
      priceData["couponDiscount"] = couponDiscount;
      priceData["coupon"] = coupon;
    }

    if (chargeAmount > 0) {
      priceData["charge"] = chargeAmount;
      overwriteUpdateSchedule = true;
    }

    if (unPaidAmount > 0) {
      priceData["unpaid"] = unPaidAmount;
    }

    if (lessAmount > 0) {
      priceData["refund"] = lessAmount;
      overwriteUpdateSchedule = true;
    }

    if (endDate) {
      endDate = moment(endDate)
        .tz(this.getTzDetails().timezone)
        .format("YYYY-MM-DD");
    }

    if (origTime != time) {
      updateSchedule = true;
    }

    let participantsData = [];
    _.map(this.state.participants, (participant) => {
      if (
        participant["checked_in"] != undefined &&
        participant["checked_in_time"] != undefined &&
        participant["checked_in_time"] != ""
      ) {
        participant["checked_in_time"] =
          moment(participant["checked_in_time"])
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
      }
      participantsData.push(participant);
    });

    let details = {
      resourceData: {
        organisationId: location.organisation.id,
        bookingData: {
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,

          isRecurring: isRecurring,
          frequencyInWeeks: isRecurring ? frequencyInWeeks : "",
          endDate: endDate,

          chunks: this.generateChunk(this.state.time),
          isPaid: paid,
          invoice: invoice,
          paymentMethod: paymentMethod,
          notes: notes,
          sendNotification: sendNotification,
        },
      },
      priceData: priceData,
      customerData: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        address: address,
      },
      participantsData: participantsData,
    };

    this.props.showHideLoader(true);

    this.setState({
      isUpdate: bookingParams.isUpdate,
    });

    if (bookingParams.isUpdate) {
      details.resourceData.bookingData["bookingId"] = bookingId;
      if (status.length > 0) {
        details.resourceData.bookingData["status"] = status;
      }
      details.resourceData.bookingData["statusNotes"] = statusNotes;

      details.resourceData["locationId"] = locationId;
      details.resourceData["resourceId"] = resourceId;
      details.resourceData["resourceName"] = resourceName;

      details.resourceData.bookingData["updateSchedule"] =
        updateSchedule === true ? updateSchedule : overwriteUpdateSchedule;

      details.resourceData.bookingData["generate_voucher"] =
        bookingParams.generateVoucher;

      if (
        chargeAmount > 0 &&
        bookingParams.doCharge !== undefined &&
        bookingParams.doCharge
      ) {
        details.resourceData.bookingData["isPaid"] = false;
      } else {
        if (
          (couponDiscount > 0 && couponDiscount === chargeAmount) ||
          (couponDiscount > 0 && couponDiscount === unPaidAmount)
        ) {
          details.resourceData.bookingData["isPaid"] = true;
        }
      }
    } else {
      details.resourceData["locationId"] = location.id;
      details.resourceData["resourceId"] = selectedBooking.resourceId;
      details.resourceData["resourceName"] = selectedBooking.court;
    }
    this.props.checkBookingConflicts(
      details,
      this.props.authUser.sessionToken,
      "calendar"
    );
  };

  isSelectedTimeslotPassed(chunks) {
    const { searchDateVal } = this.state;

    let isPassed = false;
    if (this.state.private) {
      chunks.forEach((value, index) => {
        var genStartTime = value.time;

        let currentDate = moment().format("YYYY-MM-DD");
        let currentTime = moment().format("HH:mm");
        if (currentTime > genStartTime && searchDateVal <= currentDate) {
          isPassed = true;
        }
      });
    }

    return isPassed;
  }

  submitBookingHandler = (
    recurringWarnings,
    validationMessages,
    bookingWarningMessages,
    action = ""
  ) => {
    let chunks = this.generateChunk(this.state.time);
    let warnings = this.getBookingWarnings(chunks);
    let canBook = recurringWarnings.length == 0;
    const { isDefaultUser } = this.state;

    if (action !== "") {
      canBook = action === "WARN" ? true : false;
    }

    const isPassed = this.isSelectedTimeslotPassed(chunks);
    if (isPassed && isDefaultUser) {
      canBook = false;
      warnings.push("Selected datetime slot has been passed.");
    }

    if (
      recurringWarnings.length > 0 ||
      warnings.length > 0 ||
      validationMessages.length > 0 ||
      bookingWarningMessages.length > 0
    ) {
      let conflicts = [];
      recurringWarnings.forEach((value, index) => {
        let booking_from = moment(value.booking_from).tz(
          this.state.locationTimezone
        );
        let booking_to = moment(value.booking_to).tz(
          this.state.locationTimezone
        );
        let booking_time =
          booking_from.format("HH:mm") + " - " + booking_to.format("HH:mm");
        conflicts.push(
          "Reference " +
            value.booking_reference +
            " - " +
            booking_from.format("YYYY/MM/DD") +
            " " +
            booking_time
        );
      });

      _.map(validationMessages, function (value) {
        conflicts.push(value);
      });

      this.props.showHideLoader(false);

      this.confirmBooking(warnings, conflicts, bookingWarningMessages, canBook);
    } else {
      console.log("submit Booking now!");
      this.submitBooking();
    }
  };

  debounceCheckBookingConflict = _.debounce(function () {
    this.checkBookingConflicts();
  }, 500);

  submitHandler = (event) => {
    event.preventDefault();
    // this.props.clearSuccessHandler();
    // this.props.clearFailedHandler();
    this.setState({
      wasValidated: "was-validated",
      isBookingSaving: true,
      // formCleared: false
    });

    if (
      event.target.checkValidity() &&
      (this.state.alert.text == "" || this.state.alert.clearAfter)
    ) {
      this.props.showHideLoader(true);
      this.debounceCheckBookingConflict();
    } else {
      document.querySelector(".modal.show").scrollTo(0, 0);
    }
  };

  submitBooking() {
    this.props.showHideLoader(true);
    // if (!this.state.invalidCoupon) {
    let bookingParams = { isUpdate: false, generateVoucher: false };
    this.doBooking(bookingParams);
    // }
  }

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  doStatusChange = (action) => (e) => {
    this.generateVoucher = false;
    if (action === "issueVoucher") {
      this.generateVoucher = true;
    }
    // let bookingParams = { isUpdate: true, generateVoucher: generateVoucher };
    // this.doBooking(bookingParams);

    // this.setState({
    //   disableStatusButton: true,
    //   disableSaveButton: true,
    // });
  };

  validateSelectedDuration() {
    let selectedTime = this.state.time;
    let reservationDate = moment(this.props.selectedDate).format("YYYY-MM-DD");

    let tz = this.getTzDetails(new Date(reservationDate));
    let from =
      moment(reservationDate + "T" + _.split(selectedTime, "-")[0] + tz.offset)
        .utc()
        .format()
        .substring(0, 19) + "Z";

    let to =
      moment(reservationDate + "T" + _.split(selectedTime, "-")[1] + tz.offset)
        .utc()
        .format()
        .substring(0, 19) + "Z";

    return { from: from, to: to };
  }

  generateChunk(selectedTime) {
    let day = moment(this.props.selectedDate).format("dddd");
    let from = _.split(selectedTime, "-")[0];
    let to = _.split(selectedTime, "-")[1];

    let addTime = from;
    let chunks = [];
    chunks.push({
      day: day,
      time: from,
    });
    to = moment(to, "HH:mm").subtract(30, "minutes").format("HH:mm");

    while (addTime !== to) {
      addTime = moment(addTime, "HH:mm").add(30, "minutes").format("HH:mm");

      chunks.push({
        day: day,
        time: addTime,
      });
    }

    return chunks;
  }

  submitRecurringBooking = async (body) => {
    this.props.showHideLoader(true);
    let response = await createRecurring(body);

    if (response) {
      this.props.showHideLoader(false);

      switch (response.status) {
        case 200:
        case 201:
          this.props.showNotification("success", response.data.message);
          this.toggleBookingModal(false);
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
    } else {
      this.props.showNotification(
        "error",
        "Error encountered. Please contact administrator."
      );
      this.toggleBookingModal(false);
      this.props.showHideLoader(false);
    }
  };

  doBooking(bookingParams) {
    const {
      sendNotification,
      first_name,
      last_name,
      email,
      mobile,
      address,
      invoice,
      paid,
      notes,
      selectedBooking,
      origTime,
      time,
      isRecurring,
      frequencyInWeeks,
      paymentMethod,

      totalPrice,
      chargeAmount,
      lessAmount,
      unPaidAmount,

      couponDiscount,
      coupon,
      bookingUuid,
      bookingId,

      statusNotes,
      status,

      locationId,
      locationUuid,
      locationName,

      resourceId,
      resourceUuid,
      resourceName,
      overwriteFee,
      overwriteFeeAmount,
    } = this.state;
    let updateSchedule = this.state.updateSchedule;
    let endDate = this.state.endDate;
    let location = this.state.locationObj;
    let overwriteUpdateSchedule = false;
    let priceData = {
      amount: totalPrice,
      currency: "AUD",
    };

    if (this.state.coupon.length > 0) {
      priceData["couponDiscount"] = couponDiscount;
      priceData["coupon"] = coupon;
    }

    if (chargeAmount > 0) {
      priceData["charge"] = chargeAmount;
      overwriteUpdateSchedule = true;
    }

    if (unPaidAmount > 0) {
      priceData["unpaid"] = unPaidAmount;
    }

    if (lessAmount > 0) {
      priceData["refund"] = lessAmount;
      overwriteUpdateSchedule = true;
    }

    if (overwriteFee && overwriteFeeAmount.length > 0) {
      priceData["overwriteFee"] = overwriteFee;
      priceData["overwriteFeeAmount"] = parseFloat(overwriteFeeAmount);
    }

    if (endDate) {
      moment(endDate).utc().format("YYYY-MM-DD");
    }

    if (origTime != time) {
      updateSchedule = true;
    }

    let participantsData = [];
    _.map(this.state.participants, (participant) => {
      let pt = Object.assign({}, participant);

      if (
        pt !== undefined &&
        pt["checked_in"] !== undefined &&
        pt["checked_in"] &&
        pt["checked_in_time"] != ""
      ) {
        pt["checked_in_time"] =
          moment(pt["checked_in_time"]).utc().format("YYYY-MM-DDTHH:mm:ss") +
          "Z";
      }
      participantsData.push(pt);
    });

    let details = {
      resourceData: {
        organisationId: location.organisation.id,
        bookingData: {
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,

          isRecurring: isRecurring,
          frequencyInWeeks: isRecurring ? frequencyInWeeks : "",
          endDate: endDate,

          chunks: this.generateChunk(this.state.time),
          isPaid: paid,
          invoice: invoice,
          paymentMethod: paymentMethod,
          notes: notes,
          sendNotification: sendNotification,
        },
      },
      priceData: priceData,
      customerData: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        address: address,
      },
      participantsData: participantsData,
    };

    this.props.showHideLoader(true);

    this.setState({
      isUpdate: bookingParams.isUpdate,
      disableSaveButton: true,
    });

    if (bookingParams.isUpdate) {
      details.resourceData.bookingData["bookingId"] = bookingId;
      if (status.length > 0) {
        details.resourceData.bookingData["status"] = status;
      }
      details.resourceData.bookingData["statusNotes"] = statusNotes;

      details.resourceData["locationId"] = locationId;
      details.resourceData["resourceId"] = resourceId;
      details.resourceData["resourceName"] = resourceName;

      details.resourceData.bookingData["updateSchedule"] =
        updateSchedule === true ? updateSchedule : overwriteUpdateSchedule;

      details.resourceData.bookingData["generate_voucher"] =
        bookingParams.generateVoucher;

      if (
        chargeAmount > 0 &&
        bookingParams.doCharge !== undefined &&
        bookingParams.doCharge
      ) {
        details.resourceData.bookingData["isPaid"] = false;
        let sessionToken = "";
        if (typeof this.props.authUser.sessionToken != "undefined") {
          sessionToken = this.props.authUser.sessionToken;
        }
        this.props.requestPayment(details, sessionToken);
      } else {
        if (
          (couponDiscount > 0 && couponDiscount === chargeAmount) ||
          (couponDiscount > 0 && couponDiscount === unPaidAmount)
        ) {
          details.resourceData.bookingData["isPaid"] = true;
        }

        this.props.updateBooking(
          details,
          this.props.authUser.sessionToken,
          bookingUuid
        );
      }
    } else {
      details.resourceData["locationId"] = location.id;
      details.resourceData["resourceId"] = selectedBooking.resourceId;
      details.resourceData["resourceName"] = selectedBooking.court;

      // If recurring send the request to the UBS v2 API
      if (isRecurring && !this.state.isDefaultUser) {
        //for admin only
        this.submitRecurringBooking(details);
      } else {
        this.props.doBooking(details, this.props.authUser.sessionToken);
      }
    }
  }

  resetBookingModal() {
    this.resetBookingStates();
    this.closeModal();
    let setDate = this.state.origSearchDateVal;
    if (
      this.state.origSearchDateVal === undefined ||
      this.state.origSearchDateVal.length === 0
    ) {
      setDate = this.props.selectedDate;
    }
    this.props.selectDate(new Date(setDate));
    this.props.clearBookingSuccessResponse();
    this.props.clearBookingFailedResponse();
    this.props.clearBookingStatusSuccessResponse();
    this.props.clearBookingStatusFailedResponse();
    this.props.clearGenCustomerVoucherSuccessResponse();
    this.props.clearGenCustomerVoucherFailedResponse();
    // this.props.pauseBackgroundRequests(false);
    if (this.intervalId == null) {
      // this.startCalendarRefresh();
    }
    // if (this.state.private) {
    //   this.props.showHideLoader(true);
    //   let params = queryString.parse(this.props.location.search);
    //   if (
    //     this.state.origSearchDateVal !== undefined &&
    //     this.state.origSearchDateVal !== ""
    //   ) {
    //     this.props.getLocationResourcesPrivate(
    //       params.uuid,
    //       {
    //         is_active: 1,
    //         display_online: 1,
    //         date: this.state.origSearchDateVal,
    //       },
    //       this.props.authUser.sessionToken
    //     );
    //   }
    // }
  }

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    let { participants } = this.state;

    if (
      event.target.dataset.type != undefined &&
      event.target.dataset.type == "customer"
    ) {
      let participantIndex = _.findIndex(participants, function (o) {
        return o.id == -1;
      });
      let participant = {};
      if (participantIndex < 0) {
        participant = {
          id: -1,
          is_primary: true,
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
        };
      } else {
        participant = participants[participantIndex];
      }

      if (event.target.name == "email") {
        let result = _.find(participants, function (o) {
          return (
            o.email == event.target.value &&
            o.id != -1 &&
            event.target.value != ""
          );
        });
        this.resetAlertMessage();
        if (result != undefined) {
          alert = {
            title: "Please review the following error: ",
            text: [
              "Participant's email already added. Please enter an unique email",
            ],
            type: "danger",
          };
          this.setState({ alert });
          return;
        }
      }

      participant[event.target.name] = event.target.value;

      if (participantIndex < 0) {
        participants.unshift(participant);
      } else {
        participants[participantIndex] = participant;
      }

      this.setState({
        participants: participants,
      });
    }
  };

  onACheckChange = (evt) => {
    this.setState({ paid: !this.state.paid });
  };

  setDefaultStatus(status) {
    //-- TODO

    status = status === "Checked In" ? "Checked" : status;

    let statusAttr = _.chain(
      this.props.getStatusesSuccessResponse.data.statuses
    )
      .map((stat) => {
        if (stat.name === status) {
          return stat;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    let statusDesc = statusAttr.additional_notes;

    this.setState({ status: status, statusDesc: statusDesc });
  }

  statusChangeHandler = (evt) => {
    let status = evt.target.value;
    let statusAttr = _.chain(
      this.props.getStatusesSuccessResponse.data.statuses
    )
      .map((stat) => {
        if (stat.name === status) {
          return stat;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    let statusDesc = statusAttr.additional_notes;
    let statusChangeConfirmation = [];

    //-- TODO checking issue_voucher

    //-- Charge, issue voucher

    if (statusAttr.is_chargable === 1) {
      statusChangeConfirmation.push(
        <span key="st1">
          <strong>Charge:</strong> Customer will not be able to refund the
          booking fee and the booking will be cancelled.
          <br />
          <br />
        </span>
      );
    }
    if (statusAttr.is_overrideble === 1) {
      statusChangeConfirmation.push(
        <span key="st2">
          <strong>Issue Voucher:</strong> Credit Voucher with the amount of $
          {this.state.totalPrice} will be issued to customer and the booking
          will be cancelled.
          <br />
          <br />
        </span>
      );
    }
    if (statusAttr.issue_voucher === 1) {
      statusChangeConfirmation.push(
        <span key="st3">
          <strong>Issue Voucher:</strong> Credit Voucher with the amount of $
          {this.state.totalPrice} will be issued to customer and the booking
          will be cancelled.
          <br />
          <br />
        </span>
      );
    }

    // if (status === "Checked in") {
    //   statusDesc = "The Visitor/User arrived and used the resource";
    //   statusChangeConfirmation = "";
    // } else if (status === "Unavoidable Circumstances") {
    //   statusDesc = "Visitor/User Retains the booking fee for use another time";
    //   statusChangeConfirmation = `Do you wish to issue a Credit Voucher with amount of $${this.state.totalPrice} to this customer?
    //   Customer will receive an email containing the credit voucher.`;
    // } else if (status === "No Show") {
    //   statusDesc = "Visitor/User looses the booking Fee";
    //   statusChangeConfirmation = `Customer will not be able to refund the booking fee and the booking will be cancelled.
    //   Do you wish to continue changing the status?`;
    // } else if (status === "Cancellation") {
    //   statusDesc = "Visitor/User Retains the booking fee for use another time";
    //   statusChangeConfirmation = `Do you wish to issue a Credit Voucher with amount of $${this.state.totalPrice} to this customer?
    //   Customer will receive an email containing the credit voucher.`;
    // } else if (status === "Late Cancellation") {
    //   statusDesc = "Visitor/User looses the booking Fee";
    //   statusChangeConfirmation = `Customer will not be able to refund the booking fee and the booking will be cancelled.
    //   Do you wish to continue changing the status?`;
    // }

    this.setState({
      status: status,
      statusDesc: statusDesc,
      statusChangeConfirmation: statusChangeConfirmation,

      isChargable: statusAttr.is_chargable,
      issueVoucher: statusAttr.issue_voucher,
      isOverrideble: statusAttr.is_overrideble,
    });
  };

  onRecurringCheckChange = (evt) => {
    this.setState({
      isRecurring: !this.state.isRecurring,
      frequencyInWeeks: this.state.isRecurring === false ? "Weekly" : "",
      endDate: "",
    });
  };

  onOverwritePriceCheckChange = (evt) => {
    this.setState({
      overwriteFee: !this.state.overwriteFee,
    });
  };

  checkMoreThanMaxDurationOnChange = (evt) => {
    this.setState({
      checkMoreThanMaxDuration: !this.state.checkMoreThanMaxDuration,
    });
  };

  RecurringBookingOption() {
    let { endDate } = this.state;

    if (this.state.isRecurring) {
      let enddateAddDays =
        this.state.recurringFrequencies[this.state.frequencyInWeeks];

      return (
        <MDBCol md="6">
          <div>
            <label className="grey-text mt-4">Frequency in Weeks</label>
            <select
              className="browser-default custom-select"
              name="frequencyInWeeks"
              defaultValue={this.state.frequencyInWeeks}
              onChange={this.changeHandler}
            >
              <option value="Weekly">Weekly</option>
              <option value="Fortnightly">Fortnightly</option>
              <option value="Monthly">Monthly</option>
            </select>
          </div>
          <div>
            <label className="grey-text mt-4">End Date</label>
            <br></br>
            <DatePicker
              className="custom-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={this.endDateChangeHandler}
              dateFormat="dd/MM/yyyy"
              name="endDate"
              filterDate={this.isWeekday}
              isClearable={true}
            />
          </div>
        </MDBCol>
      );
    }
    return <span></span>;
  }

  endDateChangeHandler = (date) => {
    this.setState({
      endDate: date,
    });
  };

  isWeekday = (date) => {
    let enddateAddDays =
      this.state.recurringFrequencies[this.state.frequencyInWeeks];
    if (this.state.isRecurring) {
      var selectedDate = this.props.selectedDate;
      var a = moment(selectedDate);
      var b = moment(date);

      if (a.format("YYYY-MM-DD") >= b.format("YYYY-MM-DD")) {
        return false;
      }

      if (
        this.cachePrevDate != "" &&
        typeof this.cachePrevDate !== "undefined"
      ) {
        selectedDate = this.cachePrevDate;
      }

      var a = moment(selectedDate);
      let daysDiff = a.diff(date, "days") - 1;
      let aDay = a.format("dddd");
      let bDay = b.format("dddd");
      return date >= a && daysDiff % enddateAddDays == 0;
    }
  };

  isInvalidCoupon() {
    if (this.state.couponErrorMsg.length > 0) {
      return (
        <span style={{ color: "red", fontWeight: "bold" }}>
          {this.state.couponErrorMsg}
        </span>
      );
    }
    return <span></span>;
  }
  onCouponChange = (evt) => {
    this.setState({
      coupon: evt.target.value,
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,
    });
  };

  getUserType() {
    let isPrivateBooking = this.state.private;
    let isDefaultUser = this.state.isDefaultUser;
    let userType = "public_user";
    if (isPrivateBooking && isDefaultUser) {
      userType = "member_user";
    } else if (isPrivateBooking && !isDefaultUser) {
      userType = "admin_user";
    }
    return userType;
  }

  buildResourceDataObj() {
    const { updateSchedule, locationObj } = this.state;

    let locationId = "";
    let resourceName = "";
    let resourceId = "";
    let organisationId = "";
    let location = {};

    let userType = this.getUserType();
    if (userType == "public_user") {
      //public booking
      let bookingDetails = this.props.bookingDetails;
      locationId = bookingDetails.locationId;
      resourceName = bookingDetails.resource;
      resourceId = bookingDetails.resourceId;
      organisationId = bookingDetails.organisationId;
    } else {
      if (this.state.showBookingDetailsModal) {
        // Private existiing booking
        locationId = this.state.selectedBookingDetails.location.id;
        organisationId =
          this.state.selectedBookingDetails.location.organisation.id;
        resourceName = this.state.selectedBookingDetails.resource.name;
        resourceId = this.state.selectedBookingDetails.resource.id;
      } else {
        // Private non existing booking
        location = locationObj;

        locationId = location ? location.id : 0;
        organisationId = location ? location.organisation.id : 0;
        resourceId = this.state.selectedBooking.resourceId;
        resourceName = this.state.selectedBooking.court;
      }
    }

    let resourceData = {
      organisationId: organisationId,
      locationId: locationId,
      resourceId: resourceId,
      resourceName: resourceName,
      bookingData: {
        updateSchedule: updateSchedule,
        from: this.validateSelectedDuration().from,
        to: this.validateSelectedDuration().to,
        chunks: this.generateChunk(this.state.time),
      },
    };
    return resourceData;
  }

  calculatePrice() {
    const {
      first_name,
      last_name,
      email,
      chargeAmount,
      unPaidAmount,
      participants,
      overwriteFee,
      overwriteFeeAmount,
    } = this.state;

    this.props.showHideLoader(true);
    let couponObj = {};
    if (this.state.coupon.length > 0) {
      couponObj["coupon"] = this.state.coupon;
    }

    if (chargeAmount > 0) {
      couponObj["charge"] = chargeAmount;
    }

    if (unPaidAmount > 0) {
      couponObj["unpaid"] = unPaidAmount;
    }

    if (overwriteFee && overwriteFeeAmount.length > 0) {
      couponObj["overwriteFee"] = overwriteFee;
      couponObj["overwriteFeeAmount"] = parseFloat(overwriteFeeAmount);
    }

    let participantsData = [];
    _.map(participants, (participant) => {
      let pt = Object.assign({}, participant);

      if (
        pt !== undefined &&
        pt["checked_in"] !== undefined &&
        pt["checked_in"] &&
        pt["checked_in_time"] != ""
      ) {
        pt["checked_in_time"] =
          moment(pt["checked_in_time"]).utc().format("YYYY-MM-DDTHH:mm:ss") +
          "Z";
      }
      participantsData.push(pt);
    });
    let resourceData = this.buildResourceDataObj();

    let details = {
      resourceData,
      customerData: {
        first_name: first_name,
        last_name: last_name,
        email: email,
      },
      priceData: couponObj,
      participantsData: participantsData,
    };

    this.setState({
      locked: details,
    });

    let bookingId = "";

    if (this.state.showBookingDetailsModal) {
      bookingId = this.state.selectedBookingDetails.id;
    }
    if (bookingId > 0) {
      details.resourceData.bookingData["bookingId"] = bookingId;
    }
    let requestObj = {};
    requestObj["organisationId"] = resourceData.organisationId;
    requestObj["locationId"] = resourceData.locationId;
    requestObj["resourceId"] = resourceData.resourceId;
    requestObj["from"] = resourceData.bookingData.from;
    requestObj["to"] = resourceData.bookingData.to;
    requestObj["chunks"] = resourceData.bookingData.chunks;
    // Send initial Request to lock the timeslot to check if there is conflict
    this.props
      .lockBookingTimeslot(requestObj, this.props.authUser.sessionToken)
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data.isConflictTimeslot) {
            // Do not continue on calculating price if there is conflict
            this.props.showNotification(
              "error",
              "Selected slot already taken by other user.",
              60000
            );
            this.toggleBookingModal(false);
            return;
          } else {
            this.intervalLockBookingtimeslot();
          }
        }
      });

    // if (websocketApi.isOpen) {
    //   websocketApi.send(JSON.stringify({ ...details, action: "lock" }));
    // }

    if (this.state.showBookingDetailsModal) {
      //Calculate only price if its not Booking details modal is not shown.
      //This will be improved soon when Booking editor and weekly calender were merged
      return;
    }

    if (this.state.private) {
      this.props.calculatePrice(details, this.props.authUser.sessionToken);
    } else {
      this.props.calculatePrice(details);
    }
  }

  debouncedValidateAddedCustomer = _.debounce(function () {
    this.doValidateAddedCustomer();
  }, 500);

  intervalLockBookingtimeslot() {
    //Continously lock the selected time slot
    let resourceData = this.buildResourceDataObj();
    let requestObj = {};
    requestObj["organisationId"] = resourceData.organisationId;
    requestObj["locationId"] = resourceData.locationId;
    requestObj["resourceId"] = resourceData.resourceId;
    requestObj["from"] = resourceData.bookingData.from;
    requestObj["to"] = resourceData.bookingData.to;
    requestObj["chunks"] = resourceData.bookingData.chunks;

    // clear the old interval then send another new time slot locking
    if (this.state.lockingTimeSlotIntervalId !== "") {
      clearInterval(this.state.lockingTimeSlotIntervalId);
    }

    // This is for continuous locking of selected timeslot while on the booking modal every 5 seconds
    let lockingTimeSlotIntervalId = setInterval(() => {
      this.props.lockBookingTimeslot(
        requestObj,
        this.props.authUser.sessionToken
      );
    }, 5000);

    this.setState({ lockingTimeSlotIntervalId: lockingTimeSlotIntervalId });
    return;
  }

  doValidateAddedCustomer() {
    const { selectedCustomerOrParticipant, isMainCustomer } = this.state;
    const resourceData = this.buildResourceDataObj();

    if (selectedCustomerOrParticipant) {
      this.props.showHideLoader(true);

      if (this.state.private) {
        this.props.validateAddedCustomer(
          {
            resourceData: resourceData,
            participantData: selectedCustomerOrParticipant,
            isMainCustomer,
          },
          this.props.authUser.sessionToken
        );
      } else {
        this.props.validateAddedCustomer({
          resourceData: resourceData,
          participantData: selectedCustomerOrParticipant,
          isMainCustomer,
        });
      }
    }
  }

  getOriginalPrice(selectedBookingDetails) {
    let couponObj = {};
    let location = this.state.locationObj;
    let locationId = selectedBookingDetails.location.id;
    let resourceName = selectedBookingDetails.resource.name;
    let resourceId = selectedBookingDetails.resource.id;

    let details = {
      resourceData: {
        organisationId: location.organisation.id,
        locationId: locationId,
        resourceId: resourceId,
        resourceName: resourceName,
        bookingData: {
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,
          chunks: this.generateChunk(this.state.time),
        },
      },

      priceData: couponObj,
    };

    this.setState({ doneGettingOriginalPrice: false });
    if (this.state.private) {
      this.props.calculatePrice(details, this.props.authUser.sessionToken);
    } else {
      this.props.calculatePrice(details);
    }
  }

  debouncedCalculatePrice = _.debounce(function () {
    this.calculatePrice();
  }, 10);

  getCustomerResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      let resultContainer = [];
      if (this.props.customerResponse.data !== undefined) {
        if (this.props.customerResponse.type === "GET_CUSTOMERS") {
          resultContainer = _.chain(this.props.customerResponse.data.results)
            .orderBy(["first_name"], ["asc"])
            .value();
        }
      }

      this.setState({
        customerResultContainer: resultContainer,
        isFavouriteListSearch: false,
      });
    }
  }

  validateCustomerDetailsResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      if (this.props.customerResponse.data !== undefined) {
        let res = this.props.customerResponse;
        if (res.type === "VALIDATE_CUSTOMER_DETAILS") {
          if (!this.state.isDefaultUser) {
            if (res.status === 200) {
              if (res.data.message !== "Record found") {
                const modal = {
                  isOpen: true,
                  content: res.data.message,
                  title: "Participant's Warning",
                  negativeButtonText: "",
                  positiveButtonText: "Close",
                  positiveButtonColor: "primary",
                  modalSize: "sm",
                  positiveButtonAction: this.closeModal.bind(this),
                };
                this.props.toggleModal(modal);
              }
            }
          }
        }
      }
    }
  }

  debouncedSearchCustomer = _.debounce(function (val) {
    let params = { limit: 10, query: val };
    const { isDefaultUser, locationObj } = this.state;
    if (isDefaultUser) {
      params["organisationId"] = locationObj.organisation.id;
    }
    if (val != "") {
      this.props.getCustomers(params, this.props.authUser.sessionToken);
    } else {
      this.props.getUserFavourites({}, this.props.authUser.sessionToken);
    }
  }, 10);

  addValidatedMainCustomer() {
    const { selectedCustomerOrParticipant, isLocationPerMembership } =
      this.state;
    let { participants } = this.state;

    let participant = {
      first_name: selectedCustomerOrParticipant.first_name,
      last_name: selectedCustomerOrParticipant.last_name,
      email: selectedCustomerOrParticipant.email,
      mobile: selectedCustomerOrParticipant.mobile,
      address: selectedCustomerOrParticipant.address,
      is_primary: true,
      id: -1,
    };
    if (participant) {
      let participantIndex = _.findIndex(participants, function (o) {
        return o.id == -1;
      });
      if (participantIndex >= 0) {
        participants[participantIndex] = participant;
      } else {
        participants.unshift(participant);
      }
    }

    this.setState({
      customerId: selectedCustomerOrParticipant.id,
      customerResultContainer: [selectedCustomerOrParticipant],
      first_name: selectedCustomerOrParticipant.first_name,
      last_name: selectedCustomerOrParticipant.last_name,
      email: selectedCustomerOrParticipant.email,
      mobile: selectedCustomerOrParticipant.mobile,
      address: selectedCustomerOrParticipant.address,
      participants: participants,
      triggerPricing: true,
    });

    if (isLocationPerMembership) {
      this.props.showHideLoader(true);
    }
  }

  renderCustomerAutoComplete() {
    let { participants } = this.state;
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete">Search customer</label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.value}
          items={this.state.customerResultContainer}
          getItemValue={(item) => {
            let label =
              item.first_name + " " + item.last_name + ", " + item.email;
            if (item.membership) {
              label +=
                " - " +
                (item.membership.number ? item.membership.number : "N/A") +
                " - " +
                item.membership.name +
                " " +
                item.membership.type.name;
            }
            return label;
          }}
          onSelect={(value, item) => {
            let result = _.find(participants, function (o) {
              return o.email == item.email && o.id != -1 && item.email != "";
            });
            this.resetAlertMessage();

            if (result != undefined) {
              alert = {
                title: "Please review the following error: ",
                text: [
                  "Participant's email already added. Please enter an unique email",
                ],
                type: "danger",
              };
              this.setState({ alert });
              return;
            }

            this.setState({
              value,
              isMainCustomer: true,
              selectedCustomerOrParticipant: item,
              isValidateCustomerOrParticipant: true, // Component did update will be triggered when this is changed
            });
          }}
          onChange={(event, value) => {
            clearTimeout(this.state.timer);
            let timer = setTimeout(() => {
              this.debouncedSearchCustomer(value);
            }, 500);
            this.setState({
              ...this.state,
              timer: timer,
              value,
              customerId: "",
            });
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.first_name + " " + item.last_name + ", " + item.email}
              {item.membership
                ? " - " +
                  (item.membership.number ? item.membership.number : "N/A") +
                  " - " +
                  item.membership.name +
                  " " +
                  item.membership.type.name
                : ""}
            </div>
          )}
        />
      </div>
    );
  }

  addValidatedParticipant() {
    const { selectedCustomerOrParticipant, isLocationPerMembership } =
      this.state;
    let participantIndex = _.findIndex(this.state.participants, function (o) {
      return o.email == selectedCustomerOrParticipant.email;
    });

    let participant = {
      id: selectedCustomerOrParticipant.id,
      first_name: selectedCustomerOrParticipant.first_name,
      last_name: selectedCustomerOrParticipant.last_name,
      email: selectedCustomerOrParticipant.email,
      mobile: selectedCustomerOrParticipant.mobile,
      address: selectedCustomerOrParticipant.address,
    };

    if (participantIndex < 0) {
      this.state.participants.push(participant);
    } else {
      this.state.participants[participantIndex] = participant;
    }

    if (isLocationPerMembership) {
      this.calculatePrice();
    }
  }
  renderParticipantAutoComplete() {
    const { isDefaultUser } = this.state;

    const handleOnFocus = () => {
      if (this.searchParticipantsRef.current) {
        setTimeout(() => {
          this.searchParticipantsRef.current.scrollIntoView();
        }, 300);
      }
    };

    return (
      <div className="autoComplete">
        <label
          ref={this.searchParticipantsRef}
          htmlFor="participant-autocomplete"
        >
          Search Participant
        </label>
        <Autocomplete
          autoComplete="off"
          inputProps={{
            id: "participant-autocomplete",
            onFocus: handleOnFocus,
          }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          items={this.state.customerResultContainer}
          value={this.state.participantValue}
          getItemValue={() => this.state.participantValue} // Value of the search bar on select
          onSelect={(participantValue, item) => {
            let result = _.find(this.state.participants, function (o) {
              return o.email == item.email;
            });

            this.resetAlertMessage();
            if (result != undefined) {
              alert = {
                title: "Please review the following error: ",
                text: [
                  "Participant's email already added. Please enter an unique email",
                ],
                type: "danger",
                clearAfter: true,
              };
              this.setState({ alert });
              document.querySelector(".modal.show").scrollTo(0, 0);
              return;
            }

            this.setState({
              participantValue,
              isMainCustomer: false,
              selectedCustomerOrParticipant: item,
              isValidateCustomerOrParticipant: true, // Component did update will be triggered when this is changed
            });
          }}
          onChange={(event, participantValue) => {
            clearTimeout(this.state.timer);
            let timer = setTimeout(() => {
              this.debouncedSearchCustomer(participantValue);
            }, 500);
            this.setState({
              ...this.state,
              participantValue,
              timer: timer,
            });
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => {
            if (
              this.state.isDefaultUser &&
              this.state.isFavouriteListSearch &&
              this.state.userFavouritesEmail.includes(item.email.toLowerCase())
            ) {
              let favouriteCustomer =
                this.state.userFavourites[item.email.toLowerCase()]["display"];
              return (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.id}
                >
                  {favouriteCustomer}
                  {this.state.isDefaultUser && (
                    <MDBIcon className="green-text" icon="star" />
                  )}
                </div>
              );
            } else {
              return (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.id}
                >
                  {item.first_name + " " + item.last_name}

                  {!isDefaultUser ? ", " + item.email : ""}

                  {item.membership
                    ? !isDefaultUser
                      ? " - " +
                        (item.membership.number
                          ? item.membership.number
                          : "N/A") +
                        " - " +
                        item.membership.name +
                        " " +
                        item.membership.type.name
                      : " - " +
                        (item.membership.number
                          ? item.membership.number
                          : "N/A")
                    : ""}
                </div>
              );
            }
          }}
        />
      </div>
    );
  }

  toggle = () => {
    this.setState({
      showBookingModal: !this.state.showBookingModal,
    });
    this.resetBookingStates();
    this.props.pauseBackgroundRequests(false);
    this.getDefaultCustomer();
  };

  resetAlertMessage() {
    alert = {
      title: "",
      text: "",
      type: "",
      clearAfter: false,
    };
    this.setState({ alert });
  }

  showAlert() {
    if (this.state.alert.text !== "") {
      return (
        <MDBAlert color={`${this.state.alert.type}`}>
          <strong>{this.state.alert.title}</strong>
          <br />
          <ul className="text-left">
            {this.state.alert.text.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </MDBAlert>
      );
    }
  }

  tempReadonlyModal = () => {
    let contentElem = [];
    const { selectedBookingDetails, locationObj } = this.state;
    // console.log("CHECK DETAILS>>>>>>>>");
    // console.log(locationObj);
    contentElem.push(
      <MDBRow>
        <MDBCol md="12" className="text-center">
          For more information about this booking please contact{" "}
          {locationObj.name}, {locationObj.attributes.contact_number}, or{" "}
          {locationObj.attributes.contact_email}.
        </MDBCol>
      </MDBRow>
    );

    const modal = {
      isOpen: true,
      content: contentElem,
      title: "Booking Form",
      negativeButtonText: "",
      positiveButtonText: "Close",
      positiveButtonColor: "grey",
      modalSize: "lg",
      positiveButtonAction: this.closeModal.bind(this),
    };
    this.props.toggleModal(modal);
  };

  renderBookingModalForm() {
    const {
      showBookingModal,
      selectedBooking,
      first_name,
      last_name,
      email,
      mobile,
      address,
      searchDateVal,
      invoice,
      paid,
      notes,
      time,
      paymentMethod,
      coupon,
      getAvailableSlot,
      isDefaultUser,
      readOnlyEmail,
      defaultCustomer,
      overwriteFee,
      overwriteFeeAmount,
      sendNotification,
    } = this.state;
    const { storeUserAccess } = this.props;
    if (showBookingModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    if (selectedBooking !== undefined && showBookingModal) {
      let allowCustomerSearch =
        validateAccess(
          storeUserAccess,
          "BookingDetails",
          "allowCustomerSearch"
        ) && !isDefaultUser;

      return (
        <div>
          <MDBModal
            ref={this.modal}
            isOpen={showBookingModal}
            size="lg"
            toggle={this.toggle}
          >
            <form
              className={`needs-validation ${this.state.wasValidated}`}
              onSubmit={this.submitHandler}
              noValidate
            >
              <MDBModalHeader>Details</MDBModalHeader>
              <MDBModalBody>
                {this.showAlert()}
                <SectionContainer>
                  <h5>Customer Details</h5>

                  {allowCustomerSearch && this.renderCustomerAutoComplete()}
                  <MDBRow>
                    <MDBCol md="6" className="mb-3">
                      <label
                        htmlFor="idFirstname"
                        className="grey-text required"
                      >
                        First name
                      </label>
                      <input
                        data-type="customer"
                        value={first_name}
                        name="first_name"
                        onChange={this.changeHandler}
                        type="text"
                        id="idFirstname"
                        className="form-control"
                        required
                        readOnly={
                          isDefaultUser &&
                          !_.isEmpty(defaultCustomer.first_name)
                        }
                      />
                      <div className="invalid-feedback">
                        Please provide the first name
                      </div>
                    </MDBCol>
                    <MDBCol md="6" className="mb-3">
                      <label
                        htmlFor="idLastname"
                        className="grey-text required"
                      >
                        Last name
                      </label>
                      <input
                        data-type="customer"
                        value={last_name}
                        name="last_name"
                        onChange={this.changeHandler}
                        type="text"
                        id="idLastname"
                        className="form-control"
                        required
                        readOnly={
                          isDefaultUser && !_.isEmpty(defaultCustomer.last_name)
                        }
                      />
                      <div className="invalid-feedback">
                        Please provide the last name
                      </div>
                    </MDBCol>
                    <MDBCol md="6" className="mb-3">
                      <label htmlFor="idEmail" className="grey-text required">
                        Email
                      </label>
                      <input
                        data-type="customer"
                        value={email}
                        name="email"
                        onChange={this.changeHandler}
                        type="email"
                        id="idEmail"
                        className="form-control"
                        required
                        readOnly={isDefaultUser && readOnlyEmail}
                      />
                      <div className="invalid-feedback">
                        Please provide the email
                      </div>
                    </MDBCol>
                    <MDBCol md="6" className="mb-3">
                      <label
                        htmlFor="idContactNumber"
                        className="grey-text required"
                      >
                        Contact number
                      </label>
                      <input
                        data-type="customer"
                        value={mobile}
                        name="mobile"
                        onChange={this.changeHandler}
                        type="text"
                        id="idContactNumber"
                        className="form-control"
                        required
                        pattern="^[0-9]{10,15}$"
                        title="Please enter a valid contact number."
                        readOnly={
                          isDefaultUser && !_.isEmpty(defaultCustomer.mobile)
                        }
                      />
                      <div className="invalid-feedback">
                        Please provide a valid contact number
                      </div>
                    </MDBCol>
                    <MDBCol md="12" className="mb-3">
                      <label
                        ref={this.addressRef}
                        htmlFor="idAddress"
                        className="grey-text"
                      >
                        Address
                      </label>
                      <input
                        data-type="customer"
                        value={address}
                        name="address"
                        onChange={this.changeHandler}
                        type="text"
                        id="idAddress"
                        className="form-control"
                        readOnly={
                          isDefaultUser && !_.isEmpty(defaultCustomer.address)
                        }
                        onFocus={(event) => {
                          if (this.addressRef.current) {
                            setTimeout(() => {
                              this.addressRef.current.scrollIntoView();
                            }, 300);
                          }
                        }}
                      />
                      <div className="invalid-feedback">
                        Please provide the contact number
                      </div>
                    </MDBCol>
                  </MDBRow>
                </SectionContainer>
                <SectionContainer>
                  <h5>Booking Details</h5>
                  <MDBRow>
                    <MDBCol md="12">
                      <MDBInput
                        label="Location"
                        disabled
                        value={this.state.locationObj.name}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Resource"
                        disabled
                        value={selectedBooking.court}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Reservation date"
                        disabled
                        value={moment(searchDateVal, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Time"
                        disabled
                        value={time}
                        style={{ fontWeight: "bold" }}
                      />
                    </MDBCol>
                    {this.renderAvailableSlot(getAvailableSlot, 30)}
                    {validateAccess(
                      storeUserAccess,
                      "BookingDetails",
                      "manualPaid"
                    ) && (
                      <MDBCol md="1">
                        <div className="custom-control custom-checkbox mt-4">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="defaultUnchecked"
                            onChange={this.onACheckChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="defaultUnchecked"
                            style={{
                              fontWeight: "bold",
                              marginTop: "35px !important",
                            }}
                          >
                            Paid
                          </label>
                        </div>
                      </MDBCol>
                    )}
                    {validateAccess(
                      storeUserAccess,
                      "BookingDetails",
                      "manualPaid"
                    ) && (
                      <MDBCol md="5">
                        <label htmlFor="idInvoice" className="grey-text">
                          Invoice
                        </label>
                        <input
                          value={invoice}
                          name="invoice"
                          onChange={this.changeHandler}
                          type="text"
                          id="idInvoice"
                          className="form-control"
                        />
                        <div className="invalid-feedback">
                          Please provide the contact number
                        </div>
                      </MDBCol>
                    )}
                    {validateAccess(
                      storeUserAccess,
                      "BookingDetails",
                      "allowRecurringBooking"
                    ) && (
                      <MDBCol md="6">
                        <div className="custom-control custom-checkbox mt-4">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="isRecurring"
                            onChange={this.onRecurringCheckChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isRecurring"
                            style={{
                              fontWeight: "bold",
                              marginTop: "35px !important",
                            }}
                          >
                            Recurring Booking?
                          </label>
                        </div>
                      </MDBCol>
                    )}
                    {!isDefaultUser && (
                      <MDBCol md="6">
                        <label className="grey-text mt-4">Payment method</label>
                        <select
                          className="browser-default custom-select"
                          name="paymentMethod"
                          onChange={this.changeHandler}
                        >
                          {validateAccess(
                            storeUserAccess,
                            "BookingDetails",
                            "paymentOptionCreditCard"
                          ) && <option value="Credit Card">Credit Card</option>}
                          {/* {validateAccess(
                            storeUserAccess,
                            "BookingDetails",
                            "paymentOptionVoucher"
                          ) && <option value="Voucher">Voucher</option>} */}
                          {validateAccess(
                            storeUserAccess,
                            "BookingDetails",
                            "paymentOptionCash"
                          ) && <option value="Cash">Cash</option>}
                        </select>
                        {paymentMethod === "Voucher" ? (
                          <div>
                            <label
                              htmlFor="idCoupon"
                              className="grey-text mt-4"
                            >
                              Discount Code
                            </label>
                            <input
                              value={coupon}
                              name="coupon"
                              onChange={this.onCouponChange}
                              type="text"
                              id="idCoupon"
                              className="form-control"
                            />
                            {this.isInvalidCoupon()}
                          </div>
                        ) : (
                          <MDBCol md="6"></MDBCol>
                        )}
                      </MDBCol>
                    )}

                    {this.RecurringBookingOption()}
                    {validateAccess(
                      storeUserAccess,
                      "AdminBooking",
                      "allowManualChangeBookingFeeTodo"
                    ) && (
                      <MDBCol md="6">
                        <div className="custom-control custom-checkbox mt-4">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="overwriteFee"
                            onChange={this.onOverwritePriceCheckChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="overwriteFee"
                            style={{
                              fontWeight: "bold",
                              marginTop: "35px !important",
                            }}
                          >
                            Overwrite price?
                          </label>
                        </div>
                        {overwriteFee && (
                          <div>
                            <label
                              htmlFor="idOverwriteFeeAmount"
                              className="grey-text mt-3"
                            >
                              New price
                            </label>
                            <input
                              value={overwriteFeeAmount}
                              name="overwriteFeeAmount"
                              onChange={this.changeHandler}
                              type="number"
                              id="idOverwriteFeeAmount"
                              className="form-control"
                            />
                          </div>
                        )}
                      </MDBCol>
                    )}
                    {!isDefaultUser && (
                      <MDBCol md="12">
                        <label htmlFor="idNotes" className="grey-text mt-3">
                          Notes
                        </label>
                        <input
                          value={notes}
                          name="notes"
                          onChange={this.changeHandler}
                          type="text"
                          id="idNotes"
                          className="form-control"
                        />
                      </MDBCol>
                    )}

                    {this.renderDiscountSection(coupon)}
                    <MDBCol md="12">
                      <h5 className="mt-3">
                        <strong className="float-right">
                          Total price: ${this.state.totalPrice}
                        </strong>
                      </h5>
                    </MDBCol>
                  </MDBRow>
                </SectionContainer>
                <SectionContainer>
                  <h5>Booking Participants</h5>
                  <MDBRow>
                    <MDBCol className="col-sm-12 col-md-12">
                      <h5>Participants</h5>
                      <hr></hr>
                      {this.renderParticipantAutoComplete()}
                      <br></br>
                      {this.renderBookingParticipants()}
                    </MDBCol>
                  </MDBRow>
                </SectionContainer>
                {this.renderMaxDurationExceedAcknowledge()}
                {!this.state.isDefaultUser && (
                  <>
                    <MDBRow>
                      <MDBCol>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="sendNotification"
                            defaultChecked={sendNotification}
                            checked={sendNotification}
                            onChange={this.onSendNotificationChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="sendNotification"
                            style={{
                              fontWeight: "bold",
                              marginTop: "35px !important",
                            }}
                          >
                            Send customer notification?
                          </label>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
                {this.state.isDefaultUser && (
                  <>
                    <MDBRow>
                      <MDBCol size="12">
                        <div className="custom-control custom-checkbox mb-3 text-center">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="defaultUncheckedTerms"
                            onChange={this.onChangeTermsAndConditionCheckbox}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="defaultUncheckedTerms"
                          >
                            I agree to the Terms and Conditions
                          </label>
                        </div>
                        <div
                          className="text-center mb-3 modalTCLink"
                          onClick={() => this.showHideTC()}
                        >
                          {!this.state.showHideTC
                            ? "Read the Terms and Conditions"
                            : "Read Less"}
                        </div>
                      </MDBCol>
                      <MDBCol size="12" className="mb-3">
                        {this.state.showHideTC && (
                          <div>{this.renderTermsAndConditions()}</div>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <SectionContainer noBorder={true} noBottom>
                  {this.state.isDefaultUser && (
                    <MDBRow>
                      <MDBCol
                        md="12"
                        className=" custom-control custom-checkbox text-right"
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="reviewSelectedDateCheckbox"
                          onChange={(evt) => {
                            this.setState({
                              isCorrectSelectedDate:
                                !this.state.isCorrectSelectedDate,
                            });
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="reviewSelectedDateCheckbox"
                        >
                          Confirm selected date and time:
                        </label>
                      </MDBCol>
                      <MDBCol md="12" className="text-right">
                        <label htmlFor="reviewSelectedDateCheckbox">
                          <strong>{`${moment(
                            searchDateVal,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")} ${this.state.time}`}</strong>
                        </label>
                      </MDBCol>
                    </MDBRow>
                  )}
                  <MDBRow>
                    <MDBCol md="12 text-right">
                      <MDBBtn
                        color="secondary"
                        onClick={() => this.toggleBookingModal(false)}
                      >
                        Close
                      </MDBBtn>

                      {/* make sure to check first if agree to terms and condition and reviewed selected date if default user */}
                      <MDBBtn
                        color="primary"
                        type="submit"
                        disabled={
                          !(
                            (this.state.iAggree &&
                              this.state.isCorrectSelectedDate) ||
                            !this.state.isDefaultUser
                          )
                        }
                      >
                        Book
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </SectionContainer>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      );
    }
    return <span></span>;
  }

  allowUpdatingParticipant(e) {
    if (
      (typeof e.row.data != "undefined" &&
        typeof e.row.data.is_primary != "undefined") ||
      e.row.data.is_primary
    ) {
      let editableFields = ["checked_in_time"];
      e.row.cells.forEach((column, index) => {
        e.row.cells[index].column.isEditable = editableFields.includes(
          column.column.dataField
        );
      });
    }
    return !this.state.isDefaultUser;
  }

  allowDeletingParticipant(e) {
    return (
      typeof e.row.data.is_primary == "undefined" || !e.row.data.is_primary
    );
  }

  allowRemovingFavouriteContact(e) {
    // check if  the user is default user and not primary user
    return (
      (typeof e.row.data.is_primary == "undefined" || !e.row.data.is_primary) &&
      this.state.isDefaultUser &&
      isNaN(e.row.data.id) &&
      this.state.userFavouritesEmail.includes(e.row.data.email.toLowerCase()) // checking here if it is added as favourite contact
    );
  }

  allowAddingFavouriteContact(e) {
    // check if  the user is default user and not primary user
    return (
      (typeof e.row.data.is_primary == "undefined" || !e.row.data.is_primary) &&
      this.state.isDefaultUser &&
      isNaN(e.row.data.id) &&
      !this.state.userFavouritesEmail.includes(e.row.data.email.toLowerCase()) // checking here if it is not added as favourite contact
    );
  }

  renderGridCell(data) {
    if (!isNaN(data.data.id)) {
      return "";
    } else {
      return data.value;
    }
  }

  renderBookingParticipants() {
    const { isDefaultUser } = this.state;
    let columns = [
      {
        fieldName: "first_name",
        displayName: "First Name",
        isEditable: true,
        dataType: "",
        isRequired: true,
      },
      {
        fieldName: "last_name",
        displayName: "Last Name",
        isEditable: true,
        dataType: "",
        isRequired: true,
      },
      {
        fieldName: "mobile",
        displayName: "Mobile",
        isEditable: true,
        dataType: "text",
        isRequired: true,
        pattern: "^[0-9]{10,15}$",
        patternErrorMessage: "Please enter a valid contact number",
        renderGridCell: isDefaultUser ? this.renderGridCell : "",
      },
      {
        fieldName: "email",
        displayName: "Email",
        isEditable: true,
        dataType: "email",
        isRequired: true,
        renderGridCell: isDefaultUser ? this.renderGridCell : "",
        validationCallback: this.participantUniqueValidation,
        validationCallbackMessage:
          "Participant's email already added. Please enter an unique email.",
      },
    ];

    if (!isDefaultUser) {
      columns.push(
        {
          fieldName: "checked_in",
          displayName: "Checked In",
          isEditable: true,
          sourceName: "value",
          sourceId: "id",
          sources: [
            {
              id: 0,
              value: "No",
            },
            {
              id: 1,
              value: "Yes",
            },
          ],
        },
        {
          fieldName: "checked_in_time",
          displayName: "Checked In Timestamp",
          isEditable: false,
        }
      );
    }

    let rows = _.uniqWith(Object.values(this.state.participants), _.isEqual);
    let canAdd =
      rows.length < this.maxBookingParticipants ||
      this.maxBookingParticipants == -1;
    return (
      <CustomDataGrid
        key={this.state.showBookingModal}
        source={rows}
        columns={columns}
        rowValidating={this.rowValidating}
        allowDeleting={this.allowDeletingParticipant}
        allowUpdating={this.allowUpdatingParticipant}
        allowAddingFavourite={this.allowAddingFavouriteContact}
        allowRemovingFavourite={this.allowRemovingFavouriteContact}
        canAdd={canAdd}
        actions={["edit", "delete", "addfavourite", "removefavourite"]}
        editMode={"form"}
        confirmDeleteMessage={""}
        triggerUpdatedEvent={this.triggerEvent}
        triggerInsertedEvent={this.triggerInsertEvent}
        triggerDeletedEvent={this.triggerDeletedEvent}
        toolbarItemRender={this.toolbarItemRender}
        triggerAddFavourite={this.onAddFavouriteContact}
        triggerRemoveFavourite={this.onRemoveFavouriteContact}
      />
    );
  }

  onRemoveFavouriteContact(e) {
    let rowParticipant = e.row.data;

    var result = DevExtremeConfirm(
      "Are you sure you want to remove " +
        rowParticipant["first_name"] +
        " " +
        rowParticipant["last_name"] +
        " as favourite contact?"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        let email = rowParticipant["email"];
        let favouriteId = this.state.userFavourites[email]["data"]["id"];
        this.props.deleteFavouriteCustomer(
          favouriteId,
          this.props.authUser.sessionToken
        );
      }
    });
  }

  onAddFavouriteContact(e) {
    let rowParticipant = e.row.data;
    let source = {
      first_name: rowParticipant["first_name"],
      last_name: rowParticipant["last_name"],
      email: rowParticipant["email"],
      mobile_no: rowParticipant["mobile"],
    };
    this.showAddCustomerAsFavouriteModal(source);
  }
  resetBookingStates() {
    // this.startCalendarRefresh();

    this.setState({
      isParticipantLoaded: false,
      isBookingSaving: false,
      isConflictLoaded: false,
      customerId: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      address: "",
      invoice: "",
      paid: false,
      notes: "",
      selectedBooking: {},
      duration: "",
      origTime: "",
      time: "",
      paymentMethod: "Credit Card",
      isRecurring: false,
      frequencyInWeeks: "Weekly",
      totalPrice: 0,
      origPrice: 0,
      doneGettingOriginalPrice: false,
      showPriceChangeWarning: false,
      chargeAmount: 0,
      unPaidAmount: 0,
      lessAmount: 0,
      totalAmount: 0,
      unPaidAmount: 0,

      wasValidated: "",
      isInitialPriceComputed: false,
      showBookingModal: false,

      isClientSideFilter: false,
      locationResourcesLoaded: false,

      coupon: "",
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,

      showBookingDetailsModal: false,
      selectedBookingDetails: {},

      durationDefaultVal: 30,

      discountAmount: 0,
      receiptUrl: "",
      vendor: "",

      bookingRef: "",
      bookingUuid: "",
      bookingId: "",

      locationId: "",
      locationUuid: "",
      locationName: "",

      resourceId: "",
      resourceUuid: "",
      resourceName: "",

      customerId: "",
      customerUuid: "",

      origStatus: "",
      status: "",
      statusDesc: "",
      statusChangeConfirmation: "",

      statusNotes: "",

      isUpdate: true,

      activeItemInnerPills: "1",

      isChargable: 0,
      issueVoucher: 0,
      isOverrideble: 0,

      paymentTransaction: {},

      paymentHistoryElem: [],
      bookingStatusHistoryElem: [],

      disableStatusButton: false,

      generateVoucher: false,

      disableSaveButton: false,
      showRecurringWarning: false,
      searchDateVal: this.state.origSearchDateVal,

      showCalendarSelect: false,
      updateSchedule: false,

      showExceedMaxDurationAcknowledge: false,
      maxDuration: 0,
      maxDurationInText: "",
      checkMoreThanMaxDuration: false,

      value: "",
      participants: [],
      readOnlyEmail: false,
      defaultCustomer: {},
      overwriteFee: false,
      overwriteFeeAmount: "",
      newSelectedBookingsContainer: undefined,
      showHideTC: false,
      iAggree: false,
      isCorrectSelectedDate: false,
    });

    this.generateVoucher = false;
  }

  validateShowExceedMaxDuration(duration, resourceUuid, isBookingDetails) {
    const { locationResourcesContainer, selectedBooking } = this.state;

    let maxDuration = 0;

    if (isBookingDetails) {
      let resource = _.chain(locationResourcesContainer.results)
        .map((resource) => {
          if (resource.uuid === resourceUuid) {
            return resource;
          }
          return [];
        })
        .filter(_.size)
        .value()[0];

      maxDuration = new Number(
        _.replace(resource.attributes.max_duration, "m", "")
      ).valueOf();
    } else {
      maxDuration = new Number(
        _.replace(selectedBooking.maxDuration, "m", "")
      ).valueOf();
    }

    let showExceedMaxDurationAcknowledge = false;
    if (new Number(duration).valueOf() > maxDuration) {
      showExceedMaxDurationAcknowledge = true;
    }

    this.setState({
      maxDuration: maxDuration,
      showExceedMaxDurationAcknowledge: showExceedMaxDurationAcknowledge,
    });
  }

  openBookingDetailsModal = (paramData, booked) => {
    // Used to open a modal for Booked details time slot

    // clearInterval(this.intervalId);
    this.intervalId = null;
    let getAvailableSlot = this.getAvailableSlotPrivate(
      paramData.resourceUuid,
      paramData.time,
      paramData
    );

    let bookObj = booked[0];

    let startTime = moment(bookObj.start_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");
    let endTime = moment(bookObj.end_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");

    let t1 = moment(bookObj.start_time);
    let t2 = moment(bookObj.end_time);

    let duration = t2.diff(t1, "minutes");

    let paymentHistoryElem = [];

    this.setState({
      selectedBookingDetails: booked[0],
    });

    this.setState({
      newSelectedBookingsContainer: paramData,
      showBookingDetailsModal: true,
      selectedBookingDetails: booked[0],

      customerId: bookObj.customer.id,
      first_name: bookObj.customer.first_name,
      last_name: bookObj.customer.last_name,
      email: bookObj.customer.email,
      mobile: bookObj.customer.mobile,
      address: bookObj.customer.address,

      invoice: bookObj.transaction.invoice,
      paid: bookObj.transaction.ispaid ? true : false,
      notes: bookObj.comments,
      time: startTime + "-" + endTime,
      origTime: startTime + "-" + endTime,

      durationDefaultVal: duration,
      duration: duration,

      discountAmount: bookObj.transaction.totalVoucherCreditAmount,

      bookingRef: bookObj.booking_reference,
      bookingUuid: bookObj.uuid,
      bookingId: bookObj.id,

      locationId: bookObj.location.id,
      locationUuid: bookObj.location.uuid,
      locationName: bookObj.location.name,

      resourceId: paramData.resourceId,
      resourceUuid: bookObj.resource.uuid,
      resourceName: bookObj.resource.name,

      customerId: bookObj.customer.id,
      customerUuid: bookObj.customer.uuid,

      origStatus: bookObj.status,
      status: bookObj.status,
      getAvailableSlot: getAvailableSlot,

      paymentTransaction: bookObj.transaction,

      isRecurring: bookObj.is_recurring === 1 ? true : false,
      frequencyInWeeks: bookObj.recurring_type,

      paymentHistoryElem: paymentHistoryElem,

      showRecurringWarning: bookObj.is_recurring === 1 ? true : false,

      origSearchDateVal: _.cloneDeep(this.state.searchDateVal),
    });
    this.props.pauseBackgroundRequests(true);
  };

  toggleBookingModal = (showBookingModal) => {
    this.resetAlertMessage();
    this.setState({
      value: null,
      participants: [],
      showBookingModal: showBookingModal,
      customerId: "",
      participants: [],
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      address: "",
      invoice: "",
      paid: false,
      notes: "",
      selectedBooking: {},
      duration: "",
      origTime: "",
      time: "",
      paymentMethod: "Credit Card",
      isRecurring: false,
      frequencyInWeeks: "Weekly",
      totalPrice: 0,
      origPrice: 0,
      doneGettingOriginalPrice: false,
      showPriceChangeWarning: false,
      chargeAmount: 0,
      unPaidAmount: 0,
      lessAmount: 0,
      totalAmount: 0,
      unPaidAmount: 0,

      wasValidated: "",
      coupon: "",
      statusNotes: "",
      isChargable: 0,
      issueVoucher: 0,
      isOverrideble: 0,
      // customerResultContainer: [],
      value: "",
      iAggree: false,
    });
    const { locked } = this.state;
    // if (websocketApi.isOpen) {
    //   websocketApi.send(JSON.stringify({ ...locked, action: "release_lock" }));
    // }
    this.props.pauseBackgroundRequests(false);
    this.getDefaultCustomer();
  };

  openBookingModal = (paramData) => {
    // Used to open an available for logged in  users (admin and private)

    const { isChangeSchedule, locationResourcesContainer } = this.state;
    // if (Object.keys(locationResourcesContainer).length !== 0) {
    clearInterval(this.intervalId);
    let resource = _.chain(locationResourcesContainer.results)
      .map((resource) => {
        if (resource.uuid === paramData.resourceUuid) {
          return resource;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];
    if (
      typeof resource.attributes.max_participants !== "undefined" &&
      resource.attributes.max_participants != ""
    ) {
      this.maxBookingParticipants = resource.attributes.max_participants;
    }

    if (isChangeSchedule) {
      this.props.bookingChangeSchedule({
        time: paramData.time,
        origTime: paramData.time,
        defaultEndTime: paramData.defaultEndTime,
        defaultStartTime: paramData.defaultStartTime,
        useCalendarSelectedTime: true,
        resourceName: paramData.court,
        resourceUuid: paramData.resourceUuid,
        resourceId: paramData.resourceId,
        updateSchedule: true,
        isRecurring: false,
        searchDateVal: this.state.searchDateVal,
        locationResource: resource,
      });
      this.setState({
        showCalendarSelect: false,
      });
    } else if (this.state.showBookingDetailsModal) {
      let getAvailableSlot = this.getAvailableSlotPrivate(
        paramData.resourceUuid,
        paramData.time,
        paramData
      );

      let start = paramData.time.split("-")[0];
      let duration = this.state.durationDefaultVal;
      let defaultEndTime = paramData.defaultEndTime;

      let end = start;
      let endMeet = false;

      let actualDuration = 0;

      let bookedStartTimeArr = _.chain(paramData.bookings)
        .map((book) => {
          return {
            startTime: moment(book.start_time)
              .tz(this.getTzDetails().timezone)
              .format("HH:mm"),
            endTime: moment(book.end_time)
              .tz(this.getTzDetails().timezone)
              .format("HH:mm"),
            bookingRef: book.booking_reference,
          };
        })
        .orderBy(["startTime"], ["asc"])
        .value();

      while (!endMeet) {
        end = moment(end, "HH:mm").add(30, "minutes").format("HH:mm");
        actualDuration += 30;

        _.map(bookedStartTimeArr, (bookTimeObj) => {
          if (
            this.state.bookingRef !== bookTimeObj.bookingRef &&
            bookTimeObj.startTime === end
          ) {
            endMeet = true;
          }
        });

        if (moment(end, "HH:mm").isSame(moment(defaultEndTime, "HH:mm"))) {
          endMeet = true;
        }
        if (actualDuration === duration) {
          endMeet = true;
        }
      }

      let time = `${start}-${end}`;

      this.setState({
        time: time,
        origTime: time,
        getAvailableSlot: getAvailableSlot,
        showCalendarSelect: false,

        resourceName: paramData.court,
        resourceUuid: paramData.resourceUuid,
        resourceId: paramData.resourceId,
        updateSchedule: true,
        isRecurring: false,
        isUpdate: true,
        duration:
          this.state.durationDefaultVal < actualDuration
            ? this.state.durationDefaultVal
            : actualDuration,
      });
    } else {
      let getAvailableSlot = [];
      if (
        validateAccess(
          this.props.storeUserAccess,
          "BookingDetails",
          "allowBeyondMaxDuration"
        )
      ) {
        getAvailableSlot = this.getAvailableSlotPrivate(
          paramData.resourceUuid,
          paramData.time,
          paramData
        );
      } else {
        getAvailableSlot = this.getAvailableSlot(
          paramData.resourceUuid,
          paramData.time,
          paramData
        );
        this.setDefaultCustomer();

        let minDuration = new Number(_.replace(paramData.minDuration, "m", ""));

        let timeFrom = _.split(paramData.time, "-")[0];
        let timeTo = moment(_.split(paramData.time, "-")[0], "HH:mm")
          .add(minDuration, "minutes")
          .format("HH:mm");

        paramData.time = timeFrom + "-" + timeTo;
      }

      // set the modal to false first because we need to validate the private user first if its still allowed to book , otherwise show the modal immediately if the user is admin
      const isshowBookingModal = this.state.isDefaultUser ? false : true;

      this.setState({
        isUpdate: true,
        showBookingModal: isshowBookingModal,
        selectedBooking: paramData,
        origTime: paramData.time,
        time: paramData.time,
        isInitialPriceComputed: false,
        getAvailableSlot: getAvailableSlot,
        resourceId: paramData.resourceId,
        origSearchDateVal: _.cloneDeep(this.state.searchDateVal),
      });
      this.props.pauseBackgroundRequests(true);
      //Check if the logged in private user is allowed to have another booking
      if (this.state.isDefaultUser) {
        let customerDetails = {
          email: this.props.authUser.username,
        };

        this.setState({
          selectedCustomerOrParticipant: customerDetails,
          isValidateCustomerOrParticipant: true, // Component did update will be triggered when this is changed
        });
      }
    }

    if (this.state.isDefaultUser) {
      this.props.getUserFavourites({}, this.props.authUser.sessionToken);
    }
  };

  toggleBookingTimeslotNotificationModal = (value) => {
    this.setState({
      isShowBookingTimeslotNotificationModal: value,
    });
  };

  openBookingTimeslotNotificationModal = (timeslotNotificationData) => {
    this.toggleBookingTimeslotNotificationModal(true);
    this.setState({
      selectedTimeslotforBookingNotification:
        timeslotNotificationData.selectedTimeslot,
      selectedTimeslotLocation: timeslotNotificationData.location_id,
    });
  };

  setDefaultCustomer() {
    if (this.state.isDefaultUser) {
      const { customerResultContainer, locationObj } = this.state;
      let customer = _.filter(customerResultContainer, function (data) {
        if (data.organisation) {
          return data.organisation.uuid == locationObj.organisation.uuid;
        }
      })[0];

      if (customer !== undefined) {
        let participant = {
          id: -1,
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
          mobile: customer.mobile,
          address: customer.address,
          is_primary: true,
        };

        this.state.participants.push(participant);

        this.setState({
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
          mobile: customer.mobile,
          address: customer.address,
          readOnlyEmail: customer.email.length > 0,
          defaultCustomer: customer,
        });
      } else {
        this.setState({
          email: this.props.authUser.username,
          readOnlyEmail: true,
        });
      }
    }
  }

  debouncedCheckout = _.debounce(function () {
    this.props.history.push("/checkout");
  }, 10);

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isChangeSchedule) {
      if (nextProps.showCalendarSelect) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  componentDidMount() {
    this.modalConfig();

    this.props.showHideLoader(true);

    let params = queryString.parse(this.props.location.search);

    if (!this.state.private) {
      this.props.getLocationResources(
        params.uuid,
        this.state.searchDateVal,
        "2"
      );
      let loc = _.chain(this.props.locations.results)
        .map((location) => {
          if (location.uuid === params.uuid) {
            return location;
          }
          return [];
        })
        .filter(_.size)
        .value()[0];

      this.setState({
        locationTimezone: loc.attributes.timezone,
        bookingColors: getBookingColors(loc.attributes.booking_calendar_colors),
        allowLocResourcePublicBooking:
          loc.attributes.allow_loc_resource_public_booking,
      });
    } else {
      const { storeUserAccess } = this.props;
      let isDefaultUser =
        validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
        validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails");

      this.props.getLocationList(
        { q: params.uuid },
        this.props.authUser.sessionToken
      );

      this.props.getLocationResourcesPrivate(
        params.uuid,
        {
          is_active: 1,
          display_online: 1,
          date: this.state.searchDateVal,
          version: "2",
        },
        this.props.authUser.sessionToken
      );

      this.setState({ isDefaultUser: isDefaultUser });
      this.getDefaultCustomer(isDefaultUser);
    }

    // this.resourceIntervalId = setInterval(
    //   this.isResourceLoaded.bind(this),
    //   500
    // );
    if (this.state.private) {
      this.props.getStatuses(this.props.authUser.sessionToken);
    }

    this.generateVoucher = false;

    // --- Mobile
    window.addEventListener("scroll", this.onScroll);

    this.popupStatusClass = "";
  }

  componentWillUnmount() {
    if (this.state.lockingTimeSlotIntervalId !== "") {
      clearInterval(this.state.lockingTimeSlotIntervalId);
    }
  }

  getDefaultCustomer(isDefaultUser = undefined) {
    if (isDefaultUser !== undefined || this.state.isDefaultUser) {
      let params = queryString.parse(this.props.location.search);
      this.props.getCustomers(
        { limit: 1, customerLocationUuid: params.uuid },
        this.props.authUser.sessionToken
      );
    }
  }

  isResourceLoaded() {
    const { locationResourcesLoaded } = this.state;
  }

  refreshCalendar() {
    const { showBookingDetailsModal, showBookingModal } = this.state;

    // Skipped refreshing when calendar is not active
    if (showBookingModal || showBookingDetailsModal) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.props.showHideLoader(false);
      return;
    }

    let date = this.selectedDateVar;
    let formattedDate = moment(date).format("YYYY-MM-DD");
    let params = queryString.parse(this.props.location.search);

    this.props.selectDate(date);
    this.setState({
      searchDateVal: formattedDate,
      isClientSideFilter: false,
      ClientSidefilterCompleted: false,
      locationResourcesLoaded: false,
    });
    if (this.state.private) {
      this.props.getLocationResourcesPrivate(
        params.uuid,
        { is_active: 1, display_online: 1, date: this.state.searchDateVal },
        this.props.authUser.sessionToken
      );
    } else {
      this.props.getLocationResources(params.uuid, formattedDate);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);

    // -- Mobile
    window.removeEventListener("scroll", this.onScroll);
  }

  startCalendarRefresh = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.isCanRefresh) {
      this.intervalId = setInterval(
        this.refreshCalendar.bind(this),
        this.state.refreshInterval
      );
    } else {
      this.refreshCalendar();
    }
  };

  createCourtTypeOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Resource Type",
      value: "",
    });
    if (
      this.props.locationResources.search_aggs !== undefined &&
      this.props.locationResources.search_aggs.resource_type !== undefined
    ) {
      let buckets =
        this.props.locationResources.search_aggs.resource_type.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    // this.setState({ isCourtTypeLoaded: true });
    return options;
  };

  createSurfaceTypeOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Surface Type",
      value: "",
    });
    if (
      this.props.locationResources.search_aggs !== undefined &&
      this.props.locationResources.search_aggs.surface_type !== undefined
    ) {
      let buckets =
        this.props.locationResources.search_aggs.surface_type.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    // this.setState({ isSurfaceTypeLoaded: true });
    return options;
  };

  debouncedDropdownLoaded = _.debounce(function () {
    this.setState({
      isCourtTypeLoaded: true,
      isSurfaceTypeLoaded: true,
    });
  }, 10);

  componentDidUpdate(prevProps, prevState) {
    this.getSpecificLocation(prevProps, prevState);
    this.validateCalendarRendering(prevProps, prevState);
    this.couponValidation(prevProps, prevState);
    this.bookingStatusRenderValidation(prevProps, prevState);
    // this.validateOnVoucherIssuedSuccess(prevProps, prevState);
    // this.validatePrices(prevProps, prevState);
    this.validateBookingResponse(prevProps, prevState);
    this.validateAddedCustomeresponse(prevProps, prevState);
    this.getCustomerResult(prevProps, prevState);
    this.validateCustomerDetailsResult(prevProps, prevState);
    this.validateRequestPaymentResponse(prevProps, prevState);
    this.validateCalendarNav(prevProps, prevState);

    this.validateResourceResponse(prevProps, prevState);

    if (
      prevState.showBookingModal !== this.state.showBookingModal &&
      this.state.showBookingModal === false &&
      this.state.lockingTimeSlotIntervalId !== ""
    ) {
      clearInterval(this.state.lockingTimeSlotIntervalId);
      this.setState({ lockingTimeSlotIntervalId: "" });
    }

    if (
      prevProps.isCloseBooking !== this.props.isCloseBooking &&
      this.props.isCloseBooking
    ) {
      this.props.closeBooking(false);
      this.setState({
        selectedBookingDetails: null,
        showBookingDetailsModal: false,
      });
      this.resetBookingModal();

      this.props.pauseBackgroundRequests(false);
    }

    if (this.state.isLocationPerMembership && this.state.triggerPricing) {
      this.setState({
        triggerPricing: false,
      });
      this.calculatePrice();
    }

    this.validateBookingConflicts(prevProps, prevState);
    this.subcribeResponse(prevProps, prevState);
    this.getPublicLocation(prevProps, prevState);
    this.validateOverwriteFee(prevProps, prevState);

    if (prevProps.userFavouriteResponse !== this.props.userFavouriteResponse) {
      let response = this.props.userFavouriteResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.props.getUserFavourites({}, this.props.authUser.sessionToken);
        }
      } else {
        const userFavouriteList = this.props.userFavouriteResponse.data;
        let userFavourites = {};

        let userFavouritesEmail = [];
        let customerList = [];

        userFavouriteList.map((favourite) => {
          let favouriteEmail = favourite["email"].toLowerCase();

          if (favouriteEmail != this.props.authUser.username.toLowerCase()) {
            // format the favourites list here
            userFavourites[favouriteEmail] = {};
            userFavourites[favouriteEmail]["display"] =
              favourite.first_name +
              " " +
              favourite.last_name +
              ", " +
              favourite.email +
              ", " +
              favourite.mobile_no;
            userFavourites[favouriteEmail]["data"] = favourite;
            userFavouritesEmail.push(favouriteEmail);

            let formattedData = {};
            formattedData["first_name"] = favourite["first_name"];
            formattedData["last_name"] = favourite["last_name"];
            formattedData["mobile"] = favourite["mobile_no"];
            formattedData["email"] = favourite["email"];
            customerList.push(formattedData);
          }
        });

        this.setState({
          userFavouritesEmail: userFavouritesEmail,
          userFavourites: userFavourites,
          customerResultContainer: customerList,
          isFavouriteListSearch: true,
        });
      }
    }

    if (
      prevState.isValidateCustomerOrParticipant !==
        this.state.isValidateCustomerOrParticipant &&
      this.state.isValidateCustomerOrParticipant == true
    ) {
      this.debouncedValidateAddedCustomer();
    }
  }

  validateOverwriteFee(prevProps, prevState) {
    if (
      this.state.locationObj.id !== undefined &&
      (prevState.overwriteFee !== this.state.overwriteFee ||
        prevState.overwriteFeeAmount !== this.state.overwriteFeeAmount)
    ) {
      this.calculatePrice();
    }
  }

  getPublicLocation(prevProps, prevState) {
    if (prevProps.locations !== this.props.locations) {
      let allowLocResourcePublicBooking = "No";

      if (this.props.locations.results !== undefined) {
        allowLocResourcePublicBooking =
          this.props.locations.results[0].attributes
            .allow_loc_resource_public_booking;
      }

      this.setState({
        allowLocResourcePublicBooking: allowLocResourcePublicBooking,
      });
    }
  }

  subcribeResponse(prevProps, prevState) {
    if (prevProps.userResponse !== this.props.userResponse) {
      let response = this.props.userResponse;
      if (
        response.action == "ACTION" &&
        response.type === "SUBSCRIBE_RESPONSE_HANDLER"
      ) {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
      }
    }
  }

  validateBookingConflicts = (prevProps, prevState) => {
    let bookingResponse = this.props.bookingResponse;
    if (prevProps.bookingResponse !== bookingResponse) {
      if (
        bookingResponse.action == "CONFLICTS" &&
        bookingResponse.page == "calendar"
      ) {
        let warnings = bookingResponse.data.conflicts;
        let validationMessages = [];
        let action = "WARN";
        _.map(bookingResponse.data.validationMessages, function (data) {
          validationMessages.push(data.message);
          action = data.action;
        });

        const bookingWarningMessages =
          bookingResponse.data.bookingWarningMessages;

        this.submitBookingHandler(
          warnings,
          validationMessages,
          bookingWarningMessages,
          action
        );
      }
    }
  };

  validateCalendarNav(prevProps, prevState) {
    if (!this.state.private) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        if (
          moment(this.props.selectedDate, "YYYY-MM-DD").isSameOrBefore(
            new Date(),
            "YYYY-MM-DD"
          )
        ) {
          this.setState({
            calendarPrevNavDisable: true,
          });
          this.props.disablePrevNav(true);
        } else {
          this.setState({
            calendarPrevNavDisable: false,
          });
          this.props.disablePrevNav(false);
        }

        if (
          moment(this.props.selectedDate, "YYYY-MM-DD").isSameOrAfter(
            moment(
              moment(new Date()).add(30, "days").format("YYYY-MM-DD"),
              "YYYY-MM-DD"
            )
          )
        ) {
          this.setState({
            calendarNextNavDisable: true,
          });
          this.props.disableNextNav(true);
        } else {
          this.setState({
            calendarNextNavDisable: false,
          });
          this.props.disableNextNav(false);
        }
      }
    }
  }

  validateResourceResponse(prevProps, prevState) {
    if (
      prevProps.resourceResponse !== this.props.resourceResponse &&
      this.props.resourceResponse.action ===
        "LOCK_RESOURCE_BOOKING_TIME_SLOT" &&
      this.props.resourceResponse.status === 400
    ) {
      if (this.state.lockingTimeSlotIntervalId !== "") {
        clearInterval(this.state.lockingTimeSlotIntervalId);
      }
    }
  }

  validateRequestPaymentResponse(prevProps, prevState) {
    // this.props.paymentRequestSuccess.status
    // paymentRequestFailed

    if (
      prevProps.paymentResponse !== this.props.paymentResponse &&
      this.props.paymentResponse.action === "FAILED_HANDLER" &&
      this.props.paymentResponse.data.isConflict
    ) {
      this.props.showHideLoader(false);
      this.props.showNotification(
        "error",
        this.props.paymentResponse.data.errorMessages[0]
      );
      this.toggleBookingModal(false);
    }

    if (prevProps.paymentRequestSuccess !== this.props.paymentRequestSuccess) {
      if (this.state.isDefaultUser) {
        if (this.props.paymentRequestSuccess.status === 200) {
          if (
            this.props.paymentRequestSuccess.data !== undefined &&
            this.props.paymentRequestSuccess.data.response !== undefined &&
            this.props.paymentRequestSuccess.data.response
              .booking_request_uuid !== undefined
          ) {
            const bookingRequestUUID =
              this.props.paymentRequestSuccess.data.response
                .booking_request_uuid;

            window.location.replace(
              "/admin/checkoutpage?booking_request_uuid=" + bookingRequestUUID
            );
          } else {
            let msg = "Booking Successful";
            this.props.showNotification("success", msg);
            this.props.showHideLoader(false);
            this.resetBookingModal();
            this.props.pauseBackgroundRequests(false);
          }
        }
      } else {
        let msg = "Booking request has been created. Payment request sent!";
        this.props.showNotification("success", msg);
        this.props.showHideLoader(false);
        this.resetBookingModal();
        this.props.pauseBackgroundRequests(false);
      }
    }
    if (prevProps.paymentRequestFailed !== this.props.paymentRequestFailed) {
      this.props.showHideLoader(false);
      let msg = "";

      if (this.props.paymentRequestFailed === "Network error") {
        msg =
          "Unable to save booking. Please try again later or contact system administrator.";
      } else {
        msg = this.props.paymentRequestFailed.data.message;
      }

      this.props.showNotification("error", msg);
      this.resetBookingModal();
    }
  }

  // validateGetOriginaPrice(prevProps,prevState) {
  //   if (
  //     prevProps.successHandlerResponse !== this.props.successHandlerResponse
  //   ) {
  //     if (
  //       this.props.successHandlerResponse !== null &&
  //       this.props.successHandlerResponse.status === 200
  //     ) {
  //       this.props.showHideLoader(false);
  //       this.debouncedSetPrice(
  //         this.props.successHandlerResponse.data.totalPrice
  //       );
  //       this.setState({
  //         couponDiscount: this.props.successHandlerResponse.data.couponDiscount,
  //       });
  //     }
  //   }
  // }

  validateAddedCustomeresponse(prevProps, prevState) {
    if (
      prevProps.addedCustomerParticipantResponse !==
        this.props.addedCustomerParticipantResponse &&
      this.props.addedCustomerParticipantResponse.status === 200 &&
      !this.state.showBookingDetailsModal
    ) {
      const validationData = this.props.addedCustomerParticipantResponse.data;
      this.showValidateCustomerOrParticipantModal(validationData);
      this.setState({ isValidateCustomerOrParticipant: false });
    }
  }

  validateBookingResponse(prevProps, prevState) {
    //-- Success Response
    if (
      prevProps.bookingSuccessResponse !== this.props.bookingSuccessResponse &&
      this.props.bookingSuccessResponse.status === 200 &&
      this.props.bookingSuccessResponse.iscreate
    ) {
      let msg = this.state.isUpdate
        ? "Updated booking details"
        : "Customer has been booked!";

      // redirect to payment page when booking request
      let bookingRespData = this.props.bookingSuccessResponse.data.data;

      if (bookingRespData && this.state.isDefaultUser) {
        if (bookingRespData) {
          const bookingRequestUUID = bookingRespData.booking_request_uuid;
          window.location.replace(
            "/admin/checkoutpage?booking_request_uuid=" + bookingRequestUUID
          );
        }
      }

      this.props.showNotification("success", msg);
      this.props.showHideLoader(false);
      this.resetBookingModal();
      this.props.pauseBackgroundRequests(false);
    }

    //-- Failed Response
    if (
      prevProps.bookingFailedResponse !== this.props.bookingFailedResponse &&
      this.props.bookingFailedResponse.status >= 400 &&
      this.props.bookingFailedResponse.iscreate
    ) {
      if (
        this.props.bookingFailedResponse.data !== undefined &&
        this.props.bookingFailedResponse.data.errorMessages !== undefined &&
        this.props.bookingFailedResponse.data.errorMessages.length > 0
      ) {
        this.props.showHideLoader(false);
        this.props.showNotification(
          "error",
          this.props.bookingFailedResponse.data.errorMessages[0]
        );
      } else {
        this.props.showHideLoader(false);
        this.props.showNotification(
          "error",
          this.props.bookingFailedResponse.data.message
        );
      }

      this.setState({ disableSaveButton: false, disableStatusButton: false });
      this.props.clearBookingFailedResponse();
    } else if (
      prevProps.bookingFailedResponse !== this.props.bookingFailedResponse &&
      this.props.bookingFailedResponse === "Network error"
    ) {
      this.props.showHideLoader(false);

      let msg = this.state.isUpdate
        ? "Unable to update details. Please try again later"
        : "Unable to book customer. Please try again later";

      this.props.showNotification("error", msg);

      this.setState({ disableSaveButton: false, disableStatusButton: false });
      this.props.clearBookingFailedResponse();
    }
  }

  getSpecificLocation(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
        this.props.getLocationListSuccessResponse &&
      this.props.getLocationListSuccessResponse.status === 200
    ) {
      let params = queryString.parse(this.props.location.search);
      let locationObj = _.filter(
        this.props.getLocationListSuccessResponse.data.locations.results,
        function (data) {
          return data.uuid == params.uuid;
        }
      )[0];
      this.setState({
        // isLocationPerMembership:
        //   typeof locationObj.attributes.is_membership_based !== "undefined" &&
        //   locationObj.attributes.is_membership_based.toLowerCase() == "yes",
        isLocationPerMembership: true,
        locationObj: locationObj,
        locationTimezone: locationObj.attributes.timezone,
        bookingColors: getBookingColors(
          locationObj.attributes.booking_calendar_colors
        ),
      });

      //Get the advance booking day for the logged in member
      if (this.state.isDefaultUser && this.props.authUser.username !== "") {
        this.props
          .getMemberAdvanceBookingDay(
            {
              customerEmail: this.props.authUser.username,
              organisationId: locationObj["organisation"]["id"],
              locationId: locationObj["id"],
            },
            this.props.authUser.sessionToken
          )
          .then((response) => {
            if (response.status == 200) {
              const dataResponse = response.data;
              const ruleData = dataResponse["ruleData"];

              let ruleMessage = "";

              if (dataResponse["ruleData"]["message"]) {
                ruleMessage = dataResponse["ruleData"]["message"];
              }

              if ("advance_booking_days" in ruleData) {
                this.setState({
                  overrideRuleDaysInAdvanceMessage: ruleMessage,
                  overrideRuleDaysInAdvanceValue:
                    dataResponse["ruleData"]["advance_booking_days"],
                });
              }
            }
          })
          .catch((error) => {
            console.log(error.toJSON());
          });
      }

      if (this.state.isDefaultUser) {
        // this.debouncedSearchCustomer("testdefault00001@grr.la");
      }
    }

    if (
      this.props.isChangeSchedule &&
      prevState.bookingColors === this.state.bookingColors &&
      this.state.bookingColors === undefined
    ) {
      let locationObj =
        this.props.getLocationListSuccessResponse.data.locations.results[0];
      this.setState({
        locationObj: locationObj,
        locationTimezone: locationObj.attributes.timezone,
        bookingColors: getBookingColors(
          locationObj.attributes.booking_calendar_colors
        ),
      });
    }
  }

  // validateOnVoucherIssuedSuccess(prevProps, prevState) {
  //   //-- Depricated
  //   if (
  //     prevProps.genCustomerVoucherSuccessResponse !==
  //       this.props.genCustomerVoucherSuccessResponse &&
  //     this.props.genCustomerVoucherSuccessResponse.status === 200
  //   ) {
  //     this.doBooking(true);
  //   }
  // }

  bookingStatusRenderValidation(prevProps, prevState) {
    if (
      prevProps.getBookingStatusSuccessResponse !==
      this.props.getBookingStatusSuccessResponse
    ) {
      if (this.props.getBookingStatusSuccessResponse.status === 200) {
        let bookingStatusHistoryElem = this.buildStatusHistory(
          this.props.getBookingStatusSuccessResponse
        );
        this.setState({ bookingStatusHistoryElem: bookingStatusHistoryElem });
      }
    }
  }

  debouncedSetPrice = _.debounce(function (price) {
    this.setState({
      totalPrice: price.totalPrice,
      origPrice: price.originalPrice,
      chargeAmount: price.chargePrice,
      unPaidAmount: price.unpaidAmount,
      lessAmount: price.refundAmount,
      couponDiscount: price.couponDiscount,
      showPriceChangeWarning: price.totalPrice !== price.originalPrice,
    });
  }, 10);

  couponValidation(prevProps, prevState) {
    if (this.state.showBookingModal && prevState.time !== this.state.time) {
      this.calculatePrice();
    }

    if (this.state.showBookingModal && prevState.coupon !== this.state.coupon) {
      this.debouncedCalculatePrice();
    }

    if (
      prevProps.successHandlerResponse !== this.props.successHandlerResponse
    ) {
      if (
        this.props.successHandlerResponse !== null &&
        this.props.successHandlerResponse.status === 200
      ) {
        this.props.showHideLoader(false);
        this.debouncedSetPrice(this.props.successHandlerResponse.data);
        this.setState({
          couponDiscount: this.props.successHandlerResponse.data.couponDiscount,
        });

        // // -- Get original price
        // if (
        //   this.state.showBookingDetailsModal &&
        //   !this.state.doneGettingOriginalPrice
        // ) {
        //   this.setState({
        //     origPrice: this.props.successHandlerResponse.data.totalPrice,
        //     doneGettingOriginalPrice: true,
        //   });
        // }
      }
    }

    if (prevProps.failedHandlerResponse !== this.props.failedHandlerResponse) {
      if (
        this.props.failedHandlerResponse !== null &&
        this.props.failedHandlerResponse.status === 400
      ) {
        if (
          this.props.failedHandlerResponse.data.errorMessages !== undefined &&
          this.props.failedHandlerResponse.data.errorMessages.length > 0
        ) {
          this.setState({
            couponErrorMsg:
              this.props.failedHandlerResponse.data.errorMessages[0],
            invalidCoupon: true,
          });
        }

        this.props.showHideLoader(false);
        this.debouncedSetPrice(this.props.failedHandlerResponse.data);
        this.setState({
          couponDiscount: this.props.failedHandlerResponse.data.couponDiscount,
        });
      }
    }
  }

  setAdvanceDaysBooking() {
    let selectedLocation = this.state.locationObj;
    let days_in_advance = 30;
    let additionalbookingDays = 0;

    if (!this.state.isDefaultUser && this.state.private == true) {
      //If logged in user is admin set default is no limit
      days_in_advance = null;
    } else if (this.state.overrideRuleDaysInAdvanceValue) {
      days_in_advance = this.state.overrideRuleDaysInAdvanceValue;
    } else if (selectedLocation.hasOwnProperty("attributes")) {
      let isLeadTimeRollover = false;
      let leadTimeRollover = 0;

      if (this.state.isDefaultUser == true && this.state.private == true) {
        // if logged in user is customer
        days_in_advance = selectedLocation.attributes.hasOwnProperty(
          "private_user_allowed_booking_days"
        )
          ? parseInt(
              selectedLocation.attributes.private_user_allowed_booking_days
            )
          : 30;

        additionalbookingDays = selectedLocation.attributes.hasOwnProperty(
          "private_user_additional_booking_day"
        )
          ? parseInt(
              selectedLocation.attributes.private_user_additional_booking_day
            )
          : 0;

        //For roll over private
        if (
          selectedLocation.attributes.hasOwnProperty(
            "private_user_rollover_lead_time"
          )
        ) {
          leadTimeRollover =
            selectedLocation.attributes.private_user_rollover_lead_time;
          isLeadTimeRollover = true;
        }
      } else if (this.state.private == false) {
        // public booking
        days_in_advance = selectedLocation.attributes.hasOwnProperty(
          "public_user_allowed_booking_days"
        )
          ? parseInt(
              selectedLocation.attributes.public_user_allowed_booking_days
            )
          : 30;
        additionalbookingDays = selectedLocation.attributes.hasOwnProperty(
          "public_user_additional_booking_day"
        )
          ? parseInt(
              selectedLocation.attributes.public_user_additional_booking_day
            )
          : 0;

        //for public rollover

        if (
          selectedLocation.attributes.hasOwnProperty(
            "public_user_rollover_lead_time"
          )
        ) {
          leadTimeRollover =
            selectedLocation.attributes.public_user_rollover_lead_time;
          isLeadTimeRollover = true;
        }
      }

      //Let Start checking if we will start add days based on rollover lead time

      if (isLeadTimeRollover) {
        let currentTime = moment(new Date()).format("HH:mm");
        if (currentTime > leadTimeRollover) {
          //add the additional allowed booking days
          days_in_advance += additionalbookingDays;
        }
      }
    }

    this.days_in_advance = days_in_advance;
  }

  validateCalendarRendering(prevProps, prevState) {
    if (this.props.locationResources.results !== undefined) {
      //-- Date Filter
      if (
        prevProps.locationResources.results !==
          this.props.locationResources.results &&
        !this.state.isClientSideFilter &&
        !this.state.locationResourcesLoaded
      ) {
        const { browserSize } = this.props;
        this.setAdvanceDaysBooking();

        var results = _.chain(this.props.locationResources.results)
          .orderBy(["id", "name"], ["asc", "asc"])
          .value();

        this.props.locationResources.results = results;

        let generatedTimeContainer = this.generateTime(
          this.props.locationResources
        );

        let tableElem = undefined;
        let tableElemForMobile = false;
        if (browserSize.lessThan.medium) {
          tableElem = this.mobileBuildTableElem(
            generatedTimeContainer,
            this.props.locationResources
          );
          tableElemForMobile = true;
        } else {
          tableElem = this.buildTableElem(
            generatedTimeContainer,
            this.props.locationResources
          );
          tableElemForMobile = false;
        }

        // let recurrings = [];
        // this.props.locationResources.results.forEach((value, index) => {
        //   value.recurrings.forEach((recurring, index) => {
        //     recurrings.push(recurring);
        //   });
        // });

        this.setState({
          // recurrings: recurrings,
          locationResourcesContainer: this.props.locationResources,
          origLocationResourcesContainer: _.cloneDeep(
            this.props.locationResources
          ),
          locationResourcesLoaded: true,
          courtTypeOptions: this.createCourtTypeOptions(),
          surfaceTypeOptions: this.createSurfaceTypeOptions(),
          doDropdownFilter: false,
          generatedTimeContainer: generatedTimeContainer,
          tableElem: tableElem,
          tableElemForMobile: tableElemForMobile,
        });

        if (!this.isCanRefresh) {
          this.isCanRefresh = true;
          // this.startCalendarRefresh();
        }

        this.props.showHideLoader(false);
      }
      //-- Resource and Surface Filter
      else if (
        prevProps.locationResources.results ===
          this.props.locationResources.results &&
        this.state.doDropdownFilter &&
        this.state.isClientSideFilter &&
        !this.state.ClientSidefilterCompleted
      ) {
        let filterLocationResources = this.filterLocationResources();
        let generatedTimeContainer = this.generateTime(filterLocationResources);
        let tableElem = this.buildTableElem(
          generatedTimeContainer,
          this.props.locationResources
        );

        this.setState({
          locationResourcesContainer: filterLocationResources,
          generatedTimeContainer: generatedTimeContainer,
          tableElem: tableElem,
        });
        this.props.showHideLoader(false);
      }
    }
  }

  filterLocationResources() {
    let filteredData = _.cloneDeep(this.state.origLocationResourcesContainer);

    if (this.state.selectedResource !== "Select Resource Type") {
      filteredData.results = _.chain(filteredData.results)
        .map((result) => {
          if (result.attributes.resource_type === this.state.selectedResource) {
            return result;
          }
          return [];
        })
        .filter(_.size)
        .value();
    }

    if (this.state.selectedSurface !== "Select Surface Type") {
      filteredData.results = _.chain(filteredData.results)
        .map((result) => {
          if (result.attributes.surface_type === this.state.selectedSurface) {
            return result;
          }
          return [];
        })
        .filter(_.size)
        .value();
    }

    // filteredData.results = [filteredData.results[0]];
    this.setState({ ClientSidefilterCompleted: true });
    return filteredData;
  }

  generateTime(locationResourcesContainer) {
    if (locationResourcesContainer.results !== undefined) {
      let minTime = _.chain(locationResourcesContainer.results)
        .map((result) => {
          if (
            // result.is_active === 1 &&
            // result.display_online === 1 &&
            result.attributes.max_duration !== undefined &&
            result.attributes.max_duration.length > 0 &&
            result.attributes.min_duration !== undefined &&
            result.attributes.min_duration.length > 0 &&
            result.attributes.duration_per_chunk.length > 0 &&
            moment(result.attributes.default_start_time, "HH:mm").isValid() &&
            moment(result.attributes.default_end_time, "HH:mm").isValid()
          ) {
            return result.attributes.default_start_time;
          }
          return [];
        })
        .filter(_.size)
        .sort()
        .value()[0];
      let maxTime = _.chain(locationResourcesContainer.results)
        .map((result) => {
          if (
            // result.is_active === 1 &&
            // result.display_online === 1 &&
            result.attributes.max_duration !== undefined &&
            result.attributes.max_duration.length > 0 &&
            result.attributes.min_duration !== undefined &&
            result.attributes.min_duration.length > 0 &&
            result.attributes.duration_per_chunk.length > 0 &&
            moment(result.attributes.default_start_time, "HH:mm").isValid() &&
            moment(result.attributes.default_end_time, "HH:mm").isValid()
          ) {
            return result.attributes.default_end_time;
          }
          return [];
        })
        .filter(_.size)
        .sort()
        .reverse()
        .value()[0];

      let addedTime = minTime;

      let formattedTimeArr = [];
      let toFormatObj = {};

      let counter = 0;
      if (
        moment(addedTime, "HH:mm").isValid() &&
        moment(maxTime, "HH:mm").isValid()
      ) {
        while (addedTime !== maxTime) {
          counter++;
          if (counter !== 1) {
            addedTime = moment(addedTime, "HH:mm")
              .add(30, "minutes")
              .format("HH:mm");
          }
          if (toFormatObj.start === undefined) {
            toFormatObj["start"] = addedTime;
          } else if (
            toFormatObj.start !== undefined &&
            toFormatObj.end === undefined
          ) {
            toFormatObj["end"] = addedTime;
          }

          if (
            toFormatObj.start !== undefined &&
            toFormatObj.end !== undefined
          ) {
            formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
            addedTime = toFormatObj.start;
            toFormatObj = {};
          }
        }
      }

      return formattedTimeArr;
    }
  }

  testReturnH1(text) {
    return (
      <div>
        <h1>{text}</h1>
      </div>
    );
  }

  renderTH(text, key, className, width) {
    return (
      <th key={key} className={className} width={width + "%"}>
        <strong>{text}</strong>
      </th>
    );
  }

  calendarNav(isNext) {
    let date = null;
    if (isNext) {
      date = moment(this.props.selectedDate).add(1, "days").toDate();
    } else {
      date = moment(this.props.selectedDate).subtract(1, "days").toDate();
    }
    this.setState({
      isClientSideFilter: false,
      ClientSidefilterCompleted: false,
      locationResourcesLoaded: false,
    });
    this.debouncedSelectedDate(date);
  }

  clearRefreshCalendarTimer() {
    clearInterval(this.intervalId);
    // this.debouncedReRunRefreshCalendar();
  }

  debouncedReRunRefreshCalendar = _.debounce(function () {
    this.intervalId = setInterval(
      this.refreshCalendar.bind(this),
      this.state.refreshInterval
    );
  }, 5000);

  renderTHNav(text, key, className, isNext) {
    const { calendarPrevNavDisable } = this.state;
    let calendarNextNavDisable = this.state.calendarNextNavDisable;
    if (isNext) {
      if (this.overrideCalendarNextNavDisable) {
        calendarNextNavDisable = this.overrideCalendarNextNavDisable;
        this.overrideCalendarNextNavDisable = undefined;
      }
      return (
        <th key={key} className={className}>
          <CalendarNavigation
            calendarNav={this.calendarNav}
            isNext={true}
            defaultState={calendarNextNavDisable}
          />
        </th>
      );
    } else {
      return (
        <th key={key} className={className}>
          <CalendarNavigation
            calendarNav={this.calendarNav}
            isNext={false}
            defaultState={calendarPrevNavDisable}
          />
        </th>
      );
    }
  }

  renderTD(text, key) {
    const { bookingColors } = this.state;
    if (!this.state.private) {
      return (
        <td
          key={key}
          className={
            text.length === 0
              ? getCss(bookingColors.bg_public_notAvailable)
              : ""
          }
        >
          <strong>{text}</strong>
        </td>
      );
    } else {
      if (text.length === 0) {
        return (
          <td key={key} className={getCss(bookingColors.bg_notAvailable)}></td>
        );
      }
      return (
        <td key={key}>
          <strong>{text}</strong>
        </td>
      );
    }
  }

  renderTDmobile(text, key, width) {
    const { bookingColors } = this.state;
    if (!this.state.private) {
      return (
        <td
          key={key}
          className={
            text.length === 0
              ? getCss(bookingColors.bg_public_notAvailable)
              : ""
          }
          style={{ width: width + "%" }}
        >
          <strong>{text}</strong>
        </td>
      );
    } else {
      if (text.length === 0) {
        return (
          <td
            key={key}
            style={{ width: width + "%" }}
            className={getCss(bookingColors.bg_notAvailable)}
          ></td>
        );
      }
      return (
        <td key={key} style={{ width: width + "%" }}>
          <strong>{text}</strong>
        </td>
      );
    }
  }

  renderTDContainer(paramData, rowSpan) {
    const { bookingColors } = this.state;
    return (
      <td
        rowSpan={rowSpan}
        key={paramData.key}
        style={{ padding: "0", position: "relative" }}
      >
        <CalendarBookingComponent
          resourceObj={paramData}
          locationTimezone={this.state.locationTimezone}
          bookingColors={bookingColors}
          isDefaultUser={this.state.isDefaultUser}
          searchDateVal={this.state.searchDateVal}
          isPrivate={this.state.private}
          allowLocResourcePublicBooking={
            this.state.allowLocResourcePublicBooking
          }
          storeUserAccess={this.props.storeUserAccess}
          popupStatusClass={this.popupStatusClass}
          openBookingDetailsModal={this.openBookingDetailsModal}
          isChangeSchedule={this.props.isChangeSchedule}
          showCalendarSelect={this.props.showCalendarSelect}
          tempReadonlyModal={this.tempReadonlyModal}
          openBookingModal={this.openBookingModal}
          openModal={this.openModal}
          openBookingTimeslotNotificationModal={
            this.openBookingTimeslotNotificationModal
          }
          isSelectedTimeslotPassed={this.isSelectedTimeslotPassed}
        />
      </td>
    );

    // const { bookingColors } = this.state;
    // const { storeUserAccess } = this.props;
    // let isStartTime = false;
    // // let rowSpan = 1;
    // let bookObj = booked[0];
    // let genStartTime = _.split(paramData.time, "-")[0];

    // let localStartTime = moment(bookObj.start_time)
    //   .tz(this.getTzDetails().timezone)
    //   .format("HH:mm");

    // let t1 = moment(bookObj.start_time);
    // let t2 = moment(bookObj.end_time);

    // let duration = t2.diff(t1, "minutes");
    // rowSpan = duration / 30;

    // if (genStartTime === localStartTime) {
    //   isStartTime = true;
    // }

    // if (!this.state.private) {
    //   if (isStartTime) {
    //     return (
    //       <td
    //         className={getCss(bookingColors.bg_public_booked)}
    //         rowSpan={rowSpan}
    //         key={paramData.key}
    //       >
    //         <strong className={getCss(bookingColors.tc_public_booked)}>
    //           Booked
    //         </strong>
    //       </td>
    //     );
    //   }
    //   return <></>;
    // } else {
    //   // -- TODO Temporary validation
    //   let noCustomerDetails = false;
    //   if (bookObj.customer === undefined) {
    //     // if (bookObj.customer.email !== "test1@test.com") {
    //     noCustomerDetails = true;
    //   }

    //   let isRecurring =
    //     bookObj.parent_booking !== undefined || bookObj.recurring !== undefined;

    //   let tdColor = "",
    //     tdTextColor = "",
    //     tdColorClickable = "";

    //   if (!noCustomerDetails) {
    //     tdColor = isRecurring
    //       ? getCss(bookingColors.bg_recurring)
    //       : getCss(bookingColors.bg_oneOff);
    //     tdTextColor = isRecurring
    //       ? getCss(bookingColors.tc_recurring)
    //       : getCss(bookingColors.tc_oneOff);
    //     tdColorClickable = isRecurring
    //       ? getCss(bookingColors.bg_recurring)
    //       : getCss(bookingColors.bg_oneOff);
    //   } else {
    //     tdColor = getCss(bookingColors.bg_notAvailable);
    //     tdTextColor = getCss(bookingColors.tc_notAvailable);
    //     tdColorClickable = getCss(bookingColors.bg_notAvailable);
    //   }

    //   if (this.props.isChangeSchedule && this.props.showCalendarSelect) {
    //     if (isStartTime) {
    //       if (noCustomerDetails) {
    //         return (
    //           <td
    //             rowSpan={rowSpan}
    //             className={tdColorClickable}
    //             key={paramData.key}
    //           >
    //             <strong className={tdTextColor}>Booked</strong>
    //           </td>
    //         );
    //       }
    //       return (
    //         <td rowSpan={rowSpan} className={`${tdColor}`} key={paramData.key}>
    //           {this.displayIcon(bookObj)}
    //           <strong className={tdTextColor}>
    //             {bookObj.customer.first_name + " " + bookObj.customer.last_name}
    //           </strong>
    //         </td>
    //       );
    //     }
    //     return <></>;
    //   }
    //   if (isStartTime) {
    //     if (noCustomerDetails) {
    //       return (
    //         <td
    //           rowSpan={rowSpan}
    //           className={tdColorClickable}
    //           key={paramData.key}
    //         >
    //           <strong className={tdTextColor}>Booked</strong>
    //         </td>
    //       );
    //     }
    //     if (validateAccess(storeUserAccess, "AdminBooking", "viewBooking")) {
    //       return (
    //         <td
    //           rowSpan={rowSpan}
    //           className={`${tdColorClickable}`}
    //           key={paramData.key}
    //           style={{ cursor: "pointer" }}
    //           onClick={() => this.openBookingDetailsModal(paramData, booked)}
    //         >
    //           {this.displayIcon(bookObj)}
    //           <strong className={tdTextColor}>
    //             {bookObj.customer.first_name + " " + bookObj.customer.last_name}
    //           </strong>
    //         </td>
    //       );
    //     }
    //     return (
    //       <td
    //         rowSpan={rowSpan}
    //         className={tdColorClickable}
    //         key={paramData.key}
    //       >
    //         {this.displayIcon(bookObj)}
    //         <strong className={tdTextColor}>
    //           {bookObj.customer.first_name + " " + bookObj.customer.last_name}
    //         </strong>
    //       </td>
    //     );
    //   } else {
    //     return <></>;
    //   }
    // }
  }

  renderBookedTD(paramData, booked) {
    const { bookingColors } = this.state;
    const { storeUserAccess } = this.props;
    let isStartTime = false;
    let rowSpan = 1;
    let bookObj = booked[0];
    let genStartTime = _.split(paramData.time, "-")[0];

    let localStartTime = moment(bookObj.start_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");

    let t1 = moment(bookObj.start_time);
    let t2 = moment(bookObj.end_time);

    let duration = t2.diff(t1, "minutes");
    rowSpan = duration / 30;

    if (genStartTime === localStartTime) {
      isStartTime = true;
    }

    if (!this.state.private) {
      if (isStartTime) {
        return (
          <td
            className={getCss(bookingColors.bg_public_booked)}
            rowSpan={rowSpan}
            key={paramData.key}
          >
            <strong className={getCss(bookingColors.tc_public_booked)}>
              Booked
            </strong>
          </td>
        );
      }
      return <></>;
    } else {
      // -- TODO Temporary validation
      let noCustomerDetails = false;
      if (bookObj.customer === undefined) {
        // if (bookObj.customer.email !== "test1@test.com") {
        noCustomerDetails = true;
      }

      let isRecurring =
        bookObj.parent_booking !== undefined || bookObj.recurring !== undefined;

      let tdColor = "",
        tdTextColor = "",
        tdColorClickable = "";

      if (!noCustomerDetails) {
        tdColor = isRecurring
          ? getCss(bookingColors.bg_recurring)
          : getCss(bookingColors.bg_oneOff);
        tdTextColor = isRecurring
          ? getCss(bookingColors.tc_recurring)
          : getCss(bookingColors.tc_oneOff);
        tdColorClickable = isRecurring
          ? getCss(bookingColors.bg_recurring)
          : getCss(bookingColors.bg_oneOff);
      } else {
        tdColor = getCss(bookingColors.bg_notAvailable);
        tdTextColor = getCss(bookingColors.tc_notAvailable);
        tdColorClickable = getCss(bookingColors.bg_notAvailable);
      }

      if (this.props.isChangeSchedule && this.props.showCalendarSelect) {
        if (isStartTime) {
          if (noCustomerDetails) {
            return (
              <td
                rowSpan={rowSpan}
                className={tdColorClickable}
                key={paramData.key}
              >
                <strong className={tdTextColor}>Booked</strong>
              </td>
            );
          }
          return (
            <td rowSpan={rowSpan} className={`${tdColor}`} key={paramData.key}>
              {this.displayIcon(bookObj)}
              <strong className={tdTextColor}>
                {bookObj.customer.first_name + " " + bookObj.customer.last_name}
              </strong>
            </td>
          );
        }
        return <></>;
      }
      if (isStartTime) {
        if (noCustomerDetails) {
          return (
            <td
              rowSpan={rowSpan}
              className={tdColorClickable}
              key={paramData.key}
            >
              <strong className={tdTextColor}>Booked</strong>
            </td>
          );
        }
        if (validateAccess(storeUserAccess, "AdminBooking", "viewBooking")) {
          return (
            <td
              rowSpan={rowSpan}
              className={`${tdColorClickable}`}
              key={paramData.key}
              style={{ cursor: "pointer" }}
              onClick={() => this.openBookingDetailsModal(paramData, booked)}
            >
              {this.displayIcon(bookObj)}
              <strong className={tdTextColor}>
                {bookObj.customer.first_name + " " + bookObj.customer.last_name}
              </strong>
            </td>
          );
        }
        return (
          <td
            rowSpan={rowSpan}
            className={tdColorClickable}
            key={paramData.key}
          >
            {this.displayIcon(bookObj)}
            <strong className={tdTextColor}>
              {bookObj.customer.first_name + " " + bookObj.customer.last_name}
            </strong>
          </td>
        );
      } else {
        return <></>;
      }
    }
  }

  displayIcon(bookObj) {
    let isPaid =
      bookObj.transaction !== undefined &&
      bookObj.transaction.ispaid !== undefined &&
      bookObj.transaction.ispaid;

    let lastPaymentElem = bookObj.transaction.payments[0];

    let partiallyPaid =
      bookObj.transaction !== undefined &&
      bookObj.transaction.ispaid !== undefined &&
      bookObj.transaction.ispaid === 0 &&
      lastPaymentElem.voucherCreditAmount > 0;

    let unpaid =
      bookObj.transaction !== undefined &&
      bookObj.transaction.ispaid !== undefined &&
      bookObj.transaction.ispaid === 0 &&
      // lastPaymentElem.totalAmount === lastPaymentElem.subTotalAmount &&
      lastPaymentElem.voucherCreditAmount === 0;

    return (
      <div className="slotIconContainer">
        <MDBBadge pill color="secondary">
          {bookObj.status}
        </MDBBadge>

        {isPaid ? (
          <MDBBadge pill color="success">
            Paid
          </MDBBadge>
        ) : (
          <></>
        )}

        {partiallyPaid ? (
          <MDBBadge pill color="warning-color-dark">
            Partially Paid
          </MDBBadge>
        ) : (
          <></>
        )}

        {unpaid ? (
          <MDBBadge pill color="warning-color-dark">
            Unpaid
          </MDBBadge>
        ) : (
          <></>
        )}

        {bookObj.is_recurring === 1 ? (
          <MDBBadge pill color="primary">
            {bookObj.recurring_type}
          </MDBBadge>
        ) : (
          <></>
        )}

        {bookObj.is_recurring === 0 &&
        bookObj.parent_booking !== undefined &&
        bookObj.parent_booking.recurring_type !== undefined ? (
          <MDBBadge pill color="primary">
            {bookObj.parent_booking.recurring_type}
          </MDBBadge>
        ) : (
          <></>
        )}
      </div>
    );
  }

  getTzDetails(dt = new Date()) {
    const { locationTimezone } = this.state;
    return extractTimezone(locationTimezone, dt);
  }

  // -------------------- MOBILE CODES ------------------ //
  goToTop() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      collapseID: "",
    }));
  }

  mobileToggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  mobileModalToggleCollapse = (modalCollapseID) => () =>
    this.setState((prevState) => ({
      modalCollapseID:
        prevState.modalCollapseID !== modalCollapseID ? modalCollapseID : "",
    }));

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 500);
  };

  onHover = () => {
    this.setState({
      buttonStyle: {
        transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
        opacity: "1",
      },
    });
  };

  onMouseLeave = () => {
    this.setState({
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0",
      },
    });
  };

  mobileActionButton() {
    const { calendarPrevNavDisable, calendarNextNavDisable } = this.state;
    return (
      <section
      // style={{ height: "1000px" }}
      >
        <MDBBtnFixed
          floating
          onClick={this.onHover}
          onMouseEnter={this.onHover}
          onMouseLeave={this.onMouseLeave}
          size="lg"
          color="blue"
          icon="pencil-alt"
          style={{ bottom: "45px", right: "13px" }}
        >
          <div style={{ position: "relative" }}>
            <MDBBtnFixedItem
              buttonStyle={this.state.buttonStyle}
              color="orange"
              icon="calendar-check"
              onClick={() => this.doGoToToday()}
              onMouseEnter={() => this.clearRefreshCalendarTimer()}
            />
            <div className="action-tooltips">Go to today</div>
          </div>

          <div style={{ position: "relative" }}>
            <MDBBtnFixedItem
              buttonStyle={this.state.buttonStyle}
              color="blue"
              icon="angle-double-left"
              onClick={() => this.calendarNav(false)}
              onMouseEnter={() => this.clearRefreshCalendarTimer()}
              disabled={calendarPrevNavDisable}
            />
            <div className="action-tooltips">Prev. Date</div>
          </div>
          <div style={{ position: "relative" }}>
            <MDBBtnFixedItem
              buttonStyle={this.state.buttonStyle}
              color="blue"
              icon="angle-double-right"
              onClick={() => this.calendarNav(true)}
              onMouseEnter={() => this.clearRefreshCalendarTimer()}
              disabled={calendarNextNavDisable}
            />
            <div className="action-tooltips">Next Date</div>
          </div>
          <div style={{ position: "relative" }}>
            <MDBBtnFixedItem
              onClick={() => this.goToTop()}
              buttonStyle={this.state.buttonStyle}
              color="green"
              icon="window-minimize"
            />
            <div className="action-tooltips">Minimize</div>
          </div>
        </MDBBtnFixed>
      </section>
    );
  }

  mobileHeaderSection() {
    const { courtTypeOptions, surfaceTypeOptions } = this.state;
    return (
      <div className={`calendarHeader`}>
        <MDBRow className={`${this.props.private ? "" : ""}`}>
          <MDBCol md="12">
            <MDBRow>
              <MDBCol md="12">
                <MDBTable borderless className="dateContainer">
                  <MDBTableBody>
                    <tr>
                      <td className="dateNav">{this.mobileRenderNav(false)}</td>
                      <td className="datePicker">{this.datePicker()}</td>
                      <td className="dateNav">{this.mobileRenderNav(true)}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCol>
              {/* <MDBCol sm="2" xs="2">
              {this.mobileRenderNav(false)}
            </MDBCol>
            <MDBCol sm="8" xs="8">
              {this.datePicker()}
            </MDBCol>
            <MDBCol sm="2" xs="2">
              {this.mobileRenderNav(true)}
            </MDBCol> */}

              {/* Disabled filtering for now, Will make it API base filtering */}
              {courtTypeOptions.length > 2 && (
                <MDBCol md="3" style={{ display: "none" }}>
                  <MDBSelect
                    outline
                    color="primary"
                    onClick={this.filterEnable}
                    getValue={this.getValueOfSelectCourtType}
                    getTextContent={this.getValueOfSelectCourtType}
                    options={courtTypeOptions}
                    label="Resource Type"
                  />
                </MDBCol>
              )}
              {surfaceTypeOptions.length > 2 && (
                <MDBCol md="3" style={{ display: "none" }}>
                  <MDBSelect
                    outline
                    color="primary"
                    onClick={this.filterEnable}
                    getValue={this.getValueOfSelectSurfaceType}
                    getTextContent={this.getValueOfSelectSurfaceType}
                    options={surfaceTypeOptions}
                    label="Surface Type"
                  />
                </MDBCol>
              )}
            </MDBRow>
          </MDBCol>
          <MDBCol>
            <div className="float-right">{this.statusIndicator()}</div>
          </MDBCol>
        </MDBRow>
        {/* <span style={{ position: "absolute", bottom: "0", fontSize: "small" }}>
        Location current time:
        <br />
        <strong>
          {moment()
            .tz(this.getTzDetails().timezone)
            .format("DD/MM/YYYY HH:mm")}
        </strong>
      </span> */}
        <MDBRow>
          <MDBCol size="12">
            <div>
              <h6 style={{ position: "absolute", marginTop: "12px" }}>
                {moment(this.props.selectedDate).format("dddd, DD MMMM YYYY")}
              </h6>
            </div>
          </MDBCol>
          <MDBCol size="12" style={{ marginTop: "35px" }}>
            <div className="float-right goToTodayCont">
              <MDBBtn
                size="sm"
                onClick={() =>
                  this.setState({ showTermsAndConditionalModal: true })
                }
              >
                Terms and Conditions
              </MDBBtn>
              {this.renderSubscribeButton()}
              <MDBBtn
                className="goToToday"
                size="sm"
                onClick={() => this.doGoToToday()}
                onMouseEnter={() => this.clearRefreshCalendarTimer()}
              >
                Go to Today
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
        {/* <div className="pricing-note">
        Please click the <strong>View Pricing Button</strong> at the right
        side corner to see pricing details
      </div> */}
      </div>
    );
  }

  subscribe() {
    if (!this.state.private) {
      let params = queryString.parse(this.props.location.search);
      this.props.history.push("/register?uuid=" + params.uuid);
    } else {
      let body = {
        locationId: this.state.locationObj.id,
        subscribe: true,
      };
      this.props.subscribe(body, this.props.authUser.sessionToken);
    }
  }

  renderSubscribeButton() {
    if (this.state.isDefaultUser || !this.state.private) {
      return (
        <MDBBtn
          className=""
          color="success"
          size="sm"
          onClick={() => this.subscribe()}
        >
          Subscribe
        </MDBBtn>
      );
    }
    return <></>;
  }

  mobileRenderNav(isNext) {
    const { calendarPrevNavDisable, calendarNextNavDisable } = this.state;
    if (isNext) {
      return (
        // <MDBBtn
        //   color="grey darken-1"
        //   size="sm"
        //   style={{ margin: "0" }}
        //   onClick={() => this.calendarNav(true)}
        //   onMouseEnter={() => this.clearRefreshCalendarTimer()}
        //   disabled={calendarNextNavDisable}
        // >
        //   <MDBIcon icon="angle-double-right" />
        // </MDBBtn>
        <CalendarNavigation
          calendarNav={this.calendarNav}
          isNext={true}
          defaultState={calendarNextNavDisable}
        />
      );
    } else {
      return (
        // <MDBBtn
        //   color="grey darken-1"
        //   size="sm"
        //   style={{ margin: "0" }}
        //   onClick={() => this.calendarNav(false)}
        //   onMouseEnter={() => this.clearRefreshCalendarTimer()}
        //   disabled={calendarPrevNavDisable}
        // >
        //   <MDBIcon icon="angle-double-left" />
        // </MDBBtn>
        <CalendarNavigation
          calendarNav={this.calendarNav}
          isNext={false}
          defaultState={calendarPrevNavDisable}
        />
      );
    }
  }

  mobileResourceAcordtion() {
    const { collapseID, tableElem } = this.state;
    let elements = [];
    _.map(tableElem, (elem) => {
      elements.push(
        <MDBCard className="my-1" key={"cid-" + elem.collapseId}>
          <MDBCollapseHeader
            className="grey lighten-3"
            onClick={this.mobileToggleCollapse(elem.collapseId)}
          >
            <span className="">{elem.title}</span>
            <MDBIcon
              icon={collapseID === elem.collapseId ? "angle-up" : "angle-down"}
              className=""
              style={{ float: "right" }}
            />
          </MDBCollapseHeader>
          <MDBCollapse id={elem.collapseId} isOpen={collapseID}>
            <MDBTable bordered>{elem.elem}</MDBTable>
          </MDBCollapse>
        </MDBCard>
      );
    });

    return (
      <div>
        <h5 className="mb-3">Please select resource</h5>
        {elements}
        {!this.state.isScrolling && this.mobileActionButton()}
      </div>
    );
  }

  mobileBuildTableElem(generatedTimeContainer, locationResourcesContainer) {
    const { collapseID } = this.state;
    let genTimeCount = generatedTimeContainer.length + 1;
    let generatedTimeArr = generatedTimeContainer;
    let tableElem = [];

    _.map(locationResourcesContainer.results, (results) => {
      let tableBodyElem = [];
      for (let i = 0; i < genTimeCount; i++) {
        let thElem = [];
        let tbHeadElem = [];
        for (let j = 0; j < 2; j++) {
          if (i > 0) {
            if (j === 0) {
              thElem.push(
                this.renderTDmobile(
                  generatedTimeArr[i - 1],
                  "td-" + i + "-" + j,
                  20
                )
              );
            } else {
              let paramData = {
                tdWidth: "100",
                location: results.location,
                court: results.name,
                time: generatedTimeArr[i - 1],
                generatedTimeArr: generatedTimeArr,
                key: "td-" + i + "-" + j,
                maxBookingParticipants: results.attributes.max_participants,
                defaultStartTime: results.attributes.default_start_time,
                defaultEndTime: results.attributes.default_end_time,
                resourceUuid: results.uuid,
                resourceId: results.id,

                durationPerChunk: results.attributes.duration_per_chunk,
                maxDuration: results.attributes.max_duration,
                minDuration: results.attributes.min_duration,
                allowBookingSameTime:
                  typeof results.attributes.book_at_same_time == "undefined"
                    ? ""
                    : results.attributes.book_at_same_time,
                allowStartTime:
                  typeof results.attributes.allowed_start_time == "undefined"
                    ? ""
                    : parseInt(
                        results.attributes.allowed_start_time.replace(":", "")
                      ),
                allowEndTime:
                  typeof results.attributes.allow_end_time == "undefined"
                    ? ""
                    : parseInt(
                        results.attributes.allow_end_time.replace(":", "")
                      ),
              };
              if (i === 1) {
                thElem.push(this.renderTDContainer(paramData, genTimeCount));
              }
            }

            if (j === 1) {
              tbHeadElem.push(
                <tr
                  className={`tr-fix-height text-center ${
                    this.state.private && i % 2 !== 0
                      ? "table-border-bottom-dotted"
                      : ""
                  }`}
                  key={"bodyTr-" + i + "-" + j}
                >
                  {thElem}
                </tr>
              );
              tableBodyElem.push(tbHeadElem);
              thElem = [];
              tbHeadElem = [];
            }
          }
        }
      }

      tableElem.push(
        {
          collapseId: "collapse-" + results.id,
          title: results.name,
          elem: tableBodyElem,
        }
        // <MDBCard className="my-1">
        //   <MDBCollapseHeader
        //     className="grey lighten-3"
        //     onClick={this.mobileToggleCollapse("collapse-" + results.id)}
        //   >
        //     <span className="">{results.name}</span>
        //     <MDBIcon
        //       icon={
        //         collapseID === "collapse-" + results.id
        //           ? "angle-up"
        //           : "angle-down"
        //       }
        //       className=""
        //       style={{ float: "right" }}
        //     />
        //   </MDBCollapseHeader>
        //   <MDBCollapse id={"collapse-" + results.id} isOpen={collapseID}>
        //     <MDBTable bordered>{tableBodyElem}</MDBTable>
        //   </MDBCollapse>
        // </MDBCard>
      );
    });

    // tableElem.push(
    //   <MDBTableBody key={"body-key"}>{tableBodyElem}</MDBTableBody>
    // );

    return tableElem;
  }

  renderMobileViewContainer() {
    return (
      <div className="mobile-view">
        <MDBTable bordered>
          <MDBTableHead>
            <tr>
              <th>{this.mobileHeaderSection()}</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td>{this.mobileResourceAcordtion()}</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </div>
    );
  }

  // -------------------- MOBILE CODES ------------------ //

  buildTableElem(generatedTimeContainer, locationResourcesContainer) {
    let genTimeCount = generatedTimeContainer.length + 1;
    let totalResult = locationResourcesContainer.results.length + 2;
    let results = locationResourcesContainer.results;
    let generatedTimeArr = generatedTimeContainer;
    let tableElem = [];
    let tableBodyElem = [];
    let maxWidth = 76;
    let tdWidth = maxWidth / results.length;
    this.popupStatusClass = "";

    // if (tdWidth < 21) {
    this.popupStatusClass = "popup-status";
    // }
    // console.log("genTimeCount>>>>>>>>>>>>>" + genTimeCount);
    for (let i = 0; i < genTimeCount; i++) {
      let thElem = [];
      let tbHeadElem = [];
      for (let j = 0; j < totalResult; j++) {
        //-- build header
        if (i === 0) {
          if (j === 0 || j === totalResult - 1) {
            thElem.push(
              this.renderTHNav(
                "",
                "th-" + i + "-" + j,
                "fix-size-calendar-timeslot",
                j === 0 ? false : true
              )
            );
          } else {
            thElem.push(
              this.renderTH(
                results[j - 1].name,
                "th-" + i + "-" + j,
                "",
                tdWidth
              )
            );
          }

          if (j === totalResult - 1) {
            tbHeadElem.push(
              <MDBTableHead key={"tableHead-" + i + "-" + j}>
                <tr className="tr-fix-height">
                  <th colSpan={totalResult}>{this.headerSection()}</th>
                </tr>
                <tr
                  className={`text-center ${
                    this.state.private ? "table-border-bottom-dotted" : ""
                  }`}
                >
                  {thElem}
                </tr>
              </MDBTableHead>
            );
            tableElem.push(tbHeadElem);
            thElem = [];
            tbHeadElem = [];
          }
        } else {
          if (j === 0 || j === totalResult - 1) {
            thElem.push(
              this.renderTD(generatedTimeArr[i - 1], "td-" + i + "-" + j)
            );
          } else {
            let paramData = {
              tdWidth: tdWidth,
              // resources: results,
              location: results[j - 1].location,
              court: results[j - 1].name,
              // bookings: results[j - 1].bookings,
              time: generatedTimeArr[i - 1],
              generatedTimeArr: generatedTimeArr,
              key: "td-" + i + "-" + j,
              maxBookingParticipants:
                results[j - 1].attributes.max_participants,
              defaultStartTime: results[j - 1].attributes.default_start_time,
              defaultEndTime: results[j - 1].attributes.default_end_time,
              resourceUuid: results[j - 1].uuid,
              resourceId: results[j - 1].id,

              durationPerChunk: results[j - 1].attributes.duration_per_chunk,
              maxDuration: results[j - 1].attributes.max_duration,
              minDuration: results[j - 1].attributes.min_duration,
              // locked: results[j - 1].locked,
              // recurrings: results[j - 1].recurrings,
              allowBookingSameTime:
                typeof results[j - 1].attributes.book_at_same_time ==
                "undefined"
                  ? ""
                  : results[j - 1].attributes.book_at_same_time,
              allowStartTime:
                typeof results[j - 1].attributes.allowed_start_time ==
                "undefined"
                  ? ""
                  : parseInt(
                      results[j - 1].attributes.allowed_start_time.replace(
                        ":",
                        ""
                      )
                    ),
              allowEndTime:
                typeof results[j - 1].attributes.allow_end_time == "undefined"
                  ? ""
                  : parseInt(
                      results[j - 1].attributes.allow_end_time.replace(":", "")
                    ),
            };
            if (j < totalResult - 1 && i === 1) {
              thElem.push(this.renderTDContainer(paramData, genTimeCount));
            }
          }

          if (j === totalResult - 1) {
            tbHeadElem.push(
              <tr
                className={`tr-fix-height text-center ${
                  this.state.private && i % 2 !== 0
                    ? "table-border-bottom-dotted"
                    : ""
                }`}
                key={"bodyTr-" + i + "-" + j}
              >
                {thElem}
              </tr>
            );
            tableBodyElem.push(tbHeadElem);
            thElem = [];
            tbHeadElem = [];
          }
        }
      }
    }

    tableElem.push(
      <MDBTableBody key={"body-key"}>{tableBodyElem}</MDBTableBody>
    );

    return tableElem;
  }

  doGoToToday() {
    let date = new Date();
    this.props.selectDate(date);
    this.setState({
      isClientSideFilter: false,
      ClientSidefilterCompleted: false,
      locationResourcesLoaded: false,
    });
    this.debouncedSelectedDate(date);
  }

  renderContent() {
    const { courtTypeOptions, surfaceTypeOptions, tableElemForMobile } =
      this.state;
    const { browserSize } = this.props;

    if (
      this.state.generatedTimeContainer.length > 0 &&
      browserSize.lessThan.medium &&
      tableElemForMobile
    ) {
      return (
        <div
          className={`mobile-view weeklycalendar-mobile  ${
            courtTypeOptions.length > 2 ? "ct" : ""
          } ${surfaceTypeOptions.length > 2 ? "st" : ""}`}
        >
          {this.renderMobileViewContainer()}
        </div>
      );
    }

    if (
      this.state.generatedTimeContainer.length > 0 &&
      !browserSize.lessThan.medium &&
      !tableElemForMobile
    ) {
      return (
        <div>
          <MDBTable
            bordered
            className={`${this.popupStatusClass} weeklycalendar  ${
              courtTypeOptions.length > 2 ? "ct" : ""
            } ${surfaceTypeOptions.length > 2 ? "st" : ""}`}
          >
            {this.state.tableElem}
          </MDBTable>
        </div>
      );
    } else if (
      this.state.locationResourcesLoaded &&
      this.state.generatedTimeContainer.length === 0
    ) {
      return (
        <div className="text-center">
          <h5>No resource found.</h5>
        </div>
      );
    }
    return <div className="text-center">Loading...</div>;
  }

  revalidateComponent() {
    let params = queryString.parse(this.props.location.search);
    this.props.getLocationResources(params.uuid, this.state.searchDateVal);
    return <span></span>;
  }

  validateRenderingOfIdleTimer() {
    if (!this.props.private) {
      return (
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={this.state.idleTime}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
      );
    } else {
      return <></>;
    }
  }

  renderTermsAndConditions() {
    let location = {};
    if (
      !(
        this.state.locationObj === undefined ||
        Object.keys(this.state.locationObj).length === 0
      )
    ) {
      location = this.state.locationObj;
    } else if (this.props.locations.results !== undefined) {
      location = this.props.locations.results[0];
    }

    if (Object.keys(location).length !== 0) {
      return (
        <RichTextRenderer
          doScrollY={true}
          htmlString={location.attributes.terms_and_conditions}
        />
      );
    }
    return <div className="text-center">Loading...</div>;
  }
  onChangeTermsAndConditionCheckbox = (evt) => {
    this.setState({ iAggree: !this.state.iAggree });
  };

  renderTermsAndConditionModal() {
    const { showTermsAndConditionalModal } = this.state;

    if (showTermsAndConditionalModal) {
      return (
        <div>
          <MDBModal
            ref={this.modal}
            isOpen={true}
            size="lg"
            toggle={this.toggle}
          >
            <MDBModalBody>{this.renderTermsAndConditions()}</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() =>
                  this.setState({ showTermsAndConditionalModal: false })
                }
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </div>
      );
    } else {
      return <span></span>;
    }
  }

  showAddCustomerAsFavouriteModal = (source) => {
    let attr = {
      show: true,
      action: "add",
      parent: this.state.componentName,
      payload: source,
    };
    this.props.genModalFavouriteCustomer(attr);
  };

  render() {
    return (
      <div>
        <FavouriteCustomerModal />
        <BookingTimeslotNotificationModal
          toggleNotificationModal={this.toggleBookingTimeslotNotificationModal}
          isShowModal={this.state.isShowBookingTimeslotNotificationModal}
          selectedTimeslot={this.state.selectedTimeslotforBookingNotification}
          locationId={this.state.selectedTimeslotLocation}
        />

        {this.validateRenderingOfIdleTimer()}
        {/* {this.renderPricingDetails()} */}
        {this.renderContent()}
        {this.renderBookingModalForm()}
        {this.renderBookingDetailsForm()}
        {this.renderTermsAndConditionModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    locationResources: state.locationResources,
    selectedDate: state.selectedDate,
    locations: state.locations,
    bookingDetails: state.bookingDetails,
    authUser: state.authUser,
    paymentRequestSuccess: state.paymentRequestSuccess,
    paymentRequestFailed: state.paymentRequestFailed,
    bookingSuccessResponse: state.bookingSuccessResponse,
    bookingFailedResponse: state.bookingFailedResponse,
    successHandlerResponse: state.successHandlerResponse,
    failedHandlerResponse: state.failedHandlerResponse,
    getStatusesSuccessResponse: state.getStatusesSuccessResponse,
    getStatusesFailedResponse: state.getStatusesFailedResponse,
    getBookingStatusSuccessResponse: state.getBookingStatusSuccessResponse,
    getBookingStatusFailedResponse: state.getBookingStatusFailedResponse,
    genCustomerVoucherSuccessResponse: state.genCustomerVoucherSuccessResponse,
    genCustomerVoucherFailedResponse: state.genCustomerVoucherFailedResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getLocationListFailedResponse: state.getLocationListFailedResponse,
    customerResponse: state.customerResponse,
    isCloseBooking: state.closeBooking,
    bookingResponse: state.bookingResponse,
    browserSize: state.browserSize,
    storeUserAccess: state.storeUserAccess,
    userResponse: state.userResponse,
    userFavouriteResponse: state.userFavouriteResponse,
    addedCustomerParticipantResponse: state.addedCustomerParticipantResponse,
    resourceResponse: state.resourceResponse,
    paymentResponse: state.paymentResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleModal,
    getLocationResources,
    selectDate,
    searchLocations,
    setBookingDetails,
    showHideLoader,
    getLocationResourcesPrivate,
    requestPayment,
    clearBookingSuccessResponse,
    clearBookingFailedResponse,
    doBooking,
    updateBooking,
    calculatePrice,
    getStatuses,
    clearStatusSuccessResponse,
    clearStatusFailedResponse,
    getBookingStatus,
    clearBookingStatusSuccessResponse,
    clearBookingStatusFailedResponse,
    generateCustomerVoucher,
    clearGenCustomerVoucherSuccessResponse,
    clearGenCustomerVoucherFailedResponse,
    getLocationList,
    showNotification,
    clearNotification,
    getCustomers,
    validateCustomerDetails,
    closeBooking,
    bookingChangeSchedule,
    checkBookingConflicts,
    validateAddedCustomer,
    subscribe,
    pauseBackgroundRequests,
    disableNextNav,
    disablePrevNav,
    getUserFavourites,
    deleteFavouriteCustomer,
    genModalFavouriteCustomer,
    lockBookingTimeslot,
    getMemberAdvanceBookingDay,
  })(WeeklyCalendar)
);
