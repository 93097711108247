export default (
  layout = {
    showBanner: true,
    showFooter: true,
    showSearch: true,
    showHeader: true,
    showLogin: true,
  },
  action
) => {
  if (action.type === "VALIDATE_LAYOUT") {
    return action.payload;
  }
  return layout;
};
