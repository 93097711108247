import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBSwitch } from "mdbreact";
import moment, { utc } from "moment";

class CalendarDateName extends React.Component {
  componentDidMount() {
    // this.props.onSwitch(true);
    // console.log;
    // console.log("check !this.props.setSwitchValResponse");
    // console.log(this.props.setSwitchValResponse);
  }

  render() {
    return (
      <div className="text-center">
        <h4 style={{ marginBottom: "0", marginTop: "-29px" }}>
          {moment(this.props.selectedDate).format("dddd, DD MMMM YYYY")}
        </h4>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("this.state.switch" + this.state.switch);
    // console.log("check !this.props.setSwitchValResponse");
    // console.log(this.props.setSwitchValResponse);
  }
}

const mapStateToProps = (state) => {
  return { selectedDate: state.selectedDate };
};

export default connect(mapStateToProps, {})(CalendarDateName);
