import React from "react";
import { connect } from "react-redux";
import {
  MDBFormInline,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdbreact";
import _ from "lodash";
import GenericSearchResult from "../shared/GenericSearchResult";

import {
  GEN_SEARCH_VOUCHER,
  GEN_SEARCH_BOOKING,
  GEN_SEARCH_CUSTOMER,
} from "../../../actions/types";

class GenericSearch extends React.Component {
  state = {
    showSearchModal: false,
    query: "b",
  };

  componentDidMount() {}

  doFocus() {
    this.inputRef.focus();
  }

  onChange = (evt) => {
    this.debouncedSearch(evt.target.value);
  };

  debouncedSearch = _.debounce(function (query) {
    this.setState({ query: query });
  }, 1000);

  renderContent() {
    return this.renderModal();
  }

  toggleSearchModal = () => {
    const { showSearchModal } = this.state;
    this.setState({ showSearchModal: !showSearchModal });
    this.props.callback(!showSearchModal);
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log("showSearchModal");
    // console.log(this.props.showSearchModal);

    // if (prevProps.showSearchModal !== showSearchModal && showSearchModal) {
    //   this.debounceDoFocus();
    // }

    if (prevProps.showSearchModal !== this.props.showSearchModal) {
      this.setState({ showSearchModal: this.props.showSearchModal });
      if (this.props.showSearchModal) {
        console.log("focus");
        this.debounceDoFocus();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.authResponse !== this.props.authResponse &&
      nextProps.authResponse.type == "HEART_BEAT_RESPONSE_HANDLER"
    ) {
      return false;
    }
    return true;
  }

  debounceDoFocus = _.debounce(function () {
    this.doFocus();
  }, 400);

  renderModal() {
    const { showSearchModal, query } = this.state;
    return (
      <div style={{ color: "#212529" }}>
        <MDBModal
          isOpen={showSearchModal}
          toggle={this.toggleSearchModal}
          size="lg"
        >
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="12">
                <input
                  className="form-control"
                  onChange={this.onChange}
                  type="text"
                  ref={(input) => {
                    this.inputRef = input;
                  }}
                />
                <span>
                  Please enter your search keyword in the search box to start
                  searching
                </span>
              </MDBCol>
              <MDBCol md="12" className="mt-4">
                <GenericSearchResult
                  category="Bookings"
                  type={GEN_SEARCH_BOOKING}
                  query={query}
                />
                <GenericSearchResult
                  category="Customers"
                  type={GEN_SEARCH_CUSTOMER}
                  query={query}
                />
                <GenericSearchResult
                  category="Vouchers"
                  type={GEN_SEARCH_VOUCHER}
                  query={query}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleSearchModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { authResponse: state.authResponse };
};

export default connect(mapStateToProps, {})(GenericSearch);
