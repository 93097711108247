import {
  VALIDATE_LAYOUT,
  TOGGLE_MODAL,
  ADD_MODAL_ATTR,
  VALIDATE_SEARCH,
  SELECT_DATE,
  ALERT_MESSAGE,
  SHOW_HIDE_LOADER,
  DISPLAY_BREADCRUMBS,
  REDIRECTED,
  SUCCESS_HANDLER,
  FAILED_HANDLER,
  REVALIDATE_COMPONENT,
  SET_VAL,
  SHOW_NOTIFICATION,
  CLOSE_BOOKING,
  BOOKING_CHANGE_SCHEDULE,
  GEN_MODAL_CUSTOMER,
  GEN_MODAL_FAVOURITE_CUSTOMER,
  GEN_MODAL_VOUCHER,
  GEN_MODAL_ROLE,
  GEN_MODAL_ROLE_ACCESS,
  STORE_USER_ACCESS,
  GEN_MODAL_SUBSCRIBE_MORE_DETAILS,
  ACTIVE_COMPONENT,
  SWITCH_VAL,
  DISABLE_PARENT_CALENDAR_TIMER,
  PAUSE_BACKGROUND_REQUESTS,
  GEN_MODAL_RULES_ENGINE,
  GEN_MODAL_RULE_CONDITION_EVALUATOR,
  GEN_MODAL_RULE_TRIGGER,
  SET_WIZARD_STEP,
  SET_INTERVAL_ID,
  SET_STRIPE_CONFIRM_PAYMENT_RESPONSE,
} from "./types";

export const validateLayout = (layout) => {
  return {
    type: VALIDATE_LAYOUT,
    payload: layout,
  };
};

export const bookingChangeSchedule = (obj) => {
  return {
    type: BOOKING_CHANGE_SCHEDULE,
    payload: obj,
  };
};

export const closeBooking = (isClose = false) => {
  return {
    type: CLOSE_BOOKING,
    payload: isClose,
  };
};

export const toggleModal = (modal) => {
  if (
    modal.isOpen &&
    (modal.content === undefined ||
      (modal.content !== undefined && modal.content.length === 0))
  ) {
    modal["isOpen"] = false;
  }

  return {
    type: TOGGLE_MODAL,
    payload: modal,
  };
};

export const addModalAttr = (attr) => {
  return {
    type: ADD_MODAL_ATTR,
    payload: attr,
  };
};

export const validateSearch = (search) => {
  return {
    type: VALIDATE_SEARCH,
    payload: search,
  };
};

export const selectDate = (date) => {
  return {
    type: SELECT_DATE,
    payload: date,
  };
};

export const alertMessage = (alert) => {
  return {
    type: ALERT_MESSAGE,
    payload: alert,
  };
};

export const showHideLoader = (isShow) => {
  return {
    type: SHOW_HIDE_LOADER,
    payload: isShow,
  };
};

export const showNotification = (type, message, timeout = 5000) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      type: type,
      message: message,
      timeout: timeout,
    },
  };
};

export const clearNotification = () => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      type: "",
      message: "",
    },
  };
};

export const displayBreadcrumbs = (val) => {
  return {
    type: DISPLAY_BREADCRUMBS,
    payload: val,
  };
};

export const doRedirected = (val) => {
  return {
    type: REDIRECTED,
    payload: val,
  };
};

export const clearSuccessHandler = () => {
  return {
    type: SUCCESS_HANDLER,
    payload: null,
  };
};

export const clearFailedHandler = () => {
  return {
    type: FAILED_HANDLER,
    payload: null,
  };
};

export const revalidateComponent = () => {
  return {
    type: REVALIDATE_COMPONENT,
    payload: Math.random(),
  };
};

export const setVal = (val) => {
  return {
    type: SET_VAL,
    payload: val,
  };
};

export const genModalCustomer = (val) => {
  return {
    type: GEN_MODAL_CUSTOMER,
    payload: val,
  };
};

export const genModalFavouriteCustomer = (val) => {
  return {
    type: GEN_MODAL_FAVOURITE_CUSTOMER,
    payload: val,
  };
};

export const genModalVoucher = (val) => {
  return {
    type: GEN_MODAL_VOUCHER,
    payload: val,
  };
};

export const genModalRole = (val) => {
  return {
    type: GEN_MODAL_ROLE,
    payload: val,
  };
};

export const genModalRoleAccess = (val) => {
  return {
    type: GEN_MODAL_ROLE_ACCESS,
    payload: val,
  };
};

export const genModalSubscriptionMoreDetails = (val) => {
  return {
    type: GEN_MODAL_SUBSCRIBE_MORE_DETAILS,
    payload: val,
  };
};

export const storeUserAccess = (val) => {
  return {
    type: STORE_USER_ACCESS,
    payload: val,
  };
};

export const clearStoreUserAccess = () => {
  return {
    type: STORE_USER_ACCESS,
    payload: undefined,
  };
};

export const setActiveComponent = (val) => {
  return {
    type: ACTIVE_COMPONENT,
    payload: val,
  };
};

export const setSwitch = (val) => {
  return {
    type: SWITCH_VAL,
    payload: val,
  };
};

export const disableParentCalendarTimer = (val) => {
  return {
    type: DISABLE_PARENT_CALENDAR_TIMER,
    payload: val,
  };
};

export const pauseBackgroundRequests = (val) => {
  return {
    type: PAUSE_BACKGROUND_REQUESTS,
    payload: val,
  };
};

export const genModalRulesEngine = (val) => {
  return {
    type: GEN_MODAL_RULES_ENGINE,
    payload: val,
  };
};

export const genModalRuleConditionEvaluator = (val) => {
  return {
    type: GEN_MODAL_RULE_CONDITION_EVALUATOR,
    payload: val,
  };
};

export const genModalRuleTrigger = (val) => {
  return {
    type: GEN_MODAL_RULE_TRIGGER,
    payload: val,
  };
};

export const setWizardStep = (val) => {
  return {
    type: SET_WIZARD_STEP,
    payload: val,
  };
};

export const setIntervalId = (val) => {
  return {
    type: SET_INTERVAL_ID,
    payload: val,
  };
};

export const setStripePaymentConfirmResponse = (val) => {
  return {
    type: SET_STRIPE_CONFIRM_PAYMENT_RESPONSE,
    payload: val,
  };
};
