import {
  MDBBtn,
  MDBCollapse,
  MDBContainer,
  MDBMask,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
  MDBView,
  MDBIcon,
} from "mdbreact";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { validateLayout } from "../../../actions";
import BannerImage from "../assets/banner3.jpg";
import logo from "../assets/ublogo.png";
import SearchField from "./SearchField";

class Header extends React.Component {
  state = {
    publicNotice: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  showSearch() {
    if (this.props.layout.showSearch) {
      return <SearchField />;
    }
  }

  showLogin() {
    if (this.props.layout.showLogin) {
      return (
        <MDBNavItem>
          <MDBNavLink className="menu-item-box" to="/login">
            Login
          </MDBNavLink>
        </MDBNavItem>
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("check location");
    if (prevProps.locations !== this.props.locations) {
      if (
        this.props.locations.results !== undefined &&
        this.props.locations.results.length > 0
      ) {
        let location = this.props.locations.results[0];
        this.setState({
          publicNotice:
            location.attributes.general_configurations.public_notice,
        });
      }
    }
  }

  validateRender() {
    if (this.props.layout.showBanner) {
      return (
        <div>
          <header>
            <MDBView className="banner" src={BannerImage}>
              <MDBNavbar expand="md" color="grey lighten-4">
                <MDBContainer>
                  <MDBNavbarBrand>
                    <Link to="/">
                      <img src={logo} style={{ height: "35px" }} />
                    </Link>
                  </MDBNavbarBrand>
                  <MDBNavbarToggler onClick={this.onClick} />
                  <MDBCollapse
                    isOpen={this.state.collapse}
                    navbar
                    className="custom-navbar"
                  >
                    <MDBNavbarNav right>
                      <MDBNavItem active>
                        <MDBNavLink to="/">Home</MDBNavLink>
                      </MDBNavItem>
                      {this.showLogin()}
                    </MDBNavbarNav>
                  </MDBCollapse>
                </MDBContainer>
              </MDBNavbar>

              <MDBMask
                overlay="indigo-slight"
                className="flex-center flex-column text-white text-center"
              >
                <h2
                  className="mb-5"
                  style={{
                    fontWeight: "bold",
                    background: "#3233357d",
                    padding: "2px",
                  }}
                >
                  Choose your venues and start booking now!{" "}
                </h2>
                {this.state.publicNotice && (
                  <h5
                    key="publicNoticeKey"
                    className="color-yellow"
                    style={{
                      fontWeight: "bold",
                      background: "#3233357d",
                      padding: "2px",
                      // display: "none",
                    }}
                  >
                    {this.state.publicNotice}
                  </h5>
                )}
                {this.showSearch()}
                <br />
                <MDBNavLink to="/register">
                  <MDBBtn rounded size="lg">
                    Sign up now!
                  </MDBBtn>
                </MDBNavLink>
              </MDBMask>
            </MDBView>
          </header>
        </div>
      );
    } else {
      return (
        <div>
          <header>
            <MDBNavbar expand="md" color="grey lighten-4">
              <MDBContainer>
                <MDBNavbarBrand>
                  <Link to="/">
                    <img src={logo} style={{ height: "35px" }} />
                  </Link>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={this.onClick} />
                <MDBCollapse
                  isOpen={this.state.collapse}
                  navbar
                  className="custom-navbar"
                >
                  <MDBNavbarNav right>
                    <MDBNavItem active>
                      <MDBNavLink to="/">Home</MDBNavLink>
                    </MDBNavItem>
                    {this.showLogin()}
                  </MDBNavbarNav>
                </MDBCollapse>
              </MDBContainer>
            </MDBNavbar>

            {this.showSearch()}
          </header>
        </div>
      );
    }
  }

  render() {
    return this.validateRender();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout, locations: state.locations };
};

export default connect(mapStateToProps, { validateLayout })(Header);
