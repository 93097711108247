import React from "react";
import { MDBContainer } from "mdbreact";

class VenueDetails extends React.Component {
  renderContent() {
    return (
      <div>
        <MDBContainer>
          <h5>Details:</h5>
          <p className="text-justify">
            <strong>
              Venue details like location, duractions, pricing etc.
            </strong>{" "}
            Ambitioni dedisse scripsisse iudicaretur. Cras mattis iudicium purus
            sit amet fermentum. Donec sed odio operae, eu vulputate felis
            rhoncus. Praeterea iter est quasdam res quas ex communi. At nos hinc
            posthac, sitientis piros Afros. Petierunt uti sibi concilium totius
            Galliae in diem certam indicere. Cras mattis iudicium purus sit amet
            fermentum.
          </p>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default VenueDetails;
