import { SET_USER_AUTH } from "../actions/types";

export default (
  auth = {
    username: "",
    sessionToken: "",
    isLoggedIn: "",
    domain: "",
    organisations: [],
  },
  action
) => {
  if (action.type === SET_USER_AUTH) {
    return action.payload;
  }
  return auth;
};
