import {
  GEN_SEARCH_VOUCHER,
  GEN_SEARCH_BOOKING,
  GEN_SEARCH_CUSTOMER,
  GEN_SEARCH_FAILED_HANDLER,
} from "./types";
import bookingAppPrivate from "../apis/bookingAppPrivate";

export const genSearchVoucher = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };
  bookingAppPrivate
    .get(`/vouchers`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GEN_SEARCH_VOUCHER,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: GEN_SEARCH_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const genSearchBooking = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/bookings`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GEN_SEARCH_BOOKING,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: GEN_SEARCH_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const genSearchCustomer = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };

  bookingAppPrivate
    .get(`/customers`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GEN_SEARCH_CUSTOMER,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: GEN_SEARCH_FAILED_HANDLER,
        payload: errRes,
      });
    });
};
