import { ADD_MODAL_ATTR } from "../actions/types";

export default (
  attr = {
    showAlert: false,
    alertType: "",
    alertMsg: "",
  },
  action
) => {
  if (action.type === ADD_MODAL_ATTR) {
    return action.payload;
  }
  return attr;
};
