import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MaintenanceImage from "../modules/assets/under_maintenance.png";
import { validateLayout } from "../../actions";

import { MDBContainer } from "mdbreact";

const MaintenancePage = (props) => {
  useEffect(() => {
    //Hide the headers and footer
    if (props.authUser.sessionToken === "") {
      const layout = {
        showBanner: false,
        showFooter: true,
        showSearch: false,
        showHeader: true,
        showLogin: false,
      };
      props.validateLayout(layout);
    }
  }, []);

  return (
    <MDBContainer fluid={true} className="mt-4 ">
      <h1 className="text-center font-weight-bold">
        <img className="text-center" width="50%" src={MaintenanceImage}></img>
      </h1>
    </MDBContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    validateLayout,
  })(MaintenancePage)
);
