import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBDataTable,
  MDBAutoV5,
  MDBSelectV5,
  MDBSelect,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtnGroup,
} from "mdbreact";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import {
  displayBreadcrumbs,
  alertMessage,
  showHideLoader,
  toggleModal,
  addModalAttr,
} from "../../../actions";
import {
  getLocationList,
  searchLocationsPrivate,
  getLocationMenu,
} from "../../../actions/locationAction";
import {
  getResourceList,
  clearGetResourceListSuccessResponse,
  clearGetResourceListFailedResponse,
  insertResource,
  clearInsertResourceSuccessResponse,
  clearInsertResourceFailedResponse,
  updateResource,
  clearUpdateResourceSuccessResponse,
  clearUpdateResourceFailedResponse,
  deleteResource,
  clearDeleteResourceSuccessResponse,
  clearDeleteResourceFailedResponse,
} from "../../../actions/resourceAction";

import {
  getAttributeKeys,
  clearGetAttrKeysSuccessResponse,
  clearGetAttrKeysFailedResponse,
  getAttributes,
  clearGetAttributesSuccessResponse,
  clearGetAttributesFailedResponse,
  insertAttribute,
  clearInsertAttrSuccessResponse,
  clearInsertAttrFailedResponse,
  updateAttribute,
  clearUpdateAttrSuccessResponse,
  clearUpdateAttrFailedResponse,
  insertAttrKey,
  clearInsertAttrKeySuccessResponse,
  clearInsertAttrKeyFailedResponse,
  getAttrTypes,
  clearGetAttrTypesSuccessResponse,
  clearGetAttrTypesFailedResponse,
} from "../../../actions/attributeAction";

import _ from "lodash";
import moment from "moment";
import Modal from "../shared/Modal";
import { validateAccess } from "../../../helper/utils";

class ManageResource extends React.Component {
  state = {
    type: "resource",
    resourceId: "",
    resourceUuid: "",
    resourceName: "",
    locationUuid: "",
    locationId: "",
    locationName: "",

    name: "",
    isActive: false,
    displayOnline: false,
    domain: "",
    formCleared: false,
    wasValidated: "",
    showModal: false,
    isModalTrigger: false,
    isEdit: false,

    showAttributeModal: false,
    resultContainer: {},
    locationAttributeKeysContainer: {},

    limit: "10",
    query: "",
    page: "1",
    isActiveFilter: false,
    displayOnlineFilter: false,
    limitOptions: [],

    attrIsEdit: false,
    attrWasValidated: "",
    selectAttrKeyId: "",
    attrLimit: "10",
    attrQuery: "",
    attrPage: "1",
    attrValue: "",
    locationName: "",
    attrId: "",
    isFilterable: false,

    attrKeyName: "",
    attrKeyDisplayName: "",
    // -- TODO should be populate from api
    typeId: "",
    displayChildModal: false,
    childModalAlertType: "",
    childModalAlertMsg: "",
  };

  submitHandler = (event) => {
    const {
      name,
      isActive,
      displayOnline,
      isEdit,
      resourceId,
      locationId,
    } = this.state;
    event.preventDefault();
    this.clearResourceAlert();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      let body = {
        locationId: locationId,
        name: name,
        displayOnline: displayOnline,
        isActive: isActive,
      };
      if (isEdit) {
        this.props.updateResource(
          body,
          resourceId,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertResource(body, this.props.authUser.sessionToken);
      }
    }
  };

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  searchChangeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    this.debouncedDoFilter();
  };

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  componentDidMount() {
    this.modalConfig();
    this.props.displayBreadcrumbs("Dashboard / Administrator / Resource");
    this.props.searchLocationsPrivate("", this.props.authUser.sessionToken);

    let qs = queryString.parse(this.props.location.search);

    this.props.getLocationList(
      { q: qs.uuid },
      this.props.authUser.sessionToken
    );

    this.props.getResourceList(
      { locationUuid: qs.uuid },
      this.props.authUser.sessionToken
    );
    this.initLimitOptions();
    this.props.getAttrTypes({}, this.props.authUser.sessionToken);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getResourceListSuccessResponse !==
      this.props.getResourceListSuccessResponse &&
      this.props.getResourceListSuccessResponse.status === 200
    ) {
      this.setState({
        resultContainer: this.props.getResourceListSuccessResponse,
      });
    }

    if (
      prevProps.getLocationListSuccessResponse !==
      this.props.getLocationListSuccessResponse &&
      this.props.getLocationListSuccessResponse.status === 200
    ) {
      this.setState({
        locationId: this.props.getLocationListSuccessResponse.data.locations
          .results[0].id,
        locationUuid: this.props.getLocationListSuccessResponse.data.locations
          .results[0].uuid,
        locationName: this.props.getLocationListSuccessResponse.data.locations
          .results[0].name,
      });
    }

    if (
      prevProps.getAttrKeysSuccessResponse !==
      this.props.getAttrKeysSuccessResponse &&
      this.props.getAttrKeysSuccessResponse.status === 200 &&
      this.state.showAttributeModal
    ) {
      this.setState({
        locationAttributeKeysContainer: this.props.getAttrKeysSuccessResponse,
      });
    }

    if (prevState.attrKey !== this.state.setAttrKey) {
      this.setState({ attrKey: this.state.setAttrKey });
    }

    this.validateDeleteLocationResponse(prevProps, prevState);
    this.validateInsertAttrKeyResponse(prevProps, prevState);
    this.getTypeId(prevProps, prevState);
  }

  getTypeId(prevProps, prevState) {
    const { type } = this.state;
    if (
      prevProps.getAttrTypesSuccessResponse !==
      this.props.getAttrTypesSuccessResponse &&
      this.props.getAttrTypesSuccessResponse.status === 200
    ) {
      let typeId = _.chain(this.props.getAttrTypesSuccessResponse.data.types)
        .map((t) => {
          if (t.name === type) {
            return t.id + "";
          }
          return "";
        })
        .filter(_.size)
        .value()[0];

      this.setState({ typeId: typeId });
    }
  }

  validateInsertAttrKeyResponse(prevProps, prevState) {
    if (
      prevProps.insertAttrKeySuccessResponse !==
      this.props.insertAttrKeySuccessResponse &&
      this.props.insertAttrKeySuccessResponse.status === 200
    ) {
      this.setState({
        childModalAlertType: "success",
        childModalAlertMsg: "Attribute key has been saved",
      });

      this.clearAttrKeyOnSuccess();
    }

    if (
      prevProps.insertAttrKeyFailedResponse !==
      this.props.insertAttrKeyFailedResponse &&
      this.props.insertAttrKeyFailedResponse.status === 400
    ) {
      this.setState({
        childModalAlertType: "danger",
        childModalAlertMsg: this.props.insertAttrKeyFailedResponse.data.message,
      });
    }

    if (
      prevProps.insertAttrKeyFailedResponse !==
      this.props.insertAttrKeyFailedResponse &&
      this.props.insertAttrKeyFailedResponse === "Network error"
    ) {
      this.setState({
        childModalAlertType: "danger",
        childModalAlertMsg:
          "Unable to save attribute key. Please try again later or contact system administrator.",
      });
    }
  }

  validateDeleteLocationResponse(prevProps, prevState) {
    if (
      prevProps.deleteResourceFailedResponse !==
      this.props.deleteResourceFailedResponse &&
      this.props.deleteResourceFailedResponse.status !== 200
    ) {
      let modalAttr = {
        showAlert: true,
        alertType: "danger",
        alertMsg:
          "Unable to delete resource. Please try again later or contact system administrator.",
      };
      this.props.addModalAttr(modalAttr);
    }

    if (
      prevProps.deleteResourceSuccessResponse !==
      this.props.deleteResourceSuccessResponse &&
      this.props.deleteResourceSuccessResponse.status === 200
    ) {
      let modalAttr = {
        showAlert: true,
        alertType: "success",
        alertMsg: "Resource has been deleted",
      };
      this.props.addModalAttr(modalAttr);
      this.closeDeleteResourceOnSuccess();
    }
  }

  render() {
    return (
      <div>

        {this.renderForm()}
        {this.renderAttributeModal()}
      </div>
    );
  }

  // --- Add/Update Location validations

  resetFilter() {
    this.setState({
      limit: "10",
      query: "",
      page: "1",
      isActiveFilter: false,
      displayOnlineFilter: false,
    });
    this.initLimitOptions();
    this.doFilter();
  }

  clearResourceAlert() {
    this.props.clearInsertResourceSuccessResponse();
    this.props.clearInsertResourceFailedResponse();
    this.props.clearUpdateResourceSuccessResponse();
    this.props.clearUpdateResourceFailedResponse();
  }

  clearResourceForm() {
    this.setState({
      name: "",
      isActive: false,
      displayOnline: false,
      wasValidated: "",
      isEdit: false,
      resourceId: "",
      resourceUui: "",
    });
  }

  refreshResource() {
    this.clearResourceAlert();
    this.clearResourceForm();

    this.setState({
      showModal: false,
      isModalTrigger: false,
    });
    this.resetFilter();
    this.initLimitOptions();
  }

  closeResourceOnSuccess = _.debounce(function () {
    this.props.showHideLoader(false);
    this.refreshResource();
  }, 1000);
  // --- Add/Update Location validations

  // --- Delete Location validations
  closeDeleteModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.clearDeleteResourceAlert();
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
    };
    this.props.toggleModal(modal);
  };

  doDelete() {
    const { resourceId } = this.state;
    this.props.deleteResource(resourceId, this.props.authUser.sessionToken);
  }

  confirmDelete = (loc) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${loc.name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeDeleteModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };
    this.props.toggleModal(modal);

    this.setState({ resourceId: loc.id });
  };

  clearDeleteResourceAlert() {
    let modalAttr = {
      showAlert: false,
      alertType: "",
      alertMsg: "",
    };
    this.props.addModalAttr(modalAttr);
  }

  clearResourceForm() {
    this.setState({
      name: "",
      isActive: false,
      displayOnline: false,
      wasValidated: "",
      isEdit: false,
    });
  }

  refreshDeleteResource() {
    this.closeDeleteModal();
    this.resetFilter();
  }

  closeDeleteResourceOnSuccess = _.debounce(function () {
    this.refreshDeleteResource();
  }, 1000);
  // --- Delete Location validations

  validateSuccess() {
    if (
      this.props.insertResourceSuccessResponse.status === 200 ||
      this.props.updateResourceSuccessResponse.status === 200
    ) {
      this.closeResourceOnSuccess();
      return (
        <MDBAlert color="success" className="text-center">
          Resource has been saved!
        </MDBAlert>
      );
    }
    return <span></span>;
  }
  validateFailed() {
    let errMgsArr = [];
    if (
      this.props.insertResourceFailedResponse === "Network error" ||
      this.props.updateResourceFailedResponse === "Network error"
    ) {
      errMgsArr.push(
        "Error saving location. Please try again later or contact system administrator."
      );
      let alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.showHideLoader(false);

      return (
        <MDBAlert color={`${alert.type}`}>
          <strong>{alert.title}</strong>
          <br />
          <ul className="text-left">
            {alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    } else if (this.props.insertResourceFailedResponse.status === 400) {
      errMgsArr.push(this.props.insertResourceFailedResponse.data.message);
      let alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.showHideLoader(false);

      return (
        <MDBAlert color={`${alert.type}`}>
          <strong>{alert.title}</strong>
          <br />
          <ul className="text-left">
            {alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    } else if (this.props.updateResourceFailedResponse.status === 400) {
      errMgsArr.push(this.props.updateResourceFailedResponse.data.message);
      let alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.showHideLoader(false);

      return (
        <MDBAlert color={`${alert.type}`}>
          <strong>{alert.title}</strong>
          <br />
          <ul className="text-left">
            {alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    }
    return <span></span>;
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        name: source.name,
        isActive: source.is_active === 1 ? true : false,
        displayOnline: source.display_online === 1 ? true : false,
        resourceUuid: source.uuid,
        resourceId: source.id,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }

    if (!showModal) {
      this.clearResourceAlert();
      this.clearResourceForm();
    }
  };

  renderForm() {
    const {
      showModal,
      name,
      isActive,
      displayOnline,
      isEdit,
      locationName,
    } = this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">{locationName} Resources</h3>
          </MDBCol>
          {validateAccess(storeUserAccess, "LocationResources", "add") && (
            <MDBCol md="6">
              <MDBBtn
                color="primary"
                onClick={() => this.showModal(true, false, null)}
                className="float-right"
              >
                Add Resource
              </MDBBtn>
            </MDBCol>
          )}
        </MDBRow>
        {this.renderTable()}

        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>{isEdit ? "Edit" : "Add"} Resource</MDBModalHeader>
            <MDBModalBody>
              {this.validateSuccess()}
              {this.validateFailed()}
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="idName" className="grey-text">
                    Name
                  </label>
                  <input
                    value={name}
                    name="name"
                    onChange={this.changeHandler}
                    type="text"
                    id="idName"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkActive"
                      defaultChecked={isActive}
                      onChange={this.onIsActiveLocChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkActive"
                      style={{
                        fontWeight: "bold",
                        marginTop: "35px !important",
                      }}
                    >
                      Active
                    </label>
                  </div>
                </MDBCol>
                <MDBCol md="12" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkDisplayOnline"
                      defaultChecked={displayOnline}
                      onChange={this.onDisplayOnlineLocChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkDisplayOnline"
                      style={{
                        fontWeight: "bold",
                        marginTop: "35px !important",
                      }}
                    >
                      Display Online
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (resultContainer.status === 200) {
      return (
        <span>
          Showing {resultContainer.data.resources.results.length} result
        </span>
      );
    }
    return <span></span>;
  }

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  onIsActiveLocChange = (evt) => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  onDisplayOnlineLocChange = (evt) => {
    this.setState({
      displayOnline: !this.state.displayOnline,
    });
  };

  onIsActiveCheckChange = (evt) => {
    this.setState({
      isActiveFilter: !this.state.isActiveFilter,
    });
    this.debouncedDoFilter();
  };

  onDisplayOnlineCheckChange = (evt) => {
    this.setState({
      displayOnlineFilter: !this.state.displayOnlineFilter,
    });
    this.debouncedDoFilter();
  };

  doFilter() {
    const {
      isActiveFilter,
      displayOnlineFilter,
      page,
      limit,
      query,
    } = this.state;
    let params = {};
    if (isActiveFilter) {
      params["is_active"] = 1;
    }
    if (displayOnlineFilter) {
      params["display_online"] = 1;
    }
    if ((page + "").length > 0) {
      params["page"] = page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }
    if (query !== undefined) {
      params["q"] = query;
    }

    let qs = queryString.parse(this.props.location.search);
    params["locationUuid"] = qs.uuid;

    this.props.getResourceList(params, this.props.authUser.sessionToken);
  }

  renderFilters() {
    const { limitOptions, query } = this.state;
    return (
      <div>
        <MDBRow>
          <MDBCol md="3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="isActiveFilter"
                onChange={this.onIsActiveCheckChange}
              />
              <label
                className="custom-control-label"
                htmlFor="isActiveFilter"
                style={{
                  fontWeight: "bold",
                  marginTop: "35px !important",
                }}
              >
                Active
              </label>
            </div>

            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="displayOnlineFilter"
                onChange={this.onDisplayOnlineCheckChange}
              />
              <label
                className="custom-control-label"
                htmlFor="displayOnlineFilter"
                style={{
                  fontWeight: "bold",
                  marginTop: "35px !important",
                }}
              >
                Display Online
              </label>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search resource"
                name="query"
                value={query}
                onChange={this.searchChangeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    if (resultContainer.status === 200) {
      let rows = [];
      _.map(resultContainer.data.resources.results, (loc) => {
        if (loc.is_deleted === 0) {
          rows.push({
            heading0: loc.name,
            heading1: loc.is_active === 1 ? "Active" : "Inactive",
            heading2: loc.display_online === 1 ? "Yes" : "No",
            heading3: moment(loc.created, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("DD/MM/YYYY HH:mm:ss"),
            heading4: moment(loc.updated, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("DD/MM/YYYY HH:mm:ss"),
            heading5: (
              <div>
                {validateAccess(
                  storeUserAccess,
                  "LocationResources",
                  "delete"
                ) && (
                    <MDBBtn
                      color="danger"
                      size="sm"
                      className="float-right"
                      onClick={() => this.confirmDelete(loc)}
                    >
                      Delete
                    </MDBBtn>
                  )}
                {validateAccess(
                  storeUserAccess,
                  "LocationResources",
                  "update"
                ) && (
                    <MDBBtn
                      color="primary"
                      size="sm"
                      className="float-right"
                      onClick={() => this.showModal(true, true, loc)}
                    >
                      Edit
                    </MDBBtn>
                  )}
                {validateAccess(
                  storeUserAccess,
                  "LocationResources",
                  "attributes"
                ) && (
                    <MDBBtn
                      color="primary"
                      size="sm"
                      className="float-right"
                      onClick={() => this.showAttributeModal(true, loc)}
                    >
                      Attributes
                    </MDBBtn>
                  )}
              </div>
            ),
          });
        }
      });

      let data = {
        columns: [
          {
            label: "Name",
            field: "heading0",
            sort: "asc",
          },
          {
            label: "Status",
            field: "heading1",
            sort: "asc",
          },
          {
            label: "Display Online",
            field: "heading2",
            sort: "asc",
          },
          {
            label: "Created",
            field: "heading3",
            sort: "asc",
          },
          {
            label: "Updated",
            field: "heading4",
            sort: "asc",
          },
          {
            label: "",
            field: "heading5",
            sort: "asc",
          },
        ],
        rows: [],
      };

      data.rows = rows;

      return (
        <div>
          <MDBContainer>
            {this.renderFilters()}
            <MDBTable responsive striped bordered>
              <MDBTableHead columns={data.columns} />
              <MDBTableBody rows={data.rows} />
            </MDBTable>
            <MDBRow>
              <MDBCol md="12">
                {this.renderTotalResult()}
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={resultContainer.data.resources.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  // --- ATTRIBUTE MODAL

  // --- Add/Update Attr validations

  resetAttrFilter() {
    this.setState({
      attrLimit: "10",
      attrQuery: "",
      attrPage: "1",
      attrValue: "",
    });
    this.doAttrFilter();
  }

  clearAttrAlert() {
    this.props.clearInsertAttrSuccessResponse();
    this.props.clearInsertAttrFailedResponse();
    this.props.clearUpdateAttrSuccessResponse();
    this.props.clearUpdateAttrFailedResponse();
  }

  clearAttrForm() {
    this.setState({
      attrIsEdit: false,
      attrWasValidated: "",
      selectAttrKeyId: "",
      attrValue: "",
      attrId: "",
    });
  }

  refreshAttr() {
    this.clearAttrAlert();
    this.clearAttrForm();
    this.resetAttrFilter();
    this.initLimitOptions();
  }

  clearAttrOnSuccess = _.debounce(function () {
    this.props.showHideLoader(false);
    this.refreshAttr();
  }, 1000);
  // --- Add/Update Attr validations

  validateAttrSuccess() {
    if (
      (this.props.insertAttrSuccessResponse !== undefined &&
        this.props.insertAttrSuccessResponse.status === 200) ||
      (this.props.updateAttrSuccessResponse !== undefined &&
        this.props.updateAttrSuccessResponse.status === 200)
    ) {
      this.clearAttrOnSuccess();
      return (
        <MDBAlert color="success" className="text-center">
          Attribute has been saved!
        </MDBAlert>
      );
    }
    return <span></span>;
  }
  validateAttrFailed() {
    let errMgsArr = [];
    if (
      this.props.insertAttrFailedResponse === "Network error" ||
      this.props.updateAttrFailedResponse === "Network error"
    ) {
      errMgsArr.push(
        "Error saving location. Please try again later or contact system administrator."
      );
      let alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.showHideLoader(false);

      return (
        <MDBAlert color={`${alert.type}`}>
          <strong>{alert.title}</strong>
          <br />
          <ul className="text-left">
            {alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    } else if (this.props.insertAttrFailedResponse.status === 400) {
      errMgsArr.push(this.props.insertAttrFailedResponse.data.message);
      let alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.showHideLoader(false);

      return (
        <MDBAlert color={`${alert.type}`}>
          <strong>{alert.title}</strong>
          <br />
          <ul className="text-left">
            {alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    } else if (this.props.updateAttrFailedResponse.status === 400) {
      errMgsArr.push(this.props.updateAttrFailedResponse.data.message);
      let alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.showHideLoader(false);

      return (
        <MDBAlert color={`${alert.type}`}>
          <strong>{alert.title}</strong>
          <br />
          <ul className="text-left">
            {alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    }
    return <span></span>;
  }

  attrSubmitHandler = (event) => {
    const {
      selectAttrKeyId,
      attrValue,
      attrIsEdit,
      resourceUuid,
      attrId,
      type,
    } = this.state;
    event.preventDefault();
    // this.clearResourceAlert();
    this.setState({ attrWasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      let body = {
        sourceUuid: resourceUuid,
        attributeKeyId: selectAttrKeyId,
        attributeValue: attrValue,
        // parentAttributeId: 0,
      };
      if (attrIsEdit) {
        this.props.updateAttribute(
          type,
          attrId,
          body,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertAttribute(
          type,
          body,
          this.props.authUser.sessionToken
        );
      }
    }
  };

  showAttributeModal = (showAttributeModal, source) => {
    ("showAttributeModal");
    const { type } = this.state;
    if (!showAttributeModal) {
      this.setState({
        showAttributeModal: showAttributeModal,
      });
      this.refreshAttr();
    } else {
      this.setState({
        showAttributeModal: showAttributeModal,
        resourceUuid: source.uuid,
        resourceName: source.name,
      });
      this.debouncedDoAttrFilter();
      let params = { limit: 1000 };
      this.props.getAttributeKeys(
        type,
        params,
        this.props.authUser.sessionToken
      );
    }
  };

  doAttrEdit(attr) {
    const { locationAttributeKeysContainer } = this.state;
    let attrKeyId = _.chain(
      locationAttributeKeysContainer.data.attribute_keys.results
    )
      .map((key) => {
        if (key.name === attr.name) {
          return key.id;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    this.setState({
      attrId: attr.id,
      attrValue: attr.value,
      selectAttrKeyId: attrKeyId,
      attrIsEdit: true,
    });
  }

  renderAttributeTable() {
    const { attrPage } = this.state;
    const { storeUserAccess } = this.props;
    if (this.props.getAttrSuccessResponse.status === 200) {
      let rows = [];
      _.map(
        this.props.getAttrSuccessResponse.data.attributes.results,
        (attr) => {
          rows.push({
            heading0: attr.name,
            heading1: attr.pretty_name,
            heading2: attr.value,
            heading3: (
              <div>
                {validateAccess(
                  storeUserAccess,
                  "LocationResourceAttributes",
                  "update"
                ) && (
                    <MDBBtn
                      color="primary"
                      size="sm"
                      className="float-right"
                      onClick={() => this.doAttrEdit(attr)}
                    >
                      Edit
                    </MDBBtn>
                  )}
              </div>
            ),
          });
        }
      );

      let data = {
        columns: [
          {
            label: "Name",
            field: "heading0",
            sort: "asc",
          },
          {
            label: "Pretty name",
            field: "heading1",
            sort: "asc",
          },
          {
            label: "Value",
            field: "heading2",
            sort: "asc",
          },
          {
            label: "",
            field: "heading3",
            sort: "asc",
          },
        ],
        rows: [],
      };

      data.rows = rows;

      return (
        <div>
          {this.renderAttrFilters()}
          <MDBTable responsive striped bordered scrollY maxHeight="500px">
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
          <MDBRow>
            <MDBCol md="12">
              {this.renderAttrTotalResult()}
              <div className="float-right">
                <MDBBtnGroup size="sm">
                  <MDBBtn
                    color="primary"
                    onClick={() => this.doAttrPagination(false)}
                    disabled={
                      attrPage + "" === "1" ||
                      this.props.getAttrSuccessResponse.data.attributes
                        .totalPage === 0
                    }
                  >
                    Previous
                  </MDBBtn>
                  <MDBBtn
                    color="primary"
                    onClick={() => this.doAttrPagination(true)}
                    disabled={
                      attrPage + "" ===
                      this.props.getAttrSuccessResponse.data.attributes
                        .totalPage +
                      "" ||
                      this.props.getAttrSuccessResponse.data.attributes
                        .totalPage === 0
                    }
                  >
                    Next
                  </MDBBtn>
                </MDBBtnGroup>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      );
    } else {
      return <div className="text-center">Loading...</div>;
    }
  }

  attrSearchChangeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    this.debouncedDoAttrFilter();
  };

  renderAttrTotalResult() {
    if (this.props.getAttrSuccessResponse.status === 200) {
      return (
        <span>
          Showing{" "}
          {this.props.getAttrSuccessResponse.data.attributes.results.length}{" "}
          result
        </span>
      );
    }
    return <span></span>;
  }

  getValueOfSelectAttrLimit = (value) => {
    this.setState({ attrLimit: value.length > 0 ? value[0] : "" });
    this.debouncedDoAttrFilter();
  };

  doAttrPagination(isNext) {
    const { attrPage } = this.state;
    let p = new Number(attrPage).valueOf();

    if (isNext) {
      p = p + 1;
    } else {
      p = p - 1;
    }

    this.setState({ attrPage: p });
    this.debouncedDoAttrFilter();
  }

  debouncedDoAttrFilter = _.debounce(function () {
    this.doAttrFilter();
  }, 500);

  doAttrFilter() {
    const { attrPage, attrLimit, attrQuery, resourceUuid, type } = this.state;
    let params = {};

    if ((attrPage + "").length > 0) {
      params["page"] = attrPage;
    }
    if (attrLimit.length > 0) {
      params["limit"] = attrLimit;
    }
    if (attrQuery !== undefined) {
      params["q"] = attrQuery;
    }

    params["sourceUuid"] = resourceUuid;

    this.props.getAttributes(type, params, this.props.authUser.sessionToken);
  }

  renderAttrFilters() {
    const { limitOptions, attrQuery } = this.state;
    return (
      <div>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectAttrLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search"
                name="attrQuery"
                value={attrQuery}
                onChange={this.attrSearchChangeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  getValueOfSelect = (value) => {
    const { doSelectAttrKey } = this.state;
    if (doSelectAttrKey) {
      this.setState({ selectAttrKeyId: value[0], doSelectAttrKey: false });
    }
  };

  selectAttrKeyEnable = () => {
    this.setState({ doSelectAttrKey: true });
  };

  showChildModalAlert() {
    const { childModalAlertType, childModalAlertMsg } = this.state;
    return (
      <MDBAlert color={childModalAlertType} className="text-center">
        {childModalAlertMsg}
      </MDBAlert>
    );
  }

  saveAttrKey() {
    const {
      attrKeyName,
      attrKeyDisplayName,
      typeId,
      displayChildModal,
      isFilterable,
      type,
    } = this.state;
    let errMsg = "";
    if (attrKeyName.length === 0) {
      errMsg = "Key name is required";
    } else if (attrKeyDisplayName.length === 0) {
      errMsg = "Display name is required";
    }

    if (errMsg.length === 0) {
      let body = {
        name: attrKeyName,
        displayName: attrKeyDisplayName,
        typeId: typeId,
        isFilterable: isFilterable,
      };

      this.props.insertAttrKey(type, body, this.props.authUser.sessionToken);
    } else {
      this.setState({
        childModalAlertType: "danger",
        childModalAlertMsg: errMsg,
      });
    }
  }

  onIsFilterableChange = (evt) => {
    this.setState({
      isFilterable: !this.state.isFilterable,
    });
  };

  clearChildModalForm() {
    const { type } = this.state;
    this.setState({
      attrKeyName: "",
      attrKeyDisplayName: "",
      displayChildModal: false,
      childModalAlertType: "",
      childModalAlertMsg: "",
    });
    let params = { limit: 1000 };
    this.props.getAttributeKeys(type, params, this.props.authUser.sessionToken);
  }

  clearAttrKeyOnSuccess = _.debounce(function () {
    this.clearChildModalForm();
  }, 1000);

  renderChildModal() {
    const {
      attrKeyName,
      attrKeyDisplayName,
      typeId,
      displayChildModal,
      isFilterable,
    } = this.state;

    if (displayChildModal) {
      return (
        <MDBCard className="modal-child-popup">
          <MDBCardBody>
            <h5>Add attribute key</h5>
            {this.showChildModalAlert()}
            <MDBRow>
              <MDBCol md="6" className="mb-3">
                <label htmlFor="attrKeyName" className="grey-text">
                  Key name
                </label>
                <input
                  value={attrKeyName}
                  name="attrKeyName"
                  onChange={this.changeHandler}
                  type="text"
                  id="attrKeyName"
                  className="form-control"
                  placeholder=" Key name"
                />
              </MDBCol>
              <MDBCol md="6" className="mb-3">
                <label htmlFor="attrKeyDisplayName" className="grey-text">
                  Display name
                </label>
                <input
                  value={attrKeyDisplayName}
                  name="attrKeyDisplayName"
                  onChange={this.changeHandler}
                  type="text"
                  id="attrKeyDisplayName"
                  className="form-control"
                  placeholder="Display name"
                />
              </MDBCol>
              <MDBCol md="6" className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isFilterable"
                    defaultChecked={isFilterable}
                    onChange={this.onIsFilterableChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isFilterable"
                    style={{
                      fontWeight: "bold",
                      marginTop: "35px !important",
                    }}
                  >
                    Filterable
                  </label>
                </div>
              </MDBCol>

              <MDBCol size="12" style={{ marginTop: "105px" }}>
                <MDBBtn
                  color="primary"
                  className="float-right"
                  size="sm"
                  onClick={() => this.saveAttrKey()}
                >
                  Save
                </MDBBtn>
                <MDBBtn
                  color="secondary"
                  className="float-right"
                  size="sm"
                  onClick={() => this.showChildModal(false)}
                >
                  Cancel
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      );
    }
    return <span></span>;
  }

  showChildModal(show) {
    this.setState({ displayChildModal: show });
    if (!show) {
      this.clearChildModalForm();
    }
  }

  renderAvailableAttributeKey() {
    const {
      locationAttributeKeysContainer,
      showAttributeModal,
      selectAttrKeyId,
    } = this.state;
    const { storeUserAccess } = this.props;
    if (locationAttributeKeysContainer.status === 200 && showAttributeModal) {
      let keys = _.chain(
        locationAttributeKeysContainer.data.attribute_keys.results
      )
        .map((key) => {
          return {
            checked: selectAttrKeyId === key.id + "",
            disabled: false,
            text: key.pretty_name,
            value: key.id,
          };
        })
        .value();
      return (
        <MDBRow>
          <MDBCol md="4" className="mb-3">
            <MDBSelect
              search
              color="primary"
              getValue={this.getValueOfSelect}
              options={keys}
              label="Search Attribute Key"
              onClick={this.selectAttrKeyEnable}
              className={
                selectAttrKeyId !== undefined && selectAttrKeyId.length === 0
                  ? "error"
                  : ""
              }
            />
          </MDBCol>
          {validateAccess(
            storeUserAccess,
            "LocationResourceAttributes",
            "add"
          ) && (
              <MDBCol md="1" style={{ paddingTop: "20px" }}>
                <MDBBtn
                  color="primary"
                  className="float-right"
                  size="sm"
                  onClick={() => this.showChildModal(true)}
                >
                  add
              </MDBBtn>
              </MDBCol>
            )}
          {this.renderChildModal()}
        </MDBRow>
      );
    }
    return <span></span>;
  }

  renderAttributeModal() {
    const { showAttributeModal, attrValue, resourceName } = this.state;
    const { storeUserAccess } = this.props;
    if (showAttributeModal) {
      return (
        <div className="fix-fluid-modal">
          <MDBModal
            isOpen={showAttributeModal}
            size="fluid"
            toggle={() => this.showAttributeModal(false, null)}
          >
            <form
              className={`needs-validation ${this.state.attrWasValidated}`}
              onSubmit={this.attrSubmitHandler}
              noValidate
            >
              <MDBModalHeader>{resourceName} Attributes</MDBModalHeader>
              <MDBModalBody>
                {this.validateAttrSuccess()}
                {this.validateAttrFailed()}
                <MDBRow>
                  <MDBCol md="12" className="mb-3">
                    {this.renderAvailableAttributeKey()}
                  </MDBCol>

                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="idVal" className="grey-text">
                      Value
                    </label>
                    <textarea
                      value={attrValue}
                      name="attrValue"
                      onChange={this.changeHandler}
                      type="text"
                      id="idVal"
                      rows={10}
                      className="form-control"
                      placeholder="Value"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide the value
                    </div>
                  </MDBCol>
                  <MDBCol md="12" className="mb-3">
                    {validateAccess(
                      storeUserAccess,
                      "LocationResourceAttributes",
                      "add"
                    ) && (
                        <MDBBtn
                          color="primary"
                          type="submit"
                          className="float-right"
                        >
                          Save
                        </MDBBtn>
                      )}
                    <MDBBtn
                      color="secondary"
                      className="float-right"
                      onClick={() => this.refreshAttr()}
                    >
                      Cancel
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="12" className="mb-3">
                    {this.renderAttributeTable()}
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.showAttributeModal(false, null)}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      );
    }
    return <span></span>;
  }

  // --- ATTRIBUTE MODAL
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    authUser: state.authUser,
    successHandlerResponse: state.successHandlerResponse,
    failedHandlerResponse: state.failedHandlerResponse,
    authUser: state.authUser,
    alert: state.alert,
    locations: state.locations,
    insertResourceSuccessResponse: state.insertResourceSuccessResponse,
    insertResourceFailedResponse: state.insertResourceFailedResponse,
    updateResourceSuccessResponse: state.updateResourceSuccessResponse,
    updateResourceFailedResponse: state.updateResourceFailedResponse,
    getAttrSuccessResponse: state.getAttrSuccessResponse,
    getAttrFailedResponse: state.getAttrFailedResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getResourceListSuccessResponse: state.getResourceListSuccessResponse,
    getResourceListFailedResponse: state.getResourceListFailedResponse,
    getAttrKeysSuccessResponse: state.getAttrKeysSuccessResponse,
    getAttrKeysFailedReducer: state.getAttrKeysFailedResponse,
    modal: state.modal,
    deleteResourceSuccessResponse: state.deleteResourceSuccessResponse,
    deleteResourceFailedResponse: state.deleteResourceFailedResponse,
    insertAttrSuccessResponse: state.insertAttrSuccessResponse,
    insertAttrFailedResponse: state.insertAttrFailedResponse,
    updateAttrSuccessResponse: state.updateAttrSuccessResponse,
    updateAttrFailedResponse: state.updateAttrFailedResponse,
    insertAttrKeySuccessResponse: state.insertAttrKeySuccessResponse,
    insertAttrKeyFailedResponse: state.insertAttrKeyFailedResponse,
    getAttrTypesSuccessResponse: state.getAttrTypesSuccessResponse,
    getAttrTypesFailedResponse: state.getAttrTypesFailedResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    alertMessage,
    showHideLoader,
    searchLocationsPrivate,
    insertResource,
    clearInsertResourceSuccessResponse,
    clearInsertResourceFailedResponse,
    updateResource,
    clearUpdateResourceSuccessResponse,
    clearUpdateResourceFailedResponse,
    getAttributes,
    clearGetAttributesSuccessResponse,
    clearGetAttributesFailedResponse,
    getResourceList,
    clearGetResourceListSuccessResponse,
    clearGetResourceListFailedResponse,
    getAttributeKeys,
    clearGetAttrKeysSuccessResponse,
    clearGetAttrKeysFailedResponse,
    toggleModal,
    deleteResource,
    clearDeleteResourceSuccessResponse,
    clearDeleteResourceFailedResponse,
    addModalAttr,
    getLocationMenu,
    insertAttribute,
    clearInsertAttrSuccessResponse,
    clearInsertAttrFailedResponse,
    updateAttribute,
    clearUpdateAttrSuccessResponse,
    clearUpdateAttrFailedResponse,
    insertAttrKey,
    clearInsertAttrKeySuccessResponse,
    clearInsertAttrKeyFailedResponse,
    getAttrTypes,
    clearGetAttrTypesSuccessResponse,
    clearGetAttrTypesFailedResponse,
    getLocationList,
  })(ManageResource)
);
