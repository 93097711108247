import React from "react";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  addModalAttr,
  showNotification,
  genModalRulesEngine,
} from "../../../actions";

import {
  getOrgBusinessRules,
  updateBusinessRule,
  deleteBusinessRule,
} from "../../../actions/ruleEngineAction";

import RulesEngineModal from "../shared/RulesEngineModal";

class RulesEngine extends React.Component {
  state = {
    componentName: "RulesEngine",
    bussinessRules: [],
  };

  componentDidMount() {
    this.props.displayBreadcrumbs("Dashboard / Admin / Rules Engine");
    this.props.getOrgBusinessRules({}, this.props.authUser.sessionToken);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rulesEngineResponse !== this.props.rulesEngineResponse) {
      let response = this.props.rulesEngineResponse;

      this.props.showHideLoader(false);
      if (response.action == "ACTION" || response.action == "DELETE") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
        }

        this.props.getOrgBusinessRules({}, this.props.authUser.sessionToken);
      } else {
        this.setState({
          ...this.state,
          bussinessRules: this.props.rulesEngineResponse.data,
        });
      }
    }
  }

  showBusinessRulesModal = (source, isEdit) => {
    if (isEdit) {
      let attr = {
        show: true,
        action: "edit",
        parent: this.state.componentName,
        payload: source,
      };
      this.props.genModalRulesEngine(attr);
    } else {
      let attr = {
        show: true,
        action: "add",
        parent: this.state.componentName,
        payload: {},
      };
      this.props.genModalRulesEngine(attr);
    }
  };

  setBusinessRuleStatus = (b_rule_id, b_rule_uuid, is_active) => {
    let body = {
      id: b_rule_id,
      is_active: is_active,
    };

    let statusDisplay = is_active ? "enable" : "disable";
    var result = DevExtremeConfirm(
      `Are you sure you want to ${statusDisplay} the selected business rule?`
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);

        this.props.updateBusinessRule(
          body,
          b_rule_uuid,
          this.props.authUser.sessionToken
        );
      }
    });
  };

  onClickDeleteBusinessRule = (uuid, businessRuleName) => {
    var result = DevExtremeConfirm(
      `Are you sure you want to delete <b>${businessRuleName}</b>?`
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);

        this.props.deleteBusinessRule(
          {},
          uuid,
          this.props.authUser.sessionToken
        );
      }
    });
  };

  renderRuleTriggersDisplay = (ruleTriggers) => {
    let renderedHtml = "No data available";

    if (ruleTriggers.length > 0)
      renderedHtml = (
        <ul className="pl-3">
          {ruleTriggers.map((ruleTrigger) => {
            return (
              <li>
                <ul className="pl-3">
                  {`${ruleTrigger["display_name"]}`}

                  <br />
                </ul>
              </li>
            );

            let triggerConditionDisplay = "";

            switch (ruleTrigger["trigger_condition"]) {
              case "is_in":
              case "not_in":
                if (ruleTrigger["trigger_condition"] === "is_in") {
                  triggerConditionDisplay = "Is in";
                } else {
                  triggerConditionDisplay = "Not in";
                }

                return (
                  <li>
                    <ul className="pl-3">
                      <b>{`${ruleTrigger["display_name"]} (${triggerConditionDisplay})`}</b>
                      {ruleTrigger["values"].map((ruleTriggerValue) => {
                        return <li>{ruleTriggerValue["value_display"]} </li>;
                      })}{" "}
                      <br />
                    </ul>
                  </li>
                );
              case "in_between":
              case "not_in_between":
                triggerConditionDisplay =
                  ruleTrigger["trigger_condition"] === "in_between"
                    ? "Between"
                    : "Not in between";

                let valueFromDisplay = ruleTrigger["valueFrom"]["value"];
                let valueToDisplay = ruleTrigger["valueTo"]["value"];
                if (ruleTrigger["trigger_type"] === "days_of_the_week") {
                  valueFromDisplay = ruleTrigger["valueFrom"]["text"];
                  valueToDisplay = ruleTrigger["valueTo"]["text"];
                }

                return (
                  <li>
                    <ul className="pl-3">
                      <b>{`${ruleTrigger["display_name"]} (${triggerConditionDisplay})`}</b>
                      <li>From: {valueFromDisplay} </li>
                      <li>To: {valueToDisplay} </li>
                      <br />
                    </ul>
                  </li>
                );

              default:
                break;
            }
          })}
        </ul>
      );

    return renderedHtml;
  };
  render() {
    const { bussinessRules } = this.state;
    const rule_type_list = {
      PRE_BOOKING_VALIDATION: "Pre-booking validation",
      GENERAL_BOOKING_WARNING: "Booking popup warning",
      BANNER_MESSAGE: "Banner message",
      BOOKING_CANCELLATION: "Booking cancellation validation",
    };

    const customer_type_list = {
      guest: "Guest customer",
      primary: "Primary customer",
      both: "Both main and primary customer",
    };

    return (
      <>
        <RulesEngineModal />
        <MDBContainer>
          {/* FILTER SECTION */}
          <div>
            <MDBRow className="mb-4">
              <MDBCol md="6">
                <h3 className="mt-3">Manage Business Rules</h3>
              </MDBCol>
              <MDBCol md="6">
                <MDBBtn
                  color="primary"
                  onClick={() => this.showBusinessRulesModal({}, false)}
                  className="float-right"
                >
                  Add Rule
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>

          {/* END FILTER SECTION */}

          {/* START TABLE SECTION */}
          <MDBTable responsive striped bordered>
            <MDBTableHead>
              <tr>
                <th className="text-center" width="15%">
                  Name
                </th>
                <th className="text-center" width="15%">
                  {" "}
                  Description
                </th>
                <th className="text-center" width="10%">
                  Rule Type
                </th>
                <th className="text-center" width="10%">
                  Rule Applies to
                </th>
                <th className="text-center" width="15%">
                  Rule Triggers
                </th>
                <th className="text-center" width="15%">
                  Evaluators
                </th>
                <th className="text-center" width="10%">
                  Status
                </th>
                <th className="text-center" width="10%"></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {bussinessRules.map((businessRule) => {
                return (
                  <tr>
                    <td>{businessRule.display_name}</td>
                    <td>{businessRule.description}</td>
                    <td>{rule_type_list[businessRule.rule_type]}</td>
                    <td>{customer_type_list[businessRule.customer_type]}</td>
                    <td>
                      {this.renderRuleTriggersDisplay(
                        businessRule["rule_triggers"]
                      )}
                    </td>
                    <td>
                      {businessRule["evaluators"].length > 0 ? (
                        <ul className="pl-3">
                          {businessRule["evaluators"].map((evaluator) => {
                            const evaluatorDetails =
                              evaluator["evaluator_details"];

                            let displayName = "";
                            if (evaluatorDetails) {
                              displayName = evaluatorDetails["display_name"];
                            }

                            return <li>{displayName}</li>;
                          })}
                        </ul>
                      ) : (
                        "No data available"
                      )}
                    </td>

                    <td className="text-center">
                      <span
                        className={`badge rounded-pill ${
                          businessRule["is_active"] ? "bg-success" : "bg-danger"
                        }`}
                      >
                        {businessRule["is_active"] ? "Active" : "Disabled"}
                      </span>
                    </td>

                    <td className="text-center">
                      <>
                        <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="power-off"
                          className={`${
                            businessRule["is_active"]
                              ? "danger-text"
                              : "success-text"
                          } mr-2`}
                          onClick={() => {
                            this.setBusinessRuleStatus(
                              businessRule["id"],
                              businessRule["uuid"],
                              !businessRule["is_active"] // if its active set the status to false to deactivate
                            );
                          }}
                        />

                        <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="edit"
                          className="cyan-text mr-2"
                          onClick={() => {
                            this.showBusinessRulesModal(businessRule, true);
                          }}
                        />

                        <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="trash"
                          className="danger-text"
                          onClick={() => {
                            this.onClickDeleteBusinessRule(
                              businessRule["uuid"],
                              businessRule["display_name"]
                            );
                          }}
                        />
                      </>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>

          {/* END TABLE SECTION */}
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    rulesEngineResponse: state.rulesEngineResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    toggleModal,
    addModalAttr,
    showNotification,
    genModalRulesEngine,
    getOrgBusinessRules,
    updateBusinessRule,
    deleteBusinessRule,
  })(RulesEngine)
);
