import React from "react";
import { validateLayout } from "../../../actions";
import { getBookingDetails } from "../../../actions/bookingAction";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
} from "mdbreact";
import SectionContainer from "../shared/sectionContainer";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import RichTextRenderer from "../shared/RichTextRenderer";
import { searchLocations } from "../../../actions/locationAction";
import { extractTimezone } from "../../../helper/utils";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { USER_COOKIES_KEY, USER_TMP } from "../../../helper";

class BookingSuccess extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  state = {
    locationLoaded: false,
    bookingDetailsContainer: {},
    locationContainer: {},
    receiptContainer: "",
    showGoToDashboard: false,
  };

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: false,
      showSearch: false,
      showHeader: false,
      showLogin: false,
    };
    this.props.validateLayout(layout);
  }

  getUserCookies() {
    const { cookies } = this.props;
    let userCookies = cookies.get(USER_COOKIES_KEY);
    if (userCookies !== undefined) {
      this.setState({ showGoToDashboard: true });
    }
  }

  componentDidMount() {
    this.setLayout();
    let params = _.split(this.props.location.pathname, "/");
    this.isPayment = params[2] == "payment";
    let uuid = params[3];
    if (this.isPayment) {
      this.paymentId = params[5];
      uuid = params[4];
      this.paymentStatus = params[3] == "success";
    }

    this.props.getBookingDetails(uuid);
    this.intervalId = null;
    window.scrollTo(0, 0);
    this.getUserCookies();
  }

  checkReceipt() {
    const { bookingDetailsContainer } = this.state;
    if (bookingDetailsContainer.id !== undefined) {
      let payment = [];
      if (this.paymentId > 0) {
        payment = _.find(
          this.props.bookingDetailsResponse.transaction.payments,
          (p) => {
            return p.id == this.paymentId;
          }
        );
      } else {
        payment = this.props.bookingDetailsResponse.transaction.payments[0];
      }
      if (payment.receiptUrl === undefined || payment.receiptUrl === "") {
        let uuid = _.split(this.props.location.pathname, "/")[3];
        this.props.getBookingDetails(uuid);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.bookingDetailsResponse !== this.props.bookingDetailsResponse
    ) {
      if (this.props.bookingDetailsResponse.id > 0) {
        this.props.searchLocations(
          this.props.bookingDetailsResponse.location.uuid
        );

        this.setState({
          bookingDetailsContainer: this.props.bookingDetailsResponse,
        });

        if (this.props.bookingDetailsResponse.id !== undefined) {
          let payment = [];
          if (this.paymentId > 0) {
            payment = _.find(
              this.props.bookingDetailsResponse.transaction.payments,
              (p) => {
                return p.id == this.paymentId;
              }
            );
          } else {
            payment = this.props.bookingDetailsResponse.transaction.payments[0];
          }

          if (!payment) {
            // do nothing
          } else if (payment.receiptUrl !== undefined) {
            this.setState({
              receiptContainer: payment.receiptUrl,
            });
          } else {
            this.intervalId = setInterval(this.checkReceipt.bind(this), 5000);
          }
        }
      }
    }

    if (prevProps.locations !== this.props.locations) {
      this.setState({ locationContainer: this.props.locations });
    }
  }

  getTzDetails() {
    const { locationContainer } = this.state;
    return extractTimezone(locationContainer.results[0].attributes.timezone);
  }

  renderDetails() {
    const { bookingDetailsContainer, locationContainer } = this.state;
    if (locationContainer.results !== undefined) {
      if (bookingDetailsContainer.id !== undefined) {
        let reservationDate = moment(bookingDetailsContainer.start_time)
          .tz(this.getTzDetails().timezone)
          .format("DD/MM/YYYY");

        var from = moment(bookingDetailsContainer.start_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm");

        var to = moment(bookingDetailsContainer.end_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm");

        var now = moment(from, "HH:mm"); //todays date
        var end = moment(to, "HH:mm"); // another date
        var duration = moment.duration(end.diff(now));
        var totalHours = duration.asHours();
        var status =
          bookingDetailsContainer.transaction.ispaid == 1
            ? "Confirmed"
            : "For confirmation";

        if (this.isPayment) {
          status = this.paymentStatus ? "Payment Received" : "Payment Failed";
        }
        return (
          <div>
            <MDBRow className="py-3">
              <MDBCol md="12">
                <SectionContainer noBorder title="">
                  <MDBCard>
                    <MDBCardBody>
                      <MDBTable responsive>
                        <MDBTableHead>
                          <tr>
                            <th>Booking Reference</th>
                            <th>Name</th>
                            <th>Reservation Date</th>
                            <th>Time</th>
                            <th>Duration</th>
                            <th>Location</th>
                            <th>Resource</th>
                            <th>Status</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          <tr>
                            <td>{bookingDetailsContainer.booking_reference}</td>
                            <td>
                              {bookingDetailsContainer.customer.first_name +
                                " " +
                                bookingDetailsContainer.customer.last_name}
                            </td>
                            <td>{reservationDate}</td>
                            <td>{from + "-" + to}</td>
                            <td>{totalHours + " Hour/s"}</td>
                            <td>{bookingDetailsContainer.location.name}</td>
                            <td>{bookingDetailsContainer.resource.name}</td>
                            <td>{status}</td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCardBody>
                  </MDBCard>
                </SectionContainer>
              </MDBCol>
            </MDBRow>
          </div>
        );
      } else {
        return <div className="text-center">Loading...</div>;
      }
    }
    return <div className="text-center">Loading...</div>;
  }

  openReceipt(url) {
    window.open(url, "_blank");
  }

  renderReceipt() {
    const { receiptContainer } = this.state;
    if (receiptContainer !== null && receiptContainer.length > 0) {
      return (
        <MDBBtn
          className="float-right"
          color="success"
          onClick={() => this.openReceipt(receiptContainer)}
        >
          View Payment Receipt
        </MDBBtn>
      );
    }
    return <span></span>;
  }

  renderTermsAndConditions() {
    const { locationContainer } = this.state;
    if (locationContainer.results !== undefined) {
      return (
        <RichTextRenderer
          doScrollY={true}
          htmlString={
            locationContainer.results[0].attributes.terms_and_conditions
          }
        />
      );
    }
    return <div className="text-center">Loading...</div>;
  }

  renderContent() {
    const { showGoToDashboard } = this.state;
    return (
      <div>
        <MDBContainer>
          {this.isPayment ? (
            <>
              {this.paymentStatus ? (
                <>
                  <h1 className="text-center mt-5">Payment Received</h1>
                  <h6 className="text-center">
                    We are pleased to inform you that your payment has been
                    received.
                  </h6>
                </>
              ) : (
                <>
                  <h1 className="text-center mt-5">Payment Failed Received</h1>
                </>
              )}
            </>
          ) : (
            <>
              <h1 className="text-center mt-5">Booking Confirmed</h1>
              <h6 className="text-center">
                We are pleased to inform you that your reservation request has
                been received and confirmed.
              </h6>
            </>
          )}

          <MDBRow className="mt-5">
            <MDBCol md="6">
              <h5 style={{ marginTop: "25px" }}>Booking Details</h5>
            </MDBCol>
            <MDBCol md="6">{this.renderReceipt()}</MDBCol>
          </MDBRow>
          {this.renderDetails()}
          <br />
          {this.renderTermsAndConditions()}
          <br />
          <div className="text-center">
            {!showGoToDashboard && (
              <Link to="/">
                <MDBBtn color="primary">Go back to Home page</MDBBtn>
              </Link>
            )}
            {showGoToDashboard && (
              <Link to="/admin/dashboard">
                <MDBBtn color="primary">Go back to Dashboard</MDBBtn>
              </Link>
            )}
          </div>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    bookingDetailsResponse: state.bookingDetailsResponse,
    locations: state.locations,
  };
};

export default withCookies(
  connect(mapStateToProps, {
    validateLayout,
    getBookingDetails,
    searchLocations,
  })(BookingSuccess)
);
