import {
  SUBMIT_CONTACT_MESSAGE_HANDLER,
  SUBMIT_CONTACT_MESSAGE_FAILED_HANDLER,
} from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    notificationType: notificationType,
    type: action.type,
    status: status,
    action: "LIST",
  };
  switch (action.type) {
    case SUBMIT_CONTACT_MESSAGE_HANDLER:
    case SUBMIT_CONTACT_MESSAGE_FAILED_HANDLER:
      response["data"] = action.payload.data;
      response["action"] = "ACTION";
      break;
    default:
      return state;
  }
  return response;
};
