import moment, { utc } from "moment";
import mtz from "moment-timezone";
import { StyleSheet, css } from "aphrodite";
import _ from "lodash";

export const roundNumber = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
    decimals
  );
};

export const extractTimezone = (
  timezone = "Australia/Sydney",
  dt = new Date()
) => {
  let date = moment(dt).format();
  if (timezone !== undefined) {
    date = moment(dt).tz(timezone).format();
  }

  let offset = date.substring(19, 25);
  let offsetNum = new Number(offset.substring(1, 3)).valueOf();
  let operation = offset.substring(0, 1);
  return {
    timezone: timezone,
    offset: offset,
    offsetNum: offsetNum,
    operation: operation,
  };
};

export const getBookingColors = (bookingColors) => {
  //-- Set temporary color

  if (bookingColors === undefined) {
    console.log("Set default colors");
    return StyleSheet.create({
      bg_available: {
        backgroundColor: "rgba(96,125,139,0.1)",
        ":hover": { background: "rgba(0, 0, 0, 0.1)" },
      },
      tc_available: { color: "#000000" },
      bg_oneOff: {
        backgroundColor: "#2196f3",
        ":hover": { background: "#1075c6" },
      },
      tc_oneOff: { color: "white" },
      bg_recurring: {
        backgroundColor: "rgba(76,175,80,.7)",
        ":hover": { background: "rgb(69 155 72 / 70%)" },
      },
      tc_recurring: { color: "white" },
      bg_maintenance: {
        backgroundColor: "#fff59d",
        ":hover": { background: "#e5db87" },
      },
      tc_maintenance: { color: "black" },
      bg_coachingSession: {
        backgroundColor: "rgba(244,67,54,.3)",
        ":hover": { background: "rgb(208 64 54 / 30%)" },
      },
      tc_coachingSession: { color: "black" },
      bg_notAvailable: {
        backgroundColor: "#9e9e9e",
        ":hover": { background: "#9e9e9e" },
      },
      bg_addToWaitList: {
        backgroundColor: "#00c851",
        ":hover": { background: "#00c851" },
      },
      tc_notAvailable: { color: "white" },
      bg_public_available: {
        backgroundColor: "#00c851",
        ":hover": { background: "#0ba71e" },
      },
      tc_public_available: { color: "white" },
      bg_public_booked: {
        backgroundColor: "#9e9e9e",
        ":hover": { background: "#9e9e9e" },
      },
      tc_public_booked: { color: "white" },
      bg_public_notAvailable: {
        backgroundColor: "#fffff2",
        ":hover": { background: "#fffff2" },
      },
      tc_public_notAvailable: { color: "#000000" },

      bg_request_booking: {
        backgroundColor: "#cc8616",
        ":hover": { background: "#b87509" },
      },
      tc_request_booking: { color: "#ffffff" },

      bg_public_request_booking: {
        backgroundColor: "#cc8616",
        ":hover": { background: "#b87509" },
      },
      tc_public_request_booking: { color: "#ffffff" },
    });
  }
  console.log("Set dynamic color");
  return StyleSheet.create({
    bg_available: {
      backgroundColor: bookingColors.availabe_booking_color,
      ":hover": {
        background: bookingColors.availabe_booking_color_hover,
      },
    },
    tc_available: {
      color: bookingColors.availabe_booking_color_text,
    },

    bg_oneOff: {
      backgroundColor: bookingColors.one_off_booking_color,
      ":hover": {
        background: bookingColors.one_off_booking_color_hover,
      },
    },
    tc_oneOff: {
      color: bookingColors.one_off_booking_color_text,
    },

    bg_recurring: {
      backgroundColor: bookingColors.recurring_booking_color,
      ":hover": {
        background: bookingColors.recurring_booking_color_hover,
      },
    },
    tc_recurring: {
      color: bookingColors.recurring_booking_color_text,
    },

    bg_maintenance: {
      backgroundColor: bookingColors.maintenance_booking_color,
      ":hover": {
        background: bookingColors.maintenance_booking_color_hover,
      },
    },
    tc_maintenance: {
      color: bookingColors.maintenance_booking_color_text,
    },

    bg_coachingSession: {
      backgroundColor: bookingColors.coaching_session_booking_color,
      ":hover": {
        background: bookingColors.coaching_session_booking_color_hover,
      },
    },
    tc_coachingSession: {
      color: bookingColors.coaching_session_booking_color_text,
    },

    bg_notAvailable: {
      backgroundColor: bookingColors.not_available_booking_color,
      ":hover": {
        background: bookingColors.not_available_booking_color_hover,
      },
    },
    bg_addToWaitList: {
      backgroundColor: "#00c851",
      ":hover": { background: "#00c851" },
    },
    tc_notAvailable: {
      color: bookingColors.not_available_booking_color_text,
    },

    bg_public_available: {
      backgroundColor: bookingColors.public_available_booking_color,
      ":hover": {
        background: bookingColors.public_available_booking_color_hover,
      },
    },
    tc_public_available: {
      color: bookingColors.public_available_booking_color_text,
    },

    bg_public_booked: {
      backgroundColor: bookingColors.public_booked_booking_color,
      ":hover": {
        background: bookingColors.public_booked_booking_color_hover,
      },
    },
    tc_public_booked: {
      color: bookingColors.public_booked_booking_color_text,
    },

    bg_public_notAvailable: {
      backgroundColor: bookingColors.public_not_available_booking_color,
      ":hover": {
        background: bookingColors.public_not_available_booking_color_hover,
      },
    },
    tc_public_notAvailable: {
      color: bookingColors.public_not_available_booking_color_text,
    },

    bg_request_booking: {
      backgroundColor: bookingColors.request_booking_color,
      ":hover": {
        background: bookingColors.request_booking_color_hover,
      },
    },
    tc_request_booking: {
      color: bookingColors.request_booking_color_text,
    },

    bg_public_request_booking: {
      backgroundColor: bookingColors.public_request_booking_color,
      ":hover": {
        background: bookingColors.public_request_booking_color_hover,
      },
    },
    tc_public_request_booking: {
      color: bookingColors.public_request_booking_color_text,
    },
  });
};

export const getCss = (obj) => {
  return css(obj);
};

export const enableAccess = (roleAccess, module, action) => {
  if (
    roleAccess !== undefined &&
    roleAccess.type !== undefined &&
    roleAccess.type === "STORE_USER_ACCESS"
  ) {
    let access = _.filter(roleAccess.data.roleAccess, ["type", module]);

    if (access.length > 0) {
      if (action.length === 0) {
        return access[0].enable === 1 ? true : false;
      } else {
        let details = access[0].roleAccessDetails;
        let detail = _.filter(details, ["keyName", action]);

        if (detail.length > 0) {
          return detail[0].enable === 1 ? true : false;
        } else {
          return false;
        }
      }
    }
  }

  return false;
};

export const buildAccessObject = (roleAccess) => {
  // console.log(roleAccess);
  let obj = {};
  let key = "";
  _.map(roleAccess.roleAccess, (access) => {
    // console.log(access);
    key = access.type.replace(/\s/g, "");
    obj[key] = {
      enable: access.enable === 1 ? true : false,
    };

    //-- Build actions
    let objDetails = {};
    _.map(access.roleAccessDetails, (details) => {
      objDetails[details.keyName] = details.enable === 1 ? true : false;
    });

    if (Object.keys(objDetails).length > 0) {
      obj[key]["action"] = objDetails;
    }
  });

  return obj;
};

export const validateAccess = (roleAccess, attr1, attr2) => {
  if (Object.keys(roleAccess).length > 0) {
    if (attr1.length > 0 && attr2.length === 0) {
      if (roleAccess.data[attr1] === undefined) {
        return false;
      }
      return roleAccess.data[attr1].enable;
    } else if (attr1.length > 0 && attr2.length > 0) {
      if (
        roleAccess.data[attr1] === undefined ||
        (roleAccess.data[attr1] !== undefined &&
          roleAccess.data[attr1].action[attr2] === undefined)
      ) {
        return false;
      }
      return roleAccess.data[attr1].action[attr2];
    }
  }
  return false;
};
