import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

import { getEmailLogs } from "../../../actions/emailLogAction";

import { showHideLoader } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs } from "../../../actions";
import _, { isBoolean } from "lodash";
import moment from "moment";
import { validateAccess } from "../../../helper/utils";

class ReportEmailLog extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    filterLoaded: false,
    type: "",
    user: "",
    customer: "",
  };

  constructor(props) {
    super(props);
  }

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Start Date"
          onChange={this.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  componentDidMount() {
    const { query } = this.state;

    this.props.showHideLoader(true);

    if (typeof this.props.customerId !== "undefined") {
      this.props.displayBreadcrumbs(
        "Dashboard / Administrator / Report / Notification Logs"
      );
    }

    let params = {};

    this.initLimitOptions();
  }

  render() {
    return (
      <div>
        {this.renderPreviewEmail()}
        {this.renderTable()}
      </div>
    );
  }

  createTypeOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Log Type",
      value: "",
    });

    let response = this.props.emailLogsResponse.data;
    if (
      response.search_aggs !== undefined &&
      response.search_aggs.type !== undefined
    ) {
      let buckets = response.search_aggs.type.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  createCustomerOptions = () => {
    let options = [];
    options.push({
      checked: false,
      disabled: false,
      text: "Select Customer",
      value: "",
    });
    let response = this.props.emailLogsResponse.data;
    if (
      response.search_aggs !== undefined &&
      response.search_aggs.customer !== undefined
    ) {
      let buckets = response.search_aggs.customer.buckets;
      for (let i = 0; i < buckets.length; i++) {
        options.push({
          checked: false,
          disabled: false,
          text: buckets[i].key,
          value: buckets[i].key,
        });
      }
    }
    return options;
  };

  renderFilters() {
    const { limitOptions, typeOptions, customerOptions } = this.state;
    return (
      <div>
        {this.props.hideTitle != undefined && this.props.hideTitle ? (
          ""
        ) : (
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <h3 className="mt-3">Outbound Notification Logs</h3>
            </MDBCol>
          </MDBRow>
        )}

        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectLimit}
              value={this.state.limit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueType}
              value={this.state.type}
              options={typeOptions}
              label="Filter By Type"
            />
          </MDBCol>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueUser}
              value={this.state.user}
              options={customerOptions}
              label="Filter By Customer"
            />
          </MDBCol>
          <MDBCol md="6">
            <div className="float-right">
              <MDBInput
                label="Search outbound notification by keyword(s)"
                name="query"
                onChange={this.changeHandler}
                style={{ width: "430px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow >
          <MDBCol md="3">
            <MDBInput
              outline
              type="date"
              color="primary"
              label="Date Start"
            />
          </MDBCol>
          <MDBCol md="3">
            <MDBInput
              outline
              type="date"
              color="primary"
              label="Date End"
            />
          </MDBCol>
        </MDBRow> */}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.emailLogsResponse !== this.props.emailLogsResponse) {
      this.setState({
        resultContainer: this.props.emailLogsResponse.data,
        page: this.props.emailLogsResponse.data.page,
      });
      if (!this.state.filterLoaded) {
        this.setState({
          filterLoaded: true,
          typeOptions: this.createTypeOptions(),
          customerOptions: this.createCustomerOptions(),
        });
      }
      this.props.showHideLoader(false);
    }
  }

  renderPreviewEmail() {
    const { displayPreview, htmlMessage, subject } = this.state;
    return (
      <MDBModal
        isOpen={displayPreview}
        size="lg"
        toggle={() => this.showPreviewEmail(false)}
      >
        <MDBModalHeader>
          <strong>{subject}</strong>
        </MDBModalHeader>
        <MDBModalBody>
          <div dangerouslySetInnerHTML={{ __html: htmlMessage }}></div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => this.showPreviewEmail(false)}
          >
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }

  showPreviewEmail(displayPreview = false, message = "", subject = "") {
    this.setState({
      displayPreview: displayPreview,
      htmlMessage: message,
      subject: subject,
    });
  }

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        {
          label: "Subject",
          field: "subject",
          sort: "asc",
        },
        {
          label: "Recipient",
          field: "recipient",
          sort: "asc",
        },
        {
          label: "Recipient Name",
          field: "recipient_name",
          sort: "asc",
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
        },
        ,
        {
          label: "Message",
          field: "message",
          sort: "asc",
        },
        {
          label: "Sent",
          field: "sent",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    if (typeof resultContainer !== "undefined") {
      rows = _.chain(resultContainer.results)
        .map((result, index) => {
          return {
            subject: result.subject,
            recipient: result.recipient,
            recipient_name: result.recipient_name,
            type: result.type.name,
            message: (
              <div>
                {result.type.name == "SMS" ? (
                  result.message
                ) : (
                  <div>
                    {validateAccess(
                      storeUserAccess,
                      "EmailLogs",
                      "previewEmail"
                    ) && (
                      <MDBBtn
                        color="primary"
                        type="button"
                        size="sm"
                        onClick={() => {
                          this.showPreviewEmail(
                            true,
                            result.message,
                            result.subject
                          );
                        }}
                      >
                        Preview Email
                      </MDBBtn>
                    )}
                  </div>
                )}
              </div>
            ),
            sent: moment(result.updated).local().format("DD/MM/YYYY HH:mm:ss"),
          };
        })
        .value();
      data["rows"] = rows;
      return (
        <MDBContainer>
          {this.renderFilters()}
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody>
              {rows.map((value, index) => (
                <tr index={index}>
                  <td>{value.subject}</td>
                  <td>{value.recipient}</td>
                  <td>{value.recipient_name}</td>
                  <td>{value.type}</td>
                  <td>{value.message}</td>
                  <td>{value.sent}</td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
          <MDBRow>
            <MDBCol md="12">
              {this.renderTotalResult()}
              {resultContainer.totalPage != undefined ? (
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={resultContainer.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              ) : (
                ""
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }
    return <div></div>;
  }

  getValueOfSelectLimit = (value) => {
    value = value.length > 0 ? value[0] : "";
    this.setState({ limit: value });
    if (value != "") {
      this.debouncedDoFilter();
    }
  };

  getValueUser = (value) => {
    value = value.length > 0 ? value[0] : "";
    this.setState({ customer: value });
    if (value != "") {
      this.debouncedDoFilter();
    }
  };

  getValueType = (value) => {
    value = value.length > 0 ? value[0] : "";
    this.setState({ type: value });
    if (value != "") {
      this.debouncedDoFilter();
    }
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (typeof resultContainer !== "undefined") {
      return (
        <span>
          Showing Page {resultContainer.page} of {resultContainer.totalPage}
        </span>
      );
    }
    return <span></span>;
  }

  doFilter() {
    const {
      startDateFilter,
      endDateFilter,
      status,
      page,
      limit,
      query,
      type,
      customer,
    } = this.state;

    this.props.showHideLoader(true);

    let params = {};
    if (startDateFilter) {
      params["startDate"] = moment(startDateFilter).utc().format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["endDate"] = moment(endDateFilter).utc().format("YYYY-MM-DD");
    }
    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (type.length > 0) {
      params["notificationType"] = type;
    }

    if (customer.length > 0) {
      params["customer"] = customer;
    }

    if (query !== undefined) {
      params["query"] = query;
    }

    if (typeof this.props.customerId !== "undefined") {
      params["customerId"] = this.props.customerId;
    }

    if (typeof this.props.bookingId !== "undefined") {
      params["bookingId"] = this.props.bookingId;
    }

    this.props.getEmailLogs(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    this.debouncedDoFilter();
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    emailLogsResponse: state.emailLogsResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    getEmailLogs,
    showHideLoader,
  })(ReportEmailLog)
);
