import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Autocomplete from "react-autocomplete";

import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

import {
  displayBreadcrumbs,
  clearSuccessHandler,
  clearFailedHandler,
  alertMessage,
  showHideLoader,
  showNotification,
  toggleModal,
} from "../../../actions";
import {
  createUser,
  getUsers,
  resetPassword,
  updateUser,
  deleteUser,
} from "../../../actions/userAction";

import { getOrganisations } from "../../../actions/organisationAction";
import { getRoles } from "../../../actions/roleAction";

import _ from "lodash";
import moment from "moment";
import Modal from "../shared/Modal";
import { validateAccess } from "../../../helper/utils";

class ManageUser extends React.Component {
  state = {
    name: "",
    email: "",
    domain: "",
    formCleared: false,
    wasValidated: "",
    showModal: false,
    isModalTrigger: false,
    limit: "10",
    status: "",
    query: "",
    userId: "",
    page: "1",
    allowLogin: false,
    organisationResultContainer: [],
    roleId: "",
    roleOptions: [],
  };

  clearForm() {
    let { roleOptions } = { ...this.state };
    _.map(roleOptions, (option, index) => {
      roleOptions[index].checked = false;
    });

    this.setState({
      isEdit: false,
      name: "",
      allowLogin: false,
      email: "",
      isActive: false,
      userId: "",
      roleId: "",
      roleOptions: roleOptions,
      wasValidated: "",
    });
  }

  submitHandler = (event) => {
    const {
      name,
      email,
      isActive,
      allowLogin,
      loginId,
      domain,
      userId,
      isEdit,
      roleId,
    } = this.state;
    event.preventDefault();
    this.props.clearSuccessHandler();
    this.props.clearFailedHandler();
    this.setState({ wasValidated: "was-validated", formCleared: false });

    if (name.length === 0) {
      this.props.showNotification("error", "Please enter name");
    } else if (email.length === 0) {
      this.props.showNotification("error", "Please enter valid email");
    } else if ((roleId + "").length === 0) {
      this.props.showNotification("error", "Please select role");
    }

    if (event.target.checkValidity() && (roleId + "").length > 0) {
      console.log("pumasok ba d2?");
      this.props.showHideLoader(true);
      let body = {
        name: name,
        email: email,
        isActive: isActive,
        allowLogin: allowLogin,
        domain: domain,
        roleId: roleId,
      };
      let session = this.props.authUser.sessionToken;
      if (isEdit) {
        this.props.updateUser(userId, body, session);
      } else {
        this.props.createUser(body, session);
      }
    }
  };

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  componentDidMount() {
    this.initLimitOptions();

    this.props.showHideLoader(true);
    this.props.displayBreadcrumbs("Dashboard / Administrator / Users");

    this.props.getUsers({}, this.props.authUser.sessionToken);

    let params = {};
    params["limit"] = 1000;
    params["page"] = 1;
    params["q"] = "";

    this.props.getRoles(params, this.props.authUser.sessionToken);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userResponse !== this.props.userResponse) {
      let response = this.props.userResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.doFilter();
        }
      } else {
        this.setState({
          ...this.state,
          resultContainer: response.data,
          page: response.data.page,
        });
      }
    }

    this.getOrganisationsResult(prevProps, prevState);
    this.getRoleResult(prevProps, prevState);
  }

  getRoleResult(prevProps, prevState) {
    if (prevProps.roleResponse !== this.props.roleResponse) {
      let response = this.props.roleResponse;
      if (response.type == "GET_ROLES") {
        let roleOptions = [];

        _.map(this.props.roleResponse.data.results, (result) => {
          roleOptions.push({
            checked: false,
            disabled: false,
            text: result.role,
            value: result.id,
          });
        });

        this.setState({
          roleOptions: roleOptions,
        });
      }
    }
  }

  getOrganisationsResult(prevProps, prevState) {
    if (prevProps.organisationResponse !== this.props.organisationResponse) {
      this.props.showHideLoader(false);

      let resultContainer = _.chain(
        this.props.organisationResponse.data.results
      )
        .orderBy(["name"], ["asc"])
        .value();

      this.setState({
        organisationResultContainer: resultContainer,
      });
    }
  }

  modalConfig = () => {
    const modal = {
      isOpen: false,
      content: "",
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
    };
    this.props.toggleModal(modal);
  };

  doDelete() {
    const { userId } = this.state;
    this.props.showHideLoader(true);
    this.props.deleteUser(userId, this.props.authUser.sessionToken);
    this.closeModal();
  }

  doReset() {
    const { email, name, userId } = this.state;
    let body = {
      email: email,
      name: name,
      userId: userId,
    };
    this.props.showHideLoader(true);
    this.props.resetPassword(body, this.props.authUser.sessionToken);
    this.closeModal();
  }

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  confirmReset = (user) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want send reset password to ${user.name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Reset",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.doReset.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ email: user.email, name: user.name, userId: user.id });
  };

  confirmDelete = (user) => {
    const modal = {
      isOpen: true,
      content: `Are you sure you want to delete ${user.name}?`,
      title: "Confirmation",
      negativeButtonText: "Cancel",
      positiveButtonText: "Delete",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.doDelete.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ userId: user.id });
  };

  debouncedDoFilter = _.debounce(function () {
    this.props.showHideLoader(true);
    this.doFilter();
  });

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  getValueOfSelectRole = (value) => {
    this.setState({ roleId: value.length > 0 ? value[0] : "" });
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    return (
      <span>
        Showing Page {resultContainer.page} / {resultContainer.totalPage}
      </span>
    );
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);
    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  renderOrganisationAutocomplete() {
    return (
      <div className="autoComplete">
        <label htmlFor="states-autocomplete">Search organisation</label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "states-autocomplete" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          value={this.state.value}
          items={this.state.organisationResultContainer}
          getItemValue={(item) => item.domain + " - " + item.name}
          onSelect={(value, item) => {
            this.setState({
              value,
              organisationResultContainer: [item],
            });
            this.state.domain = item.domain;
          }}
          onChange={(event, value) => {
            clearTimeout(this.state.timer);
            let timer = setTimeout(() => {
              this.debouncedSearchOrganisations(value);
            }, 500);
            this.setState({
              ...this.state,
              timer: timer,
              value,
              domain: "",
            });
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? "item-highlighted" : ""}`}
              key={item.id}
            >
              {item.domain + " - " + item.name}
            </div>
          )}
        />
      </div>
    );
  }

  debouncedSearchOrganisations = _.debounce(function (val) {
    let params = { limit: 10, query: val, is_active: 1 };
    this.props.getOrganisations(params, this.props.authUser.sessionToken);
  });

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };

  doFilter() {
    this.props.showHideLoader(true);
    const { status, page, limit, query, resultContainer } = this.state;

    let params = {};

    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
      if (page > resultContainer.totalPage) {
        params["page"] = resultContainer.totalPage;
      } else if (page >= 1 && resultContainer.results.length <= 1) {
        params["page"] = resultContainer.totalPage - 1;
        this.setState({
          page: params["page"],
        });
      }
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (query !== undefined) {
      params["userName"] = query;
    }

    this.props.getUsers(params, this.props.authUser.sessionToken);
  }
  render() {
    return (
      <div>

        {this.renderForm()}
        {this.renderTable()}
      </div>
    );
  }

  resetForm = _.debounce(function () {
    if (!this.state.formCleared) {
      this.setState({
        name: "",
        email: "",
        loginId: "",
        domain: "Select Domain",
        formCleared: true,
        wasValidated: "",
        showModal: false,
        organisationResultContainer: [],
        value: "",
      });
      this.props.getUsers({}, this.props.authUser.sessionToken);
    }
  }, 1000);

  showModal = (showModal, isEdit, source) => {
    let { roleOptions } = { ...this.state };
    if (source !== null) {
      let roleId = source.role === undefined ? "" : source.role.id;
      if (roleId !== "" && roleId !== undefined) {
        console.log("roleOptions");
        console.log(roleOptions);
        _.map(roleOptions, (option, index) => {
          roleOptions[index].checked = false;
        });

        let index = _.findIndex(roleOptions, function (o) {
          return o.value === roleId;
        });
        if (index > -1) {
          roleOptions[index].checked = true;
        }
      }

      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        name: source.name,
        email: source.email,
        email: source.email,
        isActive: source.is_active,
        allowLogin: source.allow_login,
        userId: source.id,
        roleId: source.role === undefined ? "" : source.role.id,
        roleOptions: roleOptions,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }

    if (!showModal) {
      this.resetForm();
    }
  };

  validateDomain() {
    if (this.props.userResponse.allowedDomains !== undefined) {
      return (
        <MDBCol md="6" className="mb-3">
          <label htmlFor="idDomain" className="grey-text">
            Domain
          </label>
          <div className="form-group">
            <select
              className="custom-select bMDBRowser-default"
              name="domain"
              required
              onChange={this.formDataHandler}
            >
              <option value="">Select Domain</option>
              {this.props.userResponse.allowedDomains.map((org, index) => (
                <option key={index} value={org.domain}>
                  {org.name}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select domain</div>
          </div>
        </MDBCol>
      );
    } else {
      if (typeof this.props.authUser.organisation !== undefined) {
        let isUnifi = this.props.authUser.organisation === "unifi";
        if (isUnifi) {
          return (
            <MDBCol md="12" className="mb-3">
              {this.renderOrganisationAutocomplete()}
            </MDBCol>
          );
        } else {
          return <span></span>;
        }
      }
    }
  }

  getValueOfAllowLogin = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
  };

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  onIsCheckChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };

  renderForm() {
    const {
      showModal,
      name,
      email,
      isActive,
      allowLogin,
      domain,
      isEdit,
      roleOptions,
    } = this.state;
    let options = [
      {
        checked: true,
        disabled: false,
        text: "Yes",
        value: "1",
      },
      {
        checked: false,
        disabled: false,
        text: "No",
        value: "0",
      },
    ];
    return (
      <div>
        <MDBModal
          isOpen={showModal}
          size="lg"
          toggle={() => this.showModal(false, false, null)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>{isEdit ? "Update" : "Add"} User</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <MDBInput
                    required
                    label="Name"
                    labelClass="required"
                    value={name}
                    name="name"
                    onChange={this.formDataHandler}
                    id="idName"
                  />

                  {/* <label htmlFor="idName" className="grey-text">
                    Name
                  </label>
                  <input
                    value={name}
                    name="name"
                    onChange={this.formDataHandler}
                    type="text"
                    id="idName"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide the name
                  </div> */}
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  {/* <label htmlFor="idEmail" className="grey-text">
                    Email
                  </label>
                  <input
                    value={email}
                    onChange={this.formDataHandler}
                    type="email"
                    id="idEmail"
                    className="form-control"
                    name="email"
                    placeholder="Email address"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid email address
                  </div> */}

                  <MDBInput
                    required
                    label="Email address"
                    labelClass="required"
                    value={email}
                    name="email"
                    onChange={this.formDataHandler}
                    type="email"
                    id="idEmail"
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBSelect
                    outline
                    labelClass="required"
                    name="Role"
                    color="primary"
                    required
                    getValue={this.getValueOfSelectRole}
                    options={roleOptions}
                    label="Role"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="allowLogin"
                      name="allowLogin"
                      defaultChecked={allowLogin}
                      onChange={this.onIsCheckChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="allowLogin"
                      style={{
                        fontWeight: "bold",
                        marginTop: "35px !important",
                      }}
                    >
                      Allow Login
                    </label>
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkActive"
                      name="isActive"
                      defaultChecked={isActive}
                      onChange={this.onIsCheckChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkActive"
                      style={{
                        fontWeight: "bold",
                        marginTop: "35px !important",
                      }}
                    >
                      Active
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              {isEdit ? (
                <span></span>
              ) : (
                  <MDBRow>{this.validateDomain()}</MDBRow>
                )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.showModal(false, false, null)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }

  renderFilters() {
    const { limitOptions, statusOptions } = this.state;
    const { storeUserAccess } = this.props;
    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Users</h3>
          </MDBCol>
          {validateAccess(storeUserAccess, "Users", "add") && (
            <MDBCol md="6">
              <MDBBtn
                color="primary"
                onClick={() => this.showModal(true, false, null)}
                className="float-right"
              >
                Add User
              </MDBBtn>
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              name="limit"
              color="primary"
              getValue={this.getValueOfSelectLimit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search user by keyword(s)."
                name="query"
                onChange={this.changeHandler}
                style={{ width: "230px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    if (resultContainer == undefined) {
      return;
    }
    const data = {
      columns: [
        {
          label: "Organisation",
          field: "heading0",
          sort: "asc",
        },
        {
          label: "Role",
          field: "heading1",
          sort: "asc",
        },
        {
          label: "Name",
          field: "heading2",
          sort: "asc",
        },
        {
          label: "Email",
          field: "heading3",
          sort: "asc",
        },
        {
          label: "Status",
          field: "heading4",
          sort: "asc",
        },
        {
          label: "Allow Login",
          field: "heading5",
          sort: "asc",
        },
        {
          label: "Created",
          field: "heading6",
          sort: "asc",
        },
        {
          label: "Updated",
          field: "heading7",
          sort: "asc",
        },
        {
          label: "",
          field: "heading8",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    rows = _.chain(resultContainer.results)
      .map((user, index) => {
        return {
          heading0: user.organisation.name,
          heading1: user.role === undefined ? "" : user.role.role,
          heading2: user.name,
          heading3: user.email,
          heading4: user.is_active === 1 ? "Active" : "Inactive",
          heading5: user.allow_login === 1 ? "Yes" : "No",
          heading6: moment(user.created, "YYYY-MM-DD HH:mm:ss")
            .local()
            .format("DD/MM/YYYY HH:mm:ss"),
          heading7: moment(user.updated, "YYYY-MM-DD HH:mm:ss")
            .local()
            .format("DD/MM/YYYY HH:mm:ss"),
          heading8: (
            <div>
              {validateAccess(storeUserAccess, "Users", "resetPassword") && (
                <MDBBtn
                  color="primary"
                  size="sm"
                  className="float-right"
                  onClick={() => this.confirmReset(user)}
                >
                  Reset Password
                </MDBBtn>
              )}
              {validateAccess(storeUserAccess, "Users", "delete") && (
                <MDBBtn
                  color="danger"
                  size="sm"
                  className="float-right"
                  onClick={() => this.confirmDelete(user)}
                >
                  Delete
                </MDBBtn>
              )}
              {validateAccess(storeUserAccess, "Users", "update") && (
                <MDBBtn
                  color="primary"
                  size="sm"
                  className="float-right"
                  onClick={() => this.showModal(true, true, user)}
                >
                  Edit
                </MDBBtn>
              )}
            </div>
          ),
        };
      })
      .value();
    data["rows"] = rows;
    return (
      <MDBContainer>
        {this.renderFilters()}
        <MDBTable responsive striped bordered>
          <MDBTableHead columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBRow>
          <MDBCol md="12">
            {this.renderTotalResult()}
            <div className="float-right">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={resultContainer.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    authUser: state.authUser,
    alert: state.alert,
    userResponse: state.userResponse,
    organisationResponse: state.organisationResponse,
    roleResponse: state.roleResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    createUser,
    clearSuccessHandler,
    clearFailedHandler,
    alertMessage,
    showHideLoader,
    getUsers,
    showNotification,
    resetPassword,
    updateUser,
    deleteUser,
    toggleModal,
    getOrganisations,
    getRoles,
  })(ManageUser)
);
