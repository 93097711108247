import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutPageFormWrapper from "./CheckoutPageFormWrapper";
import { validateLayout } from "../../../actions";
import { lockBookingRequest } from "../../../actions/bookingAction";

import Stepper from "react-stepper-horizontal";

import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBCardBody,
} from "mdbreact";

import { getBookingRequest } from "../../../actions/bookingAction";
import { setWizardStep, showHideLoader, setIntervalId } from "../../../actions";

const CheckoutPage = (props) => {
  const [bookingRequestUUID, setBookingRequestUUID] = useState(null);

  const [wizardStep, setCurrentWizardStep] = useState(null);

  const [clientSecretKey, setClientSecretKey] = useState(null);
  const [options, setOptions] = useState({});
  const [bookingRequestStatus, setBookingRequestStatus] = useState(null);
  const [locationUUID, setLocationUUID] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  //Count down timer
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [stripePromise, setStripePromise] = useState(null);

  const defaultDisplayDetails = {
    mainCustomer: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
    },
    participantsData: [],
    locationName: "",
    resourceName: "",
    timeDuration: "",
    reservationDate: "",
    checkBookingRequestLockIntervalId: "",
  };

  const confirmedBookingDisplayDefaultDetails = {
    bookingReferenceNumber: "",
    participantsData: [],
    locationName: "",
    resourceName: "",
    timeDuration: "",
    reservationDate: "",
  };

  const [confirmedBooking, setConfirmedBooking] = useState(
    confirmedBookingDisplayDefaultDetails
  );

  const [bookingRequestDisplayDetails, setBookingRequestDisplayDetails] =
    useState(defaultDisplayDetails);

  const steps = [
    {
      title: "PAYMENT DETAILS",
      onClick: (e) => {
        e.preventDefault();
        props.setWizardStep(0);
      },
    },
    {
      title: "PROCESS PAYMENT",
      onClick: (e) => {
        e.preventDefault();
        // props.setWizardStep(1);
      },
    },
    {
      title: "CREATING YOUR BOOKING",
      onClick: (e) => {
        e.preventDefault();
        // props.setWizardStep(2);
      },
    },
    {
      title: "BOOKING CONFIRMED",
      onClick: (e) => {
        e.preventDefault();
        // props.setWizardStep(3);
      },
    },
  ];

  useEffect(() => {
    props.showHideLoader(true);
    const params = new URLSearchParams(props.location.search);
    const bookingRequestUUIDParam = params.get("booking_request_uuid");
    setBookingRequestUUID(bookingRequestUUIDParam);

    props.getBookingRequest(bookingRequestUUIDParam, "");

    //Hide the headers and footer
    if (props.authUser.sessionToken === "") {
      const layout = {
        showBanner: false,
        showFooter: true,
        showSearch: false,
        showHeader: true,
        showLogin: true,
      };
      props.validateLayout(layout);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      } else if (seconds === 0 && minutes > 0) {
        setSeconds(59);
        setMinutes((minutes) => minutes - 1);
      } else if (minutes === 0 && hours > 0) {
        setSeconds(59);
        setMinutes(59);
        setHours((hours) => hours - 1);
      } else if (hours === 0 && days > 0) {
        setSeconds(59);
        setMinutes(59);
        setHours(23);
        setDays((days) => days - 1);
      } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        // Expired booking request
        setCurrentWizardStep(null);

        if (bookingRequestStatus === 0) {
          setBookingRequestStatus(4);
        }
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes, days, hours]);

  useEffect(() => {
    if (props.activeWizardStep >= 0) {
      setTimeout(function () {
        setCurrentWizardStep(props.activeWizardStep);
        props.showHideLoader(false);
      }, 500);
    }

    if (props.activeWizardStep === 2) {
      //Start checking booking request status
      const intervalId = setInterval(() => {
        props.getBookingRequest(bookingRequestUUID, "");
      }, 5000);
      props.setIntervalId(intervalId);
    }
  }, [props.activeWizardStep]);

  const setRemainingTimer = (expirationDateTime) => {
    const secs = expirationDateTime.seconds;
    const remainingDays = expirationDateTime.days;
    //
    let remainingHours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let remainingMinutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let remainingSeconds = Math.ceil(divisor_for_seconds);

    setHours(remainingHours);
    setMinutes(remainingMinutes);
    setSeconds(remainingSeconds);
    setDays(remainingDays);
  };

  useEffect(() => {
    if (
      props.bookingRequestResponse.action === "GET_BOOKING_REQUEST" &&
      props.bookingRequestResponse.status === 200
    ) {
      const dataResponse = props.bookingRequestResponse.data.response;

      setBookingRequestStatus(dataResponse.status);
      props.showHideLoader(false);

      if (!stripePromise) {
        const stripePublishableKey = dataResponse.publishable_stripe_key;
        const promise = loadStripe(stripePublishableKey); //load it only once
        console.log("loaded Stripe");

        setStripePromise(promise);
      }

      if (
        dataResponse.status === 0 &&
        (dataResponse.expiration_date_time.days > 0 ||
          dataResponse.expiration_date_time.seconds > 0)
      ) {
        //Pending
        const clientSecret = dataResponse.client_secret;
        setClientSecretKey(clientSecret);

        setRemainingTimer(dataResponse.expiration_date_time);

        setBookingRequestDisplayDetails({
          mainCustomer: dataResponse.customerData,
          participantsData: dataResponse.participantsData,
          locationName: dataResponse.location_name,
          resourceName: dataResponse.resource_name,
          timeDuration: dataResponse.time_from + " - " + dataResponse.time_to,
          reservationDate: dataResponse.date,
          totalAmount: dataResponse.total_amount,
        });
        setTotalAmount(dataResponse.total_amount);

        setOptions({
          clientSecret: clientSecret,
        });

        if (wizardStep === null) {
          // Initial load only
          props.setWizardStep(0);

          var lockIntervalId = setInterval(() => {
            props.lockBookingRequest({}, bookingRequestUUID);
          }, 10000);

          setTimeout(function () {
            clearInterval(lockIntervalId);
          }, 10 * 60 * 1000); //Clear the interval after 10 mins
        }
      } else if (dataResponse.status === 1) {
        //Completed
        //Clear interval of checking booking request

        const bookingObj = dataResponse.booking_obj;

        if (Object.keys(bookingObj).length > 0) {
          if (bookingObj.participants) {
            clearInterval(props.intervalIdState);
            props.setWizardStep(3);
            const participantsData = bookingObj.participants.map(
              (participant) => {
                return {
                  first_name: participant.customer.firstName,
                  last_name: participant.customer.lastName,
                  email: participant.customer.email,
                  mobile: participant.customer.mobile,
                  booking_reference_number:
                    participant.booking_participant_reference,
                };
              }
            );
            setConfirmedBooking({
              bookingReferenceNumber: bookingObj.booking_reference,
              participantsData: participantsData,
              locationName: bookingObj.location.name,
              resourceName: bookingObj.resource.name,
              startTime: bookingObj.start_time,
              endTime: bookingObj.end_time,
            });
          }
        }

        props.showHideLoader(false);
      } else if (dataResponse.status === 4) {
        // Expired booking request
        clearInterval(props.intervalIdState);
        setLocationUUID(dataResponse.location_uuid);

        setCurrentWizardStep(null);
      }
    }
  }, [props.bookingRequestResponse]);

  return (
    <MDBContainer fluid={true} className="mt-4 ">
      <MDBRow>
        <MDBCol>
          <Stepper steps={steps} activeStep={wizardStep} />
        </MDBCol>
      </MDBRow>

      {bookingRequestStatus === 4 ? (
        <MDBCard className="mt-4">
          <MDBCardBody
            style={{
              minHeight: props.browserSize.lessThan.medium ? "500px" : "650px",
            }}
          >
            <MDBRow className="text-center mt-4">
              <MDBCol md="12">
                {" "}
                <h3>
                  {" "}
                  Oops…this booking request has expired. Please make another
                  booking.
                </h3>
              </MDBCol>
            </MDBRow>

            <MDBRow className="text-center">
              <MDBCol md="12">
                <h5>
                  <a href={"/admin/customerbooking?uuid=" + locationUUID}>
                    Click here to make another booking
                  </a>
                </h5>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      ) : (
        <>
          {wizardStep === 0 && (
            <MDBRow className="mt-4 d-flex justify-content-center">
              <MDBCard className="container-fluid">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol md="7">
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol md="12">
                            <h3 className="mt-3 text-center">
                              Your Requested Booking
                            </h3>
                            <h5 className="mt-3 text-center">
                              Please review your booking and click Pay to
                              proceed to the payment process.
                            </h5>
                            <h5 className="mt-3 text-center">
                              {String(days).padStart(2, "0")}:
                              {String(hours).padStart(2, "0")}:
                              {String(minutes).padStart(2, "0")}:
                              {String(seconds).padStart(2, "0")}
                            </h5>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="mt-4">
                          <MDBTable responsive striped bordered>
                            <MDBTableHead>
                              <tr>
                                <th className="text-center" width="20%">
                                  Name
                                </th>
                                <th className="text-center" width="20%">
                                  {" "}
                                  Email
                                </th>
                                <th className="text-center" width="20%">
                                  Phone
                                </th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {bookingRequestDisplayDetails.participantsData.map(
                                (participant) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          {participant["first_name"] +
                                            " " +
                                            participant["last_name"]}
                                        </td>
                                        <td>{participant["email"]}</td>
                                        <td>{participant["mobile"]}</td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </MDBTableBody>
                          </MDBTable>
                        </MDBRow>

                        <MDBCol md="12">
                          <div className="mt-3">
                            <span className="font-weight-bold">Location:</span>{" "}
                            {bookingRequestDisplayDetails.locationName}
                          </div>
                        </MDBCol>

                        <MDBCol md="12">
                          <div className="mt-3 ">
                            <span className="font-weight-bold">
                              Court/Field:
                            </span>{" "}
                            {bookingRequestDisplayDetails.resourceName}
                          </div>
                        </MDBCol>

                        <MDBCol md="12">
                          <div className="mt-3 ">
                            <span className="font-weight-bold">Date:</span>{" "}
                            {moment(
                              bookingRequestDisplayDetails.reservationDate
                            )
                              .local()
                              .format("DD/MM/YY")}
                          </div>
                        </MDBCol>

                        <MDBCol md="12">
                          <div className="mt-3">
                            <span className="font-weight-bold">Time:</span>{" "}
                            {bookingRequestDisplayDetails.timeDuration}
                          </div>
                        </MDBCol>
                      </MDBContainer>
                    </MDBCol>
                    <MDBCol md="5">
                      <Elements stripe={stripePromise} options={options}>
                        {clientSecretKey && (
                          <CheckoutPageFormWrapper
                            clientSecretKey={clientSecretKey}
                            bookingRequestUUID={bookingRequestUUID}
                            totalAmount={totalAmount}
                          />
                        )}
                      </Elements>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          )}

          {wizardStep === 1 && (
            <MDBCard className="mt-4">
              <MDBCardBody
                style={{
                  minHeight: props.browserSize.lessThan.medium
                    ? "500px"
                    : "650px",
                }}
              >
                <MDBRow className="text-center mt-4">
                  <MDBCol md="12">
                    {" "}
                    <h3>We're processing your credit card details.</h3>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="text-center ">
                  <MDBCol md="12">
                    {" "}
                    <h6>
                      This process may take a few seconds. Please do not click
                      back button
                    </h6>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="d-flex justify-content-center  mt-4">
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}

          {wizardStep === 2 && (
            <MDBCard className="mt-4">
              <MDBCardBody
                style={{
                  minHeight: props.browserSize.lessThan.medium
                    ? "500px"
                    : "650px",
                }}
              >
                <MDBRow className="text-center mt-4">
                  <MDBCol md="12">
                    {" "}
                    <h3>
                      {" "}
                      Payment confirmed! We are now finalizing your booking.
                    </h3>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="text-center ">
                  <MDBCol md="12">
                    {" "}
                    <h6>
                      This process may take a few seconds. Please do not click
                      back button
                    </h6>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="d-flex justify-content-center  mt-4">
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  <div class="spinner-grow spinner-grow-sm" role="status"></div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}

          {wizardStep === 3 && (
            <MDBCard className="mt-4">
              <MDBCardBody
                style={{
                  minHeight: props.browserSize.lessThan.medium
                    ? "500px"
                    : "650px",
                }}
              >
                <MDBRow>
                  {" "}
                  <MDBCol md="3"></MDBCol>
                  <MDBCol md="6">
                    <MDBRow className="d-flex justify-content-center mt-4">
                      <MDBCol md="12">
                        <h3 className="mt-3 text-center">
                          Great! You have successfully made a booking!
                        </h3>
                        <h5 className="mt-3 text-center">
                          Here is your booking summary
                        </h5>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBTable small borderless>
                        <MDBTableBody>
                          <tr>
                            <td className="font-weight-bold">
                              Booking Reference:
                            </td>
                            <td> {confirmedBooking.bookingReferenceNumber}</td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">Location:</td>
                            <td> {confirmedBooking.locationName} </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">Court/Field:</td>
                            <td> {confirmedBooking.resourceName}</td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">Start Time:</td>
                            <td>
                              {" "}
                              {moment(confirmedBooking.startTime).format(
                                "h:mma [on the] Do MMMM YYYY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">End Time:</td>
                            <td>
                              {moment(confirmedBooking.endTime).format(
                                "h:mma [on the] Do MMMM YYYY"
                              )}
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBRow>

                    <MDBRow className="mt-4">
                      <p className="font-weight-bold">Participants:</p>
                    </MDBRow>
                    <MDBRow>
                      <MDBTable responsive striped bordered>
                        <MDBTableHead>
                          <tr>
                            <th className="text-center" width="20%">
                              Name
                            </th>
                            <th className="text-center" width="20%">
                              {" "}
                              Email
                            </th>
                            <th className="text-center" width="20%">
                              Phone
                            </th>
                            <th className="text-center" width="20%">
                              Booking Reference Number
                            </th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {confirmedBooking.participantsData.map(
                            (participant) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      {participant["first_name"] +
                                        " " +
                                        participant["last_name"]}
                                    </td>
                                    <td>{participant["email"]}</td>
                                    <td>{participant["mobile"]}</td>
                                    <td>
                                      {participant["booking_reference_number"]}
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </MDBTableBody>
                      </MDBTable>
                    </MDBRow>

                    <MDBRow className="d-flex justify-content-center mt-4">
                      <p>
                        Please get in touch with us on +61297475055 or email at
                        proshop@strathfieldsportsclub.com.au if you need to
                        change this, otherwise, we'll see you soon.
                      </p>
                    </MDBRow>

                    <MDBRow
                      className="p-4"
                      style={{ backgroundColor: "#363638", color: "#ffffff" }}
                    >
                      <MDBCol md="12">
                        <p className="text-center">
                          &copy; 2022 Unified Bookings. All rights reserved.
                        </p>
                        <p className="text-center">
                          Strathfield Sports Club
                          <br />
                          4a Lyons Street
                          <br />
                          (PO Box 28)
                          <br />
                          Strathfield NSW 2135
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}
        </>
      )}
    </MDBContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    bookingRequestResponse: state.bookingRequestResponse,
    activeWizardStep: state.activeWizardStep,
    intervalIdState: state.intervalId,
    browserSize: state.browserSize,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    validateLayout,
    getBookingRequest,
    setWizardStep,
    showHideLoader,
    setIntervalId,
    lockBookingRequest,
  })(CheckoutPage)
);
