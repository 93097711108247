import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_USER_FAVOURITES,
  GET_USER_FAVOURITE_RESPONSE_HANDLER,
} from "./types";

export const getUserFavourites = (params, sessionToken) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
  };

  const response = await bookingAppPrivate
    .get(`/users/favourites`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_USER_FAVOURITES,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: GET_USER_FAVOURITE_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const insertFavouriteCustomer =
  (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/users/favourites`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_USER_FAVOURITE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_USER_FAVOURITE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

export const updateFavouriteCustomer =
  (body, favouriteId, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/users/favourites/${favouriteId}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_USER_FAVOURITE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_USER_FAVOURITE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };

// export const clearUpdateMembershipSuccessResponse = () => {
//     return {
//         type: GET_USER_FAVOURITE_RESPONSE_HANDLER,
//         payload: {},
//     };
// };

// export const clearUpdateMembershipFailedResponse = () => {
//     return {
//         type: GET_USER_FAVOURITE_RESPONSE_HANDLER,
//         payload: {},
//     };
// };

export const deleteFavouriteCustomer =
  (favouriteId, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .delete(`users/favourites/${favouriteId}`, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: GET_USER_FAVOURITE_RESPONSE_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_USER_FAVOURITE_RESPONSE_HANDLER,
          payload: errRes,
        });
      });
  };
