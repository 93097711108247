import { REQUEST_PAYMENT } from "../actions/types";

export default (
  details = {
    resourceData: {},
    priceData: {},
    customerData: {},
  },
  action
) => {
  if (action.type === REQUEST_PAYMENT) {
    return action.payload;
  }
  return details;
};
