import bookingAppPrivate from "../apis/bookingAppPrivate";
import { GET_NOTIFICATION_LOGS } from "./types";


export const getEmailLogs = (params, sessionToken) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate.get(`/notifications/logs`, {
        headers: headers,
        params: params
    });

    dispatch({ type: GET_NOTIFICATION_LOGS, payload: response });
};
