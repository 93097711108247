import React from "react";
import { connect } from "react-redux";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { showHideLoader, showNotification } from "../../../actions";

class CreditHistoryModal extends React.Component {
  state = {};

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {}

  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={this.props.isShowCreditHistoryModal}
          size="lg"
          toggle={() => this.props.toggleCreditHistoryModal(false)}
        >
          <MDBModalHeader>Credit History</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="12">
                <MDBTable striped bordered>
                  <MDBTableHead>
                    <tr>
                      {/* <th className="text-center" width="10%">
                          Transaction Type
                        </th> */}
                      <th className="text-center" width="10%">
                        Amount
                      </th>
                      <th className="text-center" width="10%">
                        Booking Reference
                      </th>
                      <th className="text-center" width="20%">
                        User
                      </th>
                      <th className="text-center" width="30%">
                        Description
                      </th>
                      <th className="text-center" width="20%">
                        Date
                      </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    <>
                      {this.props.creditHistory.map((history) => {
                        return (
                          <tr>
                            {/* <td>{history.transaction_type}</td> */}
                            <td>${history.amount.toFixed(2)}</td>
                            <td>{history.booking_reference}</td>
                            <td>{history.user_name}</td>
                            <td>{history.comment}</td>
                            <td>{history.created}</td>
                          </tr>
                        );
                      })}
                    </>
                  </MDBTableBody>
                </MDBTable>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => this.props.toggleCreditHistoryModal(false)}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
  };
};

export default connect(mapStateToProps, {
  showHideLoader,
  showNotification,
})(CreditHistoryModal);
