import { GET_AUDITLOGS } from "../actions/types";

export default (state = {}, action) => {
    switch (action.type) {
        case GET_AUDITLOGS:
            return action.payload;
        default:
            return state;
    }
};
