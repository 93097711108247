import UbsApiV2 from "./UbsApiV2";

export const getCourtUsageReport = async (params = {}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.get(`/ssc/reports/court_usage`, {
    headers: headers,
    params: params,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const getMemberUsageReport = async (params = {}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.get(`/ssc/reports/member_usage`, {
    headers: headers,
    params: params,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const getMembersList = async (searchText) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.get(`/ssc/reports/get_member_list`, {
    headers: headers,
    params: {
      search_member_text: searchText,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};
