import React from "react";
import { Route, Switch } from "react-router-dom";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";

import Dashboard from "../admin/Dashboard";
import DashboardContent from "../admin/DashboardContent";
import CourtUsageReport from "../admin/CourtUsageReport";
import MemberUsageReport from "../admin/MemberUsageReport";

import ManageUser from "../admin/ManageUser";
import SelectOrganisation from "../admin/SelectOrganisation";
import ManageCustomer from "../admin/ManageCustomer";
import FavouriteList from "../admin/FavouriteList";
import ManageCredit from "../admin/ManageCredit";
import ManageVoucher from "../admin/ManageVoucher";
import ManageRole from "../admin/ManageRole";
import ManageLocation from "../admin/ManageLocation";
import ManageResource from "../admin/ManageResource";
import ReportBooking from "../admin/ReportBooking";
import BookingReport from "../admin/BookingReport";
import ReportAuditLog from "../admin/ReportAuditLog";
import ReportEmailLog from "../admin/ReportEmailLog";
import PaymentReports from "../admin/PaymentReports";
import PaymentPendingReport from "../admin/PaymentPendingReport";
import FourZeroFour from "../shared/FourZeroFour";
import BookingPrivate from "../admin/BookingPrivate";
import BookingPrivatev2 from "../admin/BookingPrivatev2";
import { setAuthUser, getHeartBeat } from "../../../actions/authAction";
import {
  showHideLoader,
  storeUserAccess,
  toggleModal,
  pauseBackgroundRequests,
} from "../../../actions";
import { USER_COOKIES_KEY, USER_TMP } from "../../../helper";
import ManageOrganisation from "../admin/ManageOrganisation";
import ManageOrganisationSettings from "../admin/ManageOrganisationSettings";
import ManageRecurring from "../admin/ManageRecurring";
import Fingerprint2 from "fingerprintjs2";
import ManageMembership from "../admin/ManageMembership";
import { getUserRoleAccess } from "../../../actions/roleAction";
import { validateAccess, buildAccessObject } from "../../../helper/utils";
import ManageRules from "../admin/ManageRules";
import RulesEngine from "../admin/RulesEngine";
import ManageOrganisationPages from "../admin/ManageOrganisationPages";
import CheckoutPage from "../admin/CheckoutPage";
import PaymentProcessPage from "../admin/PaymentProcessPage";
import Pricing from "../admin/pricing/Pricing";
import PricingTrigger from "../admin/pricing/PricingTrigger";

import CustomerBookingPrivate from "../admin/CustomerBookingPrivate";
import MyCalendarPrivate from "../admin/MyCalendarPrivate";
import { getUserDetails } from "../../../actions/userAction";

class PrivateLayout extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  isHeartBeatActive = false;

  constructor(props) {
    super(props);
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  state = {
    accessContainer: undefined,
    idleTime: 60000,
  };

  handleOnAction(event) {}

  handleOnActive(event) {}

  handleOnIdle(event) {
    this.props.pauseBackgroundRequests(true);
    this.showModal();
  }

  doExtendSession() {
    // this.checkHeartbeat();
    // this.intervalId = setInterval(this.checkHeartbeat.bind(this), 10000);
    this.props.pauseBackgroundRequests(false);
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.props.getUserDetails(this.props.authUser.sessionToken);
  }

  doCloseModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  showModal() {
    let idleTimeInMinute = this.state.idleTime / 60 / 1000;
    const modal = {
      isOpen: true,
      content: (
        <div className="text-center">
          {`You have been Idle for ${idleTimeInMinute} minute${
            idleTimeInMinute > 1 ? "s" : ""
          }. Click continue if you want to continue your session, otherwise click logout.`}
        </div>
      ),
      title: "Warning!",
      negativeButtonText: "Logout",
      positiveButtonText: "Continue",
      positiveButtonColor: "success",
      negativeButtonAction: this.doLogout.bind(this),
      positiveButtonAction: this.doExtendSession.bind(this),
      allowToggle: false,
    };
    this.props.toggleModal(modal);
  }

  componentDidMount() {
    const { cookies } = this.props;
    let hasFingerprint = cookies.get("fingerprint") != "";
    if (hasFingerprint) {
      if (window.requestIdleCallback) {
        requestIdleCallback(function () {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var fingerHashed = Fingerprint2.x64hash128(values.join(""), 31);
            cookies.set("fingerprint", fingerHashed);
          });
        });
      } else {
        setTimeout(function () {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var fingerHashed = Fingerprint2.x64hash128(values.join(""), 31);
            cookies.set("fingerprint", fingerHashed);
          });
        }, 500);
      }
    }
    this.getUserCookies();
    this.intervalId = setInterval(this.checkHeartbeat.bind(this), 10000);

    // this.tempPopulateRole();
  }

  getRoleAccess(prevProps, prevState) {
    if (prevProps.authUser !== this.props.authUser) {
      // console.log(this.props.authUser.role);
      if (
        this.props.authUser.role !== undefined &&
        this.props.authUser.role.id !== undefined
      ) {
        this.props.showHideLoader(true);
        this.props.getUserRoleAccess(
          { roleId: this.props.authUser.role.id },
          this.props.authUser.sessionToken
        );
      }
    }
  }

  getRoleResult(prevProps, prevState) {
    if (prevProps.roleResponse !== this.props.roleResponse) {
      let response = this.props.roleResponse;
      if (response.type == "GET_USER_ROLE_ACCESS") {
        let accessObject = buildAccessObject(response.data.result);
        let accessContainer = {
          type: "STORE_USER_ACCESS",
          data: accessObject,
        };
        // console.log("accessObject");
        // console.log(JSON.stringify(accessObject));
        this.setState({ accessContainer: accessContainer });
        this.props.storeUserAccess(accessObject);
        this.props.showHideLoader(false);
      }
    }
  }

  tempPopulateRole() {
    let accessObject = {
      Venues: {
        enable: true,
      },
      Administrator: {
        enable: true,
      },
      genericSearch: {
        enable: false,
      },
      Organisations: {
        enable: true,
        action: {
          viewSettings: true,
        },
      },
      OrganisationConfigurationSettings: {
        enable: true,
        action: {
          viewConfigs: true,
          updateConfig: true,
        },
      },
      Locations: {
        enable: true,
        action: {
          attributes: true,
          add: true,
          update: true,
          delete: true,
          resource: true,
        },
      },
      LocationAttributes: {
        enable: true,
        action: {
          add: true,
          update: true,
        },
      },
      LocationResources: {
        enable: true,
        action: {
          attributes: true,
          add: true,
          update: true,
          delete: true,
        },
      },
      LocationResourceAttributes: {
        enable: true,
        action: {
          add: true,
          update: true,
        },
      },
      Customers: {
        enable: true,
        action: {
          add: true,
          update: true,
          delete: true,
          viewBookings: true,
          viewBookingDetails: true,
        },
      },
      Vouchers: {
        enable: true,
        action: {
          add: true,
          update: true,
          delete: true,
        },
      },
      Users: {
        enable: true,
        action: {
          add: true,
          update: true,
          delete: true,
          resetPassword: true,
        },
      },
      Bookings: {
        enable: true,
        action: {
          viewBookingDetails: true,
        },
      },
      RecurringBookings: {
        enable: true,
        action: {
          viewBookings: true,
          cancelRecurring: true,
          viewBookingDetails: true,
        },
      },
      EmailLogs: {
        enable: true,
        action: {
          previewEmail: true,
        },
      },
      AuditLogs: {
        enable: true,
      },
      AdminBooking: {
        enable: true,
        action: {
          addBooking: true,
          updateBooking: false,
          viewBooking: true,
        },
      },
      BookingDetails: {
        enable: true,
        action: {
          bookingDetailsTab: true,
          paymentsTab: true,
          statusTab: false,
          notesTab: false,
          participantsTab: false,
          communicationsTab: false,
          sendPaymentRequest: false,
          issueVoucher: false,
          manualPaid: false,
          cancelBooking: false,
          paymentOptionCash: false,
          paymentOptionVoucher: true,
          paymentOptionCreditCard: true,
          allowBeyondMaxDuration: false,
          allowRecurringBooking: false,
          allowCustomerSearch: false,
        },
      },
      Memberships: {
        enable: true,
        action: {
          AddMembershipPlan: true,
          edit: true,
          delete: true,
          viewCustomers: true,
          addCustomer: true,
        },
      },
      Roles: {
        enable: true,
        action: {
          add: true,
          manageAccess: true,
          delete: true,
          edit: true,
        },
      },
      Rules: {
        enable: true,
        action: {
          manageRule: true,
        },
      },
      DefaultUserMenu: {
        enable: true,
        action: {
          userVenues: true,
          userDetails: true,
        },
      },
    };
    let accessContainer = {
      type: "STORE_USER_ACCESS",
      data: accessObject,
    };
    this.setState({ accessContainer: accessContainer });
    this.props.storeUserAccess(accessObject);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authResponse !== this.props.authResponse) {
      if (
        this.props.authResponse.status === 401 ||
        this.props.authResponse.status == 403
      ) {
        this.doLogout();
        return;
      }
    }

    this.getRoleAccess(prevProps, prevState);
    this.getRoleResult(prevProps, prevState);
  }

  checkHeartbeat() {
    if (this.props.authUser.sessionToken !== undefined) {
      this.props.getHeartBeat(this.props.authUser.sessionToken);
    }
  }

  doLogout() {
    this.props.showHideLoader(false);
    this.removeCookies(USER_COOKIES_KEY);
    this.removeCookies(USER_TMP);
    clearInterval(this.intervalId);
    this.doCloseModal();
    this.props.history.push("/login");
  }

  getUserCookies() {
    const { cookies } = this.props;
    let userCookies = cookies.get(USER_COOKIES_KEY);
    if (userCookies !== undefined) {
      this.props.setAuthUser(userCookies);
    } else {
      window.location.replace("/login");
    }
  }

  removeCookies(key) {
    const { cookies } = this.props;
    cookies.remove(key, { path: "/" });
  }

  renderContent() {
    const { accessContainer } = this.state;

    // if (!(this.props.authUser?.role?.role == "Administrator")) {
    //   window.location.replace("/maintenance");
    // }

    if (accessContainer !== undefined) {
      return (
        <div className="admin-page">
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={this.state.idleTime}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
          <Switch>
            <Dashboard>
              <Switch>
                <Route
                  path="/admin/dashboard"
                  exact
                  component={DashboardContent}
                />
                {validateAccess(
                  accessContainer,
                  "OrganisationConfigurationSettings",
                  ""
                ) && (
                  <Route
                    path="/admin/manageorgsettings"
                    exact
                    component={ManageOrganisationSettings}
                  />
                )}
                {validateAccess(accessContainer, "Organisations", "") && (
                  <Route
                    path="/admin/manageorg"
                    exact
                    component={ManageOrganisation}
                  />
                )}

                <Route
                  path="/admin/checkoutpage"
                  exact
                  component={CheckoutPage}
                />

                {validateAccess(accessContainer, "Organisations", "") && (
                  <Route
                    path="/admin/managepages"
                    exact
                    component={ManageOrganisationPages}
                  />
                )}
                {validateAccess(accessContainer, "Organisations", "") && (
                  <Route
                    path="/admin/payment_process"
                    exact
                    component={PaymentProcessPage}
                  />
                )}

                {validateAccess(accessContainer, "Users", "") && (
                  <Route
                    path="/admin/manageuser"
                    exact
                    component={ManageUser}
                  />
                )}
                {validateAccess(accessContainer, "Roles", "") && (
                  <Route
                    path="/admin/managerole"
                    exact
                    component={ManageRole}
                  />
                )}
                {validateAccess(accessContainer, "Locations", "") && (
                  <Route
                    path="/admin/managelocation"
                    exact
                    component={ManageLocation}
                  />
                )}
                {validateAccess(accessContainer, "LocationResources", "") && (
                  <Route
                    exact
                    path="/admin/manageresource"
                    component={ManageResource}
                  />
                )}
                {validateAccess(accessContainer, "Customers", "") && (
                  <Route
                    path="/admin/managecustomer"
                    exact
                    component={ManageCustomer}
                  />
                )}
                {validateAccess(accessContainer, "Memberships", "") && (
                  <Route
                    path="/admin/managemembership"
                    exact
                    component={ManageMembership}
                  />
                )}
                {validateAccess(accessContainer, "Vouchers", "") && (
                  <Route
                    path="/admin/managecredit"
                    exact
                    component={ManageCredit}
                  />
                )}

                {validateAccess(accessContainer, "Vouchers", "") && (
                  <Route
                    path="/admin/managevoucher"
                    exact
                    component={ManageVoucher}
                  />
                )}

                <Route path="/admin/credits" exact component={ManageCredit} />

                {validateAccess(accessContainer, "Rules", "") && (
                  <Route
                    path="/admin/managerules"
                    exact
                    component={ManageRules}
                  />
                )}

                {validateAccess(accessContainer, "Rules", "") && (
                  <Route
                    path="/admin/rulesengine"
                    exact
                    component={RulesEngine}
                  />
                )}

                <Route path="/admin/pricing" exact component={Pricing} />
                <Route
                  path="/admin/pricing/trigger"
                  exact
                  component={PricingTrigger}
                />

                {validateAccess(accessContainer, "Bookings", "") &&
                  validateAccess(accessContainer, "DefaultUserMenu", "") && (
                    <Route
                      path="/admin/report/booking"
                      exact
                      component={ReportBooking}
                    />
                  )}

                {validateAccess(accessContainer, "Bookings", "") &&
                  !validateAccess(accessContainer, "DefaultUserMenu", "") && (
                    <Route
                      path="/admin/report/booking"
                      exact
                      component={BookingReport}
                    />
                  )}

                {validateAccess(accessContainer, "Bookings", "") &&
                  !validateAccess(accessContainer, "DefaultUserMenu", "") && (
                    <Route
                      path="/admin/report/court_usage"
                      exact
                      component={CourtUsageReport}
                    />
                  )}

                {validateAccess(accessContainer, "Bookings", "") &&
                  !validateAccess(accessContainer, "DefaultUserMenu", "") && (
                    <Route
                      path="/admin/report/member_usage"
                      exact
                      component={MemberUsageReport}
                    />
                  )}

                {validateAccess(accessContainer, "RecurringBookings", "") && (
                  <Route
                    path="/admin/report/recurring/booking"
                    exact
                    component={ManageRecurring}
                  />
                )}
                {validateAccess(accessContainer, "AuditLogs", "") && (
                  <Route
                    path="/admin/report/payment"
                    exact
                    component={PaymentReports}
                  />
                )}
                {validateAccess(accessContainer, "AuditLogs", "") && (
                  <Route
                    path="/admin/report/payment_pending"
                    exact
                    component={PaymentPendingReport}
                  />
                )}
                {validateAccess(accessContainer, "EmailLogs", "") && (
                  <Route
                    path="/admin/report/emaillogs"
                    exact
                    component={ReportEmailLog}
                  />
                )}
                {validateAccess(accessContainer, "AuditLogs", "") && (
                  <Route
                    path="/admin/report/auditlog"
                    exact
                    component={ReportAuditLog}
                  />
                )}
                {validateAccess(accessContainer, "AdminBooking", "") && (
                  <Route
                    path="/admin/booking"
                    exact
                    component={BookingPrivate}
                  />
                )}
                {/* {validateAccess(accessContainer, "AdminBooking", "") && (
                  <Route
                    path="/admin/bookingv2"
                    exact
                    component={BookingPrivatev2}
                  />
                )} */}

                {validateAccess(accessContainer, "DefaultUserMenu", "") &&
                  validateAccess(
                    accessContainer,
                    "DefaultUserMenu",
                    "userVenues"
                  ) && (
                    <Route
                      path="/admin/customerbooking"
                      exact
                      component={CustomerBookingPrivate}
                    />
                  )}

                {validateAccess(accessContainer, "DefaultUserMenu", "") &&
                  validateAccess(
                    accessContainer,
                    "DefaultUserMenu",
                    "myCalendar"
                  ) && (
                    <Route
                      path="/admin/mycalendar"
                      exact
                      component={MyCalendarPrivate}
                    />
                  )}

                {validateAccess(accessContainer, "DefaultUserMenu", "") &&
                  validateAccess(
                    accessContainer,
                    "DefaultUserMenu",
                    "userDetails"
                  ) && (
                    <Route
                      path="/admin/mydetails"
                      exact
                      component={ManageCustomer}
                    />
                  )}

                {validateAccess(accessContainer, "DefaultUserMenu", "") && (
                  <Route
                    path="/admin/favouritelist"
                    exact
                    component={FavouriteList}
                  />
                )}
                <Route component={FourZeroFour} />
              </Switch>
            </Dashboard>
          </Switch>
        </div>
      );
    }
    return (
      <div className="admin-page">
        <Switch>
          <Route path="/admin/selectorg" exact component={SelectOrganisation} />
          <Dashboard>
            <Route path="/admin/dashboard" exact component={DashboardContent} />
          </Dashboard>
        </Switch>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}
const mapStateToProps = (state) => {
  return {
    authResponse: state.authResponse,
    authUser: state.authUser,
    roleResponse: state.roleResponse,
    storeUserAccess: state.storeUserAccess,
  };
};

export default withCookies(
  connect(mapStateToProps, {
    setAuthUser,
    showHideLoader,
    getHeartBeat,
    getUserRoleAccess,
    storeUserAccess,
    toggleModal,
    getUserDetails,
    pauseBackgroundRequests,
  })(PrivateLayout)
);
