import { addDays } from "date-fns";
import _ from "lodash";
import queryString from "query-string";
import CustomDataGrid from "../shared/CustomDataGrid";
import {
  confirm as DevExtremeConfirm,
  custom as DevExtremeCustomDialog,
} from "devextreme/ui/dialog";

import {
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTabPane,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
} from "mdbreact";
import moment, { utc } from "moment";
import React from "react";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateRecurring } from "../../../apis/RecurringApi";
import {
  clearNotification,
  selectDate,
  showHideLoader,
  showNotification,
  toggleModal,
  closeBooking,
  pauseBackgroundRequests,
  disableParentCalendarTimer,
  genModalFavouriteCustomer,
} from "../../../actions";
import {
  doBooking,
  getBookingStatus,
  setBookingDetails,
  updateBooking,
  getBookingParticipants,
  checkBookingConflicts,
  bookingParticipantOptout,
  getBookingDetails,
  validateAddedCustomer,
} from "../../../actions/bookingAction";
import {
  getCustomers,
  validateCustomerDetails,
} from "../../../actions/customerAction";
import {
  getLocationList,
  getLocationResources,
  getLocationResourcesPrivate,
  searchLocations,
} from "../../../actions/locationAction";
import { calculatePrice, requestPayment } from "../../../actions/paymentAction";
import {
  getUserFavourites,
  deleteFavouriteCustomer,
} from "../../../actions/userFavouriteAction";
import {
  roundNumber,
  extractTimezone,
  getBookingColors,
  getCss,
  validateAccess,
} from "../../../helper/utils";
import Spinner from "../shared/Loader";
import Modal from "../shared/Modal";
import Notification from "../shared/Notification";
import ReportEmailLog from "../admin/ReportEmailLog";
import SectionContainer from "./sectionContainer";
import websocketApi from "../../../apis/websocketApi";
import WeeklyCalendarV2 from "./WeeklyCalendarV2";
import FavouriteCustomerModal from "../shared/FavouriteCustomerModal";
class BookingEditor extends React.Component {
  maxBookingParticipants = 0;
  isLocationPerMembership = false;

  state = {
    bookingParams: {},
    sendNotification: true,
    alert: {
      type: "",
      title: "",
      text: "",
    },
    isLocationPerMembership: false,
    customerResultContainer: [],
    isBookingCancellable: true,
    paymentDate: "",
    isView: true,
    selectedBooking: null,
    locationResource: {},
    statuses: [],
    statusChangeConfirmation: [],
    paymentHistoryElem: [],
    bookingStatusHistoryElem: [],
    activeItemInnerPills: "1",
    activeItemOuterTabs: "1",
    customerId: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
    invoice: "",
    paid: false,
    notes: "",
    selectedBooking: {},
    duration: "",
    origTime: "",
    time: "",
    paymentMethod: "Credit Card",
    isRecurring: false,
    frequencyInWeeks: "Weekly",
    totalPrice: 0,
    origPrice: 0,
    doneGettingOriginalPrice: false,
    showPriceChangeWarning: false,
    chargeAmount: 0,
    unPaidAmount: 0,
    paidAmount: 0,
    lessAmount: 0,
    totalAmount: 0,

    wasValidated: "",
    isInitialPriceComputed: false,
    showBookingModal: false,

    isClientSideFilter: false,
    locationResourcesLoaded: false,

    coupon: "",
    couponErrorMsg: "",
    invalidCoupon: false,
    couponDiscount: 0,

    showBookingDetailsModal: false,
    selectedBookingDetails: {},

    durationDefaultVal: 30,

    discountAmount: 0,
    receiptUrl: "",
    vendor: "",

    bookingRef: "",
    bookingUuid: "",
    bookingId: "",

    locationId: "",
    locationUuid: "",
    locationName: "",

    resourceId: "",
    resourceUuid: "",
    resourceName: "",

    customerId: "",
    customerUuid: "",

    origStatus: "",
    status: "",
    statusDesc: "",
    statusChangeConfirmation: "",

    statusNotes: "",

    isUpdate: true,

    activeItemInnerPills: "1",

    isChargable: 0,
    issueVoucher: 0,
    isOverrideble: 0,

    paymentTransaction: {},

    paymentHistoryElem: [],
    bookingStatusHistoryElem: [],

    disableStatusButton: false,

    generateVoucher: false,

    disableSaveButton: false,
    showRecurringWarning: false,
    searchDateVal: "",

    showCalendarSelect: false,
    updateSchedule: false,

    showExceedMaxDurationAcknowledge: false,
    maxDuration: 0,
    maxDurationInText: "",
    checkMoreThanMaxDuration: false,

    value: "",

    locationTimezone: undefined,
    bookingColors: undefined,
    participants: [],

    collapseID: "collapse1",

    doCalculate: false,
    overwriteFee: false,
    overwriteFeeAmount: "",
    userFavouritesEmail: [],
    userFavourites: {},
    participantValueSearch: "",
    isFavouriteListSearch: true,
    isMainCustomer: true, // This will be used to check if the added customer is main or just participant
    selectedCustomerOrParticipant: {},
  };

  constructor(props) {
    super(props);
    this.triggerOnDeleteWaring = this.triggerOnDeleteWaring.bind(this);
    this.participantUniqueValidation =
      this.participantUniqueValidation.bind(this);
    const { storeUserAccess } = this.props;

    let isDefaultUser =
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails");

    this.isReadOnly = false;
    if (isDefaultUser) {
      if (
        validateAccess(
          storeUserAccess,
          "AdminBooking",
          "showBookingNotAvailable"
        )
      ) {
        this.isReadOnly = true;
      }
    }

    this.rowValidating = this.rowValidating.bind(this);
    this.triggerEvent = this.triggerEvent.bind(this);
    this.triggerDeletedEvent = this.triggerDeletedEvent.bind(this);
    this.triggerInsertEvent = this.triggerInsertEvent.bind(this);
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.onClickCheckInPin = this.onClickCheckInPin.bind(this);
    this.allowDeletingParticipant = this.allowDeletingParticipant.bind(this);
    this.allowUpdatingParticipant = this.allowUpdatingParticipant.bind(this);
    this.allowAddingFavouriteContact =
      this.allowAddingFavouriteContact.bind(this);
    this.allowRemovingFavouriteContact =
      this.allowRemovingFavouriteContact.bind(this);
    this.onAddFavouriteContact = this.onAddFavouriteContact.bind(this);
    this.onRemoveFavouriteContact = this.onRemoveFavouriteContact.bind(this);
    this.props.showHideLoader(true);

    this.props.getBookingDetails(
      props.selectedBooking.uuid,
      this.props.authUser.sessionToken
    );

    let locationPhone = "";
    let locationEmail = "";
    if (this.isReadOnly) {
      locationPhone = props.locationObj.attributes.contact_number;
      locationEmail = props.locationObj.attributes.contact_email;
    }

    this.state["locationPhone"] = locationPhone;
    this.state["locationEmail"] = locationEmail;
    this.state["isDefaultUser"] = isDefaultUser;

    if (typeof props.isView !== "undefined") {
      this.state.isView = props.isView;
    }

    if (typeof props.locationResource !== "undefined") {
      this.locationResource = props.locationResource;
      this.originalLocationResource = props.locationResource;
      if (
        typeof this.locationResource.attributes.max_participants !==
          "undefined" &&
        this.locationResource.attributes.max_participants != ""
      ) {
        this.maxBookingParticipants =
          this.locationResource.attributes.max_participants;
      }

      // this.isLocationPerMembership =
      //   typeof props.locationObj != "undefined" &&
      //   typeof props.locationObj.attributes.is_membership_based !==
      //     "undefined" &&
      //   props.locationObj.attributes.is_membership_based.toLowerCase() == "yes";

      this.isLocationPerMembership = true;
    }

    if (typeof props.statuses !== "undefined") {
      this.state.statuses = props.statuses;
    }

    this.hasParticipantChanges = false;
  }

  isGuestOnly() {
    //Check a logged in user if guest only to a selected booking

    return (
      this.props.authUser.username !=
        this.state.selectedBooking.customer.email && this.state.isDefaultUser
    );
  }

  viewBookingResponse(prevProps, prevState) {
    if (
      prevProps.bookingDetailsResponse !== this.props.bookingDetailsResponse
    ) {
      let booking = this.props.bookingDetailsResponse;
      let membershipDetails = "N/A";
      let membership = _.filter(booking.participants, function (participant) {
        return participant.customer.email == booking.customer.email;
      });
      if (membership.length > 0 && membership[0].customer.membership) {
        let item = membership[0].customer;
        membershipDetails =
          (item.membership.number ? item.membership.number : "N/A") +
          " - " +
          item.membership.type;
      }

      this.state.selectedBooking = booking;
      this.state.showBookingDetailsModal = true;

      let overwriteFee = false;
      let overwriteFeeAmount = "";
      if (
        booking.overwriteBookingFee !== undefined &&
        booking.overwriteBookingFee.overwrite_fee !== undefined
      ) {
        overwriteFee =
          booking.overwriteBookingFee.overwrite_fee === 1 ? true : false;
      }
      if (
        booking.overwriteBookingFee !== undefined &&
        booking.overwriteBookingFee.overwrite_fee !== undefined
      ) {
        overwriteFeeAmount = booking.overwriteBookingFee.amount + "";
      }

      this.setState({
        selectedBooking: booking,
        membershipDetails: membershipDetails,
        isBookingCancellable: booking.is_cancellable,
        selectedDate: moment(booking.start_time)
          .tz(this.getTzDetails().timezone)
          .format("YYYY-MM-DD"),
        searchDateVal: moment(booking.start_time)
          .tz(this.getTzDetails().timezone)
          .format(),
        first_name: booking.customer.first_name,
        last_name: booking.customer.last_name,
        email: booking.customer.email,
        mobile: booking.customer.mobile,
        address: booking.customer.address,
        invoice: booking.transaction.invoice,
        isPaid:
          booking.transaction !== undefined &&
          booking.transaction.ispaid !== undefined &&
          (booking.transaction.ispaid + "" === "1" ? true : false),
        notes: "",
        time: "",
        durationDefaultVal: "",

        bookingRef: booking.booking_reference,
        bookingUuid: booking.uuid,
        bookingId: booking.id,

        locationId: booking.location.id,
        locationUuid: booking.location.uuid,
        locationName: booking.location.name,

        resourceId: booking.resource.id,
        resourceUuid: booking.resource.uuid,
        resourceName: booking.resource.name,

        customerId: booking.customer.id,
        customerUuid: booking.customer.uuid,
        isRecurring: booking.is_recurring,
        frequencyInWeeks: booking.location.id,
        start_time: booking.start_time,
        end_time: booking.end_time,
        origPrice: booking.transaction.subTotalAmount,
        totalPrice: booking.transaction.totalAmount,
        overwriteFee: overwriteFee,
        overwriteFeeAmount: overwriteFeeAmount,
      });

      var start_time = moment(booking.start_time)
        .tz(this.getTzDetails().timezone)
        .format("HH:mm");
      var end_time = moment(booking.end_time)
        .tz(this.getTzDetails().timezone)
        .format("HH:mm");

      this.openBookingDetailsModal({
        time: `${start_time}-${end_time}`,
      });
      this.props.showHideLoader(false);

      if (this.isGuestOnly() && this.state.activeItemInnerPills !== "5") {
        //Toggle the participants tab
        this.setState({
          activeItemInnerPills: "5",
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.viewBookingResponse(prevProps, prevState);
    this.couponValidation(prevProps, prevState);
    this.validateBookingResponse(prevProps, prevState);
    this.getCustomerResult(prevProps, prevState);
    this.validateCustomerDetailsResult(prevProps, prevState);
    this.validateAddedCustomeresponse(prevProps, prevState);
    this.validateRequestPaymentResponse(prevProps, prevState);
    this.bookingStatusRenderValidation(prevProps, prevState);
    this.validateBookingConflicts(prevProps, prevState);

    if (
      prevProps.bookingChangeSchedule !== this.props.bookingChangeSchedule &&
      this.props.bookingChangeSchedule
    ) {
      var start = this.props.bookingChangeSchedule.time.split("-")[0];
      var duration = this.state.durationDefaultVal;
      let defaultEndTime = this.props.bookingChangeSchedule.defaultEndTime;
      let end = start;
      let endMeet = false;

      let actualDuration = 0;

      let resource = this.props.bookingChangeSchedule.locationResource;
      this.locationResource = resource;
      let bookedStartTimeArr = _.chain(resource.bookings)
        .map((book) => {
          return {
            startTime: moment(book.start_time)
              .tz(this.getTzDetails().timezone)
              .format("HH:mm"),
            endTime: moment(book.end_time)
              .tz(this.getTzDetails().timezone)
              .format("HH:mm"),
            bookingRef: book.booking_reference,
          };
        })
        .orderBy(["startTime"], ["asc"])
        .value();

      while (!endMeet) {
        end = moment(end, "HH:mm").add(30, "minutes").format("HH:mm");
        actualDuration += 30;

        _.map(bookedStartTimeArr, (bookTimeObj) => {
          if (
            this.state.bookingRef !== bookTimeObj.bookingRef &&
            bookTimeObj.startTime === end
          ) {
            endMeet = true;
          }
        });

        if (moment(end, "HH:mm").isSame(moment(defaultEndTime, "HH:mm"))) {
          endMeet = true;
        }
        if (actualDuration === duration) {
          endMeet = true;
        }
      }

      let time = `${start}-${end}`;
      this.setState({
        showCalendarSelect: false,
        time: time,
        isUpdate: true,
        resourceName: this.props.bookingChangeSchedule.resourceName,
        resourceUuid: this.props.bookingChangeSchedule.resourceUuid,
        resourceId: this.props.bookingChangeSchedule.resourceId,
        duration:
          this.state.durationDefaultVal < actualDuration
            ? this.state.durationDefaultVal
            : actualDuration,
        locationResource: resource,
        getAvailableSlot: this.getAvailableSlotPrivate(time),
        searchDateVal: this.props.bookingChangeSchedule.searchDateVal,
        updateSchedule: this.props.bookingChangeSchedule.updateSchedule,
      });
    }
    this.validateOverwriteFee(prevProps, prevState);
    if (prevProps.userFavouriteResponse !== this.props.userFavouriteResponse) {
      let response = this.props.userFavouriteResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        this.props.showNotification(
          response.notificationType,
          response.data.message
        );
        if (response.status >= 200 && response.status <= 300) {
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
          this.props.getUserFavourites({}, this.props.authUser.sessionToken);
        }
      } else {
        const userFavouriteList = this.props.userFavouriteResponse.data;
        let userFavourites = {};
        let customerList = [];

        let userFavouritesEmail = [];
        userFavouriteList.map((favourite) => {
          let favouriteEmail = favourite["email"].toLowerCase();

          if (favouriteEmail != this.props.authUser.username.toLowerCase()) {
            // format the favourites list here
            userFavourites[favouriteEmail] = {};
            userFavourites[favouriteEmail]["display"] =
              favourite.first_name +
              " " +
              favourite.last_name +
              ", " +
              favourite.email +
              ", " +
              favourite.mobile_no;
            userFavourites[favouriteEmail]["data"] = favourite;
            userFavouritesEmail.push(favouriteEmail);
            let formattedData = {};
            formattedData["first_name"] = favourite["first_name"];
            formattedData["last_name"] = favourite["last_name"];
            formattedData["mobile"] = favourite["mobile_no"];
            formattedData["email"] = favourite["email"];
            customerList.push(formattedData);
          }
        });

        this.setState({
          userFavouritesEmail: userFavouritesEmail,
          userFavourites: userFavourites,
          customerResultContainer: customerList,
          isFavouriteListSearch: true,
        });
      }
    }
  }

  validateOverwriteFee(prevProps, prevState) {
    if (
      this.state.showBookingDetailsModal &&
      (prevState.overwriteFee !== this.state.overwriteFee ||
        prevState.overwriteFeeAmount !== this.state.overwriteFeeAmount)
    ) {
      this.debouncedCalculatePrice();
    }
  }

  validateBookingConflicts = (prevProps, prevState) => {
    let bookingResponse = this.props.bookingResponse;
    if (prevProps.bookingResponse !== bookingResponse) {
      if (
        bookingResponse.action == "CONFLICTS" &&
        bookingResponse.page == "booking_editor"
      ) {
        let warnings = bookingResponse.data.conflicts;
        let validationMessages = [];
        let action = "WARN";
        _.map(bookingResponse.data.validationMessages, function (data) {
          validationMessages.push(data.message);
          action = data.action;
        });
        this.submitBookingHandler(warnings, validationMessages, action);
      }
    }
  };

  submitBookingHandler = (
    recurringWarnings,
    validationMessages,
    action = ""
  ) => {
    let canBook = recurringWarnings.length == 0;
    const { isDefaultUser } = this.state;
    if (action !== "") {
      canBook = action === "WARN" ? true : false;
    }
    if (recurringWarnings.length > 0 || validationMessages.length > 0) {
      let conflicts = [];
      recurringWarnings.forEach((value, index) => {
        let booking_from = moment(value.booking_from).tz(
          this.getTzDetails().timezone
        );
        let booking_to = moment(value.booking_to).tz(
          this.getTzDetails().timezone
        );
        let booking_time =
          booking_from.format("HH:mm") + " - " + booking_to.format("HH:mm");
        conflicts.push(
          "Reference " +
            value.booking_reference +
            " - " +
            booking_from.format("YYYY/MM/DD") +
            " " +
            booking_time
        );
      });

      _.map(validationMessages, function (value) {
        conflicts.push(value);
      });

      // if (isDefaultUser) {
      //   this.props.showNotification(
      //     "error",
      //     "Sorry, Unable to book the selected slot. There might be a conflicts with other bookings. Please contact system administrator."
      //   );
      //   this.confirmBooking(conflicts, false);
      //   this.props.showHideLoader(false);
      // } else {
      this.props.showHideLoader(false);
      this.confirmBooking(conflicts, canBook);
      // }
    } else {
      this.doBooking();
    }
  };

  resetToOriginalVal() {
    const { selectedBooking } = this.state;
    let start_time = moment(selectedBooking.start_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");
    let end_time = moment(selectedBooking.end_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");

    let time = `${start_time}-${end_time}`;

    this.locationResource = this.originalLocationResource;

    let selectedDate = moment(selectedBooking.start_time)
      .tz(this.getTzDetails().timezone)
      .format("YYYY-MM-DD");
    // reset the selected date
    this.props.selectDate(new Date(selectedDate));

    this.setState({
      selectedDate: selectedDate,
      searchDateVal: moment(selectedBooking.start_time)
        .tz(this.getTzDetails().timezone)
        .format(),
      time: time,
      durationDefaultVal: this.state.durationDefaultVal,
      duration: this.state.durationDefaultVal,
      getAvailableSlot: this.getAvailableSlotPrivate(time),

      resourceId: selectedBooking.resource.id,
      resourceUuid: selectedBooking.resource.uuid,
      resourceName: selectedBooking.resource.name,
    });
  }

  validateBookingResponse(prevProps, prevState) {
    //-- Success Response
    if (
      prevProps.bookingSuccessResponse !== this.props.bookingSuccessResponse &&
      this.props.bookingSuccessResponse.status === 200
    ) {
      let msg = "Updated booking details";

      this.props.showNotification("success", msg);
      this.props.showHideLoader(false);
      this.resetBookingModal();
    }

    if (
      prevProps.bookingResponse !== this.props.bookingResponse &&
      this.props.bookingResponse.status === 200 &&
      this.props.bookingResponse.action == "BOOKING_PARTICIPANT_OPTOUT"
    ) {
      let msg = this.props.bookingResponse.data.message;
      this.props.showNotification("success", msg);
      this.props.showHideLoader(false);
      this.resetBookingModal();
    }

    //-- Failed Response
    if (
      prevProps.bookingFailedResponse !== this.props.bookingFailedResponse &&
      this.props.bookingFailedResponse.status >= 400
    ) {
      if (
        this.props.bookingFailedResponse.data !== undefined &&
        this.props.bookingFailedResponse.data.errorMessages !== undefined &&
        this.props.bookingFailedResponse.data.errorMessages.length > 0
      ) {
        this.props.showHideLoader(false);
        this.props.showNotification(
          "error",
          this.props.bookingFailedResponse.data.errorMessages[0]
        );
      } else {
        this.props.showHideLoader(false);
        this.props.showNotification(
          "error",
          this.props.bookingFailedResponse.data.message
        );
      }

      this.setState({ disableSaveButton: false, disableStatusButton: false });
    }
    // else if (this.state.invalidCoupon) {
    //   this.props.showHideLoader(false);
    //   return (
    //     <MDBAlert color="danger" className="text-center">
    //       Invalid Discount code provided
    //     </MDBAlert>
    //   );
    // }
    else if (
      prevProps.bookingFailedResponse !== this.props.bookingFailedResponse &&
      this.props.bookingFailedResponse === "Network error"
    ) {
      this.props.showHideLoader(false);

      let msg = this.state.isUpdate
        ? "Unable to update details. Please try again later"
        : "Unable to book customer. Please try again later";

      this.props.showNotification("error", msg);

      this.setState({ disableSaveButton: false, disableStatusButton: false });
    }
  }

  validateAddedCustomeresponse(prevProps, prevState) {
    if (
      prevProps.addedCustomerParticipantResponse !==
        this.props.addedCustomerParticipantResponse &&
      this.props.addedCustomerParticipantResponse.status === 200 &&
      this.state.showBookingDetailsModal
    ) {
      const validationData = this.props.addedCustomerParticipantResponse.data;
      this.props.showHideLoader(false);
      this.showValidateParticipantModal(validationData);
    }
  }

  validateRequestPaymentResponse(prevProps, prevState) {
    // this.props.paymentRequestSuccess.status
    // paymentRequestFailed
    if (prevProps.paymentRequestSuccess !== this.props.paymentRequestSuccess) {
      if (this.state.isDefaultUser) {
        if (this.props.paymentRequestSuccess.status === 200) {
          if (
            this.props.paymentRequestSuccess.data.ispaid !== undefined &&
            this.props.paymentRequestSuccess.data.ispaid
          ) {
            window.location.replace(
              "/booking/success/" +
                this.props.paymentRequestSuccess.data.bookingData.bookingUuid
            );
          } else {
            this.props.showHideLoader(false);
            this.props.history.push("/payment");
          }
        }
      } else {
        let msg = "Booking has been saved. Payment request sent!";
        this.props.showNotification("success", msg);
        this.props.showHideLoader(false);
        this.resetBookingModal();
      }
    }
    if (prevProps.paymentRequestFailed !== this.props.paymentRequestFailed) {
      this.props.showHideLoader(false);

      let msg = "";

      if (this.props.paymentRequestFailed === "Network error") {
        msg =
          "Unable to save booking. Please try again later or contact system administrator.";
      } else {
        msg = this.props.paymentRequestFailed.data.message;
      }

      this.props.showNotification("error", msg);
      this.resetBookingModal();
    }
  }

  renderAvailableSlot(availableSlot, durationDefaultVal) {
    if (availableSlot != undefined) {
      return (
        <MDBCol md="6">
          <label className="grey-text required">Select duration</label>
          <select
            className="browser-default custom-select"
            onChange={this.onDurationChange}
            defaultValue={durationDefaultVal}
            value={this.state.duration}
            disabled={this.state.isDefaultUser}
          >
            {availableSlot.map((time) => (
              <option key={time} value={time}>
                {time + " Minutes or " + time / 60 + " Hour/s"}
              </option>
            ))}
          </select>
        </MDBCol>
      );
    }
    return <span></span>;
  }

  updateTimeValue(duration) {
    const { showBookingDetailsModal, start_time, time, origTime } = this.state;
    let startTime = _.split(time, "-")[0];
    let endTime = moment(time, "HH:mm")
      .add(duration, "minutes")
      .format("HH:mm");

    let newTime = startTime + "-" + endTime;

    this.setState({ time: newTime, updateSchedule: origTime != newTime });

    this.validateShowExceedMaxDuration(
      duration,
      this.state.resourceUuid,
      showBookingDetailsModal
    );
  }

  onDurationChange = (evt) => {
    this.updateTimeValue(evt.target.value);
    this.setState({
      duration: evt.target.value,
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,
    });
  };

  getAvailableSlotPrivate(time) {
    if (this.state.isView) {
      return [];
    }
    let resource = this.locationResource;
    let newSelectedBookingsContainer = this.props.newSelectedBookingsContainer;
    let bookingsCont = resource.bookings;
    if (newSelectedBookingsContainer !== undefined) {
      bookingsCont = newSelectedBookingsContainer.bookings;
    }

    let tz = this.getTzDetails();
    let minDuration = parseInt(
      resource.attributes.min_duration.replace("m", "")
    );
    let maxDuration = parseInt(
      resource.attributes.max_duration.replace("m", "")
    );

    let durationPerChunk = parseInt(
      resource.attributes.duration_per_chunk.replace("m", "")
    );

    let minTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(30, "minutes")
      .format("HH:mm");

    let maxTime = resource.attributes.default_end_time;

    let addedTime = minTime;

    let formattedTimeArr = [];
    let toFormatObj = {};

    let counter = 0;
    while (addedTime !== maxTime) {
      counter++;
      if (counter !== 1) {
        addedTime = moment(addedTime, "HH:mm")
          .add(durationPerChunk, "minutes")
          .format("HH:mm");
      }
      if (toFormatObj.start === undefined) {
        toFormatObj["start"] = addedTime;
      } else if (
        toFormatObj.start !== undefined &&
        toFormatObj.end === undefined
      ) {
        toFormatObj["end"] = addedTime;
      }

      if (toFormatObj.start !== undefined && toFormatObj.end !== undefined) {
        formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
        addedTime = toFormatObj.start;
        toFormatObj = {};
      }
    }

    let durationArr = [];

    // --set initial value
    durationArr.push(30);

    // let bookedStartTimeArr = _.chain(resource.bookings)
    //   .map((book) => {
    //     return moment(book.start_time)
    //       .tz(this.getTzDetails().timezone)
    //       .format("HH:mm");
    //   })
    //   .sort()
    //   .value();

    let bookedStartTimeArr = _.chain(bookingsCont)
      .map((book) => {
        return {
          startTime: moment(book.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          endTime: moment(book.end_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          bookingRef: book.booking_reference,
        };
      })
      .orderBy(["startTime"], ["asc"])
      .value();

    let recurrings = [];

    if (newSelectedBookingsContainer !== undefined) {
      recurrings = newSelectedBookingsContainer.recurrings;
    }

    for (let i = 0; i < formattedTimeArr.length; i++) {
      let isFound = false;
      let formattedStartTime = _.split(formattedTimeArr[i], "-")[0];
      _.map(bookedStartTimeArr, (bookTimeObj) => {
        if (
          this.state.bookingRef !== bookTimeObj.bookingRef &&
          bookTimeObj.startTime === formattedStartTime
        ) {
          isFound = true;
        }
      });

      if (!isFound) {
        _.map(recurrings, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(tz.timezone)
            .format("HH:mm");
          if (!isFound && booking_from === formattedStartTime) {
            isFound = true;
          }
        });
      }

      if (!isFound) {
        durationArr.push(
          durationPerChunk + durationArr[durationArr.length - 1]
        );
      } else {
        break;
      }
    }

    return durationArr;
  }

  bookingStatusRenderValidation(prevProps, prevState) {
    if (
      prevProps.getBookingStatusSuccessResponse !==
        this.props.getBookingStatusSuccessResponse &&
      this.props.getBookingStatusSuccessResponse.status === 200
    ) {
      let bookingStatusHistoryElem = this.buildStatusHistory(
        this.props.getBookingStatusSuccessResponse
      );
      this.setState({ bookingStatusHistoryElem: bookingStatusHistoryElem });
    }
  }

  buildStatusHistory(getBookingStatusSuccessResponse) {
    let bookingStatusHistoryElem = [];
    bookingStatusHistoryElem.push(
      <MDBTable responsive scrollY key="sttable1">
        <MDBTableHead>
          <tr>
            <th>Status</th>
            <th>Date</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {getBookingStatusSuccessResponse.data.history.map(
            (history, index) => (
              <tr key={index}>
                <td key={index + "1"}>{history.status}</td>
                <td key={index + "7"}>
                  {moment(
                    _.split(history.submittedDate, " ")[0] +
                      "T" +
                      _.split(history.submittedDate, " ")[1] +
                      "Z",
                    "YYYY-MM-DDTHH:mm:ssZ"
                  )
                    .tz(this.getTzDetails().timezone)
                    .format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td key={index + "9"}>{history.comments}</td>
              </tr>
            )
          )}
        </MDBTableBody>
      </MDBTable>
    );

    return bookingStatusHistoryElem;
  }

  getAvailableSlot(time) {
    if (this.state.isView) {
      return [];
    }
    let resource = this.locationResource;
    let newSelectedBookingsContainer = this.props.newSelectedBookingsContainer;
    let bookingsCont = resource.bookings;
    if (newSelectedBookingsContainer !== undefined) {
      bookingsCont = newSelectedBookingsContainer.bookings;
    }

    let minDuration = parseInt(
      resource.attributes.min_duration.replace("m", "")
    );
    let maxDuration = parseInt(
      resource.attributes.max_duration.replace("m", "")
    );

    let durationPerChunk = parseInt(
      resource.attributes.duration_per_chunk.replace("m", "")
    );

    let minTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(30, "minutes")
      .format("HH:mm");

    // let maxTime = resource.attributes.default_end_time;

    let maxTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(maxDuration, "minutes")
      .format("HH:mm");
    if (maxTime > resource.attributes.default_end_time) {
      maxTime = resource.attributes.default_end_time;
    }

    let addedTime = minTime;

    let formattedTimeArr = [];
    let toFormatObj = {};

    let counter = 0;
    while (addedTime !== maxTime) {
      counter++;
      if (counter !== 1) {
        addedTime = moment(addedTime, "HH:mm")
          .add(durationPerChunk, "minutes")
          .format("HH:mm");
      }
      if (toFormatObj.start === undefined) {
        toFormatObj["start"] = addedTime;
      } else if (
        toFormatObj.start !== undefined &&
        toFormatObj.end === undefined
      ) {
        toFormatObj["end"] = addedTime;
      }

      if (toFormatObj.start !== undefined && toFormatObj.end !== undefined) {
        formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
        addedTime = toFormatObj.start;
        toFormatObj = {};
      }
    }

    let durationArr = [];

    // --set initial value
    durationArr.push(30);

    // let bookedStartTimeArr = _.chain(resource.bookings)
    //   .map((book) => {
    //     return moment(book.start_time)
    //       .tz(this.getTzDetails().timezone)
    //       .format("HH:mm");
    //   })
    //   .sort()
    //   .value();

    let bookedStartTimeArr = _.chain(bookingsCont)
      .map((book) => {
        return {
          startTime: moment(book.start_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          endTime: moment(book.end_time)
            .tz(this.getTzDetails().timezone)
            .format("HH:mm"),
          bookingRef: book.booking_reference,
        };
      })
      .orderBy(["startTime"], ["asc"])
      .value();

    for (let i = 0; i < formattedTimeArr.length; i++) {
      let isFound = false;
      let formattedStartTime = _.split(formattedTimeArr[i], "-")[0];
      _.map(bookedStartTimeArr, (bookTimeObj) => {
        if (
          this.state.bookingRef !== bookTimeObj.bookingRef &&
          bookTimeObj.startTime === formattedStartTime
        ) {
          isFound = true;
        }
      });

      if (!isFound) {
        durationArr.push(
          durationPerChunk + durationArr[durationArr.length - 1]
        );
      } else {
        break;
      }
    }

    return durationArr;
  }

  getAvailableSlotOld(time) {
    let resource = this.locationResource;
    // let resource = _.chain(this.state.locationResourcesContainer.results)
    //   .map((resource) => {
    //     if (resource.uuid === resourceId) {
    //       return resource;
    //     }
    //     return [];
    //   })
    //   .filter(_.size)
    //   .value()[0];

    let minDuration = parseInt(
      resource.attributes.min_duration.replace("m", "")
    );
    let maxDuration = parseInt(
      resource.attributes.max_duration.replace("m", "")
    );

    let durationPerChunk = parseInt(
      resource.attributes.duration_per_chunk.replace("m", "")
    );

    let minTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(minDuration, "minutes")
      .format("HH:mm");

    let maxTime = moment(_.split(time, "-")[0], "HH:mm")
      .add(maxDuration, "minutes")
      .format("HH:mm");
    if (maxTime > resource.attributes.default_end_time) {
      maxTime = resource.attributes.default_end_time;
    }
    let addedTime = minTime;

    let formattedTimeArr = [];
    let toFormatObj = {};

    let counter = 0;
    while (addedTime !== maxTime) {
      counter++;
      if (counter !== 1) {
        addedTime = moment(addedTime, "HH:mm")
          .add(durationPerChunk, "minutes")
          .format("HH:mm");
      }
      if (toFormatObj.start === undefined) {
        toFormatObj["start"] = addedTime;
      } else if (
        toFormatObj.start !== undefined &&
        toFormatObj.end === undefined
      ) {
        toFormatObj["end"] = addedTime;
      }

      if (toFormatObj.start !== undefined && toFormatObj.end !== undefined) {
        formattedTimeArr.push(toFormatObj.start + "-" + toFormatObj.end);
        addedTime = toFormatObj.start;
        toFormatObj = {};
      }
    }

    let durationArr = [];

    // --set initial value
    durationArr.push(minDuration);

    let bookedStartTimeArr = _.chain(resource.bookings)
      .map((book) => {
        return moment(book.start_time)
          .tz(this.getTzDetails().timezone)
          .format("HH:mm");
      })
      .sort()
      .value();

    for (let i = 0; i < formattedTimeArr.length; i++) {
      let isFound = false;
      let formattedStartTime = _.split(formattedTimeArr[i], "-")[0];
      _.map(bookedStartTimeArr, (startTime) => {
        if (startTime === formattedStartTime) {
          isFound = true;
        }
      });

      if (!isFound) {
        durationArr.push(
          durationPerChunk + durationArr[durationArr.length - 1]
        );
      } else {
        break;
      }
    }

    return durationArr;
  }

  openBookingDetailsModal = (paramData) => {
    const { selectedBooking } = this.state;

    let selectedDate = moment(selectedBooking.start_time)
      .tz(this.getTzDetails().timezone)
      .format("YYYY-MM-DD");
    this.props.selectDate(new Date(selectedDate));

    if (this.state.isDefaultUser && !this.isGuestOnly()) {
      this.props.getUserFavourites({}, this.props.authUser.sessionToken);
    }

    let getAvailableSlot = [];
    if (
      validateAccess(
        this.props.storeUserAccess,
        "BookingDetails",
        "allowBeyondMaxDuration"
      )
    ) {
      getAvailableSlot = this.getAvailableSlotPrivate(paramData.time);
    } else {
      getAvailableSlot = this.getAvailableSlot(paramData.time);
    }

    let bookObj = selectedBooking;

    let startTime = moment(bookObj.start_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");
    let endTime = moment(bookObj.end_time)
      .tz(this.getTzDetails().timezone)
      .format("HH:mm");

    this.setState({
      time: paramData.time,
    });

    let t1 = moment(bookObj.start_time);
    let t2 = moment(bookObj.end_time);

    let duration = t2.diff(t1, "minutes");

    let paymentRequestIds = [];
    bookObj.transaction.payments.forEach((value, index) => {
      if (value.method.vendor == "Stripe" || value.method.vendor == "POS") {
        paymentRequestIds.push(value.id);
      }
    });

    let paymentHistoryElem = [];
    if (!this.isReadOnly) {
      paymentHistoryElem = this.buildPaymentHistory(bookObj.transaction);
    }

    let participantsData = [];
    _.map(bookObj.participants, (participant) => {
      let checked_in_time = participant.checked_in_datetime;
      if (checked_in_time != "" && checked_in_time != null) {
        checked_in_time = moment(checked_in_time)
          .local(this.getTzDetails().timezone)
          .format("YYYY-MM-DD HH:mm:ss");
      }

      let membershipDetails = "N/A";

      if (participant.customer.membership) {
        let item = participant.customer;
        membershipDetails =
          (item.membership.number ? item.membership.number : "N/A") +
          " - " +
          item.membership.type;
      }
      let data = {
        id: participant.id,
        first_name: participant.customer.firstName,
        last_name: participant.customer.lastName,
        email: participant.customer.email,
        mobile: participant.customer.mobile,
        checked_in: participant.checked_in,
        checked_in_time: checked_in_time,
        membership_details: membershipDetails,
        participant_reference: participant.booking_participant_reference,
      };
      participantsData.push(data);
    });

    this.setState({
      showBookingDetailsModal: true,
      customerId: bookObj.customer.id,
      first_name: bookObj.customer.first_name,
      last_name: bookObj.customer.last_name,
      email: bookObj.customer.email,
      mobile: bookObj.customer.mobile,
      address: bookObj.customer.address,

      invoice: bookObj.transaction.invoice,
      paid: bookObj.transaction.ispaid ? true : false,
      notes: bookObj.comments,
      time: startTime + "-" + endTime,
      origTime: startTime + "-" + endTime,

      durationDefaultVal: duration,
      duration: duration,

      discountAmount: bookObj.transaction.totalVoucherCreditAmount,

      bookingRef: bookObj.booking_reference,
      bookingUuid: bookObj.uuid,
      bookingId: bookObj.id,

      locationId: bookObj.location.id,
      locationUuid: bookObj.location.uuid,
      locationName: bookObj.location.name,

      resourceId: bookObj.resource.id,
      resourceUuid: bookObj.resource.uuid,
      resourceName: bookObj.resource.name,

      customerId: bookObj.customer.id,
      customerUuid: bookObj.customer.uuid,

      origStatus: bookObj.status,
      status: bookObj.status,

      getAvailableSlot: getAvailableSlot,

      paymentTransaction: bookObj.transaction,

      isRecurring: bookObj.is_recurring === 1 ? true : false,
      frequencyInWeeks: bookObj.recurring_type,

      paymentHistoryElem: paymentHistoryElem,

      showRecurringWarning: bookObj.is_recurring === 1 ? true : false,

      origSearchDateVal: _.cloneDeep(this.state.searchDateVal),
      participants: participantsData,
      originalPariticipants: bookObj.participants,
      customerUniqueReference: this.getCustomerUniqueReference(),
    });

    this.validateShowExceedMaxDuration(duration, bookObj.resource.uuid, true);

    this.setDefaultStatus(bookObj.status);
    if (!this.isReadOnly) {
      this.props.getBookingStatus(
        bookObj.uuid,
        this.props.authUser.sessionToken
      );
    }
  };

  getCustomerUniqueReference() {
    //get the logged in user on the participant list
    let loggedinUserEmail = this.props["authUser"]["username"];
    let customer = _.find(
      this.state["selectedBooking"]["participants"],
      function (data) {
        return data["customer"]["email"] == loggedinUserEmail;
      }
    );

    //if the user is admin do not show the Unique reference on header
    let customerUniqueReference = "";
    if (!_.isEmpty(customer)) {
      customerUniqueReference = customer["booking_participant_reference"];
    }
    return customerUniqueReference;
  }

  setDefaultStatus(status) {
    if (this.state.isView) {
      return;
    }

    status = status === "Checked In" ? "Checked" : status;

    let statusAttr = _.chain(this.state.statuses)
      .map((stat) => {
        if (stat.name === status) {
          return stat;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    let statusDesc = statusAttr.additional_notes;

    this.state = {
      ...this.state,
      ...{ status: status, statusDesc: statusDesc },
    };
  }

  validateShowExceedMaxDuration(duration, resourceUuid, isBookingDetails) {
    const { locationResourcesContainer, selectedBooking } = this.state;

    let maxDuration = 0;

    if (isBookingDetails) {
      let resource = _.chain(locationResourcesContainer.results)
        .map((resource) => {
          if (resource.uuid === resourceUuid) {
            return resource;
          }
          return [];
        })
        .filter(_.size)
        .value()[0];

      maxDuration = new Number(
        _.replace(resource.attributes.max_duration, "m", "")
      ).valueOf();
    } else {
      maxDuration = new Number(
        _.replace(selectedBooking.maxDuration, "m", "")
      ).valueOf();
    }

    let showExceedMaxDurationAcknowledge = false;
    if (new Number(duration).valueOf() > maxDuration) {
      showExceedMaxDurationAcknowledge = true;
    }

    this.state = {
      ...this.state,
      ...{
        maxDuration: maxDuration,
        showExceedMaxDurationAcknowledge: showExceedMaxDurationAcknowledge,
      },
    };
  }

  buildPaymentHistory(paymentTransaction) {
    let paymentHistoryElem = [];
    paymentHistoryElem.push(
      <MDBTable responsive key="tablekey1">
        <MDBTableHead>
          <tr>
            <th>Transaction Type</th>
            <th>Payment Method</th>
            <th>Payment Reference</th>
            <th className="text-align-right">Amount</th>
            <th>Transaction Date</th>
            <th>Status</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {paymentTransaction.payments.map((payment, index) => (
            <tr key={index}>
              <td key={index + "1"}>{payment.method.vendor}</td>
              <td key={index + "2"}>{payment.method.method}</td>
              <td key={index + "3"}>{payment.payment_reference}</td>
              <td
                key={index + "7"}
                className={
                  payment.method.vendor == "REFUND"
                    ? "text-align-right text-danger "
                    : "text-align-right"
                }
              >
                ${roundNumber(payment.totalAmount, 2)}
              </td>
              <td key={index + "8"}>
                {moment(
                  _.split(payment.paymentDate, " ")[0] +
                    "T" +
                    _.split(payment.paymentDate, " ")[1] +
                    "Z",
                  "YYYY-MM-DDTHH:mm:ssZ"
                )
                  .tz(this.getTzDetails().timezone)
                  .format("DD/MM/YYYY HH:mm:ss")}
              </td>
              <td key={index + "9"}>{payment.status}</td>
              <td key={index + "10"}>{payment.comments}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    );
    return paymentHistoryElem;
  }

  renderPaymentHistory() {
    const { paymentHistoryElem } = this.state;
    if (paymentHistoryElem.length > 0) {
      return <div>{paymentHistoryElem}</div>;
    }
    return <span></span>;
  }

  onACheckChange = (evt) => {
    this.setState({ paid: !this.state.paid });
  };

  onSendNotificationChange = (evt) => {
    this.setState({ sendNotification: !this.state.sendNotification });
  };

  doCharge(doCharge) {
    let bookingParams = {
      isUpdate: true,
      generateVoucher: false,
      doCharge: doCharge,
      forcePaid: true,
    };

    this.setState({
      bookingParams: bookingParams,
    });
    this.checkBookingConflicts(bookingParams);
  }

  doIssueVoucher(generateVoucher) {
    let bookingParams = {
      isUpdate: true,
      generateVoucher: generateVoucher,
      doCharge: false,
      forcePaid: !generateVoucher ? true : false,
      refund: !generateVoucher ? 0 : this.state.lessAmount,
    };

    this.setState({
      bookingParams: bookingParams,
    });

    this.checkBookingConflicts(bookingParams);
  }

  // ----- MOBILE ------- //

  mobileToggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  renderBookingDetailsFormMobile() {
    const {
      showBookingDetailsModal,
      selectedBooking,
      activeItemOuterTabs,
      activeItemInnerPills,
      first_name,
      last_name,
      email,
      mobile,
      address,
      membershipDetails,
      searchDateVal,
      invoice,
      paymentDate,
      paid,
      notes,
      time,
      durationDefaultVal,

      discountAmount,

      bookingRef,
      bookingUuid,
      bookingId,

      locationId,
      locationUuid,
      locationName,

      resourceId,
      resourceUuid,
      resourceName,

      customerId,
      customerUuid,

      statusNotes,

      status,
      statusDesc,
      statusChangeConfirmation,
      getAvailableSlot,
      isRecurring,
      frequencyInWeeks,
      disableSaveButton,
      showRecurringWarning,
      showCalendarSelect,

      origPrice,
      lessAmount,
      chargeAmount,
      unPaidAmount,
      paidAmount,
      couponDiscount,
      totalPrice,
      coupon,
      paymentMethod,
      collapseID,
      tempCharge,
      sendNotification,
      overwriteFee,
      overwriteFeeAmount,
      locationEmail,
      locationPhone,
      isDefaultUser,
    } = this.state;
    const { storeUserAccess } = this.props;
    let allowUpdatingParticipants =
      (!this.state.selectedBooking.is_participant &&
        this.state.isDefaultUser &&
        this.state.isBookingCancellable) ||
      !this.state.isDefaultUser;

    if (
      this.state.selectedBooking.location !== undefined &&
      showBookingDetailsModal
    ) {
      var href = window.location.pathname + window.location.search;
      var renderParticipants =
        (typeof this.state.selectedBooking.participants != "undefined" &&
          this.state.selectedBooking.participants.length > 0) ||
        this.maxBookingParticipants == -1 ||
        this.maxBookingParticipants > 0;

      return (
        <div className="booking-modal">
          <MDBModal
            isOpen={showBookingDetailsModal}
            size="fluid"
            wrapClassName="scrollable"
            toggle={() => this.toggleBookingDetailsModal(false)}
          >
            <form
              className={`needs-validation ${this.state.wasValidated}`}
              onSubmit={this.updateSubmitHandler}
              noValidate
            >
              <MDBModalHeader className="bookingForm">
                <MDBRow>
                  <MDBCol
                    md="2"
                    style={{ paddingTop: "12px", paddingLeft: "41px" }}
                  >
                    Booking Form
                  </MDBCol>
                  <MDBCol md="10">
                    <MDBRow>
                      <MDBCol md="5">
                        <MDBInput
                          label="Location"
                          disabled
                          value={locationName}
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          label="Resource"
                          disabled
                          value={resourceName}
                        />
                      </MDBCol>
                      <MDBCol md="2">
                        <MDBInput
                          label="Booking Reference"
                          disabled
                          value={bookingRef}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="5">
                        <MDBInput
                          label="Reservation Date"
                          disabled
                          value={moment(searchDateVal, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Time" disabled value={time} />
                      </MDBCol>
                      <MDBCol md="3">
                        <MDBInput
                          label="Name"
                          disabled
                          value={first_name + " " + last_name}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBModalHeader>
              {this.isReadOnly ? (
                <MDBModalBody>
                  <p
                    style={{ textAlign: "center", overflowWrap: "break-word" }}
                  >
                    For more information about this booking please contact{" "}
                    {locationName}, {locationPhone}, or {locationEmail}.
                  </p>
                </MDBModalBody>
              ) : (
                <MDBModalBody>
                  {/* Accordion */}
                  {validateAccess(
                    storeUserAccess,
                    "BookingDetails",
                    "bookingDetailsTab"
                  ) &&
                    !this.isGuestOnly() && (
                      <MDBCard className="my-1" key={"cid-1"}>
                        <MDBCollapseHeader
                          className="grey lighten-3"
                          onClick={this.mobileToggleCollapse("collapse1")}
                        >
                          <span className="">Booking Details</span>
                          <MDBIcon
                            icon={
                              collapseID === "collapse1"
                                ? "angle-up"
                                : "angle-down"
                            }
                            className=""
                            style={{ float: "right" }}
                          />
                        </MDBCollapseHeader>
                        <MDBCollapse
                          className="p-2"
                          id={"collapse1"}
                          isOpen={collapseID}
                        >
                          {/* Original form */}
                          <div
                            style={{
                              display: `${
                                showCalendarSelect ? "none" : "block"
                              }`,
                            }}
                          >
                            <MDBRow>
                              <MDBCol md="6" className="custom-select-date">
                                <div class="md-form input-group mb-3">
                                  <label
                                    className="label grey-text required"
                                    style={{ fontSize: "13px", top: "-31px" }}
                                  >
                                    {!isDefaultUser
                                      ? "Reservation date(Click to change booking schedule)"
                                      : "Reservation date"}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // aria-describedby="MaterialButton-addon2"
                                    readOnly={
                                      this.state.isView || isDefaultUser
                                    }
                                    disabled={
                                      this.state.isView || isDefaultUser
                                    }
                                    value={moment(
                                      searchDateVal,
                                      "YYYY-MM-DD"
                                    ).format("DD/MM/YYYY")}
                                    onClick={() =>
                                      this.showCalendarSelect(true)
                                    }
                                  />
                                  {!isDefaultUser && !this.state.isView && (
                                    <div className="input-group-append">
                                      <MDBBtn
                                        style={{
                                          borderRight: "2px solid white",
                                        }}
                                        color="primary"
                                        className="m-0 px-3 py-2 z-depth-0 btn-sm change-text"
                                        onClick={() =>
                                          this.showCalendarSelect(true)
                                        }
                                      >
                                        <i
                                          className="fas fa-undo"
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </MDBBtn>

                                      <MDBBtn
                                        color="primary"
                                        className="m-0 px-3 py-2 z-depth-0 btn-sm undo-text"
                                        onClick={() =>
                                          this.resetToOriginalVal()
                                        }
                                      >
                                        <i
                                          className="fas fa-undo"
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </MDBBtn>
                                    </div>
                                  )}
                                </div>
                                {!isDefaultUser && (
                                  <span
                                    style={{ fontSize: "small" }}
                                    className="text-info"
                                  >
                                    When changing booking schedule, recurring
                                    booking linked to this booking will be
                                    cancelled
                                  </span>
                                )}
                              </MDBCol>
                              <MDBCol md="6">
                                <MDBInput
                                  readOnly={this.state.isView || isDefaultUser}
                                  label="Time"
                                  disabled
                                  value={time}
                                  style={{ fontWeight: "bold" }}
                                />
                              </MDBCol>

                              {/* Customer details */}
                              <MDBCol md="6" className="mb-3">
                                <label
                                  htmlFor="idFirstname"
                                  className="grey-text required"
                                >
                                  First name
                                </label>
                                <input
                                  value={first_name}
                                  name="first_name"
                                  onChange={this.changeHandler}
                                  type="text"
                                  id="idFirstname"
                                  className="form-control"
                                  required
                                  readOnly={this.state.isView || isDefaultUser}
                                  disabled={this.state.isView || isDefaultUser}
                                />
                                <div className="invalid-feedback">
                                  Please provide the first name
                                </div>
                              </MDBCol>
                              <MDBCol md="6" className="mb-3">
                                <label
                                  htmlFor="idLastname"
                                  className="grey-text required"
                                >
                                  Last name
                                </label>
                                <input
                                  value={last_name}
                                  name="last_name"
                                  onChange={this.changeHandler}
                                  type="text"
                                  id="idLastname"
                                  className="form-control"
                                  required
                                  readOnly={this.state.isView || isDefaultUser}
                                  disabled={this.state.isView || isDefaultUser}
                                />
                                <div className="invalid-feedback">
                                  Please provide the last name
                                </div>
                              </MDBCol>
                              <MDBCol md="6" className="mb-3">
                                <MDBInput
                                  label="Email"
                                  labelClass="required"
                                  disabled
                                  value={email}
                                />
                              </MDBCol>
                              <MDBCol md="6" className="mb-3">
                                <label
                                  htmlFor="idContactNumber"
                                  className="grey-text required"
                                >
                                  Contact number
                                </label>
                                <input
                                  readOnly={this.state.isView || isDefaultUser}
                                  disabled={this.state.isView || isDefaultUser}
                                  value={mobile}
                                  name="mobile"
                                  onChange={this.changeHandler}
                                  type="text"
                                  id="idContactNumber"
                                  className="form-control"
                                  required
                                />
                                <div className="invalid-feedback">
                                  Please provide the contact number
                                </div>
                              </MDBCol>
                              <MDBCol md="6" className="mb-3">
                                <label
                                  htmlFor="idAddress"
                                  className="grey-text"
                                >
                                  Address
                                </label>
                                <input
                                  readOnly={this.state.isView || isDefaultUser}
                                  disabled={this.state.isView || isDefaultUser}
                                  value={address}
                                  name="address"
                                  onChange={this.changeHandler}
                                  type="text"
                                  id="idAddress"
                                  className="form-control"
                                />
                                <div className="invalid-feedback">
                                  Please provide the contact number
                                </div>
                              </MDBCol>
                              <MDBCol md="6" className="mb-3">
                                <label
                                  htmlFor="idAddress"
                                  className="grey-text"
                                >
                                  Membership Details
                                </label>
                                <input
                                  readOnly={true}
                                  disabled={true}
                                  value={membershipDetails}
                                  type="text"
                                  className="form-control"
                                />
                              </MDBCol>
                              {/* Customer details */}
                              {/* Duration and payment details */}
                              {this.state.isView
                                ? ""
                                : this.renderAvailableSlot(
                                    getAvailableSlot,
                                    durationDefaultVal
                                  )}

                              <MDBCol md="6">
                                <label className="grey-text required">
                                  Payment method
                                </label>
                                <select
                                  readOnly={this.state.isView || isDefaultUser}
                                  disabled={this.state.isView || isDefaultUser}
                                  className="browser-default custom-select"
                                  name="paymentMethod"
                                  onChange={this.changeHandler}
                                >
                                  <option value="Credit Card">
                                    Credit Card
                                  </option>
                                  {/* <option value="Voucher">Voucher</option> */}
                                  <option value="Cash">Cash</option>
                                </select>
                              </MDBCol>
                              {!isDefaultUser && (
                                <MDBCol md="6" className="mt-4">
                                  <label
                                    htmlFor="idCoupon"
                                    className="grey-text required"
                                  >
                                    Discount Code
                                  </label>
                                  <input
                                    value={coupon}
                                    name="coupon"
                                    onChange={this.onCouponChange}
                                    type="text"
                                    id="idCoupon"
                                    className="form-control"
                                  />
                                  {this.isInvalidCoupon()}
                                </MDBCol>
                              )}
                              {!isDefaultUser && (
                                <MDBCol md="1" className=" mt-4">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      readOnly={this.state.isView}
                                      disabled={this.state.isView}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="defaultUnchecked"
                                      defaultChecked={paid}
                                      checked={paid}
                                      onChange={this.onACheckChange}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="defaultUnchecked"
                                      style={{
                                        fontWeight: "bold",
                                        marginTop: "35px !important",
                                      }}
                                    >
                                      Paid
                                    </label>
                                  </div>
                                </MDBCol>
                              )}
                              {!isDefaultUser && (
                                <MDBCol md="5" className="mt-4">
                                  <label
                                    htmlFor="idInvoice"
                                    className="grey-text"
                                  >
                                    Invoice
                                  </label>
                                  <input
                                    readOnly={this.state.isView}
                                    disabled={this.state.isView}
                                    value={invoice}
                                    name="invoice"
                                    onChange={this.changeHandler}
                                    type="text"
                                    id="idInvoice"
                                    className="form-control"
                                  />
                                  <div className="invalid-feedback">
                                    Please provide the contact number
                                  </div>
                                </MDBCol>
                              )}

                              {!isDefaultUser && paid && (
                                <MDBCol md="5" className="mt-4">
                                  <label
                                    htmlFor="idInvoice"
                                    className="grey-text"
                                  >
                                    Override Payment Date
                                  </label>
                                  <DatePicker
                                    className="custom-input"
                                    placeholderText="Override Payment Date"
                                    selected={paymentDate}
                                    onChange={this.paymentDateChangeHandler}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    name="paymentDate"
                                    isClearable={true}
                                    showTimeSelect
                                  />
                                </MDBCol>
                              )}
                              {!isDefaultUser &&
                                selectedBooking.is_recurring === 1 && (
                                  <MDBCol md="2" className="mt-4">
                                    <MDBInput
                                      readOnly={this.state.isView}
                                      disabled={this.state.isView}
                                      label="Recurring Type"
                                      disabled
                                      value={selectedBooking.recurring_type}
                                      style={{ fontWeight: "bold" }}
                                    />
                                  </MDBCol>
                                )}

                              {!isDefaultUser &&
                                selectedBooking.is_recurring === 0 &&
                                selectedBooking.parent_booking !== undefined &&
                                selectedBooking.parent_booking
                                  .recurring_type !== undefined && (
                                  <MDBCol md="2" className="mt-4">
                                    <MDBInput
                                      readOnly={this.state.isView}
                                      disabled={this.state.isView}
                                      label="Recurring Type"
                                      disabled
                                      value={
                                        selectedBooking.parent_booking
                                          .recurring_type
                                      }
                                      style={{ fontWeight: "bold" }}
                                    />
                                  </MDBCol>
                                )}
                              {validateAccess(
                                storeUserAccess,
                                "AdminBooking",
                                "allowManualChangeBookingFeeTodo"
                              ) && (
                                <MDBCol md="12">
                                  <div className="custom-control custom-checkbox mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="overwriteFee"
                                      defaultValue={overwriteFee}
                                      checked={overwriteFee}
                                      onChange={
                                        this.onOverwritePriceCheckChange
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="overwriteFee"
                                      style={{
                                        fontWeight: "bold",
                                        marginTop: "35px !important",
                                      }}
                                    >
                                      Overwrite price?
                                    </label>
                                  </div>
                                  {overwriteFee && (
                                    <MDBCol md="6">
                                      <label
                                        htmlFor="idOverwriteFeeAmount"
                                        className="grey-text mt-3"
                                      >
                                        New price
                                      </label>
                                      <input
                                        value={overwriteFeeAmount}
                                        name="overwriteFeeAmount"
                                        onChange={this.changeHandler}
                                        type="number"
                                        id="idOverwriteFeeAmount"
                                        className="form-control"
                                      />
                                    </MDBCol>
                                  )}
                                </MDBCol>
                              )}
                              {/* Duration and payment details */}
                            </MDBRow>
                          </div>
                          {/* Change schedule */}
                          <div
                            style={{
                              display: `${
                                showCalendarSelect ? "block" : "none"
                              }`,
                            }}
                          >
                            <MDBBtn
                              color="primary"
                              onClick={() => {
                                // reset the selected date
                                let selectedDate = moment(
                                  selectedBooking.start_time
                                )
                                  .tz(this.getTzDetails().timezone)
                                  .format("YYYY-MM-DD");
                                this.props.selectDate(new Date(selectedDate));

                                this.showCalendarSelect(false);
                              }}
                            >
                              <MDBIcon icon="angle-left" className="mr-1" />
                              Go back
                            </MDBBtn>
                            <div className="modalCalendar">
                              {this.renderWeeklyCalendar()}
                            </div>
                          </div>
                        </MDBCollapse>
                      </MDBCard>
                    )}

                  {validateAccess(
                    storeUserAccess,
                    "BookingDetails",
                    "participantsTab"
                  ) &&
                    renderParticipants && (
                      <MDBCard className="my-1" key={"cid-2"}>
                        <MDBCollapseHeader
                          className="grey lighten-3"
                          onClick={this.mobileToggleCollapse("collapse2")}
                        >
                          <span className="">Participants</span>
                          <MDBIcon
                            icon={
                              collapseID === "collapse2"
                                ? "angle-up"
                                : "angle-down"
                            }
                            className=""
                            style={{ float: "right" }}
                          />
                        </MDBCollapseHeader>
                        <MDBCollapse
                          className="p-2"
                          id={"collapse2"}
                          isOpen={collapseID}
                        >
                          <MDBRow>
                            <MDBCol md="12">
                              {allowUpdatingParticipants && (
                                <div>
                                  {this.renderParticipantAutoComplete()}
                                </div>
                              )}
                              <br></br>
                              {this.renderBookingParticipants()}
                            </MDBCol>
                          </MDBRow>
                        </MDBCollapse>
                      </MDBCard>
                    )}

                  {validateAccess(
                    storeUserAccess,
                    "BookingDetails",
                    "paymentsTab"
                  ) && (
                    <MDBCard className="my-1" key={"cid-3"}>
                      <MDBCollapseHeader
                        className="grey lighten-3"
                        onClick={this.mobileToggleCollapse("collapse3")}
                      >
                        <span className="">Payments</span>
                        <MDBIcon
                          icon={
                            collapseID === "collapse3"
                              ? "angle-up"
                              : "angle-down"
                          }
                          className=""
                          style={{ float: "right" }}
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        className="p-2"
                        id={"collapse3"}
                        isOpen={collapseID}
                      >
                        <MDBRow>
                          <MDBCol md="12">{this.renderPaymentHistory()}</MDBCol>
                        </MDBRow>
                      </MDBCollapse>
                    </MDBCard>
                  )}
                  {validateAccess(
                    storeUserAccess,
                    "BookingDetails",
                    "statusTab"
                  ) && (
                    <MDBCard className="my-1" key={"cid-4"}>
                      <MDBCollapseHeader
                        className="grey lighten-3"
                        onClick={this.mobileToggleCollapse("collapse4")}
                      >
                        <span className="">Status</span>
                        <MDBIcon
                          icon={
                            collapseID === "collapse4"
                              ? "angle-up"
                              : "angle-down"
                          }
                          className=""
                          style={{ float: "right" }}
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        className="p-2"
                        id={"collapse4"}
                        isOpen={collapseID}
                      >
                        <MDBRow>
                          {this.renderBookingStatuses()}
                          {paid && (
                            <MDBCol md="6">
                              {this.renderStatusChangeConfirmation()}
                            </MDBCol>
                          )}

                          <MDBCol md="12">
                            <label
                              htmlFor="idStatusNotes"
                              className={`grey-text mt-3 ${
                                this.state.statusChangeConfirmation.length >
                                  0 && paid
                                  ? "required"
                                  : ""
                              }`}
                            >
                              Notes
                            </label>
                            <textarea
                              readOnly={this.state.isView}
                              disabled={this.state.isView}
                              value={statusNotes}
                              name="statusNotes"
                              onChange={this.changeHandler}
                              type="textarea"
                              id="idStatusNotes"
                              className="form-control"
                              rows="5"
                              required={
                                this.state.statusChangeConfirmation.length > 0
                              }
                            />
                            <div className="invalid-feedback">
                              Please provide the notes when changing status
                            </div>
                          </MDBCol>

                          <MDBCol md="12">
                            <h5 className="mt-4">Status History</h5>
                            {this.renderStatusHistory()}
                          </MDBCol>
                        </MDBRow>
                      </MDBCollapse>
                    </MDBCard>
                  )}
                  {validateAccess(
                    storeUserAccess,
                    "BookingDetails",
                    "notesTab"
                  ) && (
                    <MDBCard className="my-1" key={"cid-5"}>
                      <MDBCollapseHeader
                        className="grey lighten-3"
                        onClick={this.mobileToggleCollapse("collapse5")}
                      >
                        <span className="">Notes</span>
                        <MDBIcon
                          icon={
                            collapseID === "collapse5"
                              ? "angle-up"
                              : "angle-down"
                          }
                          className=""
                          style={{ float: "right" }}
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        className="p-2"
                        id={"collapse5"}
                        isOpen={collapseID}
                      >
                        <MDBRow>
                          <MDBCol size="12">
                            <label htmlFor="idNotes" className="grey-text mt-3">
                              Notes
                            </label>
                            <textarea
                              readOnly={this.state.isView}
                              disabled={this.state.isView}
                              value={notes}
                              name="notes"
                              onChange={this.changeHandler}
                              type="textarea"
                              id="idNotes"
                              className="form-control"
                              rows="5"
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCollapse>
                    </MDBCard>
                  )}
                  {validateAccess(
                    storeUserAccess,
                    "BookingDetails",
                    "communicationsTab"
                  ) && (
                    <MDBCard className="my-1" key={"cid-6"}>
                      <MDBCollapseHeader
                        className="grey lighten-3"
                        onClick={this.mobileToggleCollapse("collapse6")}
                      >
                        <span className="">Communications</span>
                        <MDBIcon
                          icon={
                            collapseID === "collapse3"
                              ? "angle-up"
                              : "angle-down"
                          }
                          className=""
                          style={{ float: "right" }}
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse
                        className="p-2"
                        id={"collapse3"}
                        isOpen={collapseID}
                      >
                        <MDBRow>
                          <MDBCol md="12">
                            {this.renderCustomerOutboundNotifications()}
                          </MDBCol>
                        </MDBRow>
                      </MDBCollapse>
                    </MDBCard>
                  )}
                  {/* Accordion */}

                  <MDBTabContent
                    className=""
                    activeItem={activeItemOuterTabs}
                    style={{ paddingBottom: "0" }}
                  >
                    <MDBTabPane tabId="1" role="tabpanel">
                      <MDBRow>
                        <MDBCol md="10" style={{ position: "relative" }}>
                          <MDBTabContent
                            activeItem={activeItemInnerPills}
                            style={{ marginBottom: "145px" }}
                          >
                            {!this.isGuestOnly() && (
                              <div className="pricing-container">
                                <div className="float-right">
                                  <table className="pricing-table">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Booking Price:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${origPrice}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Charge:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${!tempCharge ? chargeAmount : 0}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Paid amount:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${paidAmount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Discount:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${couponDiscount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Refund:</strong>
                                        </td>
                                        <td className="float-right">
                                          <span
                                            className={
                                              lessAmount > 0
                                                ? "text-danger"
                                                : ""
                                            }
                                          >
                                            ${lessAmount}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>TOTAL CHARGE:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${totalPrice}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </MDBTabContent>
                        </MDBCol>
                      </MDBRow>
                      {!this.state.isDefaultUser && !this.state.isView && (
                        <>
                          <br></br>
                          <MDBRow>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="sendNotification"
                                defaultChecked={sendNotification}
                                checked={sendNotification}
                                onChange={this.onSendNotificationChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="sendNotification"
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "35px !important",
                                }}
                              >
                                Send customer notification?
                              </label>
                            </div>
                          </MDBRow>
                        </>
                      )}
                    </MDBTabPane>
                    {this.validatePriceChangeWarning()}
                    {this.renderMaxDurationExceedAcknowledge()}
                  </MDBTabContent>
                </MDBModalBody>
              )}

              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.toggleBookingDetailsModal(false)}
                >
                  Close
                </MDBBtn>
                {this.state.isBookingCancellable &&
                  this.state.selectedBooking.is_participant &&
                  this.state.isDefaultUser &&
                  !this.isReadOnly && (
                    <>
                      <MDBBtn
                        color="primary"
                        onClick={() => this.bookingParticipantOptout()}
                      >
                        Opt out of the Booking
                      </MDBBtn>
                    </>
                  )}
                {selectedBooking.is_cancelled !== 1 &&
                  this.state.isBookingCancellable &&
                  !this.state.selectedBooking.is_participant &&
                  this.state.isDefaultUser &&
                  !this.isReadOnly && (
                    <>
                      <MDBBtn
                        color="danger"
                        onClick={() => this.cancelBooking()}
                      >
                        Cancel Booking
                      </MDBBtn>
                    </>
                  )}
                {isDefaultUser &&
                  !this.isGuestOnly() &&
                  (this.state.chargeAmount > 0 ||
                    (this.state.unPaidAmount > 0 &&
                      this.state.selectedBooking.is_cancelled !== 1)) && (
                    <MDBBtn
                      color="success"
                      type="button"
                      onClick={() => this.doCharge(true)}
                    >
                      Pay {this.state.selectedBooking.is_cancelled}
                    </MDBBtn>
                  )}
                {this.state.isView || this.isReadOnly
                  ? ""
                  : ((isDefaultUser && this.state.isBookingCancellable) ||
                      !isDefaultUser) &&
                    validateAccess(
                      storeUserAccess,
                      "AdminBooking",
                      "updateBooking"
                    ) && (
                      <MDBBtn
                        color="primary"
                        type="submit"
                        disabled={disableSaveButton}
                      >
                        Save
                      </MDBBtn>
                    )}
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      );
    }
    return <span></span>;
  }

  // ----- MOBILE ------- //
  onOverwritePriceCheckChange = (evt) => {
    this.setState({
      overwriteFee: !this.state.overwriteFee,
    });
  };

  renderBookingDetailsForm() {
    const {
      showBookingDetailsModal,
      selectedBooking,
      activeItemOuterTabs,
      activeItemInnerPills,
      first_name,
      last_name,
      email,
      mobile,
      address,
      membershipDetails,
      searchDateVal,
      invoice,
      paid,
      paymentDate,
      notes,
      time,
      durationDefaultVal,

      discountAmount,

      bookingRef,
      customerUniqueReference,
      bookingUuid,
      bookingId,

      locationId,
      locationUuid,
      locationName,

      resourceId,
      resourceUuid,
      resourceName,

      customerId,
      customerUuid,

      statusNotes,

      status,
      statusDesc,
      statusChangeConfirmation,
      getAvailableSlot,
      isRecurring,
      frequencyInWeeks,
      disableSaveButton,
      showRecurringWarning,
      showCalendarSelect,

      origPrice,
      lessAmount,
      chargeAmount,
      unPaidAmount,
      paidAmount,
      couponDiscount,
      totalPrice,
      coupon,
      paymentMethod,
      tempCharge,
      sendNotification,
      overwriteFee,
      overwriteFeeAmount,
      locationEmail,
      locationPhone,
      isDefaultUser,
    } = this.state;
    const { storeUserAccess } = this.props;

    let allowUpdatingParticipants =
      (!this.state.selectedBooking.is_participant &&
        this.state.isDefaultUser &&
        this.state.isBookingCancellable) ||
      !this.state.isDefaultUser;

    if (
      this.state.selectedBooking.location !== undefined &&
      showBookingDetailsModal
    ) {
      var href = window.location.pathname + window.location.search;
      var renderParticipants =
        (typeof this.state.selectedBooking.participants != "undefined" &&
          this.state.selectedBooking.participants.length > 0) ||
        this.maxBookingParticipants == -1 ||
        this.maxBookingParticipants > 0;
      return (
        <div className="booking-modal">
          <MDBModal
            isOpen={showBookingDetailsModal}
            size={this.isReadOnly ? "lg" : "fluid"}
            wrapClassName="scrollable"
            toggle={() => this.toggleBookingDetailsModal(false)}
          >
            <form
              className={`needs-validation ${this.state.wasValidated}`}
              onSubmit={this.updateSubmitHandler}
              noValidate
            >
              <MDBModalHeader className="bookingForm">
                <MDBRow>
                  <MDBCol
                    md="2"
                    style={{ paddingTop: "12px", paddingLeft: "41px" }}
                  >
                    Booking Form
                  </MDBCol>
                  <MDBCol md="10">
                    <MDBRow>
                      <MDBCol md="5">
                        <MDBInput
                          label="Location"
                          disabled
                          value={locationName}
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          label="Resource"
                          disabled
                          value={resourceName}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <MDBInput
                          label="Booking Reference"
                          disabled
                          value={bookingRef}
                        />

                        {customerUniqueReference != "" && (
                          <MDBInput
                            label="Your Unique Reference"
                            disabled
                            value={customerUniqueReference}
                          />
                        )}
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="5">
                        <MDBInput
                          label="Reservation Date"
                          disabled
                          value={moment(searchDateVal, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Time" disabled value={time} />
                      </MDBCol>
                      <MDBCol md="3">
                        <MDBInput
                          label="Name"
                          disabled
                          value={first_name + " " + last_name}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBModalHeader>
              <MDBModalBody>
                {this.isReadOnly ? (
                  <p
                    style={{ textAlign: "center", overflowWrap: "break-word" }}
                  >
                    For more information about this booking please contact{" "}
                    {locationName}, {locationPhone}, or {locationEmail}.
                  </p>
                ) : (
                  <MDBTabContent
                    className=""
                    activeItem={activeItemOuterTabs}
                    style={{ paddingBottom: "0" }}
                  >
                    <MDBTabPane tabId="1" role="tabpanel">
                      <MDBRow>
                        <MDBCol md="2" className="border-right">
                          <MDBNav pills color="primary" className="flex-column">
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "bookingDetailsTab"
                            ) &&
                              !this.isGuestOnly() && (
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to={href}
                                    active={activeItemInnerPills === "1"}
                                    onClick={this.toggleInnerPills("1")}
                                  >
                                    Booking Details
                                  </MDBNavLink>
                                </MDBNavItem>
                              )}

                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "participantsTab"
                            ) &&
                              renderParticipants && (
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to={href}
                                    active={activeItemInnerPills === "5"}
                                    onClick={this.toggleInnerPills("5")}
                                  >
                                    Participants
                                  </MDBNavLink>
                                </MDBNavItem>
                              )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "paymentsTab"
                            ) && (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to={href}
                                  to={href}
                                  active={activeItemInnerPills === "2"}
                                  onClick={this.toggleInnerPills("2")}
                                >
                                  Payments
                                </MDBNavLink>
                              </MDBNavItem>
                            )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "statusTab"
                            ) && (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to={href}
                                  active={activeItemInnerPills === "3"}
                                  onClick={this.toggleInnerPills("3")}
                                >
                                  Status
                                </MDBNavLink>
                              </MDBNavItem>
                            )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "notesTab"
                            ) && (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to={href}
                                  active={activeItemInnerPills === "4"}
                                  onClick={this.toggleInnerPills("4")}
                                >
                                  Notes
                                </MDBNavLink>
                              </MDBNavItem>
                            )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "communicationsTab"
                            ) && (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to={href}
                                  active={activeItemInnerPills === "6"}
                                  onClick={this.toggleInnerPills("6")}
                                >
                                  Communications
                                </MDBNavLink>
                              </MDBNavItem>
                            )}
                          </MDBNav>
                        </MDBCol>
                        <MDBCol md="10" style={{ position: "relative" }}>
                          <MDBTabContent
                            activeItem={activeItemInnerPills}
                            style={{ marginBottom: "145px" }}
                          >
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "bookingDetailsTab"
                            ) &&
                              !this.isGuestOnly() && (
                                <MDBTabPane tabId="1">
                                  {/* Original form */}
                                  <div
                                    style={{
                                      display: `${
                                        showCalendarSelect ? "none" : "block"
                                      }`,
                                    }}
                                  >
                                    <MDBRow>
                                      <MDBCol
                                        md="6"
                                        className="custom-select-date"
                                      >
                                        <div className="md-form input-group mb-3">
                                          <label
                                            className="label grey-text required"
                                            style={{
                                              fontSize: "13px",
                                              top: "-22px",
                                            }}
                                          >
                                            {!isDefaultUser
                                              ? "Reservation date(Click to change booking schedule)"
                                              : "Reservation date"}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            // aria-describedby="MaterialButton-addon2"
                                            readOnly={
                                              this.state.isView || isDefaultUser
                                            }
                                            disabled={
                                              this.state.isView || isDefaultUser
                                            }
                                            value={moment(
                                              searchDateVal,
                                              "YYYY-MM-DD"
                                            ).format("DD/MM/YYYY")}
                                            onClick={() =>
                                              this.showCalendarSelect(true)
                                            }
                                          />
                                          {!this.state.isView &&
                                            !isDefaultUser && (
                                              <div className="input-group-append">
                                                <MDBBtn
                                                  style={{
                                                    borderRight:
                                                      "2px solid white",
                                                  }}
                                                  color="primary"
                                                  className="m-0 px-3 py-2 z-depth-0 btn-sm change-text"
                                                  onClick={() =>
                                                    this.showCalendarSelect(
                                                      true
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fas fa-undo"
                                                    style={{ fontSize: "12px" }}
                                                  ></i>
                                                </MDBBtn>

                                                <MDBBtn
                                                  color="primary"
                                                  className="m-0 px-3 py-2 z-depth-0 btn-sm undo-text"
                                                  onClick={() =>
                                                    this.resetToOriginalVal()
                                                  }
                                                >
                                                  <i
                                                    className="fas fa-undo"
                                                    style={{ fontSize: "12px" }}
                                                  ></i>
                                                </MDBBtn>
                                              </div>
                                            )}
                                        </div>
                                        {!isDefaultUser && (
                                          <span
                                            style={{ fontSize: "small" }}
                                            className="text-info"
                                          >
                                            When changing booking schedule,
                                            recurring booking linked to this
                                            booking will be cancelled
                                          </span>
                                        )}
                                      </MDBCol>
                                      <MDBCol md="6">
                                        <MDBInput
                                          readOnly={this.state.isView}
                                          label="Time"
                                          disabled
                                          value={time}
                                          style={{ fontWeight: "bold" }}
                                        />
                                      </MDBCol>

                                      {/* Customer details */}
                                      <MDBCol md="6" className="mb-3">
                                        <label
                                          htmlFor="idFirstname"
                                          className="grey-text required"
                                        >
                                          First name
                                        </label>
                                        <input
                                          data-type="customer"
                                          value={first_name}
                                          name="first_name"
                                          onChange={this.changeHandler}
                                          type="text"
                                          id="idFirstname"
                                          className="form-control"
                                          required
                                          readOnly={
                                            this.state.isView || isDefaultUser
                                          }
                                          disabled={
                                            this.state.isView || isDefaultUser
                                          }
                                        />
                                        <div className="invalid-feedback">
                                          Please provide the first name
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6" className="mb-3">
                                        <label
                                          htmlFor="idLastname"
                                          className="grey-text required"
                                        >
                                          Last name
                                        </label>
                                        <input
                                          data-type="customer"
                                          value={last_name}
                                          name="last_name"
                                          onChange={this.changeHandler}
                                          type="text"
                                          id="idLastname"
                                          className="form-control"
                                          required
                                          readOnly={
                                            this.state.isView || isDefaultUser
                                          }
                                          disabled={
                                            this.state.isView || isDefaultUser
                                          }
                                        />
                                        <div className="invalid-feedback">
                                          Please provide the last name
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6" className="mb-3">
                                        <MDBInput
                                          data-type="customer"
                                          label="Email"
                                          labelClass="required"
                                          disabled
                                          value={email}
                                        />
                                      </MDBCol>
                                      <MDBCol md="6" className="mb-3">
                                        <label
                                          htmlFor="idContactNumber"
                                          className="grey-text required"
                                        >
                                          Contact number
                                        </label>
                                        <input
                                          data-type="customer"
                                          readOnly={
                                            this.state.isView || isDefaultUser
                                          }
                                          disabled={
                                            this.state.isView || isDefaultUser
                                          }
                                          value={mobile}
                                          name="mobile"
                                          onChange={this.changeHandler}
                                          type="text"
                                          id="idContactNumber"
                                          className="form-control"
                                          required
                                        />
                                        <div className="invalid-feedback">
                                          Please provide the contact number
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6" className="mb-3">
                                        <label
                                          htmlFor="idAddress"
                                          className="grey-text"
                                        >
                                          Address
                                        </label>
                                        <input
                                          data-type="customer"
                                          readOnly={
                                            this.state.isView || isDefaultUser
                                          }
                                          disabled={
                                            this.state.isView || isDefaultUser
                                          }
                                          value={address}
                                          name="address"
                                          onChange={this.changeHandler}
                                          type="text"
                                          id="idAddress"
                                          className="form-control"
                                        />
                                        <div className="invalid-feedback">
                                          Please provide the contact number
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6" className="mb-3">
                                        <label
                                          htmlFor="idAddress"
                                          className="grey-text"
                                        >
                                          Membership Details
                                        </label>
                                        <input
                                          readOnly={true}
                                          disabled={true}
                                          value={membershipDetails}
                                          type="text"
                                          className="form-control"
                                        />
                                      </MDBCol>
                                      {/* Customer details */}
                                      {/* Duration and payment details */}
                                      {this.state.isView
                                        ? ""
                                        : this.renderAvailableSlot(
                                            getAvailableSlot,
                                            durationDefaultVal
                                          )}

                                      <MDBCol size="6">
                                        <label className="grey-text required">
                                          Payment method
                                        </label>
                                        <select
                                          readOnly={
                                            this.state.isView || isDefaultUser
                                          }
                                          disabled={
                                            this.state.isView || isDefaultUser
                                          }
                                          className="browser-default custom-select"
                                          name="paymentMethod"
                                          onChange={this.changeHandler}
                                        >
                                          <option value="Credit Card">
                                            Credit Card
                                          </option>
                                          {/* <option value="Voucher">Voucher</option> */}
                                          <option value="Cash">Cash</option>
                                        </select>
                                      </MDBCol>
                                      {!isDefaultUser && (
                                        <MDBCol size="6" className="mt-4">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              readOnly={this.state.isView}
                                              disabled={this.state.isView}
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="defaultUnchecked"
                                              defaultChecked={paid}
                                              checked={paid}
                                              onChange={this.onACheckChange}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="defaultUnchecked"
                                              style={{
                                                fontWeight: "bold",
                                                marginTop: "35px !important",
                                              }}
                                            >
                                              Paid
                                            </label>
                                          </div>
                                        </MDBCol>
                                      )}
                                      {!isDefaultUser ? (
                                        <MDBCol size="6" className=" mt-4">
                                          <label
                                            htmlFor="idCoupon"
                                            className="grey-text required"
                                          >
                                            Discount Code
                                          </label>
                                          <input
                                            value={coupon}
                                            name="coupon"
                                            onChange={this.onCouponChange}
                                            type="text"
                                            id="idCoupon"
                                            className="form-control"
                                          />
                                          {this.isInvalidCoupon()}
                                        </MDBCol>
                                      ) : (
                                        <MDBCol md="6"></MDBCol>
                                      )}
                                      {!isDefaultUser && (
                                        <MDBCol size="6">
                                          <label
                                            htmlFor="idInvoice"
                                            className="grey-text"
                                          >
                                            Invoice
                                          </label>
                                          <input
                                            readOnly={this.state.isView}
                                            disabled={this.state.isView}
                                            value={invoice}
                                            name="invoice"
                                            onChange={this.changeHandler}
                                            type="text"
                                            id="idInvoice"
                                            className="form-control"
                                          />
                                          <div className="invalid-feedback">
                                            Please provide the contact number
                                          </div>
                                        </MDBCol>
                                      )}

                                      {!isDefaultUser &&
                                        selectedBooking.is_recurring === 1 && (
                                          <MDBCol md="6">
                                            <MDBInput
                                              readOnly={this.state.isView}
                                              disabled={this.state.isView}
                                              label="Recurring Type"
                                              disabled
                                              value={
                                                selectedBooking.recurring_type
                                              }
                                              style={{ fontWeight: "bold" }}
                                            />
                                          </MDBCol>
                                        )}

                                      {!isDefaultUser &&
                                      selectedBooking.is_recurring === 0 &&
                                      selectedBooking.parent_booking !==
                                        undefined &&
                                      selectedBooking.parent_booking
                                        .recurring_type !== undefined ? (
                                        <MDBCol md="6">
                                          <MDBInput
                                            readOnly={this.state.isView}
                                            disabled={this.state.isView}
                                            label="Recurring Type"
                                            disabled
                                            value={
                                              selectedBooking.parent_booking
                                                .recurring_type
                                            }
                                            style={{ fontWeight: "bold" }}
                                          />
                                        </MDBCol>
                                      ) : (
                                        <MDBCol md="6"></MDBCol>
                                      )}
                                      {!isDefaultUser &&
                                      validateAccess(
                                        this.props.storeUserAccess,
                                        "AdminBooking",
                                        "updateBooking"
                                      ) &&
                                      paid ? (
                                        <MDBCol md="6">
                                          <label
                                            htmlFor="idInvoice"
                                            className="grey-text"
                                          >
                                            Set Payment Date
                                          </label>
                                          <DatePicker
                                            className="custom-input"
                                            placeholderText="Override Payment Date"
                                            selected={paymentDate}
                                            onChange={
                                              this.paymentDateChangeHandler
                                            }
                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                            name="paymentDate"
                                            isClearable={true}
                                            showTimeSelect
                                          />
                                        </MDBCol>
                                      ) : (
                                        <MDBCol md="6"></MDBCol>
                                      )}

                                      {validateAccess(
                                        storeUserAccess,
                                        "AdminBooking",
                                        "allowManualChangeBookingFeeTodo"
                                      ) && (
                                        <MDBCol md="12">
                                          <div className="custom-control custom-checkbox mt-4">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="overwriteFee"
                                              defaultValue={overwriteFee}
                                              checked={overwriteFee}
                                              onChange={
                                                this.onOverwritePriceCheckChange
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="overwriteFee"
                                              style={{
                                                fontWeight: "bold",
                                                marginTop: "35px !important",
                                              }}
                                            >
                                              Overwrite price?
                                            </label>
                                          </div>
                                          {overwriteFee && (
                                            <MDBCol md="6">
                                              <label
                                                htmlFor="idOverwriteFeeAmount"
                                                className="grey-text mt-3"
                                              >
                                                New price
                                              </label>
                                              <input
                                                value={overwriteFeeAmount}
                                                name="overwriteFeeAmount"
                                                onChange={this.changeHandler}
                                                type="number"
                                                id="idOverwriteFeeAmount"
                                                className="form-control"
                                              />
                                            </MDBCol>
                                          )}
                                        </MDBCol>
                                      )}

                                      {/* Duration and payment details */}
                                    </MDBRow>
                                  </div>
                                  {/* Change schedule */}
                                  <div
                                    style={{
                                      display: `${
                                        showCalendarSelect ? "block" : "none"
                                      }`,
                                    }}
                                  >
                                    <MDBBtn
                                      color="primary"
                                      onClick={() => {
                                        // reset the selected date
                                        let selectedDate = moment(
                                          selectedBooking.start_time
                                        )
                                          .tz(this.getTzDetails().timezone)
                                          .format("YYYY-MM-DD");
                                        this.props.selectDate(
                                          new Date(selectedDate)
                                        );
                                        this.showCalendarSelect(false);
                                      }}
                                    >
                                      <MDBIcon
                                        icon="angle-left"
                                        className="mr-1"
                                      />
                                      Go back
                                    </MDBBtn>
                                    <div className="modalCalendar">
                                      {this.renderWeeklyCalendar()}
                                    </div>
                                  </div>
                                </MDBTabPane>
                              )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "paymentsTab"
                            ) && (
                              <MDBTabPane tabId="2">
                                <MDBRow>
                                  <MDBCol size="12">
                                    {this.renderPaymentHistory()}
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                            )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "statusTab"
                            ) && (
                              <MDBTabPane tabId="3">
                                <MDBRow>
                                  {this.renderBookingStatuses()}
                                  {paid && (
                                    <MDBCol md="6">
                                      {this.renderStatusChangeConfirmation()}
                                    </MDBCol>
                                  )}

                                  <MDBCol md="12">
                                    <label
                                      htmlFor="idStatusNotes"
                                      className={`grey-text mt-3 ${
                                        this.state.statusChangeConfirmation
                                          .length > 0 && paid
                                          ? "required"
                                          : ""
                                      }`}
                                    >
                                      Notes
                                    </label>
                                    <textarea
                                      readOnly={this.state.isView}
                                      disabled={this.state.isView}
                                      value={statusNotes}
                                      name="statusNotes"
                                      onChange={this.changeHandler}
                                      type="textarea"
                                      id="idStatusNotes"
                                      className="form-control"
                                      rows="5"
                                      required={
                                        this.state.statusChangeConfirmation
                                          .length > 0
                                      }
                                    />
                                    <div className="invalid-feedback">
                                      Please provide the notes when changing
                                      status
                                    </div>
                                  </MDBCol>

                                  <MDBCol md="12">
                                    <h5 className="mt-4">Status History</h5>
                                    {this.renderStatusHistory()}
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                            )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "notesTab"
                            ) && (
                              <MDBTabPane tabId="4">
                                <MDBRow>
                                  <MDBCol size="12">
                                    <label
                                      htmlFor="idNotes"
                                      className="grey-text mt-3"
                                    >
                                      Notes
                                    </label>
                                    <textarea
                                      readOnly={this.state.isView}
                                      disabled={this.state.isView}
                                      value={notes}
                                      name="notes"
                                      onChange={this.changeHandler}
                                      type="textarea"
                                      id="idNotes"
                                      className="form-control"
                                      rows="5"
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                            )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "participantsTab"
                            ) &&
                              renderParticipants && (
                                <MDBTabPane tabId="5">
                                  <MDBRow>
                                    <MDBCol size="12">
                                      {this.showAlert()}
                                      {allowUpdatingParticipants && (
                                        <div>
                                          {this.renderParticipantAutoComplete()}
                                        </div>
                                      )}
                                      <br></br>
                                      {this.renderBookingParticipants()}
                                    </MDBCol>
                                  </MDBRow>
                                </MDBTabPane>
                              )}
                            {validateAccess(
                              storeUserAccess,
                              "BookingDetails",
                              "communicationsTab"
                            ) && (
                              <MDBTabPane tabId="6">
                                <MDBRow>
                                  <MDBCol size="12">
                                    {this.renderCustomerOutboundNotifications()}
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                            )}

                            <div className="pricing-container">
                              {!this.state.isDefaultUser &&
                                !this.state.isView && (
                                  <>
                                    <div
                                      style={{
                                        float: "left",
                                        position: "absolute",
                                        bottom: "10px",
                                      }}
                                    >
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="sendNotification"
                                          defaultChecked={sendNotification}
                                          checked={sendNotification}
                                          onChange={
                                            this.onSendNotificationChange
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="sendNotification"
                                          style={{
                                            fontWeight: "bold",
                                            marginTop: "35px !important",
                                          }}
                                        >
                                          Send customer notification?
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                )}
                              {!this.isGuestOnly() && (
                                <div className="float-right">
                                  <table className="pricing-table">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Booking Price:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${origPrice}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Paid amount:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${paidAmount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Discount:</strong>
                                        </td>
                                        <td className="float-right">
                                          ${couponDiscount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Refund:</strong>
                                        </td>
                                        <td className="float-right">
                                          <span
                                            className={
                                              lessAmount > 0
                                                ? "text-danger"
                                                : ""
                                            }
                                          >
                                            ${lessAmount}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>TOTAL CHARGE:</strong>
                                        </td>
                                        <td className="float-right">
                                          {(() => {
                                            if (totalPrice > 0) {
                                              return (
                                                <span className="text-success">
                                                  {" "}
                                                  ${totalPrice}
                                                </span>
                                              );
                                            } else if (totalPrice < 0) {
                                              return (
                                                <span className="text-danger">
                                                  -${Math.abs(totalPrice)}
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span> ${totalPrice}</span>
                                              );
                                            }
                                          })()}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </MDBTabContent>
                        </MDBCol>
                      </MDBRow>
                    </MDBTabPane>
                    {this.validatePriceChangeWarning()}
                    {this.renderMaxDurationExceedAcknowledge()}
                  </MDBTabContent>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.toggleBookingDetailsModal(false)}
                >
                  Close
                </MDBBtn>
                {this.state.isBookingCancellable &&
                  this.state.selectedBooking.is_participant &&
                  this.state.isDefaultUser &&
                  !this.isReadOnly && (
                    <>
                      <MDBBtn
                        color="primary"
                        onClick={() => this.bookingParticipantOptout()}
                      >
                        Opt out of the Booking
                      </MDBBtn>
                    </>
                  )}
                {selectedBooking.is_cancelled !== 1 &&
                  this.state.isBookingCancellable &&
                  !this.state.selectedBooking.is_participant &&
                  this.state.isDefaultUser &&
                  !this.isReadOnly && (
                    <>
                      <MDBBtn
                        color="danger"
                        onClick={() => this.cancelBooking()}
                      >
                        Cancel Booking
                      </MDBBtn>
                    </>
                  )}
                {isDefaultUser &&
                  !this.isGuestOnly() &&
                  (this.state.chargeAmount > 0 ||
                    (this.state.unPaidAmount > 0 &&
                      this.state.selectedBooking.is_cancelled !== 1)) && (
                    <MDBBtn
                      color="success"
                      type="button"
                      onClick={() => this.doCharge(true)}
                    >
                      Pay
                    </MDBBtn>
                  )}
                {this.state.isView || this.isReadOnly
                  ? ""
                  : ((isDefaultUser && this.state.isBookingCancellable) ||
                      !isDefaultUser) &&
                    validateAccess(
                      storeUserAccess,
                      "AdminBooking",
                      "updateBooking"
                    ) && (
                      <MDBBtn
                        color="primary"
                        type="submit"
                        disabled={disableSaveButton}
                      >
                        Save
                      </MDBBtn>
                    )}
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      );
    }
    return <span></span>;
  }

  showAlert() {
    if (this.state.alert.text !== "") {
      return (
        <MDBAlert color={`${this.state.alert.type}`}>
          <strong>{this.state.alert.title}</strong>
          <br />
          <ul className="text-left">
            {this.state.alert.text.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </MDBAlert>
      );
    }
  }

  resetAlertMessage() {
    alert = {
      title: "",
      text: "",
      type: "",
    };
    this.setState({ alert });
  }

  toolbarItemRender() {
    return (
      <div className="informer">
        {this.maxBookingParticipants > 0 ? (
          <i>
            {" "}
            <small>
              (Max booking Participants is {this.maxBookingParticipants})
            </small>
          </i>
        ) : (
          ""
        )}
      </div>
    );
  }

  allowUpdatingParticipant(e) {
    // let allowUpdatingParticipants =
    //   (!this.state.selectedBooking.is_participant &&
    //     this.state.isDefaultUser) ||
    //   !this.state.isDefaultUser;

    return !this.props.isView && !this.state.isDefaultUser;
  }

  rowValidating(e) {
    let editableFields = ["first_name", "last_name", "email", "mobile"];
    editableFields.forEach((column, index) => {
      e.component.focus();
      if (e.data.email == this.state.email && !this.props.isView) {
        e.component.columnOption(column).formItem.cssClass =
          "dx-cell-focus-disabled dx-state-readonly disabled";
      } else {
        e.component.columnOption(column).formItem.cssClass = "";
      }
    });
    //e.component.focus();
    return e;
  }

  allowDeletingParticipant(e) {
    let allowUpdatingParticipants =
      (!this.state.selectedBooking.is_participant &&
        this.state.isDefaultUser) ||
      !this.state.isDefaultUser;

    return (
      e.row.data.email != this.state.email &&
      !this.props.isView &&
      ((allowUpdatingParticipants && this.state.isBookingCancellable) ||
        !this.state.isDefaultUser)
    );
  }

  allowRemovingFavouriteContact(e) {
    // check if  the user is default user and not primary user
    return (
      (typeof e.row.data.is_primary == "undefined" || !e.row.data.is_primary) &&
      this.state.isDefaultUser &&
      isNaN(e.row.data.id) &&
      this.state.userFavouritesEmail.includes(e.row.data.email.toLowerCase()) && // checking here if it is added as favourite contact
      !this.isGuestOnly()
    );
  }

  allowAddingFavouriteContact(e) {
    // check if  the user is default user and not primary user
    return (
      (typeof e.row.data.is_primary == "undefined" || !e.row.data.is_primary) &&
      this.state.isDefaultUser &&
      isNaN(e.row.data.id) &&
      !this.state.userFavouritesEmail.includes(
        e.row.data.email.toLowerCase()
      ) && // checking here if it is not added as favourite contact
      !this.isGuestOnly()
    );
  }

  participantUniqueValidation(data) {
    let result = _.find(this.state.participants, function (o) {
      return o.email == data.value && data.value != "" && o.id != data.data.id;
    });
    return result == undefined || result.length == 0;
  }

  addValidatedParticipant() {
    const { selectedCustomerOrParticipant, isLocationPerMembership } =
      this.state;
    let participantIndex = _.findIndex(this.state.participants, function (o) {
      return o.email == selectedCustomerOrParticipant.email;
    });

    let participant = {
      id: selectedCustomerOrParticipant.id,
      first_name: selectedCustomerOrParticipant.first_name,
      last_name: selectedCustomerOrParticipant.last_name,
      email: selectedCustomerOrParticipant.email,
      mobile: selectedCustomerOrParticipant.mobile,
      address: selectedCustomerOrParticipant.address,
      is_auto: true,
    };

    if (participantIndex < 0) {
      this.state.participants.push(participant);
    } else {
      this.state.participants[participantIndex] = participant;
    }

    if (this.isLocationPerMembership) {
      this.debouncedCalculatePrice();
    }
  }

  renderParticipantAutoComplete() {
    const { isDefaultUser } = this.state;
    return (
      <div className="autoComplete">
        <label htmlFor="participant-autocomplete">Search Participant</label>
        <Autocomplete
          autoComplete="off"
          inputProps={{ id: "participant-autocomplete" }}
          wrapperStyle={{ width: "100%", display: "inline-block" }}
          menuStyle={{ position: "absolute", background: "red", zIndex: "3" }}
          items={this.state.customerResultContainer}
          value={this.state.participantValueSearch}
          getItemValue={() => this.state.participantValueSearch} // Value of the search bar on select
          onSelect={async (participantValueSearch, item) => {
            this.hasParticipantChanges = true;

            let result = _.find(this.state.participants, function (o) {
              return o.email == item.email;
            });
            if (result != undefined) {
              alert = {
                title: "Please review the following error: ",
                text: [
                  "Participant's email already added. Please enter an unique email",
                ],
                type: "danger",
              };
              this.setState({ alert });
              document.querySelector(".modal.show").scrollTo(0, 0);
              return;
            }

            let respt = this.triggerOnActionParticipantValidation({
              isValid: true,
              data: item,
            });
            if (respt) {
              let stat = await respt.then((result) => {
                return result;
              });
              if (!stat) {
                return;
              }
            }

            this.setState({
              participantValueSearch,
              isMainCustomer: false,
              selectedCustomerOrParticipant: item,
            });
            this.validateSelectedParticipant(item);
          }}
          onChange={(event, participantValueSearch) => {
            clearTimeout(this.state.timer);
            let timer = setTimeout(() => {
              this.debouncedSearchCustomer(participantValueSearch);
            }, 500);
            this.setState({
              ...this.state,
              participantValueSearch,
              timer: timer,
            });
          }}
          renderMenu={(children) => <div className="menu">{children}</div>}
          renderItem={(item, isHighlighted) => {
            if (
              this.state.isDefaultUser &&
              this.state.isFavouriteListSearch &&
              this.state.userFavouritesEmail.includes(item.email.toLowerCase())
            ) {
              let favouriteCustomer =
                this.state.userFavourites[item.email.toLowerCase()]["display"];
              return (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.id}
                >
                  {favouriteCustomer}
                  {this.state.isDefaultUser && (
                    <MDBIcon className="green-text" icon="star" />
                  )}
                </div>
              );
            } else {
              return (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.id}
                >
                  {item.first_name + " " + item.last_name}

                  {!isDefaultUser ? ", " + item.email : ""}

                  {item.membership
                    ? !isDefaultUser
                      ? " - " +
                        (item.membership.number
                          ? item.membership.number
                          : "N/A") +
                        " - " +
                        item.membership.name +
                        " " +
                        item.membership.type.name
                      : " - " +
                        (item.membership.number
                          ? item.membership.number
                          : "N/A")
                    : ""}
                </div>
              );
            }
          }}
        />
      </div>
    );
  }
  renderGridCell(data) {
    if (!isNaN(data.data.id)) {
      return "";
    } else {
      return data.value;
    }
  }
  renderBookingParticipants() {
    const { participants, isDefaultUser } = this.state;

    let allowUpdatingParticipants =
      (!this.state.selectedBooking.is_participant &&
        this.state.isDefaultUser) ||
      !this.state.isDefaultUser;

    let columns = [
      {
        fieldName: "first_name",
        displayName: "First Name",
        isEditable: true,
        dataType: "",
        isRequired: true,
      },
      {
        fieldName: "last_name",
        displayName: "Last Name",
        isEditable: true,
        dataType: "",
        isRequired: true,
      },
      {
        fieldName: "mobile",
        displayName: "Mobile",
        isEditable: true,
        dataType: "text",
        isRequired: true,
        pattern: "^[0-9]{10,15}$",
        patternErrorMessage: "Please enter a valid contact number",
        renderGridCell: isDefaultUser ? this.renderGridCell : "",
      },
      {
        fieldName: "email",
        displayName: "Email",
        isEditable: true,
        dataType: "email",
        isRequired: true,
        validationCallback: this.participantUniqueValidation,
        validationCallbackMessage:
          "Participant's email already added. Please enter an unique email.",
        renderGridCell: isDefaultUser ? this.renderGridCell : "",
      },
    ];

    if (!this.state.isDefaultUser) {
      columns.push(
        {
          fieldName: "participant_reference",
          displayName: "Participant Reference",
          isEditable: false,
        },
        {
          fieldName: "membership_details",
          displayName: "Membership Details",
          isEditable: false,
        },
        {
          fieldName: "checked_in",
          displayName: "Checked In",
          isEditable: true,
          sourceName: "value",
          sourceId: "id",
          sources: [
            {
              id: 0,
              value: "No",
            },
            {
              id: 1,
              value: "Yes",
            },
          ],
        },
        {
          fieldName: "checked_in_time",
          displayName: "Checked In Timestamp",
          isEditable: false,
          dataType: "",
        }
      );
    }

    let rows = _.uniqWith(Object.values(participants), _.isEqual);
    let canAdd =
      (rows.length < this.maxBookingParticipants ||
        this.maxBookingParticipants == -1) &&
      !this.props.isView &&
      ((allowUpdatingParticipants && this.state.isBookingCancellable) ||
        !this.state.isDefaultUser);

    return (
      <CustomDataGrid
        key={new Date().getTime()}
        source={rows}
        columns={columns}
        rowValidating={this.rowValidating}
        allowDeleting={this.allowDeletingParticipant}
        allowUpdating={this.allowUpdatingParticipant}
        allowCheckin={!this.state.isDefaultUser}
        canAdd={canAdd}
        key="id"
        actions={[
          "edit",
          "delete",
          "addfavourite",
          "removefavourite",
          "checkin",
        ]}
        editMode={"form"}
        triggerUpdatedEvent={this.triggerEvent}
        triggerInsertedEvent={this.triggerInsertEvent}
        triggerDeletedEvent={this.triggerDeletedEvent}
        toolbarItemRender={this.toolbarItemRender}
        triggerOnDeleteWaring={this.triggerOnDeleteWaring}
        triggerCheckIn={this.onClickCheckInPin}
        confirmDeleteMessage={""}
        allowAddingFavourite={this.allowAddingFavouriteContact}
        allowRemovingFavourite={this.allowRemovingFavouriteContact}
        triggerAddFavourite={this.onAddFavouriteContact}
        triggerRemoveFavourite={this.onRemoveFavouriteContact}
      />
    );
  }

  onClickCheckInPin(e) {
    let rowParticipant = e.row.data;
    let rowIndex = e.row.dataIndex;

    var result = DevExtremeConfirm(
      "Are you sure you want to check in " +
        rowParticipant["first_name"] +
        " " +
        rowParticipant["last_name"] +
        " ?"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        rowParticipant["checked_in"] = 1;
        rowParticipant["checked_in_time"] = moment()
          .local(this.getTzDetails().timezone)
          .format("YYYY-MM-DD HH:mm:ss");

        let { participants } = this.state;
        participants[rowIndex] = rowParticipant;
        this.setState({ participants: participants });
      }
    });
  }
  onRemoveFavouriteContact(e) {
    let rowParticipant = e.row.data;

    var result = DevExtremeConfirm(
      "Are you sure you want to remove " +
        rowParticipant["first_name"] +
        " " +
        rowParticipant["last_name"] +
        " as favourite contact?"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        let email = rowParticipant["email"];
        let favouriteId = this.state.userFavourites[email]["data"]["id"];
        this.props.deleteFavouriteCustomer(
          favouriteId,
          this.props.authUser.sessionToken
        );
      }
    });
  }

  onAddFavouriteContact(e) {
    let rowParticipant = e.row.data;
    let source = {
      first_name: rowParticipant["first_name"],
      last_name: rowParticipant["last_name"],
      email: rowParticipant["email"],
      mobile_no: rowParticipant["mobile"],
    };
    this.showAddCustomerAsFavouriteModal(source);
  }

  triggerOnDeleteWaring(e) {
    let participant = _.find(
      this.state.originalPariticipants,
      (participant) => {
        return participant.customer.email == e.data.email;
      }
    );

    if (!this.state.isBookingCancellable && participant) {
      var result = DevExtremeConfirm(
        "<p><b>24hrs Cancellation/Change Policy</b> <br> Partipiant is part of the original booking<br>Are you sure you want to remove <b>" +
          e.data.first_name +
          " " +
          e.data.last_name +
          "</b> participant?</p>",
        "Booking Participant Warning!"
      );
    } else {
      var result = DevExtremeConfirm(
        "Are you sure you want to remove this participant?"
      );
    }

    e.cancel = new Promise((resolve, reject) => {
      result
        .done(function (dialogResult) {
          return dialogResult
            ? resolve()
            : reject("Removing Participant Action Cancelled");
        })
        .catch(() => false);
    });
  }

  triggerOnActionParticipantValidation(e) {
    if (!this.state.isBookingCancellable) {
      var result = DevExtremeConfirm(
        "<p><b>24hrs Cancellation/Change Policy</b> <br>Are you sure you want to add <b>" +
          e.data.first_name +
          " " +
          e.data.last_name +
          "</b> participant?</p>",
        "Booking Participant Warning!"
      );
      var that = this;
      e.cancel = new Promise((resolve, reject) => {
        result
          .then(function (dialogResult) {
            that.setState({
              refresh: true,
            });
            return dialogResult ? resolve(e.isValid) : false;
          })
          .catch(() => false);
      });
      if (e.isValid) {
        return e.cancel;
      }
    }
    if (e.isValid) {
      return false;
    }
  }

  triggerEvent = (e) => {
    this.hasParticipantChanges = true;
    let { participants } = this.state;

    let data = e.data;
    let key = data.__KEY__;
    if (typeof data.__KEY__ == "undefined") {
      key = data.id;
    }

    data.is_auto = true;

    if (data["checked_in"]) {
      e.data["checked_in_time"] = data["checked_in_time"] = moment()
        .local(this.getTzDetails().timezone)
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (data["checked_in"] == 0) {
      data["checked_in_time"] = "";
    }

    if (typeof data.is_primary != "undefined" && data.is_primary) {
      this.setState({
        ...this.state,
        ...data,
      });
    }

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });
    if (participantIndex < 0) {
      participants.push(data);
    } else {
      participants[participantIndex] = data;
    }

    if (!this.state.isDefaultUser) {
      let custObj = {
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phone: data.mobile,
      };
      this.props.validateCustomerDetails(
        custObj,
        this.props.authUser.sessionToken
      );
    }

    this.state.participants = participants;

    if (this.isLocationPerMembership) {
      this.debouncedCalculatePrice();
    }
    return false;
  };

  triggerDeletedEvent = (e) => {
    this.hasParticipantChanges = true;
    let { participants } = this.state;
    let data = e.key;
    let key = data.__KEY__;
    if (typeof data.__KEY__ == "undefined") {
      key = data.id;
    }

    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });

    participants.splice(participantIndex, 1);

    this.state.participants = participants;

    if (this.isLocationPerMembership) {
      this.debouncedCalculatePrice();
    }
    return false;
  };

  triggerInsertEvent = (e) => {
    this.hasParticipantChanges = true;
    let { participants } = this.state;
    let data = e.data;
    let key = data.__KEY__;
    data["id"] = key;
    delete data.__KEY__;

    if (data["checked_in"]) {
      data["checked_in_time"] = moment()
        .local(this.getTzDetails().timezone)
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (data["checked_in"] == 0) {
      data["checked_in_time"] = "";
    }
    let participantIndex = _.findIndex(participants, function (o) {
      return o.id == key;
    });
    if (participantIndex < 0) {
      participants.push(data);
      // do validate customer details
      if (!this.state.isDefaultUser) {
        let custObj = {
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phone: data.mobile,
        };
        this.props.validateCustomerDetails(
          custObj,
          this.props.authUser.sessionToken
        );
      }
    } else {
      participants[participantIndex] = data;
    }

    this.state.participants = participants;
    if (this.isLocationPerMembership) {
      this.debouncedCalculatePrice();
    }
    return false;
  };

  proceedButtonValidateParticipant(modal) {
    const { isMainCustomer, isDefaultUser } = this.state;

    if (isMainCustomer == true) {
      // This is for proceeding the default seleted logged in private customer
      this.props.pauseBackgroundRequests(true);

      if (!isDefaultUser) {
        this.addValidatedMainCustomer();
      }
    } else if (isMainCustomer == false) {
      // This is for proceeding adding the guest participant
      this.addValidatedParticipant();
      this.props.pauseBackgroundRequests(true);
    }

    modal["isOpen"] = false;
    this.props.toggleModal(modal);
  }

  closeButtonValidateParticipant(modal) {
    if (!this.state.showBookingModal) {
      this.props.pauseBackgroundRequests(false);
    }

    modal["isOpen"] = false;
    this.props.toggleModal(modal);
  }

  showValidateParticipantModal = (validationData) => {
    let warnings = validationData["blockingMessages"];

    const modal = {
      isOpen: true,
      content: (
        <div>
          <ul>
            {warnings.map((warning, index) => (
              <li
                key={index}
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: warning }}
              ></li>
            ))}
          </ul>
        </div>
      ),
      title: <span style={{ color: "red" }}>Booking Warning</span>,
      negativeButtonText: "Close",
      positiveButtonText: "Yes, please proceed.",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeButtonValidateParticipant.bind(this),
      positiveButtonAction: this.proceedButtonValidateParticipant.bind(this),
    };

    if (validationData["hasBlocker"] && validationData["action"] == "block") {
      modal["hasPositiveButtonAction"] = false;
      delete modal["positiveButtonAction"];
      delete modal["positiveButtonColor"];
      delete modal["positiveButtonText"];
    } else if (!validationData["hasBlocker"]) {
      this.addValidatedParticipant();
    }

    if (validationData["blockingMessages"].length > 0) {
      this.props.toggleModal(modal);
    }
  };

  checkBookingConflicts = (bookingParams = {}) => {
    const {
      first_name,
      last_name,
      email,
      mobile,
      address,
      invoice,
      paid,
      notes,
      selectedBooking,
      duration,
      origTime,
      time,
      isRecurring,
      frequencyInWeeks,
      paymentMethod,

      totalPrice,
      chargeAmount,
      unPaidAmount,
      paidAmount,
      lessAmount,

      couponDiscount,
      coupon,
      bookingUuid,
      bookingId,

      statusNotes,
      status,

      locationId,
      locationUuid,
      locationName,

      resourceId,
      resourceUuid,
      resourceName,
      locationResource,
      tempCharge,
      participants,
      paymentDate,
    } = this.state;
    this.props.showHideLoader(true);

    let isPaidPaymentDate =
      paid && paymentDate != ""
        ? moment(
            moment(paymentDate).format("YYYY-MM-DDTHH:mm") +
              ":00" +
              this.getTzDetails().offset
          )
            .utc()
            .format("YYYY-MM-DD HH:mm")
        : paymentDate;
    let endDate = this.state.endDate;
    let location = this.state.locationObj;
    let overwriteUpdateSchedule = false;
    let priceData = {
      amount: totalPrice,
      currency: "AUD",
      paymentDate: isPaidPaymentDate,
    };

    let updateSchedule = this.state.updateSchedule;
    if (origTime != time) {
      updateSchedule = true;
    }

    if (this.state.coupon.length > 0) {
      priceData["couponDiscount"] = couponDiscount;
      priceData["coupon"] = coupon;
    }

    if (chargeAmount > 0 && !tempCharge) {
      priceData["charge"] = chargeAmount;
    }

    if (bookingParams.refund === undefined && lessAmount > 0) {
      priceData["refund"] = lessAmount;
    } else if (bookingParams.refund !== undefined) {
      priceData["refund"] = bookingParams.refund;
    }

    if (unPaidAmount > 0) {
      priceData["unpaid"] = unPaidAmount;
    }

    if (endDate) {
      moment(endDate).utc().format("YYYY-MM-DD");
    }

    let forcePaid = bookingParams.forcePaid;

    let participantsData = [];

    _.map(participants, (participant) => {
      let pt = Object.assign({}, participant);

      if (
        pt !== undefined &&
        pt["checked_in"] !== undefined &&
        pt["checked_in"] &&
        pt["checked_in_time"] != ""
      ) {
        pt["checked_in_time"] =
          moment(pt["checked_in_time"]).utc().format("YYYY-MM-DDTHH:mm:ss") +
          "Z";
      }
      participantsData.push(pt);
    });

    let details = {
      resourceData: {
        organisationId: this.locationResource.organisation.id,
        bookingData: {
          uuid: bookingUuid,
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,

          isRecurring: isRecurring,
          frequencyInWeeks: isRecurring ? frequencyInWeeks : "",
          endDate: endDate,

          chunks: this.generateChunk(),
          isPaid: paid || forcePaid,
          invoice: invoice,
          paymentMethod: paymentMethod,
          notes: notes,
        },
      },
      priceData: priceData,
      customerData: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        address: address,
      },
      participantsData: participantsData,
    };

    this.props.showHideLoader(true);

    if (bookingParams.isUpdate || this.state.selectedBooking) {
      details.resourceData.bookingData["bookingId"] = bookingId;
      if (status.length > 0) {
        details.resourceData.bookingData["status"] = status;
      }
      details.resourceData.bookingData["statusNotes"] = statusNotes;

      details.resourceData["locationId"] = locationId;
      details.resourceData["resourceId"] = resourceId;
      details.resourceData["resourceName"] = resourceName;

      details.resourceData.bookingData["updateSchedule"] =
        updateSchedule === true ? updateSchedule : overwriteUpdateSchedule;

      details.resourceData.bookingData["generate_voucher"] =
        bookingParams.generateVoucher;
    } else {
      details.resourceData["locationId"] = location.id;
      details.resourceData["resourceId"] = selectedBooking.resourceId;
      details.resourceData["resourceName"] = selectedBooking.court;
    }

    console.error("Check conflict data");
    console.error(JSON.stringify(details));

    this.props.checkBookingConflicts(
      details,
      this.props.authUser.sessionToken,
      "booking_editor"
    );
  };

  updateSubmitHandler = (event) => {
    const { origPrice, totalPrice, lessAmount, paid } = this.state;
    event.preventDefault();
    this.setState({
      wasValidated: "was-validated",
    });

    if (
      event.target.checkValidity() &&
      (this.state.alert.text == "" || this.state.alert.clearAfter)
    ) {
      if (lessAmount > 0 && paid) {
        this.generateVoucher = true;
      }

      let bookingParams = {
        isUpdate: true,
        generateVoucher: this.generateVoucher,
      };

      this.setState({
        bookingParams: bookingParams,
      });

      this.checkBookingConflicts(bookingParams);
    } else {
      document.querySelector(".modal.show").scrollTo(0, 0);
    }
  };

  renderWeeklyCalendar = () => {
    const { showCalendarSelect } = this.state;
    return (
      <div>
        {showCalendarSelect ? (
          <WeeklyCalendarV2
            private={true}
            isChangeSchedule={true}
            showCalendarSelect={showCalendarSelect}
            locationUuid={this.state.selectedBooking.location.uuid}
            changeScheduleDate={this.state.selectedBooking.start_time}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  renderCustomerOutboundNotifications = () => {
    const { selectedBooking } = this.state;
    return (
      <div>
        <ReportEmailLog
          hideTitle={true}
          bookingId={selectedBooking.id}
          customerId={selectedBooking.customer.id}
        />
      </div>
    );
  };

  submitRecurringBooking = async (body) => {
    const dialog = DevExtremeCustomDialog({
      title: "Update Recurring Booking",
      messageHtml: `
        <div>
          <p>Please select type of recurring booking update.</p>
        </div>
      `,
      buttons: [
        {
          text: "This booking only",
          onClick: () => "current_booking",
        },
        {
          text: "This and future bookings",
          onClick: () => "current_and_future_bookings",
        },
        {
          text: "Cancel",
          onClick: () => "cancel",
        },
      ],
    });

    const result = await dialog.show();

    switch (result) {
      case "current_booking":
      case "current_and_future_bookings":
        break;
      case "cancel":
        this.closeModal();
        break;
      default:
        this.closeModal();
    }

    if (
      result == "current_booking" ||
      result == "current_and_future_bookings"
    ) {
      this.props.showHideLoader(true);
      let response = await updateRecurring(body, { update_type: result });

      if (response) {
        this.props.showHideLoader(false);
        switch (response.status) {
          case 200:
          case 201:
            this.props.showNotification("success", response.data.message);
            break;
          default:
            this.props.showNotification("error", response.data.message);
            break;
        }
        this.closeModal();
      } else {
        this.props.showHideLoader(false);
        this.closeModal();
        this.props.showNotification(
          "error",
          "Error encountered. Please contact administrator."
        );
      }
    }
  };

  doBooking() {
    const {
      first_name,
      last_name,
      email,
      mobile,
      address,
      invoice,
      paid,
      notes,
      selectedBooking,
      origTime,
      time,
      isRecurring,
      frequencyInWeeks,
      paymentMethod,

      totalPrice,
      chargeAmount,
      unPaidAmount,
      lessAmount,

      couponDiscount,
      coupon,
      bookingUuid,
      bookingId,

      statusNotes,
      status,

      locationId,

      resourceId,
      resourceName,
      tempCharge,
      participants,
      paymentDate,
      bookingParams,
      sendNotification,
      overwriteFee,
      overwriteFeeAmount,
    } = this.state;
    let isPaidPaymentDate =
      paid && paymentDate != ""
        ? moment(
            moment(paymentDate).format("YYYY-MM-DDTHH:mm") +
              ":00" +
              this.getTzDetails().offset
          )
            .utc()
            .format("YYYY-MM-DD HH:mm")
        : paymentDate;
    let endDate = this.state.endDate;
    let location = this.state.locationObj;
    let overwriteUpdateSchedule = false;
    let priceData = {
      amount: totalPrice,
      currency: "AUD",
      paymentDate: isPaidPaymentDate,
    };

    let updateSchedule = this.state.updateSchedule;
    if (origTime != time) {
      updateSchedule = true;
    }

    if (this.state.coupon.length > 0) {
      priceData["couponDiscount"] = couponDiscount;
      priceData["coupon"] = coupon;
    }

    if (chargeAmount > 0 && !tempCharge) {
      priceData["charge"] = chargeAmount;
    }

    if (typeof bookingParams.refund === "undefined" && lessAmount > 0) {
      priceData["refund"] = lessAmount;
    } else if (typeof bookingParams.refund !== "undefined") {
      priceData["refund"] = bookingParams.refund;
    }

    if (unPaidAmount > 0) {
      priceData["unpaid"] = unPaidAmount;
    }

    if (overwriteFee && overwriteFeeAmount.length > 0) {
      priceData["overwriteFee"] = overwriteFee;
      priceData["overwriteFeeAmount"] = parseFloat(overwriteFeeAmount);
    }

    if (endDate) {
      moment(endDate).utc().format("YYYY-MM-DD");
    }

    let forcePaid = bookingParams.forcePaid;

    let participantsData = [];
    _.map(participants, (participant) => {
      let pt = Object.assign({}, participant);

      if (
        pt !== undefined &&
        pt["checked_in"] !== undefined &&
        pt["checked_in"] &&
        pt["checked_in_time"] != ""
      ) {
        pt["checked_in_time"] =
          moment(pt["checked_in_time"]).utc().format("YYYY-MM-DDTHH:mm:ss") +
          "Z";
      }
      participantsData.push(pt);
    });

    let details = {
      resourceData: {
        organisationId: this.locationResource.organisation.id,
        bookingData: {
          from: this.validateSelectedDuration().from,
          to: this.validateSelectedDuration().to,

          isRecurring: isRecurring,
          frequencyInWeeks: isRecurring ? frequencyInWeeks : "",
          endDate: endDate,

          chunks: this.generateChunk(),
          isPaid: paid || forcePaid,
          invoice: invoice,
          paymentMethod: paymentMethod,
          notes: notes,
          sendNotification: sendNotification,
        },
      },
      priceData: priceData,
      customerData: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        address: address,
      },
      participantsData: participantsData,
    };

    this.props.showHideLoader(true);

    if (bookingParams.isUpdate || this.state.selectedBooking) {
      // if (forcePaid !== undefined && forcePaid) {
      //   details.resourceData.bookingData["forcePaid"] = forcePaid;
      //   priceData["refund"] = 0;
      //   priceData["charge"] = 0;
      // }

      details.resourceData.bookingData["bookingId"] = bookingId;
      if (status.length > 0) {
        details.resourceData.bookingData["status"] = status;
      }
      details.resourceData.bookingData["statusNotes"] = statusNotes;

      details.resourceData["locationId"] = locationId;
      details.resourceData["resourceId"] = resourceId;
      details.resourceData["resourceName"] = resourceName;

      details.resourceData.bookingData["updateSchedule"] =
        updateSchedule === true ? updateSchedule : overwriteUpdateSchedule;

      details.resourceData.bookingData["generate_voucher"] =
        bookingParams.generateVoucher;

      if (
        totalPrice > 0 &&
        bookingParams.doCharge !== undefined &&
        bookingParams.doCharge
      ) {
        // Send payment request
        details.resourceData.bookingData["isPaid"] = false;
        let sessionToken = "";
        if (typeof this.props.authUser.sessionToken != "undefined") {
          sessionToken = this.props.authUser.sessionToken;
        }
        this.props.requestPayment(details, sessionToken);
      } else {
        if (
          couponDiscount > 0 &&
          (couponDiscount === chargeAmount || couponDiscount === unPaidAmount)
        ) {
          details.resourceData.bookingData["isPaid"] = true;
        }

        // If recurring send the request to the UBS v2 API
        let isAdmin = !this.state.isDefaultUser;

        if (
          (this.hasParticipantChanges || updateSchedule) &&
          isRecurring &&
          isAdmin
        ) {
          //for admin only
          this.props.showHideLoader(false);
          this.submitRecurringBooking(details);
        } else {
          this.props.updateBooking(
            details,
            this.props.authUser.sessionToken,
            bookingUuid
          );
        }
      }
    } else {
      details.resourceData["locationId"] = location.id;
      details.resourceData["resourceId"] = selectedBooking.resourceId;
      details.resourceData["resourceName"] = selectedBooking.court;

      this.props.doBooking(details, this.props.authUser.sessionToken);
    }

    this.setState({
      isUpdate: bookingParams.isUpdate,
      disableSaveButton: true,
    });
  }

  validatePriceChangeWarning() {
    const {
      origPrice,
      totalPrice,
      showPriceChangeWarning,
      couponDiscount,
      isPaid,
      unPaidAmount,
      paidAmount,
      chargeAmount,
      tempCharge,
      lessAmount,
    } = this.state;
    const { storeUserAccess } = this.props;

    let isPriceChanged =
      showPriceChangeWarning &&
      (unPaidAmount > 0 || chargeAmount > 0) &&
      totalPrice > 0;

    let isDefaultUser =
      validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
      validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails");
    if (
      !this.state.isView &&
      !isDefaultUser &&
      validateAccess(storeUserAccess, "BookingDetails", "sendPaymentRequest") &&
      (isPriceChanged || (!isPaid && !this.props.isView && totalPrice > 0))
    ) {
      let amount = totalPrice;

      return (
        <MDBRow>
          <MDBCol size="12" className="mt-2 text-info price-change-msg">
            *** {!tempCharge ? "Price changed. " : ""}Do you wish to send a{" "}
            <strong>payment request</strong>{" "}
            {isDefaultUser ? "on your" : "to the customer's"} email with the
            amount of <strong style={{ fontSize: "large" }}>${amount}?</strong>
            <MDBBtn
              color="primary"
              type="button"
              size="sm"
              onClick={() => this.doCharge(true)}
            >
              Send payment request
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    } else if (
      validateAccess(storeUserAccess, "BookingDetails", "issueVoucher") &&
      showPriceChangeWarning &&
      lessAmount > 0
    ) {
      return (
        <MDBRow>
          <MDBCol size="12" className="mt-2 text-info price-change-msg">
            *** Price changed. Do you wish to issue a{" "}
            <strong>credit voucher</strong> to the customer with the amout of{" "}
            <strong style={{ fontSize: "large" }}>${lessAmount}</strong>? An
            email will be sent to the customer containing the voucher code ***
            <MDBBtn
              color="primary"
              type="button"
              size="sm"
              onClick={() => this.doIssueVoucher(true)}
            >
              Issue Credit Voucher
            </MDBBtn>
            <MDBBtn
              color="primary"
              type="button"
              size="sm"
              onClick={() => this.doIssueVoucher(false)}
            >
              Dont issue Credit Voucher
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    }

    return <span></span>;
  }

  renderMaxDurationExceedAcknowledge() {
    const { showExceedMaxDurationAcknowledge, maxDuration } = this.state;

    if (showExceedMaxDurationAcknowledge) {
      return (
        <MDBRow>
          <MDBCol>
            <div
              className="custom-control custom-checkbox mt-2"
              style={{ textAlign: "center" }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="checkMoreThanMaxDuration"
                defaultChecked={true}
                readOnly={this.state.isView}
                disabled={this.state.isView}
                // onChange={this.checkMoreThanMaxDurationOnChange}
                required
              />
              <label
                className="custom-control-label"
                htmlFor="checkMoreThanMaxDuration"
              >
                I acknowledge that the customer will book more than the maximum
                duration{" "}
                <strong>
                  {maxDuration + " Minutes or " + maxDuration / 60 + " Hour/s"}
                </strong>
              </label>
            </div>
          </MDBCol>
        </MDBRow>
      );
    }
    return <span></span>;
  }

  renderStatusHistory() {
    const { bookingStatusHistoryElem } = this.state;
    if (bookingStatusHistoryElem.length > 0) {
      return <div>{bookingStatusHistoryElem}</div>;
    }
    return <span></span>;
  }

  renderStatusChangeConfirmation() {
    if (this.state.statusChangeConfirmation.length > 0) {
      return (
        <MDBCard border="info" className="m-3">
          <MDBCardBody className="text-info">
            <MDBCardTitle tag="h5">Confirm change status</MDBCardTitle>
            <MDBCardText>
              Status change to <strong>{this.state.status}</strong>.
              <br />
              <br />
              {this.state.statusChangeConfirmation}
            </MDBCardText>
            {this.isBookingStatusChangeSuccess()}
            {this.isBookingStatusChangeFailed()}
            {this.validateStatusChangeButton()}
          </MDBCardBody>
        </MDBCard>
      );
    }
    return <span></span>;
  }

  isBookingStatusChangeSuccess() {
    if (this.props.genCustomerVoucherSuccessResponse.status === 200) {
      return (
        <MDBAlert color="success" className="text-center">
          Voucher has been generated and issued to customer and the booking is
          cancelled.
        </MDBAlert>
      );
    }

    return <span></span>;
  }

  isBookingStatusChangeFailed() {
    if (
      (this.props.genCustomerVoucherFailedResponse !== null &&
        this.props.genCustomerVoucherFailedResponse.status === 400) ||
      this.props.genCustomerVoucherFailedResponse === "Network error"
    ) {
      this.props.showHideLoader(false);
      return (
        <MDBAlert color="danger" className="text-center">
          Unable to issue voucher. Please try again later or contact system
          administrator.
        </MDBAlert>
      );
    }
    return <span></span>;
  }

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  paymentDateChangeHandler = (date) => {
    this.setState({
      paymentDate: date,
    });
  };

  changeHandler = (event) => {
    let { participants } = this.state;

    if (
      event.target.dataset.type != undefined &&
      event.target.dataset.type == "customer"
    ) {
      let participantIndex = _.findIndex(participants, function (o) {
        return o.id == -1;
      });
      let participant = {};
      if (participantIndex < 0) {
        participant = {
          id: -1,
          is_primary: true,
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
        };
      } else {
        participant = participants[participantIndex];
      }

      participant[event.target.name] = event.target.value;

      if (participantIndex < 0) {
        participants.push(participant);
      } else {
        participants[participantIndex] = participant;
      }

      this.state.participants = participants;
    }
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  isInvalidCoupon() {
    if (this.state.couponErrorMsg.length > 0) {
      return (
        <span style={{ color: "red", fontWeight: "bold" }}>
          {this.state.couponErrorMsg}
        </span>
      );
    }
    return <span></span>;
  }

  onCouponChange = (evt) => {
    this.setState({
      coupon: evt.target.value,
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,
    });
  };

  buildStatusHistory(getBookingStatusSuccessResponse) {
    let bookingStatusHistoryElem = [];
    bookingStatusHistoryElem.push(
      <MDBTable responsive scrollY key="sttable1">
        <MDBTableHead>
          <tr>
            <th>Status</th>
            <th>Date</th>
            <th>Remarks</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {getBookingStatusSuccessResponse.data.history.map(
            (history, index) => (
              <tr key={index}>
                <td key={index + "1"}>{history.status}</td>
                <td key={index + "7"}>
                  {moment(
                    _.split(history.submittedDate, " ")[0] +
                      "T" +
                      _.split(history.submittedDate, " ")[1] +
                      "Z",
                    "YYYY-MM-DDTHH:mm:ssZ"
                  )
                    .tz(this.getTzDetails().timezone)
                    .format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td key={index + "9"}>{history.comments}</td>
              </tr>
            )
          )}
        </MDBTableBody>
      </MDBTable>
    );

    return bookingStatusHistoryElem;
  }

  buildResourceDataObj() {
    const { updateSchedule } = this.state;

    let location = this.state.locationObj;
    let locationId = "";
    let resourceName = "";
    let resourceId = "";

    if (this.state.showBookingDetailsModal) {
      locationId = this.state.selectedBooking.location.id;
      resourceName = this.state.selectedBooking.resource.name;
      resourceId = this.state.selectedBooking.resource.id;
    } else {
      if (location == undefined) {
        this.props.showHideLoader(false);
        return false;
      }
      locationId = location.id;
      resourceId = this.state.selectedBooking.resourceId;
      resourceName = this.state.selectedBooking.court;
    }

    let resourceData = {
      organisationId: this.locationResource.organisation.id,
      locationId: locationId,
      resourceId: resourceId,
      resourceName: resourceName,
      bookingData: {
        updateSchedule: updateSchedule,
        from: this.validateSelectedDuration().from,
        to: this.validateSelectedDuration().to,
      },
    };
    return resourceData;
  }

  calculatePrice() {
    const {
      updateSchedule,
      first_name,
      last_name,
      email,
      locationResource,
      chargeAmount,
      unPaidAmount,
      paidAmount,
      participants,
      overwriteFee,
      overwriteFeeAmount,
    } = this.state;
    this.props.showHideLoader(true);
    let couponObj = {};
    if (this.state.coupon.length > 0) {
      couponObj["coupon"] = this.state.coupon;
    }

    if (chargeAmount > 0) {
      couponObj["charge"] = chargeAmount;
    }

    if (unPaidAmount > 0) {
      couponObj["unpaid"] = unPaidAmount;
    }
    // console.log("overwriteFee>>>" + overwriteFee);
    // console.log("overwriteFeeAmount>>>>" + overwriteFeeAmount);
    if (overwriteFee && overwriteFeeAmount.length > 0) {
      couponObj["overwriteFee"] = overwriteFee;
      couponObj["overwriteFeeAmount"] = parseFloat(overwriteFeeAmount);
    }

    let participantsData = [];
    _.map(participants, (participant) => {
      let pt = Object.assign({}, participant);
      if (
        pt !== undefined &&
        pt["checked_in"] !== undefined &&
        pt["checked_in"] &&
        pt["checked_in_time"] != ""
      ) {
        pt["checked_in_time"] =
          moment(pt["checked_in_time"]).utc().format("YYYY-MM-DDTHH:mm:ss") +
          "Z";
      }
      participantsData.push(pt);
    });

    let resourceData = this.buildResourceDataObj();
    if (resourceData == false) {
      return;
    }

    let details = {
      resourceData,

      priceData: couponObj,
      customerData: {
        firstName: first_name,
        lastName: last_name,
        email: email,
      },
      participantsData: participantsData,
    };
    this.setState({
      locked: details,
      disableSaveButton: true,
    });

    let bookingId = "";

    let selectedBooking = this.state.selectedBooking;
    if (this.state.showBookingDetailsModal) {
      bookingId = selectedBooking.id;
    }

    if (bookingId > 0) {
      details.resourceData.bookingData["bookingId"] = bookingId;
    }
    if (websocketApi.isOpen) {
      websocketApi.send(JSON.stringify({ ...details, action: "lock" }));
    }

    if (
      selectedBooking?.transaction?.ispaid == 1 &&
      selectedBooking?.transaction?.totalAmount &&
      this.hasParticipantChanges == false &&
      updateSchedule == false
    ) {
      // If booking is already paid do no do calculation

      this.setState({
        origPrice: selectedBooking?.transaction?.totalAmount,
        paidAmount: selectedBooking?.transaction?.totalAmount,
        totalPrice: 0,
        disableSaveButton: false,
      });
    } else {
      // Do a calculation if the booking is not paid or there is a change in participant, schedule etc
      this.props.calculatePrice(details, this.props.authUser.sessionToken);
    }
  }

  validateSelectedParticipant(customerdetails) {
    const { isMainCustomer } = this.state;
    const resourceData = this.buildResourceDataObj();
    if (customerdetails) {
      this.props.showHideLoader(true);
      this.props.validateAddedCustomer(
        {
          resourceData: resourceData,
          participantData: customerdetails,
          isMainCustomer,
        },
        this.props.authUser.sessionToken
      );

      this.hasParticipantChanges = true;
    }
  }

  getTzDetails(dt = new Date()) {
    const { locationTimezone } = this.state;
    return extractTimezone(locationTimezone, dt);
  }

  validateSelectedDuration() {
    let selectedTime = this.state.time;

    let selectedDate =
      this.props.selectedNewDate !== "" &&
      this.props.selectedNewDate !== undefined &&
      this.state.updateSchedule === true
        ? this.props.selectedNewDate
        : this.state.selectedDate;

    let reservationDate = moment(selectedDate).format("YYYY-MM-DD");
    let from = "";
    let to = "";

    if (this.getTzDetails().operation === "+") {
      from =
        moment(new Date(reservationDate + "T" + _.split(selectedTime, "-")[0]))
          .subtract(
            this.getTzDetails(new Date(reservationDate)).offsetNum,
            "hours"
          )
          .format()
          .substring(0, 19) + "Z";

      to =
        moment(new Date(reservationDate + "T" + _.split(selectedTime, "-")[1]))
          .subtract(
            this.getTzDetails(new Date(reservationDate)).offsetNum,
            "hours"
          )
          .format()
          .substring(0, 19) + "Z";
    } else {
      from =
        moment(new Date(reservationDate + "T" + _.split(selectedTime, "-")[0]))
          .add(this.getTzDetails(new Date(reservationDate)).offsetNum, "hours")
          .format()
          .substring(0, 19) + "Z";

      to =
        moment(new Date(reservationDate + "T" + _.split(selectedTime, "-")[1]))
          .add(this.getTzDetails(new Date(reservationDate)).offsetNum, "hours")
          .format()
          .substring(0, 19) + "Z";
    }

    return { from: from, to: to };
  }

  getBookingWarnings(chunks) {
    const { searchDateVal, recurrings } = this.state;

    var resourceId = 0;
    if (this.state.showBookingDetailsModal) {
      resourceId = this.state.selectedBooking.resourceId;
    }
    var warnings = [];
    if (this.state.private) {
      chunks.forEach((value, index) => {
        var genStartTime = value.time;

        let currentDateTime = moment(searchDateVal + " " + genStartTime).format(
          "YYYY-MM-DD HH:mm"
        );

        let timezone = this.getTzDetails().timezone;
        let recurring = _.filter(recurrings, function (data) {
          let booking_from = moment(data.booking_from)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm");
          let booking_to = moment(data.booking_to)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm");
          return (
            data["resource_id"] == resourceId &&
            currentDateTime >= booking_from &&
            currentDateTime < booking_to
          );
        });

        if (recurring.length > 0) {
          warnings.push(
            "There is a recurring booking for the timeslot " + genStartTime
          );
        }
      });
    }

    return warnings;
  }

  renderBookingStatuses() {
    let statuses = _.chain(this.state.statuses)
      .orderBy(["name"], ["asc"])
      .value();

    return (
      <MDBCol md="6" className="mb-3">
        <div>
          {this.state.isView ? (
            <MDBInput
              label="Booking Status"
              value={this.state.selectedBooking.status}
              readOnly={true}
              disabled={true}
            />
          ) : (
            <select
              className="browser-default custom-select"
              name="status"
              onChange={this.statusChangeHandler}
              defaultValue={this.state.status}
            >
              {statuses.map((status) => (
                <option key={status.id} value={status.name}>
                  {status.name}
                </option>
              ))}
            </select>
          )}
          <div className="mt-3">
            <label className="grey-text">Description</label>
            <h5>{this.state.statusDesc}</h5>
          </div>
        </div>
      </MDBCol>
    );
  }

  statusChangeHandler = (evt) => {
    let status = evt.target.value;
    let statusAttr = _.chain(this.props.statuses)
      .map((stat) => {
        if (stat.name === status) {
          return stat;
        }
        return [];
      })
      .filter(_.size)
      .value()[0];

    let statusDesc = statusAttr.additional_notes;
    let statusChangeConfirmation = [];

    //-- TODO checking issue_voucher

    //-- Charge, issue voucher

    if (this.state.isBookingCancellable) {
      if (statusAttr.is_chargable === 1) {
        statusChangeConfirmation.push(
          <span key="st1">
            <strong>Charge:</strong> Customer will not be able to refund the
            booking fee and the booking will be cancelled.
            <br />
            <br />
          </span>
        );
      }
    }

    if (statusAttr.is_overrideble === 1 && this.state.origPrice > 0) {
      statusChangeConfirmation.push(
        <span key="st2">
          <strong>Issue Voucher:</strong> Credit Voucher with the amount of $
          {this.state.origPrice} will be issued to customer and the booking will
          be cancelled.
          <br />
          <br />
        </span>
      );
    }

    if (statusAttr.issue_voucher === 1 && this.state.origPrice > 0) {
      statusChangeConfirmation.push(
        <span key="st3">
          <strong>Issue Voucher:</strong> Credit Voucher with the amount of $
          {this.state.origPrice} will be issued to customer and the booking will
          be cancelled.
          <br />
          <br />
        </span>
      );
    }

    // if (status === "Checked in") {
    //   statusDesc = "The Visitor/User arrived and used the resource";
    //   statusChangeConfirmation = "";
    // } else if (status === "Unavoidable Circumstances") {
    //   statusDesc = "Visitor/User Retains the booking fee for use another time";
    //   statusChangeConfirmation = `Do you wish to issue a Credit Voucher with amount of $${this.state.totalPrice} to this customer?
    //   Customer will receive an email containing the credit voucher.`;
    // } else if (status === "No Show") {
    //   statusDesc = "Visitor/User looses the booking Fee";
    //   statusChangeConfirmation = `Customer will not be able to refund the booking fee and the booking will be cancelled.
    //   Do you wish to continue changing the status?`;
    // } else if (status === "Cancellation") {
    //   statusDesc = "Visitor/User Retains the booking fee for use another time";
    //   statusChangeConfirmation = `Do you wish to issue a Credit Voucher with amount of $${this.state.totalPrice} to this customer?
    //   Customer will receive an email containing the credit voucher.`;
    // } else if (status === "Late Cancellation") {
    //   statusDesc = "Visitor/User looses the booking Fee";
    //   statusChangeConfirmation = `Customer will not be able to refund the booking fee and the booking will be cancelled.
    //   Do you wish to continue changing the status?`;
    // }

    this.setState({
      status: status,
      statusDesc: statusDesc,
      statusChangeConfirmation: statusChangeConfirmation,

      isChargable: statusAttr.is_chargable,
      issueVoucher: statusAttr.issue_voucher,
      isOverrideble: statusAttr.is_overrideble,
    });
  };

  confirmBooking = (conflicts, canBook) => {
    let selectedTime = this.state.time;
    let day = moment(this.props.selectedNewDate).format("YYYY-MM-DD");
    let from = _.split(selectedTime, "-")[0];
    let to = _.split(selectedTime, "-")[1];

    const modal = {
      isOpen: true,
      content: (
        <div>
          <p>
            Are you sure you want to update book date {day} with timeslot from{" "}
            {from} to {to}?
          </p>
          {conflicts.length > 0 ? (
            <span style={{ color: "red" }}>
              Booking cannot be updated.
              {canBook && conflicts.length > 0
                ? " Please review the validation messages below:"
                : " Please review the conflicts below:"}
            </span>
          ) : (
            ""
          )}
          <ul>
            {conflicts.map((warning, index) => (
              <li
                key={index}
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: warning }}
              ></li>
            ))}
          </ul>
        </div>
      ),
      title: <span style={{ color: "red" }}>Booking Warnings</span>,
      negativeButtonText: "Cancel",
      positiveButtonText:
        canBook && conflicts.length > 0
          ? "Yes, override validation errors."
          : "Yes, please proceed.",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModalOnly.bind(this),
      positiveButtonAction: this.doBooking.bind(this),
    };

    if (!canBook) {
      modal["hasPositiveButtonAction"] = false;
      delete modal["positiveButtonAction"];
      delete modal["positiveButtonColor"];
      delete modal["positiveButtonText"];
    }
    this.props.toggleModal(modal);
  };

  generateChunk() {
    let selectedTime = this.state.time;
    let day = moment(this.props.selectedNewDate).format("dddd");
    let from = _.split(selectedTime, "-")[0];
    let to = _.split(selectedTime, "-")[1];

    let addTime = from;
    let chunks = [];
    chunks.push({
      day: day,
      time: from,
    });
    to = moment(to, "HH:mm").subtract(30, "minutes").format("HH:mm");

    while (addTime !== to) {
      addTime = moment(addTime, "HH:mm").add(30, "minutes").format("HH:mm");

      chunks.push({
        day: day,
        time: addTime,
      });
    }

    return chunks;
  }

  submitHandler = (event) => {
    event.preventDefault();
    // this.props.clearSuccessHandler();
    // this.props.clearFailedHandler();
    this.setState({
      wasValidated: "was-validated",
      isBookingSaving: true,
      // formCleared: false
    });

    if (event.target.checkValidity()) {
      this.checkBookingConflicts();
    }
  };

  debouncedCalculatePrice = _.debounce(function () {
    // console.log("test2 >>>>");

    this.calculatePrice();
  }, 100);

  resetBookingStates() {
    this.setState({
      participants: [],
      isParticipantLoaded: false,
      isBookingSaving: false,
      isConflictLoaded: false,
      customerId: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      address: "",
      invoice: "",
      paid: false,
      notes: "",
      selectedBooking: {},
      duration: "",
      origTime: "",
      time: "",
      paymentMethod: "Credit Card",
      isRecurring: false,
      frequencyInWeeks: "Weekly",
      totalPrice: 0,
      origPrice: 0,
      doneGettingOriginalPrice: false,
      showPriceChangeWarning: false,
      chargeAmount: 0,
      unPaidAmount: 0,
      lessAmount: 0,
      totalAmount: 0,
      unPaidAmount: 0,
      paidAmount: 0,
      wasValidated: "",
      isInitialPriceComputed: false,
      showBookingModal: false,

      isClientSideFilter: false,
      locationResourcesLoaded: false,

      coupon: "",
      couponErrorMsg: "",
      invalidCoupon: false,
      couponDiscount: 0,

      showBookingDetailsModal: false,
      selectedBookingDetails: {},

      durationDefaultVal: 30,

      discountAmount: 0,
      receiptUrl: "",
      vendor: "",

      bookingRef: "",
      bookingUuid: "",
      bookingId: "",

      locationId: "",
      locationUuid: "",
      locationName: "",

      resourceId: "",
      resourceUuid: "",
      resourceName: "",

      customerId: "",
      customerUuid: "",

      origStatus: "",
      status: "",
      statusDesc: "",
      statusChangeConfirmation: "",

      statusNotes: "",

      isUpdate: true,

      activeItemInnerPills: "1",

      isChargable: 0,
      issueVoucher: 0,
      isOverrideble: 0,

      paymentTransaction: {},

      paymentHistoryElem: [],
      bookingStatusHistoryElem: [],

      disableStatusButton: false,

      generateVoucher: false,

      disableSaveButton: false,
      showRecurringWarning: false,
      searchDateVal: this.state.origSearchDateVal,

      showCalendarSelect: false,
      updateSchedule: false,

      showExceedMaxDurationAcknowledge: false,
      maxDuration: 0,
      maxDurationInText: "",
      checkMoreThanMaxDuration: false,

      value: "",
      overwriteFee: false,
      overwriteFeeAmount: "",
    });

    this.generateVoucher = false;
    this.hasParticipantChanges = false;
  }

  resetBookingModal() {
    this.resetBookingStates();
    this.closeModal();
    this.props.selectDate(new Date(this.state.searchDateVal));
    if (this.state.private) {
      let params = queryString.parse(this.props.location.search);
      this.props.getLocationResourcesPrivate(
        params.uuid,
        { is_active: 1, display_online: 1, date: this.state.searchDateVal },
        this.props.authUser.sessionToken
      );
    }
  }

  getCustomerResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      let resultContainer = [];
      if (this.props.customerResponse.data !== undefined) {
        if (this.props.customerResponse.type === "GET_CUSTOMERS") {
          resultContainer = _.chain(this.props.customerResponse.data.results)
            .orderBy(["first_name"], ["asc"])
            .value();
        }
      }

      this.setState({
        customerResultContainer: resultContainer,
        isFavouriteListSearch: false,
      });
    }
  }

  closeWarningModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  validateCustomerDetailsResult(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      this.props.showHideLoader(false);
      if (this.props.customerResponse.data !== undefined) {
        let res = this.props.customerResponse;
        if (res.type === "VALIDATE_CUSTOMER_DETAILS") {
          if (!this.state.isDefaultUser) {
            if (res.status === 200) {
              if (res.data.message !== "Record found") {
                const modal = {
                  isOpen: true,
                  content: res.data.message,
                  title: "Participant's Warning",
                  negativeButtonText: "",
                  positiveButtonText: "Close",
                  positiveButtonColor: "primary",
                  modalSize: "sm",
                  positiveButtonAction: this.closeWarningModal.bind(this),
                };
                this.props.toggleModal(modal);
              }
            }
          }
        }
      }
    }
  }

  debouncedSearchCustomer = _.debounce(function (val) {
    let params = { limit: 10, query: val };
    const { isDefaultUser, locationObj } = this.state;
    if (isDefaultUser) {
      params["organisationId"] = this.locationResource.organisation.id;
    }
    if (val != "") {
      this.props.getCustomers(params, this.props.authUser.sessionToken);
    } else {
      this.props.getUserFavourites({}, this.props.authUser.sessionToken);
    }
  }, 10);

  toggleOuterTabs = (tab) => (e) => {
    const { activeItemOuterTabs2 } = this.state;
    if (activeItemOuterTabs2 !== tab) {
      this.setState({
        activeItemOuterTabs: tab,
      });
    }
  };

  toggleInnerPills = (tab) => (e) => {
    const { selectedBooking } = this.state;

    if (this.state.showCalendarSelect == true) {
      if (!this.state.updateSchedule) {
        // reset the selected date
        let selectedDate = moment(selectedBooking.start_time)
          .tz(this.getTzDetails().timezone)
          .format("YYYY-MM-DD");
        this.props.selectDate(new Date(selectedDate));
      }

      //Hide the calendar select change when switching tabs

      this.showCalendarSelect(false);
    }

    const { activeItemInnerPills } = this.state;
    if (activeItemInnerPills !== tab) {
      this.setState({
        activeItemInnerPills: tab,
      });
    }
  };

  bookingParticipantOptout() {
    const { bookingUuid, customerId } = this.state;
    this.props.showHideLoader(true);
    this.props.bookingParticipantOptout(
      bookingUuid,
      {
        organisationId: this.locationResource.organisation.id,
        customerId: customerId,
      },
      this.props.authUser.sessionToken
    );
  }

  cancelBooking() {
    this.props.showHideLoader(true);

    let bookingParams = {
      generateVoucher: this.state.paid,
    };

    this.setState({
      status: "Cancelled",
      bookingParams: bookingParams,
    });

    setTimeout(() => {
      this.checkBookingConflicts();
    }, 500);
  }

  getDefaultCustomer(isDefaultUser = undefined) {
    if (isDefaultUser !== undefined || this.state.isDefaultUser) {
      let params = queryString.parse(this.props.location.search);
      this.props.getCustomers(
        { limit: 1, customerLocationUuid: params.uuid },
        this.props.authUser.sessionToken
      );
    }
  }

  toggleBookingDetailsModal = (status) => {
    this.setState({
      showBookingDetailsModal: false,
      isLoaded: false,
      time: "",
    });
    this.props.closeBooking(true);
    this.getDefaultCustomer();
  };

  showCalendarSelect = (show) => {
    if (show) {
      this.props.pauseBackgroundRequests(false);
      this.props.disableParentCalendarTimer(true);
    } else {
      this.props.pauseBackgroundRequests(true);
    }
    this.setState({
      showCalendarSelect: show,
    });
  };

  validateShowExceedMaxDuration(duration, resourceUuid, isBookingDetails) {
    if (this.state.isView) {
      return;
    }
    const { locationResource, selectedBooking } = this.state;

    let maxDuration = 0;

    if (isBookingDetails) {
      maxDuration = new Number(
        _.replace(this.locationResource.attributes.max_duration, "m", "")
      ).valueOf();
    } else {
      maxDuration = new Number(
        _.replace(selectedBooking.maxDuration, "m", "")
      ).valueOf();
    }

    let showExceedMaxDurationAcknowledge = false;
    if (new Number(duration).valueOf() > maxDuration) {
      showExceedMaxDurationAcknowledge = true;
    }

    this.state.maxDuration = maxDuration;
    this.state.showExceedMaxDurationAcknowledge =
      showExceedMaxDurationAcknowledge;
  }

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
    this.props.closeBooking(true);
  }

  closeModalOnly() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }
  debouncedSetPrice = _.debounce(function (price) {
    const { isPaid, isChangeSchedule } = this.state;
    let chargeAmount = price.chargePrice;
    let tempCharge = false;
    if (chargeAmount == 0 && !isChangeSchedule && !isPaid) {
      // chargeAmount = price.unpaidAmount;
      tempCharge = true;
    }

    let showPriceChangeWarning =
      tempCharge || price.totalPrice > 0 || price.refundAmount > 0;

    let disableSaveButton = showPriceChangeWarning && price.refundAmount > 0;

    let pricingStates = {
      totalPrice: price.totalPrice,
      origPrice: price.actualBookingPrice,
      chargeAmount: chargeAmount,
      tempCharge: tempCharge,
      unPaidAmount: price.unpaidAmount,
      paidAmount: price.paidAmount,
      lessAmount: price.refundAmount,
      couponDiscount: price.couponDiscount,
      showPriceChangeWarning: showPriceChangeWarning,
      disableSaveButton: disableSaveButton,
    };

    if (price.totalPrice > 0 && isPaid) {
      pricingStates["paid"] = false;
    } else if (price.totalPrice <= 0 && isPaid) {
      pricingStates["paid"] = true;
    }

    this.setState(pricingStates);
  }, 10);

  couponValidation(prevProps, prevState) {
    // TO BE CONFIRM WHY When isView preventing the calculation?
    // if (this.state.isView) {
    //   return;

    // }
    if (
      this.state.showBookingDetailsModal &&
      (prevState.showBookingDetailsModal !==
        this.state.showBookingDetailsModal ||
        prevState.time !== this.state.time)
    ) {
      this.debouncedCalculatePrice();
    }

    if (
      prevState.doCalculate === this.state.doCalculate &&
      !this.state.doCalculate &&
      this.props.doCalculate !== undefined &&
      this.props.doCalculate &&
      !this.readOnly
    ) {
      this.debouncedCalculatePrice();
      this.setState({ doCalculate: true });
    }

    if (
      this.state.showBookingDetailsModal &&
      prevState.coupon !== this.state.coupon
    ) {
      this.debouncedCalculatePrice();
    }

    if (
      prevProps.successHandlerResponse !== this.props.successHandlerResponse
    ) {
      if (
        this.props.successHandlerResponse !== null &&
        this.props.successHandlerResponse.status === 200
      ) {
        this.props.showHideLoader(false);
        this.debouncedSetPrice(this.props.successHandlerResponse.data);
        this.setState({
          couponDiscount: this.props.successHandlerResponse.data.couponDiscount,
          disableSaveButton: false,
        });
      }
    }

    if (prevProps.failedHandlerResponse !== this.props.failedHandlerResponse) {
      if (
        this.props.failedHandlerResponse !== null &&
        this.props.failedHandlerResponse.status === 400
      ) {
        if (
          this.props.failedHandlerResponse.data.errorMessages !== undefined &&
          this.props.failedHandlerResponse.data.errorMessages.length > 0
        ) {
          this.props.showNotification(
            "error",
            this.props.failedHandlerResponse.data.errorMessages[0]
          );
        }
        this.props.showHideLoader(false);
        this.debouncedSetPrice(this.props.failedHandlerResponse.data);
        this.setState({
          couponDiscount: this.props.failedHandlerResponse.data.couponDiscount,
        });
      }
    }
  }

  validateStatusChangeButton() {
    const { isChargable, isOverrideble, issueVoucher, disableStatusButton } =
      this.state;
    return (
      <div>
        {isOverrideble === 1 && this.state.origPrice > 0 && (
          <MDBBtn
            color="info"
            className="float-right"
            disabled={disableStatusButton}
            type="submit"
            onClick={this.doStatusChange("issueVoucher")}
          >
            Issue Voucher
          </MDBBtn>
        )}
        {isChargable === 1 && this.state.isBookingCancellable && (
          <MDBBtn
            color="info"
            className="float-right"
            disabled={disableStatusButton}
            type="submit"
            onClick={this.doStatusChange("charge")}
          >
            Charge
          </MDBBtn>
        )}
        {issueVoucher === 1 && this.state.origPrice > 0 && (
          <MDBBtn
            color="info"
            className="float-right"
            disabled={disableStatusButton}
            type="submit"
            onClick={this.doStatusChange("issueVoucher")}
          >
            Issue Voucher
          </MDBBtn>
        )}
      </div>
    );
  }

  doStatusChange = (action) => (e) => {
    this.generateVoucher = false;
    if (action === "issueVoucher") {
      this.generateVoucher = true;
    }
    // let bookingParams = { isUpdate: true, generateVoucher: generateVoucher };
    // this.doBooking(bookingParams);

    // this.setState({
    //   disableStatusButton: true,
    //   disableSaveButton: true,
    // });
  };
  showAddCustomerAsFavouriteModal = (source) => {
    let attr = {
      show: true,
      action: "add",
      parent: this.state.componentName,
      payload: source,
    };
    this.props.genModalFavouriteCustomer(attr);
  };

  render() {
    const { browserSize } = this.props;
    return (
      <div>
        <FavouriteCustomerModal />

        {browserSize.lessThan.medium && this.renderBookingDetailsFormMobile()}
        {!browserSize.lessThan.medium && this.renderBookingDetailsForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    locationResources: state.locationResources,
    selectedNewDate: state.selectedDate,
    locations: state.locations,
    bookingDetails: state.bookingDetails,
    authUser: state.authUser,
    paymentRequestSuccess: state.paymentRequestSuccess,
    paymentRequestFailed: state.paymentRequestFailed,
    bookingSuccessResponse: state.bookingSuccessResponse,
    bookingFailedResponse: state.bookingFailedResponse,
    successHandlerResponse: state.successHandlerResponse,
    failedHandlerResponse: state.failedHandlerResponse,
    genCustomerVoucherSuccessResponse: state.genCustomerVoucherSuccessResponse,
    genCustomerVoucherFailedResponse: state.genCustomerVoucherFailedResponse,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getLocationListFailedResponse: state.getLocationListFailedResponse,
    customerResponse: state.customerResponse,
    bookingChangeSchedule: state.bookingChangeSchedule,
    getBookingStatusSuccessResponse: state.getBookingStatusSuccessResponse,
    browserSize: state.browserSize,
    paymentResponse: state.paymentResponse,
    bookingResponse: state.bookingResponse,
    storeUserAccess: state.storeUserAccess,
    bookingDetailsResponse: state.bookingDetailsResponse,
    userFavouriteResponse: state.userFavouriteResponse,
    addedCustomerParticipantResponse: state.addedCustomerParticipantResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleModal,
    getLocationResources,
    selectDate,
    searchLocations,
    setBookingDetails,
    showHideLoader,
    getLocationResourcesPrivate,
    requestPayment,
    doBooking,
    updateBooking,
    calculatePrice,
    getBookingStatus,
    getLocationList,
    showNotification,
    clearNotification,
    getCustomers,
    validateCustomerDetails,
    closeBooking,
    getBookingParticipants,
    checkBookingConflicts,
    bookingParticipantOptout,
    getBookingDetails,
    pauseBackgroundRequests,
    disableParentCalendarTimer,
    getUserFavourites,
    deleteFavouriteCustomer,
    genModalFavouriteCustomer,
    validateAddedCustomer,
  })(BookingEditor)
);
