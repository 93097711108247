import React from "react";
import { MDBContainer } from "mdbreact";
import ReactHtmlParser from "react-html-parser";

class RichTextRenderer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={`${this.props.doScrollY ? "do-scroll-y" : ""}`}>
        {this.props.insideContainer && (
          <MDBContainer>
            {ReactHtmlParser(`${this.props.htmlString}`)}
          </MDBContainer>
        )}
        {!this.props.insideContainer &&
          ReactHtmlParser(`${this.props.htmlString}`)}
      </div>
    );
  }
}

export default RichTextRenderer;
