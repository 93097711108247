import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  SET_USER_AUTH,
  USER_LOGOUT,
  VALIDATE_RESET_TOKEN,
  CHANGE_PASSWORD,
  HEART_BEAT_RESPONSE_HANDLER,
  REGISTER_RESPONSE_HANDLER,
  FORGOTPASS_RESPONSE_HANDLER,
} from "./types";

export const authLogin = (data) => async (dispatch) => {
  // var data = {};
  // if (domain.length > 0) {
  //   data = {
  //     username: username,
  //     password: password,
  //     domain: domain,
  //   };
  // } else {
  //   data = {
  //     username: username,
  //     password: password,
  //   };

  // }

  const headers = {
    "Content-Type": "application/json",
  };
  bookingAppPrivate
    .post(`/users/validateuser`, data, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: "AUTH_LOGIN", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "AUTH_LOGIN_FAILED", payload: "AUTH_LOGIN_FAILED" });
    });
};

export const inputUsername = (inputUsername) => {
  return {
    type: "INPUT_USERNAME",
    payload: inputUsername,
  };
};

export const inputPassword = (inputPassword) => {
  return {
    type: "INPUT_PASSWORD",
    payload: inputPassword,
  };
};

export const inputDomain = (inputDomain) => {
  return {
    type: "INPUT_DOMAIN",
    payload: inputDomain,
  };
};

export const clearAuthLoginFailedAlert = () => {
  return {
    type: "AUTH_LOGIN_FAILED",
    payload: "",
  };
};

export const setAuthUser = (auth) => {
  return {
    type: SET_USER_AUTH,
    payload: auth,
  };
};

export const logout = (sessionToken) => async (dispatch) => {
  const headers = {
    "x-session-token": sessionToken,
  };
  bookingAppPrivate
    .post(
      `/users/logout`,
      {},
      {
        headers: headers,
      }
    )
    .then((res) => {
      dispatch({ type: USER_LOGOUT, payload: res });
    })
    .catch((err) => {
      dispatch({ type: USER_LOGOUT, payload: err });
    });
};

export const validateResetToken = (sessionToken) => async (dispatch) => {
  bookingAppPrivate
    .post(`/users/resetuser/${sessionToken}`, {}, {})
    .then((res) => {
      dispatch({ type: VALIDATE_RESET_TOKEN, payload: res });
    })
    .catch((err) => {
      dispatch({ type: VALIDATE_RESET_TOKEN, payload: "INVALID_TOKEN" });
    });
};

export const changePassword = (sessionToken, password) => async (dispatch) => {
  var data = { password: password };
  bookingAppPrivate
    .post(`/users/resetuser/${sessionToken}/password`, data, {})
    .then((res) => {
      dispatch({ type: CHANGE_PASSWORD, payload: res });
    })
    .catch((err) => {
      dispatch({ type: CHANGE_PASSWORD, payload: "ERROR" });
    });
};

export const getHeartBeat = (sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/users/heartbeat`, {
      headers: headers,
      params: {},
    })
    .then((res) => {
      dispatch({ type: HEART_BEAT_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: HEART_BEAT_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const clearGetHeartBeatResponse = () => {
  return {
    type: HEART_BEAT_RESPONSE_HANDLER,
    payload: {},
  };
};

export const register = (data) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/users/register`, data, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: REGISTER_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: REGISTER_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};

export const forgotPass = (data) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/users/forgotpass`, data, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: FORGOTPASS_RESPONSE_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: FORGOTPASS_RESPONSE_HANDLER,
        payload: errRes,
      });
    });
};
