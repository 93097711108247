import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_ATTR_SUCCESS_HANDLER,
  GET_ATTR_FAILED_HANDLER,
  GET_ATTR_KEYS_SUCCESS_HANDLER,
  GET_ATTR_KEYS_FAILED_HANDLER,
  INSERT_ATTR_SUCCESS_HANDLER,
  INSERT_ATTR_FAILED_HANDLER,
  UPDATE_ATTR_SUCCESS_HANDLER,
  UPDATE_ATTR_FAILED_HANDLER,
  INSERT_ATTR_KEY_SUCCESS_HANDLER,
  INSERT_ATTR_KEY_FAILED_HANDLER,
  GET_ATTR_TYPES_SUCCESS_HANDLER,
  GET_ATTR_TYPES_FAILED_HANDLER,
  GET_ATTR_CHILD_SUCCESS_HANDLER,
  GET_ATTR_CHILD_RESPONSE_SUCCESS_HANDLER
} from "./types";

export const getAttributeKeys = (type, params, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/attributes/${type}/keys`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_ATTR_KEYS_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_ATTR_KEYS_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearGetAttrKeysSuccessResponse = () => {
  return {
    type: GET_ATTR_KEYS_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearGetAttrKeysFailedResponse = () => {
  return {
    type: GET_ATTR_KEYS_FAILED_HANDLER,
    payload: {},
  };
};

export const getAttributes = (type, params, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/attributes/${type}`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_ATTR_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_ATTR_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getAttributeChilds = (type, params, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/attributes/${type}`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_ATTR_CHILD_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_ATTR_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearGetAttributesSuccessResponse = () => {
  return {
    type: GET_ATTR_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearGetAttributesFailedResponse = () => {
  return {
    type: GET_ATTR_FAILED_HANDLER,
    payload: {},
  };
};

export const insertAttribute = (type, body, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/attributes/${type}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: INSERT_ATTR_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: INSERT_ATTR_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearInsertAttrSuccessResponse = () => {
  return {
    type: INSERT_ATTR_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearInsertAttrFailedResponse = () => {
  return {
    type: INSERT_ATTR_FAILED_HANDLER,
    payload: {},
  };
};

export const updateChildAttribute = (type, id, body, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/attributes/${type}/${id}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: GET_ATTR_CHILD_RESPONSE_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response.data.message;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_ATTR_CHILD_RESPONSE_SUCCESS_HANDLER,
        payload: {
          status: 400,
          data: {
            message: errRes
          }
        }
      });
    });
};

export const updateAttribute = (type, id, body, sessionToken) => async (
  dispatch
) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .put(`/attributes/${type}/${id}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: UPDATE_ATTR_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: UPDATE_ATTR_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearUpdateAttrSuccessResponse = () => {
  return {
    type: UPDATE_ATTR_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearUpdateAttrFailedResponse = () => {
  return {
    type: UPDATE_ATTR_FAILED_HANDLER,
    payload: {},
  };
};

export const insertAttrKey = (type, body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/attributes/${type}/keys`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: INSERT_ATTR_KEY_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: INSERT_ATTR_KEY_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearInsertAttrKeySuccessResponse = () => {
  return {
    type: INSERT_ATTR_KEY_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearInsertAttrKeyFailedResponse = () => {
  return {
    type: INSERT_ATTR_KEY_FAILED_HANDLER,
    payload: {},
  };
};

export const getAttrTypes = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/attributes/types`, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      dispatch({ type: GET_ATTR_TYPES_SUCCESS_HANDLER, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_ATTR_TYPES_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearGetAttrTypesSuccessResponse = () => {
  return {
    type: GET_ATTR_TYPES_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearGetAttrTypesFailedResponse = () => {
  return {
    type: GET_ATTR_TYPES_FAILED_HANDLER,
    payload: {},
  };
};
