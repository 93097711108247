import { BOOKING_SUCCESS_HANDLER } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case BOOKING_SUCCESS_HANDLER:
      action.payload.iscreate = action.isCreate
      return action.payload;
    default:
      return state;
  }
};
