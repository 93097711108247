import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
  MDBAlert,
} from "mdbreact";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { validateLayout, alertMessage } from "../../../actions";
import {
  authLogin,
  inputUsername,
  inputPassword,
  inputDomain,
  clearAuthLoginFailedAlert,
  setAuthUser,
  clearGetHeartBeatResponse,
} from "../../../actions/authAction";
import { withRouter } from "react-router-dom";
import PageTitle from "../shared/PageTitle";
import { lowerCase } from "lodash";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { USER_COOKIES_KEY, USER_TMP } from "../../../helper";
import Spinner from "../shared/Loader";
import { showHideLoader, showNotification } from "../../../actions";
import moment from "moment";
import queryString from "query-string";

class Login extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
  }

  setCookies(key, val, expires) {
    const { cookies } = this.props;
    cookies.set(key, val, { path: "/", expires });
  }

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: true,
      showSearch: false,
      showHeader: true,
      showLogin: false,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.login.status !== this.props.login.status) {
      this.authenticated();
    }

    if (
      this.props.authResponse.status === 401 ||
      this.props.authResponse.status == 403
    ) {
      this.props.showNotification(
        this.props.authResponse.notificationType,
        "You got redirected to login because your session has expired"
      );
      this.props.clearGetHeartBeatResponse();
    }
  }

  onUsernameChange = (evt) => {
    this.props.inputUsername(evt.target.value);
  };

  onPasswordChange = (evt) => {
    this.props.inputPassword(evt.target.value);
  };

  onDomainChange = (evt) => {
    this.props.inputDomain(evt.target.value);
  };

  resetAlertMessage() {
    alert = {
      title: "",
      text: "",
      type: "",
    };
    this.props.alertMessage(alert);
  }

  validateLogin() {
    this.props.showHideLoader(true);

    this.props.clearAuthLoginFailedAlert();
    this.resetAlertMessage();
    let errMgsArr = [];

    if (this.props.iUsername.length <= 0) {
      errMgsArr.push("Email is required");
    }

    if (this.props.iPassword.length <= 0) {
      errMgsArr.push("Password is required");
    }

    if (errMgsArr.length > 0) {
      alert = {
        title: "Please review the following error: ",
        text: errMgsArr,
        type: "danger",
      };
      this.props.alertMessage(alert);
      this.props.showHideLoader(false);
    } else {
      let params = queryString.parse(this.props.location.search);
      let data = {
        username: this.props.iUsername,
        password: this.props.iPassword,
      };
      if (params.uuid !== undefined) {
        data["subscription"] = params.uuid;
      }
      this.props.authLogin(data);
    }
  }

  showAlert() {
    if (this.props.alert.text !== "") {
      return (
        <MDBAlert color={`${this.props.alert.type}`}>
          <strong>{this.props.alert.title}</strong>
          <br />
          <ul className="text-left">
            {this.props.alert.text.map((text, index) => (
              <li key={index} className="margin-bottom-0">
                {text}
              </li>
            ))}
          </ul>
        </MDBAlert>
      );
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.validateLogin();
  };

  renderLoginForm() {
    let params = queryString.parse(this.props.location.search);
    return (
      <div>
        <form onSubmit={this.submitHandler}>
          <MDBCard className="card-body">
            {this.loginFailed()}
            {this.showAlert()}
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput label="Email" onChange={this.onUsernameChange} />
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    label="Password"
                    type="password"
                    onChange={this.onPasswordChange}
                  />
                </MDBCol>
                {/* <MDBCol md="12" className="mt-3">
                  <Link to="/forgotpass" className="float-right">
                    Forgot password?
                  </Link>
                </MDBCol> */}

                <MDBCol md="12" className="mt-5 padding-left-3">
                  <MDBBtn
                    color="default"
                    className="w-100 p-3"
                    type="submit"
                    // onClick={this.validateLogin.bind(this)}
                  >
                    Login
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="12" style={{ textAlign: "center" }}>
                  {"Don't have an account? "}
                  <Link
                    to={
                      "/register" +
                      (params.uuid !== undefined ? "?uuid=" + params.uuid : "")
                    }
                  >
                    Register
                  </Link>
                </MDBCol>
                <MDBCol md="12" style={{ textAlign: "center" }}>
                  {"Forgot password? "}
                  <Link to={"/forgotpass"}>Reset password</Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCard>
        </form>
      </div>
    );
  }

  authenticated() {
    if (this.props.login.status !== undefined) {
      this.props.showHideLoader(false);
      let auth = {
        username: this.props.iUsername,
        sessionToken: this.props.login.data.sessionToken,
        isLoggedIn: true,
        organisations: this.props.login.data.organisations,
        organisation: this.props.login.data.organisation,
        role: this.props.login.data.role,
      };

      if (typeof this.props.login.data.organisationId !== "undefined") {
        auth.organisationId = this.props.login.data.organisationId;
      }

      let userCookieExp = new Date(
        moment(new Date()).add(480, "minutes").utc().format()
      );
      this.setCookies(USER_COOKIES_KEY, JSON.stringify(auth), userCookieExp);

      if (
        lowerCase(this.props.login.data.message) ===
        lowerCase("Partially Authorized")
      ) {
        let tmpCookieExp = new Date(
          moment(new Date()).add(1, "minutes").utc().format()
        );
        this.setCookies(USER_TMP, this.props.iPassword, tmpCookieExp);
        window.location.replace("/admin/selectorg");
      } else {
        window.location.replace("/admin/dashboard");
      }
      return (
        <MDBAlert color="success" className="text-center">
          User authenticated!
        </MDBAlert>
      );
    }
  }

  loginFailed() {
    if (this.props.loginFailed === "AUTH_LOGIN_FAILED") {
      this.props.showHideLoader(false);
      return (
        <MDBAlert color="danger" className="text-center">
          Invalid email or password
        </MDBAlert>
      );
    }
  }

  renderContent() {
    let userCookieExp = new Date(
      moment(new Date()).add(2, "minutes").utc().format()
    );
    return (
      <div>
        <MDBContainer className="content-min-height mt-5 mb-5">
          <div className="mb-5">
            <PageTitle title="Login" subTitle="" />
          </div>
          <MDBRow className="mt-5 mb-5" className="flex-center">
            <MDBCol md="6">{this.renderLoginForm()}</MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    login: state.authLogin,
    iUsername: state.inputUsername,
    iPassword: state.inputPassword,
    iDomain: state.inputDomain,
    loginFailed: state.authLoginFailed,
    alert: state.alert,
    authUser: state.authUser,
    loader: state.loader,
    authResponse: state.authResponse,
  };
};

export default withCookies(
  withRouter(
    connect(mapStateToProps, {
      validateLayout,
      alertMessage,
      authLogin,
      inputUsername,
      inputPassword,
      inputDomain,
      clearAuthLoginFailedAlert,
      setAuthUser,
      showHideLoader,
      showNotification,
      clearGetHeartBeatResponse,
    })(Login)
  )
);
