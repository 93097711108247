import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import defaultLogo from "../assets/ubicon.png";
import {
  setWizardStep,
  setStripePaymentConfirmResponse,
} from "../../../actions";
import { MDBRow, MDBCol, MDBCardHeader, MDBCard, MDBCardBody } from "mdbreact";

const CheckoutPageFormWrapper = ({
  clientSecretKey,
  stripePaymentResponse,
  setWizardStep,
  setStripePaymentConfirmResponse,
  totalAmount,
  browserSize,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const headerTextColor = "#ffffff";
  const headerBackgroundColor = "#4D4D4D";
  const subHeaderTextColor = "#000000";
  const formLabelsColor = "#000000";
  const formBodyBackgroundColor = "#ffffff";
  const payButtonBackgroundColor = "#4D4D4D";
  const payButtonTextColor = "#ffffff";
  const subHeaderText = "Invoice";
  const headerText = "Unified Bookings";
  const imagePreview = defaultLogo;
  const isPaymentPage = true;

  const [isErrorStripePayment, setIsErrorStripePayment] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [stripeErrorMessageResponse, setStripeErrorMessageResponse] =
    useState("");

  const handleSubmit = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const stripeResult = stripe.confirmCardPayment(clientSecretKey, {
      payment_method: { card: elements.getElement(CardNumberElement) },
    });
    setIsDisabledButton(true);
    setWizardStep(1);

    stripeResult.then(function (result) {
      setTimeout(function () {
        setIsDisabledButton(false);
        if (result.error) {
          // Go back to first step
          setWizardStep(0);
          // Then set the stripe payment response for the error message
          setStripePaymentConfirmResponse(result);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            setIsErrorStripePayment(false);
            setWizardStep(2);
          }
        }
      }, 3000);
    });
  };

  useEffect(() => {
    if (stripePaymentResponse.error) {
      setIsErrorStripePayment(true);
      setStripeErrorMessageResponse(stripePaymentResponse.error.message);
    }
  }, [stripePaymentResponse]);

  return (
    <form onSubmit={handleSubmit}>
      <MDBRow className="mt-4">
        <MDBCol md="12">
          <MDBCard style={{ maxWidth: "550px", height: "650px" }}>
            <MDBCardHeader
              className="py-4"
              style={{ backgroundColor: headerBackgroundColor }}
            >
              <MDBRow className="row align-items-center">
                <MDBCol md="4">
                  <div className="row justify-content-center">
                    <img
                      style={{ height: "60px" }}
                      src={imagePreview}
                      className="img-fluid"
                      alt="organisationLogo"
                    />
                  </div>
                </MDBCol>
                <MDBCol
                  className={
                    browserSize.lessThan.medium
                      ? "text-center mt-2"
                      : "text-left"
                  }
                  md="8"
                  style={{ color: headerTextColor }}
                >
                  <h4>{headerText}</h4>
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBCardBody style={{ backgroundColor: formBodyBackgroundColor }}>
              <h5
                style={{ color: subHeaderTextColor }}
                className="text-center py-4"
              >
                {subHeaderText}
              </h5>
              {isErrorStripePayment && (
                <div className="text-danger text-center pb-4">
                  {stripeErrorMessageResponse}
                </div>
              )}

              <label
                style={{ color: formLabelsColor }}
                className="font-weight-bold"
                formLabelsColor
              >
                Credit Card Number
              </label>
              {isPaymentPage === true ? (
                <CardNumberElement />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  placeholder="#### #### #### ####"
                />
              )}

              <br />

              <MDBRow>
                <MDBCol md="6" className="mt-2">
                  <label
                    style={{ color: formLabelsColor }}
                    className="font-weight-bold"
                  >
                    Expiry Date
                  </label>
                  {isPaymentPage === true ? (
                    <CardExpiryElement />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="## / ##"
                    />
                  )}
                </MDBCol>
                <MDBCol md="6" className="mt-2">
                  <label
                    style={{ color: formLabelsColor }}
                    className="font-weight-bold"
                  >
                    CVC
                  </label>
                  {isPaymentPage === true ? (
                    <CardCvcElement />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="#### #### #### ####"
                    />
                  )}
                </MDBCol>
              </MDBRow>

              <div className="text-center py-4 mt-3">
                <button
                  className="btn"
                  type={isPaymentPage ? "submit" : "button"}
                  disabled={isDisabledButton}
                  style={{
                    backgroundColor: payButtonBackgroundColor,
                    color: payButtonTextColor,
                  }}
                >
                  PAY ${totalAmount}
                </button>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modal: state.modal,
    stripePaymentResponse: state.stripePaymentResponse,
    browserSize: state.browserSize,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setWizardStep,
    setStripePaymentConfirmResponse,
  })(CheckoutPageFormWrapper)
);
