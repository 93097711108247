import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MDBRow, MDBCol } from "mdbreact";
import { connect } from "react-redux";
import {
  displayBreadcrumbs,
  clearSuccessHandler,
  clearFailedHandler,
  alertMessage,
  showHideLoader,
} from "../../../actions";
import { createUser, getUsers } from "../../../actions/userAction";
import _ from "lodash";
import MyCalendar from "../shared/MyCalendar";

class CustomerBookingPrivate extends React.Component {
  state = {
    name: "",
    email: "",
    loginId: "",
    domain: "",
    formCleared: false,
    wasValidated: "",
    showModal: false,
    isModalTrigger: false,
  };

  componentDidMount() {
    this.props.displayBreadcrumbs("Dashboard / My Calendar");
    //this.props.getUsers({}, this.props.authUser.sessionToken);
  }

  renderContent() {
    return (
      <div>
        <MDBRow className="no-margin-left no-margin-right">
          <MDBCol size="12">
            <MyCalendar private={true} />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    authUser: state.authUser,
    successHandlerResponse: state.successHandlerResponse,
    failedHandlerResponse: state.failedHandlerResponse,
    authUser: state.authUser,
    alert: state.alert,
    users: state.users,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    createUser,
    clearSuccessHandler,
    clearFailedHandler,
    alertMessage,
    showHideLoader,
    getUsers,
  })(CustomerBookingPrivate)
);
