import {
  GET_BOOKING_REQUEST,
  CANCEL_BOOKING_REQUEST,
  LOCK_BOOKING_REQUEST,
  GET_BOOKING_REQUEST_RECORD_LOCK,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_BOOKING_REQUEST:
    case GET_BOOKING_REQUEST_RECORD_LOCK:
      return {
        action: action.type,
        status: action.payload.status,
        data: action.payload.data,
      };
    case CANCEL_BOOKING_REQUEST:
      return action.payload;
    case LOCK_BOOKING_REQUEST:
      return action.payload;
    default:
      return state;
  }
};
