import React from "react";
import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSelect,
  MDBSwitch,
} from "mdbreact";
import { connect } from "react-redux";
import Autocomplete from "react-autocomplete";

import { getMemberships } from "../../../actions/membershipAction";

import {
  showHideLoader,
  toggleModal,
  alertMessage,
  addModalAttr,
  showNotification,
  clearNotification,
} from "../../../actions";
import {
  insertCustomer,
  clearInsertCustomerSuccessResponse,
  clearInsertCustomerFailedResponse,
  updateCustomer,
  clearUpdateCustomerSuccessResponse,
  clearUpdateCustomerFailedResponse,
} from "../../../actions/customerAction";
import _ from "lodash";
import { genModalCustomer } from "../../../actions";

import { GEN_MODAL_CUSTOMER } from "../../../actions/types";
import { validateAccess } from "../../../helper/utils";

class CustomerModal extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: new Date(),
    endDateFilter: new Date(),
    limitOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    customerUuid: "",
    notification: {},
    query: "",
    showBookingHistoryModal: false,
    parent: "",
    canBook: true,
    isAccount: false,
    // membershipResultContainer: [],
    membershipNumber: "",
    isMembershipRenewal: false,
    isMembershipApproved: false,
    membershipCardNumber: "",
    membershipId: null,
    isDefaultUser: false,
    membershipOptions: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.showModal) {
      let customer = this.props.customer;
      this.setState({
        isEdit: true,
        firstName: customer.first_name,
        lastName: customer.last_name,
        email: customer.email,
        address: customer.address,
        mobile: customer.mobile,
        customerId: customer.id,
        customerUuid: customer.uuid,
        showModal: this.props.showModal,
      });
    }
  }

  render() {
    return <div>{this.renderForm()}</div>;
  }

  formDataHandler = (event) => {
    const { isDefaultUser } = this.state;
    let readOnlyFields = ["email"];
    if (isDefaultUser && readOnlyFields.includes(event.target.name)) {
      return;
    }
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const {
      firstName,
      lastName,
      email,
      address,
      mobile,
      isEdit,
      customerUuid,
      canBook,
      isAccount,
      isMembershipRenewal,
      membershipId,
      membershipCardNumber,
      membershipNumber,
      isMembershipApproved,
      isPublic,
    } = this.state;
    let body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      address: address,
      phone: mobile,
      customerUuid: customerUuid,
      canBook: canBook,
      isAccount: isAccount,
      isRenewal: isMembershipRenewal,
      isApproved: isMembershipApproved,
      membershipNumber: membershipNumber,
      membershipCardNumber: membershipCardNumber,
      membershipId: membershipId,
      isPublic: isPublic,
    };
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.showHideLoader(true);
      if (isEdit) {
        this.props.updateCustomer(
          body,
          customerUuid,
          this.props.authUser.sessionToken
        );
      } else {
        this.props.insertCustomer(body, this.props.authUser.sessionToken);
      }
    }
  };

  getValueOfSelectMembershipPlan = (value) => {
    this.setState({ membershipId: value.length > 0 ? value[0] : "" });
  };

  renderForm() {
    const {
      showModal,
      firstName,
      lastName,
      email,
      mobile,
      address,
      isEdit,
      canBook,
      isAccount,
      membershipNumber,
      membershipCardNumber,
      isMembershipApproved,
      isMembershipRenewal,
      membershipId,
      isDefaultUser,
      isPublic,
      membershipOptions,
    } = this.state;

    let isTennisMembership = false;
    const tennisMembershipList = [4, 7, 5, 14, 21, 1, 6, 8]; // list of membership plan ID that are Tennis

    if (isDefaultUser && tennisMembershipList.includes(membershipId)) {
      isTennisMembership = true;
    }

    return (
      <>
        <div className="child-popup">
          <MDBModal
            isOpen={showModal}
            size="lg"
            toggle={() => this.showModal(false, false, null)}
          >
            <form
              className={`needs-validation ${this.state.wasValidated}`}
              onSubmit={this.submitHandler}
              noValidate
            >
              <MDBModalHeader>
                {isEdit ? "Edit" : "Add"}{" "}
                {!isDefaultUser ? "Customer" : "Details"}
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol md="6" className="mb-3">
                    <label htmlFor="firstName" className="grey-text">
                      <span className="text-danger">*</span> First Name
                    </label>
                    <input
                      value={firstName}
                      onChange={this.formDataHandler}
                      name="firstName"
                      type="text"
                      id="firstName"
                      className="form-control"
                      placeholder="Enter Customer First Name..."
                      required
                      readOnly={isTennisMembership}
                    />
                    <div className="invalid-feedback">
                      Please provide the name
                    </div>
                  </MDBCol>
                  <MDBCol md="6" className="mb-3">
                    <label htmlFor="lastName" className="grey-text">
                      <span className="text-danger">*</span> Last Name
                    </label>
                    <input
                      value={lastName}
                      onChange={this.formDataHandler}
                      name="lastName"
                      type="text"
                      id="lastName"
                      className="form-control"
                      placeholder="Enter Customer Last Name..."
                      required
                      readOnly={isTennisMembership}
                    />
                    <div className="invalid-feedback">
                      Please provide the name
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6" className="mb-3">
                    <label htmlFor="email" className="grey-text">
                      <span className="text-danger">*</span> Email Address
                    </label>
                    <input
                      readOnly={isDefaultUser}
                      disabled={isDefaultUser}
                      value={email}
                      onChange={this.formDataHandler}
                      name="email"
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Enter Email Address..."
                      required
                      readOnly={isEdit && isDefaultUser}
                    />
                    <div className="invalid-feedback">
                      Please provide the name
                    </div>
                  </MDBCol>
                  <MDBCol md="6" className="mb-3">
                    <label htmlFor="mobile" className="grey-text">
                      <span className="text-danger">*</span> Mobile
                    </label>
                    <input
                      value={mobile}
                      onChange={this.formDataHandler}
                      name="mobile"
                      type="number"
                      id="mobile"
                      className="form-control"
                      placeholder="Enter Customer Mobile..."
                      required
                      readOnly={isTennisMembership}
                    />
                    <div className="invalid-feedback">
                      Please provide the name
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  {!isDefaultUser && (
                    <MDBCol md="3" className="mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkActive"
                          defaultChecked={canBook}
                          onChange={this.onIsActiveLocChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkActive"
                          style={{
                            fontWeight: "bold",
                            marginTop: "35px !important",
                          }}
                        >
                          Is Booking Allowed
                        </label>
                      </div>
                    </MDBCol>
                  )}

                  {!isDefaultUser && (
                    <MDBCol md="2" className="mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkAccountActive"
                          defaultChecked={isAccount}
                          onChange={this.onIsActiveAccountChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkAccountActive"
                          style={{
                            fontWeight: "bold",
                            marginTop: "35px !important",
                          }}
                        >
                          Is account
                        </label>
                      </div>
                    </MDBCol>
                  )}

                  {!isDefaultUser && (
                    <MDBCol md="7" className="mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkPublicDetailsBooking"
                          defaultChecked={isPublic}
                          onChange={this.onIsPublicChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkPublicDetailsBooking"
                          style={{
                            fontWeight: "bold",
                            marginTop: "35px !important",
                          }}
                        >
                          Allow other members to see details for bookings
                        </label>
                      </div>
                    </MDBCol>
                  )}

                  <MDBCol md="12" className="mb-3">
                    <label htmlFor="address" className="grey-text">
                      Address
                    </label>
                    <input
                      value={address}
                      onChange={this.formDataHandler}
                      name="address"
                      type="text"
                      id="address"
                      className="form-control"
                      placeholder="Enter address..."
                      readOnly={isTennisMembership}
                    />
                  </MDBCol>
                  {isDefaultUser && (
                    <MDBCol md="12" className="mb-12">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitchesChecked"
                          checked={isPublic}
                          onChange={() => {
                            this.onIsPublicChange();
                          }}
                          readOnly
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitchesChecked"
                          style={{
                            fontWeight: "bold",
                            marginTop: "35px !important",
                          }}
                        >
                          Allow other members to see my details for bookings
                        </label>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
                {!isDefaultUser && (
                  <MDBRow>
                    <MDBCol md="12" className="mb-3">
                      {this.renderMembershipAutoComplete()}
                    </MDBCol>

                    <MDBCol md="6" className="mb-3">
                      <label htmlFor="firstName" className="grey-text">
                        Membership Number
                        {/* <small><em><i>Auto generated when empty</i></em></small> */}
                      </label>
                      <input
                        value={membershipNumber}
                        onChange={this.formDataHandler}
                        name="membershipNumber"
                        type="text"
                        id="membershipNumber"
                        className="form-control"
                        placeholder="Enter Membership Number..."
                      />
                    </MDBCol>
                    <MDBCol md="6" className="mb-3">
                      <label htmlFor="firstName" className="grey-text">
                        Membership Card Number
                      </label>
                      <input
                        value={membershipCardNumber}
                        onChange={this.formDataHandler}
                        name="membershipCardNumber"
                        type="text"
                        id="membershipCardNumber"
                        className="form-control"
                        placeholder="Enter Membership Card Number..."
                      />
                    </MDBCol>
                    <MDBCol md="4" className="mb-3">
                      <label htmlFor="address" className="grey-text">
                        Is Membership Approved?
                      </label>
                      <select
                        className="custom-select bMDBRowser-default"
                        name="isMembershipApproved"
                        required
                        onChange={this.formDataHandler}
                        value={isMembershipApproved}
                      >
                        <option value="0">Pending Approval</option>
                        <option value="1">Approved</option>
                        <option value="-1">Declined</option>
                      </select>
                    </MDBCol>
                    <MDBCol md="4" className="mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="isMembershipRenewal"
                          defaultChecked={isMembershipRenewal}
                          onChange={this.onIsMembershipRenewalChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isMembershipRenewal"
                          style={{
                            fontWeight: "bold",
                            marginTop: "35px !important",
                          }}
                        >
                          Is Membership Renewal?
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.showModal(false, false, null)}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn color="primary" type="submit">
                  Save
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </div>
      </>
    );
  }

  debouncedSearchMembership = _.debounce(function (val) {
    let params = { limit: 20, query: val };
    this.props.getMemberships(params, this.props.authUser.sessionToken);
  }, 1000);

  renderMembershipAutoComplete() {
    return (
      <div className="autoComplete">
        <MDBSelect
          outline
          name="limit"
          color="primary"
          getValue={(value) => {
            this.getValueOfSelectMembershipPlan(value);
          }}
          options={this.state.membershipOptions}
          label="Select Membership"
        />
      </div>
    );
  }

  onIsActiveLocChange = (evt) => {
    this.setState({
      canBook: !this.state.canBook,
    });
  };

  onIsActiveAccountChange = (evt) => {
    this.setState({
      isAccount: !this.state.isAccount,
    });
  };

  onIsPublicChange = (evt) => {
    this.setState({
      isPublic: !this.state.isPublic,
    });
  };

  onIsMembershipApprovedChange = (evt) => {
    this.setState({
      isMembershipApproved: !this.state.isMembershipApproved,
    });
  };

  onIsMembershipRenewalChange = (evt) => {
    this.setState({
      isMembershipRenewal: !this.state.isMembershipRenewal,
    });
  };
  clearForm() {
    this.setState({
      isEdit: false,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      mobile: "",
      customerId: "",
      customerUuid: "",
      wasValidated: "",
      canBook: true,
      isAccount: false,

      membershipNumber: "",
      isMembershipRenewal: false,
      isMembershipApproved: false,
      membershipCardNumber: "",
      membershipId: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerResponse !== this.props.customerResponse) {
      let response = this.props.customerResponse;
      this.props.showHideLoader(false);
      if (response.action == "ACTION") {
        if (response.status >= 200 && response.status <= 300) {
          let attr = {
            show: false,
            action: "callback",
            parent: this.state.parent,
            response: response,
          };
          this.props.genModalCustomer(attr);

          this.props.showNotification("success", response.data.message);

          this.clearForm();
          this.setState({
            showModal: false,
            isModalTrigger: false,
          });
        }
      } else {
        this.setState({
          resultContainer: this.props.customerResponse.data,
          page: this.props.customerResponse.data.page,
        });
      }
    }

    if (prevProps.genericModalResponse !== this.props.genericModalResponse) {
      let response = this.props.genericModalResponse;
      const { storeUserAccess } = this.props;
      if (response.type === GEN_MODAL_CUSTOMER) {
        const { storeUserAccess } = this.props;
        let isDefaultUser =
          validateAccess(storeUserAccess, "DefaultUserMenu", "") &&
          validateAccess(storeUserAccess, "DefaultUserMenu", "userDetails") &&
          !validateAccess(storeUserAccess, "Customers", "");

        if (response.data.show && response.data.action === "add") {
          this.setState({
            showModal: true,
            parent: response.data.parent,
            isDefaultUser: isDefaultUser,
          });
        } else if (response.data.show && response.data.action === "edit") {
          let customer = response.data.payload;
          let membership = customer.membership;
          let membershipCardNumber = "";
          let membershipName = "";
          let membershipNumber = "";
          let isMembershipApproved = false;
          let isMembershipRenewal = false;
          let membershipPlanId = 0;
          if (membership) {
            membershipName = membership.name + " " + membership.type.name;
            membershipNumber = membership.number;
            membershipCardNumber = membership.card_number;
            isMembershipApproved = membership.is_approved;
            isMembershipRenewal = membership.is_renewal;
            membershipPlanId = membership.plan_id;
          }
          this.setState({
            isEdit: true,
            firstName: customer.first_name,
            lastName: customer.last_name,
            email: customer.email,
            address: customer.address,
            mobile: customer.mobile,
            customerId: customer.id,
            customerUuid: customer.uuid,
            showModal: true,
            parent: response.data.parent,
            value: membershipName,
            isMembershipApproved: isMembershipApproved,
            isMembershipRenewal: isMembershipRenewal,
            membershipCardNumber: membershipCardNumber,
            membershipNumber: membershipNumber,
            membershipId: membershipPlanId,
            isDefaultUser: isDefaultUser,
            isPublic: customer.is_public,
            canBook: customer.canbook === 1 ? true : false,
            isAccount: customer.is_account ? true : false,
          });

          let params = { limit: 20, query: "" };
          if (isDefaultUser) {
            params["organisationId"] = customer.organisation.id;
          }
          this.props.getMemberships(params, this.props.authUser.sessionToken);
        }
      }
    }

    this.getMembershipResponse(prevProps, prevState);
  }

  getMembershipResponse(prevProps, prevState) {
    if (prevProps.membershipResponse !== this.props.membershipResponse) {
      let resultContainer = _.chain(this.props.membershipResponse.data.results)
        .orderBy(["name"], ["asc"])
        .value();

      let membershipOptions = [];

      _.map(resultContainer, (result, index) => {
        if (index === 0) {
          membershipOptions.push({
            checked: false,
            disabled: false,
            text: "No membership plan",
            value: "",
          });
        }
        let checked = false;
        if (result.id === this.state.membershipId) {
          checked = true;
        }
        if (this.state.isDefaultUser) {
          let loc = _.find(this.props.locations.results, function (loc) {
            return "" + loc.id === "" + result.location.id;
          });

          if (loc !== undefined) {
            membershipOptions.push({
              checked: checked,
              disabled: false,
              text:
                result.location.name +
                ": " +
                result.name +
                " " +
                result.type.name,
              value: result.id,
            });
          }
        } else {
          membershipOptions.push({
            checked: checked,
            disabled: false,
            text:
              result.location.name +
              ": " +
              result.name +
              " " +
              result.type.name,
            value: result.id,
          });
        }
      });

      this.setState({
        // membershipResultContainer: resultContainer,
        membershipOptions: membershipOptions,
      });
    }
  }

  showModal = (showModal, isEdit, source) => {
    if (source !== null) {
      this.setState({
        showModal: showModal,
        isEdit: isEdit,
        firstName: source.first_name,
        lastName: source.last_name,
        email: source.email,
        address: source.address,
        mobile: source.mobile,
        customerId: source.id,
        customerUuid: source.uuid,
        value: source.membership.name + " " + source.membership.type.name,
        isMembershipApproved: source.membership.is_approved,
        isMembershipRenewal: source.membership.is_renewal,
        membershipCardNumber: source.membership.card_number,
        membershipNumber: source.membership.number,
        membershipId: source.membership.plan_id,
      });
    } else {
      this.setState({ showModal: showModal, isEdit: isEdit });
    }

    if (!showModal) {
      this.clearForm();
    }
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    customerResponse: state.customerResponse,
    modal: state.modal,
    genericModalResponse: state.genericModalResponse,
    membershipResponse: state.membershipResponse,
    storeUserAccess: state.storeUserAccess,
    locations: state.locations,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    toggleModal,
    addModalAttr,
    alertMessage,
    insertCustomer,
    clearInsertCustomerSuccessResponse,
    clearInsertCustomerFailedResponse,
    updateCustomer,
    clearUpdateCustomerSuccessResponse,
    clearUpdateCustomerFailedResponse,
    showNotification,
    clearNotification,
    genModalCustomer,
    getMemberships,
  })(CustomerModal)
);
