import React from "react";
import { connect } from "react-redux";
import { showHideLoader } from "../../../actions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

class Spinner extends React.Component {
  renderContent() {
    const navStyle = {
      position: "fixed",
      zIndex: "100000000",
      background: "rgba(255, 255, 255, 0.78)",
      opacity: "0.8",
      height: "100%",
      width: "100%",
    };
    const style2 = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };

    return (
      <div style={navStyle} className="overlay-box">
        <div style={style2}>
          <Loader
            visible={this.props.loader}
            type="ThreeDots"
            color="#44474b"
            height={60}
            width={60}
            timeout={360000}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.props.loader) {
      return this.renderContent();
    } else {
      return <span></span>;
    }
  }
}

const mapStateToProps = (state) => {
  return { loader: state.loader };
};

export default connect(mapStateToProps, { showHideLoader })(Spinner);
