export default (
  modal = {
    isOpen: false,
    content: null,
    title: null,
    negativeButtonText: "Cancel",
    positiveButtonText: "Ok",
    negativeButtonAction: null,
    positiveButtonAction: null,
  },
  action
) => {
  if (action.type === "TOGGLE_MODAL") {
    return action.payload;
  }
  return modal;
};
