import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
    GET_RECURRING_BOOKINGS,
    RECURRING_BOOKING_RESPONSE_HANDLER,
} from "./types";

export const getRecurringBookings = (params, sessionToken) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken
    };

    const response = await bookingAppPrivate
        .get(`/bookings/recurrings`, {
            params: params,
            headers: headers,
        })
        .then((res) => {
            dispatch({
                type: GET_RECURRING_BOOKINGS,
                payload: res,
            });
        })
        .catch((err) => {
            let errRes = {};
            errRes = "Network error";
            if (err.response !== undefined) {
                errRes = err.response.data.message;
            }

            dispatch({
                type: RECURRING_BOOKING_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: {
                        message: errRes
                    }
                }
            });
        });
};

export const insertRecurringBooking = (body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .post(`/bookings/recurrings`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: RECURRING_BOOKING_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response.data.message;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: RECURRING_BOOKING_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: {
                        message: errRes
                    }
                }
            });
        });
};


export const cancelRecurringBooking = (body, id, sessionToken) => async (
    dispatch
) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .post(`/bookings/recurrings/cancel/${id}`, body, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: RECURRING_BOOKING_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response.data.message;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: RECURRING_BOOKING_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: {
                        message: errRes
                    }
                }
            });
        });
};

export const deleteRecurringBooking = (uuid, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
        "Content-Type": "application/json",
        "x-session-token": sessionToken,
        "x-origin": origin,
    };
    bookingAppPrivate
        .delete(`/bookings/recurrings/${uuid}`, {
            headers: headers,
        })
        .then((res) => {
            dispatch({ type: RECURRING_BOOKING_RESPONSE_HANDLER, payload: res });
        })
        .catch((err) => {
            let errRes = {};
            if (err.response !== undefined) {
                errRes = err.response.data.message;
            } else {
                errRes = "Network error";
            }
            dispatch({
                type: RECURRING_BOOKING_RESPONSE_HANDLER,
                payload: {
                    status: 400,
                    data: {
                        message: errRes
                    }
                }
            });
        });
};

export const clearDeleteRecurringBookingSuccessResponse = () => {
    return {
        type: RECURRING_BOOKING_RESPONSE_HANDLER,
        payload: {},
    };
};

export const clearDeleteRecurringBookingFailedResponse = () => {
    return {
        type: RECURRING_BOOKING_RESPONSE_HANDLER,
        payload: {},
    };
};
