import React from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import defaultLogo from "../assets/ubicon.png";

import { MDBRow, MDBCol, MDBCardHeader, MDBCard, MDBCardBody } from "mdbreact";
const CheckoutForm = (props) => {
  const {
    isPaymentPage,
    headerTextColor,
    headerBackgroundColor,
    subHeaderTextColor,
    formLabelsColor,
    formBodyBackgroundColor,
    payButtonBackgroundColor,
    payButtonTextColor,
    subHeaderText,
    headerText,
    imagePreview,
    totalAmount,
    isDisabledButton,
  } = props;
  return (
    <MDBRow>
      <MDBCol md="5">
        <MDBCard
          style={{
            width: "550px",
            height: "650px",
          }}
        >
          <MDBCardHeader
            className="py-4"
            style={{ backgroundColor: headerBackgroundColor }}
          >
            <MDBRow className="row align-items-center">
              <MDBCol md="4">
                <div className="row justify-content-center">
                  <img
                    style={{ height: "60px" }}
                    src={imagePreview}
                    className="img-fluid"
                    alt="organisationLogo"
                  />
                </div>
              </MDBCol>
              <MDBCol
                className="text-left"
                md="8"
                style={{ color: headerTextColor }}
              >
                <h4>{headerText}</h4>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody style={{ backgroundColor: formBodyBackgroundColor }}>
            <h5
              style={{ color: subHeaderTextColor }}
              className="text-center py-4"
            >
              {subHeaderText}
            </h5>
            <label
              style={{ color: formLabelsColor }}
              className="font-weight-light"
              formLabelsColor
            >
              Credit Card Number
            </label>
            {isPaymentPage === true ? (
              <CardNumberElement />
            ) : (
              <input
                type="text"
                className="form-control"
                placeholder="#### #### #### ####"
              />
            )}

            <br />

            <MDBRow>
              <MDBCol md="6">
                <label
                  style={{ color: formLabelsColor }}
                  className="font-weight-light"
                >
                  Expiry Date
                </label>
                {isPaymentPage === true ? (
                  <CardExpiryElement />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="## / ##"
                  />
                )}
              </MDBCol>
              <MDBCol md="6">
                <label
                  style={{ color: formLabelsColor }}
                  className="font-weight-light"
                >
                  CVC
                </label>
                {isPaymentPage === true ? (
                  <CardCvcElement />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="#### #### #### ####"
                  />
                )}
              </MDBCol>
            </MDBRow>

            <div className="text-center py-4 mt-3">
              <button
                className="btn"
                type={isPaymentPage ? "submit" : "button"}
                disabled={isDisabledButton}
                style={{
                  backgroundColor: payButtonBackgroundColor,
                  color: payButtonTextColor,
                }}
              >
                PAY
              </button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default CheckoutForm;
