import {
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_FAILED,
  SUCCESS_HANDLER,
  FAILED_HANDLER,
  GET_PAYMENT_REQUEST_DETAILS,
  GET_PAYMENT_REQUEST_TRANSACTIONS,
  GET_PAYMENT_TRANSACTIONS,
  SET_BULK_PAY_BOOKING_REFERENCE_NUMBER,
} from "./types";
import bookingAppPrivate from "../apis/bookingAppPrivate";

export const requestPayment =
  (details, sessionToken = "") =>
  async (dispatch) => {
    let origin = window.location.origin;
    let headers = {
      "Content-Type": "application/json",
      "x-origin": origin,
    };
    let requestPaymentResource = "request";

    if (sessionToken != undefined && sessionToken != "") {
      headers["x-session-token"] = sessionToken;
      requestPaymentResource = "requestprivate";
    }

    bookingAppPrivate
      .post(`/payments/stripe/${requestPaymentResource}`, details, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: PAYMENT_REQUEST_SUCCESS, payload: res });
      })
      .catch((err) => {
        // dispatch({
        //   type: PAYMENT_REQUEST_FAILED,
        //   payload: "PAYMENT_REQUEST_FAILED",
        // });

        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: PAYMENT_REQUEST_FAILED,
          payload: errRes,
        });
      });
  };

export const clearPaymentRequestSuccess = () => {
  return {
    type: PAYMENT_REQUEST_SUCCESS,
    payload: {},
  };
};

export const clearPaymentRequestFailed = () => {
  return {
    type: PAYMENT_REQUEST_FAILED,
    payload: {},
  };
};

export const calculatePrice =
  (details, sessionToken = "") =>
  async (dispatch) => {
    let origin = window.location.origin;
    let headers = {
      "Content-Type": "application/json",
    };
    let calculateResource = "calculate";
    if (sessionToken.length > 0) {
      headers["x-session-token"] = sessionToken;
      headers["x-origin"] = origin;
      calculateResource = "calculateprivate";
    }

    details["isCheckConflict"] = true;

    bookingAppPrivate
      .post(`/pricing/${calculateResource}`, details, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: SUCCESS_HANDLER, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: FAILED_HANDLER,
          payload: errRes,
        });
      });
  };

export const getPaymentRequestDetails = (params) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };

  const response = await bookingAppPrivate
    .get(`/payments/request`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_PAYMENT_REQUEST_DETAILS,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      errRes = "Network error";
      if (err.response !== undefined) {
        errRes = err.response;
      }
      dispatch({
        type: FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getPaymentRequestTransactions =
  (paymentRequestIds, sessionToken) => async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    const response = await bookingAppPrivate
      .get(`/payments/requests/transactions`, {
        params: {
          paymentRequestIds: paymentRequestIds,
        },
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_REQUEST_TRANSACTIONS,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: FAILED_HANDLER,
          payload: errRes,
        });
      });
  };

export const getPaymentReports =
  (params, sessionToken, synchronous = false) =>
  async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    let response = bookingAppPrivate.get(`/payments/reports`, {
      params: params,
      headers: headers,
    });

    if (!synchronous) {
      response.then((res) => {
        dispatch({
          type: GET_PAYMENT_TRANSACTIONS,
          payload: res,
        });
      });
      response.catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: FAILED_HANDLER,
          payload: errRes,
        });
      });
    }
    return await response;
  };

export const bulkPayCustomerPaymentPending = (bulkPayReferenceNumbers) => {
  return {
    type: SET_BULK_PAY_BOOKING_REFERENCE_NUMBER,
    payload: bulkPayReferenceNumbers,
  };
};

export const bulkPayPaymentPendingBookings =
  (body, session) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-origin": origin,
    };

    if (session != undefined && session != "") {
      headers["x-session-token"] = session;
    }
    const response = bookingAppPrivate
      .post(`/payments/bulkpay`, body, {
        headers: headers,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        return errRes;
      });
    return await response;
  };
