import { GET_CUSTOMER_BANNER_MESSAGE } from "../actions/types";

export default (state = {}, action) => {
  let status = 200;
  let data = {};
  let notificationType = "info";
  if (
    action.payload != undefined &&
    typeof action.payload.status != "undefined"
  ) {
    status = action.payload.status;
    notificationType = "success";
    if (status >= 400) {
      notificationType = "error";
    }
    data = action.payload.data;
  }

  let response = {
    data: data,
    type: action.type,
    status: status,
  };
  switch (action.type) {
    case GET_CUSTOMER_BANNER_MESSAGE:
      response["data"] = data;
      response["action"] = GET_CUSTOMER_BANNER_MESSAGE;
      break;

    default:
      return state;
  }
  return response;
};
