import bookingAppPrivate from "../apis/bookingAppPrivate";
import {
  GET_BOOKING_DETAILS,
  BOOKING_DETAILS,
  BOOKING_SUCCESS_HANDLER,
  BOOKING_FAILED_HANDLER,
  GET_STATUSES_SUCCESS_HANDLER,
  GET_STATUSES_FAILED_HANDLER,
  GET_BOOKING_STATUS_SUCCESS_HANDLER,
  GET_BOOKING_STATUS_FAILED_HANDLER,
  GET_BOOKINGS_SUCCESS_HANDLER,
  GET_BOOKINGS_FAILED_HANDLER,
  BOOKING_CANCELLED,
  GET_BOOKING_REQUEST,
  GET_BOOKING_REQUEST_RECORD_LOCK,
  CANCEL_BOOKING_REQUEST,
  LOCK_BOOKING_REQUEST,
  BOOKING_CONFLICTS,
  GET_BOOKING_PARTICIPANTS,
  GET_USER_BOOKINGS,
  GET_USER_CALENDAR,
  BOOKING_PARTICIPANT_OPTOUT,
  GET_RESOURCE_BOOKINGS,
  VALIDATE_ADDED_CUSTOMER,
  GET_BOOKINGS_REPORT,
  BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION,
  BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER,
} from "./types";

export const setBookingDetails = (details) => {
  return {
    type: BOOKING_DETAILS,
    payload: details,
  };
};

export const getBookingDetails =
  (uuid, sessionToken = "") =>
  async (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (sessionToken != undefined && sessionToken != "") {
      headers["x-session-token"] = sessionToken;
    }

    const response = await bookingAppPrivate.get(`/bookings/${uuid}`, {
      headers: headers,
    });

    dispatch({ type: GET_BOOKING_DETAILS, payload: response.data });
  };

export const doBooking = (body, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };

  if (sessionToken != undefined && sessionToken != "") {
    headers["x-session-token"] = sessionToken;
  }

  bookingAppPrivate
    .post(`/bookings`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: BOOKING_SUCCESS_HANDLER, payload: res, isCreate: true });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: BOOKING_FAILED_HANDLER,
        payload: errRes,
        isCreate: true,
      });
    });
};

export const clearBookingSuccessResponse = () => {
  return {
    type: BOOKING_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearBookingFailedResponse = () => {
  return {
    type: BOOKING_FAILED_HANDLER,
    payload: {},
  };
};

export const updateBooking =
  (body, sessionToken, bookingUuid) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/bookings/${bookingUuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: BOOKING_SUCCESS_HANDLER,
          payload: res,
          isCreate: false,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: BOOKING_FAILED_HANDLER,
          payload: errRes,
          isCreate: false,
        });
      });
  };

export const cancelPublicBooking = (bookingUuid) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(
      `/bookings/cancel/${bookingUuid}`,
      {},
      {
        headers: headers,
      }
    )
    .then((res) => {
      dispatch({ type: BOOKING_CANCELLED, payload: res });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: BOOKING_CANCELLED,
        payload: errRes,
      });
    });
};

export const checkBookingConflicts =
  (bookingData, sessionToken, page = "calendar") =>
  async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-origin": origin,
      "x-session-token": sessionToken,
    };
    bookingAppPrivate
      .post(`/bookings/checkconflicts`, bookingData, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: BOOKING_CONFLICTS,
          payload: res,
          page: page,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: BOOKING_CONFLICTS,
          payload: errRes,
        });
      });
  };

export const checkPublicBookingConflicts =
  (bookingData) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-origin": origin,
    };
    bookingAppPrivate
      .post(`/bookings/checkpublicconflicts`, bookingData, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: BOOKING_CONFLICTS, payload: res });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: BOOKING_FAILED_HANDLER,
          payload: errRes,
        });
      });
  };

export const getStatuses = (sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    // "x-origin": origin,
  };
  const response = await bookingAppPrivate
    .get(`/bookings/status`, {
      params: {
        // date: date,
      },
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_STATUSES_SUCCESS_HANDLER,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_STATUSES_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const clearStatusSuccessResponse = () => {
  return {
    type: GET_STATUSES_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearStatusFailedResponse = () => {
  return {
    type: GET_STATUSES_FAILED_HANDLER,
    payload: {},
  };
};

export const getBookingStatus = (uuid, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  const response = await bookingAppPrivate
    .get(`/bookings/${uuid}/status`, {
      params: {
        // date: date,
      },
      headers: headers,
    })
    .then((res) => {
      dispatch({
        type: GET_BOOKING_STATUS_SUCCESS_HANDLER,
        payload: res,
      });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_BOOKING_STATUS_FAILED_HANDLER,
        payload: errRes,
      });
    });
};

export const getBookingParticipants =
  (bookingId, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    const response = await bookingAppPrivate
      .get(`/bookings/participants`, {
        params: {
          bookingId: bookingId,
        },
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BOOKING_PARTICIPANTS,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BOOKING_STATUS_FAILED_HANDLER,
          payload: errRes,
        });
      });
  };

export const clearBookingStatusSuccessResponse = () => {
  return {
    type: GET_BOOKING_STATUS_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearBookingStatusFailedResponse = () => {
  return {
    type: GET_BOOKING_STATUS_FAILED_HANDLER,
    payload: {},
  };
};

export const getBookings =
  (params, sessionToken, isDispatch = true) =>
  async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    const response = await bookingAppPrivate
      .get(`/bookings`, {
        params: params,
        headers: headers,
      })
      .then((res) => {
        if (isDispatch) {
          dispatch({
            type: GET_BOOKINGS_SUCCESS_HANDLER,
            payload: res,
          });
        }
        return res;
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_BOOKINGS_FAILED_HANDLER,
          payload: errRes,
        });
        return errRes;
      });
    return response;
  };

export const clearBookingsSuccessResponse = () => {
  return {
    type: GET_BOOKINGS_SUCCESS_HANDLER,
    payload: {},
  };
};

export const clearBookingsFailedResponse = () => {
  return {
    type: GET_BOOKINGS_FAILED_HANDLER,
    payload: {},
  };
};

export const getUserBookings = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/bookings/userBookings`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      let payload = res;
      payload.data.bookings["locationId"] = params.locationId;
      payload.data.bookings["componentName"] = params.componentName;
      dispatch({
        type: GET_USER_BOOKINGS,
        payload: payload,
      });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_USER_BOOKINGS,
        payload: errRes,
      });
    });
};

export const bookingParticipantOptout =
  (bookingUuid, body, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-origin": origin,
      "x-session-token": sessionToken,
    };
    bookingAppPrivate
      .post(`/bookings/participants/${bookingUuid}/optout`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: BOOKING_PARTICIPANT_OPTOUT,
          payload: res,
          isCreate: false,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: BOOKING_FAILED_HANDLER,
          payload: errRes,
          isCreate: false,
        });
      });
  };

export const getUserCalendar = (params, sessionToken) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-session-token": sessionToken,
    "x-origin": origin,
  };
  bookingAppPrivate
    .get(`/bookings/userCalendar`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      let payload = res;
      dispatch({
        type: GET_USER_CALENDAR,
        payload: payload,
      });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_USER_CALENDAR,
        payload: errRes,
      });
    });
};

export const getResourceBookings =
  (params, sessionToken) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    let identifier = params.resourceUuid;

    bookingAppPrivate
      .get(`/resource/bookings`, {
        params: params,
        headers: headers,
      })
      .then((res) => {
        let payload = res;
        payload.data["identifier"] = identifier;
        dispatch({
          type: GET_RESOURCE_BOOKINGS,
          payload: payload,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: GET_RESOURCE_BOOKINGS,
          payload: errRes,
        });
      });
  };

export const getResourceBookingsPublic = (params) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  let identifier = params.resourceUuid;

  bookingAppPrivate
    .get(`/resource/bookingspublic`, {
      params: params,
      headers: headers,
    })
    .then((res) => {
      let payload = res;
      payload.data["identifier"] = identifier;
      dispatch({
        type: GET_RESOURCE_BOOKINGS,
        payload: payload,
      });
    })
    .catch((err) => {
      let errRes = {};
      if (err.response !== undefined) {
        errRes = err.response;
      } else {
        errRes = "Network error";
      }
      dispatch({
        type: GET_RESOURCE_BOOKINGS,
        payload: errRes,
      });
    });
};

export const validateAddedCustomer =
  (bookingData, sessionToken = "") =>
  async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
    };

    let validateAddedCustomerResource = "validatePublicAddedCustomer";
    if (sessionToken.length > 0) {
      headers["x-session-token"] = sessionToken;
      headers["x-origin"] = origin;
      validateAddedCustomerResource = "validateAddedCustomer";
    }

    bookingAppPrivate
      .post(`/bookings/${validateAddedCustomerResource}`, bookingData, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: VALIDATE_ADDED_CUSTOMER,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        if (err.response !== undefined) {
          errRes = err.response;
        } else {
          errRes = "Network error";
        }
        dispatch({
          type: VALIDATE_ADDED_CUSTOMER,
          payload: errRes,
        });
      });
  };

export const getBookingRequest =
  (uuid, sessionToken = "") =>
  async (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (sessionToken != undefined && sessionToken != "") {
      headers["x-session-token"] = sessionToken;
    }

    const response = await bookingAppPrivate.get(
      `/bookings/getBookingRequest/${uuid}`,
      {
        headers: headers,
      }
    );
    dispatch({
      type: GET_BOOKING_REQUEST,
      payload: response,
    });
  };

export const cancelBookingRequest =
  (body, sessionToken, uuid) => async (dispatch) => {
    let origin = window.location.origin;
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
      "x-origin": origin,
    };
    bookingAppPrivate
      .put(`/bookings/cancelBookingRequest/${uuid}`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({ type: CANCEL_BOOKING_REQUEST, payload: res });
      })
      .catch((err) => {
        console.log("ERROR");
      });
  };

export const lockBookingRequest = (body, uuid) => async (dispatch) => {
  let origin = window.location.origin;
  const headers = {
    "Content-Type": "application/json",
    "x-origin": origin,
  };
  bookingAppPrivate
    .post(`/bookings/lockBookingRequestRecordByUUID/${uuid}`, body, {
      headers: headers,
    })
    .then((res) => {
      dispatch({ type: LOCK_BOOKING_REQUEST, payload: res });
    })
    .catch((err) => {
      console.log("ERROR");
    });
};

export const checkBookingRequestRecordLock =
  (uuid, sessionToken) => async (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };
    const response = await bookingAppPrivate.get(
      `/bookings/checkBookingRequestRecordLock/${uuid}`,
      {
        headers: headers,
      }
    );

    dispatch({
      type: GET_BOOKING_REQUEST_RECORD_LOCK,
      payload: response,
    });
  };

export const getMemberAdvanceBookingDay =
  (queryParams, sessionToken) => async (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };
    const response = await bookingAppPrivate.get(
      `/bookings/getMemberAdvanceBookingDay?customerEmail=${queryParams["customerEmail"]}&organisationId=${queryParams["organisationId"]}&locationId=${queryParams["locationId"]}`,
      {
        headers: headers,
      }
    );

    return response;
  };

export const getBookingReports =
  (params, sessionToken, synchronous = false) =>
  async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    let response = bookingAppPrivate.get(`/bookings/admin/booking_report`, {
      params: params,
      headers: headers,
    });

    if (!synchronous) {
      response.then((res) => {
        dispatch({
          type: GET_BOOKINGS_REPORT,
          payload: res,
        });
      });
      response.catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: BOOKING_FAILED_HANDLER,
          payload: errRes,
        });
      });
    }
    return await response;
  };

export const notifyBookingTimeslotAvailablity =
  (body, sessionToken, synchronous = false) =>
  async (dispatch) => {
    const headers = {
      "Content-Type": "application/json",
      "x-session-token": sessionToken,
    };

    let response = await bookingAppPrivate
      .post(`/bookings/booking_timeslot_notification`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION,
          payload: res,
        });
      })
      .catch((err) => {
        let errRes = {};
        errRes = "Network error";
        if (err.response !== undefined) {
          errRes = err.response;
        }
        dispatch({
          type: BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER,
          payload: errRes,
        });
      });
    return response;
  };
