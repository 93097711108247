export const GET_BOOKING_PARTICIPANTS = "GET_BOOKING_PARTICIPANTS";
export const GET_USER_BOOKINGS = "GET_USER_BOOKINGS";
export const GET_USER_CALENDAR = "GET_USER_CALENDAR";
export const GET_RESOURCE_BOOKINGS = "GET_RESOURCE_BOOKINGS";
export const BOOKING_PARTICIPANT_OPTOUT = "BOOKING_PARTICIPANT_OPTOUT";
export const GET_NOTIFICATION_LOGS = "GET_NOTIFICATION_LOGS";
export const ACTIVE_COMPONENT = "ACTIVE_COMPONENT";
export const SWITCH_VAL = "SWITCH_VAL";
export const DISABLE_PARENT_CALENDAR_TIMER = "DISABLE_PARENT_CALENDAR_TIMER";
export const PAUSE_BACKGROUND_REQUESTS = "PAUSE_BACKGROUND_REQUESTS";
export const DISABLE_NEXT_NAV = "DISABLE_NEXT_NAV";
export const DISABLE_PREV_NAV = "DISABLE_PREV_NAV";

export const BOOKING_CONFLICTS = "BOOKING_CONFLICTS";
export const BOOKING_CANCELLED = "BOOKING_CANCELLED";
export const BOOKING_FAILED = "BOOKING_FAILED";

export const CLOSE_BOOKING = "CLOSE_BOOKING";
export const BOOKING_CHANGE_SCHEDULE = "BOOKING_CHANGE_SCHEDULE";

export const SUBMIT_CONTACT_MESSAGE_HANDLER = "SUBMIT_CONTACT_MESSAGE_HANDLER";
export const SUBMIT_CONTACT_MESSAGE_FAILED_HANDLER =
  "SUBMIT_CONTACT_MESSAGE_FAILED_HANDLER";

export const SET_VAL = "SET_VAL";
export const ADD_MODAL_ATTR = "ADD_MODAL_ATTR";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const STORE_USER_ACCESS = "STORE_USER_ACCESS";

// -- Location
export const SEARCH_LOCATIONS = "SEARCH_LOCATIONS";
export const LOCATION_RESOURCES = "LOCATION_RESOURCES";
export const INSERT_LOCATION_SUCCESS_HANDLER =
  "INSERT_LOCATION_SUCCESS_HANDLER";
export const INSERT_LOCATION_FAILED_HANDLER = "INSERT_LOCATION_FAILED_HANDLER";
export const UPDATE_LOCATION_SUCCESS_HANDLER =
  "UPDATE_LOCATION_SUCCESS_HANDLER";
export const UPDATE_LOCATION_FAILED_HANDLER = "UPDATE_LOCATION_FAILED_HANDLER";
export const GET_LOCATION_LIST_SUCCESS_HANDLER =
  "GET_LOCATION_LIST_SUCCESS_HANDLER";
export const GET_LOCATION_LIST_FAILED_HANDLER =
  "GET_LOCATION_LIST_FAILED_HANDLER";
export const GET_LOCATION_MENU_SUCCESS_HANDLER =
  "GET_LOCATION_MENU_SUCCESS_HANDLER";
export const GET_LOCATION_MENU_FAILED_HANDLER =
  "GET_LOCATION_MENU_FAILED_HANDLER";
export const DELETE_LOCATION_SUCCESS_HANDLER =
  "DELETE_LOCATION_SUCCESS_HANDLER";
export const DELETE_LOCATION_FAILED_HANDLER = "DELETE_LOCATION_FAILED_HANDLER";
// -- Location

// -- Resource
export const GET_RESOURCE = "GET_RESOURCE";
export const GET_RESOURCE_LIST_SUCCESS_HANDLER =
  "GET_RESOURCE_LIST_SUCCESS_HANDLER";
export const GET_RESOURCE_LIST_FAILED_HANDLER =
  "GET_RESOURCE_LIST_FAILED_HANDLER";
export const INSERT_RESOURCE_SUCCESS_HANDLER =
  "INSERT_RESOURCE_SUCCESS_HANDLER";
export const INSERT_RESOURCE_FAILED_HANDLER = "INSERT_RESOURCE_FAILED_HANDLER";
export const UPDATE_RESOURCE_SUCCESS_HANDLER =
  "UPDATE_RESOURCE_SUCCESS_HANDLER";
export const UPDATE_RESOURCE_FAILED_HANDLER = "UPDATE_RESOURCE_FAILED_HANDLER";

export const DELETE_RESOURCE_SUCCESS_HANDLER =
  "DELETE_RESOURCE_SUCCESS_HANDLER";
export const DELETE_RESOURCE_FAILED_HANDLER = "DELETE_RESOURCE_FAILED_HANDLER";
export const LOCK_RESOURCE_BOOKING_TIME_SLOT =
  "LOCK_RESOURCE_BOOKING_TIME_SLOT";

// -- Resource

// -- Attributes
export const GET_ATTR_KEYS_SUCCESS_HANDLER = "GET_ATTR_KEYS_SUCCESS_HANDLER";
export const GET_ATTR_KEYS_FAILED_HANDLER = "GET_ATTR_KEYS_FAILED_HANDLER";
export const GET_ATTR_SUCCESS_HANDLER = "GET_ATTR_SUCCESS_HANDLER";
export const GET_ATTR_FAILED_HANDLER = "GET_ATTR_FAILED_HANDLER";
export const INSERT_ATTR_SUCCESS_HANDLER = "INSERT_ATTR_SUCCESS_HANDLER";
export const INSERT_ATTR_FAILED_HANDLER = "INSERT_ATTR_FAILED_HANDLER";
export const UPDATE_ATTR_SUCCESS_HANDLER = "UPDATE_ATTR_SUCCESS_HANDLER";
export const UPDATE_ATTR_FAILED_HANDLER = "UPDATE_ATTR_FAILED_HANDLER";
export const INSERT_ATTR_KEY_SUCCESS_HANDLER =
  "INSERT_ATTR_KEY_SUCCESS_HANDLER";
export const INSERT_ATTR_KEY_FAILED_HANDLER = "INSERT_ATTR_KEY_FAILED_HANDLER";
export const GET_ATTR_TYPES_SUCCESS_HANDLER = "GET_ATTR_TYPES_SUCCESS_HANDLER";
export const GET_ATTR_TYPES_FAILED_HANDLER = "GET_ATTR_TYPES_FAILED_HANDLER";
export const GET_ATTR_CHILD_SUCCESS_HANDLER = "GET_ATTR_CHILD_SUCCESS_HANDLER";
export const GET_ATTR_CHILD_RESPONSE_SUCCESS_HANDLER =
  "GET_ATTR_CHILD_RESPONSE_SUCCESS_HANDLER";
// -- Attributes

export const VALIDATE_LAYOUT = "VALIDATE_LAYOUT";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const VALIDATE_SEARCH = "VALIDATE_SEARCH";
export const SELECT_DATE = "SELECT_DATE";
export const ALERT_MESSAGE = "ALERT_MESSAGE";

export const REVALIDATE_COMPONENT = "REVALIDATE_COMPONENT";

export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_USER_AUTH = "SET_USER_AUTH";
export const SHOW_HIDE_LOADER = "SHOW_HIDE_LOADER";
export const DISPLAY_BREADCRUMBS = "DISPLAY_BREADCRUMBS";
export const REDIRECTED = "REDIRECTED";

//-- Payment
export const GET_PAYMENT_TRANSACTIONS = "GET_PAYMENT_TRANSACTIONS";
export const GET_PAYMENT_REQUEST_TRANSACTIONS =
  "GET_PAYMENT_REQUEST_TRANSACTIONS";
export const REQUEST_PAYMENT = "REQUEST_PAYMENT";
export const PAYMENT_REQUEST_SUCCESS = "PAYMENT_REQUEST_SUCCESS";
export const PAYMENT_REQUEST_FAILED = "PAYMENT_REQUEST_FAILED";
export const GET_PAYMENT_REQUEST_DETAILS = "GET_PAYMENT_REQUEST_DETAILS";

export const SET_BULK_PAY_BOOKING_REFERENCE_NUMBER =
  "SET_BULK_PAY_BOOKING_REFERENCE_NUMBER";
//-- Payment

//--Booking
export const BOOKING_DETAILS = "BOOKING_DETAILS";
export const GET_BOOKING_DETAILS = "GET_BOOKING_DETAILS";
export const BOOKING_SUCCESS_HANDLER = "BOOKING_SUCCESS_HANDLER";
export const BOOKING_FAILED_HANDLER = "BOOKING_FAILED_HANDLER";
export const GET_STATUSES_SUCCESS_HANDLER = "GET_STATUSES_SUCCESS_HANDLER";
export const GET_STATUSES_FAILED_HANDLER = "GET_STATUSES_FAILED_HANDLER";
export const GET_BOOKING_STATUS_SUCCESS_HANDLER =
  "GET_BOOKING_STATUS_SUCCESS_HANDLER";
export const GET_BOOKING_STATUS_FAILED_HANDLER =
  "GET_BOOKING_STATUS_FAILED_HANDLER";
export const GET_BOOKINGS_SUCCESS_HANDLER = "GET_BOOKINGS_SUCCESS_HANDLER";
export const GET_BOOKINGS_FAILED_HANDLER = "GET_BOOKINGS_FAILED_HANDLER";

export const VALIDATE_ADDED_CUSTOMER = "VALIDATE_ADDED_CUSTOMER";
export const GET_BOOKING_REQUEST = "GET_BOOKING_REQUEST";
export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST";
export const LOCK_BOOKING_REQUEST = "LOCK_BOOKING_REQUEST";
export const GET_BOOKING_REQUEST_RECORD_LOCK =
  "GET_BOOKING_REQUEST_RECORD_LOCK";

export const GET_BOOKINGS_REPORT = "GET_BOOKINGS_REPORT";
export const BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION =
  "BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION";
export const BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER =
  "BOOKING_TIMESLOT_AVAILABILITY_NOTIFICATION_FAILED_HANDLER";

//--Booking

//-- User
export const CREATE_USER = "CREATE_USER";
export const GET_USERS = "GET_USERS";
export const USER_RESPONSE_HANDLER = "USER_RESPONSE_HANDLER";
export const USER_LOGOUT = "USER_LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const VALIDATE_RESET_TOKEN = "VALIDATE_RESET_TOKEN";
export const HEART_BEAT_RESPONSE_HANDLER = "HEART_BEAT_RESPONSE_HANDLER";
export const REGISTER_RESPONSE_HANDLER = "REGISTER_RESPONSE_HANDLER";
export const USER_DETAILS_RESPONSE_HANDLER = "USER_DETAILS_RESPONSE_HANDLER";
export const GET_SUBSCRIPTION_RESPONSE_HANDLER =
  "GET_SUBSCRIPTION_RESPONSE_HANDLER";
export const SUBSCRIBE_RESPONSE_HANDLER = "SUBSCRIBE_RESPONSE_HANDLER";
export const FORGOTPASS_RESPONSE_HANDLER = "FORGOTPASS_RESPONSE_HANDLER";
//-- User

// -- RULE --
export const RULE_RESPONSE_HANDLER = "RULE_RESPONSE_HANDLER";
export const GET_RULES = "GET_RULES";

// -- Membership --
export const MEMBERSHIP_RESPONSE_HANDLER = "MEMBERSHIP_RESPONSE_HANDLER";
export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";

// -- Membership Type --
export const MEMBERSHIP_TYPE_RESPONSE_HANDLER =
  "MEMBERSHIP_TYPE_RESPONSE_HANDLER";
export const GET_MEMBERSHIP_TYPES = "GET_MEMBERSHIP_TYPES";

//-- Customer
export const CUSTOMER_RESPONSE_HANDLER = "CUSTOMER_RESPONSE_HANDLER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMER_BANNER_MESSAGE = "GET_CUSTOMER_BANNER_MESSAGE";
export const HIDE_CUSTOMER_BANNER_MESSAGE = "HIDE_CUSTOMER_BANNER_MESSAGE";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const INSERT_CUSTOMER_SUCCESS_HANDLER =
  "INSERT_CUSTOMER_SUCCESS_HANDLER";
export const INSERT_CUSTOMER_FAILED_HANDLER = "INSERT_CUSTOMER_FAILED_HANDLER";
export const UPDATE_CUSTOMER_SUCCESS_HANDLER =
  "UPDATE_CUSTOMER_SUCCESS_HANDLER";
export const UPDATE_CUSTOMER_FAILED_HANDLER = "UPDATE_CUSTOMER_FAILED_HANDLER";
export const DELETE_CUSTOMER_SUCCESS_HANDLER =
  "DELETE_CUSTOMER_SUCCESS_HANDLER";
export const DELETE_CUSTOMER_FAILED_HANDLER = "DELETE_CUSTOMER_FAILED_HANDLER";
export const VALIDATE_CUSTOMER_DETAILS = "VALIDATE_CUSTOMER_DETAILS";
//-- Customer

//-- Generic Response Handler
export const SUCCESS_HANDLER = "SUCCESS_HANDLER";
export const FAILED_HANDLER = "FAILED_HANDLER";
//-- Generic Response Handler

// -- Voucher
export const GENERATE_CUSTOMER_VOUCHER_SUCCESS_HANDLER =
  "GENERATE_CUSTOMER_VOUCHER_SUCCESS_HANDLER";
export const GENERATE_CUSTOMER_VOUCHER_FAILED_HANDLER =
  "GENERATE_CUSTOMER_VOUCHER_FAILED_HANDLER";
export const GET_VOUCHERS = "GET_VOUCHERS";
export const GET_CREDITS = "GET_CREDITS";
export const VOUCHER_RESPONSE_HANDLER = "VOUCHER_RESPONSE_HANDLER";
// -- Voucher

//-- Audit logs
export const GET_AUDITLOGS = "GET_AUDITLOGS";
//-- Audit logs

//-- Organisation
export const GET_ORGANISATIONS = "GET_ORGANISATIONS";
export const GET_ORGANISATION_CHECKOUT_PAGE = "GET_ORGANISATION_CHECKOUT_PAGE";
export const GET_ORGANISATION = "GET_ORGANISATION";
export const ORGANISATION_RESPONSE_HANDLER = "ORGANISATION_RESPONSE_HANDLER";
//-- Organisation

//-- Organisation
export const GET_RECURRING_BOOKINGS = "GET_RECURRING_BOOKINGS";
export const RECURRING_BOOKING_RESPONSE_HANDLER =
  "RECURRING_BOOKING_RESPONSE_HANDLER";
//-- Organisation

// -- Generic Search
export const GEN_SEARCH_VOUCHER = "GEN_SEARCH_VOUCHER";
export const GEN_SEARCH_BOOKING = "GEN_SEARCH_BOOKING";
export const GEN_SEARCH_CUSTOMER = "GEN_SEARCH_CUSTOMER";
export const GEN_SEARCH_FAILED_HANDLER = "GEN_SEARCH_FAILED_HANDLER";
// -- Generic Search

// -- Reusable Modals
export const GEN_MODAL_CUSTOMER = "GEN_MODAL_CUSTOMER";
export const GEN_MODAL_VOUCHER = "GEN_MODAL_VOUCHER";
export const GEN_MODAL_ROLE = "GEN_MODAL_ROLE";
export const GEN_MODAL_ROLE_ACCESS = "GEN_MODAL_ROLE_ACCESS";
export const GEN_MODAL_SUBSCRIBE_MORE_DETAILS =
  "GEN_MODAL_SUBSCRIBE_MORE_DETAILS";
export const GEN_MODAL_FAVOURITE_CUSTOMER = "GEN_MODAL_FAVOURITE_CUSTOMER";
// -- Reusable Modals

// -- Role
export const GET_ROLES = "GET_ROLES";
export const ROLE_RESPONSE_HANDLER = "ROLE_RESPONSE_HANDLER";
export const GET_ROLE_ACCESS = "GET_ROLE_ACCESS";
export const GET_USER_ROLE_ACCESS = "GET_USER_ROLE_ACCESS";
export const SWITCH_ROLE_ACCESS_RESPONSE_HANDLER =
  "SWITCH_ROLE_ACCESS_RESPONSE_HANDLER";
// -- Role

// CUSTOMER FAVOURITES
export const GET_USER_FAVOURITES = "GET_USER_FAVOURITES";
export const GET_USER_FAVOURITE_RESPONSE_HANDLER =
  "GET_USER_FAVOURITE_RESPONSE_HANDLER";
// CUSTOMER FAVOURITES

//RULES ENGINE V2
export const GEN_MODAL_RULES_ENGINE = "GEN_MODAL_RULES_ENGINE";
export const GEN_MODAL_RULE_CONDITION_EVALUATOR =
  "GEN_MODAL_RULE_CONDITION_EVALUATOR";
export const GEN_MODAL_RULE_TRIGGER = "GEN_MODAL_RULE_TRIGGER";

export const GET_BUSINESS_RULES = "GET_BUSINESS_RULES";
export const GET_BUSINESS_RULE_RESPONSE_HANDLER =
  "GET_BUSINESS_RULE_RESPONSE_HANDLER";
export const GET_RULE_CONDITION_EVALUATOR_RESPONSE_HANDLER =
  "GET_RULE_CONDITION_EVALUATOR_RESPONSE_HANDLER";
export const DELETE_BUSINESS_RULE_RESPONSE_HANDLER =
  "DELETE_BUSINESS_RULE_RESPONSE_HANDLER";
export const GET_BUSINESS_RULE_EVALUATORS = "GET_BUSINESS_RULE_EVALUATORS";
export const GET_BUSINESS_RULE_EVALUATORS_RESPONSE_HANDLER =
  "GET_BUSINESS_RULE_EVALUATORS_RESPONSE_HANDLER";
export const GET_BUSINESS_RULE_TRIGGER_TYPES =
  "GET_BUSINESS_RULE_TRIGGER_TYPES";
export const GET_BUSINESS_RULE_TRIGGER_TYPES_RESPONSE_HANDLER =
  "GET_BUSINESS_RULE_TRIGGER_TYPES_RESPONSE_HANDLER";

//RULE ENGINE V2

export const SET_WIZARD_STEP = "SET_WIZARD_STEP";

// INTERVALS
export const SET_INTERVAL_ID = "SET_INTERVAL_ID";
export const GET_INTERVAL_ID = "GET_INTERVAL_ID";

//STRIPE REQUEST RESPONSE
export const SET_STRIPE_CONFIRM_PAYMENT_RESPONSE =
  "SET_STRIPE_CONFIRM_PAYMENT_RESPONSE";

export const GET_PRICING = "GET_PRICING";
export const GET_PRICING_TRIGGERS = "GET_PRICING_TRIGGERS";
export const GET_PRICING_TRIGGER_TYPES = "GET_PRICING_TRIGGER_TYPES";
export const CALCULATE_PRICING_RESPONSE = "CALCULATE_PRICING_RESPONSE";
