import React from "react";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";
import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import {
  displayBreadcrumbs,
  showHideLoader,
  showNotification,
} from "../../../../actions";

import PricingModal from "./PricingModal";

import { getPricing } from "../../../../actions/pricingAction";
import { updatePricing } from "../../../../apis/PricingApi";

class Pricing extends React.Component {
  state = {
    componentName: "Pricing",
    isShowPricingModal: false,
    isEditPricingModal: false,
    selectedPricingData: null,
    pricingList: [],
  };

  componentDidMount() {
    this.props.displayBreadcrumbs("Dashboard / Admin / Pricing");

    this.props.showHideLoader(true);
    this.props.getPricing();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pricingResponse !== this.props.pricingResponse) {
      let response = this.props.pricingResponse;

      if (response.action == "LIST" && response.type == "GET_PRICING") {
        if (response.status >= 200 && response.status <= 299) {
          this.props.showHideLoader(false);
          this.setState({
            pricingList: response.data.records,
          });
        }
      }
    }
  }

  errorHandler = () => {
    this.props.showHideLoader(false);
    this.props.showNotification(
      "error",
      "Error encountered. Please contact administrator."
    );
  };

  setPricingRuleStatus = async (pricingData) => {
    let formData = {
      display_name: pricingData["display_name"],
      description: pricingData["description"],
      customer_type: pricingData["customer_type"],
      pricing_type: pricingData["pricing_type"],
      price_per_block: pricingData["price_per_block"],
      booking_price_cap: pricingData["booking_price_cap"],
      hierarchy: pricingData["hierarchy"],
      is_active: !pricingData["is_active"],
    };

    var result = DevExtremeConfirm("Are you sure you want to make changes?");

    result.then(async (dialogResult) => {
      if (dialogResult) {
        this.props.showHideLoader(true);

        let response = await updatePricing(pricingData["uuid"], formData);

        if (response) {
          switch (response.status) {
            case 200:
              this.props.getPricing();
              this.props.showNotification("success", response.data.message);
              break;
            case 204:
              this.props.showNotification("success", "No changes made.");
              this.props.showHideLoader(false);

              break;

            default:
              this.props.showNotification("error", response.data.message);
              this.props.showHideLoader(false);
              break;
          }
        } else {
          this.errorHandler();
        }
      }
    });
  };

  showPricingModal = (
    isShow = false,
    isEdit = false,
    selectedPricingData = null
  ) => {
    this.setState({
      isShowPricingModal: isShow,
      isEditPricingModal: isEdit,
      selectedPricingData: selectedPricingData,
    });
  };

  renderConditions(pricingConditions) {
    return (
      <>
        {pricingConditions.map((condition) => {
          return (
            <ul>
              <li>{condition.pricing_trigger.description}</li>
            </ul>
          );
        })}
      </>
    );
  }

  render() {
    const { pricingList } = this.state;
    const pricingTypeList = {
      with_additional_guest_fee: "With additional guest fee",
      no_additional_guest_fee: "No guest fee",
      with_discount_fee: "With discount",
      no_discount_fee: "No discount",
    };

    return (
      <>
        <PricingModal
          showPricingModal={this.showPricingModal}
          isShow={this.state.isShowPricingModal}
          isEdit={this.state.isEditPricingModal}
          pricingData={this.state.selectedPricingData}
        />
        <MDBContainer>
          {/* FILTER SECTION */}
          <div>
            <MDBRow className="mb-4">
              <MDBCol md="6">
                <h3 className="mt-3">Manage Pricing</h3>
              </MDBCol>
              <MDBCol md="6">
                <MDBBtn
                  color="primary"
                  href="/admin/pricing/trigger"
                  className="float-right"
                >
                  Create Pricing Trigger
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  onClick={() => this.showPricingModal(true, false)}
                  className="float-right"
                >
                  Add Pricing
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>

          {/* END FILTER SECTION */}

          {/* START TABLE SECTION */}
          <MDBTable responsive striped bordered>
            <MDBTableHead>
              <tr>
                <th className="text-center" width="20%">
                  Name
                </th>
                <th className="text-center" width="15%">
                  {" "}
                  Description
                </th>
                <th className="text-center" width="5%">
                  Hierarchy
                </th>
                <th className="text-center">Customer Type</th>

                <th className="text-center">Price</th>

                <th className="text-center">Conditions</th>

                <th className="text-center" width="10%">
                  Status
                </th>
                <th className="text-center" width="10%"></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {pricingList.map((pricing) => {
                return (
                  <tr>
                    <td>{pricing.display_name}</td>
                    <td>{pricing.description}</td>
                    <td>{pricing.hierarchy}</td>

                    <td>
                      {pricing.customer_type == "primary" ? "Primary" : "Guest"}
                    </td>

                    <td>
                      <ul>
                        <li>
                          <b>Per block:</b> {pricing.price_per_block.toFixed(2)}
                        </li>
                        <li>
                          {" "}
                          <b>
                            Price cap (
                            {pricing.customer_type == "primary"
                              ? "Per block"
                              : "Booking duration"}
                            ):{" "}
                          </b>{" "}
                          {pricing.booking_price_cap.toFixed(2)}
                        </li>
                        <li>
                          <b>Pricing Type:</b>{" "}
                          {pricingTypeList[pricing.pricing_type]}
                        </li>

                        {pricing.discounts.length > 0 &&
                          pricing.pricing_type == "with_discount_fee" && (
                            <li>
                              <b>Discount:</b>{" "}
                              <>
                                <ul>
                                  {pricing.discounts.map((discount) => {
                                    return (
                                      <li>
                                        {discount["duration_in_mins"]} minutes (
                                        {discount["price"].toFixed(2)})
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            </li>
                          )}
                      </ul>{" "}
                    </td>

                    <td>
                      {this.renderConditions(pricing["pricing_conditions"])}
                    </td>

                    <td className="text-center">
                      <span
                        className={`badge rounded-pill ${
                          pricing["is_active"] == 1 ? "bg-success" : "bg-danger"
                        }`}
                      >
                        {pricing["is_active"] == 1 ? "Active" : "Disabled"}
                      </span>
                    </td>

                    <td className="text-center">
                      <>
                        <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="power-off"
                          className={`${
                            pricing["is_active"] == 1
                              ? "danger-text"
                              : "success-text"
                          } mr-2`}
                          onClick={() => {
                            this.setPricingRuleStatus(pricing);
                          }}
                        />

                        <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="edit"
                          className="cyan-text mr-2"
                          onClick={() => {
                            this.showPricingModal(true, true, pricing);
                          }}
                        />

                        {/* <MDBIcon
                          style={{ cursor: "pointer" }}
                          icon="trash"
                          className="danger-text"
                          onClick={() => {
                            this.onClickDeleteBusinessRule(
                              pricing["uuid"],
                              pricing["display_name"]
                            );
                          }}
                        /> */}
                      </>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>

          {/* END TABLE SECTION */}
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    pricingResponse: state.pricingResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    showNotification,
    getPricing,
    updatePricing,
  })(Pricing)
);
