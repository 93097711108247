import React from "react";
import { connect } from "react-redux";
import { validateLayout } from "../../../actions";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import { getPaymentRequestDetails } from "../../../actions/paymentAction";
import { MDBContainer } from "mdbreact";

class Payment extends React.Component {
  state = {
    firstname: this.props.customer.firstname,
    lastname: this.props.customer.lastname,
    showError: false,
  };

  constructor(props) {
    super(props);
  }

  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: false,
      showSearch: false,
      showHeader: false,
      showLogin: true,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();

    let id = _.split(this.props.location.pathname, "/")[3];
    if (id === undefined) {
      this.stripePromise = loadStripe(
        this.props.paymentRequestSuccess.data.stripeData.organisationPublicKey
      );
      const sessionId = this.props.paymentRequestSuccess.data.stripeData
        .checkoutSessionId;

      this.stripePromise.then((stripe) =>
        stripe.redirectToCheckout({ sessionId }).catch((error) => {
          console.log(error);
        })
      );
    } else {
      this.props.getPaymentRequestDetails({ requestId: id });
      //-- TODO
      //-- Get stripe details from api then redirect to stripe payment page. Place this is componentDidUpdate
      // this.stripePromise = loadStripe(
      //   "pk_test_51H2cpVB7VoUm5B0eylrOFen6a12kZ15e1HyOtIGJopans63zzhB7f14wap9B77GvKCQWGHJ64abcUNXmDiWgVlhy00fwSilZhE"
      // );
      // const sessionId =
      //   "cs_test_5iiiXwkhSifLEadpRvr6E5z75E4iBoEMMpgF2TrmMHfn49NdJBE5moEp";

      // this.stripePromise.then((stripe) =>
      //   stripe.redirectToCheckout({ sessionId }).catch((error) => {
      //     console.log(error);
      //   })
      // );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.paymentResponse !== this.props.paymentResponse) {
      if (this.props.paymentResponse.status !== 200) {
        this.setState({ showError: true });
      } else {
        this.stripePromise = loadStripe(
          this.props.paymentResponse.data.organisationPublicKey
        );
        const sessionId = this.props.paymentResponse.data.checkoutSessionId;

        this.stripePromise.then((stripe) =>
          stripe.redirectToCheckout({ sessionId }).catch((error) => {
            console.log(error);
          })
        );
      }
    }
  }

  render() {
    const { showError } = this.state;
    if (showError) {
      return (
        <div>
          <MDBContainer>
            <h1 className="text-center mt-5 text-danger">500</h1>
            <h5 className="text-center">Opps! Invalid Request</h5>
            <h6 className="text-center">
              Sorry, but the payment request is invalid. Please contact system
              administrator.
            </h6>
          </MDBContainer>
        </div>
      );
    } else {
      return (
        <div className="text-center mt-5">
          Redirecting to payment page please wait....
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    customer: state.customer,
    paymentRequestSuccess: state.paymentRequestSuccess,
    paymentResponse: state.paymentResponse,
  };
};

export default connect(mapStateToProps, {
  validateLayout,
  getPaymentRequestDetails,
})(Payment);
