import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { confirm as DevExtremeConfirm } from "devextreme/ui/dialog";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { showHideLoader, showNotification } from "../../../actions";
import { bulkPayPaymentPendingBookings } from "../../../actions/paymentAction";

import _ from "lodash";

class PaymentReferenceNumberModal extends React.Component {
  state = {
    payment_reference_number: "",
    wasValidated: "",
  };
  formDataHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ wasValidated: "was-validated" });
    if (event.target.checkValidity()) {
      this.props.togglePaymentReferenceModal(false);

      var result = DevExtremeConfirm(
        `Are you sure you want to bulk pay selected records?`
      );

      result.then((dialogResult) => {
        if (dialogResult) {
          this.props.showHideLoader(true);

          this.props
            .bulkPayPaymentPendingBookings(
              {
                payment_reference_number: this.state.payment_reference_number,
                booking_reference_number_lists:
                  this.props.bulkPaymentPendingBookingRefNo,
              },
              this.props.authUser.sessionToken
            )
            .then((response) => {
              if (response.status === 200 && response.data) {
                this.props.showNotification(
                  "success",
                  "Bulk payment has been successful"
                );
                this.props.showHideLoader(false);
                this.props.closeModal(null);
              }
            })
            .catch((err) => {
              this.props.showNotification(
                "danger",
                "Error has occured. Please try again."
              );
              this.props.showHideLoader(false);
              this.props.closeModal(null);
            });
        }
      });
    }
  };

  render() {
    return (
      <div className="child-popup">
        <MDBModal
          isOpen={this.props.isShowPaymentReferenceModal}
          size="md"
          toggle={() => this.props.togglePaymentReferenceModal(false)}
        >
          <form
            className={`needs-validation ${this.state.wasValidated}`}
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBModalHeader>Payment Pending Bulk Update</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="displayName" className="grey-text">
                    <span className="text-danger">*</span> Payment Reference
                    Number
                  </label>
                  <input
                    value={this.state.payment_reference_number}
                    onChange={this.formDataHandler}
                    name="payment_reference_number"
                    type="text"
                    id="payment_reference_number"
                    className="form-control"
                    placeholder="Enter Payment Reference Number..."
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide payment reference number
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => this.props.togglePaymentReferenceModal(false)}
              >
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Bulk Pay
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showHideLoader,
    showNotification,
    bulkPayPaymentPendingBookings,
  })(PaymentReferenceNumberModal)
);
