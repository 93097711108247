import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { connect } from "react-redux";
import { validateLayout } from "../../../actions";
import PageTitle from "../shared/PageTitle";
import WeeklyCalendarV2 from "../shared/WeeklyCalendarV2";
import VenueDetails from "../shared/VenueDetails";
import RichTextRenderer from "../shared/RichTextRenderer";
import { searchLocations } from "../../../actions/locationAction";

import queryString from "query-string";

class Booking extends React.Component {
  setLayout() {
    const layout = {
      showBanner: false,
      showFooter: true,
      showSearch: false,
      showHeader: true,
      showLogin: true,
    };
    this.props.validateLayout(layout);
  }

  componentDidMount() {
    this.setLayout();

    let params = queryString.parse(this.props.location.search);
    this.props.searchLocations(params.uuid);
    window.scrollTo(0, 0);
  }

  renderContent() {
    let location = this.props.locations.results;
    if (location !== undefined) {
      location = location[0];
      return (
        <div>
          <MDBContainer className="content-min-height mt-5">
            {location.attributes.logo_url && (
              <div className="text-center">
                <img
                  src={location.attributes.logo_url}
                  style={{ height: "100px" }}
                />
              </div>
            )}
            <PageTitle
              title={`${location.name}`}
              subTitle={location.attributes.full_address}
            />
            {typeof location.attributes.general_configurations !==
              "undefined" && (
              <h5 className="text-center mt-1" style={{ fontWeight: "bolder" }}>
                {location.attributes.general_configurations.public_notice}
              </h5>
            )}
            {typeof location.attributes.pricingDetails !== "undefined" && (
              <div className="mt-5">
                <RichTextRenderer
                  insideContainer={false}
                  htmlString={location.attributes.pricingDetails}
                />
              </div>
            )}
            <MDBRow className="mt-2 mb-5">
              <MDBCol size="12">
                <WeeklyCalendarV2 private={false} />
              </MDBCol>
              <MDBCol size="12">
                <div className="mt-5">
                  <RichTextRenderer
                    htmlString={location.attributes.html_formatted_about}
                  />
                </div>
              </MDBCol>
              <MDBCol size="12">
                <div className="mt-5">
                  <RichTextRenderer
                    doScrollY={true}
                    htmlString={location.attributes.terms_and_conditions}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      );
    } else {
      return <div className="text-center mt-5 mb-5">Loading...</div>;
    }
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return { layout: state.layout, locations: state.locations };
};

export default connect(mapStateToProps, { validateLayout, searchLocations })(
  Booking
);
