import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBSwitch } from "mdbreact";
import moment, { utc } from "moment";
import DatePicker from "react-datepicker";

class CalendarDateName extends React.Component {
  componentDidMount() {}

  render() {
    if (this.props.isPrivate) {
      return (
        <div>
          <DatePicker
            key={this.props.key}
            className="custom-input"
            placeholderText="Click here"
            maxDate={this.props.maxDate}
            clear
            selected={this.props.selectedDate}
            onChange={this.props.dateOnChange}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      );
    }
    return (
      <div>
        <DatePicker
          key={this.props.key}
          className="custom-input"
          placeholderText="Click here"
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          clear
          selected={this.props.selectedDate}
          onChange={this.props.dateOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("this.state.switch" + this.state.switch);
    // console.log("check !this.props.setSwitchValResponse");
    // console.log(this.props.setSwitchValResponse);
  }
}

const mapStateToProps = (state) => {
  return { selectedDate: state.selectedDate };
};

export default connect(mapStateToProps, {})(CalendarDateName);
