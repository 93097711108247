import { BOOKING_DETAILS } from "../actions/types";

export default (
  details = {
    location: "",
    locationUuid: "",
    resource: "",
    resourceUuid: "",
    reservationDate: "",
    time: "",
    availableSlot: [],
  },
  action
) => {
  if (action.type === BOOKING_DETAILS) {
    return action.payload;
  }
  return details;
};
