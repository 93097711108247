import UbsApiV2 from "./UbsApiV2";

export const createRecurring = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.post(
    `/ssc/recurring/create_schedules`,
    body,
    {
      headers: headers,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const unblockRecurringSchedule = async (recurring_id, body = {}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.post(
    `/ssc/recurring/unblock_schedules/${recurring_id}`,
    body,
    {
      headers: headers,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const updateRecurring = async (body, params) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.post(`/ssc/recurring/update`, body, {
    headers: headers,
    params: params,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const cancelRecurring = async (recurring_id, body = {}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.put(
    `/ssc/recurring/cancel/${recurring_id}`,
    body,
    {
      headers: headers,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const reprocessRecurring = async (recurring_id) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.post(
    `/ssc/recurring/reprocess/${recurring_id}`,
    {
      headers: headers,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};

export const getRecurringById = async (recurring_id, params) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await UbsApiV2.get(`/ssc/recurring/${recurring_id}`, {
    headers: headers,
    params: params,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });

  return response;
};
